import { Button, Checkbox, DatePicker, Form, FormInstance, Input, Modal, Select, Space } from 'antd';
import React from 'react'
import { TeacherDtoResponse } from '../../../interfaces/Teacher/TeacherDtoResponse';
import { TeacherDtoRequest } from '../../../interfaces/Teacher/TeacherDtoRequest';
import useDictionary from '../../../hooks/useDictionary';
import { TeacherService } from '../../../services/TeacherService';
import { successNotification } from '../../../helpers/successNotification';
import InputMask from "react-input-mask";
import { useTheme } from '../../../themes/useTheme';
import { EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import grantPermission from '../../../helpers/grantPermission';
import { HRService } from '../../../services/HRService';
import { DisciplinesService } from '../../../services/DisciplinesService';
import { TeacherPositionDtoResponce } from '../../../interfaces/Teacher/TeacherPositionDtoResponce';
import { TeacherTypeDtoResponse } from '../../../interfaces/Teacher/TeacherTypeDtoResponse';
import { TeacherStatusDtoResponse } from '../../../interfaces/Teacher/TeacherStatusDtoResponse';
import { DepartmentDtoResponse } from '../../../interfaces/DepartmentDtoResponse';
import { TeacherAdditionalInfoDtoRequest } from '../../../interfaces/Teacher/TeacherAdditionalInfoDtoRequest';


interface TeacherEditModalProps {
    form?: FormInstance;
    teacherInfo?: TeacherDtoResponse;
    fetchTeacherData?: () => void;
    id?: number;
}

const TeacherEditModal = ({
    form,
    teacherInfo,
    fetchTeacherData,
    id,
}: TeacherEditModalProps) => {
    const { dictionary } = useDictionary({
        fetchNationailty: true, fetchCitizenship: true, fetchScientificDegree: true,
        fetchKpiType: true, fetchAcademicStatus: true
    });
    const [open, setOpen] = React.useState(false);
    const [isDarkMode] = useTheme();
    const isHr: boolean | undefined = grantPermission('HR', 'addRole');
    const additionalInfoDtoRequest: TeacherAdditionalInfoDtoRequest[] = [];
    const [isChecked, setIsChecked] = React.useState(false);

    const [positionsList, setPositionsList] = React.useState<TeacherPositionDtoResponce[]>();
    const [teacherTypeList, setTeacherTypeList] = React.useState<TeacherTypeDtoResponse[]>();
    const [teacherStatusList, setTeacherStatusList] = React.useState<TeacherStatusDtoResponse[]>();
    const [deps, setDeps] = React.useState<DepartmentDtoResponse[]>([]);

    React.useEffect(() => {
        if (grantPermission('HR')) {
            TeacherService.getPositionsList()
                .then((res) => setPositionsList(res.data))

            TeacherService.getTypesList()
                .then((res) => setTeacherTypeList(res.data))

            TeacherService.getStatusList()
                .then((res) => setTeacherStatusList(res.data))

            DisciplinesService.getDepartmentsPublic()
                .then((res) => {
                    setDeps(res.data);
                })
            teacherInfo?.ftutor && setIsChecked(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = (teacherData: TeacherDtoRequest) => {
        teacherData.additionalInfo = additionalInfoDtoRequest.filter(value => value);

        isHr
            ?
            id &&
            HRService.updateTeacher(id, teacherData)
                .finally(() => {
                    fetchTeacherData && fetchTeacherData();
                    setOpen(false);
                    successNotification("Profile information updated!")
                })
            :
            TeacherService.updateTeacherInfo(teacherData)
                .finally(() => {
                    fetchTeacherData && fetchTeacherData();
                    setOpen(false);
                    successNotification("Profile information updated!")
                })


    }

    return (
        <>
            <Button
                type="primary"
                icon={<EditOutlined />}
                disabled={teacherInfo?.status.id === 2}
                onClick={() => setOpen(true)}
            >
                Edit
            </Button>
            <Modal
                open={open}
                title="Edit teacher"
                okText="Edit"
                cancelText="Cancel"
                okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
                onCancel={() => setOpen(false)}
                destroyOnClose
                modalRender={(dom) => (
                    <Form
                        layout="vertical"
                        form={form}
                        name="form_in_modal"
                        onFinish={(values) => onUpdate(values)}
                    >
                        {dom}
                    </Form>
                )}
            >
                <Form.Item name={"nameKz"} label={"First name"}>
                    <Input
                        defaultValue={teacherInfo?.nameKz}
                        size={"middle"}
                    />
                </Form.Item>
                <Form.Item name={"surnameKz"} label={"Last name"}>
                    <Input
                        defaultValue={teacherInfo?.surnameKz}
                        size={"middle"}
                    />
                </Form.Item>
                <Form.Item name={"patronymicKz"} label={"Patronymic"}>
                    <Input
                        defaultValue={teacherInfo?.patronymicKz}
                        size={"middle"}
                    />
                </Form.Item>
                <Form.Item name={"nameEn"} label={"First name (English)"}>
                    <Input
                        defaultValue={teacherInfo?.nameEn}
                        size={"middle"}
                    />
                </Form.Item>
                <Form.Item name={"surnameEn"} label={"Last name (English)"}>
                    <Input
                        defaultValue={teacherInfo?.surnameEn}
                        size={"middle"}
                    />
                </Form.Item>
                <Form.Item name={"iin"} label={"IIN"}>
                    <Input
                        defaultValue={teacherInfo?.iin}
                        maxLength={12}
                        size={"middle"}
                    />
                </Form.Item>
                <Form.Item
                    label="Birth Date"
                    name="birthDate"
                    style={{ marginBottom: '10px', width: '100%' }}
                    getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                    getValueProps={(i) => ({ value: i ? moment(i) : null })}
                >
                    <DatePicker placeholder="Select birth date" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name={"phone"} label={"Phone"}>
                    <InputMask
                        mask={"+7 (999) 999-99-99"}
                        defaultValue={teacherInfo?.phone}
                        style={isDarkMode ? {
                            backgroundColor: "#1f1f1f",
                            width: "100%",
                            padding: "6.5px 11px 6.5px",
                            border: "1px solid #434343"
                        } : {
                            width: "100%",
                            padding: "6.5px 11px 6.5px",
                            border: "1px solid #434343"
                        }}
                    >
                    </InputMask>
                </Form.Item>
                <Form.Item
                    name={"nationalityId"}
                    label={"Nationality"}
                >
                    <Select placeholder="Select option" defaultValue={teacherInfo?.nationality?.id} size={"middle"} allowClear style={{ width: "100%", borderRadius: "10px" }}>
                        {dictionary.nationalities?.map((option: any) => (
                            <Select.Option key={option.id} value={option.id}>
                                {option.titleEn}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name={"citizenshipId"}
                    label={"Citizenship"}
                >
                    <Select placeholder="Select option" defaultValue={teacherInfo?.citizenship?.id} size={"middle"} allowClear style={{ width: "100%", borderRadius: "10px" }}>
                        {dictionary.citizenships?.map((option: any) => (
                            <Select.Option key={option.id} value={option.id}>
                                {option.titleEn}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Gender"
                    name="sexId"
                >
                    <Select placeholder="Select option" defaultValue={teacherInfo?.sexId} allowClear size={"middle"} style={{ width: "100%", borderRadius: "10px" }}>
                        {dictionary.genderListEn.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                                {`${option.value}`}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {!isHr && <Form.List
                    name="scientificInterests"
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    label={index === 0 ? 'Scientific interests' : ''}
                                    required={false}
                                    key={field.key}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input scientific interest name or delete this field.",
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input placeholder="Scientific interest" style={{ width: "93%" }} />
                                    </Form.Item>
                                    <Button
                                        icon={<MinusCircleOutlined />}
                                        onClick={() => remove(field.name)}
                                    />
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '100%' }}
                                    icon={<PlusOutlined />}
                                >
                                    Add Scientific Interest
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>}
                {!isHr && <Form.List
                    name="taughtCourses"
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    label={index === 0 ? 'Taught courses' : ''}
                                    required={false}
                                    key={field.key}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input taught course name or delete this field.",
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input placeholder="Taught course" style={{ width: "93%" }} />
                                    </Form.Item>
                                    <Button
                                        icon={<MinusCircleOutlined />}
                                        onClick={() => remove(field.name)}
                                    />
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '100%' }}
                                    icon={<PlusOutlined />}
                                >
                                    Add Taught Course
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>}
                {isHr && (
                    <>
                        <Form.Item
                            label="Тип"
                            name="typeId"
                            style={{ marginBottom: '10px' }}
                        >
                            <Select placeholder="Выберите тип" allowClear>
                                {teacherTypeList?.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {`${option.name}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Должность"
                            name="positionId"
                            style={{ marginBottom: '10px' }}
                        >
                            <Select placeholder="Выберите должность" allowClear>
                                {positionsList?.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {`${option.name}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="KPI позиция"
                            name="kpiPosition"
                            style={{ marginBottom: '10px' }}
                        >
                            <Select placeholder="Выберите должность" allowClear>
                                {dictionary.positions?.map((option) => (
                                    <Select.Option key={option} value={option}>
                                        {`${option}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Ученая степень"
                            name="scientificDegreeId"
                            style={{ marginBottom: '10px' }}
                        >
                            <Select placeholder="Выберите ученую степень" allowClear>
                                {dictionary.scientificDegrees?.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {`${option.nameRu}/${option.nameKz}/${option.nameEn}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Академический статус"
                            name="academicStatusId"
                            style={{ marginBottom: '10px' }}
                        >
                            <Select placeholder="Выберите академический статус" allowClear>
                                {dictionary.academicStatuses?.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {`${option.nameRu}/${option.nameKz}/${option.nameEn}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Статус"
                            name="statusId"
                            style={{ marginBottom: '10px' }}
                        >
                            <Select placeholder="Выберите статус" allowClear>
                                {teacherStatusList?.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {`${option.name}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Департамент"
                            name="departmentId"
                            style={{ marginBottom: '10px' }}
                        >
                            <Select placeholder="Выберите департамент" allowClear>
                                {deps?.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {`${option.titleKz}/${option.titleRu}/${option.titleEn}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Зарубежный учитель"
                            valuePropName="checked"
                            name="ftutor"
                            initialValue={false}
                            style={{ marginBottom: '10px' }}
                        >
                            <Checkbox onChange={e => setIsChecked(e.target.checked)} />
                        </Form.Item>

                        {isChecked &&
                            // Object.keys(data.typeTitles).map((key, index) => {
                            //   return (
                            //     <Form.Item label={data.typeTitles[key]} key={index}>
                            //       <Input
                            //         placeholder={data.typeTitles[key]}
                            //         onChange={e => additionalInfoDtoRequest[index] = { type: key, value: e.target.value }}
                            //       />
                            //     </Form.Item>
                            //   );
                            // })
                            (
                                <>
                                    <Form.Item label={"Наименование организации зарубежного ученого"} style={{ marginBottom: '10px' }}>
                                        <Input
                                            placeholder={"Напишите наименование организации зарубежного ученого"}
                                            onChange={e => additionalInfoDtoRequest[0] = { type: "F_UNIVERSITY", value: e.target.value }}
                                        />
                                    </Form.Item>

                                    <Form.Item label={"Страна расположения вуза зарубежного ученого"} style={{ marginBottom: '10px' }}>
                                        <Select placeholder={"Выберите страну расположения вуза"}
                                            onSelect={(e) => additionalInfoDtoRequest[1] = { type: "F_UNIVERSITY_COUNTRY", value: e }}>
                                            {dictionary.countries?.map((data) => (
                                                <Select.Option key={data.id} value={data.id}>
                                                    {`${data.nameRu}`}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label={"Специальность"} style={{ marginBottom: '10px' }}>
                                        <Input
                                            placeholder={"Напишите специальность"}
                                            onChange={e => additionalInfoDtoRequest[2] = { type: "SPECIALITY", value: e.target.value }}
                                        />
                                    </Form.Item>

                                    <Space style={{ marginBottom: '10px' }}>
                                        <Form.Item label={"Дата начала пребывания"}>
                                            <DatePicker format="YYYY-MM-DD" placeholder='Выберите дату' onChange={(value, dateString) => additionalInfoDtoRequest[3] = { type: "F_START_DATE", value: dateString }} />
                                        </Form.Item>
                                        <Form.Item label={"Дата окончания пребывания"}>
                                            <DatePicker format="YYYY-MM-DD" placeholder='Выберите дату' onChange={(value, dateString) => additionalInfoDtoRequest[4] = { type: "F_END_DATE", value: dateString }} />
                                        </Form.Item>
                                    </Space>
                                </>
                            )
                        }

                        <Form.Item
                            label="Женат/Замужем"
                            valuePropName="checked"
                            name="isMarried"
                            initialValue={false}
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            label="В декретном отпуске"
                            valuePropName="checked"
                            name="maternityLeave"
                            initialValue={false}
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            label="Резидент РК"
                            valuePropName="checked"
                            name="isResident"
                            initialValue={false}
                        >
                            <Checkbox />
                        </Form.Item>
                    </>
                )}
            </Modal >
        </>
    )
}

export default TeacherEditModal