import { Typography } from 'antd';

interface ContactsListProps {
  children: React.ReactNode;
}

const ContactsListItem = ({ children }: ContactsListProps) => (
  <Typography.Text type="secondary">{children}</Typography.Text>
);

export default ContactsListItem;
