import { Typography, Form, Select, Input, DatePicker, DatePickerProps, Checkbox, Button, Space, Row, Col } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { AcademicMobilityService } from '../../../../services/AcademicMobilityService';
import { AcademicMobilityDtoRequest } from '../../../../interfaces/AcademicMobilityDtoRequest';
import PageWrapper from '../../../../ui/PageWrapper';
import BackButton from '../../../../ui/BackButton';
import { errorNotification } from '../../../../helpers/errorNotification';
import { successNotification } from '../../../../helpers/successNotification';
import { ForeignUniversitiesDtoResponse } from '../../../../interfaces/ForeignUniversitiesDtoResponse';
import { ForeignUniversitiesService } from '../../../../services/ForeignUniversitiesService';
import { FinancingSourceService } from '../../../../services/FinancingSourceService';
import { FinancingSourceDto } from '../../../../interfaces/FinancingSourceDto'
import { OrdersService } from '../../../../services/OrdersService';
import { OrderDtoResponse } from '../../../../interfaces/Order/OrderDtoResponse';
import StudentGroupFilter from './StudentGroupFilter';
import StudentsFilter from './StudentsFilter';
import useStudentSearch from './useStudentSearch';
import { StudentDtoResponse } from '../../../../interfaces/Student/StudentDtoResponse';
import { TranscriptService } from '../../../../services/TranscriptService';



const { Title } = Typography;
const { Option } = Select;

interface Props {

    academicMobilityId?: number;
}



const AcademicMobilityCreationPage = ({ academicMobilityId }: Props) => {


    const {
        onGroupSelect,
        groupList,
        studentsList,
        selectedGroup,
        selectedStudent,
        setSelectedStudent,


    } = useStudentSearch();
    const [form] = Form.useForm();
    const [universities, setUniversities] = useState<ForeignUniversitiesDtoResponse[]>([]);
    const [financingSources, setFinancingSources] = useState<FinancingSourceDto[]>([]);
    const [searchResults, setSearchResults] = useState<ForeignUniversitiesDtoResponse[]>([]);
    const [searchFinancing, setSearchFinancing] = useState<FinancingSourceDto[]>([]);
    const [orders, setOrders] = useState<OrderDtoResponse[] | null>(null);
    const [showResults, setShowResults] = useState<boolean>(false);




    const styles = { width: '800px', margin: '0 auto' };



    useEffect(() => {
        ForeignUniversitiesService.getAllForeignUniversities()
            .then((response) => setUniversities(Array.isArray(response.data) ? response.data : [response.data]))
            .catch((error) => console.error(error));

    }, []);
    useEffect(() => {
        FinancingSourceService.getAllFinancingSource()
            .then((response) => setFinancingSources(Array.isArray(response.data) ? response.data : [response.data]))
            .catch((error) => console.error(error));
    }, []);




    const handleFormValuesChange = (changedValues: any) => {
        const [fieldName] = Object.keys(changedValues);
        const value = changedValues[fieldName];
        if (fieldName === 'universityId' && typeof value === 'string') {
            const filteredUniversities = universities.filter(university =>
                university.nameEn.toLowerCase().includes(value.toLowerCase())
            );
            setSearchResults(filteredUniversities);
            setShowResults(true);
        }
    };


    const handleUniversitySelect = (value: number) => {
        form.setFieldsValue({ universityId: value });
        setShowResults(false);
    };

    const handleFormValuesChangeFS = (changedValues: any) => {
        const [fieldName] = Object.keys(changedValues);
        const value = changedValues[fieldName];
        if (fieldName === 'financingSourceId' && typeof value === 'string') {
            const filteredFinancingSource = financingSources.filter(financingSource =>
                financingSource.nameRu.toLowerCase().includes(value.toLowerCase())
            );
            setSearchFinancing(filteredFinancingSource);
            setShowResults(true);
        }
    };


    const handleFinancingSourceSelect = (value: number) => {
        form.setFieldsValue({ id: value });
        setShowResults(false);
    };
    const handleOrdersSelect = (value: number) => {
        form.setFieldsValue({ id: value });
        setShowResults(false);
    };

    const onFinish = (academicMobility: AcademicMobilityDtoRequest) => {
        academicMobilityId
            ? AcademicMobilityService.updateAcademicMobility(academicMobilityId, academicMobility)
                .then(() => successNotification('Данные успешно обновлены'))
                .catch((err) => errorNotification('Не удалось обновить данные', err.response?.status))
            : AcademicMobilityService.createAcademicMobility(academicMobility)
                .then(() => successNotification('Данные успешно сохранены'))
                .catch((err) => errorNotification('Не удалось сохранить данные', err.response?.status));
    };

    const [oldEndDate, setOldEndDate] = useState<moment.Moment | null>(null);

    const [oldStartDate, setOldStartDate] = useState<moment.Moment | null>(null);
    const [studentDetails, setStudentDetails] = useState<StudentDtoResponse | null>(null);
    const [selectedStudentId, setSelectedStudentId] = useState<number | null>(null);
    const initialStudentId = studentDetails?.id || null;

    const fetchOrdersForStudent = (studentId: number) => {
        OrdersService.getAcademicMobilityOrderByStudentIdAndType(studentId)
            .then((response) => {
                const newOrders = Array.isArray(response.data) ? response.data : [response.data];
                setOrders(newOrders);
            })
            .catch((error) => console.error('Error fetching orders:', error));
    };

    useEffect(() => {
        const idToFetch = selectedStudentId !== null ? selectedStudentId : initialStudentId;
        if (idToFetch) {
            fetchOrdersForStudent(idToFetch);
        }
    }, [selectedStudentId, initialStudentId]);




    useEffect(() => {
        if (
            academicMobilityId
        ) {
            AcademicMobilityService.getAcademicMobilityById(academicMobilityId)
                .then(({ data }) => {

                    form.setFieldsValue({

                        student: data.student,
                        universityId: data.university ? data.university.id : '',
                        startDate: data.startDate ? moment(data.startDate) : null,
                        endDate: data.endDate ? moment(data.endDate) : null,
                        financingSourceId: data.financingSource ? data.financingSource.id : '',
                        orderId: data.orderId,
                        mobilityType: data.mobilityType,
                        startTerm: data.startTerm,
                        finishTerm: data.finishTerm

                    });
                    setOldStartDate(data.startDate ? moment(data.startDate) : null);
                    setOldEndDate(data.endDate ? moment(data.endDate) : null);

                    TranscriptService.getStudentById(data.student.id)
                        .then((response) => {
                            const studentDetail: StudentDtoResponse | null = Array.isArray(response.data)
                                ? response.data[0]
                                : response.data;

                            setStudentDetails(studentDetail);

                        })
                        .catch((err) => {
                            console.error('Ошибка при получении данных студента', err);
                            setStudentDetails(null);
                        });




                })
                .catch((err) => {
                    errorNotification('Не удалось получить данные', err.response?.status);
                    setStudentDetails(null);
                });
        }
    }, [form, academicMobilityId]);




    const handleStartDateChange = (date: moment.Moment | null, dateString: string) => {
        if (date) {
            setOldStartDate(date);
            form.setFieldsValue({ startDate: date });
        }
    };
    const handleEndDateChange = (date: moment.Moment | null, dateString: string) => {
        if (date) {
            setOldEndDate(date);
            form.setFieldsValue({ endDate: date });
        }
    };
    const [showFilters, setShowFilters] = useState<boolean>(false);


    const handleShowFilters = () => {
        setShowFilters(true);
        setSelectedStudentId(null);
        setOrders(null);
        form.setFieldsValue({ orderId: undefined });

    };
    useEffect(() => {
        const idToFetch = selectedStudentId !== null ? selectedStudentId : initialStudentId;
        if (idToFetch) {
            setOrders(null);
            fetchOrdersForStudent(idToFetch);
        }
    }, [selectedStudentId, initialStudentId]);




    return (
        <PageWrapper>
            <BackButton />
            <header style={styles}>
                <Title level={3}>
                    {academicMobilityId ? `Редактирование Данных академической Мобильности` : 'Добавление новых данных'}
                </Title>
            </header>

            <Form
                layout="vertical"
                size="large"
                form={form}
                style={styles}
                onFinish={onFinish}
                onValuesChange={handleFormValuesChange}

            >


                <Form.Item>

                    <Title level={5}>
                        {studentDetails ? `${studentDetails.nameEn} ${studentDetails.surnameEn}` : ''}
                    </Title>

                </Form.Item>



                {showFilters && (
                    <>
                        <Form.Item
                            label="Student"
                            name="student"
                        >
                            <Space size={20} direction={'vertical'}>
                                <StudentGroupFilter
                                    placeholder="Группа обучающихся"
                                    selectedOption={selectedGroup}
                                    onSelect={(value) => onGroupSelect(value)}
                                    options={[...groupList]}
                                ></StudentGroupFilter>

                                <StudentsFilter
                                    placeholder="ФИО Студента"
                                    selectedOption={selectedStudent}
                                    onSelect={(value) => {
                                        setSelectedStudent(value);
                                        setSelectedStudentId(value);
                                        form.setFieldsValue({ student: value });
                                    }}
                                    options={[...studentsList]}

                                ></StudentsFilter>

                            </Space>
                        </Form.Item>
                    </>
                )}

                {!showFilters && (
                    <Form.Item>
                        <Button type="primary" onClick={handleShowFilters}>
                            Выбрать студента
                        </Button>
                    </Form.Item>
                )}



                <Form.Item
                    label="University ID"
                    name="universityId"

                >
                    <Select
                        showSearch
                        placeholder={'Выберите университет'}
                        onSearch={(value) => handleFormValuesChange({ universityId: value })}
                        onFocus={() => handleFormValuesChange({ universityId: '' })}
                        onBlur={() => setShowResults(false)}
                        onSelect={(value) => handleUniversitySelect(Number(value))}
                        filterOption={false}
                    >
                        {showResults
                            ? searchResults.map((university) => (
                                <Option key={university.id} value={university.id}>
                                    {university.nameEn}
                                </Option>
                            ))
                            : universities.map((university) => (
                                <Option key={university.id} value={university.id}>
                                    {university.nameEn}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="Select Start Date"
                            name="startDate"
                            valuePropName='startDate'

                            rules={
                                [
                                    {
                                        required: false,
                                        message: "Введите дату"

                                    }
                                ]
                            }
                        >

                            <DatePicker style={{ width: '90%' }}
                                format="YYYY-MM-DD"
                                onChange={handleStartDateChange}
                                value={oldStartDate}

                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Select End Date"
                            name="endDate"
                            valuePropName='endDate'


                        >

                            <DatePicker style={{ width: '90%' }}
                                format="YYYY-MM-DD"
                                onChange={handleEndDateChange}
                                value={oldEndDate}

                            />

                        </Form.Item>
                    </Col>
                </Row>


                <Form.Item
                    label="Financing Source"
                    name="financingSourceId"

                >
                    <Select
                        showSearch
                        placeholder='Выберите финансирование'
                        onSearch={(value) => handleFormValuesChangeFS({ financingSourceId: value })}
                        onFocus={() => handleFormValuesChangeFS({ financingSourceId: '' })}
                        onBlur={() => setShowResults(false)}
                        onSelect={(value) => handleFinancingSourceSelect(Number(value))}
                        filterOption={false}
                    >
                        {showResults
                            ? searchFinancing.map((financingSource) => (
                                <Option key={financingSource.id} value={financingSource.id}>
                                    {financingSource.nameRu}
                                </Option>
                            ))
                            : financingSources.map((financingSource) => (
                                <Option key={financingSource.id} value={financingSource.id}>
                                    {financingSource.nameRu}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>



                <Form.Item name="orderId" label="Order">
                    <Select placeholder="Выберите приказ">
                        {orders ? (
                            orders.map((data) => (
                                <Option key={data.id} value={data.id}>
                                    {`${data.number} - ${data.name} - ${data.issueDate}`}
                                </Option>
                            ))
                        ) : null}
                    </Select>
                </Form.Item>



                <Form.Item
                    label="Mobility Type"
                    name="mobilityType"

                >
                    <Select placeholder='Выберите тип академической мобильности'>
                        <Option value={1}>
                            Выезд внутренний
                        </Option>
                        <Option value={2}>
                            Выезд внешний
                        </Option>
                    </Select>
                </Form.Item>


                <Form.Item
                    label="Start Term"
                    name="startTerm"

                >
                    <Select placeholder='Выберите начальный триместр'>
                        <Option value={1}>
                            1
                        </Option>
                        <Option value={2}>
                            2
                        </Option>
                        <Option value={3}>
                            3
                        </Option>
                        <Option value={4}>
                            4
                        </Option>
                        <Option value={5}>
                            5
                        </Option>
                        <Option value={6}>
                            6
                        </Option>
                        <Option value={7}>
                            7
                        </Option>
                        <Option value={8}>
                            8
                        </Option>
                        <Option value={9}>
                            9
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Finish Term"
                    name="finishTerm"

                >
                    <Select placeholder='Выберите финальный триместр'>
                        <Option value={1}>
                            1
                        </Option>
                        <Option value={2}>
                            2
                        </Option>
                        <Option value={3}>
                            3
                        </Option>
                        <Option value={4}>
                            4
                        </Option>
                        <Option value={5}>
                            5
                        </Option>
                        <Option value={6}>
                            6
                        </Option>
                        <Option value={7}>
                            7
                        </Option>
                        <Option value={8}>
                            8
                        </Option>
                        <Option value={9}>
                            9
                        </Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>


                    </Space>
                </Form.Item>
            </Form>
        </PageWrapper>
    );
};

export default AcademicMobilityCreationPage;
