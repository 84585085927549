import { Select } from 'antd';
const { Option } = Select;

interface Props {
  placeholder: string;
  options: any[];
  width?: number;
  value: number | undefined;
  onClear: () => void;
  onSelect: (value: number) => void;
  renderItems: (item: any) => string;
}

const DataSelect = ({ options, placeholder, width, value, onSelect, onClear, renderItems }: Props) => {
  return (
    <Select
      style={{ width: width ? width : 400 }}
      onSelect={onSelect}
      placeholder={placeholder}
      value={value}
      allowClear
      onClear={onClear}
    >
      {options?.map((option) => (
        <Option key={option.id} value={option.value}>
          {renderItems(option)}
        </Option>
      ))}
    </Select>
  );
};

export default DataSelect;
