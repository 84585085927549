import { Select } from 'antd';

const { Option } = Select;

interface Props {
  placeholder: string;
  selectedOption: number | undefined;
  onSelect: (value: number) => void;
  // TODO: Поменять тип
  options: any;
}

const DiplomaWorkFilter = ({ placeholder, selectedOption, onSelect, options }: Props) => {
  return (
    <Select showSearch placeholder={placeholder} value={selectedOption} onSelect={onSelect} style={{ width: '100%' }}>
      {options?.map((diplomaWork: any) => (
        <Option key={diplomaWork.id} value={diplomaWork.id}>
          {diplomaWork.titleRu}
        </Option>
      )) ?? []}
    </Select>
  );
};

export default DiplomaWorkFilter;
