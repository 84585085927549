import { message } from "antd";
import authAxios from "../../../common/authAxios";
import { errorNotification } from "../../../helpers/errorNotification";
import { successNotification } from "../../../helpers/successNotification";

interface FetchFxExamApplicationDataParams {
    studentStatus: string;
    course: number | null;
    approved: number | null;
    startDate: string | null;
    endDate: string | null;
}

export class FxExamApplicationService {
    private static baseUrlFxExamApplication = '/astanait-office-module/api/v1/fx-exam-application';
    private static baseUrlFxSchedule = '/astanait-schedule-module/api/v1/fx';

    
    static getFxExamApplicationsForStudent(studentId: number) {
        return authAxios.get(`${this.baseUrlFxExamApplication}/fx-exam-applications-for-student/${studentId}`);
    }

    static getFxExamData(email: string) {
        return authAxios.get(`${this.baseUrlFxSchedule}/${email}`);
    }

    static getFxExamStudentsByTeacherEmail(email: string) {
        return authAxios.get(`${this.baseUrlFxExamApplication}/fx-exam-students-by-teacher-mail/${email}`);
    }


    static fetchFxExamApplicationData(params:FetchFxExamApplicationDataParams) {
        return authAxios.get(`${this.baseUrlFxExamApplication}/fx-exam-students-filter1`, { params });
    }

    static approveAllApplicationsByFilterByOfficeRegistrar(queryParams: string) {
        return authAxios.post(`${this.baseUrlFxExamApplication}/approve-applications-by-filter-office-registrar?${queryParams}` )
    }
    static declineAllApplicationsByFilterByOfficeRegistrar(queryParams: string) {
        return authAxios.post(`${this.baseUrlFxExamApplication}/decline-applications-by-filter-office-registrar?${queryParams}` )
    }

    static fetchFxExamApplicationExcel(params:FetchFxExamApplicationDataParams) {
        return authAxios.get(`${this.baseUrlFxExamApplication}/fx-exam-students-filter`, {
            params,
            responseType: 'blob'
        });
    }

    static moveToSecondStage(id: number) {
        return authAxios.put(`${this.baseUrlFxExamApplication}/move-to-second?id=${id}`);
    }

    static moveToThirdStage(id: number) {
        return authAxios.put(`${this.baseUrlFxExamApplication}/move-to-third?id=${id}`);
    }

    static declineApplicationByOfficeRegistrar(id: number) {
        return authAxios.put(`${this.baseUrlFxExamApplication}/decline-application-office-registrar?id=${id}`);
    }

    static acceptApplicationByOfficeRegistrar(id: number) {
        return authAxios.put(`${this.baseUrlFxExamApplication}/accept-application-office-registrar?id=${id}`);
    }

    static addComment(id: number,  comment: string) {
        return authAxios.put(`${this.baseUrlFxExamApplication}/add-comment?id=${id}&comment=${comment}`);
    }

    static uploadFileFxApplication(formData: any) {
        return authAxios.post('/astanait-office-module/api/v1/fx-exam-application/upload-fx-exam-application', formData)
      }
    
    static createFxApplication(fileId: number, disciplineId: number, teacherId: number,attendance: number,midterm: number,endterm: number,term: number,finalGrade: number) {
        authAxios.post("/astanait-office-module/api/v1/fx-exam-application/create-fx-exam-application", { fileId, disciplineId,teacherId,attendance,midterm,endterm,term,finalGrade })
        .then(() => {
            successNotification('Application accepted for consideration');
        })
        .catch((error) => {
            if (error.response && error.response.status === 403) {
                  errorNotification('Заявки принимаются с 8:00 до 19:00.');
            } else {
                errorNotification('Произошла ошибка при отправке заявки.', error.response?.status);
                console.error('Error:', error);
            }
        });

    }
    

}