import React from 'react';
import { Form, InputRef, Table, Typography } from 'antd';
import { DisciplineDtoResponse } from '../../../../interfaces/DisciplineDtoResponse';
import { ComponentDtoResponse } from '../../../../interfaces/EducationalPrograms/ComponentDtoResponse';
import { CycleDtoResponse } from '../../../../interfaces/EducationalPrograms/CycleDtoResponse';
import { CurriculumService } from '../../../../services/CurriculumService';
import GenericForm from '../GenericForm';
import { getColumnSearchProps } from '../../../../common/getColumnSearchProps';
import { successNotification } from '../../../../helpers/successNotification';

const { Title } = Typography;

interface Props {
  rerender: (count: number) => void;
  educationalProgramId: number;
  disciplines: DisciplineDtoResponse[];
  cycles: CycleDtoResponse[];
  components: ComponentDtoResponse[];
}

const ElectiveDisciplinesForm = ({ rerender, educationalProgramId, disciplines, cycles, components }: Props) => {
  const [form] = Form.useForm();
  const inputRef = React.useRef() as React.Ref<InputRef>;

  const [selectedElectiveDisciplines, setSelectedElectiveDisciplines] = React.useState<number[]>([]);

  const columns = [
    {
      title: '№',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Наименование дисциплины',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', inputRef),
    },
    {
      title: 'Код',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Количество кредитов',
      dataIndex: 'credits',
      key: 'credits',
    },
  ];

  const onFinish = (values: any) => {
    const { numberOfTrimester, year, cycleId, componentId } = values;

    if (selectedElectiveDisciplines.length) {
      CurriculumService.createCurriculumByGroup({
        disciplineId: selectedElectiveDisciplines,
        educationProgramId: educationalProgramId,
        year: parseInt(year),
        numberOfTrimester,
        cycleId,
        componentId,
      })
        .then(() => {
          successNotification('Данные успешно сохранены');
          rerender(1);
        });
    }
  };

  return (
    <>
      <Title level={3}>Выбор Элективных Дисциплин</Title>
      <Table
        pagination={{ defaultPageSize: 30, showSizeChanger: false }}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys) =>
            setSelectedElectiveDisciplines(
              selectedRowKeys.map((selectedRowKey) => parseInt(selectedRowKey.toString()))
            ),
          selectedRowKeys: selectedElectiveDisciplines,
        }}
        columns={columns}
        dataSource={disciplines
          // .filter((discipline) => discipline.id !== selectedMainDiscipline)
          .map((discipline) => ({
            key: discipline.id,
            number: discipline.id,
            name: `${discipline.titleRu} / ${discipline.titleKz} / ${discipline.titleEn}`,
            credits: discipline.volumeCredits,
            code: discipline.code,
          }))}
      />
      <GenericForm cycles={cycles} components={components} form={form} onFinish={onFinish} />
    </>
  );
};

export default ElectiveDisciplinesForm;
