import { Tag } from 'antd';
import React from 'react'


interface Props {
    statusNameRu: string;
}

const tagStyle: React.CSSProperties = {
    width: "100%",
    maxWidth: "150px",
    whiteSpace: "normal",
    overflowWrap: "break-word",
};

const ApplicationStatusTag = ({ statusNameRu }: Props) => {
    switch (statusNameRu) {
        case "На рассмотрений":
            return (
                <Tag style={tagStyle} color="blue">
                    На рассмотрении
                </Tag>
            );
        case "Одобрена":
            return (
                <Tag style={tagStyle} color="green">
                    Одобрена
                </Tag>
            );
        case "Отклонена":
            return (
                <Tag style={tagStyle} color="red">
                    Отклонена
                </Tag>
            );
        case "На доработку":
            return (
                <Tag style={tagStyle} color="orange">
                    На доработку
                </Tag>
            );
        default:
            return (
                <Tag style={tagStyle} color="default">
                    Неизвестный статус
                </Tag>
            );
    }
}

export default ApplicationStatusTag