import { useEffect, useState } from 'react';
import { Card, Table, Typography, Button, Modal, Calendar } from 'antd';
import useProfilePage from '../ProfilePage/useProfilePage';
import './CustomCalendar.css';
import UserService from '../../../services/userService';
import windowBreakpointWidth from '../../../helpers/windowBreakpointWidth';
import useWindowDimensions from '../../../hooks/useWindowDimensions/useWindowDimensions';
import grantPermission from '../../../helpers/grantPermission';
import { ScheduleService } from '../../../services/student/ScheduleService';
interface SubjectItem {
    subject: string;
    tutor: string | null;
    group: string;
    room: string | null;
    classtime_time: string | null;
    classtime_day: string | null;
    lesson_type: string;
    days: string;
    groups: string;
    teamsMeetingUrl: string;
}


interface Props {
    userId?: number;
}




const TeacherSchedulePage = ({ userId }: Props) => {
    const [data, setData] = useState<SubjectItem[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState<SubjectItem | null>(null);
    const daysOfWeek = ["d1", "d2", "d3", "d4", "d5", "d6"];
    const timeSlots = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n"];
    const isMobile = window.innerWidth < 768;
    const uniqueData: SubjectItem[] = [];
    const seenTimeDayCombinations: Set<string> = new Set();
    const groupedData: { [key: string]: SubjectItem[] } = {};
    const { loading } = useProfilePage(userId);
    const [profile, setProfile] = useState<any>();
    const { width } = useWindowDimensions();



    useEffect(() => {
        if (grantPermission('TEACHERS')) {
            setProfile(UserService.getCurrentUser());
        }
    }, []);


    const showModal = (subjectItem: SubjectItem) => {
        setSelectedSubject(subjectItem);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const tutorEmail = profile?.username;


    useEffect(() => {
        if (tutorEmail) {
            ScheduleService.getScheduleByTutor(tutorEmail)
                .then(response => {
                    const responseData = response.data.body;
                    const filteredData = responseData.filter((item: SubjectItem) => item.classtime_time && item.classtime_day);
                    const sortedData = filteredData.sort((a: SubjectItem, b: SubjectItem) => {
                        if (a.classtime_day && b.classtime_day) {
                            if (a.classtime_day === b.classtime_day) {
                                if (a.classtime_time && b.classtime_time) {
                                    return a.classtime_time.localeCompare(b.classtime_time);
                                }
                                return 0;
                            }
                            return a.classtime_day.localeCompare(b.classtime_day);
                        }
                        return 0;
                    });
                    setData(sortedData);
                })
        }
    }, [tutorEmail]);

    const getDayName = (dayId: string): string | null => {
        if (data.length === 0) {
            return null;
        }

        const daysObj = JSON.parse(data[0].days);
        const dayIndex = daysObj.days.findIndex((day: string) => day === dayId);
        return dayIndex !== -1 ? daysObj.daysname[dayIndex] || dayId : dayId;
    };


    data.forEach(item => {
        const timeDayCombination = `${item.classtime_time}_${item.classtime_day}_${item.subject}`;
        if (!seenTimeDayCombinations.has(timeDayCombination)) {
            uniqueData.push(item);
            seenTimeDayCombinations.add(timeDayCombination);
        }
    });


    uniqueData.forEach(item => {
        if (item.classtime_day) {
            if (!groupedData[item.classtime_day]) {
                groupedData[item.classtime_day] = [];
            }
            groupedData[item.classtime_day].push(item);
        }
    });


    const columns = [
        {
            title: 'Time',
            dataIndex: 'classtime_time',
            key: 'classtime_time',
            align: 'center' as const,

            render: (classtime_time: string | null) => {
                const daysObj = JSON.parse(data[0].days);
                const timeObj = daysObj.time.find((time: any) => time.id === classtime_time);
                return timeObj ? `${timeObj.start} - ${timeObj.finish}` : classtime_time;
            },
            width: '15%',
        },
        // {
        //   title: 'Subject',
        //   dataIndex: 'subject',
        //   key: 'subject',
        //   render: (subject: string, record: SubjectItem, index: number) => {
        //     const sameTimeSubjects = data.filter(
        //       item =>
        //         item.classtime_time === record.classtime_time &&
        //         item.classtime_day === record.classtime_day
        //     );
        //     if (sameTimeSubjects.length > 3) {
        //     return ( 
        //       <Collapse>
        //         <Collapse.Panel header={subject} key="oneTimeSubject">
        //           {sameTimeSubjects.map(item => (

        //             <div key={item.subject}>
        //                <Card size='small'>
        //               <strong>Discipline: </strong>
        //               {item.subject}
        //               <br />
        //               <strong>Type: </strong>
        //               {item.lesson_type}
        //               <br />
        //               <strong>Classroom: </strong>
        //               {item.room}
        //               <br />
        //               <strong>Lecturer: </strong>
        //               {item.tutor}

        //               {/* <br />
        //           <strong>Time: </strong>
        //           {getFormattedTime(record.classtime_time)} */}
        //             </Card>

        //             </div>

        //           ))}
        //         </Collapse.Panel>
        //       </Collapse>

        //     );} else {
        //       return (
        //         <>
        //           <strong>Discipline: </strong>
        //           {subject}
        //           <br />
        //           <strong>Type: </strong>
        //           {record.lesson_type}
        //           <br />
        //           <strong>Classroom: </strong>
        //           {record.room}
        //           <br />
        //           <strong>Lecturer: </strong>
        //           {record.tutor}

        //         </>
        //       );
        //     }
        //   },
        // },


        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',

            render: (subject: string, record: SubjectItem, index: number) => {
                const sameTimeSubjects = data.filter(
                    item =>
                        item.classtime_time === record.classtime_time &&
                        item.classtime_day === record.classtime_day
                );
                if (sameTimeSubjects.length > 3) {
                    return (
                        <Button type="link" onClick={() => showModal(record)}>
                            {subject}
                        </Button>
                    );
                } else {
                    return (

                        <>
                            <strong>Discipline: </strong>
                            {subject}
                            <br />
                            <strong>Type: </strong>
                            {record.lesson_type}
                            <br />
                            <strong>Classroom: </strong>
                            {record.room}
                            <br />
                            <strong>Lecturer: </strong>
                            {record.tutor}
                            <br />
                            <strong>Groups: </strong>
                            {record.groups}
                            <br />
                            {record.teamsMeetingUrl !== null && (
                                <div>
                                    <strong>Link: </strong>
                                    <a href={record.teamsMeetingUrl}>
                                        Click here
                                    </a>
                                </div>
                            )}

                        </>
                    );
                }
            },
        },
    ];







    const getTimeRange = (timeId: string | null) => {
        if (!timeId) return "N/A";
        const daysObj = data[0]?.days ? JSON.parse(data[0].days) : null;
        const timeObj = daysObj?.time.find((time: any) => time.id === timeId);
        return timeObj ? `${timeObj.start} - ${timeObj.finish}` : "N/A";
    };

    const dataSource = timeSlots.map(timeSlot => {
        const lessons = daysOfWeek.map(day => {
            const dayLessons = data.filter(
                item => item.classtime_day === day && item.classtime_time === timeSlot
            );
            return dayLessons.length > 0 ? dayLessons : [];
        });
        return {
            key: `timeSlot_${timeSlot}`,
            time: getTimeRange(timeSlot),
            ...Object.fromEntries(
                daysOfWeek.map((day, i) => [`day_${i}`, lessons[i]])
            ),
        };
    });



    const desktopColumns = [
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            width: '4%',
            align: 'center' as const,
        },
        ...daysOfWeek.map((day, index) => ({
            title: getDayName(day) || day,
            dataIndex: `day_${index}`,
            key: `day_${index}`,
            render: (lessons: SubjectItem[]) => {
                const similarLessons = lessons.filter(lesson =>
                    lessons.some(
                        item =>
                            item.subject === lesson.subject &&
                            item.classtime_time === lesson.classtime_time &&
                            item.classtime_day === lesson.classtime_day &&
                            item !== lesson
                    )
                );


                if (similarLessons.length > 0) {
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                            type="link"
                            onClick={() => showModal(lessons[0])}
                            style={{
                                padding: 0,
                            }}
                        >
                            {lessons[0].subject}
                        </a>
                    );
                } else {
                    return (
                        <div>
                            {lessons.map((lesson, lessonIndex) => (
                                <div key={lessonIndex}
                                >
                                    <div >
                                        <strong>Discipline: </strong>
                                        {lesson.subject}
                                        <br />
                                        <strong>Type: </strong>
                                        {lesson.lesson_type}
                                        <br />
                                        <strong>Classroom: </strong>
                                        {lesson.room}
                                        <br />
                                        <strong>Lecturer: </strong>
                                        {lesson.tutor}
                                        <br />
                                        <strong>Groups: </strong>
                                        {lesson.groups}
                                        <br />
                                        {lesson.teamsMeetingUrl !== null && (
                                            <div>
                                                <strong>Link: </strong>
                                                <a href={lesson.teamsMeetingUrl}>
                                                    Click here
                                                </a>
                                            </div>
                                        )}
                                    </div>

                                    {lessonIndex < lessons.length - 1 && <div style={{ margin: '10px 0' }}></div>}
                                </div>
                            ))}
                        </div>
                    );
                }
            },
            width: '15.5%',
        })),
    ];


    const scheduleContent = data.length > 0 ? (
        <div>
            <Typography.Title level={1}>
                Schedule for {tutorEmail}
            </Typography.Title>

            {isMobile ? (
                <div className="mobile-table">
                    {Object.keys(groupedData).map(dayId => (
                        <div key={dayId}>
                            <Typography.Title level={3}>{getDayName(dayId)}</Typography.Title>
                            <Table
                                dataSource={groupedData[dayId]}
                                columns={columns}
                                style={{ marginBottom: '30px', width: '100%' }}
                                loading={loading}
                                pagination={false}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="desktop-table">
                    <Table
                        dataSource={dataSource}
                        columns={desktopColumns}
                        //style={{ width:'90%' }}
                        loading={loading}
                        pagination={false}
                        bordered
                        scroll={{ x: '100px' }}
                    />
                </div>
            )}
        </div>

    ) : (<>
        <Typography.Title level={1}>
            Calendar
        </Typography.Title>
        <Calendar fullscreen={width > windowBreakpointWidth.xl} />
    </>
    );



    return (
        <div>
            {scheduleContent}

            <Modal
                title={selectedSubject?.subject}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 300px)' }}
                footer={[
                    <Button key="ok" type="primary" onClick={handleOk}>
                        Close
                    </Button>,
                ]}
            >
                {selectedSubject && (
                    <>
                        {data
                            .filter(
                                item =>
                                    item.classtime_time === selectedSubject.classtime_time &&
                                    item.classtime_day === selectedSubject.classtime_day
                            )
                            .map((item, lessonIndex) => (
                                <div key={item.subject}>
                                    <Card size="small">
                                        <strong>Discipline: </strong>
                                        {item.subject}
                                        <br />
                                        <strong>Type: </strong>
                                        {item.lesson_type}
                                        <br />
                                        <strong>Classroom: </strong>
                                        {item.room}
                                        <br />
                                        <strong>Lecturer: </strong>
                                        {item.tutor}
                                        <br />
                                        <strong>Groups: </strong>
                                        {item.groups}
                                        <br />
                                        {item.teamsMeetingUrl !== null && (
                                            <div>
                                                <strong>Link: </strong>
                                                <a href={item.teamsMeetingUrl}>
                                                    Click here
                                                </a>
                                            </div>
                                        )}
                                    </Card>
                                    {lessonIndex < data.length - 1 && (
                                        <div style={{ margin: '10px 0' }}></div>
                                    )}
                                </div>
                            ))}
                    </>
                )}
            </Modal>
        </div>
    );
};

export default TeacherSchedulePage;

