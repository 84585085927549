import { Button, DatePicker, Form, FormInstance, Input, Rate, Select, Slider, Upload, UploadProps } from 'antd';
import React from 'react'
import { RcFile } from 'antd/lib/upload';
import { StudentAcademicMobilityCompetitionInfoTypeDto } from '../../../../interfaces/AcademicMobilityCompetitionDto';
import { AbiturCatalogDtoResponse } from '../../../../interfaces/AbiturCatalogDtoResponse';
import { errorNotification } from '../../../../helpers/errorNotification';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { StudentService } from '../../../../services/StudentService';
import { TeacherService } from '../../../../services/TeacherService';
import { TeacherPublicDtoResponse } from '../../../../interfaces/Teacher/TeacherPublicDtoResponse';
import { AcademicMobilityCompetitionService } from '../../../../services/AcademicMobilityCompetitionService';

interface DataTypeComponentProps {
    type: StudentAcademicMobilityCompetitionInfoTypeDto;
    form: FormInstance<any>;
}

const MobilityDataType = ({ type, form }: DataTypeComponentProps) => {
    const [fileList, setFileList] = React.useState<any>([]);

    const props: UploadProps = {
        accept: ".pdf",
        fileList: fileList,
        beforeUpload: (file: RcFile) => {
            const isPDF = file.type === 'application/pdf';
            const reader = new FileReader();

            if (!isPDF) {
                errorNotification(`${file.name} is not a pdf file!`, 'Please, upload only pdf files!');
                return Upload.LIST_IGNORE;
            }

            if (file) {
                reader.readAsDataURL(file);
            }
            reader.onload = (e) => {
                const base64String = (reader.result as string).split(',')[1];
                form.setFieldsValue({ [type.id]: base64String })
            };

            setFileList([file]);

            return false;
        },
        onRemove: () => {
            setFileList([]);
            form.setFieldsValue({ [type.id]: "" })
        },
    };

    const [selectData, setSelectData] = React.useState<Record<string, AbiturCatalogDtoResponse[]>>({});
    const labelName = type.nameEn;

    const onSearch = (value: string) => {
        TeacherService.getTeacherByEmail(value)
            .then(({ data }) => {
                const newData: AbiturCatalogDtoResponse[] = [{
                    id: data.id,
                    name: data.surnameKz + ' ' + data.nameKz + ' ' + data.patronymicKz
                }]
                setSelectData(prevData => ({ ...prevData, [type.id]: newData }))
            });
    }

    React.useEffect(() => {
        if (type.dataType === 'object') {
            switch (type.prefix) {
                case "social_status": {
                    StudentService.getStudentSocialStatusList()
                        .then(({ data }) => {
                            setSelectData(prevData => ({ ...prevData, [type.id]: data }))
                        });

                    break;
                }
                case "award_type": {
                    StudentService.getAwardTypes()
                        .then(({ data }) => {
                            setSelectData(prevData => ({ ...prevData, [type.id]: data }))
                        });

                    break;
                }
                case "eng_level": {
                    AcademicMobilityCompetitionService.getLanguageLevels()
                        .then(({ data }) => {
                            setSelectData(prevData => ({ ...prevData, [type.id]: data }))
                        });
                    break;
                }
                default: {
                    return setSelectData({});
                }
            }
        }
    }, [type])

    switch (type.dataType) {
        case "object":
            switch (type.prefix) {
                case "teacher_id":
                    return (
                        <>
                            <p>{labelName}</p>
                            <Input.Search
                                enterButton="Search"
                                required
                                size="large"
                                onSearch={(value) => { onSearch(value) }}
                                style={{ marginBottom: 10 }}
                            />
                            {selectData[type.id] && (
                                <Form.Item
                                    name={type.id}
                                    rules={[{ required: type.required, message: 'Please select!' }]}
                                    getValueFromEvent={(selectedOption) => {
                                        const selectedItem = selectData[type.id].find((item: any) => item.id === selectedOption);
                                        return selectedItem ? JSON.stringify({ id: selectedItem.id, name: selectedItem.name, }) : '';
                                    }}
                                    getValueProps={(i) => i ? { value: JSON.parse(i).name } : { value: '' }}
                                >
                                    <Select
                                        placeholder="Select one option"
                                    >
                                        {selectData[type.id]?.map((data: any, i: number) => (
                                            <Select.Option key={i} value={data.id}>
                                                {data.name || data}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                        </>
                    );
                default:
                    return (
                        <Form.Item
                            label={labelName}
                            name={type.id}
                            rules={[{ required: type.required, message: 'Please select!' }]}
                            getValueFromEvent={(selectedOption) => {
                                const selectedItem = selectData[type.id].find((item: any) => item.id === selectedOption);
                                return selectedItem ? JSON.stringify({ id: selectedItem.id, name: selectedItem.name, }) : '';
                            }}
                            getValueProps={(i) => i ? { value: JSON.parse(i).name } : { value: '' }}
                        >
                            <Select placeholder="Select one option">
                                {selectData[type.id]?.map((data: any, i: number) => (
                                    <Select.Option key={i} value={data.id}>
                                        {data.name || data}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    );
            }
        case "decimal":
            return (
                <Form.Item
                    label={labelName}
                    name={type.id}
                    rules={[{ required: type.required, message: 'Please input!' }]}
                >
                    <Input type="number" step={"any"} placeholder="Write input" />
                </Form.Item>
            )
        case "string":
            return (
                <Form.Item
                    label={labelName}
                    name={type.id}
                    rules={[{ required: type.required, message: 'Please input!' }]}
                >
                    <Input placeholder="Write input" />
                </Form.Item>
            )
        case "file":
            return (
                <Form.Item
                    label={labelName}
                    name={type.id}
                    rules={[{ required: type.required, message: 'Please upload a file!' }]}>
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload >
                </Form.Item>
            )
        case "date":
            return (
                <Form.Item
                    label={labelName}
                    name={type.id}
                    getValueProps={(i) => i ? { value: moment(i) } : { value: '' }}
                    getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                    rules={[{ required: type.required, message: 'Please input!' }]}
                >
                    <DatePicker placeholder="Выберите дату" style={{ width: "100%" }} allowClear={false} />
                </Form.Item>
            )
        case "select_int_5":
            return (
                <Form.Item
                    label={labelName}
                    name={type.id}

                    rules={[{ required: type.required, message: 'Please select!' }]}
                >
                    <Rate value={2} character={({ index = 0 }) => index + 1} />
                </Form.Item>
            )
        default:
            return null
    }
}

export default MobilityDataType