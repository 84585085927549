import { Typography, Form, Select, Input, DatePicker, Checkbox, Button, Space } from 'antd';
import { useEffect, useState } from 'react';

import { ForeignUniversitiesService } from '../../../services/ForeignUniversitiesService';
import { ForeignUniversitiesDtoRequest } from '../../../interfaces/ForeignUniversitiesDtoRequest';
import PageWrapper from '../../../ui/PageWrapper';
import BackButton from '../../../ui/BackButton';
import { errorNotification } from '../../../helpers/errorNotification';
import { successNotification } from '../../../helpers/successNotification';
import { CountriesService } from '../../../services/CountriesService';
import { CountriesDtoRequest } from '../../../interfaces/CountriesDtoRequest';


const { Title } = Typography;
const { Option } = Select;

interface Props {
    universityId?: number;
}

const ForeignUniversityCreationPage = ({ universityId }: Props) => {
    const [form] = Form.useForm();
    const [countries, setCountries] = useState<CountriesDtoRequest[]>([]);
    const [searchResults, setSearchResults] = useState<CountriesDtoRequest[]>([]);
    const [showResults, setShowResults] = useState<boolean>(false);

    const styles = { width: '800px', margin: '0 auto' };

    useEffect(() => {
        CountriesService.getAllCountires()
            .then((response) => setCountries(Array.isArray(response.data) ? response.data : [response.data]))
            .catch((error) => console.error(error));
    }, []);

    const handleFormValuesChange = (changedValues: any) => {
        const [fieldName] = Object.keys(changedValues);
        const value = changedValues[fieldName];

        if (fieldName === 'countryId' && typeof value === 'string') {
            const filteredCountries = countries.filter(country =>
                country.nameEn.toLowerCase().includes(value.toLowerCase())
            );
            setSearchResults(filteredCountries);
            setShowResults(true);
        }
    };

    const handleCountrySelect = (value: number) => {
        form.setFieldsValue({ countryId: value });
        setShowResults(false);
    };

    useEffect(() => {
        if (
            universityId
        ) {
            ForeignUniversitiesService.getForeignUniversitiesById(universityId)
                .then(({ data }) => {

                    form.setFieldsValue({
                        nameRu: data.nameRu,
                        nameKz: data.nameKz,
                        nameEn: data.nameEn,
                        countryId: data.countryId,
                        cityNameRu: data.cityNameRu,
                        cityNameKz: data.cityNameKz,
                        cityNameEn: data.cityNameEn,
                    });
                })
                .catch((err) => errorNotification('Не удалось получить данные', err.response?.status));
        }
    }, [

        form,
    ]);

    const onFinish = (foreignUniversities: ForeignUniversitiesDtoRequest) => {
        universityId
            ? ForeignUniversitiesService.updateForeignUniversity(universityId, foreignUniversities)
                .then(() => successNotification('Данные успешно обновлены'))
                .catch((err) => errorNotification('Не удалось обновить данные', err.response?.status))
            : ForeignUniversitiesService.createForeignUniversity(foreignUniversities)
                .then(() => successNotification('Данные успешно сохранены'))
                .catch((err) => errorNotification('Не удалось сохранить данные', err.response?.status));
    };

    return (
        <PageWrapper>
            <BackButton />
            <header style={styles}>
                <Title level={3}>
                    {universityId ? 'Editing University Info' : 'Adding a University'}
                </Title>
            </header>
            <Form
                layout="vertical"
                size="large"
                form={form}
                style={styles}
                onFinish={onFinish}
                onValuesChange={handleFormValuesChange}
            // onReset={handleFormReset}
            >
                <Title level={4}>Name of the University</Title>
                <Form.Item
                    shouldUpdate
                    label="in Kazakh"
                    name="nameKz"
                    rules={[
                        {
                            required: false,
                            message: 'Введите название университета на казахском языке',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="in Russian"
                    name="nameRu"
                    rules={[
                        {
                            required: false,
                            message: 'Введите название университета на русском языке',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="In English"
                    name="nameEn"
                    rules={[
                        {
                            required: true,
                            message: 'Введите название университета на английском языке',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Title level={4}>Name of the City</Title>
                <Form.Item
                    shouldUpdate
                    label="in Kazakh"
                    name="cityNameKz"
                    rules={[
                        {
                            required: false,
                            message: 'Введите название города на казахском языке',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="in Russian"
                    name="cityNameRu"
                    rules={[
                        {
                            required: false,
                            message: 'Введите название города на русском языке',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="In English"
                    name="cityNameEn"
                    rules={[
                        {
                            required: true,
                            message: 'Введите название города на английском языке',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Country"
                    name="countryId"
                    rules={[
                        {
                            required: false,
                            message: 'Введите страну на английском языке',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Выберите страну"
                        onSearch={(value) => handleFormValuesChange({ countryId: value })}
                        onFocus={() => handleFormValuesChange({ countryId: '' })}
                        onBlur={() => setShowResults(false)}
                        onSelect={(value) => handleCountrySelect(Number(value))}
                        filterOption={false}
                    >
                        {showResults
                            ? searchResults.map((country) => (
                                <Option key={country.id} value={country.id}>
                                    {country.nameEn}
                                </Option>
                            ))
                            : countries.map((country) => (
                                <Option key={country.id} value={country.id}>
                                    {country.nameEn}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </PageWrapper>
    );
};

export default ForeignUniversityCreationPage;
