import { FiltersOptionsDtoResponse } from '../../../../interfaces/FIltersOptionsDtoResponse';

import { Select } from 'antd';

type Options = FiltersOptionsDtoResponse[];

const { Option } = Select;

interface Props {
  placeholder: string;
  options: Options;
  selectedOption: number | undefined;
  onSelect: (value: number) => void;
  onClear?: () => void;
}

const SelectOptionsFilter = ({ placeholder, options, selectedOption, onSelect, onClear }: Props) => {
  return (
    <Select
      style={{ width: 400 }}
      placeholder={placeholder}
      value={selectedOption}
      onSelect={onSelect}
      onClear={onClear}
      allowClear={!!onClear}
    >
      {options.map((option) => (
        <Option key={option.id} value={option.id}>
          {option.option}
        </Option>
      ))}
    </Select>
  );
};

export default SelectOptionsFilter;
