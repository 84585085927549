import React from 'react'
import Page from '../../../../ui/Page'
import { Descriptions, Space, Table } from 'antd'
import useStudentDiploma from './useStudentDiploma';

const StudentDiplomaSelect = () => {

    const {
        setting,
        selectedThesis,
        availableThesis,
        isAvailableThesisLoading,
        columns,
        handleStatus,
        renderAlert
    } = useStudentDiploma();

    return (
        <Page>
            <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
                {setting && renderAlert(setting)}
                {selectedThesis &&
                    <Descriptions title="Selected Diploma" bordered column={1} layout='horizontal' size='small'>
                        <Descriptions.Item label="Diploma's thesis">
                            <Space direction='vertical'>
                                {selectedThesis?.thesis.titleRu}
                                {selectedThesis?.thesis.titleKz}
                                {selectedThesis?.thesis.titleEn}
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Supervisor">
                            {selectedThesis?.thesis.supervisor.surnameEn} {selectedThesis?.thesis.supervisor.nameEn}
                        </Descriptions.Item>
                        <Descriptions.Item label="Type">
                            {selectedThesis?.thesis.type.nameEn}
                        </Descriptions.Item>
                        <Descriptions.Item label="Required Students Number">
                            {selectedThesis?.thesis.requiredStudentsNumber}
                        </Descriptions.Item>
                        <Descriptions.Item label="Defence Year">
                            {selectedThesis?.thesis.defenseYear}
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                            {handleStatus(selectedThesis?.thesis.status.nameEn)}
                        </Descriptions.Item>
                    </Descriptions>
                }
                <Table
                    bordered
                    dataSource={availableThesis}
                    columns={columns}
                    loading={isAvailableThesisLoading}
                    rowKey={(record) => record.id}
                    scroll={{ x: 800 }}
                    rowClassName={(record) => record.id === selectedThesis?.thesis.id ? "ant-table-row-selected" : ""}
                    pagination={{
                        pageSize: 20,
                    }}
                />
            </Space>
        </Page>
    )
}

export default StudentDiplomaSelect