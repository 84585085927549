import { Input } from 'antd';

interface Props {
  searchQuery?: string;
  onSearch: (value: string) => void;
  placeholder?: string;
}

const SearchFilter = ({ searchQuery, onSearch, placeholder }: Props) => {
  return (
    <Input.Search
      value={searchQuery}
      placeholder={placeholder ? placeholder : 'Введите Название Дисциплины'}
      onChange={(event) => onSearch(event.target.value)}
      style={{ width: 300 }}
      enterButton
    />
  );
};

export default SearchFilter;
