import axios from 'axios';
import authAxios from '../common/authAxios';
import { Constants } from '../common/constants';
import { EducationDocumentTypeDtoResponse } from '../interfaces/EducationDocumentTypeDtoResponse';
import { EntranceExamTypeDtoResponse } from '../interfaces/EntranceExamTypeDtoResponse';
import { StudentAdditionalInfoDtoResponse } from '../interfaces/Student/StudentAdditionalInfoDtoResponse';
import { StudentProfileDtoRequest } from '../interfaces/Student/StudentProfileDtoRequest';
import { StudentProfileDtoResponse } from '../interfaces/Student/StudentProfileDtoResponse';
import { UserDtoResponse } from '../interfaces/UserDtoResponse';
import { UserRolesDtoResponse } from '../interfaces/UserRolesDtoResponse';

class UserService {
  static baseURL = Constants.API_BASE_URL;

  static getCurrentUser() {
    return JSON.parse(localStorage.getItem('user') || '');
  }

  static switchRoles(changeTo: string) {
    const user = this.getCurrentUser();
    user.baseRole = changeTo;
    localStorage.setItem('user', JSON.stringify(user));
  }

  static logOut() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    window.location.href = '/login';
  }

  static getProfileByPrincipal() {
    return authAxios.get<StudentProfileDtoResponse>('/astanait-student-module/api/v1/student/profile/principal');
  }

  static getProfileById(userId: number) {
    return authAxios.get<StudentProfileDtoResponse>(
      `/astanait-student-module/api/v1/student/profile/user-id/${userId}`
    );
  }

  static updateStudentProfile(studentProfile: StudentProfileDtoRequest) {
    return authAxios.put('/astanait-student-module/api/v1/student/profile/update', studentProfile);
  }

  static getEntranceExamTypes() {
    return authAxios.get<EntranceExamTypeDtoResponse[]>('/astanait-student-module/api/v1/student/entrance-exam/types');
  }

  static getPreviousEducationDocumentTypes() {
    return authAxios.get<EducationDocumentTypeDtoResponse[]>(
      '/astanait-student-module/api/v1/student/education-document/types'
    );
  }

  static getStudentAdditionalInfo() {
    return authAxios.get<StudentAdditionalInfoDtoResponse[]>(
      '/astanait-student-module/api/v1/student/profile/get-student-additional-info'
    );
  }

  static getUserInfo(userId?: number) {
    return axios.get<UserDtoResponse>(
      `${this.baseURL}/astanait-authorization-module/api/v1/auth/get-user-by-id?user_id=${userId}`
    );
  }

  static getRolesById(userId?: number) {
    return authAxios.get<UserRolesDtoResponse[]>(
      `${this.baseURL}/astanait-authorization-module/api/v1/auth/get-role/${userId}`
    );
  };
  static addUserAgreement(agreeWithUserAgreement: boolean) {
    return authAxios.post(`${this.baseURL}/astanait-authorization-module/api/v1/auth/set-agree_with_user_agreement?agree_with_user_agreement=${agreeWithUserAgreement}`);
  }
}
export default UserService;
