import { Table, Tag } from "antd"
import { TeacherScientificProjectsDtoResponse } from "../../../../interfaces/Teacher/TeacherScientificProjectsDtoResponse"


interface Props {
    dataSource?: TeacherScientificProjectsDtoResponse[];
}

const PublicTeacherScientificProjectsTable = ({ dataSource }: Props) => {
    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Full Information: Title, Supervisor (PI), funding organisation',
            dataIndex: 'information',
            key: 'information',
        },
        {
            title: 'From',
            dataIndex: 'yearFrom',
            key: 'yearFrom',
        },
        {
            title: 'To',
            dataIndex: 'yearTo',
            key: 'yearTo',
        },
        {
            title: 'Budget',
            dataIndex: 'budget',
            key: 'budget',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (record: any) => {
                switch (record) {
                    case 'SUPERVISOR':
                        return <Tag color={'blue'}>{'Супервайзер'}</Tag>;
                    case 'RESEARCHER':
                        return <Tag color={'blue'}>{'Исследователь'}</Tag>;
                    case 'PROJECT_RESPONSIBLE':
                        return <Tag color={'blue'}>{'Ответственный за проект'}</Tag>;
                    default:
                        return <></>;
                }
            },
        },
        {
            title: 'Status',
            dataIndex: 'current',
            key: 'current',
            render: (status: any) => {
                return <Tag color={status ? 'green' : 'geekblue'}>{status ? 'Active' : 'Сompleted'}</Tag>;
            },
        },
    ]

    return (
        <>
            <Table bordered
                columns={columns}
                dataSource={
                    dataSource
                }
                rowKey={(record) => record.id}
            />
        </>
    )
}

export default PublicTeacherScientificProjectsTable