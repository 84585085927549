import { Typography, Form, Select, Input, DatePicker, Checkbox, Button, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import { AcademicDegreeDtoResponse } from '../../../interfaces/AcademicDegreeDtoResponse';
import { DepartmentDtoResponse } from '../../../interfaces/DepartmentDtoResponse';
import { EducationLanguageDtoResponse } from '../../../interfaces/EducationalPrograms/EducationLanguageDtoResponse';
import { EducationProgramDtoRequest } from '../../../interfaces/EducationalPrograms/EducationProgramDtoRequest';
import { GroupOfEducationProgramDtoResponse } from '../../../interfaces/EducationalPrograms/GroupOfEducationProgramDtoResponse';
import { DisciplinesService } from '../../../services/DisciplinesService';
import { EducationalProgramsService } from '../../../services/EducationalProgramsService';
import PageWrapper from '../../../ui/PageWrapper';
import BackButton from '../../../ui/BackButton';
import { successNotification } from '../../../helpers/successNotification';

const { Title } = Typography;
const { Option } = Select;

interface Props {
  educationalProgramId?: number;
}

const EducationalProgramsCreationPage = ({ educationalProgramId }: Props) => {
  const [form] = Form.useForm();

  const [commonData, setCommonData] = React.useState<GroupOfEducationProgramDtoResponse[]>([]);

  // Иерархическая структура
  const [educationFields, setEducationFields] = React.useState<GroupOfEducationProgramDtoResponse[]>([]);
  const [trainingDirections, setTrainingDirections] = React.useState<GroupOfEducationProgramDtoResponse[]>([]);
  const [educationalProgramGroups, setEducationalProgramGroups] = React.useState<GroupOfEducationProgramDtoResponse[]>(
    []
  );

  const [languages, setLanguages] = React.useState<EducationLanguageDtoResponse[]>([]);
  const [departments, setDepartments] = React.useState<DepartmentDtoResponse[]>([]);
  const [academicDegrees, setAcademicDegrees] = React.useState<AcademicDegreeDtoResponse[]>([]);
  // const [expectedLearningOutcomes, setExpectedLearningOutcomes] = React.useState<any[]>([]);
  // const [disciplines, setDisciplines] = React.useState<DisciplineDtoResponse[]>([]);

  const [selectedEducationField, setSelectedEducationField] = React.useState<number | undefined>(undefined);
  const [selectedTrainingDirection, setSelectedTrainingDirection] = React.useState<number | undefined>(undefined);
  const [selectedEducationalProgramGroup, setSelectedEducationalProgramGroup] = React.useState<number | undefined>(
    undefined
  );

  // const [selectedExpectedLearningOutcomes, setSelectedExpectedLearningOutcomes] = React.useState<number[]>([]);
  // const [selectedDisciplines, setSelectedDisciplines] = React.useState<number[]>([]);

  const styles = { width: '800px', margin: '0 auto' };

  React.useEffect(() => {
    Promise.all([
      EducationalProgramsService.getEducationalProgramGroups(),
      DisciplinesService.getDepartments(),
      DisciplinesService.getAcademicDegrees(),
      EducationalProgramsService.getLanguages(),
    ])
      .then(([commonData, departments, academicDegrees, languages]) => {
        setCommonData(commonData.data);
        setDepartments(departments.data);
        setAcademicDegrees(academicDegrees.data);
        setLanguages(languages.data);
      });
  }, []);

  React.useEffect(() => {
    if (commonData.length) {
      setEducationFields(commonData.filter((educationField) => educationField.parent === null));
    }
  }, [commonData]);

  React.useEffect(() => {
    if (selectedEducationField && commonData.length) {
      setTrainingDirections(
        commonData.filter((trainingDirection) => trainingDirection.parent === selectedEducationField)
      );
    }
  }, [selectedEducationField, commonData]);

  React.useEffect(() => {
    if (selectedTrainingDirection && commonData.length) {
      setEducationalProgramGroups(
        commonData.filter((educationalProgramGroup) => educationalProgramGroup.parent === selectedTrainingDirection)
      );
    }
  }, [selectedTrainingDirection, commonData]);

  React.useEffect(() => {
    if (
      educationalProgramId &&
      commonData.length &&
      academicDegrees.length &&
      languages.length
      // && disciplines.length
    ) {
      EducationalProgramsService.getOneEducationalProgram(educationalProgramId)
        .then(({ data }) => {
          const trainingDirection = data.groupOfEducationProgram?.parent;
          const educationField = commonData.find((educationField) => educationField.id === trainingDirection)?.parent;

          setSelectedEducationField(educationField);
          setSelectedTrainingDirection(trainingDirection);
          setSelectedEducationalProgramGroup(data.groupOfEducationProgram?.id);

          form.setFieldsValue({
            educationField: educationField,
            trainingDirection: trainingDirection,
            groupOfEducationProgramId: data.groupOfEducationProgram?.id,
            titleKz: data.titleKz,
            titleRu: data.titleRu,
            titleEn: data.titleEn,
            descriptionKz: data.descriptionKz,
            descriptionRu: data.descriptionRu,
            descriptionEn: data.descriptionEn,
            typeOfEducationProgram: data.typeOfEducationProgram,
            levelByNRK: data.levelByNRK,
            levelByORK: data.levelByORK,
            distinctiveFeaturesKz: data.distinctiveFeaturesKz,
            distinctiveFeaturesRu: data.distinctiveFeaturesRu,
            distinctiveFeaturesEn: data.distinctiveFeaturesEn,
            departmentId: data.department?.id,
            academicDegreeId: data.academicDegree?.id,
            educationStartedDate: moment(data.educationStartedDate),
            volumeCredits: data.volumeCredits,
            educationLanguageId: data.educationLanguage?.id,
            approvalDateByAcademicCouncil: moment(data.approvalDateByAcademicCouncil),
            license: data.license,
            accreditation: data.accreditation,
            code: data.code
          });
        });
    }
  }, [
    educationalProgramId,
    academicDegrees,
    // disciplines,
    commonData,
    languages,
    form,
  ]);

  const handleFormValuesChange = (changedValues: any) => {
    const [fieldName] = Object.keys(changedValues);
    const value = changedValues[fieldName];

    switch (fieldName) {
      case 'educationField':
        setSelectedEducationField(value);
        break;
      case 'trainingDirection':
        setSelectedTrainingDirection(value);
        break;
      case 'groupOfEducationProgramId':
        setSelectedEducationalProgramGroup(value);
        break;
    }
  };

  const onFinish = (educationalProgram: EducationProgramDtoRequest) => {
    educationalProgram.status = '123';
    educationalProgramId
      ? EducationalProgramsService.updateEducationalProgram(educationalProgram, educationalProgramId)
        .then(() => successNotification('Данные успешно обновлены'))
      : EducationalProgramsService.createEducationalProgram(educationalProgram)
        .then(() => successNotification('Данные успешно сохранены'));
  };

  const onResetForm = () => {
    form.resetFields();
    setSelectedEducationField(undefined);
    setSelectedTrainingDirection(undefined);
    setSelectedEducationalProgramGroup(undefined);
  };

  return (
    <PageWrapper>
      <BackButton />
      <header style={styles}>
        <Title level={3}>
          {educationalProgramId ? 'Редактирование Образовательных Программ' : 'Создание Образовательных Программ'}
        </Title>
      </header>

      <Form
        layout="vertical"
        size="large"
        form={form}
        style={styles}
        onFinish={onFinish}
        onValuesChange={handleFormValuesChange}
      // onReset={handleFormReset}
      >
        <Form.Item
          label="Область образования"
          name="educationField"
          rules={[
            {
              required: true,
              message: 'Выберите область образования из списка',
            },
          ]}
        >
          <Select placeholder="Выберите область образования из списка" allowClear>
            {educationFields.map((educationField) => (
              <Option value={educationField.id}>{educationField.titleRu}</Option>
            ))}
          </Select>
        </Form.Item>

        {/* <Title level={4} style={{ paddingBottom: 20 }}>
          Формируемые результаты обучения
          <Button style={{ float: "right" }} type="primary">
            Создать новую запись
          </Button>
        </Title>
        <ExpectedLearningOutcomesWindow
          onMultipleSelect={(values) =>
            setSelectedExpectedLearningOutcomes(values)
          }
        /> */}

        {(selectedEducationField || selectedEducationalProgramGroup) && (
          <Form.Item
            label="Направление подготовки"
            name="trainingDirection"
            rules={[
              {
                required: true,
                message: 'Выберите направление подготовки из списка',
              },
            ]}
          >
            <Select placeholder="Выберите направление подготовки из списка" allowClear>
              {trainingDirections.map((trainingDirection) => (
                <Option value={trainingDirection.id}>{trainingDirection.titleRu}</Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {((selectedEducationField && selectedTrainingDirection) || selectedEducationalProgramGroup) && (
          <Form.Item
            label="Группа образовательных программ"
            name="groupOfEducationProgramId"
            rules={[
              {
                required: true,
                message: 'Выберите группу образовательных программ из списка',
              },
            ]}
          >
            <Select placeholder="Выберите группу образовательных программ из списка" allowClear>
              {educationalProgramGroups?.map((educationalProgramGroup) => (
                <Option value={educationalProgramGroup.id}>{educationalProgramGroup.titleRu}</Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {/* {(selectedEducationField &&
          selectedTrainingDirection && */}
        {selectedEducationalProgramGroup && (
          <>
            <Title level={4} style={{ paddingBottom: 20 }}>
              Сведения о дисциплинах
              <Button style={{ float: 'right' }} type="primary">
                Создать новую запись
              </Button>
            </Title>
            {/* <DisciplinesCatalogWindow
              onMultipleSelect={(values) => setSelectedDisciplines(values)}
            /> */}

            <Title level={4}>Название образовательной программы</Title>
            <Form.Item
              shouldUpdate
              label="На казахском языке"
              name="titleKz"
              rules={[
                {
                  required: true,
                  message: 'Введите название образовательной программы на казахском языке',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="На русском языке"
              name="titleRu"
              rules={[
                {
                  required: true,
                  message: 'Введите название образовательной программы на русском языке',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="На английском языке"
              name="titleEn"
              rules={[
                {
                  required: true,
                  message: 'Введите название образовательной программы на английском языке',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Title level={4}>Цель ОП</Title>
            <Form.Item
              label="На казахском языке"
              name="descriptionKz"
              rules={[
                {
                  required: true,
                  message: 'Введите цель ОП на казахском языке',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="На русском языке"
              name="descriptionRu"
              rules={[
                {
                  required: true,
                  message: 'Введите цель ОП на русском языке',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="На английском языке"
              name="descriptionEn"
              rules={[
                {
                  required: true,
                  message: 'Введите цель ОП на английском языке',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Код ОП"
              name="code"
              rules={[{ required: true, message: 'Введите код ОП' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Вид ОП"
              name="typeOfEducationProgram"
              rules={[{ required: true, message: 'Введите вид ОП' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Вид заявки"
              name="typeOfResponse"
              rules={[{ required: true, message: 'Введите вид заявки' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Уровень по НРК"
              name="levelByNRK"
              rules={[{ required: true, message: 'Введите уровень по НРК' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Уровень по ОРК"
              name="levelByORK"
              rules={[{ required: true, message: 'Введите уровень по ОРК' }]}
            >
              <Input />
            </Form.Item>

            <Title level={4}>Отличительные особенности ОП</Title>
            <Form.Item
              label="На казахском"
              name="distinctiveFeaturesKz"
              rules={[
                {
                  required: true,
                  message: 'Введите отличительные особенности ОП на казахском языке',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="На русском"
              name="distinctiveFeaturesRu"
              rules={[
                {
                  required: true,
                  message: 'Введите отличительные особенности ОП на русском языке',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="На английском"
              name="distinctiveFeaturesEn"
              rules={[
                {
                  required: true,
                  message: 'Введите отличительные особенности ОП на английском языке',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Кафедра"
              name="departmentId"
              rules={[
                {
                  required: true,
                  message: 'Выберите кафедру из списка',
                },
              ]}
            >
              <Select placeholder="Выберите кафедру из списка" allowClear>
                {departments?.map((department) => (
                  <Option value={department.id}>{department.titleRu}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Присуждаемая академическая степень"
              name="academicDegreeId"
              rules={[
                {
                  required: true,
                  message: 'Выберите присуждаемую академическую степень из списка',
                },
              ]}
            >
              <Select placeholder="Выберите присуждаемую академическую степень из списка" allowClear>
                {academicDegrees?.map((academicDegree) => (
                  <Option value={academicDegree.id}>{academicDegree.titleRu}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Срок обучения"
              name="educationStartedDate"
              rules={[{ required: true, message: 'Введите срок обучения' }]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              label="Объем кредитов"
              name="volumeCredits"
              rules={[{ required: true, message: 'Введите объем кредитов' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Язык обучения"
              name="educationLanguageId"
              rules={[
                {
                  required: true,
                  message: 'Выберите язык обучения из списка',
                },
              ]}
            >
              <Select placeholder="Выберите язык обучения из списка" allowClear>
                {languages?.map((language) => (
                  <Option value={language.id}>{language.titleRu}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Дата утверждения ОП на учебном совете"
              name="approvalDateByAcademicCouncil"
              rules={[
                {
                  required: true,
                  message: 'Выберите дату утверждения ОП на учебном совете',
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item initialValue={false} name="license">
              <Checkbox>Наличие приложения к лицензии на направление подготовки кадров</Checkbox>
            </Form.Item>

            <Form.Item initialValue={false} name="accreditation">
              <Checkbox>Наличие аккредитации ОП</Checkbox>
            </Form.Item>

            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Сохранить
                </Button>
                <Button htmlType="button" onClick={onResetForm}>
                  Сбросить форму
                </Button>
              </Space>
            </Form.Item>
          </>
        )}
      </Form>
    </PageWrapper>
  );
};

export default EducationalProgramsCreationPage;
