import Page from '../../../../ui/Page'
import { Button, Space, Table } from 'antd'
import useApplications from '../useApplications'
import { ColumnsType } from 'antd/lib/table'
import { ApplicationDtoResponse } from '../../../../interfaces/Applications/response/ApplicationDtoResponse'
import ApplicationStatusTag from '../ApplicationStatusTag'
import { useHistory } from 'react-router-dom'
import RowSpan from '../../../../ui/RowSpan'
import { PlusOutlined } from '@ant-design/icons'

const StudentApplicationPage = () => {
    const { data } = useApplications({ fetchAllApplications: true });
    const history = useHistory();

    const currentDay = new Date().getDay();

    // Check if today is Saturday (6) or Sunday (0)
    const isWeekend = currentDay === 0 || currentDay === 6;

    const columns: ColumnsType<ApplicationDtoResponse> = [
        {
            "title": "№",
            "dataIndex": "key",
            "key": "key",
            render(value, record, index) {
                return index + 1;
            },
        },
        {
            "title": "Application type",
            "dataIndex": "typeId",
            "key": "typeId",
            render(value, record, index) {
                return record.typeId.nameEn;
            },
        },
        {
            "title": "Current step",
            "dataIndex": "currentStep",
            "key": "currentStep",
        },
        {
            "title": "Status",
            "dataIndex": "statusId",
            "key": "statusId",
            "width": "10%",
            render(value, record, index) {
                return (<ApplicationStatusTag statusNameRu={record.statusId.nameRu} />);
            }
        }
    ];

    return (
        <Page title='Applications' subtitle='Page for applications'>
            <Space direction='vertical' style={{ width: "100%" }}>

                <RowSpan right={isWeekend ? (
                    <span style={{ color: 'red' }}>Application submission is not available on weekends</span>
                ) : (
                    <Button icon={<PlusOutlined />} onClick={() => history.push('/application/create')}>
                        Create the application
                    </Button>
                )}
                />
                <Table
                    columns={columns}
                    dataSource={data.applications}
                    loading={data.isLoadingApplications}
                    scroll={{ x: 1000 }}
                    rowKey={(record) => record.id.toString()}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                history.push(`/application/${record.id}`);
                            },
                            style: { cursor: 'pointer' },

                        };
                    }}
                />
            </Space>
        </Page>
    )
}

export default StudentApplicationPage