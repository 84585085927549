import { HomeOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { List, Space, Typography } from 'antd';
import { ContactsListInterface } from '../../../interfaces/ContactsList';
import ContactsListItem from './ContactListItem';

interface Props {
  list: ContactsListInterface[];
}

const ContactsList = ({ list }: Props) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(items, i) => (
        <List.Item key={i}>
          <Space direction="vertical" style={{ width: '100%' }} size={'small'}>
            <Typography.Title level={4}>{items.title}</Typography.Title>
            <List.Item.Meta
              description={
                <>
                  {items.mail && (
                    <ContactsListItem>
                      <MailOutlined /> Mail:{' '}
                      {items.mail.map((item, mailKey) => (
                        <a href={`mailto:${item}`} style={{ wordBreak: 'break-all' }} key={mailKey}>
                          {item} <br />
                        </a>
                      ))}
                    </ContactsListItem>
                  )}
                  {items.phone && (
                    <ContactsListItem>
                      <PhoneOutlined /> Phone:{' '}
                      {items.phone.map((item, phoneKey) => (
                        <a href={`tel:${item}`} style={{ wordBreak: 'break-all' }} key={phoneKey}>
                          {item} <br />
                        </a>
                      ))}
                    </ContactsListItem>
                  )}
                  {items.room && (
                    <ContactsListItem>
                      <HomeOutlined /> Room:{' '}
                      {items.room.map((item, roomKey) => (
                        <Typography.Text type="secondary" key={roomKey}>
                          {item} <br />
                        </Typography.Text>
                      ))}
                    </ContactsListItem>
                  )}
                </>
              }
            />
          </Space>
        </List.Item>
      )}
    />
  );
};

export default ContactsList;
