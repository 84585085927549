import UniversalTable from '../UniversalTeachersTable';
import { TeacherWorkExpDto } from '../../../../interfaces/Teacher/TeacherWorkExpDto';
import { TeacherService } from '../../../../services/TeacherService';
import useFetchData from '../../../../hooks/useFetchData';
import { Field } from '../../../../interfaces/Field';
import { Tag } from 'antd';
import { successNotification } from '../../../../helpers/successNotification';

interface TeacherWorkTableProps {
    isPublic: boolean;
}

const TeacherWorkTable = ({ isPublic }: TeacherWorkTableProps) => {
    const { data: workExp, fetchData: fetchWorkExp } = useFetchData<TeacherWorkExpDto[]>(
        TeacherService.getWorkExp, true
    );

    const addRecord = (record: TeacherWorkExpDto) => {
        TeacherService.createOrUpdateWorkExp(record)
            .then(() => fetchWorkExp())
            .finally(() => successNotification('Work experience has been added!'))
    };

    const editRecord = (record: TeacherWorkExpDto) => {
        TeacherService.createOrUpdateWorkExp(record)
            .then(() => fetchWorkExp())
            .finally(() => successNotification('Work experience has been updated!'))
    };

    const deleteRecord = (record: TeacherWorkExpDto) => {
        TeacherService.deleteWorkExp(record.id)
            .then(() => fetchWorkExp())
            .finally(() => successNotification('Work experience has been deleted!'))
    };

    const workExpFields: Field[] = [
        { name: 'organizationName', label: 'Organization name', type: 'text', placeholder: "Write organization name" },
        { name: 'address', label: 'Address', type: 'text', placeholder: "Write address" },
        { name: 'position', label: 'Position', type: 'text', placeholder: "Write position" },
        { name: 'lengthOfService', label: 'Length of service', type: 'text', placeholder: "Write length of service" },
        { name: 'yearFrom', label: 'Year from', type: 'year', placeholder: "Write year from" },
        { name: 'yearTo', label: 'Year to', type: 'year', placeholder: "Write year to" },
        {
            name: 'type', label: 'Experience type', type: 'select', placeholder: 'Select experience type', options: [
                { value: 'GENERAL', label: 'General' },
                { value: 'PEDAGOGICAL', label: 'Pedagogical' },
                { value: 'PROFILE', label: 'Profile' },
            ]
        }

    ];


    return (
        <UniversalTable
            columns={[
                {
                    title: '#',
                    dataIndex: 'key',
                    key: 'key',
                },
                {
                    title: 'Organization name',
                    dataIndex: 'organizationName',
                    key: 'organizationName',
                },
                {
                    title: 'Address',
                    dataIndex: 'address',
                    key: 'address',
                },
                {
                    title: 'Position',
                    dataIndex: 'position',
                    key: 'position',
                },
                {
                    title: 'Lenght of service',
                    dataIndex: 'lengthOfService',
                    key: 'lengthOfService',
                },
                {
                    title: 'Year from',
                    dataIndex: 'yearFrom',
                    key: 'yearFrom',
                },
                {
                    title: 'Year to',
                    dataIndex: 'yearTo',
                    key: 'yearTo',
                },
                {
                    title: 'Experience type',
                    dataIndex: 'type',
                    key: 'type',
                    render: (record: any) => {
                        switch (record) {
                            case 'GENERAL':
                                return <Tag color={'blue'}>{'General'}</Tag>;
                            case 'PEDAGOGICAL':
                                return <Tag color={'blue'}>{'Pedagogical'}</Tag>;
                            case 'PROFILE':
                                return <Tag color={'blue'}>{'Profile'}</Tag>;
                            default:
                                return <></>;
                        }
                    },
                },
            ]}
            dataSource={workExp ? workExp.map((workExp, i) => ({
                id: workExp.id,
                key: i + 1,
                address: workExp.address,
                position: workExp.position,
                organizationName: workExp.organizationName,
                lengthOfService: workExp.lengthOfService,
                yearFrom: workExp.yearFrom,
                yearTo: workExp.yearTo,
                type: workExp.type,
            })) : []}
            addRecord={(e: TeacherWorkExpDto) => addRecord(e)}
            editRecord={(e: TeacherWorkExpDto) => editRecord(e)}
            deleteRecord={(e: TeacherWorkExpDto) => deleteRecord(e)}
            fields={workExpFields}
        />)
}


export default TeacherWorkTable