import { OrderTypeDtoResponse } from "../../../../interfaces/Order/OrderTypeDtoResponse";
import { Select } from 'antd';

type Options = OrderTypeDtoResponse[];

const { Option } = Select;

interface Props {
    placeholder: string;
    selectedOption: number | undefined;
    options: Options;
    onSelect: (value: number) => void;
    onClear?: () => void;
    width?: number;
}

const OrderSelect = ({ placeholder, selectedOption, options, onSelect, onClear, width }: Props) => {
    return (
        <Select
            placeholder={placeholder}
            value={selectedOption}
            allowClear
            onSelect={onSelect}
            onClear={onClear}
            style={{ width: width ? width : 600 }}>
            {options.map((option) => (
                <Option key={option.id} value={option.id}>
                    {`${option.name}/${option.nameRu}/${option.nameEn}`}
                </Option>
            ))}
        </Select>
    );
};
export default OrderSelect;