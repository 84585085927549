import authAxios from "../common/authAxios";
import { downloadFile } from "../helpers/downloadFile";
import { StudyStreamDto } from "../interfaces/StudyStream/StudyStreamDto";
import { GroupsAndStreamsOfStudentsDtoResponse } from "../interfaces/Syllabus/GroupsAndStreamsOfStudentsDtoResponse";
import { StudyStreamDtoResponse } from "../interfaces/Syllabus/StudyStreamDtoResponse";
import { SyllabusDataDraftRequest } from "../interfaces/Syllabus/SyllabusDataDraftRequest";
import { SyllabusDataDto } from "../interfaces/Syllabus/SyllabusDataDto";
import { SyllabusDraftDataDto } from "../interfaces/Syllabus/SyllabusDraftDataDto";
import { SyllabusDraftDto } from "../interfaces/Syllabus/SyllabusDraftDto";
import { SyllabusDto } from "../interfaces/Syllabus/SyllabusDto";

export class SyllabusService {
    private static baseUrl = '/astanait-office-module/api/v1/syllabus';
    private static baseUrlStream = '/astanait-office-module/api/v1/study-stream';

    static getSyllabusDraftList() {
        return authAxios.get<SyllabusDraftDto[]>(`/astanait-office-module/api/v1/syllabus/draft/all`);
    }

    static getSyllabusDraftDataById(id: number) {
        return authAxios.get<SyllabusDraftDataDto>(`/astanait-office-module/api/v1/syllabus/draft/data/${id}`);
    }

    static manipulateSyllabusDraftData(data: SyllabusDataDraftRequest) {
        return authAxios.put(`/astanait-office-module/api/v1/syllabus/draft`, data);
    }

    static undoSyllabusAction(syllabusDraftDataId: number) {
        return authAxios.delete(`/astanait-office-module/api/v1/syllabus/draft?syllabusDraftDataId=${syllabusDraftDataId}`);
    }

    static getSyllabusList() {
        return authAxios.get<SyllabusDto[]>(`/astanait-office-module/api/v1/syllabus/all`);
    }

    static getSyllabusDataById(id: number) {
        return authAxios.get<SyllabusDataDto>(`/astanait-office-module/api/v1/syllabus/data/${id}`);
    }

    static getGroupAndStreamSyllabusList(params: any) {
        for (const key of Object.keys(params)) {
            if (!params[key]) {
                delete params[key];
            }
        }

        return authAxios.get<StudyStreamDto[]>(`${this.baseUrlStream}/all`, { params });
    };

    static getGroupAndStreamSyllabusById(id: number) {
        return authAxios.get<StudyStreamDto>(`${this.baseUrlStream}/${id}`);
    };

    static createOrUpdateGroupAndStreamSyllabus(data: StudyStreamDto) {
        return authAxios.put(`${this.baseUrlStream}/create-or-update`, data);
    }



    static getGroupAndStreamSyllabus(params: any) {
        for (const key of Object.keys(params)) {
            if (!params[key]) {
                delete params[key];
            }
        }

        return authAxios.get<GroupsAndStreamsOfStudentsDtoResponse>(`${this.baseUrl}/groups-and-streams-of-students`, { params });
    };

    static getGroupAndStreamSyllabusExcel(params: any) {
        for (const key of Object.keys(params)) {
            if (!params[key]) {
                delete params[key];
            }
        }
        return downloadFile(
            `${this.baseUrl}/groups-and-streams-of-students-excel?course=${params.course}&degree_id=${params.degree_id}&education_year=${params.education_year}&enrolled_year=${params.enrolled_year}`,
            'GET',
            'blob',
            "Syllabus",
            'xlsx'
        );
    }

    static getStudyStreamList(params: any) {
        for (const key of Object.keys(params)) {
            if (!params[key]) {
                delete params[key];
            }
        }

        return authAxios.get<StudyStreamDtoResponse>(`${this.baseUrl}/study-stream-list`, { params });
    };

    static getStudyStreamListExcel(params: any) {
        for (const key of Object.keys(params)) {
            if (!params[key]) {
                delete params[key];
            }
        }
        return downloadFile(
            `${this.baseUrl}/study-stream-list-excel?course=${params.course}&degree_id=${params.degree_id}&education_year=${params.education_year}&enrolled_year=${params.enrolled_year}&trim=${params.trim}`,
            'GET',
            'blob',
            "Syllabus",
            'xlsx'
        );
    }
}