import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm, Space, Tag, UploadProps } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { successNotification } from '../../../helpers/successNotification';
import { CertificateDtoResponse } from '../../../interfaces/CertificateDtoResponse';
import { CertificateService } from '../../../services/deansOffice/CertificateService';

const useCertificateRegistration = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<CertificateDtoResponse[]>([]);
  const [editData, setEditData] = useState<CertificateDtoResponse>();
  const [importData, setImportData] = useState<{ data: CertificateDtoResponse[]; fileName: string }>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const initialData = useRef<CertificateDtoResponse[]>([]);

  const columns = [
    {
      title: 'ФИО слушателя',
      dataIndex: 'issuedTo',
      key: 'issuedTo',
    },
    {
      title: 'Статус документа',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (record: any) => (
        <Space> {record ? <Tag color="success">Активен</Tag> : <Tag color="error">Неактивен</Tag>}</Space>
      ),
      sorter: (a: any, b: any) => a.isActive - b.isActive,
      width: 50,
    },
    {
      title: 'Тип документа',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Регистрационный номер документа',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Дата выдачи документа',
      dataIndex: 'issuedDate',
      key: 'issuedDate',
    },
    {
      title: 'Документ действителен до',
      dataIndex: 'validUntil',
      key: 'validUntil',
    },
    {
      title: 'Название курса',
      dataIndex: 'courseName',
      key: 'courseName',
    },
    {
      title: 'Действие',
      dataIndex: 'operation',
      key: 'operation',
      render: (text: any, record: any) => (
        <Space>
          <Button type="default" onClick={() => editCertificate(record)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={() => deleteRecord(record)}
            okText="Да"
            cancelText="Нет"
          >
            <Button type="default" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
      width: '5%',
    },
  ];

  const importColumns = [
    {
      title: 'ФИО слушателя',
      dataIndex: 'issuedTo',
      key: 'issuedTo',
    },
    {
      title: 'Статус документа',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (record: any) => (
        <Space> {record ? <Tag color="success">Активен</Tag> : <Tag color="error">Неактивен</Tag>}</Space>
      ),
      sorter: (a: any, b: any) => a.isActive - b.isActive,
      width: 50,
    },
    {
      title: 'Тип документа',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Регистрационный номер документа',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Дата выдачи документа',
      dataIndex: 'issuedDate',
      key: 'issuedDate',
    },
    {
      title: 'Документ действителен до',
      dataIndex: 'validUntil',
      key: 'validUntil',
    },
    {
      title: 'Название курса',
      dataIndex: 'courseName',
      key: 'courseName',
    },
  ];

  const props: UploadProps = {
    // @ts-ignore
    action: (file) => {
      setLoading(true);
      CertificateService.importDataFromExcelFile(file)
        .then(({ data }) => {
          setImportData({ data, fileName: file.name });
        })
        .finally(() => setLoading(false));
    },
    multiple: false,
    showUploadList: false,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  useEffect(() => {
    fetchCertificates();
  }, []);

  const fetchCertificates = () => {
    setLoading(true);
    CertificateService.getAll()
      .then(({ data }) => {
        setData(data);
        initialData.current = data;
      })
      .finally(() => setLoading(false));
  };

  const deleteRecord = (record: any) => {
    setLoading(true);
    CertificateService.deleteCertificate(record.id)
      .then(({ data }) => notification.success({ message: 'Удалено' }))
      .finally(() => {
        setLoading(false);
        fetchCertificates();
      });
  };

  const createCertificate = () => {
    setCreateModalOpen(true);
  };

  const editCertificate = (record: CertificateDtoResponse) => {
    setEditModalOpen(true);
    CertificateService.getCertificateById(record.id)
      .then(({ data }) => {
        setEditData(data);
      });
  };

  const closeModal = () => {
    setEditModalOpen(false);
    setCreateModalOpen(false);
    setIsModalOpen(false);
    fetchCertificates();
  };

  const searchByParameters = (e: string) => {
    if (!e.length) setData(initialData.current);
    else {
      const resultsByName = data.filter((item) => item.issuedTo.toLowerCase().includes(e.toLowerCase()));
      const resultsByCode = data.filter((item) => item.number.toLowerCase().includes(e.toLowerCase()));
      setData([...resultsByCode, ...resultsByName]);
    }
  };

  const importFromExcelFile = () => {
    setIsModalOpen(true);
  };

  const saveCertificateList = () => {
    setLoading(true);
    CertificateService.saveCertificateList(importData!.data)
      .then(({ data }) => successNotification('Данные успешно сохранены!'))
      .finally(() => setLoading(false));
  };

  return {
    data: {
      data,
      props,
      columns,
      loading,
      editModalOpen,
      createModalOpen,
      importColumns,
      editData,
      isModalOpen,
      importData,
    },
    handlers: {
      searchByParameters,
      closeModal,
      editCertificate,
      confirm: deleteRecord,
      createCertificate,
      saveCertificateList,
      importFromExcelFile,
    },
  };
};

export default useCertificateRegistration;
