import { Button, Form, Select, Space, Spin, Switch, Table, Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import { useState } from 'react'
import PageWrapper from '../../../ui/PageWrapper'
import TeacherStatementTable from '../Table2/TeacherStatementTable'
import useStatementPage from './useStatementPage'
import RowSpan from '../../../ui/RowSpan'
import CustomModal from '../../../ui/Modal'
import { ColumnsType } from 'antd/lib/table'


interface DataType {
    letterGrade: string;
    count: number;
    gradePercent: string;
    overallPercent: string;
}

const TeacherStatementPage = () => {

    const { data, handlers } = useStatementPage();
    const [isMultiple, setIsMultiple] = useState<boolean>(false);

    const grades = [
        "A", "A-", "B+", "B", "B-", "C+", "C", "C-", "D+", "D", "F"
    ];
    const filteredData: DataType[] = [];


    const gradeColumns: ColumnsType<DataType> = [
        {
            title: 'Оценки используемые в ОВПО',
            dataIndex: 'letterGrade',
            key: 'letterGrade',
            width: "20%"
        },
        {
            title: 'Число положительных оценок, поставленных в референтной группе',
            dataIndex: 'count',
            key: 'count',
            width: "20%"
        },
        {
            title: 'Процент каждой оценки по отношению к общему числу положительных оценок',
            dataIndex: 'gradePercent',
            key: 'gradePercent',
            width: "20%"

        }, {
            title: 'Кумулятивный процент положительных оценок',
            dataIndex: 'overallPercent',
            key: 'overallPercent',
            width: "20%"
        },
    ];

    let sum = 0;

    // eslint-disable-next-line array-callback-return
    grades.map((grade) => {
        //@ts-ignore
        let count = data.statements.filter(v => v.alphabeticGrade === grade).length
        let gradePercent = Number(count / data.statements.length * 100).toFixed(2);
        sum += Number(gradePercent);
        filteredData.push({
            letterGrade: grade,
            count: count,
            gradePercent: gradePercent,
            overallPercent: sum >= 100 ? sum.toFixed(1) : sum.toFixed(2)
        })
    })



    return (
        <PageWrapper>
            <Title level={2}>
                Statement
            </Title>
            <RowSpan left={
                <Form form={data.form} onFinish={handlers.handleSearchStatement}>
                    <Form.Item name={"year"} rules={[{ required: true, message: "Please, choose education year" }]}>
                        <Select placeholder={"Choose education year"} allowClear onClear={() => {
                            data.form.setFieldsValue({
                                year: undefined,
                                term: undefined,
                                disciplineId: undefined,
                                groupId: undefined
                            })
                            data.setDisciplines([])
                            data.setGroups([])
                            data.setStatements([])
                        }}>
                            {data.years.map((year) => (
                                <Select.Option key={year.id} value={year.year}>
                                    {year.year}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={"term"} rules={[{ required: true, message: "Please, choose trimester" }]}>
                        <Select placeholder={"Choose trimester"} onSelect={handlers.handleSelectTrim} allowClear onClear={() => {
                            data.form.setFieldsValue({
                                term: undefined,
                                disciplineId: undefined,
                                groupId: undefined
                            })
                            data.setDisciplines([])
                            data.setGroups([])
                            data.setStatements([])
                        }}>
                            {data.trimesters.map((trimester) => (
                                <Select.Option key={trimester.id} value={trimester.trimester}>
                                    {trimester.trimester}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={"disciplineId"} rules={[{ required: true, message: "Please, choose discipline" }]}>
                        <Select placeholder={"Choose discipline"} onSelect={handlers.handleSelectDiscipline} allowClear onClear={() => {
                            data.form.setFieldsValue({
                                disciplineId: undefined,
                                groupId: undefined
                            })
                            data.setDisciplines([])
                            data.setGroups([])
                            data.setStatements([])
                        }}>
                            {data.disciplines.map((discipline) => (
                                <Select.Option key={discipline.id} value={discipline.id}>
                                    {discipline.titleEn}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={"groupId"} rules={[{ required: true, message: "Please, choose group" }]}>
                        <Select allowClear placeholder={"Choose group"} onClear={() => {
                            data.form.setFieldsValue({
                                groupId: undefined
                            });
                            data.setGroups([]);
                            data.setStatements([]);
                        }}>
                            {data.groups.map((group) => (
                                <Select.Option key={group.id} value={group.id}>
                                    {group.title}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Space style={{ alignItems: "start" }}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Search
                            </Button>
                        </Form.Item>
                        {data.statements.length > 0 && (
                            <CustomModal title='Grade statistics' style={{ minWidth: "50%" }} btnTitle='Show statistics' onOk={data.form.submit}>
                                <Table
                                    bordered
                                    pagination={{ pageSize: 20 }}
                                    columns={gradeColumns}
                                    dataSource={filteredData}
                                    style={{ width: "100%" }}
                                    summary={(pageData) => {
                                        let totalBorrow = 0;

                                        pageData.forEach(({ letterGrade, count, gradePercent, overallPercent }) => {
                                            totalBorrow += count;
                                        });

                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}>
                                                        <Typography.Text>{totalBorrow}</Typography.Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={2}>
                                                        <Typography.Text>{Number(100).toFixed(2)}</Typography.Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={3}>
                                                        <Typography.Text>-</Typography.Text>
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </>
                                        );
                                    }}
                                />
                            </CustomModal>
                        )}
                    </Space>
                </Form>
            } />


            {data.statements.length > 0 && (
                <Space direction='horizontal' style={{ marginBottom: 10 }}>
                    <Switch onChange={() => setIsMultiple(!isMultiple)} checked={isMultiple} checkedChildren="Multiple students mode" unCheckedChildren="Single student mode" />
                </Space>
            )}


            {data.loading && <Spin />}


            {
                data.statements.length > 0 && (
                    <TeacherStatementTable
                        key={Math.random()}
                        isMultiple={isMultiple}
                        vedomostData={data.statements}
                        shouldRerender={() => {
                            handlers.handleSearchStatement()
                        }}
                        loading={data.loading}
                        form={data.form}
                    />
                )
            }

        </PageWrapper >
    )
}

export default TeacherStatementPage