import authAxios from "../common/authAxios";
import { ApplicationDiscountTypeDtoResponse } from "../interfaces/Applications/response/ApplicationDiscountTypeDtoResponse";
import { ApplicationDiscountTypeItemDtoResponse } from "../interfaces/Applications/response/ApplicationDiscountTypeItemDtoResponse";
import { StudentStudyDiscountResponse } from "../interfaces/Student/StudentStudyDiscountResponse";

export class StudyDiscountService {
    static getApplicatinDiscountTypes() {
        return authAxios.get<ApplicationDiscountTypeDtoResponse[]>(
            `/astanait-student-module/api/v1/study-discount/type`
        );
    }
    static getApplicatinDiscountItems() {
        return authAxios.get<ApplicationDiscountTypeItemDtoResponse[]>(
            `/astanait-student-module/api/v1/study-discount/type-item`
        );
    }
    static getApplicatinDiscountTypeItems(TypeId?: number) {
        return authAxios.get<ApplicationDiscountTypeItemDtoResponse[]>(
            `/astanait-student-module/api/v1/study-discount/type-item/get-items-by-type?type-id=${TypeId}`
        );
    }
    static getStudentStudyDiscount() {
        return authAxios.get<StudentStudyDiscountResponse[]>(
            `/astanait-student-module/api/v1/student-study-discount`
        );
    }

}