import React from 'react'
import { Button, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Page from '../../../../../ui/Page';
import useFetchData from '../../../../../hooks/useFetchData';
import { StudentAcademicMobilityCompetitionDto } from '../../../../../interfaces/AcademicMobilityCompetitionDto';
import { AcademicMobilityCompetitionService } from '../../../../../services/AcademicMobilityCompetitionService';
import UniversalTable from '../../../TeachersPage/UniversalTeachersTable';

const StudentMobilityCompetitionsPage = () => {
    const { data: competitions } = useFetchData<StudentAcademicMobilityCompetitionDto[]>(AcademicMobilityCompetitionService.getStudentCompetitions, true);
    const history = useHistory();


    return (
        <Page title='Your applied competitions'>
            <UniversalTable
                columns={[
                    {
                        title: '#',
                        dataIndex: 'key',
                        key: 'key',
                    },
                    {
                        title: 'University',
                        dataIndex: 'university',
                        key: 'university',
                    },
                    {
                        title: 'Deadline',
                        dataIndex: 'deadline',
                        key: 'deadline',
                        width: '7%',
                    },
                    {
                        title: 'Study start at',
                        dataIndex: 'studyStartAt',
                        key: 'studyStartAt',
                        width: '8%',
                    },
                    {
                        title: 'Study end at',
                        dataIndex: 'studyEndAt',
                        key: 'studyEndAt',
                        width: '7%',
                    },
                    {
                        title: 'Quota',
                        dataIndex: 'quota',
                        key: 'quota',
                    },
                    {
                        title: 'Application Status',
                        dataIndex: 'status',
                        key: 'status',
                    },
                    {
                        title: 'Competition Status',
                        dataIndex: 'competitionStatus',
                        key: 'competitionStatus',
                    },
                    {
                        title: 'Financing source',
                        dataIndex: 'financingSource',
                        key: 'financingSource',
                    },
                    {
                        title: 'Action',
                        dataIndex: 'action',
                        key: 'action',
                        render: (_: string, record: any) => (
                            <Space>
                                <Button
                                    type="primary"
                                    icon={<InfoCircleOutlined />}
                                    onClick={() => history.push(`/academic-mobility/competition/${record.id}`)}
                                >
                                    Info
                                </Button>
                            </Space>
                        )
                    }
                ]}
                dataSource={
                    competitions ?
                        competitions
                            .sort((a, b) => a.id - b.id)
                            .map((competition, i) => ({
                                key: i + 1,
                                id: competition.id,
                                university: competition.competition.university.nameEn,
                                deadline: competition.competition.deadline,
                                studyStartAt: competition.competition.studyStartAt,
                                studyEndAt: competition.competition.studyEndAt,
                                quota: competition.competition.quota,
                                status: competition.status.nameEn,
                                competitionStatus: competition.competition.status.nameEn,
                                financingSource: competition.competition.financingSource.nameEn,
                            })) : []}
                addRecord={(e: any) => { }}
                editRecord={(e: any) => { }}
                deleteRecord={(e: any) => { }}
                isPublic={false}
                hideActions={true}
                hideCreate={true}
            />
        </Page>
    )
}

export default StudentMobilityCompetitionsPage