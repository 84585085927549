import authAxios from "../common/authAxios";
import { AvailableAppointmentDatesResponse } from "../interfaces/deansOffice/AvailableAppointmentDatesResponse";
import { DeanOfficeAppointmentDtoResponse } from "../interfaces/deansOffice/DeanOfficeAppointmentDtoResponse";
import { DeansOfficeAppointmentDtoRequest } from "../interfaces/deansOffice/DeansOfficeAppointmentDtoRequest";

export class DeansOfficeAppointmentService {
    static getAvailableDateTimes(type_id: number | undefined, date: Date) {
        return authAxios.get<any[]>(
            `/astanait-deans-module/api/v1/dean-office-reception-appointment/available-appointment-date-times?type_id=${type_id}&date=${date}`
        );
    }
    static getAvailableDates() {
        return authAxios.get<AvailableAppointmentDatesResponse[]>(
            `/astanait-deans-module/api/v1/dean-office-reception-appointment/available-appointment-dates`
        );
    }
    static createDeansOfficeAppointment(appointment: DeansOfficeAppointmentDtoRequest) {
        return authAxios.post(`/astanait-deans-module/api/v1/dean-office-reception-appointment/create`, appointment);
    }
    static getAllAppointments(type_id: number | undefined, date: Date) {
        return authAxios.get<DeanOfficeAppointmentDtoResponse[]>(
            `/astanait-deans-module/api/v1/dean-office-reception-appointment/find-by-local-date-and-type?appointment_date=${date}&type_id=${type_id}`
        );
    }
    static getAppointmentsByStudentId() {
        return authAxios.get<DeanOfficeAppointmentDtoResponse[]>(
            `/astanait-deans-module/api/v1/dean-office-reception-appointment/find-by-student-id`
        );
    }
    static deleteAppointment(appointmentId: number) {
        return authAxios.delete(`/astanait-deans-module/api/v1/dean-office-reception-appointment/delete/${appointmentId}`);
    }








}