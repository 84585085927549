import styles from './style.module.css';
import React from 'react';
import { Table, Typography } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { StudentDisciplineDtoResponse } from '../../../interfaces/Student/StudentDisciplineDtoResponse';
import { DisciplinesService } from '../../../services/DisciplinesService';
import PageWrapper from '../../../ui/PageWrapper';

const { Title } = Typography;

const columns = [
  {
    title: 'Subject name',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Trimester',
    dataIndex: 'term',
    key: 'term',
  },
  {
    title: 'Number of Credits',
    dataIndex: 'credits',
    key: 'credits',
  },
];

const StudentDisciplinesPage = () => {
  const [loading, setLoading] = React.useState(true);
  const [studentDisciplines, setStudentDisciplines] = React.useState<StudentDisciplineDtoResponse[]>([]);

  React.useEffect(() => {
    setLoading(true);

    DisciplinesService.getStudentDisciplines()
      .then(({ data }) => setStudentDisciplines(data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <PageWrapper>
      <section>
        <header>
          <Title level={3} className={styles.title}>
            List of Disciplines Studied
          </Title>
        </header>
        <Table
          columns={columns}
          dataSource={studentDisciplines.map((studentDiscipline) => ({
            key: uuidv4(),
            title: `${studentDiscipline.titleEn} / ${studentDiscipline.titleRu} / ${studentDiscipline.titleKz}`,
            term: studentDiscipline.term,
            credits: studentDiscipline.credits,
          }))}
          loading={loading}
        />
      </section>
    </PageWrapper>
  );
};

export default StudentDisciplinesPage;
