import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { OrderDtoResponse } from '../../../../interfaces/Order/OrderDtoResponse';
import { OrdersService } from '../../../../services/OrdersService';
import { Moment } from 'moment';
import { errorNotification } from '../../../../helpers/errorNotification';

interface Props {
  selectedOrderType: number | undefined;
  search: string;
  number: string;
  fromDate: Moment | undefined;
  toDate: Moment | undefined;
}

interface DataType {
  key: number | string;
  orderId: number;
  orderType: string;
  issueDate: Date;
  number: string;
  name: string;
  owner: number;
  fileid: number;
}

const OrdersTable = ({ selectedOrderType, number, search, fromDate, toDate }: Props) => {
  const [tableData, setTableData] = useState<OrderDtoResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const delayedSearch = setTimeout(() => {
      OrdersService.getOrdersByFilter({
        type: selectedOrderType,
        number: number,
        search: search,
        fromDate: fromDate,
        toDate: toDate,
      })
        .then((res) => {
          setTableData(res.data);
          if (res.data.length === 0) {
            errorNotification(`Не найдено ни одного приказа`);
          }
        })
        .finally(() => setLoading(false));
    }, 1000);

    return () => clearInterval(delayedSearch);
  }, [selectedOrderType, search, number, fromDate, toDate]);

  const handleDownload = (orderId: number, name: string) => {
    OrdersService.downloadOrder(orderId, name)
      .then(() => {
        notification.success({ message: 'Приказ успешно был скачан!' });
      });
  };

  const confirm = (e?: React.MouseEvent<HTMLElement>) => {
    notification.success({ message: 'Deleted' });
  };

  const cancel = (e?: React.MouseEvent<HTMLElement>) => {
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Дата',
      dataIndex: 'issueDate',
      key: 'issueDate',
    },
    {
      title: 'Номер',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Тип',
      dataIndex: 'orderType',
      key: 'orderType',
    },
    {
      dataIndex: 'operation',
      key: 'operation',
      render: (text: any, record: any) => (
        <Space>
          <Button type="default" onClick={() => handleDownload(record.orderId, record.name)}>
            <DownloadOutlined />
          </Button>
          <Button type="default" href={`/order-list/edit/${record.orderId}`}>
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="default" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
      width: '5%',
    },
  ];

  const data: DataType[] = [];

  for (let i = 0; i < tableData.length; ++i) {
    data.push({
      key: tableData[i].id,
      orderId: tableData[i].id,
      name: tableData[i].name,
      number: tableData[i].number,
      issueDate: tableData[i].issueDate,
      fileid: tableData[i].fileId,
      owner: tableData[i].owner,
      orderType:
        tableData[i].orderType.nameRu + ' / ' + tableData[i].orderType.name + ' / ' + tableData[i].orderType.nameEn,
    });
  }

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered={true}
      pagination={{ defaultPageSize: 20 }}
      loading={loading}
    />
  );
};

export default OrdersTable;
