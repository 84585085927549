import React from 'react'
import { TeacherDtoResponse } from '../../../interfaces/Teacher/TeacherDtoResponse';
import { Table } from 'antd';
import useTeacherSelect from './useTeacherSelect';


interface Props {
    loading: boolean;
    selectedTeacherId?: any;
    setSelectedTeacherId?: any;
    short?: boolean;
    data: TeacherDtoResponse[];
}


const TeacherSelectTable = ({ loading, selectedTeacherId, setSelectedTeacherId, short, data }: Props) => {
    const { shortColumns, columns, filteredData, selectRow, rowSelection } = useTeacherSelect(data, {
        selectedTeacherId,
        setSelectedTeacherId,
    });

    return (
        <Table
            columns={short ? shortColumns : columns}
            dataSource={filteredData}
            style={{ paddingTop: 20 }}
            loading={loading}
            onRow={(record, rowIndex) => {
                return {
                    onClick: () => selectRow(record),
                    onMouseEnter: (e) => (e.currentTarget.style.cursor = 'pointer'),
                };
            }}
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
        />
    )
}

export default TeacherSelectTable