import { useState } from 'react';
import Title from 'antd/es/typography/Title';
import { Button, Form, Input, Modal, Space, Typography } from 'antd';
import { Table } from 'antd';
import { AppointmentDatesDtoResponse } from '../../../interfaces/AppointmentDatesDtoResponse';
import { PsychologistService } from '../../../services/PsychologistService';
import './appointment.css';
import TextArea from 'antd/lib/input/TextArea';
import { useForm } from 'antd/lib/form/Form';
import Page from '../../../ui/Page';
import useFetchData from '../../../hooks/useFetchData';
import { successNotification } from '../../../helpers/successNotification';
import useModal from '../../../ui/Modal/useModal';
import useStudentDetails from '../StudentsDetailsPage/useStudentDetails';
import { StudentDataType } from '../../../interfaces/Student/StudentDtoResponse';
import StudentsTable from '../StudentsDetailsPage/StudentsTable';
import { errorNotification } from '../../../helpers/errorNotification';

const PsychologicalAppointmentPage = () => {
  const [addModal, setAddModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rejectModalValue, setRejectModalValue] = useState<any>();
  const [form] = useForm();
  const { showModal: showStudentModal, isModalOpen: isStudentModalOpen, handleCancel: handleStudentModalClose } = useModal();

  const { data: appointments, fetchData, isLoading } = useFetchData<AppointmentDatesDtoResponse[]>(PsychologistService.getAppointment, true)
  const [selectedAppointment, setSelectedAppointment] = useState<any>();
  const [selectedStudent, setSelectedStudent] = useState<StudentDataType[]>([]);
  const [selectedStudentId, setSelectedStudentId] = useState<number[]>([]);
  const {
    searchValue,
    setSearchValue,
    studentsList,
    loading,
    handleSearchStudents,
  } = useStudentDetails({ retirePage: true });

  const changeDateStatus = (value: any, status: string) => {
    if (status === 'Not available') {
      PsychologistService.MakeDateUnavailable(value)
        .then(() => {
          successNotification('You successfully change a date status!');
          fetchData();
        });
    }
    else {
      PsychologistService.MakeDateAvailable(value)
        .then(() => {
          successNotification('You successfully change a date status!');
          fetchData();
        });
    }

  };
  const cancelAppointment = (status_id: any, comment: any) => {
    PsychologistService.updateAppointment(rejectModalValue, status_id, comment)
      .then(() => {
        successNotification('You successfully change an appointment status!');
        setAddModal(false)
        fetchData();
      });
  };

  const handleRejectModalAdd = (record: any) => {
    setRejectModalValue(record);
    setAddModal(true);
  };

  const handleRejectModalShow = (record: any) => {
    setRejectModalValue(record);
    setShowModal(true);
  };

  const handleOpenStudentModal = (appointment: AppointmentDatesDtoResponse) => {
    setSelectedAppointment(appointment);
    showStudentModal();
  }

  const acceptAppointment = (id: any, status_id: any, comment: any) => {
    PsychologistService.updateAppointment(id, status_id, comment)
      .then(() => {
        successNotification('You successfully change an appointment status!');
        setAddModal(false)
        fetchData();
      });
  };

  const columns = [
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },

    {
      title: 'Status',
      key: 'status',
      render: (_: any, record: any) => (

        <Space size="middle">
          {record.status === "Available" &&
            <Button type='link' onClick={() => handleOpenStudentModal(record)}>
              Add appointment
            </Button>
            // <Link to={{
            //   pathname: '/add-appointment-page',
            //   state: { date: record.date, weekDay: record.weekDay, time: record.time }
            // }}>
            //   Add appointment
            // </Link>
          }

          {record.appointment_status === 1 &&
            <Space size="middle" className="button">  <Button
              type="primary"
              onClick={() => acceptAppointment(record.appointment_id, 2, "")}
              className="button"
            >
              Accept
            </Button>
              <Button
                onClick={() => handleRejectModalAdd(record.appointment_id)}
              >
                Cancel
              </Button></Space>}

          {record.status === "Not available" &&
            <p className="active">Not available</p>
          }
          {record.appointment_status === 2 &&
            <p className="accept">Accepted</p>
          }
          {record.appointment_status === 6 &&
            <p className="active">Canceled</p>
          }

        </Space>
      ),
    },
    {
      title: 'Reason',
      render: (record: any) => (
        <Button
          disabled={record.appointment_status !== 6}
          onClick={() => handleRejectModalShow(record.comment)}
        >
          Show reason
        </Button>
      ),
    },
  ];

  const checkIfAllTimesUnavailable = (times: any) => {
    return times.every((time: any) => time.status === 'Not available');
  };

  const confirm = (studentId: any, date: any, time: any) => {
    if (studentId === undefined) {
      errorNotification('Please select a student!');
      return;
    }

    PsychologistService.addAppointment({
      targetUserId: studentId,
      dateTime: date + "T" + time,
    })
      .then(() => {
        successNotification('You successfully made an appointment!')
        handleStudentModalClose();
        fetchData();
      });
  };

  return (
    <Page title='Appointment with a psychologist'>
      {appointments?.map((appointment, index) => (
        <section>
          <section>
            <Title level={5}>{appointment.weekDay + " " + appointment.date}</Title>

            {appointment.weekDay !== 'Cancelled' && (
              checkIfAllTimesUnavailable(appointment.times) ? (
                <Button
                  type="primary"
                  // eslint-disable-next-line no-useless-concat
                  onClick={() => changeDateStatus(appointment.date + " " + '10:00:00', 'Available')}
                >
                  Make the date available
                </Button>
              ) : (
                <Button
                  type="primary"
                  danger
                  // eslint-disable-next-line no-useless-concat
                  onClick={() => changeDateStatus(appointment.date + " " + '10:00:00', 'Not available')}
                >
                  Make the date unavailable
                </Button>
              )
            )}
          </section>
          <br />

          <Modal
            title="Rejection reason"
            open={addModal} onCancel={() => setAddModal(false)}
            footer={[
              <Button key="back" onClick={() => setAddModal(false)}>
                Cancel
              </Button>

            ]}
          >
            <Typography>
              Please write the reason for refusal
            </Typography>
            <Form onFinish={() => cancelAppointment(6, form.getFieldValue(("reason")))} form={form} >
              <Form.Item name="reason" required={true}>
                <TextArea style={{ height: "200px" }} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>

          <Modal open={showModal} onCancel={() => setShowModal(false)} onOk={() => setShowModal(false)}>
            <Space direction='vertical' style={{ width: "100%" }}>
              <Title level={5}>Rejection reason</Title>
              <TextArea readOnly value={rejectModalValue} />

            </Space>
          </Modal>

          <Table
            columns={columns}
            size="small"
            scroll={{ x: 1000 }}
            loading={isLoading}
            dataSource={appointments[index].times.map((time, i) => (
              {
                id: appointments[index].date.toString() + i,
                appointment: time.appointment,
                student: time.appointment ? time.appointment.studentFIO : '',
                group: time.appointment ? time.appointment.group : '',
                email: time.appointment ? time.appointment.email : '',
                time: time.time,
                status: time.status,
                date: appointment.date,
                appointment_status: time.appointment ? time.appointment.status.id : '',
                appointment_id: time.appointment ? time.appointment.id : null,
                comment: time.appointment ? time.appointment.comment : null,
              }
            ))}
            rowKey={(record) => record.id}
            pagination={false}
          />
        </section>
      ))}

      <Modal
        title={`Запись студента на прием ${selectedAppointment?.date} ${selectedAppointment?.time}`}
        open={isStudentModalOpen}
        onCancel={handleStudentModalClose}
        onOk={form.submit}
        okText="Добавить"
        footer={null}
      >
        <Space direction='vertical' style={{ width: "100%" }}>
          <Form onFinish={e => confirm(selectedStudent[0].userId, selectedAppointment?.date, selectedAppointment?.time)}>
            <Space direction="vertical" size={'large'}>
              <Input.Search
                placeholder="Введите ФИО Студента"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onSearch={(value) => handleSearchStudents(value)}
                enterButton
                style={{ width: 400 }}
              />
              {studentsList.length > 0 && (
                <StudentsTable
                  loading={loading}
                  data={studentsList}
                  short
                  selectableCheckbox={true}
                  selectedStudentId={selectedStudentId}
                  setSelectedStudentId={setSelectedStudentId}
                  selectedStudent={selectedStudent}
                  setSelectedStudent={setSelectedStudent}
                />
              )}
            </Space>
            <Form.Item>
              <Button type="primary" style={{ marginTop: "20px" }} htmlType="submit" >
                Добавить
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Modal>
    </Page>
  );
};

export default PsychologicalAppointmentPage;


