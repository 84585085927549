import React, { useEffect, useState } from 'react'
import Page from '../../../../ui/Page'
import { DatePicker, Form, Input, Space, Table } from 'antd'
import { SurveyService } from '../../../../services/student/SurveyService'
import { SurveyDtoResponse } from '../../../../interfaces/Survey/SurveyDtoResponse'
import { useHistory } from 'react-router-dom'
import RowSpan from '../../../../ui/RowSpan'
import CustomModal from '../../../../ui/Modal'

const AdminSurveyPage = () => {

    const [surveys, setSurveys] = useState<SurveyDtoResponse[]>([])
    const navigate = useHistory();
    const [form] = Form.useForm();

    useEffect(() => {
        SurveyService.getSurveys()
            .then(({ data }) => setSurveys(data))
    }, [])

    const columns = [
        {
            title: "№",
            key: "key",
            dataIndex: "key"
        },
        {
            title: "Name",
            key: "name",
            dataIndex: "name"
        },
        {
            title: "Created date",
            key: "createdDate",
            dataIndex: "createdDate"
        },
    ];

    const selectRow = (id: number) => {
        navigate.push(`/survey/${id}`);
    };

    return (
        <Page title='Surveys' subtitle='Page for surveys'>
            <Space direction='vertical' style={{ width: "100%" }}>
                <RowSpan right={
                    <CustomModal title='Информация об опросе' btnTitle='Создать новый опрос' onOk={() => { }}>
                        <Form size="large" form={form}>
                            <Form.Item name={"name"}>
                                <Input placeholder='Название опроса....' />
                            </Form.Item>
                            <Form.Item name={"openDate"}>
                                <DatePicker.RangePicker />
                            </Form.Item>
                            {/* <Form.Item name={"closedDate"}>
                                <DatePicker placeholder='Дата закрытия' />
                            </Form.Item> */}
                        </Form>
                    </CustomModal>
                } />
                <Table
                    bordered
                    columns={columns}
                    dataSource={surveys.map((survey, i) => ({
                        key: i + 1,
                        id: survey.id,
                        name: survey.name,
                        createdDate: survey.createdDate
                    }))}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => selectRow(record.id),
                            onMouseEnter: (e) => (e.currentTarget.style.cursor = 'pointer'),
                        };
                    }}
                />
            </Space>
        </Page>
    )
}

export default AdminSurveyPage