import styles from './style.module.css';
import { Typography, Table, Button, Pagination, Modal } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import useAcademicMobility from './useNotificationPage';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

const AcademicMobilityPage = () => {
    const { notification, column, currentPage, totalPages, setCurrentPage, isModalOpen, handleCancel, students, studentColumn, studentTotalPages,
        studentCurrentPage, setStudentCurrentPage, studentsNumber } = useAcademicMobility();

    return (
        <PageWrapper>
            <section>
                <header className={styles.header}>
                    <Title level={3} className={styles.title}>
                        Notification List
                    </Title>
                    <Link to="/notification-create">
                        <Button type="primary" icon={<PlusOutlined />}>
                            Add new Notification
                        </Button>
                    </Link>

                </header>
                <Table
                    pagination={{
                        current: currentPage,
                        total: totalPages * 10,
                        pageSize: 10,
                        onChange: (page) => setCurrentPage(page),
                    }}
                    dataSource={notification.map((notification, index) => ({
                        key: notification.id,
                        number: index + 1,
                        text: notification.text,
                        createdDate: notification.createdAt,

                    }))}
                    columns={column}
                />
            </section>
            <Modal title={'Количество студентов: ' + studentsNumber} open={isModalOpen} onCancel={handleCancel} width={800}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                ]}>
                <Table
                    pagination={{
                        current: studentCurrentPage,
                        total: studentTotalPages * 10,
                        pageSize: 10,
                        onChange: (page) => setStudentCurrentPage(page),
                    }}
                    dataSource={students.map((student, index) => ({
                        key: student.id,
                        number: index + 1,
                        student: student.user.name + " " + student.user.surname,
                        isRead: student.isRead,
                        readAt: student.readAt,
                        moodleMessageIsRead: student.moodleMessageIsRead,
                        moodleMessageReadAt: student.moodleMessageReadAt,


                    }))}
                    columns={studentColumn}
                />
            </Modal>
        </PageWrapper>
    )
}

export default AcademicMobilityPage;

