import { Col, Row, Select, Table, Typography, Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useProfilePage from './useProfilePage';
const { Option } = Select;
interface Props {
  userId?: number;
}

const ProfileCurriculum = ({ userId }: Props) => {
  const { columns, loading, data, course, onDownloadCurriculum, onCourseSelect, showModal, CurriculumAddModal } =
    useProfilePage(userId, true);
  return (
    <>
      {CurriculumAddModal}
      <Row id="profileCurriculum">
        <Col flex={0}>
          <Typography.Title level={5}>Course</Typography.Title>
          <Select defaultValue={course} style={{ marginBottom: 20, width: 120 }} onChange={onCourseSelect}>
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
          </Select>
          <Typography.Title type="secondary" level={5}>
            Disciplines amount: {data.length}
          </Typography.Title>
        </Col>
        <Col flex="auto"></Col>
        <Col flex={0}>
          <Space size={20} align="center" style={{ marginTop: 40 }}>
            <Button type="primary" onClick={() => showModal()} icon={<PlusOutlined />}>
              Добавить
            </Button>
            {userId && (
              <Select
                placeholder={'Скачать ИУП'}
                // style={{ marginTop: 40, width: 100 }}
                onSelect={(e: number) => onDownloadCurriculum(userId, course, e)}
              >
                <Select.Option key={0} value={0}>
                  Русский
                </Select.Option>
                <Select.Option key={1} value={1}>
                  Казахский
                </Select.Option>
                <Select.Option key={2} value={2}>
                  English
                </Select.Option>
              </Select>

              // <Button
              //   type="primary"
              //   onClick={() => onDownloadCurriculum(userId)}
              //   icon={<DownloadOutlined />}
              //   style={{ marginTop: 40 }}
              // >
              //   Скачать ИУП
              // </Button>
            )}
          </Space>
        </Col>
      </Row>
      <Table
        bordered
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={false}
        scroll={{ x: '1200px' }}
      />
    </>
  );
};

export default ProfileCurriculum;
