import { Form } from "antd";
import { SyllabusService } from "../../../services/SyllabusService";
import { GroupsAndStreamsOfStudentsDtoResponse } from "../../../interfaces/Syllabus/GroupsAndStreamsOfStudentsDtoResponse";
import { useState } from "react";
import { successNotification } from "../../../helpers/successNotification";
import { StudyStreamDtoResponse } from "../../../interfaces/Syllabus/StudyStreamDtoResponse";
import { StudyStreamDto } from "../../../interfaces/StudyStream/StudyStreamDto";
import { AxiosResponse } from "axios";

export const useSyllabus = () => {
    const [form] = Form.useForm();
    const [groupsAndStreams, setGroupsAndStreams] = useState<GroupsAndStreamsOfStudentsDtoResponse>();
    const [groupsAndStreamsList, setGroupsAndStreamsList] = useState<StudyStreamDto[]>([]);
    const [studyStreams, setStudyStreams] = useState<StudyStreamDtoResponse>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSearchByParamsGroupsAndStreams = (params: any) => {
        setIsLoading(true);
        SyllabusService.getGroupAndStreamSyllabus(params)
            .then((response) => {
                setGroupsAndStreams({
                    educationProgram: response.data.educationProgram,
                    departments: response.data.departments,
                    course: response.data.course,
                    degreeId: response.data.degreeId,
                    enrolledYear: response.data.enrolledYear,
                    educationYear: response.data.educationYear,
                    disciplinesByTrims: response.data.disciplinesByTrims.map((trim, index) => ({
                        trim: trim.trim,
                        disciplines: trim.disciplines.map((discipline, index) => ({
                            ...discipline,
                            key: index + 1,
                        })),
                    })) as any,
                });
            })
            .finally(() => setIsLoading(false));
    }

    const handleSearchListByParamsGroupsAndStreams = (params: any) => {
        setIsLoading(true);
        SyllabusService.getGroupAndStreamSyllabusList(params)
            .then((response) => {
                setGroupsAndStreamsList(response.data);
            })
            .finally(() => setIsLoading(false));
    }

    const handleCreateOrUpdateGroupAndStreamSyllabus = (data: StudyStreamDto): Promise<AxiosResponse<any>> => {
        return SyllabusService.createOrUpdateGroupAndStreamSyllabus(data);
    }

    const handleSearchByParamsStudyStreams = (params: any) => {
        setIsLoading(true);
        SyllabusService.getStudyStreamList(params)
            .then((response) => {
                const updatedData = {
                    ...response.data,
                    studyStreamList: response.data.studyStreamList.map((stream, index) => ({
                        ...stream,
                        key: index + 1,
                    })),
                };
                setStudyStreams(updatedData);
            })
            .finally(() => setIsLoading(false));
    }

    const handleDownloadExcelGroupsAndStreams = () => {
        setIsLoading(true);
        const params = form.getFieldsValue();
        SyllabusService.getGroupAndStreamSyllabusExcel(params)
            .then(() => successNotification('Excel file is downloaded successfully!'))
            .finally(() => setIsLoading(false));
    }

    const handleDownloadExcelStudyStreams = () => {
        setIsLoading(true);
        const params = form.getFieldsValue();
        SyllabusService.getStudyStreamListExcel(params)
            .then(() => successNotification('Excel file is downloaded successfully!'))
            .finally(() => setIsLoading(false));
    }



    return {
        data: {
            form,
            isLoading,
            groupsAndStreams,
            groupsAndStreamsList,
            studyStreams,
        },
        methods: {
            handleSearchByParamsGroupsAndStreams,
            handleSearchByParamsStudyStreams,
            handleDownloadExcelGroupsAndStreams,
            handleDownloadExcelStudyStreams,
            handleSearchListByParamsGroupsAndStreams,
            handleCreateOrUpdateGroupAndStreamSyllabus,
            setGroupsAndStreams,
            setStudyStreams,
        }
    }
}