import authAxios from '../common/authAxios';
import { SummarySheetForTranscriptDtoResponse } from '../interfaces/SummarySheetForTranscriptDtoResponse';
import { AcademicDebtsOfGroupsResponse } from './AcademicDebtsOfGroupsResponse';
import { MinNumberOfAcademicDebtsResponse } from './MinNumberOfAcademicDebtsResponse';

export class AcademicDebtService {
  private static baseUrlForOffice = '/astanait-office-module/api/v1';
  static AcademicDebtsNumberForStudent() {
    return authAxios.get<number>(
      `${this.baseUrlForOffice}/academic-department/assessment-report/student-academic-debts-number-for-student`
    );
  }

  static AcademicDebtsForStudent() {
    return authAxios.get<SummarySheetForTranscriptDtoResponse[]>(
      `${this.baseUrlForOffice}/academic-department/assessment-report/student-academic-debts-for-student`
    );
  }

  static AcademicDebtsOfGroupsForOffice(groupId: number | undefined) {
    return authAxios.get<AcademicDebtsOfGroupsResponse[]>(
      `${this.baseUrlForOffice}/academic-department/assessment-report/group-academic-debts-for-office?group_id=${groupId}`
    );
  }

  static MinNumberOfAcademicDebtsForOffice(
    academicDegreeId: number | undefined = 0,
    courseId: number | undefined = 0,
    educationProgramId: number | undefined = 0,
    groupId: number | undefined = 0,
    count: number = 0
  ) {
    return authAxios.get<MinNumberOfAcademicDebtsResponse[]>(
      `${this.baseUrlForOffice}/academic-department/assessment-report/group-academic-debts-number-for-office?academic_degree_id=${academicDegreeId}&course=${courseId}&education_program_id=${educationProgramId}&group_id=${groupId}&count=${count}`
    );
  }

  static AcademicDebtsForStudentById(userId: number | undefined) {
    return authAxios.get<SummarySheetForTranscriptDtoResponse[]>(
      `${this.baseUrlForOffice}/academic-department/assessment-report/student-academic-debts-for-office?user_id=${userId}`
    );
  }
}
