import authAxios from "../common/authAxios";
import { ParameterDtoRequest } from "../interfaces/Reports/request/ParameterDtoRequest";
import { ReportColumnsDtoResponse } from "../interfaces/Reports/response/ReportColumnsDtoResponse";
import { ReportDtoResponse } from "../interfaces/Reports/response/ReportDtoResponse";
import { ReportParameterDtoResponse } from "../interfaces/Reports/response/ReportParameterDtoResponse";

export class ReportService {
    static getReports(params: any) {
        for (const key of Object.keys(params)) {
            if (!params[key]) {
                delete params[key];
            }
        }

        return authAxios.get<ReportDtoResponse[]>(
            `/astanait-office-module/api/v1/report/all`, { params }
        );
    }

    static getReportParameters(reportId: number) {
        return authAxios.get<ReportParameterDtoResponse[]>(
            `/astanait-office-module/api/v1/report/parameters/${reportId}`
        );
    }

    static getReportColumns(reportId: number) {
        return authAxios.get<ReportColumnsDtoResponse[]>(
            `/astanait-office-module/api/v1/report/columns/${reportId}`
        );
    }

    static generateReport(reportId: number, reportName: string, parameters: ParameterDtoRequest[]) {
        return authAxios.post(
            `/astanait-office-module/api/v1/report/generate`,
            parameters,
            {
                params: {
                    reportId
                },
                responseType: 'blob'
            }
        ).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Отчет-${reportName}.xlsx`); // or any other filename you want
            document.body.appendChild(link);
            link.click();
        });
    }

}