import authAxios from "../../../common/authAxios";
import { errorNotification } from "../../../helpers/errorNotification";
import { successNotification } from "../../../helpers/successNotification";
import { PhysicalEducationChooseDtoRequest } from "../../../interfaces/PhysicalEducationChooseDtoRequest";

export class PhysicalEducationCourseEnrollmentService {
    private static baseUrl = '/astanait-student-module/api/v1/pe';

    static getStudentPhysicalEducationChoicesBySetting() {
        const settingId = 3;
        return authAxios.get(`${this.baseUrl}/student-choose-by-setting`, {
            params: {
                settingId
            }
        })
            .then((response) => {
                // successNotification('Courses fetched successfully');
                return response.data;
            })
            .catch((error) => {
                errorNotification('Error fetching data', error.response?.status);
                console.error('Error:', error);
            });
    }

    static getPhysicalEducationChoicesByFilter(typeId: number, scheduleDay: number) {
        const settingId = 3;
        return authAxios.get(`${this.baseUrl}/choose-by-filter`, {
            params: { typeId, settingId, scheduleDay }
        })
            .then((response) => {
                // successNotification('Courses fetched successfully');
                return response.data;
            })
            .catch((error) => {
                errorNotification('Error fetching data', error.response?.status);
                console.error('Error:', error);
            });
    }

    static getPhysicalEducationTypes() {
        return authAxios.get(`${this.baseUrl}/types`)
            .then((response) => response.data)
            .catch((error) => {
                errorNotification('Error fetching physical education courses', error.response?.status);
                console.error('Error:', error);
            });
    }

    static createOrUpdateStudentChoose(studentId: number, chooseId: number) {
        return authAxios.post(`${this.baseUrl}/student-choose/create-or-update`, {
            studentId,
            chooseId
        })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                const errorMessage = error.response?.data?.message || 'Error selecting course';
                errorNotification(errorMessage);
            });
    }

    static getStudentPhysicalEducationChooseSettingsById() {
        const settingId = 3;
        return authAxios.get(`${this.baseUrl}/student-choose/settings/${settingId}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                errorNotification('Error fetching settings', error.response?.status);
                console.error('Error:', error);
            });
    }

    static getStudentPhysicalEducationChooseSettings() {
        return authAxios.get(`${this.baseUrl}/student-choose/settings`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                errorNotification('Error fetching settings', error.response?.status);
                console.error('Error:', error);
            });
    }

    static getStudentPhysicalEducationChooseSettingsByIdForOfficeRegistrar(id: number) {
        return authAxios.get(`${this.baseUrl}/student-choose/settings/${id}`)
            .then((response) => response.data)
            .catch((error) => {
                errorNotification('Error fetching settings by ID', error.response?.status);
                console.error('Error:', error);
            });
    }

    static createOrUpdateStudentChooseSettings(data: any) {
        return authAxios.post(`${this.baseUrl}/student-choose/settings/create-or-update`, data)
            .then((response) => {
                successNotification('Settings saved successfully');
            })
            .catch((error) => {
                errorNotification('Error submitting settings', error.response?.status);
                console.error('Error:', error);
            });
    }

    static deleteStudentChooseSettings(id: number) {
        return authAxios.delete(`${this.baseUrl}/student-choose/settings/delete/${id}`)
            .then((response) => {
                successNotification('Course successfully deleted');
            })
            .catch((error) => {
                errorNotification('Error deleting setting', error.response?.status);
                console.error('Error:', error);
            });
    }


    static getAllPhysicalEducationTypes() {
        return authAxios.get(`${this.baseUrl}/types`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                errorNotification('Error fetching types', error.response?.status);
                console.error('Error:', error);
            });
    }


    static createOrUpdatePhysicalEducationType(data: any) {
        return authAxios.post(`${this.baseUrl}/types/create-or-update`, data)
            .then((response) => successNotification('PE type saved successfully'))
            .catch((error) => {
                errorNotification('Error submitting settings', error.response?.status);
                console.error('Error:', error);
            });
    }

    static deletePhysicalEducationType(id: number) {
        return authAxios.delete(`${this.baseUrl}/types/delete/${id}`)
            .then((response) => successNotification('PE type deleted successfully'))
            .catch((error) => {
                errorNotification('Error deleting setting', error.response?.status);
                console.error('Error:', error);
            });
    }

    static getPhysicalEducationChoicesByFilterForOfficeRegistrar(typeId: number, settingId: number, scheduleDay: number, teacherId: number) {
        return authAxios.get(`${this.baseUrl}/choose-by-filter`, {
            params: { typeId, settingId, scheduleDay, teacherId }
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                errorNotification('Error fetching choices by filter', error.response?.status);
                console.error('Error:', error);
            });
    }

    static createOrUpdatePhysicalEducationChoose(data: PhysicalEducationChooseDtoRequest) {
        return authAxios.post(`${this.baseUrl}/choose/create-or-update`, data)
            .then(response => {
                successNotification('Course successfully created/updated');
            })
            .catch(error => {
                const errorMessage = error.response?.data?.message || 'Error creating/updating course';
                errorNotification(errorMessage);
                console.error('Error:', error);
            });
    }


    static deletePhysicalEducationChoose(id: number) {
        return authAxios.delete(`${this.baseUrl}/choose/delete/${id}`)
            .then((response) => {
                successNotification('Course successfully deleted');
            })
            .catch((error) => {
                const errorMessage = error.response?.data?.message || 'Error deleting course';
                errorNotification(errorMessage, error.response?.status);
                console.error('Error:', error);
            });
    }

    static getPhysicalEducationTeachers() {
        return authAxios.get(`${this.baseUrl}/teachers`)
            .then((response) => response.data)
            .catch((error) => {
                errorNotification('Error fetching teachers', error.response?.status);
                console.error('Error:', error);
            });
    }

}
