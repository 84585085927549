import React, { useState } from 'react';
import { Steps, Button, Space, Typography, Image } from 'antd';

interface InstructionsProps {
  closeInstructionModal: () => void;
}
interface StepsProps {
  title: string;
  content: JSX.Element;
}
const { Step } = Steps;
const { Title } = Typography;
const Instructions = ({ closeInstructionModal }: InstructionsProps) => {
  const [currentStep, setCurrentStep] = useState(0);

  const steps: StepsProps[] = [
    {
      title: '',
      content: (
        <>
          <br></br>
          <Space direction="vertical" size="large" style={{ textAlign: 'center', width: '100%' }}>
            <Image
              src="/assets/disciplines_table.png"
            // preview={{
            //   src: 'https://media.discordapp.net/attachments/919615975278661682/962982652300918834/disciplines_table.png?width=790&height=229',
            // }}
            />
            <Title level={5}>
              These are your tables with elective disciplines. In each table you should put a desired discipline by
              priority.
            </Title>
          </Space>
        </>
      ),
    },
    {
      title: '',
      content: (
        <>
          <br></br>
          <Space direction="vertical" size="large" style={{ textAlign: 'center' }}>
            <Image
              src="/assets/choice_process.gif"
            // preview={{
            //   src: 'https://media.discordapp.net/attachments/919615975278661682/962982652506419200/choice_process.gif?width=790&height=229',
            // }}
            />
            <Title level={5}>
              As you can see from the gif above, you should apply your choice by dragging disciplines up or down. The
              higher discipline is placed, the higher priority is.
            </Title>
          </Space>
        </>
      ),
    },
    {
      title: '',
      content: (
        <>
          <br></br>
          <Space direction="vertical" size="large" style={{ textAlign: 'center' }}>
            <Image
              src="/assets/final_step.PNG"
            // preview={{
            //   src: 'https://media.discordapp.net/attachments/919615975278661682/962982652049244200/final_step.png',
            // }}
            />
            <Title level={5}>
              Finally, after applying your choice, you should click on button 'SAVE' to save your choice. You can click
              on button 'Show my choice, to see your choice of disciplines'
            </Title>
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} style={{ padding: 20 }} />
        ))}
      </Steps>
      <div>{steps[currentStep].content}</div>
      <div>
        <Space size="middle">
          {currentStep > 0 && <Button onClick={() => setCurrentStep(currentStep - 1)}>Previous</Button>}
          {currentStep === steps.length - 1 && (
            <Button type="primary" onClick={closeInstructionModal}>
              Got it
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => setCurrentStep(currentStep + 1)}>
              Next
            </Button>
          )}
        </Space>
      </div>
    </>
  );
};

export default Instructions;
