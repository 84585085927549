import { Typography } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import AttendanceTableForOffice from './AttendanceTableForOffice';
import { useState } from 'react';
import { StudentDtoResponse } from '../../../interfaces/Student/StudentDtoResponse';
import StudentSearchForm from '../StudentTable/StudentSearchForm';



const StudentsDetailsPage = () => {
    const [selectedTrimester, setSelectedTrimester] = useState<number>();
    const [studentsList, setStudentsList] = useState<StudentDtoResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleData = (studentsList: StudentDtoResponse[], loading: boolean, selectedTrimester: number) => {
        setStudentsList(studentsList);
        setLoading(loading);
        setSelectedTrimester(selectedTrimester);
    };

    return (
        <PageWrapper>
            <Typography.Title level={3}>Attendance information</Typography.Title>
            <StudentSearchForm onData={handleData} isAttendance={true} />

            <AttendanceTableForOffice loading={loading} tableData={studentsList.filter((student) => student.statusTypeId.id === 1 && student.studyingStatusType.id === 1)} trimester={selectedTrimester ?? 0} />
        </PageWrapper>
    );
};

export default StudentsDetailsPage;
