import React from 'react'
import Page from '../../../../ui/Page'
import { Table } from 'antd'
import { SurveyService } from '../../../../services/student/SurveyService'
import { SurveyDtoResponse } from '../../../../interfaces/Survey/SurveyDtoResponse'
import { useHistory } from 'react-router-dom'

const StudentSurveyListPage = () => {
  const navigate = useHistory();

  const [surveys, setSurveys] = React.useState<SurveyDtoResponse[]>([])

  React.useEffect(() => {
    SurveyService.getSurveys()
      .then(({ data }) => setSurveys(data))
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name"
    },
  ]

  const selectRow = (id: number) => {
    navigate.push(`/survey/${id}`);
  };

  return (
    <Page>
      <Table bordered
        columns={columns}
        dataSource={surveys.map((survey, i) => ({
          key: i + 1,
          id: survey.id,
          name: survey.name,
          createdDate: survey.createdDate
        }))}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => selectRow(record.id),
            onMouseEnter: (e) => (e.currentTarget.style.cursor = 'pointer'),
          };
        }} />
    </Page>
  )
}

export default StudentSurveyListPage