import React, { useState } from 'react';
import Page from '../../../../ui/Page';
import { Button, Col, DatePicker, Form, Input, InputRef, Modal, Row, Select, Space, Table, Tag, Typography } from 'antd';
import { SsciService } from '../../../../services/student/SsciService';
import { InfoCircleOutlined } from '@ant-design/icons';
import useModal from '../../../../ui/Modal/useModal';
import { FileService } from '../../../../services/file/FileService';
import { successNotification } from '../../../../helpers/successNotification';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { SsciApplicationProjection } from '../../../../interfaces/Ssci/SsciApplicationProjection';
import { getColumnSearchProps } from '../../../../common/getColumnSearchProps';
const { Option } = Select;

const SsciStudentApplications = () => {
  const [applications, setApplications] = React.useState<SsciApplicationProjection[]>([]);
  const [totalApplications, setTotalApplications] = React.useState(0);
  const [modalData, setModalData] = React.useState<SsciApplicationProjection>();
  const [currentPage, setCurrentPage] = React.useState<number | undefined>(0);
  const [pageSize, setPageSize] = React.useState<number | undefined>(10);

  const { isModalOpen, handleCancel, showModal } = useModal();
  const [filters, setFilters] = useState<Record<string, any>>({});

  const [isDeclineOpen, setIsDeclineOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const searchInput = React.useRef<InputRef>(null);

  const [form] = Form.useForm();


  const fetchFilteredApplications = async (
    page: number = currentPage || 0, 
    size: number = pageSize || 10, 
    newFilters: Record<string, any> = filters
  ) => {
    setLoading(true);
    try {
      const { name, status, startDate, endDate } = newFilters;
      const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : undefined;
      const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : undefined;

      const response = await SsciService.getApplicationsByFilter(page, pageSize, {
        name,
        approved: status,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
      setApplications(response.data.list);
      setTotalApplications(response.data.total_number);
    } catch (error) {
      console.error('Failed to fetch filtered applications:', error);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    fetchFilteredApplications();
  }, [loader, currentPage, totalApplications, pageSize]);
  // const handleClearFilters = () => {
  //   form.resetFields();
  //   fetchFilteredApplications({});
  // };

  // React.useEffect(() => {
  //   setLoading(true);
  //   SsciService.getAllApplications(currentPage ? currentPage - 1 : 0, pageSize)
  //     .then(({ data }) => {
  //       setApplications(data.list);
  //       setTotalApplications(data.total_number);
  //     })
  //     .finally(() => setLoading(false));
  // }, [loader, currentPage, totalApplications, pageSize]);

  const handleFormSubmit = (values: Record<string, any>) => {
    setCurrentPage(0); 
    setFilters(values); 
    fetchFilteredApplications(0, pageSize, values); 
  };
  
  const openModal = (record: any, state?: boolean) => {
    if (state) {
      setIsDeclineOpen(state);
    } else {
      showModal();
    }
    setModalData(record);
  };

  const setNullApprovedApplication = (record: any) => {
    setLoading(true);
    SsciService.setNullapproveApplication(record.id)
      .then(() => {
        setLoader(!loader);
        successNotification('Successfully updated!');
      })
      .finally(() => setLoading(false));
  };

  const handleUpdate = (status: boolean | string, id: number, comment?: string) => {
    SsciService.approveApplication({
      approved: status,
      comment: comment,
      id: id,
    })
      .then(() => {
        setLoader(!loader);
        successNotification('Successfully sended!');
      })
      .finally(() => {
        setIsDeclineOpen(false);
        setLoader(!loader);
      });
  };

  // const getColumnSearchProps = (dataIndex: string, inputRef: React.Ref<InputRef>, isStudent: boolean) => ({
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={inputRef}
  //         placeholder={`Поиск по ФИО`}
  //         value={selectedKeys[0]}
  //         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => confirm()}
  //         style={{ marginBottom: 8, display: 'block' }}
  //       />
  //       <Space>
  //         <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
  //           Поиск
  //         </Button>
  //         <Button
  //           onClick={() => {
  //             clearFilters && clearFilters();
  //             confirm();
  //           }}
  //           size="small"
  //         >
  //           Очистить
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  //   onFilter: (value: any, record: any) => {
  //     return isStudent
  //       ? `${record.student?.surnameKz + ' ' + record.student?.nameKz + ' ' + record.student?.patronymicKz}`
  //         .toLowerCase()
  //         .includes(value.toLowerCase())
  //       : `${record.eventName}`.toLowerCase().includes(value.toLowerCase());
  //   },
  // });

  const columns: ColumnsType<SsciApplicationProjection> = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
      render: (text: string, record: any, index: number) => {
          const safeCurrentPage = currentPage ?? 0; 
          const safePageSize = pageSize ?? 10;
          return safeCurrentPage * safePageSize + index + 1;
      },
  }
  ,
    {
      title: 'Student',
      dataIndex: 'fullname',
      key: 'fullname',
      render: (_, record, idx) => record.fullname,
      ...getColumnSearchProps('fullname', searchInput),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (_, record, idx) => (record.role !== null ? record.role : 'Not Found'),
    },
    {
      title: 'Index status',
      dataIndex: 'indexStatus',
      key: 'indexStatus',
      render: (_, record, idx) => record.index,
    },
    {
      title: 'Event name',
      dataIndex: 'eventName',
      key: 'eventName',
      ...getColumnSearchProps('eventName', searchInput),
    },
    {
      title: 'Event date',
      dataIndex: 'eventDate',
      key: 'eventDate',
      render: (_, record, idx) => moment(record.eventDate).format('LL'),
      sorter: (a: SsciApplicationProjection, b: SsciApplicationProjection) =>
        new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'hours',
      sorter: (a: SsciApplicationProjection, b: SsciApplicationProjection) => a.hours - b.hours,
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: '5%',
      filters: [
        {
          text: 'Approved',
          value: '1',
        },
        {
          text: 'Declined',
          value: '0',
        },
        {
          text: 'Not reviewed',
          value: '2',
        },
      ],
      onFilter: (value: any, record) =>
        record.approved === null ? value === '2' : record.approved === true ? value === '1' : value === '0',
      render: (text: any, record: any) => (
        <Space size="small">
          <Button
            onClick={() => {
              openModal(record);
            }}
            icon={<InfoCircleOutlined />}
          >
            Approve details
          </Button>
          {record.approved !== null ? (
            <>
              {record.approved === true ? (
                <Tag color="green">Approved</Tag>
              ) : (
                <Button type="primary" danger onClick={() => openModal(record, true)}>
                  Show reason
                </Button>
              )}
              <Button type="primary" danger onClick={() => setNullApprovedApplication(record)}>
                Undo
              </Button>
            </>
          ) : (
            <Space size={'small'}>
              <Button
                type="primary"
                onClick={() => {
                  handleUpdate('true', record.id);
                }}
              >
                Agree
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => {
                  openModal(record, true);
                }}
              >
                Disagree
              </Button>
            </Space>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Page title="Applications List" subtitle="Applications of students to SSCI">
        <Row gutter={16} style={{ marginBottom: '20px' }}>
          <Col span={14}>
            <Space direction="vertical" size="large">
              <Form form={form} onFinish={handleFormSubmit}>
                    <Form.Item name="name" 
                    style={{ marginBottom: '20px' }}
                    //label="Поиск по Имени и Фамилии"
                    >
                      <Input placeholder="Поиск по Имени и Фамилии" />
                    </Form.Item>
                    <Form.Item name="status"
                  // label="Выберите Статус"
                    >
                      <Select
                        placeholder="Выберите Статус"
                        allowClear
                        style={{ width: 500 ? 500 : 600 }}
                      // onClear={handleClearFilters}
                      >
                        <Option value="false" key={0} >Not Approved</Option>
                        <Option value="true" key={1}>Approved</Option>
                      </Select>
                    </Form.Item>
                  <Space dir={'horizontal'} size="large" direction="horizontal">
                      <Space align="start">
                        <Form.Item name="startDate" label="От">
                          <DatePicker placeholder="Выберите дату" style={{ width: '100%' }} />
                        </Form.Item>
                      </Space>
                      <Space align="start">
                        <Form.Item name="endDate" label="До">
                          <DatePicker placeholder="Выберите дату" style={{ width: '100%' }} />
                        </Form.Item>
                      </Space>
                      <Space align="end" >
                        <Form.Item>
                          <Button type="primary" htmlType="submit" style={{ marginLeft: 20 }}>
                            Поиск
                          </Button>
                        </Form.Item>
                      </Space>
                  </Space>
              </Form>
            </Space>
          </Col>
        </Row>
      <Table
        bordered
        columns={columns}
        dataSource={applications}
        loading={loading}
        onChange={(pagination) => {
          setCurrentPage(pagination.current ? pagination.current-1 : 0);
                    setPageSize(pagination.pageSize);
        }}
        pagination={{ current:(currentPage ?? 0) + 1,
           total: totalApplications,
            pageSize: pageSize }}
      />

      {modalData && (
        <Modal open={isModalOpen} title={modalData.fullname} onCancel={handleCancel}>
          <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
            <Space direction="vertical" size={'small'} style={{ width: '100%' }}>
              <Typography>Students Responsibilities</Typography>
              <Input.TextArea disabled value={String(modalData.info)} />
            </Space>
            {modalData.fileId && (
              <Space direction="vertical" size={'small'} style={{ width: '100%' }}>
                <Typography>File</Typography>
                <Button
                  onClick={() => {
                    FileService.getFileById(modalData.fileId, 'SSCI File');
                  }}
                >
                  Download file
                </Button>
              </Space>
            )}
            <Space direction="vertical" size={'small'} style={{ width: '100%' }}>
              <Typography>SSCI points: {modalData.points} / 100</Typography>
            </Space>
          </Space>
        </Modal>
      )}
      <Modal
        open={isDeclineOpen}
        title={'Please, write the detailed reason'}
        onOk={form.submit}
        onCancel={() => setIsDeclineOpen(false)}
      >
        <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
          {modalData?.approved === null ? (
            <Form
              form={form}
              onFinish={(e) => {
                handleUpdate('false', modalData.id, form.getFieldValue('comment'));
              }}
            >
              <Form.Item name="comment">
                <Input.TextArea />
              </Form.Item>
            </Form>
          ) : (
            <Input.TextArea value={modalData?.comment} readOnly />
          )}
        </Space>
      </Modal>
    </Page>
  );
};

export default SsciStudentApplications;
