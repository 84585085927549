import { TeacherEducationInformationRequestDto } from "../../../../interfaces/Teacher/TeacherEducationInformationDtoRequest";
import { TeacherEducationInformationResponceDto } from "../../../../interfaces/Teacher/TeacherEducationInformationResponceDto";
import { TeacherService } from "../../../../services/TeacherService";
import UniversalTable from "../UniversalTeachersTable";
import { Field } from "../../../../interfaces/Field";
import { successNotification } from "../../../../helpers/successNotification";
import useFetchData from "../../../../hooks/useFetchData";
import { AcademicDegreeDtoResponse } from "../../../../interfaces/AcademicDegreeDtoResponse";


interface TeacherEducationTableProps {
    isPublic: boolean;
}

const TeacherEducationTable = ({ isPublic }: TeacherEducationTableProps) => {
    const { data: educationalInformation, fetchData: fetchEducationalInformation } = useFetchData<TeacherEducationInformationResponceDto[]>(TeacherService.getTeacherEducationInformationList, !isPublic);
    const { data: degrees } = useFetchData<AcademicDegreeDtoResponse[]>(TeacherService.getDegrees, !isPublic);

    const educationFields: Field[] = [
        { name: 'educationalInstitution', label: 'Educational institutional name', placeholder: "Write educational institution", type: "text" },
        { name: 'admissionYear', label: 'Year of admission', type: "year" },
        { name: 'graduationYear', label: 'Year of graduation', type: "year" },
        { name: 'specialization', label: 'Specialization', placeholder: "Write specialization", type: "text" },
        { name: 'academicDegree', label: 'Academic degree', placeholder: "Select academic degree", type: "select", options: degrees?.map((degree) => ({ value: degree.id, label: degree.titleEn })) }
    ];

    const addRecord = (record: TeacherEducationInformationRequestDto) => {
        TeacherService.createOrUpdateTeacherEducationInformation(record)
            .then(() => {
                successNotification('Educational information added!');
                fetchEducationalInformation()
            })
    };

    const editRecord = (record: TeacherEducationInformationRequestDto) => {
        TeacherService.createOrUpdateTeacherEducationInformation(record)
            .then(() => {
                successNotification('Educational information updated!');
                fetchEducationalInformation()
            })
    };

    const deleteRecord = (record: TeacherEducationInformationRequestDto) => {
        TeacherService.deleteTeacherEducationInformation(record.id)
            .then(() => {
                successNotification('Educational information deleted!');
                fetchEducationalInformation()
            })
    };


    return (
        <UniversalTable
            columns={
                [
                    {
                        title: "#",
                        dataIndex: "key",
                        key: "key",
                    },
                    {
                        title: "Educational institution",
                        dataIndex: "educationalInstitution",
                        key: "educationalInstitution"
                    },
                    {
                        title: "Year of admission",
                        dataIndex: "admissionYear",
                        key: "admissionYear"
                    },
                    {
                        title: "Year of graduation",
                        dataIndex: "graduationYear",
                        key: "graduationYear"
                    },
                    {
                        title: "Specialization",
                        dataIndex: "specialization",
                        key: "specialization"
                    },
                    {
                        title: "Academic Degree",
                        dataIndex: "degree",
                        key: "degree",
                    }
                ]
            }
            dataSource={educationalInformation ? educationalInformation.map((educationInformation, i) => ({
                id: educationInformation.id,
                key: i + 1,
                educationalInstitution: educationInformation.educationalInstitution,
                admissionYear: educationInformation.admissionYear,
                graduationYear: educationInformation.graduationYear,
                specialization: educationInformation.specialization,
                academicDegree: educationInformation.academicDegree.id,
                degree: educationInformation.academicDegree.titleEn
            })) : []}
            addRecord={(e: TeacherEducationInformationRequestDto) => addRecord(e)}
            editRecord={(e: TeacherEducationInformationRequestDto) => editRecord(e)}
            deleteRecord={(e: TeacherEducationInformationRequestDto) => deleteRecord(e)}
            fields={educationFields}
            isPublic={isPublic}
        />
    )
}

export default TeacherEducationTable;
