import { useState, useEffect } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

interface FetchData<T> {
  data: T | undefined;
  isLoading: boolean;
  error: string | null;
  fetchData: () => void;
}

function useFetchData<T>(fetchingMethod: () => Promise<AxiosResponse<T>>, fetch?: boolean): FetchData<T> {
  const [data, setData] = useState<T | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    setIsLoading(true);
    fetchingMethod()
      .then(({ data }) => {
        setData(data);
        setError(null);
      })
      .catch((err: AxiosError) => {
        setError(err.message);
        setData(undefined);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (fetch) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingMethod, fetch]);

  return { data, isLoading, error, fetchData };
}

export default useFetchData;
