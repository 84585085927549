const grantPermission = (requestedRole, type) => {
  const baseRole = JSON.parse(localStorage.getItem('user'));
  var isValid = false;
  if (baseRole) {
    if (type === 'baseRole') {
      isValid = requestedRole === baseRole.baseRole;
      return isValid;
    } else {
      baseRole.roleList.forEach((role) => {
        if (role.roleName === requestedRole) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    }
  }
};

export default grantPermission;
