import useFetchData from '../../../../hooks/useFetchData';
import { TeacherOrderDtoResponse } from '../../../../interfaces/Teacher/Order/TeacherOrderDtoResponse';
import { TeacherService } from '../../../../services/TeacherService';
import UniversalTable from '../UniversalTeachersTable';
import { TeacherOrderDtoRequest } from '../../../../interfaces/Teacher/Order/TeacherOrderDtoRequest';
import { successNotification } from '../../../../helpers/successNotification';
import { useCallback } from 'react';
import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import grantPermission from '../../../../helpers/grantPermission';

interface TeacherOrderTableProps {
    userId?: number;
}

const TeacherOrderTable = ({ userId }: TeacherOrderTableProps) => {
    const callbackICInfo = useCallback(() => {
        if (userId) {
            return TeacherService.getTeacherOrderByUser(userId);
        } else {
            return TeacherService.getTeacherOrders();
        }
    }, [userId])

    const history = useHistory();
    const { data: orders, fetchData: fetchOrders } = useFetchData<TeacherOrderDtoResponse[]>(callbackICInfo, true);

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Number',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        }

    ];

    const addRecord = (record: TeacherOrderDtoRequest) => {
        TeacherService.createOrUpdateTeacherOrder(record)
            .then(() => {
                fetchOrders();
            })
            .finally(() => {
                successNotification('Order added!')
            })
    };

    const editRecord = (record: TeacherOrderDtoRequest) => {
        TeacherService.createOrUpdateTeacherOrder(record)
            .then(() => {
                fetchOrders();
            })
            .finally(() => {
                successNotification('Order updated!')
            })
    };

    const deleteRecord = (record: TeacherOrderDtoRequest) => {
        TeacherService.deleteTeacherOrder(record.id)
            .then(() => {
                fetchOrders();
            })
            .finally(() => {
                successNotification('Order deleted!')
            })
    };

    return (
        <>
            <Button hidden={!grantPermission('HR', 'addRole')} type='primary' icon={<PlusCircleOutlined />} onClick={() => { history.push('/orders') }}>Создать приказ</Button>
            <UniversalTable
                columns={columns}
                dataSource={orders ? orders.map((order, i) => ({
                    id: order.id,
                    key: i + 1,
                    name: order.name,
                    date: order.issueDate,
                    number: order.number,
                    type: order.type.nameEn,
                    fileId: order.fileId,
                })) : []}
                addRecord={(e: TeacherOrderDtoRequest) => addRecord(e)}
                editRecord={(e: TeacherOrderDtoRequest) => editRecord(e)}
                deleteRecord={(e: TeacherOrderDtoRequest) => deleteRecord(e)}
                fields={[
                    { name: 'fileId', label: 'File', type: 'file' }
                ]}
                hideDelete={true}
                hideEdit={true}
                hideCreate={true}
                isPublic={false}
            />
        </>
    )
}

export default TeacherOrderTable