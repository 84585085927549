import PageWrapper from '../../../../ui/PageWrapper';
import { Typography, Button, Space, Select, Table } from 'antd';
import SelectOptionsFilter from '../SelectOptionsFilter';
import useDeanOfficeAppointmentList from './useDeanOfficeAppointmentList';


const { Title } = Typography;

const DeanOfficeAppointment = () => {
    const {
        selectedFiltersOption,
        handleSelect,
        selectedDate,
        availableDate,
        appointmentData,

    } = useDeanOfficeAppointmentList();


    const options = availableDate.map(date => ({ value: date, label: date }));

    const columns: any[] = [
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            width: '10%',
        },
        {
            title: 'Student',
            dataIndex: 'student',
            key: 'student',
        },
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
        },
        {
            title: 'Student Question',
            dataIndex: 'studentQuestion',
            key: 'studentQuestion',
        },


    ];
    const dataSource = appointmentData.map(data => {
        // Создаем объект Date из строки времени
        const date = new Date(data.appointmentDateTime);

        // Получаем часы и минуты
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Форматируем время в нужный формат (допустим, 09:30)
        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

        return {
            time: formattedTime,
            student: data.student.nameEn + " " + data.student.surnameEn,
            group: data.student.group.title,
            studentQuestion: data.studentQuestion
        };
    });

    return (
        <PageWrapper>
            <section>
                <header >
                    <Title level={3} >
                        Appointments at the Dean's office
                    </Title>
                </header>
                <Space direction='vertical' style={{ width: "100%" }}>
                    <Space size={20} direction="vertical" style={{ marginBottom: '20px', width: "100%" }}>
                        <SelectOptionsFilter
                            placeholder="Цель посещения"
                            selectedOption={selectedFiltersOption}
                            onSelect={(value) => handleSelect.handleSelectOption(value)}

                            options={[
                                {
                                    id: 1,
                                    option: 'Для консультации',
                                },
                                {
                                    id: 2,
                                    option: 'Для подачи заявления',
                                },
                            ]}
                        ></SelectOptionsFilter>


                        <>
                            <div>

                                <Select
                                    placeholder="Дата"
                                    value={selectedDate}
                                    onSelect={(value) => handleSelect.handleSelectDate(value)}
                                    options={options}
                                    style={{ width: 400 }}
                                ></Select>

                            </div>
                        </>

                        {selectedDate && (
                            <>

                                <Table
                                    columns={columns}
                                    dataSource={dataSource} />
                            </>
                        )}






                    </Space>

                </Space>
            </section>
        </PageWrapper >
    );
};

export default DeanOfficeAppointment;
