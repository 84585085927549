import { Button, Form, Select, Statistic, Tabs, Typography } from 'antd'
import PageWrapper from '../../../ui/PageWrapper'
import { Bar, Pie } from 'react-chartjs-2';
import useStatisticsPage from './useStatisticsPage';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';



const StudentStatisticsPage = () => {
  ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

  const { genderData, totalNumber, ageData, ageOptions, academicDegrees, educationalPrograms, courseOptions, form, handleSubmitFilter, handleEducationalDegreeSelect, handleSelectCourse } = useStatisticsPage();

  return (
    <PageWrapper>
      <Typography.Title level={2}>Statistics</Typography.Title>
      <Button type="primary" href={"https://app.powerbi.com/links/WKzCOLE0hi?ctid=158f15f3-83e0-4906-824c-69bdc50d9d61&pbi_source=linkShare"}>Statistics</Button><br/><br/>
      <Form form={form} onFinish={handleSubmitFilter}>
        <Form.Item name={"academicDegree"}>
          <Select onSelect={handleEducationalDegreeSelect} placeholder={"Choose educational degree"}>
            {academicDegrees.map((degree) => (
              <Select.Option key={degree.id} value={degree.id}>
                {degree.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={"course"}>
          <Select onSelect={handleSelectCourse} placeholder={"Choose course"}>
            {courseOptions.map((course) => (
              <Select.Option key={course.id} value={course.id}>
                {course.year}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={"educationProgram"}>
          <Select placeholder={"Choose educational program"}>
            {educationalPrograms.map((programs) => (
              <Select.Option key={programs.id} value={programs.id}>
                {programs.titleEn}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

      <Statistic style={{ marginBottom: 20 }} title="Total number of students" value={totalNumber} />

      <Tabs defaultActiveKey="1" type="card" size={"large"}>
        <Tabs.TabPane tab="By gender" key="1">
          <Pie style={{ width: "100%", maxHeight: 500 }} data={genderData} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="By Age" key="2">
          <Bar style={{ width: "100%", maxHeight: 500 }} data={ageData} options={ageOptions} />
        </Tabs.TabPane>
      </Tabs>
    </PageWrapper>
  )
}

export default StudentStatisticsPage