import { getColumnSearchProps } from '../../../../common/getColumnSearchProps';
import { useRef, useEffect, useState } from 'react';
import { Row, Table, Col, List, InputRef } from 'antd';
import grantPermission from '../../../../helpers/grantPermission';
import { CurriculumService } from '../../../../services/CurriculumService';
import { StudentAffirmationService } from '../../../../services/StudentAffirmationService';
import { CurriculumDtoResponse } from '../../../../interfaces/EducationalPrograms/CurriculumDtoResponse';
import { StudentAffirmationType } from '../../../../services/StudentAffirmationService';
import { BreakDownByHourDtoResponse } from '../../../../interfaces/EducationalPrograms/BreakDownByHourDtoResponse';

const StudentCurriculumTable = () => {
  const inputRef = useRef() as React.Ref<InputRef>;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [approvedDisciplines, setApprovedDisciplines] = useState<CurriculumDtoResponse[]>([]);
  const [choiceStatus, setChoiceStatus] = useState<string | undefined>(undefined);

  const getDisciplineType = (componentId: number) => {
    switch (componentId) {
      case 1:
        return 'Mandatory Discipline';
      case 2:
      case 3:
        return 'Elective Discipline';
      default:
        return 'Unknown';
    }
  };

  const columns = [
    {
      title: '№',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Code of discipline',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Discipline Name',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title', inputRef),
    },
    {
      title: 'Number of Credits',
      dataIndex: 'credits',
      key: 'credits',
    },
    {
      title: 'Trimester',
      dataIndex: 'numberOfTrimester',
      key: 'numberOfTrimester',
      sorter: {
        compare: (a: any, b: any) => a.numberOfTrimester - b.numberOfTrimester,
        multiple: 2,
      },
    },
    {
      title: (
        <Row>
          <Col flex={0}>Type of Lesson</Col>
          <Col flex={'auto'}></Col>
          <Col flex={0}>Hours</Col>
        </Row>
      ),
      dataIndex: 'lesson',
      key: 'lesson',
      width: 350,
    },
  ];
  useEffect(() => {
    if (grantPermission('STUDENTS')) {
      CurriculumService.getStudentCurriculumForNextYear()
        .then(({ data }) => (data.length === 0 ? setIsButtonDisabled(true) : setApprovedDisciplines(data)))
        .finally(() => setLoading(false));

      StudentAffirmationService.getStudentStatusByStudentAndType(StudentAffirmationType.ELECTIVE_DISCIPLINES)
        .then((response) => setChoiceStatus(response.data));
    }
  }, []);

  const getAllLessonTypes = (lessons: BreakDownByHourDtoResponse[]) => {
    return (
      <List
        size="small"
        dataSource={lessons}
        renderItem={(item) => (
          <List.Item style={{ padding: '5px 0' }}>
            <List.Item.Meta description={item.lessonsTypeId.titleEn} />
            <div>{item.hours}</div>
          </List.Item>
        )}
      />
    );
  };

  const data = approvedDisciplines
    .slice()
    .sort((a, b) => a.year - b.year || a.numberOfTrimester - b.numberOfTrimester)
    .map((approvedDiscipline, idx) => ({
      key: idx,
      number: idx + 1,
      title: `${approvedDiscipline.discipline.titleEn} / ${approvedDiscipline.discipline.titleKz} / ${approvedDiscipline.discipline.titleRu}`,
      type: approvedDiscipline.component.componentAbbreviationEn,
      code: approvedDiscipline.discipline?.code,
      credits: approvedDiscipline.discipline.volumeCredits,
      lesson: getAllLessonTypes(approvedDiscipline.discipline.breakDownByHour),
      numberOfTrimester: approvedDiscipline.numberOfTrimester,
    }));

  return (
    <Table columns={columns} loading={loading} dataSource={data} pagination={false} />
  );
};

export default StudentCurriculumTable;
