import { Typography, Table, Space } from 'antd';
import { useEffect, useState } from 'react';
import { CertificateIssuanceRequestDtoResponse } from '../../../interfaces/deansOffice/CertificateIssuanceRequestDtoResponse';
import { CertificateIssuanceService } from '../../../services/deansOffice/CertificateIssuanceService';
import Page from '../../../ui/Page/Page';
import Center from '../../../ui/Center';
import RowSpan from '../../../ui/RowSpan';

const { Text } = Typography;
interface Props {
  certificateId: number;
}

const CertificateWebPage = ({ certificateId }: Props) => {
  const [certificate, setCertificate] = useState<CertificateIssuanceRequestDtoResponse>();

  const columns: any = [
    {
      title: 'Аты-жөні',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Лауазымы',
      dataIndex: 'status',
      key: 'status',
      width: 250,
    },
    {
      title: 'Қол қойылған күні мен уақыты',
      dataIndex: 'date',
      key: 'date',
    },
  ];
  useEffect(() => {
    CertificateIssuanceService.getIssuanceByIdPublic(certificateId)
      .then((response) => {
        setCertificate(response.data);
      });
  }, [certificateId]);

  const getFullDate = (val: string): string => {
    const dateAndTime = val.split('T');
    const date = dateAndTime[0].split('-').reverse().join('-');
    const time = dateAndTime[1].split('.')[0];
    return date + ' ' + time;
  };

  return (
    <Page>
      <Center style={{ marginBottom: '50px' }}>
        <>
          <div className="logo-container"></div>
          <Space style={{ width: '100%', justifyContent: 'center' }}>
            <Typography.Title level={2}>Astana IT University</Typography.Title>
          </Space>
        </>
      </Center>
      {certificate && (
        <RowSpan
          left={
            <Space direction="vertical">
              <div className=" col-md-12">
                <div className="col-md-12 text">
                  <u>
                    <strong>
                      <em className="ng-scope">Өтінім туралы мәліметтер</em>
                    </strong>
                  </u>
                </div>
              </div>
              <div className=" col-md-12">
                <Text strong className="col-md-4 label-colon ng-scope">
                  Білім алушының аты-жөні:
                </Text>
                <Text>{certificate.student.nameKz + ' ' + certificate.student.surnameKz}</Text>
              </div>

              <div className=" col-md-12">
                <Text strong className="col-md-4 label-colon ng-scope">
                  ЖСН:
                </Text>
                <Text>{certificate.student.iin}</Text>
              </div>

              <div className=" col-md-12">
                <Text strong className="col-md-4 label-num-sign ng-scope">
                  Өтінім:
                </Text>
                <Text>{certificate.number}</Text>
              </div>
              <div className=" col-md-12">
                <Text strong className="col-md-4 label-colon ng-scope">
                  Қызметтің атауы:
                </Text>
                <Text>{certificate.type.name}</Text>
              </div>

              <div className=" col-md-12">
                <Text strong className="col-md-4 label-colon ng-scope">
                  Өтініш мәртебесі:
                </Text>
                <Text>Берілді</Text>
              </div>

              <div className=" col-md-12">
                <Text strong className="col-md-4 label-colon ng-scope">
                  Мәртебенің тағайындалған күні мен уақыты:
                </Text>
                <Text>{getFullDate(certificate.issuedDate.toString())}</Text>
              </div>
            </Space>
          }
          right={
            <Space direction="vertical">
              <div>
                <div>
                  <u>
                    <strong>
                      <em>Орындаушы туралы мәліметтер</em>
                    </strong>
                  </u>
                </div>
              </div>
              <Table
                pagination={false}
                columns={columns}
                dataSource={[
                  {
                    name: 'Хикметов Аскар Кусупбекович',
                    status: 'Ректор',
                    date: getFullDate(certificate.issuedDate.toString()),
                  },
                ]}
              />
            </Space>
          }
        />
      )}
    </Page>
  );
};

export default CertificateWebPage;
