import { Button, DatePicker, Form, Input, Select, Space, Upload, UploadProps } from 'antd'
import { useState } from 'react'
import { TeacherDegreeDtoRequest } from '../../../../interfaces/Teacher/TeacherDegreeDtoRequest';
import { TeacherDegreeDtoResponse } from '../../../../interfaces/Teacher/TeacherDegreeDtoResponse'
import { TeacherEducationalDegree } from '../../../../interfaces/Teacher/TeacherEducationalDegree';
import { TeacherService } from '../../../../services/TeacherService';
import UniversalTable from '../UniversalTeachersTable';
import useFetchData from '../../../../hooks/useFetchData';
import { successNotification } from '../../../../helpers/successNotification';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload/interface';
import grantPermission from '../../../../helpers/grantPermission';
import { getFullDate } from '../../../../common/getFullDate';
import moment from 'moment';

interface TeacherEducationTableProps {
    isPublic: boolean;
}


const TeacherAcademicTitleTable = ({ isPublic }: TeacherEducationTableProps) => {
    const { data: teacherDegree, fetchData: fetchDegree } = useFetchData<TeacherDegreeDtoResponse[]>(TeacherService.getDegreeList, true);
    const [educationalDegreeList, setEducationalDegreeList] = useState<TeacherEducationalDegree[]>([]);
    const [hideEditModal, setHideEditModal] = useState<boolean>(false);
    const [form] = Form.useForm();

    const uploadProps: UploadProps = {
        beforeUpload: (file) => {
            return false;
        },
        onChange: (e) => {
            form.setFieldsValue({ "file": e.fileList[0].originFileObj as RcFile });
        },
    }

    const handleDownload = (record: any) => {
        // PublicService.get(record.fileId, "Academic title file" as string);
    };


    const columns = grantPermission('HR') || !isPublic ? [
        {
            title: "#",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Name",
            dataIndex: "degree",
            key: "degree"
        },
        {
            title: "Speciality",
            dataIndex: "speciality",
            key: "speciality"
        },
        {
            title: "Cipher",
            dataIndex: "cipher",
            key: "cipher",
        },
        {
            title: "Award year",
            dataIndex: "awardYear",
            key: "awardYear",
        },
        {
            title: 'Download file',
            key: 'download',
            render: (_: string, record: any) => (
                <Space>
                    <Button icon={<DownloadOutlined />} onClick={() => handleDownload(record)}>Download</Button>
                </Space>
            ),
        }
    ] : [
        {
            title: "#",
            dataIndex: "key",
            key: "key"
        },
        {
            title: "Name",
            dataIndex: "degree",
            key: "degree"
        },
        {
            title: "Speciality",
            dataIndex: "speciality",
            key: "speciality"
        },
        {
            title: "Cipher",
            dataIndex: "cipher",
            key: "cipher",
        },
        {
            title: "Award year",
            dataIndex: "awardYear",
            key: "awardYear",
            render: (date: string) => getFullDate(date)
        },
    ];

    const handleSelectType = (typeid: number) => {
        TeacherService.getEducationalDegreesByType(typeid)
            .then(({ data }) => setEducationalDegreeList(data))
    }

    const addRecord = (record: TeacherDegreeDtoRequest) => {
        TeacherService.createDegree(record)
            .then(() => successNotification('Degree was created!'))
            .finally(() => fetchDegree())
    };

    const editRecord = (record: TeacherDegreeDtoRequest) => {
        TeacherService.createDegree(record)
            .then(() => successNotification('Degree was updated!'))
            .finally(() => fetchDegree())
    };

    const deleteRecord = (record: TeacherDegreeDtoRequest) => {
        TeacherService.deleteDegree(record.id)
            .then(() => successNotification('Degree was deleted!'))
            .finally(() => fetchDegree())
    };

    const handleEditModal = (record: any) => {
        setHideEditModal(true);
        TeacherService.getEducationalDegreesByType(record.degreeType)
            .then(({ data }) => setEducationalDegreeList(data));
    };

    return (
        <UniversalTable
            columns={columns}
            dataSource={teacherDegree ? teacherDegree.map((teacherDegree, i) => ({
                id: teacherDegree.id,
                key: i + 1,
                degree: teacherDegree.teacherEducationalDegree.titleEn,
                degreeType: teacherDegree.teacherEducationalDegree.type,
                teacherEducationalDegree: teacherDegree.teacherEducationalDegree.id,
                speciality: teacherDegree.speciality,
                cipher: teacherDegree.cipher,
                awardYear: teacherDegree.awardYear,
                fileId: teacherDegree.fileId
            })) : []}
            addRecord={(e: TeacherDegreeDtoRequest) => addRecord(e)}
            editRecord={(e: TeacherDegreeDtoRequest) => editRecord(e)}
            deleteRecord={(e: TeacherDegreeDtoRequest) => deleteRecord(e)}
            editModalLogic={handleEditModal}
            createModalLogic={() => setHideEditModal(false)}
            customForm={
                <Form form={form} layout='vertical'>
                    {hideEditModal ? <></> : <Form.Item label="Degree type">
                        <Select
                            placeholder="Select degree type"
                            onChange={handleSelectType}
                        >
                            <Select.Option key={1} value={1}>
                                Academic degree
                            </Select.Option>
                            <Select.Option key={2} value={2}>
                                Academic title
                            </Select.Option>
                        </Select>
                    </Form.Item>}
                    <Form.Item required name={"teacherEducationalDegree"} label="Educational degree">
                        <Select
                            placeholder="Select educational degree"
                            options={educationalDegreeList.map((degree) => ({ value: degree.id, label: degree.titleEn }))}
                        />
                    </Form.Item>
                    <Form.Item required name={"speciality"} label="Enter your speciality">
                        <Input placeholder="Enter your speciality" />
                    </Form.Item>
                    <Form.Item required name={"cipher"} label="Enter cipher">
                        <Input placeholder="Enter your speciality" />
                    </Form.Item>
                    <Form.Item
                        required
                        name={"awardYear"}
                        label="Enter your award year"
                        getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                        getValueProps={(i) => ({ value: i ? moment(i) : null })}
                    >
                        <DatePicker placeholder='Enter date' style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item name={"file"} valuePropName="file">
                        <Upload {...uploadProps} >
                            <Button icon={<UploadOutlined />}>Upload file</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            }
            form={form}
            isPublic={isPublic}
        />
    )
}

export default TeacherAcademicTitleTable