import authAxios from '../common/authAxios';
import { StudentEducationProgramChooseDtoResponse } from '../interfaces/DisciplineEnrollmentDtoResponse';
import { EducationLanguageDtoResponse } from '../interfaces/EducationalPrograms/EducationLanguageDtoResponse';
import { EducationProgramDtoRequest } from '../interfaces/EducationalPrograms/EducationProgramDtoRequest';
import { EducationProgramDtoResponse } from '../interfaces/EducationalPrograms/EducationProgramDtoResponse';
import { GroupOfEducationProgramDtoResponse } from '../interfaces/EducationalPrograms/GroupOfEducationProgramDtoResponse';
import { StudentGroupsDtoResponse } from '../interfaces/Student/StudentGroupsDtoResponse';

export class EducationalProgramsService {
  private static baseUrl = '/astanait-office-module/api/v1';

  static getOneEducationalProgram(educationalProgramId: number) {
    return authAxios.get<EducationProgramDtoResponse>(
      `${this.baseUrl}/academic-department/education-program/get-education-program-by-one?education_program_id=${educationalProgramId}`
    );
  }

  static getEducationalPrograms() {
    return authAxios.get<EducationProgramDtoResponse[]>(
      `${this.baseUrl}/academic-department/education-program/get-all-education-programs`
    );
  }
  static getEducationalProgramsByCourse3() {
    return authAxios.get<EducationProgramDtoResponse[]>(
      `${this.baseUrl}/academic-department/education-program/get-all-education-programs-by-course?course=3`
    );
  }

  static getEducationalProgramGroups() {
    return authAxios.get<GroupOfEducationProgramDtoResponse[]>(
      `${this.baseUrl}/catalog/group-of-education-program/get-all-group-of-education-program`
    );
  }

  static getGOPbyDegree(degreeId: number) {
    return authAxios.get<GroupOfEducationProgramDtoResponse[]>(
      `${this.baseUrl}/catalog/group-of-education-program/get-by-degree?degreeId=${degreeId}`
    );
  }

  static getLanguages() {
    return authAxios.get<EducationLanguageDtoResponse[]>(
      `${this.baseUrl}/catalog/education-language/get-all-education-language`
    );
  }

  // /astanait-office-module/api/v1/academic-department/education-program/create-education-program
  static createEducationalProgram(educationalProgram: EducationProgramDtoRequest) {
    return authAxios.post(
      `${this.baseUrl}/academic-department/education-program/create-education-program`,
      educationalProgram
    );
  }

  static deleteEducationalProgram(educationalProgramId: number) {
    return authAxios.delete(
      `${this.baseUrl}/academic-department/education-program/delete-education-program?education_program_id=${educationalProgramId}`
    );
  }

  // TODO: Не доделан
  static updateEducationalProgram(educationalProgram: EducationProgramDtoRequest, educationalProgramId: number) {
    return authAxios.put(
      `${this.baseUrl}/academic-department/education-program/update-education-program?education_program_id=${educationalProgramId}`,
      educationalProgram
    );
  }

  static getStudentsEPChoice(
    education_program_id: number | undefined,
    course_grade: number | undefined,
    group_id: number | undefined
  ) {
    const baseUrl = '/astanait-student-module/api/v1';
    return authAxios.get<StudentEducationProgramChooseDtoResponse[]>(
      `${baseUrl}/student-education-program-choice/get-student-education-program-choice?education_program_id=${education_program_id}&course_grade=${course_grade}&group_id=${group_id}`
    );
  }

  static getEducationalProgramsByCourse(course: number | undefined) {
    return authAxios.get<EducationProgramDtoResponse[]>(
      `${this.baseUrl}/academic-department/education-program/get-all-education-programs-by-course?course=${course}`
    );
  }
  // Student educ program and discipline enrollment endpoints

  static getEducationProgramsByCourseAndAcademicDegree(
    course: number | undefined,
    academicDegreeId: number | undefined
  ) {
    return authAxios.get<EducationProgramDtoResponse[]>(
      `${this.baseUrl}/academic-department/education-program/get-all-education-programs-by-course-and-academic-degree?course=${course}&academic_degree_id=${academicDegreeId}`
    );
  }

  static getEducationalProgramsForStudent() {
    return authAxios.get<EducationProgramDtoResponse[]>(
      `${this.baseUrl}/academic-department/education-program/get-all-education-programs-for-student-choose`
    );
  }

  static getEducationalProgramsForApplication(isGoep: boolean) {
    return authAxios.get<EducationProgramDtoResponse[]>(
      `${this.baseUrl}/academic-department/education-program/get-all-for-application?is_goep=${isGoep}`
    );
  }

  static setEducationalProgramForStudent(id: number | undefined) {
    const baseUrl = '/astanait-student-module/api/v1';
    return authAxios.post(`${baseUrl}/student-education-program-choice/change-program?education_program_id=${id}`);
  }

  static getStudentsPreviousEPChoice() {
    const baseUrl = 'astanait-student-module/api/v1';
    return authAxios.get(`${baseUrl}/student-education-program-choice/get-previous-choice`);
  }

  static checkStudentEPChoiceAccess() {
    const baseUrl = '/astanait-student-module/api/v1';
    return authAxios.get<boolean>(`${baseUrl}/student-education-program-choice/check-access`);
  }

  static getGroupsByEducationalProgram(educationalProgramId: number) {
    const baseUrl = '/astanait-student-module/api/v1';
    return authAxios.get<StudentGroupsDtoResponse[]>(
      `${baseUrl}/group/get-all-by-education-program?education_program_id=${educationalProgramId}`
    );
  }


}
