import authAxios from "../common/authAxios";
import StudentByGenderDtoResponse from "../interfaces/Student/StudentByGenderDtoResponse";
import { StudentCountByAgeDtoResponse } from "../interfaces/Student/StudentCountByAgeDtoResponse";

export class StatisticsService {

  static getByGender(params: any) {
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }

    return authAxios.get<StudentByGenderDtoResponse[]>(`/astanait-contingent-module/api/v1/statistics/by-gender`, { params });
  }

  static getByAge(params: any) {
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }

    return authAxios.get<StudentCountByAgeDtoResponse[]>(`/astanait-contingent-module/api/v1/statistics/by-age`, { params });
  }

  static getTotal(params: any) {
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }

    return authAxios.get<number>(`/astanait-contingent-module/api/v1/statistics/total`, { params });
  }

}