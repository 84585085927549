import { useEffect, useState } from 'react';
import { StudentGroupsDtoResponse } from '../../../interfaces/Student/StudentGroupsDtoResponse';
import { StudentsListDtoResponse } from '../../../interfaces/Student/StudentsListDtoResponse';
import { StudentsListWithGroupDtoResponse } from '../../../interfaces/Student/StudentsListWithGroupDtoResponse';
import { PersistentStateConstants } from '../../../PersistentStateConstants';
import { DisciplinesService } from '../../../services/DisciplinesService';
import { PersistentStateService } from '../../../services/PersistentStateService';
import { TranscriptService } from '../../../services/TranscriptService';
import { errorNotification } from '../../../helpers/errorNotification';
import { successNotification } from '../../../helpers/successNotification';

interface TranscriptRow {
  number: number | string;
  disciplineName: string;
  creditsAmount: number | string;
  percentsGrade: number | string;
  alphabeticGrade: string;
  gpaGrade: number | string;
  traditionalGrade: number | string;
}

const useTranscript = () => {


  // Хуки для данных из сервера
  const [groupList, setGroupList] = useState<StudentGroupsDtoResponse[]>([]);
  const [studentsList, setStudentsList] = useState<StudentsListDtoResponse[]>([]);
  const [studentsListWithGroup, setStudentsListWithGroup] = useState<StudentsListWithGroupDtoResponse[]>([]);
  const [creditList, setCreditList] = useState([]);

  //Хуки для видимости
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(true);

  // Хуки для выбора из списка и для добавления дисциплины
  const [selectedGroup, setSelectedGroup] = useState<number | undefined>(undefined);
  const [selectedStudent, setSelectedStudent] = useState<number | undefined>(undefined);

  const [searchValue, setSearchValue] = useState('');
  //   const [searchStudentName, setSearchStudentName] = useState<string>('');

  // Хук для отображения данных из транскрипта
  const [transcript, setTranscript] = useState<TranscriptRow[]>([]);
  const [rerender, setRerender] = useState(false);

  const student = studentsList.find((student) => student.id === selectedStudent);
  const studentWithGroup = studentsListWithGroup.find((student) => student.id === selectedStudent);

  // Получаем группы для вывода в фильтре
  useEffect(() => {
    TranscriptService.getAllStudentsGroup().then(({ data }) => {
      let res = data.map((item) => {
        return {
          id: item.id,
          title: item.title,
        };
      });
      setGroupList(res);
    });

    DisciplinesService.getAllCredits().then(({ data }) => {
      setCreditList(data);
    });
  }, []);

  useEffect(() => {
    if (PersistentStateService.hasInputFieldsInfo(PersistentStateConstants.TRANSCRIPT_INPUT_FIELDS)) {
      const inputFields: any = JSON.parse(
        // @ts-ignore
        PersistentStateService.getInputFieldsInfo(PersistentStateConstants.TRANSCRIPT_INPUT_FIELDS)
      );
      setSelectedGroup(inputFields.selectedGroup);
      TranscriptService.getStudentsByGroupForTranscript(inputFields.selectedGroup).then(({ data }) => {
        let res = data.map((item) => {
          return {
            id: item.id,
            name: item.nameKz,
            surname: item.surnameKz,
            patronymic: item.patronymicKz,
            userId: item.userId
          };
        });
        setStudentsList(res);
      });
      setSearchValue(inputFields.searchValue);
      setIsFilterVisible(inputFields.isFilterVisible);
      // handleSearchStudents(inputFields.searchValue);
    }
  }, []);

  useEffect(() => {
    PersistentStateService.setInputFieldsInfo(PersistentStateConstants.TRANSCRIPT_INPUT_FIELDS, {
      selectedGroup,
      searchValue,
      isFilterVisible,
    });
  }, [selectedGroup, searchValue, isFilterVisible]);

  const handleSearchStudents = async (fullname: string) => {
    setSelectedStudent(undefined);
    // setSearchStudentName(fullname);
    TranscriptService.getStudentByName(fullname)
      .then(({ data }) => {
        let res = data.map((item) => {
          return {
            id: item.id,
            name: item.nameKz,
            surname: item.surnameKz,
            patronymic: item.patronymicKz,
            groupid: item.group.id,
            groupname: item.group.title,
            educationProgramId: item.educationProgramId,
            userId: item.userId,
          };
        });
        setStudentsListWithGroup(res);
        if (res.length === 0)
          errorNotification(`Не найдено ни одного студента`)
        else
          successNotification(`Найдено ${res.length} студентов`)
      });
  };

  const onGroupSelect = async (groupId: number) => {
    setSelectedGroup(groupId);
    setSelectedStudent(undefined);
    TranscriptService.getStudentsByGroupForTranscript(groupId).then(({ data }) => {
      let res = data.map((item) => {
        return {
          id: item.id,
          name: item.nameKz,
          surname: item.surnameKz,
          patronymic: item.patronymicKz,
          userId: item.userId
        };
      });
      setStudentsList(res);
    });
  };

  const exportTranscript = () => {
    const student = studentsList.find((student) => student.id === selectedStudent);
    const studentSearch = studentsListWithGroup.find((student) => student.id === selectedStudent);

    if (student) {
      TranscriptService.getSummarySheetForTranscriptPdf(selectedStudent, `${student.name} ${student.surname}`);
    }
    if (studentSearch) {
      TranscriptService.getSummarySheetForTranscriptPdf(
        selectedStudent,
        `${studentSearch.name} ${studentSearch.surname}`
      );
    }
  };

  const exportTranscriptEn = () => {
    const student = studentsList.find((student) => student.id === selectedStudent);
    const studentSearch = studentsListWithGroup.find((student) => student.id === selectedStudent);

    if (student) {
      TranscriptService.getSummarySheetForTranscriptPdfEn(selectedStudent, `${student.name} ${student.surname}`);
    }
    if (studentSearch) {
      TranscriptService.getSummarySheetForTranscriptPdfEn(
        selectedStudent,
        `${studentSearch.name} ${studentSearch.surname}`
      );
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleSearchByButton = () => {
    setIsFilterVisible(!isFilterVisible);
    setSelectedStudent(undefined);
  };

  return {
    exportTranscript,
    exportTranscriptEn,
    showModal,
    handleSearchByButton,
    onGroupSelect,
    handleSearchStudents,
    student,
    studentWithGroup,
    groupList,
    setGroupList,
    studentsList,
    setStudentsList,
    studentsListWithGroup,
    setStudentsListWithGroup,
    creditList,
    setCreditList,
    isModalVisible,
    setIsModalVisible,
    isFilterVisible,
    setIsFilterVisible,
    selectedGroup,
    setSelectedGroup,
    selectedStudent,
    setSelectedStudent,
    searchValue,
    setSearchValue,
    transcript,
    setTranscript,
    rerender,
    setRerender,
  };
};

export default useTranscript;
