import React from 'react'
import UniversalTable from '../UniversalTeachersTable';
import { TeacherDevelopmentsDtoRequest } from '../../../../interfaces/Teacher/TeacherDevelopmentDtoRequest';
import useFetchData from '../../../../hooks/useFetchData';
import { TeacherDevelopmentsDtoResponse } from '../../../../interfaces/Teacher/TeacherDevelopmentsDtoResponse';
import { TeacherService } from '../../../../services/TeacherService';
import { Field } from '../../../../interfaces/Field';
import { successNotification } from '../../../../helpers/successNotification';

interface TeacherDevelopmentsProps {
    isPublic: boolean;
}

const TeacherDevelopments = ({ isPublic }: TeacherDevelopmentsProps) => {
    const { data: developments, fetchData: fetchDevelopments } = useFetchData<TeacherDevelopmentsDtoResponse[]>(
        TeacherService.getDevelopments, true
    );

    const addRecord = (record: TeacherDevelopmentsDtoRequest) => {
        TeacherService.createOrUpdateDevelopment(record)
            .then(() => fetchDevelopments())
            .finally(() => successNotification('Development was created!'))
    };

    const editRecord = (record: TeacherDevelopmentsDtoRequest) => {
        TeacherService.createOrUpdateDevelopment(record)
            .then(() => fetchDevelopments())
            .finally(() => successNotification('Development was updated!'))
    };

    const deleteRecord = (record: TeacherDevelopmentsDtoRequest) => {
        TeacherService.deleteDevelopment(record.id)
            .then(() => fetchDevelopments())
            .finally(() => successNotification('Development was deleted!'))
    };

    const certificateFields: Field[] = [
        { name: 'name', label: 'Description', type: 'textArea', placeholder: "Write description" },
        { name: 'fileId', label: 'File', type: 'file' }
    ];


    return (
        <UniversalTable
            columns={[
                {
                    title: '#',
                    dataIndex: 'key',
                    key: 'key',
                },
                {
                    title: 'Development name',
                    dataIndex: 'name',
                    key: 'name',
                }
            ]}
            dataSource={developments ? developments.map((development, i) => ({
                id: development.id,
                key: i + 1,
                name: development.name,
                fileId: development.fileId,
            })) : []}
            addRecord={(e: TeacherDevelopmentsDtoRequest) => addRecord(e)}
            editRecord={(e: TeacherDevelopmentsDtoRequest) => editRecord(e)}
            deleteRecord={(e: TeacherDevelopmentsDtoRequest) => deleteRecord(e)}
            fields={certificateFields}
        />)
}

export default TeacherDevelopments