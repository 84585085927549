interface CacheData<T> {
  expiry: number;
  data: T;
}

export class CacheService {
  private static cache: Record<string, CacheData<any>> = {};

  static async getWithCache<T>(
    key: string,
    func: (...args: any[]) => Promise<T>,
    cacheTime: number = 5 * 60 * 1000, // 5 minutes by default
    ...funcArgs: any[]
  ): Promise<T> {
    const now = Date.now();
    const data = this.cache[key];

    if (data && now < data.expiry) {
      return data.data;
    }

    const result = await func(...funcArgs);
    this.cache[key] = { expiry: now + cacheTime, data: result };

    this.removeExpiredItems();
    return result;
  }

  static removeExpiredItems() {
    const now = Date.now();
    for (const key in this.cache) {
      if (this.cache[key].expiry < now) {
        delete this.cache[key];
      }
    }
  }

  static getCache<T>(key: string): T | null {
    const now = Date.now();
    const data = this.cache[key];

    if (data && now < data.expiry) {
      return data.data;
    }

    return null;
  }
}
