import { Button, DatePicker, Form, Input, Select, Space, Typography } from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { IrosStatus } from '../../../../../interfaces/Iros/IrosStatus';
import { SsciEventsDtoRequest } from '../../../../../interfaces/Ssci/SsciEventsDtoRequest';
import { IrosService } from '../../../../../services/student/IrosService';
import { SsciService } from '../../../../../services/student/SsciService';
import BackButton from '../../../../../ui/BackButton';
import PageWrapper from '../../../../../ui/PageWrapper';
import { successNotification } from '../../../../../helpers/successNotification';

interface Props {
    eventId?: number;
}

const SsciCreateEventPage = ({ eventId }: Props) => {
    const [status, setStatus] = useState<IrosStatus[]>([]);
    const [form] = Form.useForm();
    const history = useHistory();

    useEffect(() => {
        IrosService.getStatus()
            .then(({ data }) => { setStatus(data) })
        if (eventId) {
            SsciService.getEventById(eventId)
                .then(({ data }) => {
                    form.setFieldsValue({
                        name: data.name,
                        nameEn: data.nameEn,
                        nameRu: data.nameRu,
                        eventDate: moment(data.eventDate),
                        indexStatus: data.indexStatus.id
                    })
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleCreate = (dto: SsciEventsDtoRequest) => {
        SsciService.createOrUpdateEvent(dto, eventId)
            .then(() => successNotification("Успешно создано!"))
            .finally(() => history.goBack())
    }

    return (
        <PageWrapper>
            <BackButton />
            <Space style={{ width: "100%", alignItems: "center" }} direction={"vertical"} size="small">
                <Space style={{ minWidth: "800px" }}
                >
                    <Typography.Title level={3}>{eventId ? 'Редактирование SSCI эвента' : 'Создание SSCI эвента'}</Typography.Title>
                </Space>
                <Form
                    form={form}
                    initialValues={{ remember: true }}
                    layout="vertical"
                    size="large"
                    style={{ minWidth: "800px" }}
                    onFinish={handleCreate}
                >
                    <Form.Item
                        label="Название на казахском"
                        name="name"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Название на английском"
                        name="nameEn"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Название на русском"
                        name="nameRu"
                        rules={[{ required: true, message: 'Введите название' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Дата"
                        name="eventDate"
                        rules={[{ required: true, message: 'Введите дату' }]}
                    >
                        <DatePicker placeholder='' />
                    </Form.Item>
                    <Form.Item
                        label="Индекс статус"
                        name="indexStatus"
                        rules={[{ required: true, message: 'Выберите статус' }]}
                    >
                        <Select>
                            {status.map((status) => (
                                <Select.Option key={status.id} value={status.id}>
                                    {status.nameRu}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Сохранить
                            </Button>
                            <Button htmlType="button" onClick={() => form.resetFields()}>
                                Сбросить форму
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Space>

        </PageWrapper>
    )
}

export default SsciCreateEventPage