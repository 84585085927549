import { Space, Timeline } from 'antd';
import React from 'react';
import { OrderDtoResponse } from '../../../interfaces/Order/OrderDtoResponse';

interface TableView {
  items: OrderDtoResponse[];
  render: (item: OrderDtoResponse) => React.ReactNode;
}

const TimelineView = ({ items, render }: TableView) => {
  return (
    <Space direction="horizontal" style={{ width: '100%' }}>
      <Timeline>{items.map((item) => render(item))}</Timeline>
    </Space>
  );
};

export default TimelineView;
