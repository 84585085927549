import { Select } from 'antd';
import { EnrollYearDtoResponse } from '../../../../interfaces/EnrollYearDtoResponse';

const { Option } = Select;

type Options = EnrollYearDtoResponse[];

interface Props {
  placeholder: string;
  options: Options;
  selectedOption: number | undefined;
  width?: number;
  onSelect: (value: number) => void;
  onClear?: () => void;
}

const EnrollYearFilter = ({ placeholder, options, selectedOption, width, onSelect, onClear }: Props) => {
  return (
    <Select
      showSearch
      style={{ width: width ? width : 400 }}
      placeholder={placeholder}
      value={selectedOption}
      onSelect={onSelect}
      optionFilterProp="children"
      onClear={onClear}
      allowClear={!!onClear}
    >
      {options.map((option) => (
        <Option key={option.id} value={option.id}>
          {option.year}
        </Option>
      ))}
    </Select>
  );
};

export default EnrollYearFilter;
