import { UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Upload, Select, Table, Typography, Row, Col, Modal } from 'antd';
import moment from 'moment';
import useEditOrderForm from '../../../../hooks/Order/useEditOrderForm';
import BackButton from '../../../../ui/BackButton';
import PageWrapper from '../../../../ui/PageWrapper';
import StudentsTable from '../../StudentsDetailsPage/StudentsTable';
import useStudentDetailsPage from '../../StudentsDetailsPage/useStudentDetails';

const { Title } = Typography;
const { Option } = Select;

interface Props {
  orderId: number;
}

const EditOrderPage = ({ orderId }: Props) => {
  const {
    style,
    form,
    ordersTypes,
    ordersCategories,
    selectedStudentColumns,
    onFormFinish,
    onFormFinishFailed,
    handleOrderTypeSelect,
    selectedStudent,
    setSelectedStudent,
    selectedStudentId,
    setSelectedStudentId,
    tableVisible,
    loading,
    uploadProps,
    isModalOpen,
    handleOk,
    handleCancel,
    orderName,
    handleDownload,
  } = useEditOrderForm({ orderId });

  const { searchValue, setSearchValue, studentsList, handleSearchStudents } = useStudentDetailsPage({});

  return (
    <PageWrapper>
      <section>
        <BackButton />
        <header style={style}>
          <Title level={3}>Редактировать приказ</Title>
        </header>
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          size="large"
          form={form}
          onFinish={onFormFinish}
          onFinishFailed={onFormFinishFailed}
          style={style}
        >
          <Form.Item
            label="Тип приказа"
            name="orderType"
            rules={[{ required: true, message: 'Выберите тип приказа' }]}
            style={{ marginBottom: '10px' }}
          >
            <Select
              placeholder="Выберите приказ"
              onSelect={(value) => handleOrderTypeSelect(value)}
              allowClear
              disabled
            >
              {ordersTypes?.map((option) => (
                <Option key={option.id} value={option.id}>
                  {`${option.name}/${option.nameRu}/${option.nameEn}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {ordersCategories.length > 0 && (
            <Form.Item label="Категория" name="orderCategory" rules={[{ required: true, message: 'выберите категорию' }]}
              style={{ marginBottom: '10px' }}>
              <Select placeholder="Выберите категорию приказа" allowClear>
                {ordersCategories?.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {`${option.name}/${option.nameRu}/${option.nameEn}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="Название приказа"
            name="name"
            rules={[{ required: true, message: 'Введите название приказа' }]}
            style={{ marginBottom: '10px' }}
          >
            <Input placeholder="Введите название приказа" />
          </Form.Item>
          <Form.Item
            label="Номер приказа"
            name="number"
            rules={[{ required: true, message: 'номер приказа' }]}
            style={{ marginBottom: '10px' }}
          >
            <Input style={style} placeholder="Введите номер приказа" />
          </Form.Item>
          <Form.Item
            label="Дата приказа"
            name="issueDate"
            rules={[{ required: true, message: 'Выберите дату создания приказа' }]}
            style={{ marginBottom: '10px' }}
            getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
            getValueProps={(i) => ({ value: moment(i) })}
          >
            <DatePicker allowClear={false} placeholder="Выберите дату" style={{ width: '50%' }} />
          </Form.Item>
          {form.getFieldValue("contingateDate") ? (
            <Form.Item
              label="Дата движения"
              name="contingateDate"
              rules={[{ required: true, message: 'Выберите дату движения' }]}
              style={{ marginBottom: '10px' }}
              getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
              getValueProps={(i) => ({ value: moment(i) })}
            >
              <DatePicker allowClear={false} placeholder="Выберите дату" style={{ width: '50%' }} />
            </Form.Item>
          ) : (
            <Form.Item
              label="Дата движения"
              name="contingateDate"
              rules={[{ required: true, message: 'Выберите дату движения' }]}
              style={{ marginBottom: '10px' }}
            >
              <DatePicker allowClear={false} placeholder="Выберите дату" style={{ width: '50%' }} />
            </Form.Item>
          )}

          <Form.Item label="Файл" name="fileId">
            <Button style={{ marginRight: '25px' }} onClick={() => handleDownload(orderName)}>
              Скачать текущий файл
            </Button>
            <Upload {...uploadProps} accept={'.pdf'} style={style}>
              <Button icon={<UploadOutlined />}>Загрузите новый файл приказа</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>

          {/* {selectedOrderType && Object.entries(data).length !== 0 && (
            <>
              <Form.Item label="Год обучения" name="year" style={{ marginBottom: '10px' }}>
                <Select placeholder="Выберите год обучения" onSelect={(value) => handleYearSelect(value)} allowClear>
                  {years?.map((option) => (
                    <Option key={option.id} value={option.year}>
                      {`${option.year}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Степень образования" name="academicDegreeId" style={{ marginBottom: '10px' }}>
                <Select
                  placeholder="Выберите степень образования"
                  onSelect={(value) => handleDegreeSelect(value)}
                  allowClear
                >
                  <Option key={1} value={1}>
                    Bachelor's degree
                  </Option>
                  <Option key={2} value={2}>
                    Master's degree
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item label="Курс" name="course" style={{ marginBottom: '10px' }}>
                <Select placeholder="Выберите курс" onSelect={(value) => handleCourseSelect(value)} allowClear>
                  {courseOptions?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {`${option.year}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Триместр" name="term" style={{ marginBottom: '10px' }}>
                <Select placeholder="Выберите триместр" onSelect={(value) => handleTrimesterSelect(value)} allowClear>
                  {trimesters.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {`${option.trimester}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )} */}
        </Form>
        {tableVisible && (
          <div style={{ ...style, marginTop: '40px' }}>
            <Row>
              <Col flex={0}>
                <Title level={4}>Выбранные студенты</Title>
              </Col>
              <Col flex="auto"></Col>
              <Col flex={0}>
                {/* <Button type="default" onClick={showModal} icon={<PlusOutlined />}>
                  Добавить студентов
                </Button> */}
              </Col>
            </Row>
            <Table columns={selectedStudentColumns} dataSource={selectedStudent} loading={loading} />
          </div>
        )}
        {/* {tableVisible && (
          <div style={{ ...style, marginTop: '40px' }}>
            <Title level={4}>Все студенты подходящие к приказу</Title>
            <Table
              columns={availableStudentColumns}
              dataSource={data}
              pagination={{ defaultPageSize: 20, total: totalNumber, simple: true }}
              loading={loading}
              onChange={(pagination) => handlePageChange(pagination)}
            />
          </div>
        )} */}
        <Modal
          title="Выбрать студентов"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={true}
          width={1000}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Закрыть
            </Button>,
          ]}
        >
          <Input.Search
            placeholder="Введите ФИО Студента"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={(value) => handleSearchStudents(value)}
            size="large"
            enterButton
            allowClear
          />
          {studentsList.length > 0 && (
            <StudentsTable
              loading={loading}
              data={studentsList}
              selectable
              selectedStudentId={selectedStudentId}
              setSelectedStudentId={setSelectedStudentId}
              selectedStudent={selectedStudent}
              setSelectedStudent={setSelectedStudent}
            />
          )}
        </Modal>
      </section>
    </PageWrapper>
  );
};

export default EditOrderPage;
