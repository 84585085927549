import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { PersistentStateConstants } from '../../../PersistentStateConstants';
import { StudentDtoResponse } from '../../../interfaces/Student/StudentDtoResponse';
import { PersistentStateService } from '../../../services/PersistentStateService';
import { TranscriptService } from '../../../services/TranscriptService';
import { StudentStatusTypeDtoResponse } from '../../../interfaces/Student/StudentStatusTypeDtoResponse';
import { StudentService } from '../../../services/StudentService';
import { StudentStudyingStatusTypeDtoResponse } from '../../../interfaces/Student/StudentStudyingStatusTypeDtoResponse';
import { errorNotification } from '../../../helpers/errorNotification';

interface Props {
  retirePage?: boolean;
}

const useStudentDetails = ({ retirePage = false }: Props) => {

  const [searchValue, setSearchValue] = useState('');
  const [studentsList, setStudentsList] = useState<StudentDtoResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [studentStatusTypeList, setStudentStatusTypeList] = useState<StudentStatusTypeDtoResponse[]>([]);
  const [studentStudyStatusTypeList, setStudyStudentStatusTypeList] = useState<StudentStudyingStatusTypeDtoResponse[]>(
    []
  );
  const [studentsCount, setStudentsCount] = useState<number>(0);

  useEffect(() => {
    if (PersistentStateService.hasInputFieldsInfo(PersistentStateConstants.STUDENT_DETAILS_SEARCH_QUERY)) {
      const searchQuery: any = JSON.parse(
        // @ts-ignore
        PersistentStateService.getInputFieldsInfo(PersistentStateConstants.STUDENT_DETAILS_SEARCH_QUERY)
      );
      setSearchValue(searchQuery);
    }
    if (!retirePage) {
      StudentService.getStudentStatusList()
        .then(({ data }) => setStudentStatusTypeList(data))

      StudentService.getStudentStudyStatusList()
        .then(({ data }) => setStudyStudentStatusTypeList(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    PersistentStateService.setInputFieldsInfo(PersistentStateConstants.STUDENT_DETAILS_SEARCH_QUERY, searchValue);
  }, [searchValue]);

  const handleSearchStudents = (fullname: string) => {
    if (!fullname) {
      errorNotification('Введите имя в поле поиска!');
      return;
    }
    setLoading(true);
    TranscriptService.getStudentByName(fullname)
      .then(({ data }) => {
        setStudentsList(data);
        if (data.length === 0) errorNotification(`Не найдено ни одного студента`);
        else notification.success({ message: `Найдено ${data.length} студентов` });
        setStudentsCount(data.length);
      })
      .finally(() => setLoading(false));
  };

  return {
    searchValue,
    setSearchValue,
    studentsList,
    loading,
    handleSearchStudents,

  };
};

export default useStudentDetails;
