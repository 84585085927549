import PageWrapper from '../../../ui/PageWrapper';
import styles from './academic.module.css';
import { Typography, Button, Space, Select } from 'antd';
import EnrollYearFilter from './EnrollYearFilter';
import TrimesterFilter from './TrimesterFilter';
import SelectOptionsFilter from './SelectOptionsFilter';
import SearchFilter from './SearchFilter';
import StudentGroupFilter from './StudentGroupFilter';
import useAcademicDep from './useAcademicDep';
import useDictionary from '../../../hooks/useDictionary';

const { Title } = Typography;

const AcademicDept = () => {
  const {
    selectedFiltersOption,
    setSelectedFiltersOption,
    selectedEducationalProgram,
    selectedCourse,
    tutorList,
    disciplines,
    studentGroups,
    selectedYear,
    selectedTrimester,
    searchQueryTutor,
    searchQueryDiscipline,
    searchQueryGroup,
    exportData,
    exportEnglish,
    onSearchQueryDisciplineChange,
    onSearchQueryTutorChange,
    handleClear,
    loadData,
    courseOptions,
    table,
    educationalProgramsOptions,
    handleSelect,
    selectedStudentGroup,
    setSelectedStudentGroup,
    setSelectedTrimester,
  } = useAcademicDep();

  const { dictionary } = useDictionary({});

  return (
    <PageWrapper>
      <section>
        <header className={styles.header}>
          <Title level={3} className={styles.title}>
            Vedomosti
          </Title>
        </header>
        <Space direction='vertical' style={{ width: "100%" }}>
          <Space size={20} direction="vertical" style={{ marginBottom: '20px', width: "100%" }}>
            <SelectOptionsFilter
              placeholder="Вариант ведомости"
              selectedOption={selectedFiltersOption}
              onSelect={(value) => setSelectedFiltersOption(value)}
              onClear={handleClear.handleClearSelectOption}
              options={[
                {
                  id: 1,
                  option: 'По преподавателю и дисциплине',
                },
                {
                  id: 2,
                  option: 'По году обучения и группе обучающихся',
                },
              ]}
            ></SelectOptionsFilter>

            {selectedFiltersOption === 1 && (
              <>
                <div className={styles.filterContainer}>
                  <Select
                    placeholder="Выберите степень образования"
                    allowClear
                    style={{ width: 400 }}
                    onSelect={handleSelect.handleSelectDegree}
                    onClear={handleClear.handleClearDegree}
                  >
                    <Select.Option key={1} value={1}>
                      Bachelor's degree
                    </Select.Option>
                    <Select.Option key={2} value={2}>
                      Master's degree
                    </Select.Option>
                    <Select.Option key={3} value={6}>
                      Doctorate's degree
                    </Select.Option>
                  </Select>
                </div>

                <div className={styles.filterContainer}>
                  <Select
                    placeholder="Выберите курс"
                    allowClear
                    value={selectedCourse}
                    onSelect={handleSelect.handleCourseSelect}
                    onClear={handleClear.handleClearCourse}
                    style={{ width: 400 }}
                  >
                    {courseOptions?.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {`${option.year}`}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div className={styles.filterContainer}>
                  <Select
                    placeholder="Выберите образовательную программу"
                    allowClear
                    value={selectedEducationalProgram}
                    onSelect={handleSelect.handleEducationalProgramSelect}
                    onClear={handleClear.handleClearEducationalProgram}
                    style={{ width: 400 }}>
                    {educationalProgramsOptions.map((data) => (
                      <Select.Option key={data.id} value={data.id}>
                        {data.titleEn + ' / ' + data.titleKz + ' / ' + data.titleRu}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div className={styles.filterContainer}>
                  <StudentGroupFilter
                    placeholder="Группа обучающихся"
                    selectedOption={searchQueryGroup}
                    onSelect={handleSelect.handleGroupSelect}
                    options={[...studentGroups]}
                    onClear={handleClear.handleClearQueryGroup}
                  ></StudentGroupFilter>
                </div>

                <div className={styles.filterContainer}>
                  <EnrollYearFilter
                    placeholder="Год обучения"
                    selectedOption={selectedYear}
                    // Backend accepting 2019,2020,2021 instead of id's like 1 2 3
                    onSelect={handleSelect.handleYearSelect}
                    options={dictionary.years}
                    onClear={handleClear.handleClearYear}
                  />
                </div>

                <div className={styles.filterContainer}>
                  <TrimesterFilter
                    placeholder="Триместр"
                    selectedOption={selectedTrimester}
                    onSelect={handleSelect.handleSelectTrim}
                    options={dictionary.trimesters}
                    onClear={handleClear.handleClearTrim}
                  />
                </div>

                <div className={styles.filterContainer}>
                  <Select
                    placeholder="Название дисциплины"
                    style={{ width: 400 }}
                    value={searchQueryDiscipline}
                    allowClear
                    onSelect={onSearchQueryDisciplineChange}
                    onClear={handleClear.handleClearDiscipline}>
                    {disciplines.map((discipline) => (
                      <Select.Option key={discipline.id} value={discipline.id}>
                        {`[${discipline.code}] - ${discipline.titleEn}`}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div className={styles.filterContainer}>
                  <SearchFilter
                    placeholder="Имя преподавателя"
                    selectedOption={searchQueryTutor}
                    onSelect={(value) => onSearchQueryTutorChange(value)}
                    options={[...tutorList]}
                    onClear={handleClear.handleClearTutor}
                  ></SearchFilter>
                </div>
              </>
            )}
            {selectedFiltersOption === 2 && (
              <>
                <div className={styles.filterContainer}>
                  <EnrollYearFilter
                    placeholder="Год обучения"
                    selectedOption={selectedYear}
                    onSelect={handleSelect.handleYearSelect}
                    options={dictionary.years}
                    onClear={handleClear.handleClearYear}
                  />
                </div>

                <div className={styles.filterContainer}>

                  <TrimesterFilter
                    placeholder="Триместр"
                    selectedOption={selectedTrimester}
                    onSelect={(value) => setSelectedTrimester(value)}
                    options={dictionary.trimesters}
                    onClear={handleClear.handleClearTrimester}
                  />
                </div>

                <div className={styles.filterContainer}>
                  <StudentGroupFilter
                    placeholder="Группа обучающихся"
                    selectedOption={selectedStudentGroup}
                    onSelect={(value) => setSelectedStudentGroup(value)}
                    options={[...studentGroups]}
                    onClear={handleClear.handleClearGroup}
                  ></StudentGroupFilter>
                </div>
              </>
            )}
            {selectedFiltersOption === 1 && searchQueryTutor && (
              <Space>
                <Button type={'primary'} onClick={loadData}>
                  Search
                </Button>
                <Button type={'default'} onClick={exportData}>
                  Export data(Russian)
                </Button>
                <Button type={'default'} onClick={exportEnglish}>
                  Export data(English)
                </Button>
              </Space>
            )}
            {selectedFiltersOption === 2 && (
              <Space>
                <Button type={'primary'} onClick={loadData}>
                  Search
                </Button>
                <Button type={'default'} onClick={exportData}>
                  Export data(Russian)
                </Button>
                <Button type={'default'} onClick={exportEnglish}>
                  Export data(English)
                </Button>
              </Space>
            )}
          </Space>
          <Space style={{ width: "100%", alignContent: "flex-end" }}>
            {table}
          </Space>
        </Space>
      </section>
    </PageWrapper >
  );
};

export default AcademicDept;
