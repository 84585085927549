import { Form, Input, Space } from 'antd';
import { DisciplinesOfStreamDtoResponse, GroupsAndStreamsOfStudentsDtoResponse } from '../../../../interfaces/Syllabus/GroupsAndStreamsOfStudentsDtoResponse';

interface SyllabusTableProps {
    syllabus?: GroupsAndStreamsOfStudentsDtoResponse;
    setGroupsAndStreams: React.Dispatch<React.SetStateAction<GroupsAndStreamsOfStudentsDtoResponse | undefined>>;
}
const tableCellStyle = {
    border: "1px solid #177ddc",
    padding: "0.2em",
}

const tablePointCellStyle = {
    border: "1px solid #177ddc",
}

const SyllabusGroupsAndStreamsTable = ({ syllabus, setGroupsAndStreams }: SyllabusTableProps) => {

    const handleUpdateSyllabusStream = (fieldName: string, newValue: any, trim: number, discipline: DisciplinesOfStreamDtoResponse) => {
        const newSyllabus = JSON.parse(JSON.stringify(syllabus)) as GroupsAndStreamsOfStudentsDtoResponse;

        const targetDiscipline = newSyllabus.disciplinesByTrims
            .filter((trimObj) => trimObj.trim === trim)
            .flatMap((trimObj) => trimObj.disciplines)
            .find((d) => d.key === discipline.key);

        if (targetDiscipline) {
            if (fieldName === 'lecDivider') {
                targetDiscipline.lecDivider = Number(newValue);
                const targetStream = targetDiscipline.streams.find((stream) => stream.id === 1);
                if (targetStream) {
                    targetStream.count = Math.ceil(targetDiscipline.numberOfStudents / Number(newValue));
                }
            }
            if (fieldName === 'count') {
                const targetStream = targetDiscipline.streams.find((stream) => stream.id === 2);
                if (targetStream) {
                    targetStream.count = Number(newValue);
                }
            }

            setGroupsAndStreams(newSyllabus);
        }
    }

    return syllabus ? (
        <table>
            <thead>
                <tr>
                    <th style={{ ...tableCellStyle, width: "10%" }} rowSpan={6}>Дисциплина</th>
                    <th style={{ ...tableCellStyle }} colSpan={syllabus.educationProgram.length}>шифр ОП</th>
                    <th style={{ ...tableCellStyle }} rowSpan={6}>Разделитель(лекции)</th>
                    <th style={tableCellStyle} rowSpan={6}>
                        к-во групп
                    </th>
                    <th style={tableCellStyle} rowSpan={6}>
                        к-во студ.
                    </th>
                    <th style={tableCellStyle} rowSpan={6}>
                        к-во кредитов
                    </th>
                    <th style={tableCellStyle} rowSpan={5} colSpan={4}>
                        Потоки
                    </th>
                    <th style={tableCellStyle} rowSpan={5} colSpan={4}>
                        кол-во часов
                    </th>
                    <th style={tableCellStyle} rowSpan={6}>
                        экзамен
                    </th>
                    <th style={tableCellStyle} rowSpan={6}>
                        ВСЕГО, часов
                    </th>
                    <th style={tableCellStyle} rowSpan={6}>
                        Закрепление за ОП
                    </th>
                </tr>
                <tr>
                    {syllabus.educationProgram.map((item, index) => (
                        <th style={tableCellStyle} key={'code-' + index}>{item.code}</th>
                    ))}
                </tr>
                <tr>
                    <th colSpan={syllabus.educationProgram.length}>количество студентов</th>
                </tr>
                <tr>
                    {syllabus.educationProgram.map((item, index) => (
                        <th style={tablePointCellStyle} key={'students-' + index}>{item.numberOfStudents}</th>
                    ))}
                </tr>
                <tr>
                    <th colSpan={syllabus.educationProgram.length}>количество групп</th>
                </tr>
                <tr>
                    {syllabus.educationProgram.map((item, index) => (
                        <th style={tablePointCellStyle} key={'groups-' + index}>{item.numberOfGroups}</th>
                    ))}
                    <th style={tableCellStyle}>лекции</th>
                    <th style={tableCellStyle}>семинар</th>
                    <th style={tableCellStyle}>СРСП</th>
                    <th style={tableCellStyle}>лаб.</th>
                    <th style={tableCellStyle}>лекции</th>
                    <th style={tableCellStyle}>семинар</th>
                    <th style={tableCellStyle}>СРСП</th>
                    <th style={tableCellStyle}>лаб.</th>
                </tr>
            </thead>
            <tbody>
                {syllabus.disciplinesByTrims.map((trimester, trimesterIndex) => (
                    trimester.disciplines.map((discipline, disciplineIndex) => (
                        <tr key={"trim-" + trimesterIndex + "-discipline-" + disciplineIndex}>
                            <td style={tableCellStyle}>{discipline.name}</td>

                            {syllabus.educationProgram.map((item, index) => (
                                <td key={"ep-" + index} style={{ ...tablePointCellStyle, textAlign: "center" }}>{discipline.epHasDiscipline[item.id] ? trimester.trim : null}</td>
                            ))}
                            <td style={{ ...tableCellStyle, textAlign: "center" }}>
                                <Form layout='vertical' initialValues={{
                                    divider: discipline.lecDivider
                                }}>
                                    <Space.Compact>
                                        <Form.Item name="divider" rules={[
                                            {
                                                pattern: new RegExp(/^[0-9]+$/),
                                                message: "Please enter only numbers"
                                            }
                                        ]} style={{ margin: 0 }}>
                                            <Input onChange={e => handleUpdateSyllabusStream("lecDivider", e.target.value, trimester.trim, discipline)} />
                                        </Form.Item>
                                    </Space.Compact>
                                </Form>
                            </td>
                            <td style={{ ...tableCellStyle, textAlign: "center" }}>{discipline.numberOfGroups}</td>
                            <td style={{ ...tableCellStyle, textAlign: "center" }}>{discipline.numberOfStudents}</td>
                            <td style={{ ...tableCellStyle, textAlign: "center" }}>{discipline.credits}</td>

                            {
                                discipline.streams.map((stream, streamIndex) => (
                                    <td key={"stream-count-" + streamIndex} style={{ ...tableCellStyle, textAlign: "center" }}>{stream.count > 0 ? stream.id === 2 ?
                                        <Form layout='vertical' initialValues={{
                                            count: stream.count
                                        }}>
                                            <Space.Compact>
                                                <Form.Item name="count" rules={[
                                                    {
                                                        pattern: new RegExp(/^[0-9]+$/),
                                                        message: "Please enter only numbers"
                                                    }
                                                ]} style={{ margin: 0 }}>
                                                    <Input onChange={e => handleUpdateSyllabusStream("count", e.target.value, trimester.trim, discipline)} />
                                                </Form.Item>
                                            </Space.Compact>
                                        </Form>
                                        : stream.count : null}
                                    </td>
                                ))
                            }

                            {
                                discipline.streams.map((stream, streamIndex) => (
                                    <td key={"stream-hours-" + streamIndex} style={{ ...tableCellStyle, textAlign: "center" }}>{stream.hours > 0 ? stream.hours : null}</td>
                                ))
                            }
                            < td style={{ ...tableCellStyle, textAlign: "center" }}>{discipline.examHours && (discipline.examHours).toFixed(1)}</td>

                            <td style={{ ...tableCellStyle, textAlign: "center" }}>{(discipline.allHours).toFixed(1)}</td>

                            <td style={{ ...tableCellStyle, textAlign: "center" }}>{syllabus.departments[discipline.departmentId]}</td>
                        </tr>
                    ))
                ))}
            </tbody >
        </table >
    ) : <></>
}

export default SyllabusGroupsAndStreamsTable