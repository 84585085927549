//import useStudentPersonalIrosPage from './useStudentPersonalIrosPage';
import styles from './style.module.css';
import { useEffect, useState } from 'react';
import { Typography, Table } from 'antd';
import PageWrapper from "../../../ui/PageWrapper";
import { IrosService } from '../../../services/student/IrosService';
import { IrosApplication } from '../../../interfaces/Iros/IrosApplication';

const { Title } = Typography;

const StudentPersonalIrosPage = (
    { location }: any
) => {
    const queryParams = new URLSearchParams(location.search);
    const studentIdString = queryParams.get('studentId');
    const studentId = studentIdString ? parseInt(studentIdString) : undefined;

    //const { studentIros, column } = useStudentPersonalIrosPage(studentId);
    const [studentIros, setStudentIros] = useState<IrosApplication[]>([]);

    useEffect(() => {
        if (studentId !== undefined) {
            IrosService.getPersonalIrosApplicationsForStudent(studentId)
                .then((response) => setStudentIros(response.data))
                .catch((error) => console.error(error));
        }
    }, [studentId]);





    const column = [
        {
            title: '№',
            width: '5%',
            dataIndex: 'index'
        },
        {
            title: 'Indicator',
            dataIndex: 'indicator',
            key: 'indicator',

        },
        {
            title: 'Index Status',
            dataIndex: 'indexStatus',
            key: 'indexStatus',

        }
    ]

    return (
        <PageWrapper>
            <section>
                <header className={styles.header}>
                    <Title level={3} className={styles.title}>
                        Iros Applications Page
                    </Title>

                </header>
                <Table


                    pagination={false}
                    dataSource={studentIros.map((studentIros, index) => ({
                        index: index + 1,
                        indicator: studentIros.indicator.nameEn,
                        indexStatus: studentIros.indexStatus ? studentIros.indexStatus.nameRu : ''

                    }))}
                    columns={column}
                />
            </section>
        </PageWrapper>
    )
}
export default StudentPersonalIrosPage