import { useState, useEffect } from 'react';
import { StudentAffirmationService } from '../../services/StudentAffirmationService';
import { StudentAffirmationType } from '../../services/StudentAffirmationService';
import { StudentAffirmationStatus } from '../../services/StudentAffirmationService';
import { notification } from 'antd';
import { CourseSelectResponse } from '../../interfaces/CourseSelectResponse';
import { StudentGroupsDtoResponse } from '../../interfaces/Student/StudentGroupsDtoResponse';
import { PersistentStateService } from '../../services/PersistentStateService';
import { PersistentStateConstants } from '../../PersistentStateConstants';
import { CurriculumService } from '../../services/CurriculumService';
import { StudentWithCurriculumStatus } from '../../interfaces/Student/StudentWithCurriculumStatus';
import { VedomostiService } from '../../services/VedomostiService';
import { EducationalProgramsDtoResponse } from '../../interfaces/EducationalPrograms/EducationalProgramsDtoResponse';
import { successNotification } from '../../helpers/successNotification';

const useCurriculum = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [choiceStatus, setChoiceStatus] = useState<string | undefined>(undefined);

  const [selectedCourse, setSelectedCourse] = useState<number | undefined>();
  const [selectedEducationalDegree, setSelectedEducationalDegree] = useState<number | undefined>();
  const [selectedEducationalProgram, setSelectedEducationalProgram] = useState<number | undefined>();
  const [selectedGroup, setSelectedGroup] = useState<number | undefined>();

  const [areButtonsAvailable, setAreButtonsAvailable] = useState<boolean>(false);
  const [tableVisible, setTableVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<StudentWithCurriculumStatus[]>([]);

  const [educPrograms, setEducPrograms] = useState<EducationalProgramsDtoResponse[]>([]);
  const [courseOptions, setCourseOptions] = useState<CourseSelectResponse[]>([]);
  const [groupOptions, setGroupOptions] = useState<StudentGroupsDtoResponse[]>([]);

  useEffect(() => {
    setLoading(true);
    if (
      PersistentStateService.hasInputFieldsInfo(PersistentStateConstants.STUDENT_DEBT_INPUT_FIELDS) &&
      selectedCourse
    ) {
      const inputFields: any = JSON.parse(
        // @ts-ignore
        PersistentStateService.getInputFieldsInfo(PersistentStateConstants.STUDENT_DEBT_INPUT_FIELDS)
      );
      handleEducationalDegreeSelect(inputFields.selectedEducationalDegree);
      setSelectedCourse(inputFields.selectedCourse);
      VedomostiService.getEducationalProgramsByCourseAndDegree(
        inputFields.selectedCourse,
        inputFields.selectedEducationalDegree
      )
        .then(({ data }) => {
          setEducPrograms(sortEduProgramsAlphabetically(data));
        })
      handleEducationalProgramSelect(inputFields.selectedEducationalProgram);
      handleGroupSelect(inputFields.selectedGroup);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = () => {
    setLoading(true);
    setAreButtonsAvailable(true);
    setTableVisible(true);
    CurriculumService.getStudentsWithStatuses(
      selectedEducationalDegree,
      selectedCourse,
      selectedEducationalProgram,
      selectedGroup
    )
      .then((res) => {
        setTableData(res.data);
      })
      .finally(() => setLoading(false));
  };

  const handleEducationalDegreeSelect = (value: number) => {
    if (value === 2)
      setCourseOptions([
        { id: 1, year: 1 },
        { id: 2, year: 2 },
      ]);
    else
      setCourseOptions([
        { id: 1, year: 1 },
        { id: 2, year: 2 },
        { id: 3, year: 3 },
      ]);
    setSelectedEducationalDegree(value);
    setSelectedCourse(undefined);
    setSelectedEducationalProgram(undefined);
    setSelectedGroup(undefined);
    setAreButtonsAvailable(true);
    setTableVisible(false);
  };

  const handleCourseSelect = (value: number) => {
    setSelectedCourse(value);
    setLoading(true);
    setSelectedEducationalProgram(undefined);
    setSelectedGroup(undefined);
    setTableVisible(false);
    setAreButtonsAvailable(true);
    VedomostiService.getEducationalProgramsByCourseAndDegree(value, selectedEducationalDegree)
      .then(({ data }) => {
        setEducPrograms(sortEduProgramsAlphabetically(data));
      })
      .finally(() => setLoading(false));
  };

  const handleEducationalProgramSelect = (value: any) => {
    setLoading(true);
    setSelectedEducationalProgram(value);
    setSelectedGroup(undefined);
    setTableVisible(false);
    setAreButtonsAvailable(true);
    VedomostiService.getGroupsByCourseAndEducationalProgram(selectedCourse, value)
      .then((res) => setGroupOptions(res.data))
      .finally(() => setLoading(false));
  };

  const handleGroupSelect = (value: any) => {
    setSelectedGroup(value);
  };

  const handleClearDegree = () => {
    setSelectedEducationalDegree(undefined);
    setSelectedCourse(undefined);
    setSelectedEducationalProgram(undefined);
    setSelectedGroup(undefined);
    setAreButtonsAvailable(false);
    setTableVisible(false);
  };

  const handleClearCourse = () => {
    setSelectedCourse(undefined);
    setSelectedEducationalProgram(undefined);
    setSelectedGroup(undefined);
    setTableVisible(false);
    setAreButtonsAvailable(true);
  };

  const handleClearEducationalProgram = () => {
    setSelectedEducationalProgram(undefined);
    setSelectedGroup(undefined);
    setTableVisible(false);
    setAreButtonsAvailable(true);
  };

  const handleSetStatus = () => {
    setLoading(true);
    CurriculumService.setStatusToStudents(
      selectedEducationalDegree,
      selectedCourse,
      selectedEducationalProgram,
      selectedGroup
    )
      .then((res) => {
        if (res.status === 200) {
          notification.success({ message: 'Статус студентов был успешно изменён!' });
          handleSearch();
        }
      })
      .finally(() => setLoading(false));
  };

  const onAgreeOrDisagree = (status: boolean) => {
    StudentAffirmationService.createStudentAffirmation(StudentAffirmationType.ELECTIVE_DISCIPLINES, status, '')
      .then(() => {
        status ? setChoiceStatus(StudentAffirmationStatus.AGREE) : setChoiceStatus(StudentAffirmationStatus.NOT_AGREE);
        successNotification(`Data was successfully saved (${status ? 'Agree' : 'Disagree'})`);
      })
  };

  const sortEduProgramsAlphabetically = (data: EducationalProgramsDtoResponse[]) => {
    return data.sort((a, b) => a.titleEn.localeCompare(b.titleEn));
  };

  useEffect(() => {
    if (choiceStatus !== undefined) {
      switch (choiceStatus) {
        case StudentAffirmationStatus.AGREE:
          setIsButtonDisabled(true);
          break;
        case StudentAffirmationStatus.NOT_AGREE:
          setIsButtonDisabled(true);
          break;
        case StudentAffirmationStatus.NOT_CHECKED:
          setIsButtonDisabled(false);
          break;
        default:
          setIsButtonDisabled(false);
      }
    }
  }, [choiceStatus]);

  return {
    choiceStatus,
    onAgreeOrDisagree,
    isButtonDisabled,
    loading,
    areButtonsAvailable,
    tableVisible,
    selectedCourse,
    selectedEducationalDegree,
    selectedEducationalProgram,
    selectedGroup,
    setSelectedGroup,
    handleClearDegree,
    handleClearCourse,
    handleClearEducationalProgram,
    handleEducationalDegreeSelect,
    handleCourseSelect,
    handleEducationalProgramSelect,
    handleGroupSelect,
    handleSearch,
    handleSetStatus,
    educPrograms,
    courseOptions,
    groupOptions,
    tableData,
  };
};

export default useCurriculum;
