import { Button, Space, Table, Tag } from "antd";
import { ColumnsType } from 'antd/lib/table';
import { StudentWithCurriculumStatus } from "../../../../interfaces/Student/StudentWithCurriculumStatus";

interface Props {
    tableData: StudentWithCurriculumStatus[];
    loading: boolean;
}

interface DataType {
    key: number | string;
    userId: number;
    fullName: string;
    group: string;
    status: Boolean;
}

const OfficeCurriculumTable = ({ tableData, loading }: Props) => {
    const columns: ColumnsType<DataType> = [
        {
            title: '№',
            dataIndex: 'key',
            key: 'key'
        },
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: (a, b) => a.fullName.localeCompare(b.fullName)

        },
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
            sorter: (a, b) => a.group.localeCompare(b.group)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                {
                    text: 'Подвержден',
                    value: true,
                },
                {
                    text: 'Отклонен',
                    value: false,
                },
                {
                    text: 'Нет статуса',
                    value: '',
                },


            ],
            onFilter: (value: any, record: any) => value !== '' ? record.status === value : record.status === null,
            render: (text: any, record: any) => (
                <>
                    {record.status !== null ? (
                        <Tag color={record.status === true ? "green" : "red"}>
                            {record.status === true ? "Подвержден" : "Отклонен"}
                        </Tag>
                    ) : (
                        <Tag>
                            Нет статуса
                        </Tag>
                    )}
                </>

            ),
        },
        {
            dataIndex: "action",
            key: "action",
            render: (text: any, record: any) => (
                <Space>
                    <Button type="default" href={`/students-curriculum/detail/${record.userId}`} target={"_blank"}>
                        Подробнее
                    </Button>
                </Space>
            ),
            width: '5%',
        }
    ];

    const data: DataType[] = [];
    for (let i = 0; i < tableData.length; i++) {
        data.push({
            key: i + 1,
            userId: tableData[i].studentDtoResponse.userId,
            fullName: `${tableData[i]?.studentDtoResponse.nameKz} ${tableData[i]?.studentDtoResponse.surnameKz} ${tableData[i]?.studentDtoResponse.patronymicKz}`,
            group: tableData[i].studentDtoResponse.group.title,
            status: tableData[i].status,
        });
    }

    return (
        <>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                bordered={true}
            />
        </>
    )
}

export default OfficeCurriculumTable