import axios from "axios";
import { Constants } from "./constants";
import { errorNotification } from "../helpers/errorNotification";

const authAxios = axios.create({
  baseURL: Constants.API_BASE_URL,
  timeout: 300000,
});

authAxios.interceptors.request.use(
  (request) => {
    request.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return request;
  },

  (error) => Promise.reject(error)
);

authAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, config } = error.response;

    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      window.location.href = "/login";
    }

    const hideNotification = config.headers.showNotification === false;

    if (!hideNotification) {
      switch (config.method) {
        case 'get':
          errorNotification('Не удалось получить данные', status);
          break;
        case 'post':
          errorNotification('Не удалось отправить данные', status);
          break;
        case 'put':
          errorNotification('Не удалось обновить данные', status);
          break;
        case 'delete':
          errorNotification('Не удалось удалить данные', status);
          break;
        default:
          errorNotification('Произошла ошибка', status);
          break;
      }
    }

    return Promise.reject(error);
  }
);

export default authAxios;
