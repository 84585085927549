import React from 'react'
import Page from '../../../../ui/Page'
import { Button, Col, Form, Row, Space, Typography } from 'antd'
import Center from '../../../../ui/Center';
import { MaskedInput } from 'antd-mask-input';
import { PublicService } from '../../../../services/PublicService';

const DiplomaRegisterNumberPage = () => {
    const PUBLIC_URL = process.env.PUBLIC_URL;
    const [regNum, setRegNum] = React.useState<number>();
    const [errorString, setErrorString] = React.useState<string>("");

    const handleFindNumber = (iin: string) => {
        setRegNum(undefined)
        setErrorString("")
        PublicService.getRegisterNumberByIIN(iin)
            .then(({ data }) => setRegNum(data))
            .catch((err) => {
                setErrorString("По данному ИИН-у не найден регистрационный номер")
            });
    }

    return (
        <Page>
            <Row style={{ margin: 0 }}>
                <Col lg={8} xs={24}>
                    <Space
                        direction="vertical"
                        style={{ borderBottom: '3px solid #0693E3', paddingBottom: 20, marginBottom: 20 }}
                    >
                        <Typography.Title level={4} style={{ fontWeight: 500, fontSize: 26, margin: 0 }}>
                            Вывод регистрационного номера диплома
                        </Typography.Title>
                    </Space>
                </Col>
                <Col lg={8} xs={24}>
                    <Space
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <img src={`${PUBLIC_URL}/assets/color-aitu-logo.svg`} alt="logo" width={250} />
                    </Space>
                </Col>
                <Col lg={8} xs={24}></Col>
            </Row>
            <Center lg={6} style={{ textAlign: 'center', marginTop: 60 }}>
                <Typography.Title level={3} type="secondary">
                    Введите ваш ИИН
                </Typography.Title>
                <Form onFinish={(e) => handleFindNumber(e.iin)}>
                    <Form.Item name="iin"
                        rules={[{ required: true, message: "Введите ваш ИИН" }]}
                    >
                        {/* <Input
                            size='large'
                            maxLength={12}
                            style={{ width: "100%", textAlign: 'center' }}
                        // onPressEnter={(e) => handlers.verifyCertificate(e.currentTarget.value)}
                        // onChange={handlers.handleInputChange}
                        // value={data.codeString}
                        /> */}
                        <MaskedInput mask={'000000000000'} size="large" style={{ width: "60%", fontSize: "30px", textAlign: 'center' }} />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size={'large'}
                            style={{ marginTop: 30 }}
                            block
                        >
                            Получить регистрационный номер
                        </Button>
                    </Form.Item>
                </Form>
            </Center>

            {regNum && (
                <Space style={{ marginTop: 10, width: "100%", alignItems: "center", justifyContent: "center" }}>
                    <Typography.Title level={4}>Ваш номер: </Typography.Title>
                    <Typography.Title level={2}>{regNum}</Typography.Title>
                </Space>
            )}
            {errorString && (
                <Space style={{ marginTop: 10, width: "100%", alignItems: "center", justifyContent: "center" }}>
                    <Typography.Title level={2} type={"danger"}>{errorString}</Typography.Title>
                </Space>
            )}
        </Page >
    )
}

export default DiplomaRegisterNumberPage