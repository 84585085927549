import { DownloadOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Table, Typography, Upload } from 'antd';
import PageWrapper from '../../../../ui/PageWrapper';
import useCreateOrderForm from '../../../../hooks/Order/useCreateOrderForm';
import useStudentDetailsPage from '../../StudentsDetailsPage/useStudentDetails';
import StudentsTable from '../../StudentsDetailsPage/StudentsTable';
import BackButton from '../../../../ui/BackButton';

const { Title } = Typography;
const { Option } = Select;

const CreateOrderPage = () => {
  const {
    style,
    form,
    ordersTypes,
    courseOptions,
    availableStudentColumns,
    selectedStudentColumns,
    data,
    totalNumber,
    onFormFinish,
    onFormFinishFailed,
    handleOrderTypeSelect,
    handleDegreeSelect,
    handleCourseSelect,
    handleYearSelect,
    handleTrimesterSelect,
    handlePageChange,
    handleListOfStudents,
    handleDownloadTemplate,
    selectedOrderType,
    selectedStudent,
    setSelectedStudent,
    selectedStudentId,
    setSelectedStudentId,
    tableVisible,
    areButtonsAvailable,
    loading,
    uploadProps,
    isModalOpen,
    showModal,
    handleOk,
    handleCancel,
    ordersCategories,
  } = useCreateOrderForm({});

  const { searchValue, setSearchValue, studentsList, handleSearchStudents } = useStudentDetailsPage({});

  const years = [
    {
      id: 1,
      year: 2019,
    },
    {
      id: 2,
      year: 2020,
    },
    {
      id: 3,
      year: 2021,
    },
  ];

  const trimesters = [
    {
      id: 1,
      trimester: 1,
    },
    {
      id: 2,
      trimester: 2,
    },
    {
      id: 3,
      trimester: 3,
    },
  ];

  return (
    <PageWrapper>
      <section>
        <BackButton />
        <header style={style}>
          <Title level={3}>Создать приказ</Title>
        </header>
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          size="large"
          form={form}
          onFinish={onFormFinish}
          onFinishFailed={onFormFinishFailed}
          style={style}
        >
          <Form.Item
            label="Тип приказа"
            name="orderType"
            rules={[{ required: true, message: 'Выберите тип приказа' }]}
            style={{ marginBottom: '10px' }}
          >
            <Select placeholder="Выберите приказ" onSelect={(value) => handleOrderTypeSelect(value)} allowClear>
              {ordersTypes?.map((option) => (
                <Option key={option.id} value={option.id}>
                  {`${option.name}/${option.nameRu}/${option.nameEn}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {ordersCategories.length > 0 && (
            <Form.Item label="Категория" name="orderCategory" rules={[{ required: true, message: 'выберите категорию' }]}
              style={{ marginBottom: '10px' }}>
              <Select placeholder="Выберите категорию приказа" allowClear>
                {ordersCategories?.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {`${option.nameRu}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="Номер приказа"
            name="number"
            rules={[{ required: true, message: 'номер приказа' }]}
            style={{ marginBottom: '10px' }}
          >
            <Input style={style} placeholder="Введите номер приказа" />
          </Form.Item>
          <Form.Item
            label="Название приказа"
            name="name"
            rules={[{ required: true, message: 'Введите название приказа' }]}
            style={{ marginBottom: '10px' }}
          >
            <Input placeholder="Введите название приказа" />
          </Form.Item>
          <Form.Item
            label="Дата приказа"
            name="issueDate"
            rules={[{ required: true, message: 'Выберите дату создания приказа' }]}
            style={{ marginBottom: '10px' }}
          >
            <DatePicker placeholder="Выберите дату" style={{ width: '50%' }} />
          </Form.Item>
          <Form.Item
            label="Дата движения"
            name="contingateDate"
            rules={[{ required: true, message: 'Выберите дату движения' }]}
            style={{ marginBottom: '10px' }}
          >
            <DatePicker placeholder="Выберите дату" style={{ width: '50%' }} />
          </Form.Item>
          {/* <Form.Item label="Файл" name="file" rules={[{ required: true, message: 'Выберите дату создания приказа' }]}> */}
          <Upload {...uploadProps} accept={'.pdf'}>
            <Button icon={<UploadOutlined />}>Загрузите файл приказа</Button>
          </Upload>
          {/* </Form.Item> */}
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ marginTop: "10px" }}>
              Создать
            </Button>
          </Form.Item>
          {selectedOrderType && Object.entries(data).length !== 0 && (
            <>
              <Form.Item label="Год обучения" name="year" style={{ marginBottom: '10px' }}>
                <Select placeholder="Выберите год обучения" onSelect={(value) => handleYearSelect(value)} allowClear>
                  {years?.map((option) => (
                    <Option key={option.id} value={option.year}>
                      {`${option.year}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Степень образования" name="academicDegreeId" style={{ marginBottom: '10px' }}>
                <Select
                  placeholder="Выберите степень образования"
                  // @ts-ignore
                  onSelect={(value) => handleDegreeSelect(value)}
                  allowClear
                >
                  <Option key={1} value={1}>
                    Bachelor's degree
                  </Option>
                  <Option key={2} value={2}>
                    Master's degree
                  </Option>
                  <Option key={3} value={6}>
                    Doctorate's degree
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item label="Курс" name="course" style={{ marginBottom: '10px' }}>
                <Select placeholder="Выберите курс" onSelect={(value) => handleCourseSelect(value)} allowClear>
                  {courseOptions?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {`${option.year}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Триместр" name="term" style={{ marginBottom: '10px' }}>
                <Select placeholder="Выберите триместр" onSelect={(value) => handleTrimesterSelect(value)} allowClear>
                  {trimesters.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {`${option.trimester}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  type="default"
                  disabled={!areButtonsAvailable}
                  icon={<DownloadOutlined />}
                  style={{ marginTop: '20px' }}
                  onClick={() => handleListOfStudents()}
                >
                  Скачать список студентов
                </Button>
                <Button
                  type="default"
                  disabled={!areButtonsAvailable}
                  onClick={() => handleDownloadTemplate()}
                  icon={<DownloadOutlined />}
                  style={{ marginLeft: '20px' }}
                >
                  Скачать шаблон
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
        {tableVisible && (
          <div style={{ ...style, marginTop: '40px' }}>
            <Row>
              <Col flex={0}>
                <Title level={4}>Выбранные студенты</Title>
              </Col>
              <Col flex="auto"></Col>
              <Col flex={0}>
                <Button type="default" onClick={showModal} icon={<PlusOutlined />}>
                  Добавить студентов
                </Button>
              </Col>
            </Row>
            <Table columns={selectedStudentColumns} dataSource={selectedStudent} loading={loading} />
          </div>
        )}
        {tableVisible && (
          <div style={{ ...style, marginTop: '40px' }}>
            <Title level={4}>Все студенты подходящие к приказу</Title>
            <Table
              columns={availableStudentColumns}
              dataSource={data}
              pagination={{ defaultPageSize: 20, total: totalNumber, simple: true }}
              loading={loading}
              onChange={(pagination) => handlePageChange(pagination)}
            />
          </div>
        )}

        <Modal
          title="Выбрать студентов"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={true}
          width={1000}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Закрыть
            </Button>,
          ]}
        >
          <Input.Search
            placeholder="Введите ФИО Студента"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={(value) => handleSearchStudents(value)}
            size="large"
            enterButton
            allowClear
          />
          {studentsList.length > 0 && (
            <StudentsTable
              loading={loading}
              data={studentsList}
              selectable
              selectedStudentId={selectedStudentId}
              setSelectedStudentId={setSelectedStudentId}
              selectedStudent={selectedStudent}
              setSelectedStudent={setSelectedStudent}
            />
          )}
        </Modal>
      </section>
    </PageWrapper>
  );
};

export default CreateOrderPage;
