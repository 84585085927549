import { useState, ReactNode, useEffect } from 'react';
import { DeansOfficeAppointmentService } from '../../../services/DeansOfficeAppointmentService';
import { AvailableAppointmentDatesResponse } from '../../../interfaces/deansOffice/AvailableAppointmentDatesResponse';
import { successNotification } from '../../../helpers/successNotification';
import { DeanOfficeAppointmentDtoResponse } from '../../../interfaces/deansOffice/DeanOfficeAppointmentDtoResponse';
import { Button, Popconfirm, Space } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
const useDeanOfficeAppointment = () => {


    const [selectedFiltersOption, setSelectedFiltersOption] = useState<number | undefined>();
    const [selectedDate, setSelectedDate] = useState<Date | undefined>();
    const [availableDate, setAvailableDate] = useState<AvailableAppointmentDatesResponse[]>([]);
    const [availableDateTimes, setAvailableDateTimes] = useState<any[]>([]);
    const [selectedDateTimes, setSelectedDateTimes] = useState<any>();
    const [comment, setComment] = useState<string | undefined>();
    const [studentAppointmentList, setStudentAppointmentList] = useState<DeanOfficeAppointmentDtoResponse[]>([]);
    const [shouldRerender, setShouldRerender] = useState(false);

    useEffect(() => {
        DeansOfficeAppointmentService.getAvailableDates().then(({ data }) => setAvailableDate(data))
        setSelectedDate(undefined);
        DeansOfficeAppointmentService.getAppointmentsByStudentId()
            .then((response) => {
                setStudentAppointmentList(response.data)
            })

    }, [selectedFiltersOption, shouldRerender]);

    const saveData = () => {

        DeansOfficeAppointmentService.createDeansOfficeAppointment({
            typeId: selectedFiltersOption,
            appointmentDateTime: selectedDateTimes,
            studentQuestion: comment,

        })
            .then(() => {
                successNotification('вы успешно записались на прием');
                setSelectedFiltersOption(undefined);

            });
    };
    const onDeleteAppointment = (appointmentId: number) => {
        DeansOfficeAppointmentService.deleteAppointment(appointmentId)
            .then(() => setShouldRerender(!shouldRerender))
            .catch((error: any) => console.error(error));
    };
    const handleSelectOption = (value: number) => {
        setSelectedFiltersOption(value);
        setSelectedDate(undefined);
        setSelectedDateTimes(undefined);
    };

    const handleSelectDate = (value: Date) => {
        setSelectedDate(value);
        setSelectedDateTimes(undefined);
        DeansOfficeAppointmentService.getAvailableDateTimes(selectedFiltersOption, value)
            .then((response) => {
                setAvailableDateTimes(response.data);
            })
    };

    const handleSelectDateTime = (value: any) => {
        setSelectedDateTimes(value);
    };

    const handleChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
    };

    const columns: any[] = [
        {
            title: 'Appointment type',
            dataIndex: 'type',
            key: 'type',
            width: '25%',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            width: '10%',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },

        {
            title: 'My Question',
            dataIndex: 'studentQuestion',
            key: 'studentQuestion',
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            width: 120,
            render: (text: any, record: any) => (
                <Popconfirm
                    placement="topLeft"
                    title={'Delete an appointment?'}
                    onConfirm={() => onDeleteAppointment(record.key)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Space size="middle">
                        <Button >
                            <DeleteTwoTone twoToneColor="#ff0000" />
                        </Button>
                    </Space>
                </Popconfirm>
            ),
        },
    ];
    const dataSource = studentAppointmentList.map(data => {

        // Создаем объект Date из строки времени
        const date = new Date(data.appointmentDateTime);

        // Получаем часы и минуты
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        // Форматируем время в нужный формат 
        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

        return {
            key: data.id,
            type: data.typeId.nameRu,
            time: formattedTime,
            date: year + '-' + month + '-' + day,
            student: data.student.nameEn + " " + data.student.surnameEn,
            group: data.student.group.title,
            studentQuestion: data.studentQuestion
        };
    });
    return {

        handleSelect: {
            handleSelectOption,
            handleSelectDate,
            handleSelectDateTime,
            handleChangeComment
        },
        selectedFiltersOption,
        selectedDate,
        availableDateTimes,
        selectedDateTimes,
        saveData,
        onDeleteAppointment,
        availableDate,
        comment,
        studentAppointmentList,
        columns,
        dataSource
    };
};

export default useDeanOfficeAppointment;


