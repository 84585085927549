import { Form, notification, Space, Button } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OrderTypeDtoResponse } from '../../interfaces/Order/OrderTypeDtoResponse';
import { OrdersService } from '../../services/OrdersService';
import { ColumnsType } from 'antd/lib/table';
import { StudentDtoResponse } from '../../interfaces/Student/StudentDtoResponse';
import { CourseSelectResponse } from '../../interfaces/CourseSelectResponse';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { OrderDtoRequest } from '../../interfaces/Order/OrderDtoRequest';
import { RcFile } from 'antd/lib/upload';
import { UploadProps } from 'antd/es/upload/interface';
import { TranscriptService } from '../../services/TranscriptService';
import { errorNotification } from '../../helpers/errorNotification';
import { successNotification } from '../../helpers/successNotification';
import { OrderCategoryDtoResponse } from '../../interfaces/Order/OrderCategoryDtoResponse';

interface DataType {
  key: number | string;
  id: number;
  fullName: string;
  group: string;
}

interface FilterRow {
  text: string;
  value: string;
}

interface Props {
  retirePage?: boolean;
}

const useCreateOrderForm = ({ retirePage = false }: Props) => {
  const [form] = Form.useForm();
  const [ordersTypes, setOrderTypes] = useState<OrderTypeDtoResponse[]>([]);
  const [ordersCategories, setOrdersCategories] = useState<OrderCategoryDtoResponse[]>([]);
  const [courseOptions, setCourseOptions] = useState<CourseSelectResponse[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [tableVisible, setTableVisible] = useState<boolean>(false);
  const [areButtonsAvailable, setAreButtonsAvailable] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number | undefined>(0);
  const [tableData, setTableData] = useState<StudentDtoResponse[] | any>([]);
  const [totalNumber, setTotalNumber] = useState(0);
  const [filterRows, setFilterRows] = useState<FilterRow[]>([]);

  const [selectedOrderType, setSelectedOrderType] = useState<number | undefined>();
  const [selectedEducationalDegree, setSelectedEducationalDegree] = useState<number | undefined>();
  const [selectedCourse, setSelectedCourse] = useState<number | undefined>();
  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  const [selectedTrimester, setSelectedTrimester] = useState<number | undefined>();

  const [selectedStudent, setSelectedStudent] = useState<DataType[]>([]);
  const [selectedStudentId, setSelectedStudentId] = useState<number[]>([]);
  const [file, setFile] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!retirePage) {
      OrdersService.getAllOrderTypes().then(({ data }) => {
        setOrderTypes(data);
      });
      TranscriptService.getAllStudentsGroup().then(({ data }) => {
        let res = data.map((item) => {
          return {
            text: item.title,
            value: item.title,
          };
        });
        setFilterRows(res);
      });
    }
  }, [retirePage]);

  useEffect(() => {
    if (selectedOrderType) {
      setLoading(true);
      setTableVisible(true);
      OrdersService.getStudentsByOrder({
        page: currentPage,
        academicDegreeId: selectedEducationalDegree,
        course: selectedCourse,
        order_type: selectedOrderType,
        term: selectedTrimester,
        year: selectedYear,
      })
        .then((res) => {
          if (Object.entries(res.data).length === 0) {
            errorNotification(`Нет подходящих студентов по этому приказу`);
            setTableVisible(true);
            setTableData([]);
            setTotalNumber(0);
          } else {
            setTableData(res.data.list);
            setTotalNumber(res.data.total_number);
            if (res.data.list.length === 0) {
              errorNotification(`Не найдено ни одного студента`);
              setTableVisible(true);
            } else {
              setTableVisible(true);
            }
          }
        })
        .finally(() => setLoading(false));
      OrdersService.getOrderCategories(selectedOrderType)
        .then(({ data }) => {
          setOrdersCategories(data);
        })
    }
  }, [currentPage, selectedEducationalDegree, selectedCourse, selectedOrderType, selectedTrimester, selectedYear]);

  const data: DataType[] = [];

  for (let i = 0; i < tableData.length; ++i) {
    data.push({
      key: i + 1,
      id: tableData[i].id,
      fullName: `${tableData[i]?.nameKz} ${tableData[i]?.surnameKz} ${tableData[i]?.patronymicKz}`,
      group: tableData[i]?.group.title,
    });
  }

  const style = { width: '800px', margin: '0 auto' };
  const history = useHistory();

  const onFormFinish = (order: OrderDtoRequest) => {
    order.issued = true;
    let formData = new FormData();
    const orderStudentDtoRequest = { orderDtoRequest: order, students: selectedStudentId };

    formData.append("orderStudentDtoRequest", new Blob([JSON.stringify(orderStudentDtoRequest)], { type: 'application/json' }));
    formData.append("file", file);

    OrdersService.createOrder(formData)
      .then(() => notification.success({ message: 'Приказ создан!' }))
    setTimeout(() => history.push('/order-list'), 1000);
  };

  const handlePageChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current);
  };

  const onFormFinishFailed = (errorInfo: any) => {
    errorNotification('Заполните все поля!');
  };

  const handleOrderTypeSelect = (value: any) => {
    setSelectedOrderType(value);
    setAreButtonsAvailable(true);
    setTableVisible(false);
    form.setFieldsValue({
      year: undefined,
      academicDegreeId: undefined,
      course: undefined,
      term: undefined,
    });
  };

  const handleDegreeSelect = (value: any) => {
    setSelectedEducationalDegree(value);
    setAreButtonsAvailable(true);
    setTableVisible(false);
    if (value === 2) {
      if (selectedOrderType === 2) {
        setCourseOptions([{ id: 1, year: 1 }]);
      } else {
        setCourseOptions([
          { id: 1, year: 1 },
          { id: 2, year: 2 },
        ]);
      }
    } else {
      if (selectedOrderType === 2) {
        setCourseOptions([
          { id: 1, year: 1 },
          { id: 2, year: 2 },
        ]);
      } else {
        setCourseOptions([
          { id: 1, year: 1 },
          { id: 2, year: 2 },
          { id: 3, year: 3 },
        ]);
      }
    }
    form.setFieldsValue({
      course: undefined,
      term: undefined,
    });
  };

  const handleCourseSelect = (value: any) => {
    setSelectedCourse(value);
    setAreButtonsAvailable(true);
    setTableVisible(false);
    form.setFieldsValue({
      term: undefined,
    });
  };

  const uploadProps: UploadProps = {
    beforeUpload: (file: RcFile, fileList: RcFile[]) => {
      const isPDF = fileList[0].type === 'application/pdf';
      if (!isPDF) {
        errorNotification(`${fileList[0].name} is not pdf file!`);
      } else {
        setFile(file)
      }
      return false
    },
  };

  const handleStudentSelect = (record: any) => {
    const student = selectedStudent.find((student) => student.id === record.id);
    if (!student) {
      setSelectedStudent([...selectedStudent, record]);
      setSelectedStudentId([...selectedStudentId, record.id]);
      console.log(selectedStudent)
      successNotification(`Студент был успешно выбран`);
    } else {
      errorNotification(`Вы уже выбрали этого студента!`);
    }
  };

  const handleDelete = (record: any) => {
    const newData = selectedStudent.filter((student) => student.id !== record.id);
    setSelectedStudent(newData);
    const newDataId = selectedStudentId.filter((student) => student !== record.id);
    setSelectedStudentId(newDataId);
  };

  const handleYearSelect = (value: any) => {
    setSelectedYear(value);
    setAreButtonsAvailable(true);
    setTableVisible(false);
    form.setFieldsValue({
      academicDegreeId: undefined,
      course: undefined,
      term: undefined,
    });
  };

  const handleTrimesterSelect = (value: any) => {
    setSelectedTrimester(value);
  };

  const handleListOfStudents = () => {
    if (selectedOrderType) {
      OrdersService.studentListExcel({
        academicDegreeId: selectedEducationalDegree,
        course: selectedCourse,
        order_type: selectedOrderType,
        term: selectedTrimester,
        year: selectedYear,
      })
        .then(() => {
          notification.success({ message: 'Список студентов был успешно скачан!' });
        });
    }
  };

  const handleDownloadTemplate = () => {
    if (tableData.length > 0 && selectedOrderType) {
      if (selectedOrderType === 1 && selectedStudentId.length === 0) {
        errorNotification('Вы не выбрали студентов для приказа!');
      } else {
        OrdersService.downloadTemplate(
          {
            academicDegreeId: selectedEducationalDegree,
            course: selectedCourse,
            order_type: selectedOrderType,
            term: selectedTrimester,
            year: selectedYear,
          },
          selectedStudentId
        )
          .then(() => {
            successNotification('Шаблон был успешно скачан!');
          });
      }
    } else {
      errorNotification('Нету студентов для приказа');
    }
  };

  const availableStudentColumns: ColumnsType<DataType> = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      filters: filterRows,
      onFilter: (value: any, record: any) => record.group.indexOf(value) === 0,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: (text: any, record: any) => (
        <Space>
          <Button type="default" onClick={() => handleStudentSelect(record)}>
            <PlusOutlined />
          </Button>
        </Space>
      ),
      width: '5%',
    },
  ];

  const selectedStudentColumns: ColumnsType<DataType> = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      filters: filterRows,
      onFilter: (value: any, record: any) => record.group.indexOf(value) === 0,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: (text: any, record: any) => (
        <Space>
          <Button type="default" onClick={() => handleDelete(record)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
      width: '5%',
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return {
    style,
    form,
    ordersTypes,
    courseOptions,
    availableStudentColumns,
    selectedStudentColumns,
    data,
    totalNumber,
    onFormFinish,
    onFormFinishFailed,
    handleOrderTypeSelect,
    handleYearSelect,
    handleDegreeSelect,
    handleCourseSelect,
    handleTrimesterSelect,
    handlePageChange,
    handleListOfStudents,
    handleDownloadTemplate,
    selectedTrimester,
    selectedOrderType,
    selectedStudent,
    setSelectedStudent,
    selectedStudentId,
    setSelectedStudentId,
    tableVisible,
    areButtonsAvailable,
    loading,
    uploadProps,
    isModalOpen,
    setIsModalOpen,
    showModal,
    handleOk,
    handleCancel,
    ordersCategories
  };
};

export default useCreateOrderForm;
