import authAxios from "../common/authAxios";
import { AbiturCatalogDtoResponse } from "../interfaces/AbiturCatalogDtoResponse";
import { AcademicMobilityCompetitionDtoRequest, AcademicMobilityCompetitionDtoResponse, AcademicMobilityCompetitionStatusDto, StudentAcademicMobilityCompetitionDto, StudentAcademicMobilityCompetitionInfoDto, StudentAcademicMobilityCompetitionInfoDtoRequest, StudentAcademicMobilityCompetitionInfoTypeDto } from "../interfaces/AcademicMobilityCompetitionDto";
import { ApplicationApproveDtoRequest } from "../interfaces/Applications/request/ApplicationApproveDtoRequest";

export class AcademicMobilityCompetitionService {
    static getStatuses() {
        return authAxios.get<AcademicMobilityCompetitionStatusDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/competition/statuses`
        );
    }

    static getAllCompetitions() {
        return authAxios.get<AcademicMobilityCompetitionDtoResponse[]>(
            `/astanait-office-module/api/v1/academic-mobility/competition`
        );
    }

    static getCompetitionById(id: number) {
        return authAxios.get<AcademicMobilityCompetitionDtoResponse>(
            `/astanait-office-module/api/v1/academic-mobility/competition/${id}`
        );
    }

    static createCompetition(competition: AcademicMobilityCompetitionDtoRequest) {
        return authAxios.post(
            `/astanait-office-module/api/v1/academic-mobility/competition`,
            competition
        );
    }

    static updateCompetition(competition: AcademicMobilityCompetitionDtoRequest) {
        return authAxios.put(
            `/astanait-office-module/api/v1/academic-mobility/competition/${competition.id}`,
            competition
        );
    }

    static deleteCompetition(id: number) {
        return authAxios.delete(
            `/astanait-office-module/api/v1/academic-mobility/competition/${id}`
        );
    }

    static getStudentCompetitionStatuses() {
        return authAxios.get<AcademicMobilityCompetitionStatusDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/statuses`
        );
    }

    static getStudentCompetitions() {
        return authAxios.get<StudentAcademicMobilityCompetitionDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition`
        );
    }

    static getStudentCompetitionById(id: number) {
        return authAxios.get<StudentAcademicMobilityCompetitionDto>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/${id}`
        );
    }

    static getStudentCompetitionsByCompetitionIdAndStatus(competitionId: number, statusId: number) {
        return authAxios.get<StudentAcademicMobilityCompetitionDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/competition/${competitionId}/status/${statusId}`
        );
    }

    static getStudentCompetitionInfoById(id: number) {
        return authAxios.get<StudentAcademicMobilityCompetitionInfoDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/info/${id}`
        );
    }

    static getStudentCompetitionInfoTypes() {
        return authAxios.get<StudentAcademicMobilityCompetitionInfoTypeDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/info/types`
        );
    }

    static getAvailableStatuses(studentCompetitionId: number) {
        return authAxios.get<AcademicMobilityCompetitionStatusDto[]>(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/${studentCompetitionId}/available-statuses`
        );
    }

    static createStudentCompetition(studentCompetition: StudentAcademicMobilityCompetitionInfoDtoRequest[], competitionId?: number) {
        return authAxios.post(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/${competitionId}`, studentCompetition,
            {
                headers: {
                    showNotification: false
                },
            }
        );
    }

    static updateStudentCompetitionInfo(id: number, value: string) {
        return authAxios.put(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/info/${id}`, value,
            {
                headers: {
                    'Content-Type': 'text/plain',
                    showNotification: false
                },
            }
        );

    }

    static updateStudentCompetitionStatus(studentCompetitionId: number, request: ApplicationApproveDtoRequest) {
        return authAxios.put(
            `/astanait-office-module/api/v1/academic-mobility/student-competition/${studentCompetitionId}/status`, request
        );
    }

    static getLanguageLevels() {
        return authAxios.get<AbiturCatalogDtoResponse[]>(
            `/astanait-office-module/api/v1/dict/language-levels`
        );
    }
}