import { SaveOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd'
import React from 'react'
import { TeacherDegreeDtoResponse } from '../../../../interfaces/Teacher/TeacherDegreeDtoResponse';
import grantPermission from '../../../../helpers/grantPermission';

interface Props {
    dataSource?: TeacherDegreeDtoResponse[];
}

const PublicTeacherAcademicTitleTable = ({ dataSource }: Props) => {


    const columns = grantPermission('HR') ? [
        {
            title: "#",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Name",
            dataIndex: "degree",
            key: "degree"
        },
        {
            title: "Speciality",
            dataIndex: "speciality",
            key: "speciality"
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            render: (_: any, record: any) => {
                return (
                    <Button icon={<SaveOutlined />} onClick={() => { }}>
                        Download diploma/certificate
                    </Button>
                )
            }
        },
    ] : [
        {
            title: "#",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Name",
            dataIndex: "degree",
            key: "degree"
        },
        {
            title: "Speciality",
            dataIndex: "speciality",
            key: "speciality"
        },
    ]

    return (
        <>
            <Table columns={columns} rowKey={(record) => record.id}
                dataSource={dataSource} bordered />
        </>
    )
}

export default PublicTeacherAcademicTitleTable