import { Descriptions, Row, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { IrosService } from '../../../services/student/IrosService';
import { SsciService } from '../../../services/student/SsciService';
import { TranscriptService } from '../../../services/TranscriptService';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface Props {
  userId?: number;
  studentId?: number;
}
const NoData = () => <Typography.Text type="secondary">Нет данных</Typography.Text>;

const ProfileGpa = ({ userId, studentId }: Props) => {
  const [iROS, setIROS] = useState<number | undefined>(0);
  const [ssci, setSsci] = useState<number | undefined>(0);
  const [gpa, setGpa] = useState<number | undefined>(0);

  const [igpa, setIgpa] = useState<number>(0);

  useEffect(() => {
    if (studentId) {
      IrosService.getIrosPointForStudent(studentId)
        .then(({ data }) => setIROS(data.iros))
      if (userId) {
        TranscriptService.getTranscriptGpaForTrimestersWithAverage(userId)
          .then(({ data }) => setGpa(data.averageGpa))
      } else {
        TranscriptService.getTranscriptGpaForTrimestersWithAverageForStudents()
          .then(({ data }) => setGpa(data.averageGpa))
      }
      SsciService.getGpi(studentId)
        .then(({ data }) => setSsci(data))
    }
  }, [studentId, userId]);

  useEffect(() => {
    setIgpa((gpa || 0) * 0.5 + (iROS || 0) * 0.35 + (ssci || 0) * 0.15);
  }, [gpa, iROS, ssci]);
  return (
    <>
      <Descriptions
        title={
          <Row>
            <Typography.Title level={5}>IGPA</Typography.Title>
            <Tooltip title="IGPA is calculated by formula: GPA*50% + IROS*35% + SSCI*15%">
              <InfoCircleOutlined style={{ marginLeft: 8, marginTop: 4 }} />
            </Tooltip>
          </Row>
        }
      >
        <Descriptions.Item label="GPA">{gpa || <NoData />}</Descriptions.Item>
        <Descriptions.Item label="iROS"><Link to={`/student-personal-iros-application?studentId=${studentId}`}>{iROS || <NoData />}</Link></Descriptions.Item>

        <Descriptions.Item label="SSCI"><Link to={`/student-personal-ssci-application?studentId=${studentId}`}>{ssci || <NoData />}</Link></Descriptions.Item>

        <Descriptions.Item label="IGPA">{igpa.toFixed(2) || <NoData />}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ProfileGpa;
