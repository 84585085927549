import { useEffect, useState } from 'react';
import { PersistentStateService } from '../../../services/PersistentStateService';
import { DiplomaWorkDtoResponse } from '../../../interfaces/Diploma/response/DiplomaWorkDtoResponse';
import { DiplomaService } from '../../../services/DiplomaService';
import { StudentsListDtoResponse } from '../../../interfaces/Student/StudentsListDtoResponse';
import { StudentGroupsDtoResponse } from '../../../interfaces/Student/StudentGroupsDtoResponse';
import { TranscriptService } from '../../../services/TranscriptService';
import { MenuProps, Typography } from 'antd';
import { PersistentStateConstants } from '../../../PersistentStateConstants';
import { StudentService } from '../../../services/StudentService';

const useDiplomaSupplementPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<StudentGroupsDtoResponse[]>([]);
  const [students, setStudents] = useState<StudentsListDtoResponse[]>([]);
  const [diplomaWorks, setDiplomaWorks] = useState<DiplomaWorkDtoResponse[]>([]);

  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>();
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();
  const [selectedDiplomaWorkId, setSelectedDiplomaWorkId] = useState<number | undefined>();

  const onGroupSelect = (groupId: number) => {
    setLoading(true);
    setSelectedGroupId(groupId);
    setSelectedUserId(undefined);
    setSelectedDiplomaWorkId(undefined);

    TranscriptService.getStudentsByGroupForTranscript(groupId)
      .then((response) => {
        const mappedStudents = response.data.map((student) => ({
          id: student.id,
          name: student.nameKz,
          surname: student.surnameKz,
          patronymic: student.patronymicKz,
          userId: student.userId
        }));
        setStudents(mappedStudents);
      })
      .finally(() => setLoading(false));
  };

  const onStudentSelect = (userId: number) => {
    setLoading(true);
    setSelectedUserId(userId);
    setSelectedDiplomaWorkId(undefined);
    StudentService.getStudentIdByUserId(userId).then(({ data: student }) => {
      DiplomaService.getDiplomaWorksByStudentId(student?.id || 0)
        .then((response) => {
          setDiplomaWorks(response.data);
        })
        .finally(() => setLoading(false));
    })
  };

  useEffect(() => {
    setLoading(true);
    TranscriptService.getAllStudentsGroup()
      .then((response) => setGroups(response.data))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (PersistentStateService.hasInputFieldsInfo(PersistentStateConstants.DIPLOMA_SUPPLEMENT_INPUT_FIELDS)) {
      const inputFields: any = JSON.parse(
        // @ts-ignore
        PersistentStateService.getInputFieldsInfo(PersistentStateConstants.DIPLOMA_SUPPLEMENT_INPUT_FIELDS)
      );
      onGroupSelect(inputFields.selectedGroupId);
      onStudentSelect(inputFields.selectedUserId);
      setSelectedDiplomaWorkId(inputFields.selectedDiplomaWorkId);
    }
  }, []);

  useEffect(() => {
    PersistentStateService.setInputFieldsInfo(PersistentStateConstants.DIPLOMA_SUPPLEMENT_INPUT_FIELDS, {
      selectedGroupId,
      selectedUserId,
      selectedDiplomaWorkId,
    });
  }, [selectedGroupId, selectedUserId, selectedDiplomaWorkId]);

  const exportBothLanguages = () => {
    setLoading(true);
    DiplomaService.getDiplomaSupplementBothLanguages(selectedUserId, selectedDiplomaWorkId).finally(() =>
      setLoading(false)
    );
  };
  const exportRu = () => {
    setLoading(true);
    DiplomaService.getDiplomaSupplementRu(selectedUserId, selectedDiplomaWorkId).finally(() => setLoading(false));
  };
  const exportKz = () => {
    setLoading(true);
    DiplomaService.getDiplomaSupplementKz(selectedUserId, selectedDiplomaWorkId).finally(() => setLoading(false));
  };
  const exportEn = () => {
    setLoading(true);
    DiplomaService.getDiplomaEn(selectedUserId, selectedDiplomaWorkId).finally(() => setLoading(false));
  };
  const exportEnSupplement = () => {
    setLoading(true);
    DiplomaService.getDiplomaSupplementEn(selectedUserId, selectedDiplomaWorkId).finally(() => setLoading(false));
  };
  const exportDiploma = () => {
    setLoading(true);
    DiplomaService.getDiploma(selectedUserId, selectedDiplomaWorkId).finally(() => setLoading(false));
  };

  const items: MenuProps['items'] = [
    {
      label: <Typography.Text onClick={exportBothLanguages}>На двух языках</Typography.Text>,
      key: '0',
    },
    {
      label: <Typography.Text onClick={exportRu}>На русском</Typography.Text>,
      key: '1',
    },
    {
      label: <Typography.Text onClick={exportKz}>На казахском</Typography.Text>,
      key: '2',
    },
    {
      label: <Typography.Text onClick={exportEnSupplement}>На английском</Typography.Text>,
      key: '3',
    },
    {
      label: <Typography.Text onClick={exportEn}>На английском(болонский процесс)</Typography.Text>,
      key: '4',
    },

  ];

  const componentSize = 'large';

  return {
    groups,
    setGroups,
    students,
    setStudents,
    diplomaWorks,
    setDiplomaWorks,
    selectedGroupId,
    setSelectedGroupId,
    selectedUserId,
    setSelectedUserId,
    selectedDiplomaWorkId,
    setSelectedDiplomaWorkId,
    onGroupSelect,
    onStudentSelect,
    exportBothLanguages,
    exportRu,
    exportKz,
    exportEn,
    exportDiploma,
    loading,
    items,
    componentSize,
  };
};

export default useDiplomaSupplementPage;
