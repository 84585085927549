import { TeacherDtoResponse } from '../../../interfaces/Teacher/TeacherDtoResponse';

interface TableColumns {
    id: number;
    userId: number;
    fullName: string;
    department?: string;
}


const useTeacherSelect = (data: TeacherDtoResponse[], selectionStates: any) => {
    const { selectedTeacherId, setSelectedTeacherId } = selectionStates;
    let filteredData: TableColumns[] = [];

    // eslint-disable-next-line array-callback-return
    data.map((teacher) => {
        filteredData.push({
            id: teacher.id,
            userId: teacher.userId,
            fullName: teacher.nameKz + " " + teacher.surnameKz + " " + teacher.patronymicKz,
            department: teacher.department ? teacher.department.titleRu : "Не указано",
        });
    });

    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Позиция',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Дата рождения',
            dataIndex: 'birthDate',
            key: 'birthDate',
        },
        {
            title: 'ИИН',
            key: 'iin',
            dataIndex: 'iin',
        },
        {
            title: 'Статус',
            key: 'status',
            dataIndex: 'status',
        },
        {
            title: 'Позиция',
            key: 'position',
            dataIndex: 'position',
        },
    ];

    const shortColumns = [
        {
            title: 'ФИО',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Департамент',
            key: 'department',
            dataIndex: 'department',
        },
    ];

    const handleSelect = (record: TableColumns, selected: any) => {
        if (selected) {
            setSelectedTeacherId((ids: any) => [...ids, record.id]);
        } else {
            setSelectedTeacherId((ids: any) => {
                const index = ids.indexOf(record.id);
                return [...ids.slice(0, index), ...ids.slice(index + 1)];
            });
        }
    };

    const selectRow = (record: TableColumns) => {
        const selectedRows = [...selectedTeacherId];

        if (selectedRows.indexOf(record.id) >= 0) {
            selectedRows.splice(selectedRows.indexOf(record.id), 1);
        } else {
            selectedRows.push(record.id);
        }
        setSelectedTeacherId(selectedRows);
    };

    const rowSelection = {
        selectedRowKeys: selectedTeacherId,
        onSelect: handleSelect,
    };



    return {
        columns,
        shortColumns,
        rowSelection,
        selectRow,
        filteredData,
    }
}

export default useTeacherSelect