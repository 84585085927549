import authAxios from "../common/authAxios";

export enum StudentAffirmationStatus {
  AGREE = 'agree',
  NOT_AGREE = 'not agree',
  NOT_CHECKED = 'not checked',
}

export enum StudentAffirmationType {
  TRANSCRIPT = 1,
  ELECTIVE_DISCIPLINES,
  CONFIRM_DATIVE_CASE,
}

export class StudentAffirmationService {
  private static baseUrl = '/astanait-student-module/api/v1/student-affirmation';

  static getStudentStatusByStudentAndType(type: number) {
    return authAxios.get<string>(`${this.baseUrl}/get-status-by-student-and-type?type=${type}`)
  }

  static createStudentAffirmation(type: number, status: boolean, reason: string) {
    return authAxios.put(`${this.baseUrl}/create?type=${type}&status=${status}&comment=${reason}`);
  }
}