import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import grantPermission from '../../../../helpers/grantPermission';
import { AcademicDebtService } from '../../../../services/AcademicDebtService';

interface AcademicDebtInterface {
  number?: number | string;
  disciplineName: string;
  creditsAmount?: number | string;
  midTerm?: number | string;
  endTerm?: number | string;
  final?: number | string;
  alphabeticGrade?: string;
  gpaGrade?: number | string;
  traditionalGrade?: number | string;
}

const StudentDebtTable = () => {
  const [transcript, setTranscript] = useState<AcademicDebtInterface[] | undefined>();

  useEffect(() => {
    if (grantPermission('STUDENTS')) {
      AcademicDebtService.AcademicDebtsForStudent().then((debtsListResponse) => {
        let debtListArr: AcademicDebtInterface[] = [];
        let counter = 0;

        debtsListResponse.data.forEach((debt) => {
          const {
            academicStream: { discipline },
            academicStreamStudentGrade,
            alphabeticGrade,
            gpaGrade,
            traditionalGrade,
          } = debt;

          ++counter;

          debtListArr.push({
            number: counter,
            disciplineName: `${discipline.titleEn}/${discipline.titleRu}/${discipline.titleKz}`,
            creditsAmount: discipline.volumeCredits,
            midTerm: academicStreamStudentGrade[0].grade,
            endTerm: academicStreamStudentGrade[1].grade,
            final: academicStreamStudentGrade[2].grade,
            alphabeticGrade,
            gpaGrade,
            traditionalGrade,
          });
        });

        setTranscript(debtListArr);
      });
    }
  }, []);

  const columns: any[] = [
    {
      title: '№',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Subject Name',
      dataIndex: 'disciplineName',
      key: 'disciplineName',
    },
    {
      title: 'Credits',
      dataIndex: 'creditsAmount',
      key: 'creditsAmount',
    },
    {
      title: 'Terms',
      children: [
        {
          title: 'Midterm',
          dataIndex: 'midTerm',
          key: 'midTerm',
        },
        {
          title: 'Endterm',
          dataIndex: 'endTerm',
          key: 'endTerm',
        },
        {
          title: 'Final',
          dataIndex: 'final',
          key: 'final',
        },
      ],
    },
    {
      title: 'Mark',
      children: [
        {
          title: 'Alphabetic',
          dataIndex: 'alphabeticGrade',
          key: 'alphabeticGrade',
        },
        {
          title: 'In points',
          dataIndex: 'gpaGrade',
          key: 'gpaGrade',
        },
        {
          title: 'Traditional',
          dataIndex: 'traditionalGrade',
          key: 'traditionalGrade',
        },
      ],
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={transcript} bordered={true} />
    </>
  );
};

export default StudentDebtTable;
