import { Button, Form, FormInstance, Select } from "antd";
import { ComponentDtoResponse } from "../../../../interfaces/EducationalPrograms/ComponentDtoResponse";
import { CycleDtoResponse } from "../../../../interfaces/EducationalPrograms/CycleDtoResponse";

const { Option } = Select;

interface Props {
  cycles: CycleDtoResponse[];
  components: ComponentDtoResponse[];
  form: FormInstance<any>;
  onFinish: (values: any) => void;
}

const GenericForm = ({ cycles, components, form, onFinish }: Props) => {
  return (
    <Form
      layout="vertical"
      size="large"
      form={form}
      onFinish={(values) => onFinish(values)}
    >
      <Form.Item
        label="Выберите номер триместра из списка"
        name="numberOfTrimester"
        rules={[{ required: true, message: "Выберите номер триместра из списка" }]}
      >
        <Select>
          <Option value={1}>1</Option>
          <Option value={2}>2</Option>
          <Option value={3}>3</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Введите год обучения"
        name="year"
        rules={[{ required: true, message: "Введите год обучения" }]}
      >
        <Select>
          <Option value={1}>1</Option>
          <Option value={2}>2</Option>
          <Option value={3}>3</Option>
          <Option value={4}>4</Option>
          <Option value={5}>5</Option>
          <Option value={6}>6</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Выберите цикл"
        name="cycleId"
        rules={[{ required: true, message: "Выберите цикл" }]}
      >
        <Select>
          {cycles.map((cycle) => (
            <Option value={cycle.id}>{cycle.cycleAbbreviationRu} - {cycle.cycleTitleRu}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Выберите компонент"
        name="componentId"
        rules={[{ required: true, message: "Выберите компонент" }]}
      >
        <Select>
          {components.map((component) => (
            <Option value={component.id}>{component.componentAbbreviationRu} - {component.componentTitleRu}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
};

export default GenericForm;