import { Spin } from 'antd';
import { useState, ReactNode, useEffect } from 'react';
import { StudentGroupsDtoResponse } from '../../../interfaces/Student/StudentGroupsDtoResponse';
import { TeachersListDtoResponse } from '../../../interfaces/TeachersListDtoResponse';
import { VedomostiService } from '../../../services/VedomostiService';
import Table2 from '../Table2';
import { CourseSelectResponse } from '../../../interfaces/CourseSelectResponse';
import { EducationalProgramsDtoResponse } from '../../../interfaces/EducationalPrograms/EducationalProgramsDtoResponse';
import { DisciplineDtoResponse } from '../../../interfaces/DisciplineDtoResponse';
import { TranscriptService } from '../../../services/TranscriptService';
import { errorNotification } from '../../../helpers/errorNotification';

const useAcademicDep = () => {
  const renameTeacherObjects = (data: any) => {
    return data.map((item: any) => {
      return {
        id: item.id,
        name: item.nameKz + ' ' + item.surnameKz,
      };
    });
  };

  const renameGroupObjects = (data: any) => {
    return data.map((item: any) => {
      return {
        id: item.id,
        title: item.title,
      };
    });
  };

  // const renameDisciplineObjects = (data: any) => {
  //   return data.map((item: any) => {
  //     return {
  //       id: item.id,
  //       discipline: item.code + ' - ' + item.titleEn,
  //     };
  //   });
  // };

  // Хуки для вывода нужных фильтров
  const [selectedFiltersOption, setSelectedFiltersOption] = useState<number | undefined>();
  const [tableLoading, setTableLoading] = useState(false);

  // Хуки для сохранения данных  фильтров из ендпоинта

  const [tutorList, setTutorList] = useState<TeachersListDtoResponse[]>([]);
  const [disciplines, setDisciplines] = useState<DisciplineDtoResponse[]>([]);
  const [studentGroups, setStudentGroups] = useState<StudentGroupsDtoResponse[]>([]);
  const [studentGroupsForFilter2, setStudentGroupsForFilter2] = useState<StudentGroupsDtoResponse[]>([]);
  const [courseOptions, setCourseOptions] = useState<CourseSelectResponse[]>([]);
  const [educationalProgramsOptions, setEducationalProgramsOptions] = useState<EducationalProgramsDtoResponse[]>([]);

  // Хуки для обработки выбора фильтров
  const [selectedEducationalDegree, setSelectedEducationalDegree] = useState<number | undefined>(undefined);
  const [selectedCourse, setSelectedCourse] = useState<number | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined);
  const [selectedTrimester, setSelectedTrimester] = useState<number | undefined>(undefined);
  const [selectedEducationalProgram, setSelectedEducationalProgram] = useState<number | undefined>(undefined);
  const [searchQueryTutor, setSearchQueryTutor] = useState<number | undefined>(undefined);
  const [searchQueryDiscipline, setSearchQueryDiscipline] = useState<number | undefined>(undefined);
  const [searchQueryGroup, setSearchQueryGroup] = useState<number | undefined>(undefined);
  const [selectedStudentGroup, setSelectedStudentGroup] = useState<number | undefined>(undefined);

  // Хуки для обработки и вывода данных

  const [loadTable, setLoadTable] = useState<boolean>(false);
  const [vedomostTable, setVedomostTable] = useState<ReactNode>();
  const [rerender, setRerender] = useState(false);

  // // При ре-рендере страницы будем доставать последние настройки фильтров и сеттить их в нужные состояния. Таким образом я сделал persistent state.
  // // В некст билде лучше вытащить все состояния в отдельный компонент редаксом и там уже сделать их persistent
  // useEffect(() => {
  //   const selectedYear = sessionStorage.getItem('enrolledYear');
  //   const selectedTrimester = sessionStorage.getItem('trimester');
  //   const selectedFiltersOption = sessionStorage.getItem('option');
  //   const searchQueryTutor = sessionStorage.getItem('tutor');
  //   const searchQueryDiscipline = sessionStorage.getItem('discipline');
  //   const selectedStudentGroup = sessionStorage.getItem('StudentGroup');

  //   if (selectedYear) {
  //     try {
  //       setSelectedYear(JSON.parse(selectedYear));
  //     } catch (error) {
  //     }
  //   }
  //   if (selectedTrimester) {
  //     try {
  //       setSelectedTrimester(JSON.parse(selectedTrimester));
  //     } catch (error) {
  //     }
  //   }
  //   if (selectedFiltersOption) {
  //     try {
  //       setSelectedFiltersOption(JSON.parse(selectedFiltersOption));
  //     } catch (error) {
  //     }
  //   }
  //   if (searchQueryDiscipline) {
  //     VedomostiService.getTeachersByDiscipline(+searchQueryDiscipline).then(({ data }) => {
  //       // @ts-ignore
  //       setTutorList(renameTeacherObjects(data));
  //     });
  //     try {
  //       setSearchQueryDiscipline(JSON.parse(searchQueryDiscipline));
  //     } catch (error) {
  //     }
  //   }
  //   if (searchQueryTutor) {
  //     try {
  //       setSearchQueryTutor(JSON.parse(searchQueryTutor));
  //     } catch (error) {
  //     }
  //   }
  //   if (searchQueryTutor && searchQueryDiscipline) {
  //     // @ts-ignore
  //     VedomostiService.getGroupsByTeacherAndDiscipline(
  //       parseInt(searchQueryDiscipline),
  //       parseInt(searchQueryTutor)
  //     ).then(({ data }) => {
  //       setStudentGroupsForFilter2(renameGroupObjects(data));
  //     });
  //   }
  //   if (selectedStudentGroup) {
  //     try {
  //       setSelectedStudentGroup(JSON.parse(selectedStudentGroup));
  //     } catch (error) {
  //     }
  //   }

  //   VedomostiService.getAllDisciplines().then(({ data }) => {
  //     setDisciplines(renameDisciplineObjects(data));
  //   });
  // }, []);

  // // Здесь мы постоянно отслеживаем наши состояния и закидываем их в sessionStorage
  // useEffect(() => {
  //   sessionStorage.setItem('option', JSON.stringify(selectedFiltersOption));
  //   if (selectedFiltersOption === 1) {
  //     sessionStorage.setItem('tutor', JSON.stringify(searchQueryTutor));
  //     sessionStorage.setItem('discipline', JSON.stringify(searchQueryDiscipline));
  //     sessionStorage.removeItem('StudentGroup');
  //     sessionStorage.removeItem('enrolledYear');
  //     sessionStorage.removeItem('trimester');
  //   } else if (selectedFiltersOption === 2) {
  //     sessionStorage.setItem('StudentGroup', JSON.stringify(selectedStudentGroup));
  //     sessionStorage.setItem('enrolledYear', JSON.stringify(selectedYear));
  //     sessionStorage.setItem('trimester', JSON.stringify(selectedTrimester));
  //     sessionStorage.removeItem('tutor');
  //     sessionStorage.removeItem('discipline');
  //   }
  // });

  useEffect(() => {
    if (selectedFiltersOption === 2) {
      TranscriptService.getAllStudentsGroup().then(({ data }) => {
        data = data.map((item) => {
          return {
            id: item.id,
            title: item.title,
          };
        });
        setStudentGroups(data);
      });
    }
  }, [selectedFiltersOption]);

  const exportData = () => {
    if (selectedFiltersOption === 2) {
      if (selectedStudentGroup !== undefined && selectedTrimester !== undefined && selectedYear !== undefined) {
        VedomostiService.exportByTermAndGroup(selectedStudentGroup, selectedTrimester, selectedYear);
      } else {
        errorNotification('Select all entities', 'Please select all entitties');
      }
    }
    if (selectedFiltersOption === 1) {
      VedomostiService.exportByFirstVedomost({
        year: selectedYear,
        term: selectedTrimester,
        groupId: searchQueryGroup,
        disciplineId: searchQueryDiscipline,
        teacherId: searchQueryTutor,
      });
    }
  };

  const exportEnglish = () => {
    if (selectedFiltersOption === 1) {
      VedomostiService.exportByFirstVedomostEn({
        year: selectedYear,
        term: selectedTrimester,
        groupId: searchQueryGroup,
        disciplineId: searchQueryDiscipline,
        teacherId: searchQueryTutor,
      });
    }
  };

  const loadData = () => {
    setTableLoading(true);
    if (selectedFiltersOption === 1) {
      VedomostiService.getVedomostForOffice({
        type: selectedFiltersOption,
        year: selectedYear,
        term: selectedTrimester,
        groupId: searchQueryGroup,
        disciplineId: searchQueryDiscipline,
        teacherId: searchQueryTutor,
      })
        .then(({ data }) => {
          setLoadTable(true);
          setTableLoading(false);
          // @ts-ignore
          setVedomostTable(
            <Table2
              key={Math.random()}
              vedomostData={data}
              shouldRerender={() => {
                setRerender(!rerender);
              }}
            />
          );
        });
    } else if (selectedFiltersOption === 2) {
      VedomostiService.getVedomostForOffice({
        type: selectedFiltersOption,
        year: selectedYear,
        course: selectedCourse,
        term: selectedTrimester,
        groupId: selectedStudentGroup,
      })
        .then(({ data }) => {
          setLoadTable(true);
          setTableLoading(false);
          // @ts-ignore
          setVedomostTable(
            <Table2
              key={Math.random()}
              vedomostData={data}
              shouldRerender={() => {
                setRerender(!rerender);
              }}
            />
          );
        })
        .catch((error) => {
          setTableLoading(false);
        });
    }
  };

  const onSearchQueryDisciplineChange = (disciplineId: number) => {
    if (disciplineId !== searchQueryDiscipline) {
      VedomostiService.getTeachersByFilter(selectedYear, selectedTrimester, searchQueryGroup, disciplineId).then(
        ({ data }) => {
          setSearchQueryDiscipline(disciplineId);
          setTutorList(renameTeacherObjects(data));
        }
      );
      setSearchQueryTutor(undefined);
    }
  };

  const onSearchQueryTutorChange = (tutorId: number) => {
    if (tutorId !== searchQueryDiscipline && searchQueryDiscipline) {
      // @ts-ignore
      VedomostiService.getGroupsByTeacherAndDiscipline(searchQueryDiscipline, tutorId).then(({ data }) => {
        setSearchQueryTutor(tutorId);
        setStudentGroupsForFilter2(renameGroupObjects(data));
      });
    }
  };

  let table: ReactNode = loadTable ? vedomostTable : <></>;
  table = tableLoading ? <Spin /> : table;

  const handleSelectDegree = (value: number) => {
    setSelectedEducationalDegree(value);
    if (value === 2)
      setCourseOptions([
        { id: 1, year: 1 },
        { id: 2, year: 2 },
      ]);
    else
      setCourseOptions([
        { id: 1, year: 1 },
        { id: 2, year: 2 },
        { id: 3, year: 3 },
      ]);
    setSelectedCourse(undefined);
    setSelectedEducationalProgram(undefined);
    setSearchQueryGroup(undefined);
    setSelectedYear(undefined);
    setSelectedTrimester(undefined);
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleCourseSelect = (value: number) => {
    setSelectedCourse(value);
    VedomostiService.getEducationalProgramsByCourseAndDegree(value, selectedEducationalDegree)
      .then(({ data }) => setEducationalProgramsOptions(data));
    setSelectedEducationalProgram(undefined);
    setSearchQueryGroup(undefined);
    setSelectedYear(undefined);
    setSelectedTrimester(undefined);
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleEducationalProgramSelect = (value: number) => {
    setSelectedEducationalProgram(value);
    VedomostiService.getGroupsByCourseAndEducationalProgram(selectedCourse, value)
      .then(({ data }) => setStudentGroups(data));
    setSearchQueryGroup(undefined);
    setSelectedYear(undefined);
    setSelectedTrimester(undefined);
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleGroupSelect = (value: number) => {
    setSearchQueryGroup(value);
    setSelectedYear(undefined);
    setSelectedTrimester(undefined);
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleYearSelect = (value: number) => {
    setSelectedYear(2019 + value);
    setSelectedTrimester(undefined);
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleSelectTrim = (value: number) => {
    setSelectedTrimester(value);
    VedomostiService.getDisciplinesByFilter(selectedYear, value, searchQueryGroup)
      .then(({ data }) => setDisciplines(data));
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleClearSelectOption = () => {
    setSelectedFiltersOption(undefined);
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
    setSearchQueryGroup(undefined);
    setSelectedYear(undefined);
    setSelectedTrimester(undefined);
    setSelectedStudentGroup(undefined);
  };

  const handleClearDegree = () => {
    setSelectedEducationalDegree(undefined);
    setSelectedCourse(undefined);
    setSelectedEducationalProgram(undefined);
    setSearchQueryGroup(undefined);
    setSelectedYear(undefined);
    setSelectedTrimester(undefined);
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleClearCourse = () => {
    setSelectedCourse(undefined);
    setSelectedEducationalProgram(undefined);
    setSearchQueryGroup(undefined);
    setSelectedYear(undefined);
    setSelectedTrimester(undefined);
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleClearEducationalProgram = () => {
    setSelectedEducationalProgram(undefined);
    setSearchQueryGroup(undefined);
    setSelectedYear(undefined);
    setSelectedTrimester(undefined);
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleClearQueryGroup = () => {
    setSearchQueryGroup(undefined);
    setSelectedYear(undefined);
    setSelectedTrimester(undefined);
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleClearYear = () => {
    setSelectedYear(undefined);
    setSelectedTrimester(undefined);
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleClearTrim = () => {
    setSelectedTrimester(undefined);
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleClearDiscipline = () => {
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
  };

  const handleClearTutor = () => {
    setSearchQueryTutor(undefined);
  };

  const handleClearDisciplines = () => {
    setSearchQueryDiscipline(undefined);
    setSearchQueryTutor(undefined);
    setSearchQueryGroup(undefined);
  };

  const handleClearSearch = () => {
    setSearchQueryTutor(undefined);
    setSearchQueryGroup(undefined);
  };

  const handleClearGroup = () => {
    setSearchQueryGroup(undefined);
    setSelectedStudentGroup(undefined);
  };

  const handleClearEnrollYear = () => {
    setSelectedYear(undefined);
    setSelectedTrimester(undefined);
    setSelectedStudentGroup(undefined);
  };

  const handleClearTrimester = () => {
    setSelectedTrimester(undefined);
    setSelectedStudentGroup(undefined);
  };

  return {
    handleClear: {
      handleClearSelectOption,
      handleClearDisciplines,
      handleClearDegree,
      handleClearSearch,
      handleClearGroup,
      handleClearEnrollYear,
      handleClearTrimester,
      handleClearCourse,
      handleClearEducationalProgram,
      handleClearQueryGroup,
      handleClearYear,
      handleClearTrim,
      handleClearDiscipline,
      handleClearTutor,
    },
    handleSelect: {
      handleSelectDegree,
      handleCourseSelect,
      handleEducationalProgramSelect,
      handleGroupSelect,
      handleSelectTrim,
      handleYearSelect,
    },
    selectedEducationalProgram,
    selectedCourse,
    selectedEducationalDegree,
    setSelectedEducationalDegree,
    selectedFiltersOption,
    setSelectedFiltersOption,
    tableLoading,
    setTableLoading,
    tutorList,
    setTutorList,
    disciplines,
    setDisciplines,
    studentGroups,
    courseOptions,
    setStudentGroups,
    studentGroupsForFilter2,
    setStudentGroupsForFilter2,
    selectedYear,
    setSelectedYear,
    selectedTrimester,
    setSelectedTrimester,
    searchQueryTutor,
    setSearchQueryTutor,
    searchQueryDiscipline,
    setSearchQueryDiscipline,
    searchQueryGroup,
    setSearchQueryGroup,
    selectedStudentGroup,
    setSelectedStudentGroup,
    loadTable,
    setLoadTable,
    vedomostTable,
    setVedomostTable,
    rerender,
    setRerender,
    exportData,
    exportEnglish,
    onSearchQueryDisciplineChange,
    onSearchQueryTutorChange,
    loadData,
    table,
    educationalProgramsOptions,
  };
};

export default useAcademicDep;
