import React from 'react'
import UniversalTable from '../UniversalTeachersTable';
import { TeacherScholarInformationDtoResponse } from '../../../../interfaces/Teacher/TeacherScholarInformationDtoResponse';
import { TeacherScholarInformationDtoRequest } from '../../../../interfaces/Teacher/TeacherScholarInformationDtoRequest';
import { Field } from '../../../../interfaces/Field';
import useFetchData from '../../../../hooks/useFetchData';
import { TeacherService } from '../../../../services/TeacherService';
import { TeacherStatusDtoResponse } from '../../../../interfaces/Teacher/TeacherStatusDtoResponse';
import { successNotification } from '../../../../helpers/successNotification';

interface TeacherScholarInformationTableProps {
    isPublic: boolean;
}

const TeacherScholarInformationTable = ({ isPublic }: TeacherScholarInformationTableProps) => {
    const { data: scholarInformations, fetchData: fetchScholarInformations } = useFetchData<TeacherScholarInformationDtoResponse[]>(TeacherService.getScholarInformationList, true);
    const { data: typesList } = useFetchData<TeacherStatusDtoResponse[]>(TeacherService.getPlatformTypesList, true);


    const scholarFields: Field[] = [
        { name: 'platformId', label: 'Platform ID', type: 'text' },
        { name: 'hindex', label: 'H-index', type: 'text' },
        { name: 'url', label: 'URL', type: 'text' },
        {
            name: 'platformTypeId', label: 'Platform Type', type: 'select', options: typesList?.map((type) => ({
                value: type.id,
                label: type.name,
            })) || [],
        },
    ];

    const addRecord = (record: TeacherScholarInformationDtoRequest) => {
        TeacherService.createOrUpdateScholarInformation(record)
            .then(() => fetchScholarInformations())
            .finally(() => successNotification('Scholar information added!'));
    };

    const editRecord = (record: TeacherScholarInformationDtoRequest) => {
        TeacherService.createOrUpdateScholarInformation(record)
            .then(() => fetchScholarInformations())
            .finally(() => successNotification('Scholar information updated!'));
    };

    const deleteRecord = (record: TeacherScholarInformationDtoRequest) => {
        TeacherService.deleteTeacherScholarInformation(record.id)
            .then(() => fetchScholarInformations())
            .finally(() => successNotification('Scholar information deleted!'));
    };

    return (
        <UniversalTable
            columns={[
                {
                    title: "#",
                    dataIndex: "key",
                    key: "key"
                },
                {
                    title: "Scientific work ID",
                    dataIndex: "platformId",
                    key: "platformId",
                },
                {
                    title: "Scientific work H-index",
                    dataIndex: "hindex",
                    key: "hindex",
                },
                {
                    title: "Scientific work url",
                    dataIndex: "url",
                    key: "url",
                    render: (record: any) => {
                        return record && (
                            <a href={record} target={"_blank"} rel="noreferrer">Link</a>
                        )
                    },

                },
                {
                    title: "Platform Type",
                    dataIndex: "platformType",
                    key: "platformType",
                },
            ]}
            dataSource={scholarInformations ? scholarInformations.map((scholarInformation, i) => ({
                id: scholarInformation.id,
                key: i + 1,
                platformId: scholarInformation.platformId,
                hindex: scholarInformation.hindex,
                url: scholarInformation.url,
                platformType: scholarInformation.platformType.name,
                platformTypeId: scholarInformation.platformType.id,
            })) : []}
            addRecord={(e: TeacherScholarInformationDtoRequest) => addRecord(e)}
            editRecord={(e: TeacherScholarInformationDtoRequest) => editRecord(e)}
            deleteRecord={(e: TeacherScholarInformationDtoRequest) => deleteRecord(e)}
            fields={scholarFields}
        />)
}

export default TeacherScholarInformationTable