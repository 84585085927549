import UniversalTable from '../UniversalTeachersTable';
import useFetchData from '../../../../hooks/useFetchData';
import { CenterTrainingDirection, TeacherTrainingDirectionDtoRequest, TeacherTrainingDirectionDtoResponse } from '../../../../interfaces/Teacher/EPVO/TeacherEpvoDto';
import { TeacherService } from '../../../../services/TeacherService';
import { successNotification } from '../../../../helpers/successNotification';
import grantPermission from '../../../../helpers/grantPermission';
import { Field } from '../../../../interfaces/Field';
import { useCallback } from 'react';

interface Props {
    userId?: number;
}

const TeacherTrainingTable = ({ userId }: Props) => {
    const callbackICInfo = useCallback(() => {
        if (userId) {
            return TeacherService.getTeacherTrainingDirectionsByUser(userId);
        } else {
            return TeacherService.getTeacherTrainingDirections();
        }
    }, [userId])


    const { data: centerTrainingDirections } = useFetchData<CenterTrainingDirection[]>(TeacherService.getCenterTrainingDirections, true);
    const { data: trainings, fetchData: fetchTrainings } = useFetchData<TeacherTrainingDirectionDtoResponse[]>(callbackICInfo, true);

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Number',
            dataIndex: 'number',
            key: 'number',
        }
    ];

    const trainingFields: Field[] = [
        {
            name: 'centerTrainingDirectionId', label: 'Center training direction', placeholder: "Select training direction", type: "select",
            options: centerTrainingDirections?.map((data) => (
                {
                    value: data.id,
                    label: data.nameEn + "/" + data.nameRu + "/" + data.nameKz
                }))
        }
    ];

    const addRecord = (record: TeacherTrainingDirectionDtoRequest) => {
        if (userId) {
            record.userId = userId;
        }
        TeacherService.createOrUpdateTeacherTrainingDirection(record)
            .then(() => {
                fetchTrainings();
                successNotification('Training added!')
            })
    };

    const editRecord = (record: TeacherTrainingDirectionDtoRequest) => {
        if (userId) {
            record.userId = userId;
        }
        TeacherService.createOrUpdateTeacherTrainingDirection(record)
            .then(() => {
                fetchTrainings();
                successNotification('Training updated!')
            })
    };

    const deleteRecord = (record: TeacherTrainingDirectionDtoRequest) => {
        TeacherService.deleteTeacherTrainingDirection(record.id)
            .then(() => {
                fetchTrainings();
                successNotification('Training deleted!')
            })
    };

    return (
        <UniversalTable
            columns={columns}
            dataSource={trainings ? trainings.map((training, i) => ({
                id: training.id,
                key: i + 1,
                name: training.centerTrainingDirection.nameEn + "/" + training.centerTrainingDirection.nameRu + "/" + training.centerTrainingDirection.nameKz,
                number: training.centerTrainingDirection.codeDirection,
            })) : []}
            addRecord={(e: TeacherTrainingDirectionDtoRequest) => addRecord(e)}
            editRecord={(e: TeacherTrainingDirectionDtoRequest) => editRecord(e)}
            deleteRecord={(e: TeacherTrainingDirectionDtoRequest) => deleteRecord(e)}
            hideActions={grantPermission('HR', 'addRole') ? false : true}
            hideCreate={grantPermission('HR', 'addRole') ? false : true}
            hideEdit={true}
            fields={trainingFields}
            isPublic={false}
        />
    )
}

export default TeacherTrainingTable