import { CommissionMembersDtoResponse } from '../../../../interfaces/CommissionMembersDtoResponse';
import { Select } from 'antd';

type Options = CommissionMembersDtoResponse[];

const { Option } = Select;

interface Props {
  placeholder: string;
  selectedOption: number | undefined;
  options: Options;
  onSelect: (value: number) => void;
  onChange: (value: number) => void;
}

const CommissionMemberFilter = ({ placeholder, options, onSelect, selectedOption, onChange }: Props) => {
  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      value={selectedOption}
      onSelect={onSelect}
      style={{ width: 600 }}
    >
      {options.map((option) => (
        <Option key={option.id} value={option.id}>
          {option.surnameKz == null && `${option.nameKz} `} {option.patronymicKz == null && ``}
          {option.surnameKz != null && `${option.surnameKz} ${option.nameKz} `}{' '}
          {option.patronymicKz != null && `${option.patronymicKz}`}
        </Option>
      ))}
    </Select>
  );
};

export default CommissionMemberFilter;
