import { Row, Col, Typography, Calendar, Card, Collapse } from 'antd';
import ContactsList from './ContactsList';
import PageWrapper from '../../ui/PageWrapper';
import StudentSchedulePage from './StudentSchedulePage/StudentSchedulePage';
import TeacherSchedulePage from './StudentSchedulePage/TeacherSchedulePage';
import UserService from '../../services/userService';
import { useRef, } from 'react';
import checkRoleListContains from '../../helpers/checkRoleListContains';

const UserPage = () => {
  // const agreeWithUserAgreement = useRef(UserService.getCurrentUser());
  // const { confirm } = Modal;
  // useEffect(() => {
  //   console.log(agreeWithUserAgreement.current.agreeWithUserAgreement)
  //   if (agreeWithUserAgreement.current.agreeWithUserAgreement == false) {
  //     confirm({
  //       title: 'Do you agree with User agreement?',
  //       icon: <InfoCircleOutlined style={{ color: '#08c' }} />,
  //       content: 'User agreement',
  //       okText: 'Yes',
  //       cancelText: 'No',
  //       onOk() {
  //         UserService.addUserAgreement(true)
  //           .then(() => {
  //             successNotification('You agree With User Agreement!')

  //           });
  //       },
  //       onCancel() {
  //         UserService.logOut()
  //       },
  //     });
  //   }
  // }, []);
  const data = [
    {
      title: 'DU Tech Support (technical questions about du, moodle, abitur)',
      mail: ['support_du@astanait.edu.kz'],
    },
    {
      title: 'Tech Support (other technical questions)',
      mail: ['helpdesk@astanait.edu.kz'],
    },
    {
      title: 'Military department',
      mail: ['alexei.peresipkin@astanait.edu.kz'],
      phone: ['+7 (701) 412-87-58'],
      room: ['C1.3.336'],
    },
    {
      title: 'Request for Transcript',
      mail: ['transcript@astanait.edu.kz'],
    },
    {
      title: 'Медпункт ',
      phone: ['8 (7172) 64-57-33'],
    },
    {
      title: "Department of the students' affairs (Clubs, Volunteers, Dormitory, Lockers) ",
      mail: ['arman.kenzhebekov@astanait.edu.kz'],
      room: ['C1.1.323'],
    },
    {
      title: 'Change password (Moodle, Microsoft products), SEB configurations',
      mail: ['helpdesk@astanait.edu.kz'],
      room: ['C1.2.255'],
    },
    {
      title: "Head of the Registrar's office (FX, Retake, Schedule)",
      mail: ['office.reg@astanait.edu.kz'],
      room: ['C1.1.270'],
    },
    {
      title: 'Head of the Students Department',
      mail: ['g.zhussupova@astanait.edu.kz'],
      room: ['C1.1.273'],
    },
    {
      title: 'Deans office',
      mail: ['deans_office@astanait.edu.kz'],
      room: ['C1.1.320'],
    },
    {
      title: 'Muslim Sergaziyev - Department of Computational and Data Science',
      room: ['C1.1.321'],
    },
    {
      title: 'Praveen Kumar- Computer Engineering Department',
      room: ['C1.3.359'],
    },
    {
      title: 'Zhibek Tleshova- Department of General Educational Disciplines',
      room: ['C1.1.263'],
    },
    {
      title: 'Leila Rzayeva - Department of Intelligent Systems and Cybersecurity',
      room: ['C1.1.330'],
    },
    {
      title: 'Ha Jin Hwang - School of Creative Industries',
      room: ['C1.3.353'],
    },
  ];


  const role = useRef(UserService.getCurrentUser());

  // const shouldShowStudentSchedule = grantPermission(role.current.baseRole, 'someType'); 

  const renderSchedule = () => {
    const roleList = role.current.roleList;

    switch (true) {
      case checkRoleListContains(roleList, ['STUDENTS']):
        return <StudentSchedulePage />;
      case checkRoleListContains(roleList, ['TEACHERS']):
        return <TeacherSchedulePage />;
      case checkRoleListContains(roleList, ['TEACHERS']) && checkRoleListContains(roleList, ['OFFICE']):
        return <Calendar />;
      default:
        return <Calendar />;
    }
  }


  return (
    <Row>
      <Col xl={18} xs={24}>
        <PageWrapper>
          {/* <Typography.Title level={3}>Announcements</Typography.Title>
          <NewsCarousel /> 
          <Typography.Title level={3}>Calendar</Typography.Title> */}
          {renderSchedule()}
        </PageWrapper>
      </Col>
      <Col xl={6} xs={24}>
        <Card
          style={{
            height: '100%',
            minHeight: 360,
          }}
        >
          <Collapse expandIconPosition="end" bordered={false} defaultActiveKey={1}>
            <Collapse.Panel
              header={<Typography.Title level={3}>Contacts</Typography.Title>}
              key="1"
              style={{ borderBottom: 'none', borderRadius: 15 }}
            >
              <ContactsList list={data} />
            </Collapse.Panel>
          </Collapse>
          {/* <AuthenticatedContent role={'STUDENTS'}>
            <Typography.Title level={3} style={{ marginTop: '20px' }}>
              Attendance
            </Typography.Title>
            <Row gutter={16}>
              <AttendanceBox />
            </Row>
            <Typography.Title level={3} style={{ marginTop: '20px' }}>
              Book a Psychologist
            </Typography.Title>
            <BookingModal />
          </AuthenticatedContent> */}
          {/* <AuthenticatedContent role={'TEACHERS'}>
            <Typography.Title level={3}>Courses</Typography.Title>
            <Row gutter={16}>
              <CoursesBox></CoursesBox>
            </Row> 
          </AuthenticatedContent> */}
        </Card>
      </Col>
    </Row>
  );
};

export default UserPage;
