import { DeleteOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Modal, Popconfirm, Space, Table, Tag, Upload, UploadProps } from 'antd';
import { useState } from 'react';
import { IrosService } from '../../../services/student/IrosService';
import { successNotification } from '../../../helpers/successNotification';
import { RcFile } from 'antd/lib/upload';
import { errorNotification } from '../../../helpers/errorNotification';

interface TableProps {
  studentsData: FilteredData[];
  loading: boolean;
  selectedDisciplineId: number | null;
  onSubmit: () => void;
}

interface FilteredData {
  id: number;
  studentId: number;
  name: string;
  group: string;
  grade?: any;
}

const IrosTeachersTable = ({ studentsData, loading, selectedDisciplineId, onSubmit }: TableProps) => {
  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Группа',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'Оценка',
      dataIndex: 'grade',
      key: 'grade',
      render: (_: any, record: any) => record.grade ?? <Tag color="error">Отсутствует</Tag>,
    },
    {
      title: 'Действие',
      dataIndex: 'operation',
      render: (_: any, record: any) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={() => openModalForGrading(record)}>
            Оценить
          </Button>
          {record.grade && (
            <Popconfirm
              title="Вы уверены что хотите удалить?"
              onConfirm={() => { handleDeleteIrosApplication(record.id) }}
              okText="Да"
              cancelText="Нет"
            >
              <Button danger icon={<DeleteOutlined />}>
                Удалить
              </Button>
            </Popconfirm>
          )}
        </Space>

      ),
    },
  ];
  const [isMarkModalOpen, setIsMarkModalOpen] = useState<boolean>(false);
  const [selectedStudent, setSelectedStudent] = useState<FilteredData | null>(null);
  const [irosFile, setIrosFile] = useState<any>();

  const uploadProps: UploadProps = {
    maxCount: 1,
    beforeUpload: (file: RcFile) => {
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        errorNotification(`${file.name} is not pdf file!`, 'Please, upload only pdf file!');
      }
      return isPDF || Upload.LIST_IGNORE;
    },
    onChange(e) {
      e.file.status = 'done';
    },
    customRequest(info) {
      setIrosFile(info.file);
    },
  };

  const handleDeleteIrosApplication = (id: number) => {
    IrosService.deleteStudentApplication(id)
      .then(() => successNotification('Успешно удалено!'))
      .finally(() => onSubmit());
  }


  const onFinish = (values: any) => {
    const confirmation: any = {
      id: selectedStudent?.id,
      studentId: selectedStudent!.studentId,
      points: values.grade,
      disciplineId: selectedDisciplineId!,
    };
    let formData = new FormData();
    formData.append('irosFile', irosFile);
    IrosService.uploadFile(formData)
      .then(({ data }) => {
        confirmation.fileId = data;
        IrosService.gradeStudentApplication(confirmation)
          .then(() => successNotification('Оценка добавлена!'));
      })
      .finally(() => {
        closeModalForGrading();
        onSubmit();
      });
  };

  const openModalForGrading = (studentFields: any) => {
    setIsMarkModalOpen(true);
    setSelectedStudent(studentFields);
  };

  const closeModalForGrading = () => {
    setIsMarkModalOpen(false);
    setSelectedStudent(null);
  };

  return (
    <>
      <Table columns={columns} dataSource={studentsData} loading={loading} />
      {isMarkModalOpen && (
        <Modal title="Выставить оценку" open={isMarkModalOpen} onCancel={() => closeModalForGrading()} footer={null}>
          <Form onFinish={onFinish} layout="vertical" autoComplete="off">
            <Form.Item label="Оценка" name="grade" rules={[{ required: true, message: 'Введите число!' }]}>
              <InputNumber min={0} max={100} />
            </Form.Item>
            <Form.Item
              label="Подтверждающий документ"
              name={'fileId'}
              rules={[{ required: true, message: 'Загрузите подтверждающий документ!' }]}
            >
              <Upload {...uploadProps} accept={'.pdf'} maxCount={1}>
                <Button icon={<UploadOutlined />}>Upload file</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default IrosTeachersTable;
