import React from 'react'
import Page from '../../../../ui/Page'
import { Table } from 'antd'
import useTeacherDiplomaApprove from './useTeacherDiplomaApprove'

const TeacherDiplomaApprove = () => {
    const {
        groupedByThesis,
        isGraduationThesisLoading,
        columns,
        expandedRowRender,
        contextHolder
    } = useTeacherDiplomaApprove();

    const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);

    const handleExpand = (expanded: boolean, record: any) => {
        setExpandedRowKeys(expanded ? [record.thesis.id] : []);
    };

    return (
        <Page>
            <Table
                bordered
                dataSource={groupedByThesis.sort((a, b) => a.thesis.id - b.thesis.id)}
                columns={columns}
                loading={isGraduationThesisLoading}
                rowKey={(record) => record.thesis.id}
                expandable={{
                    expandedRowRender: record => expandedRowRender(record.students),
                    rowExpandable: record => record.students.length > 0,
                    onExpand: handleExpand,
                    expandedRowKeys: expandedRowKeys,
                }}
                scroll={{ x: 800 }}
                pagination={{
                    pageSize: 20,
                }}
            />
            {contextHolder}
        </Page>
    )
}

export default TeacherDiplomaApprove