import {
  Button,
  Col,
  InputRef,
  List,
  notification,
  Popconfirm,
  Row,
  Timeline,
  Typography,
  Modal,
  Divider,
  Table,
} from 'antd';
import { useEffect, useRef, useState, useMemo } from 'react';
import { getColumnSearchProps } from '../../../common/getColumnSearchProps';
import { errorNotification } from '../../../helpers/errorNotification';
import { AbiturCatalogDtoResponse } from '../../../interfaces/AbiturCatalogDtoResponse';
import { BreakDownByHourDtoResponse } from '../../../interfaces/EducationalPrograms/BreakDownByHourDtoResponse';
import { StudentDisciplineFinalChooseDtoResponse } from '../../../interfaces/Student/StudentDisciplineFinalChooseDtoResponse';
import { StudentProfileDtoResponse } from '../../../interfaces/Student/StudentProfileDtoResponse';
import { CurriculumService } from '../../../services/CurriculumService';
import { FileService } from '../../../services/file/FileService';
import { StudentService } from '../../../services/StudentService';
import UserService from '../../../services/userService';
import { CurriculumDtoResponse } from '../../../interfaces/EducationalPrograms/CurriculumDtoResponse';
import grantPermission from '../../../helpers/grantPermission';
import { successNotification } from '../../../helpers/successNotification';

const useProfilePage = (userId?: number, curriculumOpen?: boolean) => {
  const [profileInfo, setProfileInfo] = useState<StudentProfileDtoResponse>();
  const [currentUser, setCurrentUser] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [curriculumList, setCurriculumList] = useState<CurriculumDtoResponse[]>([]);
  const [image3x4, setImage3x4] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [disciplines, setDisciplines] = useState<StudentDisciplineFinalChooseDtoResponse[]>([]);
  const [socialStatusList, setSocialStatusList] = useState<AbiturCatalogDtoResponse[]>([]);
  const [course, setCourse] = useState<number>(1);
  const [infoHidden, setInfoHidden] = useState<boolean>(localStorage.infoHidden ?? false);
  // const [filterDate, setFilterDate] = useState<FilterRow[]>([]);
  const defaultCourseSelected = 1;
  const inputRef = useRef() as React.Ref<InputRef>;

  const handleToggleInfoDisplay = () => {
    localStorage.infoHidden = JSON.stringify(!infoHidden);
    setInfoHidden((prevState) => !prevState);
  };

  const handleDownloadFile = (id: number | undefined, name: string) => {
    if (id) {
      setLoading(true);
      FileService.getFileById(id, name)
        .then(() => successNotification('Successfully downloaded!'))
        .finally(() => setLoading(false));
    } else errorNotification('Не удалось скачать');
  };

  useEffect(() => {
    setLoading(true);
    StudentService.getStudentSocialStatusList()
      .then(({ data }) => setSocialStatusList(data))
      .finally(() => setLoading(false));
    // for (let i: any = 1; i <= 3; i++) {
    //   i = i.toString();
    //   setFilterDate((oldValue) => [
    //     ...oldValue,
    //     {
    //       text: i,
    //       value: i,
    //     },
    //   ]);
    // }
  }, []);

  const columns = userId
    ? [
      {
        title: '№',
        dataIndex: 'number',
        key: 'number',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Code of discipline',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: 'Discipline Name',
        dataIndex: 'title',
        key: 'title',
        ...getColumnSearchProps('title', inputRef),
      },
      {
        title: 'Number of Credits',
        dataIndex: 'credits',
        key: 'credits',
      },
      {
        title: 'Trimester',
        dataIndex: 'numberOfTrimester',
        key: 'numberOfTrimester',
        sorter: {
          compare: (a: any, b: any) => a.numberOfTrimester - b.numberOfTrimester,
          multiple: 2,
        },
      },
      {
        title: (
          <Row>
            <Col flex={0}>Type of Lesson</Col>
            <Col flex={'auto'}></Col>
            <Col flex={0}>Hours</Col>
          </Row>
        ),
        dataIndex: 'lesson',
        key: 'lesson',
        width: 350,
      },
      {
        title: 'Action',
        key: 'delete',
        render: (record: { key: React.Key }) => (
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => deleteDiscipline(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        ),

        width: 0,
      },
    ]
    : [
      {
        title: '№',
        dataIndex: 'number',
        key: 'number',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Code of discipline',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: 'Discipline Name',
        dataIndex: 'title',
        key: 'title',
        ...getColumnSearchProps('title', inputRef),
      },
      {
        title: 'Number of Credits',
        dataIndex: 'credits',
        key: 'credits',
      },
      {
        title: 'Trimester',
        dataIndex: 'numberOfTrimester',
        key: 'numberOfTrimester',
        sorter: {
          compare: (a: any, b: any) => a.numberOfTrimester - b.numberOfTrimester,
          multiple: 2,
        },
      },
      {
        title: (
          <Row>
            <Col flex={0}>Type of Lesson</Col>
            <Col flex={'auto'}></Col>
            <Col flex={0}>Hours</Col>
          </Row>
        ),
        dataIndex: 'lesson',
        key: 'lesson',
        width: 350,
      },
    ];

  const curriculumAddColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Code of discipline',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Discipline Name',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title', inputRef),
    },
    {
      title: 'Trimester',
      dataIndex: 'numberOfTrimester',
      key: 'numberOfTrimester',
      sorter: {
        compare: (a: any, b: any) => a.numberOfTrimester - b.numberOfTrimester,
        multiple: 2,
      },
    },
    {
      title: 'Course',
      dataIndex: 'course',
      key: 'course',
    },
  ];

  interface CardContentProps {
    condition: boolean;
    headerTitle: string;
    children: JSX.Element[] | JSX.Element;
  }

  // interface FilterRow {
  //   text: string;
  //   value: number;
  // }

  interface ProfileDescriptionItemProps {
    keys: string;
    item: string | undefined;
    styles?: object;
    icon?: React.ReactNode;
    isHidden?: boolean;
  }

  // interface CurriculumList {
  //   key: number;
  //   title: string;
  //   type: string;
  //   code: string;
  //   numberOfTrimester: number;
  //   course: number;
  //   disciplineId: number;
  // }

  const deleteDiscipline = (row: any) => {
    setLoading(true);
    CurriculumService.deleteStudentDiscipline(row.disciplineId)
      .then(() => {
        notification.success({ message: 'Дисциплина удалена!' });
        if (userId && course)
          if (curriculumOpen)
            CurriculumService.getStudentCurriculumForNextYearForOffice(userId, course).then(({ data }) => {
              setDisciplines(data);
            });
      })
      .finally(() => setLoading(false));
  };

  const curriculumsData = curriculumList
    .filter((curriculum) => {
      const isNotInDisciplines =
        !disciplines.some((discipline) => discipline.curriculum.id === curriculum.id) &&
        curriculum.year.toString() === course.toString();
      return isNotInDisciplines;
    })
    .map((curriculum) => ({
      key: curriculum.id,
      title: `${curriculum.discipline?.titleEn} / ${curriculum.discipline?.titleKz} / ${curriculum.discipline?.titleRu}`,
      type: curriculum.component.componentAbbreviationEn,
      code: curriculum.discipline?.code,
      numberOfTrimester: curriculum.numberOfTrimester,
      course: curriculum.year,
      disciplineId: curriculum.discipline?.id,
    }));

  const onCourseSelect = (value: number) => {
    setCourse(value);
    setLoading(true);
    if (userId) {
      CurriculumService.getStudentCurriculumForNextYearForOffice(userId, value)
        .then(({ data }) => {
          setDisciplines(data);
        })
        .finally(() => setLoading(false));
      if (profileInfo?.student.educationProgramId) {
        CurriculumService.getCurriculumsByEducationalProgram(profileInfo?.student.educationProgramId)
          .then(({ data }) => {
            setCurriculumList(data);
          })
      }
    } else
      CurriculumService.getStudentCurriculumForNextYearForStudent(value)
        .then(({ data }) => {
          setDisciplines(data);
        })
        .finally(() => setLoading(false));
  };

  const CardContent = ({ condition, headerTitle, children }: CardContentProps) => {
    return (
      <>
        <Typography.Title level={3}>{headerTitle}</Typography.Title>
        {condition ? (
          <Timeline>
            <Timeline.Item>{children}</Timeline.Item>
          </Timeline>
        ) : (
          <Typography.Title level={5} type="secondary">
            None
          </Typography.Title>
        )}
      </>
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onOk = () => {
    if (profileInfo?.student.id) {
      const studentDisciplineFinalChoose = {
        studentId: profileInfo?.student.id,
        curriculumIds: selectedRowKeys,
      };
      setSelectedRowKeys([]);
      CurriculumService.saveDisciplineChoiceForOffice(studentDisciplineFinalChoose)
        .then(() => {
          successNotification('Successfully added!');
          if (userId && course)
            if (curriculumOpen)
              CurriculumService.getStudentCurriculumForNextYearForOffice(userId, course).then(({ data }) => {
                setDisciplines(data);
                setIsModalVisible(false);
              });
        })
    }
  };

  const handleRowSelectionChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleRowSelectionChange,
  };

  const ProfileDescriptionItem = ({ keys, item, styles, icon, isHidden = false }: ProfileDescriptionItemProps) => {
    return (
      <>
        <Typography.Text type="secondary" style={styles}>
          {icon} {keys}:{' '}
          {isHidden ? (
            <Typography.Text type="secondary">(hidden)</Typography.Text>
          ) : (
            <Typography.Text>{item}</Typography.Text>
          )}
        </Typography.Text>
      </>
    );
  };

  const CurriculumAddModal = useMemo(() => {
    return (
      <>
        <Modal
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          okText="Cохранить"
          cancelText="Отмена"
          onOk={onOk}
          width={1000}
        >
          <Typography.Title level={4}>Добавить учебный план</Typography.Title>
          <Divider />
          <Table
            rowSelection={rowSelection}
            loading={loading}
            columns={curriculumAddColumns}
            dataSource={curriculumsData}
          />
        </Modal>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible, curriculumsData, loading]);

  const hasCompleteEducation = !!profileInfo?.completedEducationInstitution && !!profileInfo.completedEducationDate;
  const hasEnrolledEducation =
    !!profileInfo?.enrolledEducationInstitutionEn ||
    !!profileInfo?.enrolledEducationInstitutionRu ||
    !!profileInfo?.enrolledEducationInstitutionKz ||
    !!profileInfo?.enrolledEducationInstitutionKz;
  const hasEntranceExam =
    !!profileInfo?.entranceExamDocumentNumber &&
    !!profileInfo?.entranceExamDocumentIssueDate &&
    !!profileInfo?.entranceExamType;
  const hasPreviousEducation =
    !!profileInfo?.previousEducationDocumentNumber &&
    !!profileInfo?.previousEducationDocumentIssueDate &&
    !!profileInfo?.previousEducationDocumentType;

  useEffect(() => {
    localStorage.infoHidden = infoHidden ?? JSON.stringify(true);
    setInfoHidden(JSON.parse(localStorage.infoHidden) ?? false);
    setLoading(true);
    if (userId) {
      if (curriculumOpen)
        CurriculumService.getStudentCurriculumForNextYearForOffice(userId, defaultCourseSelected)
          .then(({ data }) => {
            setDisciplines(data);
          })
          .finally(() => setLoading(false));

      UserService.getProfileById(userId)
        .then((res) => {
          setProfileInfo(res.data);
          res.data?.student?.image3x4 && FileService.getStudentImage(res.data?.student?.image3x4 ?? 0).then(({ data }) => {
            let imageUrl = URL.createObjectURL(data);
            setImage3x4(imageUrl);
          });

          if (res.data?.student.educationProgramId && curriculumList.length === 0) {
            CurriculumService.getCurriculumsByEducationalProgram(res.data?.student.educationProgramId)
              .then(({ data }) => {
                setCurriculumList(data);
              })
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    else if (grantPermission('STUDENTS')) {
      if (curriculumOpen)
        CurriculumService.getStudentCurriculumForNextYearForStudent(defaultCourseSelected)
          .then(({ data }) => {
            setDisciplines(data);
          })
          .finally(() => setLoading(false));
      UserService.getProfileByPrincipal()
        .then((res) => {
          setProfileInfo(res.data)
          res.data?.student?.image3x4 && FileService.getStudentImage(res.data?.student?.image3x4)
            .then(({ data }) => {
              let imageUrl = URL.createObjectURL(data);
              setImage3x4(imageUrl)
            })
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllLessonTypes = (lessons: BreakDownByHourDtoResponse[]) => {
    return (
      <List
        size="small"
        dataSource={lessons}
        renderItem={(item) => (
          <List.Item style={{ padding: '5px 0' }}>
            <List.Item.Meta description={item.lessonsTypeId.titleEn} />
            <div>{item.hours}</div>
          </List.Item>
        )}
      />
    );
  };

  const onDownloadCurriculum = (userId: number, course: number, langId: number) => {
    CurriculumService.getStudentCurriculumForNextYearPDF(userId, course, langId)
      .then(() => {
        successNotification('Successfully downloaded!');
      })
  };

  const data = disciplines
    .map((approvedDiscipline, idx) => ({
      key: idx,
      number: idx + 1,
      title: `${approvedDiscipline.curriculum.discipline?.titleEn} / ${approvedDiscipline.curriculum.discipline?.titleKz} / ${approvedDiscipline.curriculum.discipline?.titleRu}`,
      type: approvedDiscipline.curriculum.component.componentAbbreviationEn,
      code: approvedDiscipline.curriculum.discipline?.code,
      credits: approvedDiscipline.curriculum.discipline.volumeCredits,
      lesson: getAllLessonTypes(approvedDiscipline.curriculum.discipline.breakDownByHour),
      numberOfTrimester: approvedDiscipline.curriculum.numberOfTrimester,
      disciplineId: approvedDiscipline.id,
    }))
    .slice()
    .sort((a, b) => a.numberOfTrimester - b.numberOfTrimester)
    .map((item, idx) => ({
      ...item,
      number: idx + 1,
    }));

  return {
    infoHidden,
    handleToggleInfoDisplay,
    profileInfo,
    setProfileInfo,
    currentUser,
    setCurrentUser,
    loading,
    setLoading,
    CardContent,
    CurriculumAddModal,
    showModal,
    hasCompleteEducation,
    hasEnrolledEducation,
    hasEntranceExam,
    hasPreviousEducation,
    ProfileDescriptionItem,
    columns,
    data,
    onDownloadCurriculum,
    onCourseSelect,
    course,
    setCourse,
    handleDownloadFile,
    socialStatusList,
    image3x4,
  };
};
export default useProfilePage;
