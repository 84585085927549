import { SearchOutlined, TableOutlined } from '@ant-design/icons';
import { Button, InputNumber, Space, Typography } from 'antd';
import AuthenticatedContent from '../../../common/AuthenticatedContent';
import PageWrapper from '../../../ui/PageWrapper';
import StudentDebtTable from './StudentDebtTable';
import DebtTableForOffice from './DebtTableForOffice';
import DataSelect from './DataSelect';
import useStudentDebtPage from './useStudentDebtPage';

const { Title } = Typography;

const StudentDebtPage = () => {
  const {
    downloadExcelTable,
    handleSearch,
    handleEducationalDegreeSelect,
    handleCourseSelect,
    handleEducationalProgramSelect,
    handleGroupSelect,
    handleCountSelect,
    educPrograms,
    courseOptions,
    groupOptions,
    numberOfDebts,
    tableData,
    areButtonsAvailable,
    setAreButtonsAvailable,
    tableVisible,
    setTableVisible,
    selectedCourse,
    setSelectedCourse,
    selectedEducationalDegree,
    setSelectedEducationalDegree,
    selectedEducationalProgram,
    setSelectedEducationalProgram,
    selectedGroup,
    setSelectedGroup,
    loading,
    count,
  } = useStudentDebtPage();

  return (
    <PageWrapper>
      <section>
        <AuthenticatedContent role={'STUDENTS'} type={'baseRole'}>
          <header style={{ marginBottom: '20px' }}>
            <Title level={3}>Student Debts</Title>
          </header>
          <Title level={4} type="secondary">
            Number of academic debts: {numberOfDebts}
          </Title>
          <StudentDebtTable />
        </AuthenticatedContent>

        <AuthenticatedContent role={'OFFICE'} type={'baseRole'}>
          <header style={{ marginBottom: '20px' }}>
            <Title level={3}>Студенческие задолженности</Title>
          </header>
          <Space size="large" direction="vertical">
            <DataSelect
              options={[
                {
                  id: 1,
                  value: 1,
                  name: "Bachelor's degree",
                },
                {
                  id: 2,
                  value: 2,
                  name: "Master's degree",
                },
                {
                  id: 3,
                  value: 6,
                  name: "Doctorate's degree",
                },
              ]}
              value={selectedEducationalDegree}
              onClear={() => {
                setSelectedEducationalDegree(undefined);
                setSelectedCourse(undefined);
                setSelectedEducationalProgram(undefined);
                setSelectedGroup(undefined);
                setAreButtonsAvailable(false);
                setTableVisible(false);
              }}
              placeholder={'Выберите степень образования'}
              onSelect={handleEducationalDegreeSelect}
              renderItems={(item) => item.name}
            />
            <DataSelect
              options={courseOptions}
              value={selectedCourse}
              placeholder={'Выберите курс'}
              onSelect={handleCourseSelect}
              onClear={() => {
                setSelectedCourse(undefined);
                setSelectedEducationalProgram(undefined);
                setSelectedGroup(undefined);
                setTableVisible(false);
                setAreButtonsAvailable(true);
              }}
              renderItems={(item) => item.year}
            />
            <DataSelect
              options={educPrograms}
              value={selectedEducationalProgram}
              onSelect={handleEducationalProgramSelect}
              onClear={() => {
                setSelectedEducationalProgram(undefined);
                setSelectedGroup(undefined);
                setTableVisible(false);
                setAreButtonsAvailable(true);
              }}
              placeholder={'Выберите образовательную программу'}
              renderItems={(item) => `${item.titleEn} / ${item.titleRu} / ${item.titleKz}`}
            />
            <DataSelect
              options={groupOptions}
              value={selectedGroup}
              onSelect={handleGroupSelect}
              onClear={() => setSelectedGroup(undefined)}
              placeholder={'Выберите группу'}
              renderItems={(item) => item.title}
            />
            <Space direction="vertical">
              <Typography.Title level={5}>Минимальное количество задолжностей:</Typography.Title>
              <InputNumber
                min={0}
                defaultValue={0}
                value={count}
                onChange={handleCountSelect}
                style={{ marginTop: '-20px', width: '400px' }}
              />
            </Space>
          </Space>
          <br />
          <br />
          <Space size="large">
            <Button
              style={{ display: 'block', marginBottom: '20px' }}
              type="primary"
              onClick={handleSearch}
              disabled={!areButtonsAvailable}
              loading={loading}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
            {areButtonsAvailable && (
              <Button
                style={{ display: 'block', marginBottom: '20px' }}
                size="large"
                type="default"
                onClick={downloadExcelTable}
                loading={loading}
                icon={<TableOutlined />}
              >
                Export to Excel Table
              </Button>
            )}
          </Space>
          {tableVisible && <DebtTableForOffice tableData={tableData} loading={loading} />}
        </AuthenticatedContent>
      </section>
    </PageWrapper>
  );
};

export default StudentDebtPage;
