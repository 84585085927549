import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Modal, notification, Popconfirm, Space, Table } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Column from 'antd/lib/table/Column';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useState, useRef } from 'react'
import { VedomostiService } from '../../../../services/VedomostiService';

interface Props {
    vedomostData: any;
    isMultiple: boolean;
    shouldRerender: Function;
    loading: boolean;
    form: any;
}

function gradeConvert(grade: any) {
    grade = Math.floor(grade);
    let alphabeticGrade = '';
    let gpaGrade = 0;
    let traditionalGrade = '';

    switch (true) {
        case grade >= 95:
            alphabeticGrade = 'A';
            gpaGrade = 4;
            traditionalGrade = 'Excellent';
            break;
        case grade >= 90 && grade <= 94:
            alphabeticGrade = 'A-';
            gpaGrade = 3.67;
            traditionalGrade = 'Excellent';
            break;
        case grade >= 85 && grade <= 89:
            alphabeticGrade = 'B+';
            gpaGrade = 3.33;
            traditionalGrade = 'Good';
            break;
        case grade >= 80 && grade <= 84:
            alphabeticGrade = 'B';
            gpaGrade = 3;
            traditionalGrade = 'Good';
            break;
        case grade >= 75 && grade <= 79:
            alphabeticGrade = 'B-';
            gpaGrade = 2.67;
            traditionalGrade = 'Good';
            break;
        case grade >= 70 && grade <= 74:
            alphabeticGrade = 'C+';
            gpaGrade = 2.33;
            traditionalGrade = 'Good';
            break;
        case grade >= 65 && grade <= 69:
            alphabeticGrade = 'C';
            gpaGrade = 2;
            traditionalGrade = 'Satisfactory';
            break;
        case grade >= 60 && grade <= 64:
            alphabeticGrade = 'C-';
            gpaGrade = 1.67;
            traditionalGrade = 'Satisfactory';
            break;
        case grade >= 55 && grade <= 59:
            alphabeticGrade = 'D+';
            gpaGrade = 1.33;
            traditionalGrade = 'Satisfactory';
            break;
        case grade >= 50 && grade <= 54:
            alphabeticGrade = 'D';
            gpaGrade = 1;
            traditionalGrade = 'Satisfactory';
            break;
        case grade >= 30 && grade <= 49:
            alphabeticGrade = 'FX';
            gpaGrade = 0;
            traditionalGrade = 'Fail';
            break;
        case grade >= 0 && grade <= 29:
            alphabeticGrade = 'F';
            gpaGrade = 0;
            traditionalGrade = 'Fail';
            break;
    }

    return {
        alphabeticGrade,
        gpaGrade,
        traditionalGrade,
    };
}

const TeacherStatementTable = ({ vedomostData, shouldRerender, isMultiple, loading, form }: Props) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [reason, setReason] = useState<string>();
    const [studentId, setStudentId] = useState<number>(0);
    const inputRef = useRef() as React.Ref<InputRef>;

    const getColumnSearchProps = (dataIndex: string, inputRef: React.Ref<InputRef>) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={inputRef}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && clearFilters();
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) => {
            return `${record.name}`.toLowerCase().includes(value.toLowerCase());
        },
    });
    const handleSelect = (record: any, selected: any) => {
        if (selected) {
            setSelectedRowKeys((ids: any) => [...ids, record.id]);
        } else {
            setSelectedRowKeys((ids: any) => {
                const index = ids.indexOf(record.id);
                return [...ids.slice(0, index), ...ids.slice(index + 1)];
            });
        }
    };

    const handleSelectAll = (selected: any, record: any, changeRows: any) => {
        if (selected) {
            changeRows.map((row: any) => setSelectedRowKeys(((ids: any) => [...ids, row.id])))
        }
        else {
            setSelectedRowKeys([])
        }
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onSelectAll: handleSelectAll,
        onSelect: handleSelect,
        getCheckboxProps: (record: any) => ({
            disabled: record.approved !== null, // Column configuration not to be checked
        }),
    };


    let filteredData = vedomostData?.map((item: any, id: number) => {
        let rating = 0;
        let exam = 0;
        let final = 0;

        item.academicStreamStudentGrade.forEach((item: any) => {
            if (item.gradeType === 1 || item.gradeType === 2) {
                rating += +item.grade;
            }
            if (item.gradeType === 3) {
                exam = item.grade;
            }
            if (item.gradeType === 4) {
                final = item.grade;
            }
        });

        return {
            id: item.student.id,
            key: id + 1,
            name: `${item.student.surnameEn} ${item.student.nameEn}`,
            academicStreamGrades: item.academicStreamStudentGrade,
            group: item.student.group.title,
            rating: Number(rating / 2).toFixed(1),
            exam: Number(exam).toFixed(1),
            total: final,
            approved: item.approved,
        };
    });

    const data = [...filteredData];

    // @ts-ignore
    data.forEach((item) => {
        let ratingConvert = gradeConvert(+item.rating);
        let examConvert = gradeConvert(+item.exam);
        let totalConvert = gradeConvert(+item.total);
        // @ts-ignore
        item.ratingGPA = ratingConvert.gpaGrade;
        // @ts-ignore
        item.ratingALPH = ratingConvert.alphabeticGrade;
        // @ts-ignore
        item.ratingTRD = ratingConvert.traditionalGrade;
        // @ts-ignore
        item.examGPA = examConvert.gpaGrade;
        // @ts-ignore
        item.examALPH = examConvert.alphabeticGrade;
        // @ts-ignore
        item.examTRD = examConvert.traditionalGrade;
        // @ts-ignore
        item.totalGPA = totalConvert.gpaGrade;
        // @ts-ignore
        item.totalALPH = totalConvert.alphabeticGrade;
        // @ts-ignore
        item.totalTRD = totalConvert.traditionalGrade;
    });

    const handleApprove = (value: any, status: boolean, reason?: string) => {
        VedomostiService.approveByTeacher(value.id, status, form.getFieldValue("year"), form.getFieldValue("term"), form.getFieldValue("groupId"), form.getFieldValue("disciplineId"), reason)
            .then(() => notification.success({ message: "Successfully approved" }))
            .finally(() => (shouldRerender()))
    };

    const handleMultipleApprove = (value: any, status: boolean, reason?: string) => {
        VedomostiService.approveByTeacher(value, status, form.getFieldValue("year"), form.getFieldValue("term"), form.getFieldValue("groupId"), form.getFieldValue("disciplineId"), reason)
            .then(() => notification.success({ message: "Successfully approved" }))
            .finally(() => (shouldRerender()))
    }



    return isMultiple ? (
        <>
            <Modal open={isVisible} title="Опишите причину" onCancel={() => setIsVisible(false)} onOk={() => handleMultipleApprove(selectedRowKeys, false, reason)}>
                <TextArea onChange={(e) => setReason(e.currentTarget.value)} />
            </Modal>
            <Space direction='vertical' style={{ width: "100%" }}>
                <Space>
                    <Popconfirm title="Вы уверены что хотите подтвердить?" onConfirm={() => handleMultipleApprove(selectedRowKeys, true)}>
                        <Button disabled={selectedRowKeys.length === 0}>Подтвердить</Button>
                    </Popconfirm>
                    <Popconfirm title="Вы уверены что хотите опровергнуть?" onConfirm={() => setIsVisible(true)}>
                        <Button disabled={selectedRowKeys.length === 0}>Опровергнуть</Button>
                    </Popconfirm>
                </Space>

                <Table rowKey={(record) => record.id}
                    scroll={{ x: true }} dataSource={data} bordered rowSelection={rowSelection} loading={loading} pagination={{ defaultPageSize: 25 }}
                >
                    <Column title={'Full name'} dataIndex={'name'} />
                    <Column title={'Group'} dataIndex={'group'} />
                    <ColumnGroup title={'Rating'}>
                        <Column title={'In %'} dataIndex={'rating'} />
                        <Column title={'Number'} dataIndex={'ratingGPA'} />
                        <Column title={'Letter'} dataIndex={'ratingALPH'} />
                        <Column title={'Traditional'} dataIndex={'ratingTRD'} />
                    </ColumnGroup>
                    <ColumnGroup title={'Exam'}>
                        <Column title={'In %'} dataIndex={'exam'} />
                        <Column title={'Number'} dataIndex={'examGPA'} />
                        <Column title={'Letter'} dataIndex={'examALPH'} />
                        <Column title={'Traditional'} dataIndex={'examTRD'} />
                    </ColumnGroup>
                    <ColumnGroup title={'Total score'}>
                        <Column title={'In %'} dataIndex={'total'} />
                        <Column title={'Number'} dataIndex={'totalGPA'} />
                        <Column title={'Letter'} dataIndex={'totalALPH'} />
                        <Column title={'Traditional'} dataIndex={'totalTRD'} />
                    </ColumnGroup>
                </Table>
            </Space>
        </>

    ) : (
        <>
            <Modal open={isVisible} title="Опишите причину" onCancel={() => setIsVisible(false)} onOk={() => handleApprove(studentId, false, reason)}>
                <TextArea onChange={(e) => setReason(e.currentTarget.value)} />
            </Modal>
            <Table
                pagination={{ defaultPageSize: 25 }}
                scroll={{ x: true }} dataSource={data} bordered loading={loading}>
                <Column title={'Full name'} dataIndex={'name'} sorter={(a: any, b: any) => a.name.localeCompare(b.name)} {...getColumnSearchProps('fullName', inputRef)} />
                <Column title={'Group'} dataIndex={'group'} />
                <ColumnGroup title={'Rating'}>
                    <Column title={'In %'} dataIndex={'rating'} />
                    <Column title={'Number'} dataIndex={'ratingGPA'} />
                    <Column title={'Letter'} dataIndex={'ratingALPH'} />
                    <Column title={'Traditional'} dataIndex={'ratingTRD'} />
                </ColumnGroup>
                <ColumnGroup title={'Exam'}>
                    <Column title={'In %'} dataIndex={'exam'} />
                    <Column title={'Number'} dataIndex={'examGPA'} />
                    <Column title={'Letter'} dataIndex={'examALPH'} />
                    <Column title={'Traditional'} dataIndex={'examTRD'} />
                </ColumnGroup>
                <ColumnGroup title={'Total score'}>
                    <Column title={'In %'} dataIndex={'total'} />
                    <Column title={'Number'} dataIndex={'totalGPA'} />
                    <Column title={'Letter'} dataIndex={'totalALPH'} />
                    <Column title={'Traditional'} dataIndex={'totalTRD'} />
                </ColumnGroup>
                <Column
                    title={''}
                    render={(key, value: any) => {
                        return value.approved === null ? (
                            <Space>
                                <Popconfirm title="Are you sure that you want to approve?" onConfirm={() => handleApprove(value, true)}>
                                    <Button
                                        type="primary"
                                    >
                                        Approve
                                    </Button>
                                </Popconfirm>
                                <Popconfirm title="Are you sure that you want to refute?" onConfirm={() => { setIsVisible(true); setStudentId(value) }}>
                                    <Button
                                        danger>
                                        Refute
                                    </Button>
                                </Popconfirm>
                            </Space>
                        ) : (
                            <Button
                                disabled={value.approved !== null}>
                                {value.approved === true ? "Approved" : "Refuted"}
                            </Button>
                        )
                    }}
                />
            </Table >
        </>

    );
}

export default TeacherStatementTable