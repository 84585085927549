import { ColumnsType } from 'antd/lib/table';
import { StudentDtoResponse } from '../../../interfaces/Student/StudentDtoResponse';

interface TableColumns {
  id: number;
  userId: number;
  fullName: string;
  group?: string;
  iin?: string;
  dateOfBirth: string;
  courseGrade?: string;
  studentStatus?: string;
  studyingStatus?: string;
}

const useStudentsTable = (data: StudentDtoResponse[], navigate: any, selectionStates: any) => {
  let filteredData: TableColumns[] = [];
  const { selectedStudentId, setSelectedStudentId, selectedStudent, setSelectedStudent } = selectionStates;
  // eslint-disable-next-line array-callback-return
  data.map((student) => {
    filteredData.push({
      id: student.id,
      userId: student.userId,
      fullName: student.fullName,
      group: student.group.title,
      iin: student.iin,
      dateOfBirth: student.birthDate?.toString().slice(0, 10),
      courseGrade: student.courseGrade,
      studentStatus: student.statusTypeId.nameRu,
      studyingStatus: student.studyingStatusType.nameRu,
    });
  });
  const columns: ColumnsType<TableColumns> = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Группа',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'Дата рождения',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
    },
    {
      title: 'Курс',
      dataIndex: 'courseGrade',
      key: 'courseGrade',
    },
    {
      title: 'ИИН',
      key: 'iin',
      dataIndex: 'iin',
    },
    {
      title: 'Статус',
      key: 'studentStatus',
      dataIndex: 'studentStatus',
    },
    {
      title: 'Академ. Статус',
      key: 'studyingStatus',
      dataIndex: 'studyingStatus',
    },
  ];

  const shortColumns = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Группа',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'Курс',
      dataIndex: 'courseGrade',
      key: 'courseGrade',
    },
    {
      title: 'Академ. Статус',
      key: 'studyingStatus',
      dataIndex: 'studyingStatus',
    },
  ];



  const onRowSelect = (student: TableColumns) => {
    navigate.push(`/profile/${student.userId}`);
  };

  const handleSelect = (record: TableColumns, selected: any) => {
    if (selected) {
      setSelectedStudentId((ids: any) => [...ids, record.id]);
    } else {
      setSelectedStudentId((ids: any) => {
        const index = ids.indexOf(record.id);
        return [...ids.slice(0, index), ...ids.slice(index + 1)];
      });
    }
  };

  const selectRow = (record: TableColumns) => {
    const selectedRows = [...selectedStudentId];
    const selectedStudents = [...selectedStudent];
    const student = data.find((student) => student.id === record.id);
    if (selectedRows.indexOf(record.id) >= 0) {
      selectedStudents.splice(selectedStudents.findIndex(student => student.id === record.id), 1);
      selectedRows.splice(selectedRows.indexOf(record.id), 1);
    } else {
      selectedStudents.push({
        fullName: `${student?.fullName}`,
        group: student?.group.title,
        id: student?.id,
        userId: student?.userId,
      });
      selectedRows.push(record.id);
    }
    setSelectedStudentId(selectedRows);
    setSelectedStudent(selectedStudents);
  };

  const rowSelection = {
    selectedRowKeys: selectedStudentId,
    onSelect: handleSelect,
  };

  return { columns, shortColumns, filteredData, onRowSelect, selectRow, rowSelection };
};

export default useStudentsTable;
