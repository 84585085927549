import { StudentsListWithGroupDtoResponse } from "../../../../interfaces/Student/StudentsListWithGroupDtoResponse";
import { Select } from 'antd';

type Options = StudentsListWithGroupDtoResponse[];

const { Option } = Select;

interface Props {
    placeholder: string;
    selectedOption: number | undefined;
    options: Options;
    onSelect: (value: number) => void;
    width?: number;
}

const StudentSearch = ({ placeholder, selectedOption, options, onSelect, width, }: Props) => {
    return (
        <Select
            placeholder={placeholder}
            value={selectedOption}
            onSelect={onSelect}
            style={{ width: width ? width : 600 }}>
            {options.map((option) => (
                <Option key={option.id} value={option.id}>
                    {`${option.name} ${option.surname} ${option.patronymic} | ${option.groupname}`}
                </Option>
            ))}
        </Select>
    )
}
export default StudentSearch;