import { useEffect, useState } from "react"
import { TeacherKpiApplicationWithKPIDto } from "../../../interfaces/Kpi/TeacherKpiApplicationWithKPIDto";
import { KpiService } from "../../../services/kpi/KpiService";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { KpiApplicationSettingsDtoResponse } from "../../../interfaces/Kpi/KpiApplicationSettingsDtoResponse";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const useTeacher = () => {
    const [loading, setLoading] = useState(false);
    const [kpiAppications, setKpiApplications] = useState<TeacherKpiApplicationWithKPIDto>();
    const [settings, setSettings] = useState<KpiApplicationSettingsDtoResponse[]>([]);


    const handleOnLoading = () => {
        setLoading(true)
    }

    const handleOffLoading = () => {
        setLoading(false)
    }


    useEffect(() => {
        KpiService.getApplicationSettings()
            .then(({ data }) => {
                setSettings(data);
                KpiService.getTeacherApplicationsWithKpi(data.find(v => v.isCurrent === true)?.id)
                    .then(({ data }) => setKpiApplications(data))
            })
    }, [loading])

    const handleUpdateKpiInfo = (id: number) => {
        KpiService.getTeacherApplicationsWithKpi(id)
            .then(({ data }) => setKpiApplications(data))
    }

    const kpiData = {
        labels: kpiAppications?.kpiApplicationDtoResponses.map((kpiAppication) => kpiAppication.kpiCategory.name),
        datasets: [
            {
                label: "Weight",
                data: kpiAppications?.kpiApplicationDtoResponses.map((kpiAppication) => kpiAppication.kpiCategory.weight),
                backgroundColor: 'rgba(173,255,47, 0.6)',
            },
        ],
    };


    return {
        data: {
            loading,
            kpiData,
            kpiAppications,
            settings,
        },
        handlers: {
            handleOffLoading,
            handleOnLoading,
            handleUpdateKpiInfo
        }
    }
}

export default useTeacher