import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Input, Tag, Select, DatePicker, Modal } from 'antd';
import PageWrapper from "../../../ui/PageWrapper";
import { SummerRetakeApplicationService } from "./SummerRetakeApplicationService";
import useProfilePage from "../ProfilePage/useProfilePage";
import moment from "moment";
import { CheckOutlined, CloseOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons";
import { FileService } from "../../../services/file/FileService";
import { successNotification } from "../../../helpers/successNotification";

interface RetakeApplicationItem {
    id: number;
    iin: number;
    fileId: number;
    studentName: string;
    studentSurname: string;
    discipline: string;
    fileName: string;
    approved: number;
    createdDate: Date;
    course: number;
    studentStatus: string;
    creditAmount: number;
    comment: string;
    teacher: string;
    groupName: string;
    email:string;
    studentNameEn:string;
    studentSurnameEn:string;
    attendance:number;
    midterm:number;
    endterm:number;
    term:number;
    final:number;
    studentId:number;
    approvedOfficeRegistrar:number;
}

const { Option } = Select;
const { RangePicker } = DatePicker;

interface Props {
    userId?: number;
}


const SummerRetakeApplications = ({ userId }: Props) => {
    const { profileInfo } = useProfilePage(userId);
    const [retakeApplicationsData, setRetakeApplicationsData] = useState<RetakeApplicationItem[]>([]);
    const [searchIIN, setSearchIIN] = useState('');
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchIIN(e.target.value);
    };
    interface FilterState {
        studentStatus: string;
        course: number | null;
        approved: number | null;
        startDate: string | null;
        endDate: string | null;
    }

    const [filters, setFilters] = useState<FilterState>({
        studentStatus: '',
        course: null,
        approved: null,
        startDate: null,
        endDate: null
    });

    const downloadFile = (id: number, disciplineName: string, studentName: string, studentSurname: string) => {
        const fileName = `Квитанция_${disciplineName}_${studentName}_${studentSurname}`;
        FileService.getFileById(id, fileName)
            .then(() => successNotification("Файл успешно скачан!"))
    };


    useEffect(() => {
        fetchRetakeApplicationFilter();
    }, [profileInfo]);

    const fetchRetakeApplicationFilter = () => {

        SummerRetakeApplicationService.fetchRetakeApplicationFilter(filters)
            .then((response) => {
                setRetakeApplicationsData(response.data);
            });
    };
    const getStatusText = (status: number) => {
        switch (status) {
            case 1:
                return <Tag color="blue">In processing</Tag>;
            case 2:
                return <Tag color="red">Declined</Tag>;
            case 3:
                return <Tag color="green">Approved</Tag>;
            default:
                return <Tag color="gray">Unknown status</Tag>;
        }
    };
    const filteredData = retakeApplicationsData.filter(item =>
        item.iin.toString().includes(searchIIN)
    );

    const declineApplicationByOfficeRegistrar = (id: number) => {
        SummerRetakeApplicationService.declineApplicationByOfficeRegistrar(id)
            .then(() => {
                fetchRetakeApplicationFilter();
            })
            .catch(error => console.error("Ошибка при обновлении статуса:", error));
    };

    const acceptApplicationByOfficeRegistrar = (id: number) => {
        SummerRetakeApplicationService.acceptApplicationByOfficeRegistrar(id)
            .then(() => {
                fetchRetakeApplicationFilter();
            })
            .catch(error => console.error("Ошибка при обновлении статуса:", error));
    };

    const fetchSummerRetakeApplicationExcel = () => {
        const params = {
            studentStatus: filters.studentStatus,
            course: filters.course,
            approved: filters.approved,
            startDate: filters.startDate,
            endDate: filters.endDate
        };

        SummerRetakeApplicationService.fetchRetakeApplicationExcel(params)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'summer-retake-applications-' + filters.studentStatus + '-' + filters.course + '-' + filters.startDate + '-' + filters.endDate + '.xlsx');
                document.body.appendChild(link);
                link.click();
                if (link.parentNode) {
                    link.parentNode.removeChild(link);
                }
            })
            .catch((error) => {
                console.error("Ошибка при получении данных:", error);
            });
    };

    const handleDownloadStudentRetakeApplicationWord = (studentId:number,studentName: string, studentSurname: string, groupName:string) => {
        SummerRetakeApplicationService.getStudentRetakeApplicationWord(studentId)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'retake application '+studentName+' '+studentSurname+'.docx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error downloading student letter to rector:', error);
               
            });
    };
    // const columns = [
    //     // {
    //     //     title: 'ID',
    //     //     dataIndex: 'id',
    //     //     key: 'id',
    //     // },
    //     // {
    //     //     title: 'File Name',
    //     //     dataIndex: 'fileName',
    //     //     key: 'fileName',
    //     // },
    //     {
    //         title: 'IIN',
    //         dataIndex: 'comment',
    //         key: 'comment',
    //     },
    //     {
    //         title: 'Comment',
    //         dataIndex: 'comment',
    //         key: 'comment',
    //     },
    //     {
    //         title: 'Course',
    //         dataIndex: 'course',
    //         key: 'course',
    //     },
    //     {
    //         title: 'Approved Date',
    //         dataIndex: 'approvedDate',
    //         key: 'approvedDate',
    //     },
    //     {
    //         title: 'Discipline',
    //         dataIndex: 'discipline',
    //         key: 'discipline',
    //     },
    //     {
    //         title: 'Approved',
    //         dataIndex: 'approved',
    //         key: 'approved',
    //     },
    //     {
    //         title: 'Created Date',
    //         dataIndex: 'createdDate',
    //         key: 'createdDate',
    //     },
    //     {
    //         title: 'Student Surname',
    //         dataIndex: 'studentSurname',
    //         key: 'studentSurname',
    //     },
    //     {
    //         title: 'Student Name',
    //         dataIndex: 'studentName',
    //         key: 'studentName',
    //     },
    //     {
    //         title: 'Email',
    //         dataIndex: 'email',
    //         key: 'email',
    //     },
    //     {
    //         title: 'Approved Office Registrar',
    //         dataIndex: 'approvedOfficeRegistrar',
    //         key: 'approvedOfficeRegistrar',
    //     },
    //     {
    //         title: 'Student Status',
    //         dataIndex: 'studentStatus',
    //         key: 'studentStatus',
    //     },
    //     {
    //         title: 'Group Name',
    //         dataIndex: 'groupName',
    //         key: 'groupName',
    //     },
    // ];
    const columns =[
        {
            title: (
                <>
                    <Input
                        placeholder="Find by IIN"
                        value={searchIIN}
                        onChange={handleSearchChange}
                        style={{ width: 150, margin: '10px 0' }}
                    />
                </>
            ),
            dataIndex: 'iin',
        },
        {
            title: 'Name',
            dataIndex: 'studentName',
        },
        {
            title: 'Surname',
            dataIndex: 'studentSurname',
        },
        {
            title: 'Name EN',
            dataIndex: 'studentNameEn',
        },
        {
            title: 'Surname EN',
            dataIndex: 'studentSurnameEn',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Course',
            dataIndex: 'course',
        },
        {
            title: 'Educational Degree',
            dataIndex: 'studentStatus',
        },
        {
            title: 'Group',
            dataIndex: 'groupName',
        },
        {
            title: 'Discipline',
            dataIndex: 'discipline',
        },
        {
            title: 'Amount of credits',
            dataIndex: 'creditAmount',
        },
        {
            title: 'Accountant application status',
            dataIndex: 'approved',
            key: 'approved',
            render: (status: number) => <span>{getStatusText(status)}</span>,
        },
        {
            title: 'Office Registrar application status',
            dataIndex: 'approvedOfficeRegistrar',
            key: 'approved',
            render: (status: number) => <span>{getStatusText(status)}</span>,
        },
        {
            title: 'Submission date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render:(date: string | undefined) => moment(date).format('DD.MM.YYYY HH:mm'),
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            // render: (text: string, record: FxExamApplicationItem) => (
            //     <>
            //         <span>{record.comment}  </span>
            //         <Button onClick={() => showModal(record)}>{record.comment ? <EditOutlined /> : <PlusOutlined />}</Button>
            //     </>
            // )
        },
        {
            title: 'Receipt',
            dataIndex: 'fileId',
            render: (text: string, record: any) =>
                <Button icon={<DownloadOutlined />} onClick={() => downloadFile(record.fileId, record.discipline, record.studentName, record.studentSurname)} type="default">Скачать</Button>
        },
        {
            title: 'Applications',
            dataIndex: 'fileId',
            render: (text: string, record: RetakeApplicationItem) => {
                if (record.approvedOfficeRegistrar === 3) {
                    return (
                        <div>
                <Button icon={<DownloadOutlined />} onClick={() => handleDownloadStudentRetakeApplicationWord(record.studentId,record.studentName, record.studentSurname,record.groupName)} type="default">Скачать</Button>
                </div>
                );
            } else {
                return null;
            }
        },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: RetakeApplicationItem) => {
                if (record.approved === 3) {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button icon={<CheckOutlined />} onClick={() => acceptApplicationByOfficeRegistrar(record.id)} type='primary'>Approve</Button>
                            <Button icon={<CloseOutlined />} onClick={() => declineApplicationByOfficeRegistrar(record.id)} type='primary' danger style={{ marginLeft: '10px' }}>Decline</Button>
                        </div>
                    );
                } else {
                    return null;
                }
            }
        }
    ]

    const shouldProcessItem = (item: RetakeApplicationItem) => {
        return (!filters.studentStatus || item.studentStatus === filters.studentStatus) &&
            (!filters.course || item.course === filters.course) &&
            (!filters.approved || item.approved === filters.approved) &&
            (!filters.startDate || new Date(item.createdDate) >= new Date(filters.startDate)) &&
            (!filters.endDate || new Date(item.createdDate) <= new Date(filters.endDate));
    };
    const approveAllFilteredApplications = () => {
        retakeApplicationsData.forEach(item => {
            if (shouldProcessItem(item)) {
                acceptApplicationByOfficeRegistrar(item.id);
            }
        });
    };

    const rejectAllFilteredApplications = () => {
        retakeApplicationsData.forEach(item => {
            if (shouldProcessItem(item)) {
                declineApplicationByOfficeRegistrar(item.id);
            }
        });
    };
    
    return (
        <PageWrapper>
            <Typography.Title level={1}>Retake Applications</Typography.Title>
            <div>
                <Select
                    value={filters.studentStatus}
                    onChange={value => setFilters({ ...filters, studentStatus: value })}
                    style={{ width: 300 }}
                    placeholder="Educational Degree"
                >
                    <Option value="null">All statuses</Option>
                    <Option value="Bachelor">Bachelor</Option>
                    <Option value="Master of scientific and pedagogical direction">Master of scientific and pedagogical direction</Option>
                    <Option value="Master of the core area">Master of the core area</Option>
                    <Option value="Master of Business Administration">Master of Business Administration</Option>
                    <Option value="Резидент">Резидент</Option>
                    <Option value="Doctor PhD">Doctor PhD</Option>
                    <Option value="Doctor with specialization">Doctor with specialization</Option>
                    <Option value="Specialist">Specialist</Option>
                    <Option value="Preparatory department">Preparatory department</Option>
                </Select><br /><br />
                <Select
                    value={filters.course}
                    onChange={value => setFilters({ ...filters, course: value })}
                    style={{ width: 300 }}
                    placeholder="Course"
                >
                <Option value={0}>All courses</Option>
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                </Select><br /><br />
                <Select
                    value={filters.approved}
                    onChange={value => setFilters({ ...filters, approved: value })}
                    style={{ width: 300 }}
                    placeholder="Accountant application status"
                >
                    <Option value={0}>All statuses</Option>
                    <Option value={1}>In processing</Option>
                    <Option value={2}>Declined</Option>
                    <Option value={3}>Approved</Option>
                </Select><br /><br />
                <RangePicker
                    onChange={dates => {
                        const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null;
                        const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null;
                        setFilters({ ...filters, startDate, endDate });
                    }}
                /><br /><br />
                <Button onClick={fetchRetakeApplicationFilter}>Apply filter</Button> <br /><br />
                <Button onClick={approveAllFilteredApplications} style={{ marginRight: '20px' }} type='primary'><CheckOutlined /> Approve all applications by filter</Button>
                <Button onClick={rejectAllFilteredApplications} type='primary' danger><CloseOutlined /> Decline all applications by filter</Button> <br /><br />
                <Button onClick={fetchSummerRetakeApplicationExcel}><FileExcelOutlined /> Excel export by filter options</Button><br /><br />
            </div>

            <Table dataSource={filteredData} columns={columns} scroll={{ x: '500px' }}/>

        </PageWrapper>
    );
};


export default SummerRetakeApplications;