import { useEffect, useState } from 'react';
import { Table, Typography, Button, Modal, Upload, message, UploadProps, Tag } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import {
  UploadOutlined,
} from '@ant-design/icons';
import useProfilePage from '../ProfilePage/useProfilePage';
import { FxExamApplicationService } from './FxExamApplicationService';

interface FxExamApplicationItem {
  duId: number;
  lastname: string;
  firstname: string;
  fullname: string;
  final: number;
  email: string;
  courseId: number;
  courseName: string;
  studentMoodleId: number;
  midterm: number;
  endterm: number;
  term: number;
  attendance: number;
  duTutorId: number;
}

interface FxExamStatusItem {
  id: number;
  fileName: string;
  createdDate: Date;
  approved: number;
  approvedDate: string | null;
  studentId: number | null;
  fileId: number;
  studentName: string;
  disciplineName: string;
  studentSurname: string;
  studentPatronymic: string | null;
  comment: string;
  iin: string;
  disciplineId: number;
  approvedOfficeRegistrar: number;
}

interface Props {
  userId?: number;
}

const FxExamApplication = ({ userId }: Props) => {
  const [fxExamApplicationData, setFxExamApplicationData] = useState<FxExamApplicationItem[]>([]);
  const { profileInfo } = useProfilePage(userId);
  const [fileId, setFileId] = useState<number>();
  const [fxExamStatusData, setFxExamStatusData] = useState<FxExamStatusItem[]>([]);
  const [showFxExamStatus, setShowFxExamStatus] = useState(false);
  const [showFxExamApplication, setShowFxExamApplication] = useState(true);
  const { Text } = Typography;

  const fetchFxExamStatusData = () => {
    const studentId = profileInfo?.student.id;
    if (studentId) {
      FxExamApplicationService.getFxExamApplicationsForStudent(studentId)
        .then((response) => {
          setFxExamStatusData(response.data);
        })
        .catch((error) => {
          console.error('Ошибка при получении статуса заявки:', error);
        });
    }
  };

  const toggleFxExamApplication = () => {
    setShowFxExamApplication(true);
    setShowFxExamStatus(false);
    fetchFxExamApplicationData();
    fetchFxExamStatusData();
  };

  const toggleFxExamStatus = () => {
    setShowFxExamApplication(false);
    setShowFxExamStatus(true);
    fetchFxExamApplicationData();
    fetchFxExamStatusData();
  };

  useEffect(() => {
    fetchFxExamApplicationData();
  }, [profileInfo]);

  useEffect(() => {
    fetchFxExamStatusData();
  }, [profileInfo]);

  const fetchFxExamApplicationData = () => {
    const email = profileInfo?.email;
    if (email) {
      FxExamApplicationService.getFxExamData(email)
        //FxExamApplicationService.getFxExamData('230483@astanait.edu.kz')
        .then((response) => {
          setFxExamApplicationData(response.data);
        })
        .catch((error) => {
          console.error('Ошибка при получении данных:', error);
        });
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDiscipline, setSelectedDiscipline] = useState<FxExamApplicationItem | null>(null);
  const [fileList, setFileList] = useState<any>();

  const showModal = (discipline: FxExamApplicationItem) => {
    setSelectedDiscipline(discipline);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    handleUpload();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('fxFile', fileList);

    if (selectedDiscipline) {
      FxExamApplicationService.uploadFileFxApplication(formData)
        .then((response) => {
          setFileId(response.data);
          FxExamApplicationService.createFxApplication(response.data,
            selectedDiscipline.duId,
            selectedDiscipline.duTutorId,
            selectedDiscipline.attendance,
            selectedDiscipline.midterm,
            selectedDiscipline.endterm,
            selectedDiscipline.term,
            selectedDiscipline.final
          );
          //successNotification('Application accepted for consideration');
          setFileList(null);
          setTimeout(() => {
            fetchFxExamApplicationData();
            fetchFxExamStatusData();
          }, 3000);
        })
        .catch((error) => {
          message.error('Ошибка загрузки файла');
          console.error('Error:', error);
        });
    }
  };

  const uploadProps: UploadProps = {
    name: 'file',
    onChange(e) {
      e.file.status = 'done';
    },
    customRequest(info) {
      setFileList(info.file);
    },
  };

  const isButtonDisabledForDiscipline = (disciplineId: number) => {
    return fxExamStatusData.some(
      (item) => item.disciplineId === disciplineId && (item.approvedOfficeRegistrar === 1 || item.approvedOfficeRegistrar === 3)
    );
  };

  const columns = [
    // {
    //   title: 'id',
    //   dataIndex: 'duId',
    //   key: 'fullname',
    // },
    {
      title: 'Discipline',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Grade',
      dataIndex: 'final',
      key: 'final',
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_: any, record: FxExamApplicationItem) => {
        const disabled = isButtonDisabledForDiscipline(record.duId);

        return (
          <Button onClick={() => (disabled ? null : showModal(record))} disabled={disabled}>
            {disabled ? 'Wait for your application to be processed' : 'Submit an application to FX'}
          </Button>
        );
      },
    },
  ];

  const getStatusText = (status: number) => {
    switch (status) {
      case 1:
        return <Tag color="blue">In processing</Tag>;
      case 2:
        return <Tag color="red">Declined</Tag>;
      case 3:
        return <Tag color="green">Approved</Tag>;
      default:
        return <Tag color="gray">Unknown status</Tag>;
    }
  };

  const formatDateString = (dateString: string): string => {
    if (!dateString || new Date(dateString).toString() === 'Invalid Date') {
      return 'Unknown';
    }
    const date = new Date(dateString);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    return formattedDate;
  };

  const statusColumns = [
    // {
    //   title: 'duid',
    //   dataIndex: 'disciplineId',
    //   key: 'disciplineId',
    // },
    {
      title: 'Discipline',
      dataIndex: 'disciplineName',
      key: 'disciplineName',
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'approved',
    //   key: 'approved',
    //   render: (status: number) => <span>{getStatusText(status)}</span>,
    // },
    {
      title: 'Status',
      dataIndex: 'approvedOfficeRegistrar',
      key: 'approved',
      render: (status: number) => <span>{getStatusText(status)}</span>,
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Submission date',
      dataIndex: 'createdDate',
      render: (text: string) => <span>{formatDateString(text)}</span>,
    },
    {
      title: 'Approved date',
      dataIndex: 'approvedDate',
      render: (text: string) => <span>{formatDateString(text)}</span>,
    },
  ];

  const [isTimeInRange, setIsTimeInRange] = useState(false);

  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();
    setIsTimeInRange(hours >= 8 && hours < 19);
  }, []);

  const [timeMessage, setTimeMessage] = useState('Applications are accepted from 08:00 to 19:00');

  return (
    <PageWrapper>
      <Typography.Title level={1}>FX Exam Application</Typography.Title>
      {/* <Typography.Title level={3}>{profileInfo?.email}</Typography.Title>
            {profileInfo?.student.fullName}<br />
            {profileInfo?.student.group?.title} */}

      <p>
        <Typography.Title level={4}>The procedure for applying for the "FX" retake</Typography.Title>
        1.1 The "FX" retake application is accepted exclusively through the “Digital University” system.

        <br />
        1.2 <Text mark>The learner applies for registration a minimum of 2 days before the FX re-examination between 8.00 am and 7.00 pm.</Text>
        <br />
        1.3 For the payment purpose, it is necessary to specify the full name and IIN of the student for whom the payment is made, as well as select the type of payment named "FX retake".<br />
        1.4 Registration for the “FX” retake consists of the following steps:<br />
        &nbsp;&nbsp;&nbsp;&nbsp;1. <Text style={{ fontStyle: 'italic' }}>Payment.</Text> The student makes the payment in the price of 1 academic credit - according to the approved price list - at any branch of Halyk Bank or through the Homebank application.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;2. <Text style={{ fontStyle: 'italic' }}>Formation of the application.</Text> The learner attaches the payment receipt in PDF format (from the payment history) under the Digital University (DU) → FX Exam Application tab.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;3. <Text style={{ fontStyle: 'italic' }}>Application confirmation.</Text> In the "Show Application Status" section, the status should be "confirmed".<br />
        1.5 In the case any of the steps fail the registration is “invalid.” And student is responsible for the completion of the registration <br />

        <br />
        <Typography.Title level={4}>
          University requisites for payment of retake "FX":
        </Typography.Title>
        Astana IT University LLP
        <br />
        BIN: 190240014589
        <br />
        Postal address: 010000, Republic of Kazakhstan, Nur-Sultan, A.Baitursynov str. 9, office. 109
        <br />
        Bank details:
        <br /> IIC: KZ266018821000167581 at JSC Halyk Bank of Kazakhstan BIK: HSBKKZKX
        <br /> KBE: 17 KNP: 861
        <br />
        <br />
        <Typography.Title level={4}>
          <strong>Cost of 1 credit:</strong>
        </Typography.Title>
        • Bachelor's degree 1,2,3 courses - 20000 tg <br />
        • Master's Degree 1-2 courses - 28000 tg <br />
        • Doctoral Degree 1 course - 45000 tg <br />
        • For foreign nationals on scholarship program (Bologna process) -10597 tg
      </p>
      <Button style={{ margin: '30px 30px 30px 0' }} onClick={toggleFxExamApplication}>
        Submitting an application
      </Button>
      <Button onClick={toggleFxExamStatus} style={{ margin: '0 30px 30px 0' }}>
        Show application status
      </Button>

      {showFxExamApplication && (
        <div>
          {isTimeInRange && (
            <div>
              <Table dataSource={fxExamApplicationData} columns={columns} scroll={{ x: '500px' }} />
            </div>
          )}
          {!isTimeInRange && (
            <div>
              <Typography.Title level={4}>
                <Text mark>{timeMessage}</Text>
              </Typography.Title>
            </div>
          )}
        </div>
      )}

      {showFxExamStatus && <Table dataSource={fxExamStatusData} columns={statusColumns} scroll={{ x: '500px' }} />}

      <Modal
        title="Submitting an Application"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Send
          </Button>,
        ]}
      >
        <Upload {...uploadProps} fileList={fileList ? [fileList] : []}>
          <Button icon={<UploadOutlined />}>Select a file</Button>
        </Upload>
      </Modal>
    </PageWrapper>
  );
};

export default FxExamApplication;
