import {
  BankOutlined,
  CalendarOutlined,
  IdcardOutlined,
  UserOutlined,
  MoneyCollectOutlined,
  SolutionOutlined,
  MailOutlined,
  PhoneOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  SwapOutlined,
  PauseCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Card, Col, Row, Tooltip, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../ui/PageWrapper';
import ProfileCurriculum from './ProfileCurriculum';
import ProfileDetails from './ProfileDetails';
import useProfilePage from './useProfilePage';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import ProfileOrders from './ProfileOrders';
import Spinner from '../../../ui/Spinner';
import BackButton from '../../../ui/BackButton';
import ProfileGpa from './ProfileGpa';
import TranscriptTable from '../TranscriptPage/TranscriptTable';
import AuthenticatedContent from '../../../common/AuthenticatedContent';
import moment from 'moment';

interface Props {
  userId?: number;
}

const ProfilePage = ({ userId }: Props) => {
  const { profileInfo, loading, infoHidden, handleToggleInfoDisplay, image3x4, ProfileDescriptionItem } =
    useProfilePage(userId);
  const [rerender, setRerender] = useState(false);
  const location = useLocation();
  let getCurrentLocationForTab = useRef('');
  useEffect(() => {
    const tabLocationArr = location.pathname.split('/');
    getCurrentLocationForTab.current = tabLocationArr[tabLocationArr.length - 1];
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
  const history = useHistory();

  return (
    <PageWrapper>
      {userId && <BackButton />}

      <Typography.Title level={1}>Profile</Typography.Title>
      <Card style={{ width: '100%', borderRadius: 15 }}>
        <Row>
          <Col flex={0}>
            <Avatar src={image3x4} icon={<UserOutlined />} size={192} shape={'circle'} />
          </Col>
          <Col flex={12} offset={1}>
            {!loading && (
              <>
                <Typography.Title level={2}>{`${profileInfo?.student?.fullName}`}</Typography.Title>
                <Typography.Title level={3}>{`${profileInfo?.student?.nameEn}` + " " + `${profileInfo?.student?.surnameEn}`}</Typography.Title>
                <Typography.Title level={5} type="secondary" style={{ marginTop: 0 }}>
                  {infoHidden ? '(hidden)' : profileInfo?.email}
                </Typography.Title>
                <Typography.Title level={5} type="secondary" style={{ marginTop: 0 }}>
                  {infoHidden ? '(hidden)' : profileInfo?.student.group?.title}
                </Typography.Title>
                <Row style={{ marginTop: 20 }}>
                  <Col flex={12} style={{ marginTop: 10 }}>
                    <ProfileDescriptionItem
                      keys="Email"
                      item={profileInfo?.email}
                      icon={<MailOutlined />}
                      isHidden={infoHidden}
                    />
                    <ProfileDescriptionItem
                      keys="IIN"
                      item={profileInfo?.student.iin?.toString()}
                      icon={<IdcardOutlined />}
                      styles={{ marginTop: 10, display: 'block' }}
                      isHidden={infoHidden}
                    />
                    <ProfileDescriptionItem
                      keys="Birthdate"
                      item={moment(profileInfo?.student.birthDate).format('LL')}
                      icon={<CalendarOutlined />}
                      styles={{ marginTop: 10, display: 'block' }}
                      isHidden={infoHidden}
                    />
                    <ProfileDescriptionItem
                      keys="Financing"
                      item={profileInfo?.student.financing?.typeOfFinancing}
                      icon={<MoneyCollectOutlined />}
                      styles={{ marginTop: 10, display: 'block' }}
                      isHidden={infoHidden}
                    />
                  </Col>
                  <Col flex={12} style={{ marginTop: 10 }}>
                    <ProfileDescriptionItem
                      keys="Course"
                      item={profileInfo?.student.courseGrade}
                      icon={<BankOutlined />}
                      isHidden={infoHidden}
                    />
                    <ProfileDescriptionItem
                      keys="Status"
                      item={profileInfo?.student.statusTypeId.nameRu}
                      styles={{ marginTop: 10, display: 'block' }}
                      icon={<UserOutlined />}
                      isHidden={infoHidden}
                    />
                    <ProfileDescriptionItem
                      keys="Studying status"
                      item={profileInfo?.student.studyingStatusType.nameRu}
                      styles={{ marginTop: 10, display: 'block' }}
                      icon={<SolutionOutlined />}
                      isHidden={infoHidden}
                    />
                    <ProfileDescriptionItem
                      keys="Phone"
                      item={profileInfo?.studentPersonalInfoDtoResponse?.phone}
                      styles={{ marginTop: 10, display: 'block' }}
                      icon={<PhoneOutlined />}
                      isHidden={infoHidden}
                    />
                  </Col>
                  <Col flex={12} style={{ marginTop: 10 }}>
                    <ProfileDescriptionItem
                      keys="Academic mobility"
                      item={profileInfo?.student.isAcademicMobility ? 'Yes' : 'No'}
                      styles={{ marginTop: 10, display: 'block' }}
                      icon={<SwapOutlined />}
                      isHidden={infoHidden}
                    />
                    <ProfileDescriptionItem
                      keys="Retire"
                      item={profileInfo?.student.isInRetire ? 'Yes' : 'No'}
                      styles={{ marginTop: 10, display: 'block' }}
                      icon={<PauseCircleOutlined />}
                      isHidden={infoHidden}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col flex={0}>

            <AuthenticatedContent type='addRole' role={"STUDENT_DEPARTMENT"}>
              <Tooltip title="Edit information">
                <Button
                  icon={<EditOutlined />}
                  shape="circle"
                  size="large"
                  onClick={() => history.push(`/edit-student-info/${userId}`)}
                  style={{ marginRight: '10px' }}
                />
              </Tooltip>
            </AuthenticatedContent>

            <Tooltip title={infoHidden ? 'Display information' : 'Hide information'}>
              <Button
                icon={infoHidden ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                shape="circle"
                size="large"
                onClick={handleToggleInfoDisplay}
              ></Button>
            </Tooltip>
          </Col>
        </Row>
      </Card>

      <Card style={{ marginTop: 20, borderRadius: 15 }}>
        {!loading ? (
          <Tabs size="large" defaultActiveKey={getCurrentLocationForTab.current}>
            <Tabs.TabPane tab="Curriculum" key={'curriculum'}>
              <ProfileCurriculum userId={userId ? userId : undefined} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Details" key={'details'}>
              <ProfileDetails userId={userId ? userId : undefined} infoDetailsHidden={infoHidden} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Orders" key={'orders'}>
              <ProfileOrders studentId={profileInfo?.student.id ? profileInfo?.student.id : undefined} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="IGPA" key={'igpa'}>
              <ProfileGpa
                studentId={profileInfo?.student.id ? profileInfo?.student.id : undefined}
                userId={userId ? userId : undefined}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Transcript" key={'transcript'}>
              <TranscriptTable key={userId} userId={userId ? userId : undefined} userRerender={rerender} />
            </Tabs.TabPane>
          </Tabs>
        ) : (
          <Spinner size="default" />
        )}
      </Card>
    </PageWrapper>
  );
};

export default ProfilePage;
