import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography, Input, DatePicker, Form, Select } from 'antd';
import { Link } from 'react-router-dom';
import PageWrapper from '../../../ui/PageWrapper';
import useOrderPage from '../../../hooks/Order/useOrderPage';
import OrdersTable from './OrdersTable';
import OrderSelect from './OrderSelect';
import CustomModal from '../../../ui/Modal';
import useDictionary from '../../../hooks/useDictionary';

const { Title } = Typography;
const { Search } = Input;

const OrdersPage = () => {
  const {
    ordersTypes,
    search,
    numberSearch,
    onSearch,
    onNumberSearch,
    fromDate,
    toDate,
    loading,
    handleClear,
    handleSetFromDate,
    handleSetToDate,
    selectedOrderType,
    onSelectOrderType,
    handleDegreeSelect,
    form,
  } = useOrderPage();

  const { dictionary } = useDictionary({});

  return (
    <PageWrapper>
      <section>
        <header>
          <Row>
            <Col flex="100px">
              <Title level={3}>Приказы</Title>
            </Col>
            <Col flex="auto"></Col>
            <Col flex="100px">
              <Space>
                <Link to="/order-list/create">
                  <Button loading={loading} type="primary" icon={<PlusOutlined />}>
                    Создать приказ
                  </Button>
                </Link>
                <CustomModal btnTitle='Скачать Excel Контингента' title='Скачать Excel Контингента' onOk={form.submit}>
                  <Form form={form} onFinish={handleDegreeSelect}>
                    <Form.Item name="academicDegreeId" label="Степень образования">
                      <Select>
                        {dictionary.degreeList.map((degree) => (
                          <Select.Option key={degree.id} value={degree.id}>
                            {degree.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="date" label="Дата контингента">
                      <DatePicker format={"DD-MM-YYYY"} placeholder="Выберите дату" />
                    </Form.Item>
                  </Form>
                </CustomModal>
              </Space>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: '20px' }}>
            <Col span={14}>
              <Space direction="vertical">
                <Search placeholder="Поиск по названию приказа" enterButton onSearch={onSearch} loading={loading} />

                <Search placeholder="Поиск по номеру приказа" enterButton onSearch={onNumberSearch} loading={loading} />

                <OrderSelect
                  placeholder="Выберите приказ"
                  onSelect={(value) => onSelectOrderType(value)}
                  selectedOption={selectedOrderType}
                  options={[...ordersTypes]}
                  width={500}
                  onClear={() => {
                    handleClear();
                  }}
                />
                <Space dir={'horizontal'} size="large">
                  <Space align="start">
                    <Title level={5}>От</Title>
                    <DatePicker placeholder="Выберите дату" onChange={(e) => handleSetFromDate(e)} />
                  </Space>
                  <Space align="end">
                    <Title level={5}>До</Title>
                    <DatePicker placeholder="Выберите дату" onChange={(e) => handleSetToDate(e)} />
                  </Space>
                </Space>
                {/* <DataSelect
                  options={[
                    {
                      id: 1,
                      value: 1,
                      name: "Bachelor's degree",
                    },
                    {
                      id: 2,
                      value: 2,
                      name: "Master's degree",
                    },
                  ]}
                  value={selectedEducationalDegree}
                  onClear={() => {
                    setSelectedEducationalDegree(undefined);
                    setSelectedCourse(undefined);
                    setAreButtonsAvailable(false);
                    setTableVisible(false);
                  }}
                  placeholder={'Выберите степень образования'}
                  onSelect={handleEducationalDegreeSelect}
                  renderItems={(item) => item.name}
                />
                <DataSelect
                  options={courseOptions}
                  value={selectedCourse}
                  placeholder={'Выберите курс'}
                  onSelect={handleCourseSelect}
                  onClear={() => {
                    setSelectedCourse(undefined);
                    setTableVisible(false);
                    setAreButtonsAvailable(true);
                  }}
                  renderItems={(item) => item.year}
                /> */}
              </Space>
            </Col>
            <Col span={16}></Col>
          </Row>
        </header>
        <OrdersTable
          selectedOrderType={selectedOrderType}
          search={search}
          number={numberSearch}
          fromDate={fromDate}
          toDate={toDate}
        />
      </section>
    </PageWrapper>
  );
};

export default OrdersPage;
