import { Button, Dropdown, Space } from 'antd';
import StudentGroupFilter from './StudentGroupFilter';
import StudentsFilter from './StudentsFilter';
import DiplomaWorkFilter from './DiplomaWorkFilter/DiplomaWorkFilter';
import useDiplomaSupplementPage from './useDiplomaSupplementPage';
import Page from '../../../ui/Page/Page';
import { DownOutlined } from '@ant-design/icons';

const DiplomaSupplementPage = () => {
  const {
    groups,
    students,
    diplomaWorks,
    selectedGroupId,
    setSelectedGroupId,
    selectedUserId,
    setSelectedUserId,
    selectedDiplomaWorkId,
    setSelectedDiplomaWorkId,
    onGroupSelect,
    onStudentSelect,
    exportDiploma,
    loading,
    items,
  } = useDiplomaSupplementPage();

  const componentSize = 'large';

  return (
    <Page title="Diploma Supplement" width={800} center>
      {/* <div className={styles.mainContainer}> */}
      <Space size={componentSize} direction={'vertical'} style={{ width: '100%' }}>
        <StudentGroupFilter
          placeholder="Группа обучающихся"
          selectedOption={selectedGroupId}
          loading={loading}
          componentSize={componentSize}
          onSelect={(value) => onGroupSelect(value)}
          options={groups}
          onClear={() => {
            setSelectedGroupId(undefined);
            setSelectedUserId(undefined);
            setSelectedDiplomaWorkId(undefined);
          }}
        />

        <StudentsFilter
          placeholder="ФИО Студента"
          loading={loading}
          selectedOption={selectedUserId}
          onSelect={(value) => onStudentSelect(value)}
          componentSize={componentSize}
          options={students}
          onClear={() => {
            setSelectedUserId(undefined);
            setSelectedDiplomaWorkId(undefined);
          }}
        />

        <DiplomaWorkFilter
          placeholder="Дипломная работа"
          componentSize={componentSize}
          loading={loading}
          selectedOption={selectedDiplomaWorkId}
          onSelect={(value) => setSelectedDiplomaWorkId(value)}
          options={diplomaWorks}
          onClear={() => setSelectedDiplomaWorkId(undefined)}
        />
      </Space>
      <Space size={20} style={{ marginTop: 25 }} wrap></Space>
      <br />
      <br />
      <Space size={20}>
        <Button loading={loading} type={'primary'} size="large" onClick={exportDiploma}>
          Скачать диплом
        </Button>
        <Dropdown menu={{ items }} trigger={['click']}>
          <Button loading={loading} size={'large'} onClick={(e) => e.preventDefault()}>
            <Space>
              Скачать
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>

        {selectedGroupId && selectedUserId && selectedDiplomaWorkId && <Button></Button>}
      </Space>
      {/* </div> */}
    </Page>
  );
};

export default DiplomaSupplementPage;
