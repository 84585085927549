import Page from '../../../ui/Page'
import { Button, Form, Input, Modal, Result, Select, Space, Table, Tag, Upload } from 'antd'
import { useKpi } from './useKpi'
import CustomModal from '../../../ui/Modal'
import { getFullDate } from '../../../common/getFullDate'
import { TeacherKpiApplicationDtoResponse } from '../../../interfaces/Kpi/TeacherKpiApplicationDtoResponse'

const KpiApplicationPage = () => {

    const { data, handlers } = useKpi();

    const teacherColumns = [
        {
            title: '№',
            dataIndex: 'key',
            key: 'key',
            render: (text: any, record: TeacherKpiApplicationDtoResponse, index: number) => index + 1
        },
        {
            title: 'Teacher',
            dataIndex: 'teacher',
            key: 'teacher',
            render: (text: any, record: TeacherKpiApplicationDtoResponse) => record.teacher.surnameKz + " " + record.teacher.nameKz + " " + record.teacher.patronymicKz
        },
        {
            title: 'Category',
            dataIndex: 'kpiCategory',
            key: 'kpiCategory',
            render: (text: any, record: TeacherKpiApplicationDtoResponse) => record.kpiCategory.name,
            width: "50%"
        },
        {
            title: 'Created date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (text: any, record: TeacherKpiApplicationDtoResponse) => getFullDate(record.createdDate.toString())
        },
        {
            title: 'File',
            dataIndex: 'fileId',
            key: 'fileId',
            render: (text: any, record: TeacherKpiApplicationDtoResponse) => record.fileId && (
                <Button type='primary' onClick={() => handlers.handleDownloadFile(record.fileId)}>
                    Download file
                </Button>
            )
        },
        {
            title: 'Approved',
            dataIndex: 'approved',
            key: 'approved',
            render: (text: any, record: TeacherKpiApplicationDtoResponse, index: number) => record.approved !== null ? (
                record.approved === true ? (
                    <Tag color="green" key={index}>Approved</Tag>
                ) : (
                    <Button type='primary' key={index} danger onClick={() => handlers.handleOpenReasonModal(record)}>Show reason</Button>
                )
            ) : (
                <></>
            ),
        },
    ];

    return (
        <Page title='KPI Application' subtitle='Page for submitting applications for KPI'>
            {data.deadline ? (
                <Space direction='vertical' size={"large"} style={{ width: "100%" }}>
                    <CustomModal title='KPI Application' btnTitle='Create KPI Application' onOk={data.form.submit}>
                        <Form
                            size="large"
                            form={data.form}
                            onFinish={handlers.handleCreateApplication}>
                            <Form.Item rules={[{ required: true, message: 'Please, choose category' }]}>
                                <Select placeholder={"Choose category"} onSelect={handlers.handleChooseCategory}>
                                    {data.categories.map((category, i) => (
                                        <Select.Option key={i} value={category.id} >
                                            {category.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item rules={[{ required: true, message: 'Please, choose sub-category' }]} name={"kpiCategoryId"} >
                                <Select placeholder={"Choose sub-category"}>
                                    {data.subCategories.map((subCategory, i) => (
                                        <Select.Option key={i} value={subCategory.id}>
                                            {subCategory.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name={"url"}>
                                <Input placeholder={"Provide url"} />
                            </Form.Item>
                            <Form.Item name={"kpiFile"}>
                                <Upload  {...data.uploadProps}>
                                    <Button>Upload file</Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </CustomModal>
                    <Table bordered columns={teacherColumns} dataSource={data.kpiApplications} />
                    <Modal open={data.isReasonOpen} title="HR's reason of disagreement" onOk={() => data.setIsReasonOpen(false)} onCancel={() => data.setIsReasonOpen(false)}>
                        <Form form={data.reasonForm}>
                            <Form.Item name={"comment"}>
                                <Input.TextArea size="small" bordered={false} disabled autoSize={true} />
                            </Form.Item>
                        </Form>
                    </Modal>
                </Space>
            ) : (
                <Result
                    status="warning"
                    title="Application date closed"
                    subTitle="Please wait for commission verification."
                />
            )}

        </Page>
    )
}

export default KpiApplicationPage