import { DownloadOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Space, Table, Tag, Upload } from 'antd';
import Page from '../../../ui/Page/Page';
import RowSpan from '../../../ui/RowSpan';
import useIrosHook from './useIros';
import TextArea from 'antd/lib/input/TextArea';

const StudentIrosPage = () => {
  const { data, handlers, uploadProps } = useIrosHook();

  const columns = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Index status',
      dataIndex: 'indexStatus',
      key: 'indexStatus',
    },
    {
      title: 'Indicator',
      dataIndex: 'indicator',
      key: 'indicator',
    },
    {
      title: 'Created date',
      dataIndex: 'createdDate',
      key: 'createdDate',
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      render: (text: any, record: any) => (
        <Space size={'small'}>
          <Button type="default" icon={<DownloadOutlined />} onClick={() => handlers.handleDownloadFile(record.fileId)}>
            Download file
          </Button>
          {/* <Button type="default" icon={<EditOutlined />} onClick={() => { handlers.handleEditModalOpen(true, record) }}>
                        Edit
                    </Button> */}
        </Space>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'approved',
      key: 'approved',
      render: (_: any, record: any, idx: any) => {
        let color = record.approved === null ? '#ffc53d' : record.approved ? '#73d13d' : '#ff4d4f';
        let text = record.approved ? 'Approved' : record.approved === null ? 'Under review' : 'Rejected';

        switch (record.approved) {
          case false:
            return (
              <Button type="primary" danger onClick={() => handlers.handleRejectModal(record)}>
                Show reason | {text}
              </Button>
            );
          default:
            return (
              <Tag color={color} key={idx}>
                {text}
              </Tag>
            );
        }
      },
    },
  ];

  const handleIndicatorChange = (indicatorId: number) => {
    handlers.fetchStatusesByIndicatorId(indicatorId);
  };

  return (
    <Page title={'iROS'}>
      <Space direction="vertical" style={{ width: '100%' }} size={'large'}>
        <RowSpan
          right={
            <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => handlers.setIsOpen(true)}>
                Add
              </Button>
              <Modal
                onCancel={() => handlers.setIsOpen(false)}
                onOk={data.form.submit}
                open={data.isOpen}
                title={'Create iROS application'}
              >
                <Form form={data.form} onFinish={handlers.handleCreateStudentApplication}>
                  <Form.Item name={'indicator'} rules={[{ required: true }]}>
                    <Select
                      placeholder={'Select the indicator'}
                      loading={data.loading}
                      onChange={handleIndicatorChange}
                    >
                      {data.indicators.map(indicator => (
                        <Select.Option key={indicator.id} value={indicator.id}>
                          {indicator.nameEn}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name={'indexStatus'} rules={[{ required: true }]}>
                    <Select placeholder={'Select status'} loading={data.loading}>
                      {data.statuses.map(status => (
                        <Select.Option key={status.id} value={status.id}>
                          {status.nameEn}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name={'fileId'} rules={[{ required: true }]}>
                    <Upload {...uploadProps} accept={'.pdf'} maxCount={1}>
                      <Button icon={<UploadOutlined />}>Upload file</Button>
                    </Upload>
                  </Form.Item>
                </Form>
              </Modal>
            </Space>
          }
        />

        <Modal
          onCancel={() => handlers.handleEditModalOpen(false)}
          onOk={data.form.submit}
          open={data.isEditOpen}
          title={'Edit iROS application'}
        >
          <Form form={data.form} onFinish={handlers.handleCreateStudentApplication}>
            <Form.Item name={'indicator'} rules={[{ required: true }]}>
              <Select
                placeholder={'Select the indicator'}
                loading={data.loading}
                onChange={handleIndicatorChange}
              >
                {data.indicators.map(indicator => (
                  <Select.Option key={indicator.id} value={indicator.id}>
                    {indicator.nameEn}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={'indexStatus'} rules={[{ required: true }]}>
              <Select placeholder={'Select status'} loading={data.loading}>
                {data.statuses.map(status => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.nameEn}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={'fileId'} rules={[{ required: true }]}>
              <Upload {...uploadProps} accept={'.pdf'} maxCount={1}>
                <Button icon={<UploadOutlined />}>Upload file</Button>
              </Upload>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          open={data.modal.isRejectModalOpen}
          title={'Reason'}
          onCancel={() => data.modal.setIsRejectModalOpen(false)}
          onOk={data.form.submit}
        >
          <TextArea value={data.modal.modalValue?.comment} readOnly />
        </Modal>

        <Table
          pagination={{ defaultPageSize: 10, total: data.totalTableDataForOffice }}
          columns={columns}
          dataSource={data.tableDataForStudent.map((data, i) => ({
            key: i + 1,
            indexStatus: data.indexStatus.name,
            indicator: data.indicator.name,
            createdDate: data.createdDate,
            fileId: data.fileId,
            comment: data.comment,
            approved: data.approved,
          }))}
          onChange={(pagination) => data.setCurrentPage(pagination.current)}
          loading={data.loading}
          scroll={{ x: 1000 }}
          bordered
        />
      </Space>
    </Page>
  );
};

export default StudentIrosPage;
