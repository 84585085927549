import Title from 'antd/es/typography/Title';
import { Alert, Select, Space, Table, Typography } from 'antd';
import './appointment.css';
import usePsychologistAppointmentPage from './usePsychologistAppointmentPage';
import Page from '../../../ui/Page';
import useFetchData from '../../../hooks/useFetchData';
import { UserDtoResponse } from '../../../interfaces/UserDtoResponse';
import { PsychologistService } from '../../../services/PsychologistService';

const PsychologicalAppointmentPage = () => {
  const { columns, data, handleSelectPsychologist } = usePsychologistAppointmentPage();
  const { data: psychologists } = useFetchData<UserDtoResponse[]>(PsychologistService.getPsychologists, true)

  return (
    <Page title='Appointment with a psychologist'>
      <Space direction='vertical'>
        <Alert style={{ width: "100%" }} message='В неделю вы можете записатся только один раз.' type="info" />
        <Select
          placeholder="Select psychologist"
          options={psychologists?.map((psychologist) => ({ label: psychologist.surname + " " + psychologist.name, value: psychologist.id })) || []}
          style={{ width: "100%" }}
          onSelect={(value) => handleSelectPsychologist(value)}
        />
      </Space>
      {/* <Title level={4}>Приемы психолога временно проводятся онлайн. Можете записаться через телеграм @yuten_psy </Title> */}

      {data.dates && data.dates.length > 0 && (
        <Space direction='vertical'
          style={{
            width: "100%"
          }}
        >
          <Typography.Text code>Почта: {psychologists?.find((v) => v.id === data.selectedPsychologist)?.username}</Typography.Text>
          <Table
            bordered
            showHeader={false}
            scroll={{ x: 1000 }}
            columns={columns.appointmentsColumns}
            dataSource={data.dates.map((date, i) => ({
              key: i,
              weekDay: date.weekDay,
              date: date.date,
              times: date.times,
            }))}
            rowKey={(record) => record.key}
          />
          <Title level={3}>My Appointments</Title>
          <Table
            columns={columns.userAppointmentsColumns} scroll={{ x: 1000 }}
            dataSource={(data.filteredUserAppointments).reverse()}
            rowKey={(record) => record.id}
          />
        </Space>
      )}
    </Page>
  );
};

export default PsychologicalAppointmentPage;
