import { FinancingSourceDto } from "./FinancingSourceDto";
import { ForeignUniversitiesDtoResponse } from "./ForeignUniversitiesDtoResponse";
import { StudentDtoResponse } from "./Student/StudentDtoResponse";

export enum AcademicMobilityCompetitionStatusEnum {
    DRAFT = 'draft',
    OPEN = 'open',
    CHECKING = 'checking',
    COMMISSION = 'commission',
    CLOSED = 'closed'
}

export interface AcademicMobilityCompetitionStatusDto {
    id: number;
    name: string;
    nameEn: string;
    nameRu: string;
    prefix: string;
}

export interface AcademicMobilityCompetitionDtoRequest {
    id: number;
    universityId: number;
    deadline: Date;
    studyStartAt: Date;
    studyEndAt: Date;
    quota: number;
    statusId: number;
    studyStartTerm: number;
    studyEndTerm: number;
    financingSourceId: number;
}

export interface AcademicMobilityCompetitionDtoResponse {
    id: number;
    university: ForeignUniversitiesDtoResponse;
    deadline: Date;
    studyStartAt: Date;
    studyEndAt: Date;
    quota: number;
    status: AcademicMobilityCompetitionStatusDto;
    studyStartTerm: number;
    studyEndTerm: number;
    financingSource: FinancingSourceDto;
    createdAt: Date;
    updatedAt: Date;
}

export interface StudentAcademicMobilityCompetitionInfoDtoRequest {
    value: string;
    infoTypeId: number;
}

export interface StudentAcademicMobilityCompetitionInfoTypeDto extends AcademicMobilityCompetitionStatusDto {
    dataType: string;
    required: boolean;
    orderNumber: number;
    studentField: boolean;
}

export interface StudentAcademicMobilityCompetitionDto {
    id: number;
    student: StudentDtoResponse;
    competition: AcademicMobilityCompetitionDtoResponse;
    status: AcademicMobilityCompetitionStatusDto;
    comment: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface StudentAcademicMobilityCompetitionInfoDto {
    id: number;
    type: StudentAcademicMobilityCompetitionInfoTypeDto;
    value: string;
    createdAt: Date;
    updatedAt: Date;
}