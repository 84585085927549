import {Spin} from "antd";

interface SpinnerProps {
    size: "small" | "large" | "default"
}

export default function Spinner({size}: SpinnerProps) {
    return (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%', margin: '100px 0'}}>
            <Spin size={size ?? "default"}/>
        </div>
    )
}