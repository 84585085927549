import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select, Space, Spin, Table, List, Tabs, Typography, InputNumber, Tag, Dropdown } from 'antd';
import React, { useCallback, useState } from 'react';
import { successNotification } from '../../../../helpers/successNotification';
import useFetchData from '../../../../hooks/useFetchData';
import { SyllabusDataDraftRequest } from '../../../../interfaces/Syllabus/SyllabusDataDraftRequest';
import { CustomData } from '../../../../interfaces/Syllabus/SyllabusDataDto';
import { SyllabusDraftDataDto } from '../../../../interfaces/Syllabus/SyllabusDraftDataDto';
import { SyllabusService } from '../../../../services/SyllabusService';
import CustomModal from '../../../../ui/Modal';
import useModal from '../../../../ui/Modal/useModal';
import Page from '../../../../ui/Page';
import RowSpan from '../../../../ui/RowSpan';
import SyllabusFieldRenderer from './SyllabusFieldRenderer';

interface Props {
    id: number;
}

const NoData = () => <Typography.Text type="secondary">Нет данных</Typography.Text>;

const backgroundStyle = (action: string) => {
    switch (action) {
        case "d":
            return "#800f19";
        case "u":
            return "#15395b";
        case "i":
            return "#274916";
        default:
            return "transparent";
    }

}

const SyllabusDraftPage = ({ id }: Props) => {
    const callbackSyllabus = useCallback(() => SyllabusService.getSyllabusDraftDataById(id), [id]);
    const { data: syllabusData, fetchData: fetchSyllabusData, isLoading } = useFetchData<SyllabusDraftDataDto>(callbackSyllabus, true)

    const [secondSection, setSecondSection] = React.useState<SyllabusDataDraftRequest | SyllabusDataDraftRequest[]>();
    const [currentItems, setCurrentItems] = useState<CustomData[]>([]);
    const [currentItem, setCurrentItem] = useState<CustomData | undefined>(undefined);
    const { isModalOpen, showModal, handleCancel } = useModal();
    const { isModalOpen: isEditModalOpen, showModal: showEditModal, handleCancel: handleEditCancel } = useModal();

    const handleSave = (data?: SyllabusDataDraftRequest) => {
        data && SyllabusService.manipulateSyllabusDraftData(data)
            .then(() => {
                successNotification("Saved!")
                fetchSyllabusData();
            })
            .finally(() => {
                handleCancelModals();
            })
    }

    const handleDelete = (data: CustomData) => {
        let deleteInfo: SyllabusDataDraftRequest = {
            id: data.id,
            draftId: id,
            order: data.order,
            value: data.value,
            syllabusPrefixId: data.prefix.id,
            action: 'd',
        }

        SyllabusService.manipulateSyllabusDraftData(deleteInfo)
            .then(() => {
                successNotification("Deleted!")
            })
            .finally(() => {
                fetchSyllabusData();
                handleCancelModals();
            })
    }

    const handleUndo = (data: CustomData | undefined) => {
        data && SyllabusService.undoSyllabusAction(data.id)
            .then(() => {
                successNotification("Undo!")
                fetchSyllabusData();
            })
    }

    const handleCreateModalOpen = (sectionItems: CustomData[] | undefined) => {
        showModal();
        sectionItems && setCurrentItems(sectionItems);
    }

    const handleEditModalOpen = (sectionItem: CustomData | undefined) => {
        showEditModal();
        sectionItem && setCurrentItem(sectionItem);
        sectionItem && setSecondSection({
            id: sectionItem.id,
            draftId: id,
            order: sectionItem.order,
            syllabusPrefixId: sectionItem.prefix.id,
            action: 'u',
            value: sectionItem.value,
        })
    }

    const handleCancelModals = () => {
        handleEditCancel();
        handleCancel();
        setSecondSection(undefined);
        setCurrentItems([]);
        setCurrentItem(undefined);
    }

    return (
        <Page
            title={"Syllabus of the course"}
        >
            <Space>
                <Tag color="red">This color means that item is Deleted</Tag>
                <Tag color="green">This color means that item is Inserted</Tag>
                <Tag color="blue">This color means that item is Updated</Tag>
            </Space>
            {currentItem && (
                <Modal
                    open={isEditModalOpen}
                    onOk={() => handleSave(secondSection as SyllabusDataDraftRequest)}
                    onCancel={handleCancelModals}
                    destroyOnClose
                    closeIcon={<></>}
                >
                    <Input.TextArea
                        style={{ height: 400 }}
                        value={(secondSection as SyllabusDataDraftRequest)?.value}
                        onChange={(e) => {
                            setSecondSection({
                                id: currentItem?.id || 0,
                                draftId: id || 0,
                                order: currentItem?.order || 0,
                                syllabusPrefixId: currentItem?.prefix.id || 0,
                                action: 'u',
                                value: e.target.value,
                            })
                        }}
                    />
                </Modal>
            )}

            {currentItems && (
                <Modal
                    open={isModalOpen}
                    onOk={() => handleSave(secondSection as SyllabusDataDraftRequest)}
                    onCancel={handleCancelModals}
                    destroyOnClose
                    closeIcon={<></>}
                >
                    <Space direction={"vertical"} style={{ width: "100%" }}>
                        {currentItems[0]?.prefix?.prefix === "supplementary_literature" ||
                            currentItems[0]?.prefix?.prefix === "basic_literature" ? (
                            <>
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Please, select literature type"
                                    onSelect={(value) => {
                                        setSecondSection({
                                            draftId: id || 0,
                                            order: currentItems.filter(item => item.prefix.id === value).sort((a, b) => b.order - a.order)[0].order + 1 || 0,
                                            action: 'i',
                                            syllabusPrefixId: value,
                                        })
                                    }}
                                >
                                    <Select.Option value={8}>Basic literature</Select.Option>
                                    <Select.Option value={9}>Supplementary literature</Select.Option>
                                </Select>
                                <Input
                                    placeholder='Write literature name here...'
                                    onChange={(e) => {
                                        setSecondSection((prevState) => ({
                                            ...prevState,
                                            draftId: id || 0,
                                            action: 'i',
                                            value: e.target.value,
                                            parentId: 132 // Reading list id
                                        }))
                                    }}
                                />
                            </>
                        ) : (
                            <Input
                                placeholder='Write here...'
                                onChange={(e) => {
                                    setSecondSection({
                                        draftId: id || 0,
                                        order: currentItems[currentItems.length - 1].order + 1 || 0,
                                        syllabusPrefixId: currentItems[currentItems.length - 1]?.prefix.id || 0,
                                        action: 'i',
                                        value: e.target.value,
                                    })
                                }}
                            />
                        )}
                    </Space>
                </Modal>
            )}
            <Tabs defaultActiveKey="1" tabPosition={'top'}>
                {/* <Tabs.TabPane key="1" tab="General information">
                    <Space direction={'vertical'} size={'small'}>
                        <Descriptions title={"General information"}>
                             <Descriptions.Item label="Course Title">{`${syllabusData?.syllabus?.discipline?.titleEn}` || <NoData />}</Descriptions.Item>
                            <Descriptions.Item label="Department">{`${syllabusData?.syllabus.discipline.department.titleEn}` || <NoData />}</Descriptions.Item>
                            <Descriptions.Item label="Degree Cycle (Level)/ Major">{`${syllabusData?.syllabus.discipline.academicDegree.titleEn}`}</Descriptions.Item>
                            <Descriptions.Item label="Year">{`${syllabusData?.syllabus.academicYear}`}</Descriptions.Item>
                            <Descriptions.Item label="Number of Credits">{syllabusData?.syllabus.discipline.volumeCredits || <NoData />}</Descriptions.Item>
                            <Descriptions.Item label="Language of Delivery">{dictionary.languageList.find(v => v.id === syllabusData?.syllabus.lang)?.value}</Descriptions.Item> 
                        </Descriptions>
                    </Space>
                </Tabs.TabPane> */}

                <Tabs.TabPane key="1" tab="Goals, objectives and learning outcomes of the course">
                    <Tabs tabPosition={'left'}>
                        <Tabs.TabPane key="1" tab="Course Description">
                            {isLoading ? <Spin /> : (
                                <SyllabusFieldRenderer
                                    type="input-textarea"
                                    sectionItem={syllabusData?.secondSection.courseDescription}
                                    handleEditModalOpen={handleEditModalOpen}
                                    handleDelete={handleDelete}
                                    handleCreateModalOpen={handleCreateModalOpen}
                                    handleUndo={handleUndo}
                                />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane key="2" tab="Course Goal">
                            {isLoading ? <Spin /> : (
                                <SyllabusFieldRenderer
                                    type="input-textarea"
                                    sectionItem={syllabusData?.secondSection.courseGoal}
                                    handleEditModalOpen={handleEditModalOpen}
                                    handleDelete={handleDelete}
                                    handleCreateModalOpen={handleCreateModalOpen}
                                    handleUndo={handleUndo}
                                />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane key="3" tab="Course Objectives">
                            {isLoading ? <Spin /> : (
                                <SyllabusFieldRenderer
                                    type="list"
                                    sectionItems={syllabusData?.secondSection?.courseObjectives}
                                    handleEditModalOpen={handleEditModalOpen}
                                    handleDelete={handleDelete}
                                    handleCreateModalOpen={handleCreateModalOpen}
                                    handleUndo={handleUndo}
                                />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane key="4" tab="Skills and Competences">
                            {isLoading ? <Spin /> : (
                                <SyllabusFieldRenderer
                                    type="list"
                                    sectionItems={syllabusData?.secondSection?.skillsAndCompetences}
                                    handleEditModalOpen={handleEditModalOpen}
                                    handleDelete={handleDelete}
                                    handleCreateModalOpen={handleCreateModalOpen}
                                    handleUndo={handleUndo}
                                />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane key="5" tab="Learning Outcomes">
                            {isLoading ? <Spin /> : (
                                <SyllabusFieldRenderer
                                    type="list"
                                    sectionItems={syllabusData?.secondSection?.learningOutcomes}
                                    handleEditModalOpen={handleEditModalOpen}
                                    handleDelete={handleDelete}
                                    handleCreateModalOpen={handleCreateModalOpen}
                                    handleUndo={handleUndo}
                                />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane key="6" tab="Teaching Methods">
                            {isLoading ? <Spin /> : (
                                <SyllabusFieldRenderer
                                    type="input-textarea"
                                    sectionItem={syllabusData?.secondSection?.teachingMethods}
                                    handleEditModalOpen={handleEditModalOpen}
                                    handleDelete={handleDelete}
                                    handleCreateModalOpen={handleCreateModalOpen}
                                    handleUndo={handleUndo}
                                />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane key="7" tab="Assessment Methods">
                            {isLoading ? <Spin /> : (
                                <SyllabusFieldRenderer
                                    type="input-textarea"
                                    sectionItem={syllabusData?.secondSection?.assessmentMethods}
                                    handleEditModalOpen={handleEditModalOpen}
                                    handleDelete={handleDelete}
                                    handleCreateModalOpen={handleCreateModalOpen}
                                    handleUndo={handleUndo}
                                />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane key="8" tab="Reading List">
                            {isLoading ? <Spin /> : (
                                <SyllabusFieldRenderer
                                    type="reading-list"
                                    sectionItems={syllabusData?.secondSection?.readingList?.linkedData}
                                    handleEditModalOpen={handleEditModalOpen}
                                    handleDelete={handleDelete}
                                    handleCreateModalOpen={handleCreateModalOpen}
                                    handleUndo={handleUndo}
                                />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane key="9" tab="Resources">
                            {isLoading ? <Spin /> : (
                                <SyllabusFieldRenderer
                                    type="list"
                                    sectionItems={syllabusData?.secondSection?.resources}
                                    handleEditModalOpen={handleEditModalOpen}
                                    handleDelete={handleDelete}
                                    handleCreateModalOpen={handleCreateModalOpen}
                                    handleUndo={handleUndo}
                                />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane key="10" tab="Course Policy">
                            <Space direction={'vertical'} size={'small'}>
                                <Typography.Text>{syllabusData?.secondSection.coursePolicy || <NoData />}</Typography.Text>
                            </Space>
                        </Tabs.TabPane>
                    </Tabs>
                </Tabs.TabPane>

                <Tabs.TabPane key="2" tab="Course Content">
                    <Tabs tabPosition={'left'}>
                        <Tabs.TabPane key="1" tab="Abbreviations">
                            <Space direction={'vertical'} size={'small'}>
                                <NoData />
                            </Space>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="2" tab="Lesson Plans">
                            <Space direction={'vertical'} style={{ width: "100%" }}>
                                <RowSpan
                                    right={
                                        <Button icon={<PlusOutlined />} onClick={() => {
                                            handleSave({
                                                draftId: id,
                                                order: (syllabusData?.thirdSection.lessonPlans.sort((a, b) => b.order - a.order)[0]?.order ?? 0) + 1,
                                                value: String(Number(syllabusData?.thirdSection.lessonPlans.sort((a, b) => b.order - a.order)[0]?.value ?? 0) + 1),
                                                syllabusPrefixId: (syllabusData?.thirdSection.lessonPlans.sort((a, b) => b.order - a.order)[0]?.prefix.id ?? 0),
                                                action: 'i',
                                            })
                                        }}>
                                            Add
                                        </Button>
                                    }
                                />
                                {isLoading
                                    ? <Spin />
                                    : <Table
                                        bordered
                                        dataSource={syllabusData?.thirdSection.lessonPlans.sort((a, b) => a.order - b.order)}
                                        rowClassName={(record: CustomData) => record.action === 'd' ? 'deleted-row' : ''}
                                        pagination={false}
                                        rowKey={i => i.id}
                                        columns={[
                                            {
                                                title: 'Week',
                                                width: "50px",
                                                dataIndex: 'value',
                                                key: 'value'
                                            },
                                            {
                                                title: 'Course Topic',
                                                dataIndex: 'linkedData',
                                                key: 'linkedData',
                                                render(text: string, record: CustomData) {
                                                    return record.linkedData?.sort((a, b) => a.order - b.order).map((item) => (
                                                        <List
                                                            header={<Typography.Text>{item.value}</Typography.Text>}
                                                            size='large'
                                                            key={item.id}
                                                            rowKey={i => i.id}
                                                            dataSource={item.linkedData?.sort((a, b) => a.order - b.order)}
                                                            renderItem={(item) => (
                                                                <List.Item style={{ cursor: "pointer" }}>
                                                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "20px" }}>
                                                                        <Input
                                                                            style={{ cursor: "pointer", background: backgroundStyle(item.action) }}
                                                                            defaultValue={item?.value ?? ''}
                                                                            readOnly
                                                                        />
                                                                        <Dropdown
                                                                            menu={{
                                                                                onClick: ({ key }) => {
                                                                                    switch (key) {
                                                                                        case "edit":
                                                                                            handleEditModalOpen(item);
                                                                                            break;
                                                                                        case "delete":
                                                                                            handleDelete(item);
                                                                                            break;
                                                                                        case "undo":
                                                                                            handleUndo(item)
                                                                                            break;
                                                                                    }
                                                                                },
                                                                                items: [
                                                                                    { key: "edit", label: "Edit" },
                                                                                    { key: "delete", label: "Delete" },
                                                                                    { key: "undo", label: "Undo" }
                                                                                ]
                                                                            }}>
                                                                            <Space>
                                                                                Action
                                                                                <DownOutlined />
                                                                            </Space>
                                                                        </Dropdown>
                                                                    </div>
                                                                </List.Item>
                                                            )}
                                                        />
                                                    ))
                                                }
                                            },
                                            {
                                                dataIndex: "add",
                                                key: "add",
                                                width: "50px",
                                                render(text: string, record: CustomData) {
                                                    return (
                                                        <Space>
                                                            <Dropdown
                                                                menu={{
                                                                    onClick: ({ key }) => {
                                                                        switch (key) {

                                                                            case "delete":
                                                                                handleDelete(record);
                                                                                break;
                                                                            case "undo":
                                                                                handleUndo(record)
                                                                                break;
                                                                        }
                                                                    },
                                                                    items: [
                                                                        {
                                                                            key: "add", label: (
                                                                                <CustomModal
                                                                                    btnType='text'
                                                                                    title='Add lecture/seminar'
                                                                                    btnTitle='Add lecture/seminar'
                                                                                    btnIcon={<></>}
                                                                                    onOk={() => {
                                                                                        handleSave(secondSection as SyllabusDataDraftRequest)
                                                                                    }}
                                                                                >
                                                                                    <Space direction={"vertical"} style={{ width: "100%" }}>
                                                                                        <Select
                                                                                            style={{ width: "100%" }}
                                                                                            placeholder="Please, select lesson type"
                                                                                            onSelect={(value) => {
                                                                                                if (!record.linkedData?.map(data => data.value)?.includes("Seminar")) {
                                                                                                    handleSave({
                                                                                                        draftId: id,
                                                                                                        order: 2,
                                                                                                        value: "Seminar",
                                                                                                        syllabusPrefixId: 12,
                                                                                                        action: 'i',
                                                                                                        parentId: record.id
                                                                                                    })
                                                                                                }
                                                                                                if (!record.linkedData?.map(data => data.value)?.includes("Lecture")) {
                                                                                                    handleSave({
                                                                                                        draftId: id,
                                                                                                        order: 1,
                                                                                                        value: "Lecture",
                                                                                                        syllabusPrefixId: 12,
                                                                                                        action: 'i',
                                                                                                        parentId: record.id
                                                                                                    })
                                                                                                }
                                                                                                setSecondSection({
                                                                                                    draftId: id,
                                                                                                    action: "i",
                                                                                                    parentId: record.linkedData?.find(v => v.value === value)?.id,
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            <Select.Option value={"Seminar"}>Seminar</Select.Option>
                                                                                            <Select.Option value={"Lecture"}>Lecture</Select.Option>
                                                                                        </Select>
                                                                                        <Input
                                                                                            placeholder='Write literature name here...'
                                                                                            onChange={(e) => {
                                                                                                setSecondSection((prevState) => ({
                                                                                                    ...prevState,
                                                                                                    draftId: id || 0,
                                                                                                    action: 'i',
                                                                                                    syllabusPrefixId: 13,
                                                                                                    value: e.target.value,
                                                                                                }))
                                                                                            }}
                                                                                        />
                                                                                    </Space>
                                                                                </CustomModal>
                                                                            )
                                                                        },
                                                                        { key: "delete", label: "Delete" },
                                                                        { key: "undo", label: "Undo" }
                                                                    ]
                                                                }}>
                                                                <Space>
                                                                    Action
                                                                    <DownOutlined />
                                                                </Space>
                                                            </Dropdown>
                                                        </Space>
                                                    )
                                                }
                                            },
                                        ]}
                                    />
                                }
                            </Space>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="3" tab="Assignments for SIS">
                            <Space direction={'vertical'} style={{ width: "100%" }}>
                                <RowSpan
                                    right={
                                        <Button icon={<PlusOutlined />} onClick={() => {
                                            handleSave({
                                                draftId: id,
                                                order: (syllabusData?.thirdSection.assignmentsForSIS.sort((a, b) => b.order - a.order)[0]?.order ?? 0) + 1,
                                                value: String(Number(syllabusData?.thirdSection.assignmentsForSIS.sort((a, b) => b.order - a.order)[0]?.value ?? 0) + 1),
                                                syllabusPrefixId: (syllabusData?.thirdSection.assignmentsForSIS.sort((a, b) => b.order - a.order)[0]?.prefix.id ?? 0),
                                                action: 'i',
                                            })
                                        }}>
                                            Add
                                        </Button>
                                    }
                                />
                                <Table
                                    bordered
                                    dataSource={syllabusData?.thirdSection.assignmentsForSIS.sort((a, b) => a.order - b.order)}
                                    pagination={false}
                                    rowKey={i => i.id}
                                    rowClassName={(record: CustomData) => record.action === 'd' ? 'deleted-row' : ''}
                                    columns={[
                                        {
                                            title: "№",
                                            dataIndex: "key",
                                            width: "50px",
                                            key: "key",
                                            render(text: string, record: CustomData, index: number) {
                                                return index + 1;
                                            },
                                        },
                                        {
                                            title: 'SIS Name',
                                            dataIndex: 'sis_name',
                                            width: "25%",
                                            key: 'sis_name',
                                            render(text: string, record: CustomData) {
                                                return <List
                                                    dataSource={record.linkedData?.filter((item) => item.prefix.prefix === 'sis_name').sort((a, b) => a.order - b.order) || []}
                                                    rowKey={i => i.id}
                                                    renderItem={(item) => (
                                                        <List.Item style={{ cursor: "pointer" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "20px" }}>
                                                                <Input
                                                                    style={{ cursor: "pointer", background: backgroundStyle(item.action) }}
                                                                    defaultValue={item?.value ?? ''}
                                                                    readOnly
                                                                />
                                                                <Dropdown
                                                                    menu={{
                                                                        onClick: ({ key }) => {
                                                                            switch (key) {
                                                                                case "edit":
                                                                                    handleEditModalOpen(item);
                                                                                    break;
                                                                                case "delete":
                                                                                    handleDelete(item);
                                                                                    break;
                                                                                case "undo":
                                                                                    handleUndo(item)
                                                                                    break;
                                                                            }
                                                                        },
                                                                        items: [
                                                                            { key: "edit", label: "Edit" },
                                                                            { key: "delete", label: "Delete" },
                                                                            { key: "undo", label: "Undo" }
                                                                        ]
                                                                    }}>
                                                                    <Space>
                                                                        Action
                                                                        <DownOutlined />
                                                                    </Space>
                                                                </Dropdown>
                                                            </div>
                                                        </List.Item>
                                                    )}
                                                />
                                            }
                                        },
                                        {
                                            title: 'SIS Submission form',
                                            dataIndex: 'sis_submission_form',
                                            width: "25%",
                                            key: 'sis_submission_form',
                                            render(text: string, record: CustomData) {
                                                return <List
                                                    dataSource={record.linkedData?.filter((item) => item.prefix.prefix === 'sis_submission_form').sort((a, b) => a.order - b.order) || []}
                                                    rowKey={i => i.id}
                                                    renderItem={(item) => (
                                                        <List.Item style={{ cursor: "pointer" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "20px" }}>
                                                                <Input
                                                                    style={{ cursor: "pointer", background: backgroundStyle(item.action) }}
                                                                    defaultValue={item?.value ?? ''}
                                                                    readOnly
                                                                />
                                                                <Dropdown
                                                                    menu={{
                                                                        onClick: ({ key }) => {
                                                                            switch (key) {
                                                                                case "edit":
                                                                                    handleEditModalOpen(item);
                                                                                    break;
                                                                                case "delete":
                                                                                    handleDelete(item);
                                                                                    break;
                                                                                case "undo":
                                                                                    handleUndo(item)
                                                                                    break;
                                                                            }
                                                                        },
                                                                        items: [
                                                                            { key: "edit", label: "Edit" },
                                                                            { key: "delete", label: "Delete" },
                                                                            { key: "undo", label: "Undo" }
                                                                        ]
                                                                    }}>
                                                                    <Space>
                                                                        Action
                                                                        <DownOutlined />
                                                                    </Space>
                                                                </Dropdown>
                                                            </div>
                                                        </List.Item>
                                                    )}
                                                />
                                            }
                                        },
                                        {
                                            title: 'SIS Link',
                                            dataIndex: 'sis_link',
                                            width: "25%",
                                            key: 'sis_link',
                                            render(text: string, record: CustomData) {
                                                return <List
                                                    dataSource={record.linkedData?.filter((item) => item.prefix.prefix === 'sis_link').sort((a, b) => a.order - b.order) || []}
                                                    rowKey={i => i.id}
                                                    renderItem={(item) => (
                                                        <List.Item style={{ cursor: "pointer" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "20px" }}>
                                                                <Input
                                                                    style={{ cursor: "pointer", background: backgroundStyle(item.action) }}
                                                                    defaultValue={item?.value ?? ''}
                                                                    readOnly
                                                                />
                                                                <Dropdown
                                                                    menu={{
                                                                        onClick: ({ key }) => {
                                                                            switch (key) {
                                                                                case "edit":
                                                                                    handleEditModalOpen(item);
                                                                                    break;
                                                                                case "delete":
                                                                                    handleDelete(item);
                                                                                    break;
                                                                                case "undo":
                                                                                    handleUndo(item)
                                                                                    break;
                                                                            }
                                                                        },
                                                                        items: [
                                                                            { key: "edit", label: "Edit" },
                                                                            { key: "delete", label: "Delete" },
                                                                            { key: "undo", label: "Undo" }
                                                                        ]
                                                                    }}>
                                                                    <Space>
                                                                        Action
                                                                        <DownOutlined />
                                                                    </Space>
                                                                </Dropdown>
                                                            </div>
                                                        </List.Item>
                                                    )}
                                                />
                                            }
                                        },
                                        {
                                            dataIndex: "add",
                                            key: "add",
                                            width: "50px",
                                            render(text: string, record: CustomData) {
                                                return (
                                                    <Space>
                                                        <Dropdown
                                                            menu={{
                                                                onClick: ({ key }) => {
                                                                    switch (key) {
                                                                        case "delete":
                                                                            handleDelete(record);
                                                                            break;
                                                                        case "undo":
                                                                            handleUndo(record)
                                                                            break;
                                                                    }
                                                                },
                                                                items: [
                                                                    {
                                                                        key: "add", label: (
                                                                            <CustomModal
                                                                                title='Add SIS'
                                                                                btnTitle='Add SIS'
                                                                                btnIcon={<></>}
                                                                                btnType='text'
                                                                                onOk={() => {
                                                                                    handleSave(secondSection as SyllabusDataDraftRequest)
                                                                                }}
                                                                            >
                                                                                <Space direction={"vertical"} style={{ width: "100%" }}>
                                                                                    <Select
                                                                                        style={{ width: "100%" }}
                                                                                        placeholder="Please, select type"
                                                                                        onSelect={(value) => {
                                                                                            setSecondSection({
                                                                                                draftId: id,
                                                                                                action: "i",
                                                                                                order: (record?.linkedData?.filter(sys => sys.prefix.id === Number(value)).sort((a, b) => b.order - a.order)[0]?.order ?? 0) + 1 || 1,
                                                                                                syllabusPrefixId: value
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <Select.Option value={"17"}>SIS Name</Select.Option>
                                                                                        <Select.Option value={"19"}>SIS Submission Form</Select.Option>
                                                                                        <Select.Option value={"18"}>SIS Link</Select.Option>
                                                                                    </Select>
                                                                                    <Input
                                                                                        placeholder='Write SIS info here...'
                                                                                        onChange={(e) => {
                                                                                            setSecondSection((prevState) => ({
                                                                                                ...prevState,
                                                                                                parentId: record.id,
                                                                                                value: e.target.value,
                                                                                            }))
                                                                                        }}
                                                                                    />
                                                                                </Space>
                                                                            </CustomModal>
                                                                        ),
                                                                    },
                                                                    { key: "delete", label: "Delete" },
                                                                    { key: "undo", label: "Undo" }
                                                                ]
                                                            }}>
                                                            <Space>
                                                                Action
                                                                <DownOutlined />
                                                            </Space>
                                                        </Dropdown>
                                                    </Space>
                                                )
                                            }
                                        },
                                    ]}
                                />
                            </Space>
                        </Tabs.TabPane>
                    </Tabs>
                </Tabs.TabPane>

                <Tabs.TabPane key="3" tab="Student performance evaluation system for the course">
                    <Tabs tabPosition={'left'}>
                        <Tabs.TabPane key="1" tab="Grade criteria and scale">
                            <Space direction={'vertical'} style={{ width: "100%" }}>
                                <RowSpan
                                    right={
                                        <CustomModal
                                            title='Add grade criteria'
                                            btnTitle='Add'
                                            onOk={() => {
                                                handleSave({
                                                    draftId: id,
                                                    syllabusPrefixId: 26,
                                                    action: 'i',
                                                    ...secondSection,
                                                })
                                            }}
                                        >
                                            <Space direction={'vertical'} style={{ width: "100%" }}>
                                                <Input placeholder='Write grades (for example: 70-79)'
                                                    onChange={(e) => {
                                                        setSecondSection({
                                                            value: e.target.value,
                                                        })
                                                    }}
                                                />
                                                <InputNumber style={{ width: "100%" }} placeholder='Write order number'
                                                    defaultValue={(syllabusData?.fourthSection?.gradeCriteria.sort((a, b) => b.order - a.order)[0]?.order ?? 0) + 1 || 1}
                                                    onChange={(e) => {
                                                        setSecondSection((prevState) => ({
                                                            ...prevState,
                                                            order: Number(e),
                                                        }))
                                                    }}
                                                />
                                            </Space>
                                        </CustomModal>
                                    }
                                />
                                <Table
                                    bordered
                                    dataSource={syllabusData?.fourthSection.gradeCriteria.sort((a, b) => a.order - b.order)}
                                    rowKey={i => i.id}
                                    rowClassName={(record: CustomData) => record.action === 'd' ? 'deleted-row' : ''}
                                    pagination={false}
                                    columns={[
                                        {
                                            title: "Grade",
                                            dataIndex: "grade",
                                            key: "grade",
                                            width: "80px",
                                            render(text: string, record: CustomData) {
                                                return record.value;
                                            },
                                        },
                                        {
                                            title: 'Criteria',
                                            dataIndex: 'criteria',
                                            key: 'criteria',
                                            render(text: string, record: CustomData) {
                                                return (
                                                    <List
                                                        dataSource={record.linkedData?.sort((a, b) => a.order - b.order)}
                                                        rowKey={i => i.id}
                                                        renderItem={(item) => (
                                                            <List.Item style={{ cursor: "pointer" }}>
                                                                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "20px" }}>
                                                                    <Input
                                                                        style={{ cursor: "pointer", background: backgroundStyle(item.action) }}
                                                                        defaultValue={item?.value ?? ''}
                                                                        readOnly
                                                                    />
                                                                    <Dropdown
                                                                        menu={{
                                                                            onClick: ({ key }) => {
                                                                                switch (key) {
                                                                                    case "edit":
                                                                                        handleEditModalOpen(item);
                                                                                        break;
                                                                                    case "delete":
                                                                                        handleDelete(item);
                                                                                        break;
                                                                                    case "undo":
                                                                                        handleUndo(item)
                                                                                        break;
                                                                                }
                                                                            },
                                                                            items: [
                                                                                { key: "edit", label: "Edit" },
                                                                                { key: "delete", label: "Delete" },
                                                                                { key: "undo", label: "Undo" }
                                                                            ]
                                                                        }}>
                                                                        <Space>
                                                                            Action
                                                                            <DownOutlined />
                                                                        </Space>
                                                                    </Dropdown>
                                                                </div>
                                                            </List.Item>
                                                        )}
                                                    />
                                                )
                                            }
                                        },
                                        {
                                            dataIndex: "add",
                                            key: "add",
                                            width: "50px",
                                            render(text: string, record: CustomData) {
                                                return (
                                                    <Space>
                                                        <Dropdown
                                                            menu={{
                                                                onClick: ({ key }) => {
                                                                    switch (key) {
                                                                        case "delete":
                                                                            handleDelete(record);
                                                                            break;
                                                                        case "undo":
                                                                            handleUndo(record)
                                                                            break;
                                                                    }
                                                                },
                                                                items: [
                                                                    {
                                                                        key: "add", label: (
                                                                            <CustomModal
                                                                                title='Add Crtieria'
                                                                                btnTitle='Add Criteria'
                                                                                btnIcon={<></>}
                                                                                btnType='text'
                                                                                onOk={() => {
                                                                                    handleSave(secondSection as SyllabusDataDraftRequest)
                                                                                }}
                                                                            >
                                                                                <Space direction={"vertical"} style={{ width: "100%" }}>
                                                                                    <Input placeholder='Write criteria here...'
                                                                                        onChange={(e) => {
                                                                                            setSecondSection({
                                                                                                draftId: id,
                                                                                                action: "i",
                                                                                                order: (record?.linkedData?.sort((a, b) => b.order - a.order)[0]?.order ?? 0) + 1 || 1,
                                                                                                syllabusPrefixId: 27,
                                                                                                value: e.target.value,
                                                                                                parentId: record.id
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </Space>
                                                                            </CustomModal>
                                                                        )
                                                                    },
                                                                    { key: "delete", label: "Delete" },
                                                                    { key: "undo", label: "Undo" }
                                                                ]
                                                            }}>
                                                            <Space>
                                                                Action
                                                                <DownOutlined />
                                                            </Space>
                                                        </Dropdown>
                                                    </Space>
                                                )
                                            }
                                        },
                                    ]}
                                />
                            </Space>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="2" tab="Student performance evaluation system">
                            <Space direction={'vertical'} style={{ width: "100%" }}>
                                <RowSpan
                                    right={
                                        <CustomModal
                                            title='Add grade criteria'
                                            btnTitle='Add'
                                            onOk={() => {
                                                handleSave(secondSection as SyllabusDataDraftRequest)
                                            }}
                                        >
                                            <Space direction={'vertical'} style={{ width: "100%" }}>
                                                <Select placeholder="Select period" style={{ width: "100%" }} onSelect={(e) =>
                                                    setSecondSection({
                                                        parentId: e,
                                                        order: (syllabusData?.fourthSection?.studentPerformanceEvaluationSystem.linkedData?.find(spes => spes.id === e)?.linkedData?.sort((a, b) => b.order - a.order)[0].order ?? 0) + 1 || 1,
                                                    })
                                                }>
                                                    {syllabusData?.fourthSection.studentPerformanceEvaluationSystem.linkedData?.sort((a, b) => a.order - b.order).map((item) => (
                                                        <Select.Option key={item.id} value={item.id}>{item.value}</Select.Option>
                                                    ))}
                                                </Select>
                                                <Input placeholder='Write assignment'
                                                    onChange={(e) => {
                                                        setSecondSection((prevState) => ({
                                                            ...prevState,
                                                            value: e.target.value,
                                                            draftId: id,
                                                            action: 'i',
                                                            syllabusPrefixId: 23,
                                                        }))
                                                    }}
                                                />
                                            </Space>
                                        </CustomModal>
                                    }
                                />
                                <Table
                                    bordered
                                    dataSource={syllabusData?.fourthSection.studentPerformanceEvaluationSystem.linkedData?.sort((a, b) => a.order - b.order)}
                                    rowKey={i => i.id}
                                    pagination={false}
                                    columns={[
                                        {
                                            "title": "Period",
                                            "dataIndex": "value",
                                            "key": "value",
                                        },
                                        {
                                            "title": "Assignments",
                                            "dataIndex": "linkedData",
                                            "key": "linkedData",
                                            render(text: string, record: CustomData) {
                                                return record.linkedData?.sort((a, b) => a.order - b.order).map((item) => item.linkedData?.length > 0 ? (
                                                    <List
                                                        key={item.id}
                                                        dataSource={item.linkedData?.filter((weekData) => weekData.prefix.prefix === 'spes_assignments_deadline').sort((a, b) => a.order - b.order) || []}
                                                        rowKey={i => i.id}
                                                        renderItem={(weekData) => (
                                                            <List.Item >
                                                                {weekData.value
                                                                    ? <Space>
                                                                        <Typography.Text style={{ background: backgroundStyle(item.action) }}>{`${weekData.value} -`}</Typography.Text>
                                                                        <Typography.Text style={{ background: backgroundStyle(item.action) }}>{`${item.value}`}</Typography.Text>
                                                                    </Space>
                                                                    : <Typography.Text style={{ background: backgroundStyle(item.action) }}>{`${item.value}`}</Typography.Text>
                                                                }
                                                            </List.Item>
                                                        )}
                                                    />
                                                ) :
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "20px" }}>
                                                        <Typography.Text>{`${item.value}`}</Typography.Text>
                                                        <CustomModal
                                                            title='Add week'
                                                            btnTitle='Add week and weight'
                                                            onOk={() => {
                                                                Array.isArray(secondSection)
                                                                    ? secondSection.map((secondSectio: SyllabusDataDraftRequest) => handleSave(secondSectio))
                                                                    : handleSave(secondSection)
                                                            }}
                                                        >
                                                            <Space direction={'vertical'} style={{ width: "100%" }}>
                                                                <Input
                                                                    placeholder='Write week here...'
                                                                    onChange={(e) => {
                                                                        setSecondSection(oldArray => {
                                                                            if (Array.isArray(oldArray)) {
                                                                                if (oldArray.length >= 2) {
                                                                                    let newArray = [...oldArray];
                                                                                    newArray[0] = {
                                                                                        draftId: id,
                                                                                        action: 'i',
                                                                                        order: 1,
                                                                                        syllabusPrefixId: 25,
                                                                                        value: e.target.value,
                                                                                        parentId: item.id
                                                                                    };
                                                                                    return newArray;
                                                                                } else {
                                                                                    return [
                                                                                        ...oldArray,
                                                                                        {
                                                                                            draftId: id,
                                                                                            action: 'i',
                                                                                            order: 1,
                                                                                            syllabusPrefixId: 25,
                                                                                            value: e.target.value,
                                                                                            parentId: item.id
                                                                                        }
                                                                                    ];
                                                                                }
                                                                            } else {
                                                                                return [{
                                                                                    draftId: id,
                                                                                    action: 'i',
                                                                                    order: 1,
                                                                                    syllabusPrefixId: 25,
                                                                                    value: e.target.value,
                                                                                    parentId: item.id
                                                                                }];
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <Input
                                                                    placeholder='Write weight here...'
                                                                    onChange={(e) => {
                                                                        setSecondSection(oldArray => {
                                                                            if (Array.isArray(oldArray)) {
                                                                                if (oldArray.length >= 2) {
                                                                                    let newArray = [...oldArray];
                                                                                    newArray[1] = {
                                                                                        draftId: id,
                                                                                        action: 'i',
                                                                                        order: 2,
                                                                                        syllabusPrefixId: 24,
                                                                                        value: e.target.value,
                                                                                        parentId: item.id
                                                                                    };
                                                                                    return newArray;
                                                                                } else {
                                                                                    return [
                                                                                        ...oldArray,
                                                                                        {
                                                                                            draftId: id,
                                                                                            action: 'i',
                                                                                            order: 2,
                                                                                            syllabusPrefixId: 24,
                                                                                            value: e.target.value,
                                                                                            parentId: item.id
                                                                                        }
                                                                                    ];
                                                                                }
                                                                            } else {
                                                                                return [{
                                                                                    draftId: id,
                                                                                    action: 'i',
                                                                                    order: 2,
                                                                                    syllabusPrefixId: 24,
                                                                                    value: e.target.value,
                                                                                    parentId: item.id
                                                                                }];
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                            </Space>
                                                        </CustomModal>
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            "title": "Weight (%)",
                                            "dataIndex": "linkedData",
                                            "key": "linkedData",
                                            render(text: string, record: CustomData) {
                                                return record.linkedData?.sort((a, b) => a.order - b.order).map((item) => (
                                                    <List
                                                        key={item.id}
                                                        dataSource={item.linkedData?.filter((weekData) => weekData.prefix.prefix === 'spes_assignments_weight').sort((a, b) => a.order - b.order) || []}
                                                        rowKey={i => i.id}
                                                        renderItem={(weekData) => (
                                                            <List.Item>
                                                                <Typography.Text style={{ background: backgroundStyle(item.action) }}>{`${weekData.value}`}</Typography.Text>
                                                            </List.Item>
                                                        )}
                                                    />
                                                ))
                                            }
                                        },
                                        {
                                            "title": "Actions",
                                            "dataIndex": "actions",
                                            "key": "actions",
                                            render(text: string, record: CustomData) {
                                                return record.linkedData?.sort((a, b) => a.order - b.order).map((item, i) => (
                                                    <List
                                                        key={item.id}
                                                        dataSource={item.linkedData?.filter((weekData) => weekData.prefix.prefix === 'spes_assignments_weight').sort((a, b) => a.order - b.order) || []}
                                                        rowKey={i => i.id}
                                                        renderItem={(weekData) => (
                                                            <Space direction='horizontal' style={{ marginBottom: 10, width: "100%" }}>
                                                                <Dropdown
                                                                    menu={{
                                                                        onClick: ({ key }) => {
                                                                            switch (key) {
                                                                                case "assignment":
                                                                                    handleEditModalOpen(item)
                                                                                    break;
                                                                                case "deadline":
                                                                                    // eslint-disable-next-line array-callback-return
                                                                                    item.linkedData?.filter((weekData) => weekData.prefix.prefix === 'spes_assignments_deadline').sort((a, b) => a.order - b.order).map((weekData) => {
                                                                                        handleEditModalOpen(weekData)
                                                                                    })
                                                                                    break;
                                                                                case "weight":
                                                                                    // eslint-disable-next-line array-callback-return
                                                                                    item.linkedData?.filter((weekData) => weekData.prefix.prefix === 'spes_assignments_weight').sort((a, b) => a.order - b.order).map((weekData) => {
                                                                                        handleEditModalOpen(weekData)
                                                                                    })
                                                                                    break;
                                                                                default:
                                                                                    break;
                                                                            }
                                                                        },
                                                                        items: [
                                                                            { key: "assignment", label: "Assignment" },
                                                                            { key: "deadline", label: "Deadline" },
                                                                            { key: "weight", label: "Weight" }
                                                                        ]
                                                                    }}>
                                                                    <Space>
                                                                        Edit
                                                                        <DownOutlined />
                                                                    </Space>
                                                                </Dropdown>
                                                                <Dropdown
                                                                    menu={{
                                                                        onClick: ({ key }) => {
                                                                            switch (key) {
                                                                                case "assignment":
                                                                                    handleDelete(item)
                                                                                    break;
                                                                                case "deadline":
                                                                                    // eslint-disable-next-line array-callback-return
                                                                                    item.linkedData?.filter((weekData) => weekData.prefix.prefix === 'spes_assignments_deadline').sort((a, b) => a.order - b.order).map((weekData) => {
                                                                                        handleDelete(weekData)
                                                                                    })
                                                                                    break;
                                                                                case "weight":
                                                                                    // eslint-disable-next-line array-callback-return
                                                                                    item.linkedData?.filter((weekData) => weekData.prefix.prefix === 'spes_assignments_weight').sort((a, b) => a.order - b.order).map((weekData) => {
                                                                                        handleDelete(weekData)
                                                                                    })
                                                                                    break;
                                                                                default:
                                                                                    break;
                                                                            }
                                                                        },
                                                                        items: [
                                                                            { key: "assignment", label: "Assignment" },
                                                                            { key: "deadline", label: "Deadline" },
                                                                            { key: "weight", label: "Weight" }
                                                                        ]
                                                                    }}>
                                                                    <Space>
                                                                        Delete
                                                                        <DownOutlined />
                                                                    </Space>
                                                                </Dropdown>
                                                                <Dropdown
                                                                    menu={{
                                                                        onClick: ({ key }) => {
                                                                            switch (key) {
                                                                                case "assignment":
                                                                                    handleUndo(item)
                                                                                    break;
                                                                                case "deadline":
                                                                                    // eslint-disable-next-line array-callback-return
                                                                                    item.linkedData?.filter((weekData) => weekData.prefix.prefix === 'spes_assignments_deadline').sort((a, b) => a.order - b.order).map((weekData) => {
                                                                                        handleUndo(weekData)
                                                                                    })
                                                                                    break;
                                                                                case "weight":
                                                                                    // eslint-disable-next-line array-callback-return
                                                                                    item.linkedData?.filter((weekData) => weekData.prefix.prefix === 'spes_assignments_weight').sort((a, b) => a.order - b.order).map((weekData) => {
                                                                                        handleUndo(weekData)
                                                                                    })
                                                                                    break;
                                                                                default:
                                                                                    break;
                                                                            }
                                                                        },
                                                                        items: [
                                                                            { key: "assignment", label: "Assignment" },
                                                                            { key: "deadline", label: "Deadline" },
                                                                            { key: "weight", label: "Weight" }
                                                                        ]
                                                                    }}>
                                                                    <Space>
                                                                        Undo
                                                                        <DownOutlined />
                                                                    </Space>
                                                                </Dropdown>
                                                            </Space>
                                                        )}
                                                    />

                                                ))
                                            }
                                        },
                                    ]}
                                />
                            </Space>
                        </Tabs.TabPane>
                    </Tabs>
                </Tabs.TabPane>
            </Tabs>
        </Page>
    )
}

export default SyllabusDraftPage;