import Page from '../../../../ui/Page'
import { Button, Descriptions, Space } from 'antd'
import RowSpan from '../../../../ui/RowSpan'
import { useSyllabus } from '../useSyllabus'
import useDictionary from '../../../../hooks/useDictionary'
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons'
import SyllabusTable from './SyllabusGroupsAndStreamsTable'
import moment from 'moment'
import { successNotification } from '../../../../helpers/successNotification'
import BackButton from '../../../../ui/BackButton'
import { useEffect, useState } from 'react'
import { GroupsAndStreamsOfStudentsDtoResponse } from '../../../../interfaces/Syllabus/GroupsAndStreamsOfStudentsDtoResponse'
import { SyllabusService } from '../../../../services/SyllabusService'
import { StudyStreamDto } from '../../../../interfaces/StudyStream/StudyStreamDto'

interface Props {
    id?: number;
}


const SyllabusGroupsAndStreamsEditPage = ({ id }: Props) => {
    const { data, methods } = useSyllabus();
    const { dictionary } = useDictionary({});
    const [currentGroupStream, setCurrentGroupStream] = useState<StudyStreamDto>();
    const [groupStreamState, setGroupStreamState] = useState<GroupsAndStreamsOfStudentsDtoResponse>();

    useEffect(() => {
        if (id) {
            SyllabusService.getGroupAndStreamSyllabusById(id)
                .then(({ data }) => {
                    setCurrentGroupStream(data)
                    setGroupStreamState(JSON.parse(data.json));
                })
        }
    }, [id])

    return (
        <Page title={'Edit syllabus'} subtitle='By groups and streams'>
            <BackButton />
            <RowSpan
                left={
                    <Descriptions title="Stream info">
                        <Descriptions.Item label="Academic degree">{dictionary.degreeList?.find(item => item.id === currentGroupStream?.degreeId)?.title}</Descriptions.Item>
                        <Descriptions.Item label="Course">{currentGroupStream?.course}</Descriptions.Item>
                        <Descriptions.Item label="Enrolled Year">{currentGroupStream?.enrolledYear}</Descriptions.Item>
                        <Descriptions.Item label="Approved">{currentGroupStream?.approved ? "Yes" : "No"}</Descriptions.Item>
                        <Descriptions.Item label="Created Date">{moment(currentGroupStream?.createdDate).format('LL hh:mm')}</Descriptions.Item>
                        <Descriptions.Item label="Updated Date">{currentGroupStream?.updatedDate ? moment(currentGroupStream?.updatedDate).format('LL hh:mm') : "No"}</Descriptions.Item>
                    </Descriptions>
                }
                right={
                    currentGroupStream &&
                    (<Space style={{ marginBottom: 10 }}>
                        <Button type='primary' onClick={methods.handleDownloadExcelGroupsAndStreams} loading={data.isLoading} icon={<DownloadOutlined />}>Download Excel</Button>
                        <Button type='primary' onClick={() => {
                            methods.handleCreateOrUpdateGroupAndStreamSyllabus(
                                {
                                    id: currentGroupStream?.id,
                                    degreeId: currentGroupStream?.degreeId ?? 0,
                                    enrolledYear: currentGroupStream?.enrolledYear ?? 0,
                                    course: currentGroupStream?.course ?? 0,
                                    approved: false,
                                    json: JSON.stringify(groupStreamState)
                                }
                            ).then(() => {
                                successNotification('Syllabus has been saved successfully!')
                            })
                        }} loading={data.isLoading} icon={<PlusOutlined />}>Save syllabus</Button>
                    </Space>)
                }
            />

            {currentGroupStream && (
                <SyllabusTable
                    syllabus={groupStreamState}
                    setGroupsAndStreams={setGroupStreamState}
                />
            )}
        </Page >
    )
}

export default SyllabusGroupsAndStreamsEditPage