import { Button, Space, Table } from 'antd'
import useIrosIndicators from './useIrosIndicators'
import Page from '../../../ui/Page/Page'
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const IrosIndicators = () => {
    const { indicators, columns } = useIrosIndicators();

    return (
        <Page title={"iROS Indicators"}>
            <Link to="/create-iros-indicators">
                <Button type="primary" icon={<PlusOutlined />}>
                    Создать индикатор
                </Button>
            </Link>
            <Space direction='vertical' style={{ width: "100%" }} size={"large"}>
                <Table columns={columns} dataSource={indicators.map((data, i) => ({
                    "number": i + 1,
                    "key": data.id,
                    "indicator": data.nameRu,
                    "type": data.type.name,

                }))} bordered />
            </Space>
        </Page>
    )
}

export default IrosIndicators