import { TableColumnsType } from 'antd';
import { Table } from 'antd';
import { useState } from 'react';
import { StudentDtoResponse } from '../../../../interfaces/Student/StudentDtoResponse';
import UserService from '../../../../services/userService';
import { AttendanceService } from '../../../../services/AttendanceService';
import moment from 'moment';

interface DataType {
  key: number | string;
  id: number;
  userId: number;
  fullName: string;
  group: string;

  // debtDisciplines: ExpandedDataType[];
}

interface ExpandedDataType {
  key: number | string;
  discipline: string;
  date: string;
  grade: number | string;
}

interface Props {
  tableData: StudentDtoResponse[];
  loading: boolean;
}

export interface AttendanceTableForOfficeProps {
  trimester: number;
}

const AttendanceTableForOffice = ({ tableData, loading, trimester }: Props & AttendanceTableForOfficeProps) => {
  const [expandableData, setExpandableData] = useState<any>([]);
  const [expandedRow, setExpandedRow] = useState<any>([]);
  const [expandableLoading, setExpandableLoading] = useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const keys: any = [];

  const columns: TableColumnsType<DataType> = [
    { title: '№', dataIndex: 'id', key: 'id' },
    { title: 'Full Name', dataIndex: 'fullName', key: 'fullName' },
    { title: 'Group', dataIndex: 'group', key: 'group' },

  ];

  const data: DataType[] = [];

  for (let i = 0; i < tableData.length; ++i) {
    data.push({
      key: i + 1,
      id: i + 1,
      userId: tableData[i].userId,
      fullName: `${tableData[i].nameKz} ${tableData[i].surnameKz} ${tableData[i].patronymicKz}`,
      group: tableData[i].group.title,
    });
  }

  const expandedRowRender = (record: DataType, index: number, indent: any, expanded: boolean) => {
    const columns: TableColumnsType<ExpandedDataType> = [
      { title: '№', dataIndex: 'key', key: 'key' },
      { title: 'Discipline', dataIndex: 'discipline', key: 'discipline' },
      { title: 'Date', dataIndex: 'date', key: 'date' },
      {
        title: 'Attendance',
        dataIndex: 'grade',
        key: 'grade',
        render: (grade: number) => (grade === 0 ? 'Absent' : 'Present'),
        filters: [
          { text: 'Absent', value: '0' },
          { text: 'Present', value: '1' },
        ],
        onFilter: (value: string | number | boolean, record: ExpandedDataType) => {
          const numericValue = Number(value); // Конвертировать значение в число
          if (numericValue === 0) {
            return record.grade === 0;
          } else if (numericValue === 1) {
            return record.grade !== 0;
          }
          return false;
        },
      },
    ];

    const data: ExpandedDataType[] = [];
    for (let i = 0; i < expandableData.length; ++i) {
      data.push({
        key: i + 1,
        discipline: expandableData[i].discipline,
        grade: expandableData[i].grade,
        date: moment(expandableData[i].date).format('DD.MM.YYYY')
      });
    }
    return <Table columns={columns} dataSource={data} pagination={false} bordered={true} loading={expandableLoading} />;
  };

  const handleExpand = (data: DataType, expanded: boolean) => {
    if (expanded) {
      keys.push(data.id);
    }

    setExpandedRow(keys)
    setExpandableLoading(true);
    setExpandedRowKeys(keys);
    UserService.getProfileById(data.userId)
      .then((res) => {
        AttendanceService.AttendanceDateByEmail(res.data.email, trimester).then((response) => {
          setExpandableData(response.data);
        })
          .finally(() => setExpandableLoading(false));
      })
      .finally(() => setExpandableLoading(false));
  };

  const onTableRowExpand = (expanded: boolean, record: any) => { };

  return (
    <>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
          onExpand: (expanded, record) => handleExpand(record, expanded),
          expandedRowKeys: expandedRow,
        }}
        expandedRowKeys={expandedRowKeys}
        onExpand={onTableRowExpand}
        dataSource={data}
        bordered={true}
        expandRowByClick={true}
      />
    </>
  );
};

export default AttendanceTableForOffice;
