import React from 'react'
import Page from '../../../../../ui/Page'
import UniversalTable from '../../../TeachersPage/UniversalTeachersTable'
import { AcademicMobilityCompetitionDtoRequest, AcademicMobilityCompetitionDtoResponse, AcademicMobilityCompetitionStatusDto, AcademicMobilityCompetitionStatusEnum, StudentAcademicMobilityCompetitionInfoDtoRequest, StudentAcademicMobilityCompetitionInfoTypeDto } from '../../../../../interfaces/AcademicMobilityCompetitionDto';
import useFetchData from '../../../../../hooks/useFetchData';
import { AcademicMobilityCompetitionService } from '../../../../../services/AcademicMobilityCompetitionService';
import { Field } from '../../../../../interfaces/Field';
import { ForeignUniversitiesDtoResponse } from '../../../../../interfaces/ForeignUniversitiesDtoResponse';
import { ForeignUniversitiesService } from '../../../../../services/ForeignUniversitiesService';
import { FinancingSourceDto } from '../../../../../interfaces/FinancingSourceDto';
import { FinancingSourceService } from '../../../../../services/FinancingSourceService';
import { successNotification } from '../../../../../helpers/successNotification';
import grantPermission from '../../../../../helpers/grantPermission';
import { Button, Form, Modal, Space } from 'antd';
import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { isDeadlinePassed } from '../../../../../helpers/isDeadlinePassed';
import { errorNotification } from '../../../../../helpers/errorNotification';
import { useHistory } from 'react-router-dom';
import MobilityDataType from '../MobilityDataType';

const AcademicMobilityCompetitionPage = () => {
    const isDepartment = grantPermission('IR_DEPARTMENT', 'addRole');

    const { data: competitions, fetchData: fetchCompetitions } = useFetchData<AcademicMobilityCompetitionDtoResponse[]>(AcademicMobilityCompetitionService.getAllCompetitions, true);
    const { data: universities } = useFetchData<ForeignUniversitiesDtoResponse[]>(ForeignUniversitiesService.getAllForeignUniversities, isDepartment);
    const { data: statuses } = useFetchData<AcademicMobilityCompetitionStatusDto[]>(AcademicMobilityCompetitionService.getStatuses, isDepartment);
    const { data: financingSources } = useFetchData<FinancingSourceDto[]>(FinancingSourceService.getAllFinancingSource, isDepartment);
    const { data: infoTypes } = useFetchData<StudentAcademicMobilityCompetitionInfoTypeDto[]>(AcademicMobilityCompetitionService.getStudentCompetitionInfoTypes, !isDepartment);
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const [currentCompetition, setCurrentCompetition] = React.useState<AcademicMobilityCompetitionDtoResponse | null>(null);
    const [createForm] = Form.useForm();

    const fields: Field[] = [
        { name: 'universityId', label: 'University', placeholder: "Select university", type: "select", options: universities && universities?.map((university) => ({ value: university.id, label: university.nameEn as string })) },
        { name: 'deadline', label: 'Deadline', type: "date" },
        { name: 'studyStartAt', label: 'Study start at', type: "date" },
        { name: 'studyEndAt', label: 'Study end at', type: "date" },
        { name: 'quota', label: 'Quota', type: "input" },
        { name: 'statusId', label: 'Status', placeholder: "Select status", type: "select", options: statuses && statuses?.map((status) => ({ value: status.id, label: status.nameEn })) },
        { name: 'studyStartTerm', label: 'Study start term', type: "input" },
        { name: 'studyEndTerm', label: 'Study end term', type: "input" },
        { name: 'financingSourceId', label: 'Financing source', placeholder: "Select financing source", type: "select", options: financingSources && financingSources?.map((financingSource) => ({ value: financingSource.id, label: financingSource.nameEn as string })) }
    ];

    const addRecord = (record: AcademicMobilityCompetitionDtoRequest) => {
        AcademicMobilityCompetitionService.createCompetition(record)
            .then(() => {
                successNotification('Competition added!');
            })
            .finally(() => {
                fetchCompetitions()
            });
    };

    const editRecord = (record: AcademicMobilityCompetitionDtoRequest) => {
        AcademicMobilityCompetitionService.updateCompetition(record)
            .then(() => {
                successNotification('Competition updated!');
            })
            .finally(() => {
                fetchCompetitions()
            });
    };

    const deleteRecord = (record: AcademicMobilityCompetitionDtoRequest) => {
        AcademicMobilityCompetitionService.deleteCompetition(record.id)
            .then(() => {
                successNotification('Competition deleted!');
            })
            .finally(() => {
                fetchCompetitions()
            });
    };

    const openModal = (record: AcademicMobilityCompetitionDtoResponse) => {
        setCurrentCompetition(record);
        setOpen(true);
    }

    return (
        <Page title='Academic Mobility Competitions'>
            <UniversalTable
                columns={[
                    {
                        title: '#',
                        dataIndex: 'key',
                        key: 'key',
                    },
                    {
                        title: 'University',
                        dataIndex: 'university',
                        key: 'university',
                    },
                    {
                        title: 'Deadline',
                        dataIndex: 'deadline',
                        key: 'deadline',
                        width: '7%',
                    },
                    {
                        title: 'Study start at',
                        dataIndex: 'studyStartAt',
                        key: 'studyStartAt',
                        width: '8%',
                    },
                    {
                        title: 'Study end at',
                        dataIndex: 'studyEndAt',
                        key: 'studyEndAt',
                        width: '7%',
                    },
                    {
                        title: 'Quota',
                        dataIndex: 'quota',
                        key: 'quota',
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        filters: statuses && statuses.map((status) => ({ text: status.nameEn, value: status.id })),
                        onFilter: (value: any, record: any) => record.statusId === value,
                    },
                    {
                        title: 'Study start term',
                        dataIndex: 'studyStartTerm',
                        key: 'studyStartTerm',
                    },
                    {
                        title: 'Study end term',
                        dataIndex: 'studyEndTerm',
                        key: 'studyEndTerm',
                    },
                    {
                        title: 'Financing source',
                        dataIndex: 'financingSource',
                        key: 'financingSource',
                    },
                    ...(isDepartment ? [
                        {
                            title: 'Details',
                            dataIndex: 'details',
                            key: 'details',
                            render: (_: string, record: any) => (
                                <Space>
                                    <Button
                                        type="primary"
                                        icon={<InfoCircleOutlined />}
                                        onClick={() => history.push(`/academic-mobility/competition/${record.id}`)}
                                    >
                                        Info
                                    </Button>
                                </Space>
                            )
                        }
                    ] : [{
                        title: 'Action',
                        dataIndex: 'action',
                        key: 'action',
                        render: (_: string, record: any) => (
                            <Space>
                                <Button
                                    disabled={
                                        isDeadlinePassed(record.deadline, true) ||
                                        record.statusPrefix !== AcademicMobilityCompetitionStatusEnum.OPEN
                                    }
                                    type="primary"
                                    icon={<ArrowRightOutlined />}
                                    onClick={() => openModal(record)}
                                >
                                    Enroll
                                </Button>
                            </Space>
                        ),
                    }])
                ]}
                dataSource={
                    competitions ?
                        competitions
                            .sort((a, b) => a.id - b.id)
                            .map((competition, i) => ({
                                key: i + 1,
                                id: competition.id,
                                university: competition.university.nameEn,
                                universityId: competition.university.id,
                                deadline: competition.deadline,
                                studyStartAt: competition.studyStartAt,
                                studyEndAt: competition.studyEndAt,
                                quota: competition.quota,
                                status: competition.status.nameEn,
                                statusPrefix: competition.status.prefix,
                                statusId: competition.status.id,
                                studyStartTerm: competition.studyStartTerm,
                                studyEndTerm: competition.studyEndTerm,
                                financingSource: competition.financingSource.nameEn,
                                financingSourceId: competition.financingSource.id
                            })) : []}
                fields={fields}
                addRecord={(e: AcademicMobilityCompetitionDtoRequest) => addRecord(e)}
                editRecord={(e: AcademicMobilityCompetitionDtoRequest) => editRecord(e)}
                deleteRecord={(e: AcademicMobilityCompetitionDtoRequest) => deleteRecord(e)}
                isPublic={false}
                hideActions={!isDepartment}
                hideCreate={!isDepartment}
            />
            <Modal
                title="Enroll"
                open={open}
                okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
                onCancel={() => setOpen(false)}
                destroyOnClose
                modalRender={(dom) => (
                    <Form
                        layout="vertical"
                        form={createForm}
                        name="createForm"
                        onFinish={(values) => {
                            const mobilityCompetitions: StudentAcademicMobilityCompetitionInfoDtoRequest[] = infoTypes?.map((infoType) => ({
                                infoTypeId: Number(Object.keys(values).find((key) => key === String(infoType.id)) ?? infoType.id),
                                value: values[infoType.id] ?? null
                            })) || [];

                            AcademicMobilityCompetitionService.createStudentCompetition(mobilityCompetitions, currentCompetition?.id)
                                .then(() => {
                                    successNotification('Enrolled!');
                                    setOpen(false);
                                })
                                .catch((e) => {
                                    errorNotification(e.response.data.message);
                                })
                                .finally(() => {
                                    fetchCompetitions();
                                });
                        }}
                    >
                        {dom}
                    </Form>
                )}
            >
                {infoTypes?.filter((infoType) => infoType.studentField === true).map((infoType) => (
                    <MobilityDataType key={infoType.id} type={infoType} form={createForm} />
                ))}
            </Modal>
        </Page >
    )
}

export default AcademicMobilityCompetitionPage