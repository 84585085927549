
import { useState, useEffect, useCallback } from 'react';
import { Table, Button, Spin, Typography, Space, Modal } from 'antd';
import { DisciplinesService } from '../../../services/DisciplinesService';
import { v4 as uuidv4 } from 'uuid';
import DragListView from 'react-drag-listview';
import { CurriculumService } from '../../../services/CurriculumService';
import PreviousChoiceModal from './PreviousChoiceModal';
import Instructions from './Instructions';
import { successNotification } from '../../../helpers/successNotification';
import CustomModal from '../../../ui/Modal';
import { ElectiveChooseDtoResponse } from '../../../interfaces/EducationalPrograms/ElectiveChooseDtoResponse';
import { errorNotification } from '../../../helpers/errorNotification';

const { Title } = Typography;

const ChooseDiscipline = () => {
  // hooks to handle data from api
  const [isAbleToChoose, setIsAbleToChoose] = useState<boolean | undefined>();
  const [disciplines, setDisciplines] = useState<{ [groupedDisciplineId: number]: ElectiveChooseDtoResponse[] }>([]);
  const [isOpenInstruction, setIsOpenInstruction] = useState(false);

  const columns = [
    {
      title: 'Subject name',
      dataIndex: 'title',
      key: 'title',
      width: "35%",
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: "5%",
      render: (text: any, record: any, index: any) => record.description && (
        <CustomModal btnTitle='Show description' title={record.title} onOk={() => { }}>
          {record.description}
        </CustomModal>
      )
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
    },
    {
      title: 'Trimester',
      dataIndex: 'numberOfTrimester',
      key: 'numberOfTrimester',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any, index: any) => record.numberOfStudentChose < record.maxStudents ? (
        <a className="drag-handle" href="#">
          Drag
        </a>
      ) : (
        <p className="drag-ignore" style={{ color: 'red' }}>
          Unavailable
        </p>
      ),
    },
  ];

  // groupedDisciplineId - ID таблицы
  const onDragEnd = (groupedDisciplineId: number, fromIndex: number, toIndex: number) => {
    // Если под этим ключом нет массива (undefined)

    const data = disciplines[groupedDisciplineId];

    data.splice(toIndex, 0, data.splice(fromIndex, 1)[0]);

    const firstElement = data.at(0)

    if (firstElement?.numberOfStudentChose && firstElement?.curriculumDtoResponse.maxStudents
      && firstElement?.numberOfStudentChose >= firstElement?.curriculumDtoResponse.maxStudents) {
      data.splice(fromIndex, 0, data.splice(toIndex, 1)[0]);
      errorNotification("You cannot choose this discipline as first priority")
    }
    else {
      setDisciplines({ ...disciplines, [groupedDisciplineId]: data });
    }
  };

  const getFilteredDisciplines = useCallback(() => {
    const tableArray: JSX.Element[] = [];

    Object.entries(disciplines).forEach(([groupedDisciplineId, groupedDisciplines]) => {
      const dataSource = groupedDisciplines.map((discipline) => {
        return {
          title: discipline.curriculumDtoResponse.discipline.titleEn,
          description: discipline.curriculumDtoResponse.discipline.descriptionEn,
          credit: discipline.curriculumDtoResponse.discipline.volumeCredits,
          numberOfTrimester: discipline.curriculumDtoResponse.numberOfTrimester,
          year: discipline.curriculumDtoResponse.year,
          numberOfStudentChose: discipline.numberOfStudentChose,
          maxStudents: discipline.curriculumDtoResponse.maxStudents,
          minStudents: discipline.curriculumDtoResponse.minStudents
        };
      });


      tableArray.push(
        // @ts-ignore
        <DragListView
          onDragEnd={(fromIndex, toIndex) => onDragEnd(parseInt(groupedDisciplineId), fromIndex, toIndex)}
          handleSelector="a.drag-handle"
        >
          <Table key={uuidv4()} columns={columns} dataSource={dataSource} pagination={false} />
        </DragListView>
      );
    });

    return tableArray;
  }, [disciplines]);

  const handleSaveChoice = () => {
    const promiseArray: Promise<any>[] = [];

    Object.values(disciplines).forEach((disciplines) => {
      const requestObj: any = {};
      disciplines.forEach((discipline, index) => {
        requestObj[index + 1] = discipline.curriculumDtoResponse.id;
      });

      promiseArray.push(CurriculumService.saveDisciplineChoice(requestObj));
    });

    Promise.all([...promiseArray])
      .then(() => successNotification('Everything has been saved'))
  };
  useEffect(() => {
    const tempSubjects: { [groupedDisciplineId: number]: ElectiveChooseDtoResponse[] } = {};
    Promise.all([
      DisciplinesService.checkStudentDisciplineChoiceAccess(),
      DisciplinesService.getDisciplinesForStudentChoice(),
    ])
      .then(([access, subjects]) => {
        setIsAbleToChoose(access.data);

        subjects.data.forEach((subject) => {
          if (!tempSubjects[subject.curriculumDtoResponse.groupedDisciplines]) {
            tempSubjects[subject.curriculumDtoResponse.groupedDisciplines] = [];
          }

          tempSubjects[subject.curriculumDtoResponse.groupedDisciplines].push(subject);
        });
        setDisciplines(tempSubjects);
      })
  }, []);

  return (
    <>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Modal
          width={1000}
          style={{ padding: 30 }}
          centered
          open={isOpenInstruction}
          onCancel={() => setIsOpenInstruction(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
          <Instructions closeInstructionModal={() => setIsOpenInstruction(false)} />
        </Modal>
        {isAbleToChoose === undefined ? (
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Spin size="large"></Spin>
          </div>
        ) : isAbleToChoose ? (
          <>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Space size="middle">
                <Title level={3}>Select your elective disciplines for next term</Title>
                <Button type="primary" onClick={() => setIsOpenInstruction(true)}>
                  Click for instructions!
                </Button>
              </Space>

              <p style={{ color: 'blue', margin: 0 }}>
                Unavailable means - you cannot choose this discipline as first priority due to the fact that the maximum number of students have already chosen this discipline.
              </p>
              <p style={{ color: 'blue', margin: 0 }}>
                Drag means - you can apply your choice by dragging disciplines up or down.
              </p>

              {getFilteredDisciplines()}
              <Space size="middle">
                <Button type="primary" size="large" style={{ width: 200 }} onClick={handleSaveChoice}>
                  Save
                </Button>
                <PreviousChoiceModal />
              </Space>
            </Space>
          </>
        ) : (
          <Title level={4}>Discipline selection is closed</Title>
        )}
      </Space>
    </>
  );
};

export default ChooseDiscipline;
