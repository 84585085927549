import axios from 'axios';
import { Constants } from '../common/constants';
import { UserDtoResponse } from '../interfaces/UserDtoResponse';
interface JSONResp {
  status: number;
  baseRole: string;
}
class AuthService {
  static isLoggedIn() {
    const storedUserString = localStorage.getItem('user');
    const user: UserDtoResponse | null = storedUserString ? JSON.parse(storedUserString) : null;

    return !!localStorage.getItem('user') && user?.agreeWithUserAgreement === true;
  }
  static baseURL = Constants.API_BASE_URL;

  static async login(data: string) {
    try {
      const response = await axios.post<JSONResp>(
        `${this.baseURL}/astanait-authorization-module/api/v1/auth/authorization?token=${data}`
      );
      if (response.status === 200) {
        // const jwtToken = response.headers.get("Jwt-Token");
        this.setToken(response.headers['jwt-token']);
        this.setCredentials(response.data);
        return Promise.resolve(response);
      }
    } catch (error: any) {

      const message = error.response?.data?.message;
      if (message) {
        return Promise.reject(message);
      }
      const defaultErr = error.response?.data?.statusText;
      if (defaultErr) {
        return Promise.reject(defaultErr);
      } else {
        return Promise.reject('Server Error');
      }
    }
  }

  static setCredentials(response: any) {
    var baseRole = { 'roleName': response.baseRole };
    response.roleList.push(baseRole);
    localStorage.setItem('user', JSON.stringify(response));
  }

  static setToken(response: any) {
    localStorage.setItem('token', response);
  }
}
export default AuthService;
