import { useEffect, useState } from 'react'
import { KpiService } from '../../../services/kpi/KpiService';
import { KpiCategoryDtoResponse } from '../../../interfaces/Kpi/KpiCategoryDtoResponse';
import { errorNotification } from '../../../helpers/errorNotification';
import { TeacherKpiApplicationDtoResponse } from '../../../interfaces/Kpi/TeacherKpiApplicationDtoResponse';
import { Form, UploadProps } from 'antd';
import { FileService } from '../../../services/file/FileService';
import { successNotification } from '../../../helpers/successNotification';
import grantPermission from '../../../helpers/grantPermission';
import { TeacherKpiApproveDtoRequest } from '../../../interfaces/Kpi/TeacherKpiApproveDtoRequest';
import { useHistory } from 'react-router-dom';

export const useKpi = () => {
    //lists
    const [categories, setCategories] = useState<KpiCategoryDtoResponse[]>([]);
    const [subCategories, setSubCategories] = useState<KpiCategoryDtoResponse[]>([]);
    const [kpiApplications, setKpiApplications] = useState<TeacherKpiApplicationDtoResponse[]>([]);


    //constants
    const kpiFileType = 16;
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [deadline, setDeadline] = useState<boolean>(false);

    //Reason Modal
    const [reasonForm] = Form.useForm();
    const [form] = Form.useForm();
    const [isReasonOpen, setIsReasonOpen] = useState(false);

    const handleOpenReasonModal = (record: TeacherKpiApplicationDtoResponse) => {
        setIsReasonOpen(true)
        reasonForm.setFieldsValue({
            comment: record.comment
        })
    }


    const uploadProps: UploadProps = {
        maxCount: 1,
        // beforeUpload: (file: RcFile) => {
        //     const isPDF = file.type === 'application/pdf';
        //     if (!isPDF) {
        //         openNotification(`${file.name} is not pdf file!`, 'Please, upload only pdf file!', 'error', 1.5);
        //     }
        //     return isPDF || Upload.LIST_IGNORE;
        // },
        onChange(e) {
            e.file.status = 'done';
        },
    };


    useEffect(() => {
        KpiService.getCategories()
            .then(({ data }) => setCategories(data))
    }, [])

    useEffect(() => {
        if (grantPermission('HR')) {
            KpiService.getApplications()
                .then(({ data }) => setKpiApplications(data))
        }
        else {
            Promise.all([
                KpiService.getTeacherApplications(),
                KpiService.getApplicationsDeadline(),
            ])
                .then(([applications, deadline]) => {
                    setKpiApplications(applications.data)
                    setDeadline(deadline.data)
                })
        }
    }, [loading])


    const handleCreateApplication = (request: any) => {
        let formData = new FormData();
        request.kpiFile && formData.append('file', request.kpiFile.file.originFileObj);
        request.kpiFile
            ?
            FileService.uploadFile(formData, kpiFileType)
                .then(({ data }) => {
                    request.fileId = data.id
                    KpiService.createApplication(request)
                        .then((data) => successNotification('Данные успешно сохранены'))
                        .catch((err) => {
                            switch (err.response!.status) {
                                case 404:
                                    return errorNotification('Вы уже подали заявку по данной категорий!');
                                default:
                                    return errorNotification('Не удалось сохранить данные', err.response?.status);
                            }
                        })
                        .finally(() => {
                            setLoading(!loading)
                            form.resetFields()
                        })
                })
            :
            KpiService.createApplication(request)
                .then((data) => successNotification('Данные успешно сохранены'))
                .catch((err) => {
                    switch (err.response!.status) {
                        case 404:
                            return errorNotification('Вы уже подали заявку по данной категорий!');
                        default:
                            return errorNotification('Не удалось сохранить данные', err.response?.status);
                    }
                })
                .finally(() => {
                    setLoading(!loading)
                    form.resetFields()
                })
    }

    const handleChooseCategory = (value: number) => {
        setSubCategories([]);
        KpiService.getSubCategories(value)
            .then(({ data }) => setSubCategories(data))
    }

    const handleDownloadFile = (fileId: number) => {
        FileService.getFileById(fileId, 'Kpi File')
            .then(() => successNotification('Successfully downloaded!'));
    };

    const handleApprove = (teacherKpiApproveDtoRequest: TeacherKpiApproveDtoRequest, id: number) => {
        KpiService.approveApplication(teacherKpiApproveDtoRequest, id)
            .then(() => {
                successNotification('Успешно отправлено!')
                setLoading(!loading)
            });
    }

    const handleSearchApplications = (form: any) => {
        KpiService.getTeacherApplicationsByFilter(form)
            .then(({ data }) => {
                setKpiApplications(data)
            });
    }


    return { data: { kpiApplications, history, categories, subCategories, uploadProps, reasonForm, isReasonOpen, setIsReasonOpen, deadline, form }, handlers: { handleChooseCategory, handleCreateApplication, handleDownloadFile, handleApprove, handleOpenReasonModal, handleSearchApplications } }
}

