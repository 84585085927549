import React from 'react'
import UniversalTable from '../UniversalTeachersTable';
import { TeacherScientificProjectsDtoResponse } from '../../../../interfaces/Teacher/TeacherScientificProjectsDtoResponse';
import { Field } from '../../../../interfaces/Field';
import useFetchData from '../../../../hooks/useFetchData';
import { TeacherService } from '../../../../services/TeacherService';
import { Tag } from 'antd';
import { successNotification } from '../../../../helpers/successNotification';

interface TeacherScientificProjectsTableProps {
    isPublic: boolean;
}

const TeacherScientificProjectsTable = ({ isPublic }: TeacherScientificProjectsTableProps) => {
    const { data: scientificProject, fetchData: fetchScientificProject } = useFetchData<TeacherScientificProjectsDtoResponse[]>(
        TeacherService.getTeacherScientificProjects, true
    );

    const addRecord = (record: TeacherScientificProjectsDtoResponse) => {
        TeacherService.createOrUpdateTeacherScientificProject(record)
            .then(() => fetchScientificProject())
            .finally(() => successNotification('Scientific project has been added!'))
    };

    const editRecord = (record: TeacherScientificProjectsDtoResponse) => {
        TeacherService.createOrUpdateTeacherScientificProject(record)
            .then(() => fetchScientificProject())
            .finally(() => successNotification('Scientific project has been updated!'))
    };

    const deleteRecord = (record: TeacherScientificProjectsDtoResponse) => {

    };

    const scholarFields: Field[] = [
        { name: 'information', label: 'Full Information: Title, Supervisor (PI), funding organisation', type: 'text', placeholder: "Write full Information: Title, Supervisor (PI), funding organisation" },
        { name: 'yearFrom', label: 'From', type: 'date' },
        { name: 'yearTo', label: 'To', type: 'date' },
        { name: 'budget', label: 'Budget', type: 'text', placeholder: "Write budget" },
        {
            name: 'role', label: 'Role', type: 'select', placeholder: 'Select role', options: [
                { value: 'SUPERVISOR', label: 'Супервайзер' },
                { value: 'RESEARCHER', label: 'Исследователь' },
                { value: 'PROJECT_RESPONSIBLE', label: 'Ответственный за проект' },
            ]
        },
        { name: 'current', label: 'Is project active?', type: 'checkbox' },
    ];


    return (
        <UniversalTable
            columns={[
                {
                    title: '#',
                    dataIndex: 'key',
                    key: 'key',
                },
                {
                    title: 'Full Information: Title, Supervisor (PI), funding organisation',
                    dataIndex: 'information',
                    key: 'information',
                },
                {
                    title: 'From',
                    dataIndex: 'yearFrom',
                    key: 'yearFrom',
                },
                {
                    title: 'To',
                    dataIndex: 'yearTo',
                    key: 'yearTo',
                },
                {
                    title: 'Budget',
                    dataIndex: 'budget',
                    key: 'budget',
                },
                {
                    title: 'Role',
                    dataIndex: 'role',
                    key: 'role',
                    render: (record: any) => {
                        switch (record) {
                            case 'SUPERVISOR':
                                return <Tag color={'blue'}>{'Супервайзер'}</Tag>;
                            case 'RESEARCHER':
                                return <Tag color={'blue'}>{'Исследователь'}</Tag>;
                            case 'PROJECT_RESPONSIBLE':
                                return <Tag color={'blue'}>{'Ответственный за проект'}</Tag>;
                            default:
                                return <></>;
                        }
                    },
                },
                {
                    title: 'Status',
                    dataIndex: 'current',
                    key: 'current',
                    render: (status: any) => {
                        return <Tag color={status ? 'green' : 'geekblue'}>{status ? 'Active' : 'Сompleted'}</Tag>;
                    },
                },
            ]}
            dataSource={scientificProject ? scientificProject.map((scholarInformation, i) => ({
                id: scholarInformation.id,
                key: i + 1,
                information: scholarInformation.information,
                yearFrom: scholarInformation.yearFrom,
                yearTo: scholarInformation.yearTo,
                budget: scholarInformation.budget,
                current: scholarInformation.current,
                role: scholarInformation.role,
            })) : []}
            addRecord={(e: TeacherScientificProjectsDtoResponse) => addRecord(e)}
            editRecord={(e: TeacherScientificProjectsDtoResponse) => editRecord(e)}
            deleteRecord={(e: TeacherScientificProjectsDtoResponse) => deleteRecord(e)}
            fields={scholarFields}
        />)
}

export default TeacherScientificProjectsTable