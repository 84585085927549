import authAxios from '../../common/authAxios';
import { KpiCategoryDtoResponse } from '../../interfaces/Kpi/KpiCategoryDtoResponse';
import { TeacherKpiApplicationDtoResponse } from '../../interfaces/Kpi/TeacherKpiApplicationDtoResponse';
import { TeacherKpiApplicationDtoRequest } from '../../interfaces/Kpi/TeacherKpiApplicationDtoRequest';
import { TeacherKpiApproveDtoRequest } from '../../interfaces/Kpi/TeacherKpiApproveDtoRequest';
import { KpiApplicationSettingsDtoResponse } from '../../interfaces/Kpi/KpiApplicationSettingsDtoResponse';
import { KpiApplicationSettingsDtoRequest } from '../../interfaces/Kpi/KpiApplicationSettingsDtoRequest';
import { TeacherKpiApplicationWithKPIDto } from '../../interfaces/Kpi/TeacherKpiApplicationWithKPIDto';
import axios from 'axios';
import { Constants } from '../../common/constants';


export class KpiService {
    private static baseUrlKpi = '/astanait-teacher-module/api/v1/teacher/kpi';

    static getCategories() {
        return authAxios.get<KpiCategoryDtoResponse[]>(`${this.baseUrlKpi}/categories`)
    }

    static getSubCategories(categoryId: number) {
        return authAxios.get<KpiCategoryDtoResponse[]>(`${this.baseUrlKpi}/categories/${categoryId}/sub-categories`)
    }

    static getApplications() {
        return authAxios.get<TeacherKpiApplicationDtoResponse[]>(`${this.baseUrlKpi}/applications`)
    }

    static getTeacherApplications() {
        return authAxios.get<TeacherKpiApplicationDtoResponse[]>(`${this.baseUrlKpi}/teacher/applications`)
    }

    static createApplication(teacherKpiApplicationDtoRequest: TeacherKpiApplicationDtoRequest) {
        return authAxios.post(`${this.baseUrlKpi}/create-or-update`, teacherKpiApplicationDtoRequest)
    }

    static approveApplication(teacherKpiApproveDtoRequest: TeacherKpiApproveDtoRequest, id: number) {
        return authAxios.post(`${this.baseUrlKpi}/approve?id=${id}`, teacherKpiApproveDtoRequest)
    }

    static getTeacherApplicationsByFilter(params: any) {

        for (const key of Object.keys(params)) {
            if (!params[key]) {
                delete params[key];
            }
        }


        return authAxios.get<TeacherKpiApplicationDtoResponse[]>(`${this.baseUrlKpi}/applications-by-filter`, { params })
    }

    static getApplicationSettings() {
        return axios.get<KpiApplicationSettingsDtoResponse[]>(`${Constants.API_BASE_URL}${this.baseUrlKpi}/public/settings`)
    }

    static getApplicationsDeadline() {
        return authAxios.get<boolean>(`${this.baseUrlKpi}/check-deadline`)
    }

    static createOrUpdateApplicationSettings(kpiApplicationSettingsDtoRequest: KpiApplicationSettingsDtoRequest, id?: number) {
        if (id) {
            return authAxios.post(`${this.baseUrlKpi}/settings/create-or-update?id=${id}`, kpiApplicationSettingsDtoRequest)
        }
        else {
            return authAxios.post(`${this.baseUrlKpi}/settings/create-or-update`, kpiApplicationSettingsDtoRequest)
        }
    }

    static deleteApplicationSetting(id: number) {
        return authAxios.delete(`${this.baseUrlKpi}/setting/${id}`)
    }

    static getTeacherKpi(teacherId: number, settingId?: number) {
        if (settingId) {
            return axios.get<number>(`${Constants.API_BASE_URL}${this.baseUrlKpi}/public/calculate?teacherId=${teacherId}&settingId=${settingId}`)

        } else {
            return axios.get<number>(`${Constants.API_BASE_URL}${this.baseUrlKpi}/public/calculate?teacherId=${teacherId}`)
        }
    }

    static getTeacherApplicationsWithKpi(settingId?: number) {
        if (settingId) {
            return authAxios.get<TeacherKpiApplicationWithKPIDto>(`${this.baseUrlKpi}/get-applications-with-kpi?settingId=${settingId}`)

        } else {
            return authAxios.get<TeacherKpiApplicationWithKPIDto>(`${this.baseUrlKpi}/get-applications-with-kpi`)
        }
    }

    static getTeacherApplicationsWithKpiForHR(teacherId: number, settingId?: number) {
        if (settingId) {
            return authAxios.get<TeacherKpiApplicationWithKPIDto>(`${this.baseUrlKpi}/get-applications-with-kpi-for-hr?teacherId=${teacherId}&settingId=${settingId}`)

        } else {
            return authAxios.get<TeacherKpiApplicationWithKPIDto>(`${this.baseUrlKpi}/get-applications-with-kpi-for-hr?teacherId=${teacherId}`)
        }
    }

    static getTeacherPositions() {
        return authAxios.get<string[]>(`/astanait-teacher-module/api/v1/teacher/kpi/positions`)
    }
}