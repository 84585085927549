import { SaveOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd'
import { TeacherLegalDocsDtoResponce } from '../../../../interfaces/Teacher/TeacherLegalDocsDtoResponce';
import { FileService } from '../../../../services/file/FileService';
import { successNotification } from '../../../../helpers/successNotification';
import grantPermission from '../../../../helpers/grantPermission';

interface Props {
    dataSource?: TeacherLegalDocsDtoResponce[];
}

const PublicTeacherLegalDocsTable = ({ dataSource }: Props) => {
    const columns = grantPermission('HR') ? [
        {
            title: "#",
            dataIndex: "key",
            key: "key"
        },
        {
            title: "Patent type",
            dataIndex: "type",
            key: "type"
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            render: (_: any, record: any) => {
                return (
                    <Button icon={<SaveOutlined />} onClick={() => handleDownload(record.fileId)}>
                        Download security document
                    </Button>
                )
            }
        },
    ] : [
        {
            title: "#",
            dataIndex: "key",
            key: "key"
        },
        {
            title: "Patent type",
            dataIndex: "type",
            key: "type"
        },
    ]

    const handleDownload = (id: number) => {
        FileService.getFileById(id, "Teacher legal document")
            .then(() => successNotification("Security document was successfully downloaded!"))
    }

    return (
        <Table bordered columns={columns}
            dataSource={dataSource?.map((data, i) => ({
                id: data.id,
                key: i + 1,
                type: data.type.name,
                fileId: data.fileId,
                typeId: data.type.id,
            }))}
            style={{
                overflowX: "auto"
            }}
        />
    )
}

export default PublicTeacherLegalDocsTable