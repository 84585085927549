import { OrderTypeDtoResponse } from '../../interfaces/Order/OrderTypeDtoResponse';
import { OrdersService } from '../../services/OrdersService';
import { useState, useEffect } from 'react';
import { Moment } from 'moment';
import { Form } from 'antd';

const useOrderPage = () => {
  const [ordersTypes, setOrderTypes] = useState<OrderTypeDtoResponse[]>([]);
  const [form] = Form.useForm();
  // const [courseOptions, setCourseOptions] = useState<CourseSelectResponse[]>([]);
  // const [educPrograms, setEducPrograms] = useState<EducationProgramDtoResponse[]>([]);
  // const [groupOptions, setGroupOptions] = useState<StudentGroupsDtoResponse[]>([]);

  const [selectedOrderType, setSelectedOrderType] = useState<number | undefined>();
  const [search, setSearch] = useState<string>('');
  const [numberSearch, setNumberSearch] = useState<string>('');
  const [fromDate, setFromDate] = useState<Moment | undefined>(undefined);
  const [toDate, setToDate] = useState<Moment | undefined>(undefined);

  // const [selectedEducationalDegree, setSelectedEducationalDegree] = useState<number | undefined>();
  // const [selectedCourse, setSelectedCourse] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    OrdersService.getAllOrderTypes().then(({ data }) => {
      setOrderTypes(data);
    })
  }, []);

  // const sortEduProgramsAlphabetically = (data: EducationProgramDtoResponse[]) => {
  //   return data.sort((a, b) => a.titleEn.localeCompare(b.titleEn));
  // };

  // const handleEducationalDegreeSelect = (value: number) => {
  //   setSelectedEducationalDegree(value);
  //   setSelectedCourse(undefined);
  //   if (value === 2) {
  //     if (selectedOrderType === 2) {
  //       setCourseOptions([{ id: 1, year: 1 }]);
  //     } else {
  //       setCourseOptions([
  //         { id: 1, year: 1 },
  //         { id: 2, year: 2 },
  //       ]);
  //     }
  //   } else {
  //     if (selectedOrderType === 2) {
  //       setCourseOptions([
  //         { id: 1, year: 1 },
  //         { id: 2, year: 2 },
  //       ]);
  //     } else {
  //       setCourseOptions([
  //         { id: 1, year: 1 },
  //         { id: 2, year: 2 },
  //         { id: 3, year: 3 },
  //       ]);
  //     }
  //   }
  // };

  const handleSetFromDate = (date: any) => {
    if (date) {
      setFromDate(date.format('YYYY-MM-DD'));
    } else {
      setFromDate(undefined);
    }
  };

  const handleSetToDate = (date: any) => {
    if (date) {
      setToDate(date.format('YYYY-MM-DD'));
    } else {
      setToDate(undefined);
    }
  };

  const handleClear = () => {
    setSelectedOrderType(undefined);
  };

  const onSelectOrderType = (value: number) => {
    setSelectedOrderType(value);
  };

  const onSearch = (value: string) => {
    setSearch(value);
  };

  const onNumberSearch = (value: string) => {
    setNumberSearch(value);
  };

  // const handleCourseSelect = (value: number) => {
  //   setLoading(true);
  //   setSelectedCourse(value);
  //   EducationalProgramsService.getEducationProgramsByCourseAndAcademicDegree(value, selectedEducationalDegree)
  //     .then((educProgramsData) => setEducPrograms(sortEduProgramsAlphabetically(educProgramsData.data)))
  //     .finally(() => setLoading(false));
  // };

  // const handleEducationalProgramSelect = (value: any) => {
  //   setLoading(true);
  //   EducationalProgramsService.getGroupsByEducationalProgram(value)
  //     .then((res) => setGroupOptions(res.data))
  //     .finally(() => setLoading(false));
  // };

  const handleDegreeSelect = (value: any) => {
    setLoading(true);
    OrdersService.downloadCurrentContingentExcelFile({
      academicDegreeId: value.academicDegreeId,
      date: value.date && value.date.format("DD-MM-YYYY")
    })
      .finally(() => setLoading(false));
  };

  return {
    ordersTypes,
    search,
    numberSearch,
    fromDate,
    toDate,
    onSearch,
    onNumberSearch,
    loading,
    handleClear,
    handleSetFromDate,
    handleSetToDate,
    selectedOrderType,
    onSelectOrderType,
    handleDegreeSelect,
    form,
  };
};

export default useOrderPage;
