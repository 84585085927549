
import React from 'react';

export const KazakhContent = () => (
    <>
        <h3>Пайдаланушы Келісімі</h3>
        <p>Күшіне ену күні: 01.03.2024</p>
        <p>
            Осы Пайдаланушы келісімі (бұдан әрі – "Келісім") Astana IT University (бұдан әрі – "Университет") мен du.astanait.edu.kz веб-сайттың ресурстары мен қызметтерін (бұдан әрі - "Сайт") пайдаланатын тұлғалар (бұдан әрі - "пайдаланушы") арасындағы қатынастарды реттейді. </p>
        <p><b> 1. Жалпы ережелер</b></p>

        <p>1.1. Сайтты пайдалана отырып, Пайдаланушы осы Келісімнің шарттарымен келісімін білдіреді.<br />
            1.2. Келісім шарттарымен келіспеген жағдайда сайтты пайдалану дереу тоқтатылуы тиіс.
        </p>
        <p><b> 2. Пайдалану шарттары</b></p>

        <p>  2.1. Сайт пайдаланушыларға кіруге мүмкіндік береді.<br />
            • Сайтта берілген материалдарды қарау;<br />
            • Сайтта тіркелу және/немесе авторизациялау;<br />
            • Сайтта кез келген материалдарды тіркеу немесе көрсету, соның ішінде: мәтіндер, гипермәтіндік Сілтемелер,
            Суреттер, аудио және бейне файлдар, мәліметтер және / немесе өзге де ақпарат; Қазақстан Республикасы Азаматтық кодексінің (ҚР Азаматтық кодексі)
            437 және 438-баптарының ережелеріне сәйкес осы Келісімнің негізінде шарт жасайды.<br />
            • Smart қызметтер<br />
            • Транскрипт<br />
            • Өтініш беру<br />
            • психологқа жазуды тіркеу<br />
            • Элективті пәндерді таңдау<br />
            • Бағалар жиынтыған (ведомостарды) растау<br />
            2.2. Университет пайдаланушыға алдын ала ескертусіз ұсынылатын қызметтерге өзгерістер енгізу құқығын өзіне қалдырады.
        </p>
        <p><b>3. Тараптардың құқықтары мен міндеттеледі</b></p>

        <p>
            3.1. Пайдаланушы Сайтты заңсыз контентті тарату үшін пайдаланбауға және ҚР заңнамасын бұзатын әрекеттер жасамауға міндеттенеді.<br />
            3.2 Пайдаланушы сайтта өзінің есептік жазбасына кіре отырып, өзінің есептік жазбасында жасалған барлық іс-әрекеттер, соның ішінде өтініш беру, мазмұнды жариялау және сайттағы кез келген басқа әрекеттер үшін толық жауапкершілікті өзіне алады. Пайдаланушы өзінің есептік жазбасы арқылы жасаған барлық әрекеттер оның атынан орындалған болып саналады және сайттың ресурстарын пайдалануда тиісті дәлдік пен хабардарлықты талап етеді<br />
            3.3. Университет техникалық қызмет көрсету кезеңдері мен форс-мажорлық жағдайларды қоспағанда, сайттың қолжетімділігін қамтамасыз етуге міндеттеледі.<br />
        </p>

        <p><b>4. Жауапкершілік</b></p>

        <p>4.1. Университет сайттың уақытша істен шығуы мен үзілістері және олар тудырған ақпараттың жоғалуы үшін жауап бермейді.<br />
            4.2. Университет сайтты пайдалану немесе пайдалану мүмкіндігі шектелу нәтижесінде пайдаланушыға келтірілген залал үшін жауап бермейді.<br />
        </p>
        <p><b>5. Құпиялылық</b></p>

        <p>5.1. Университет Құпиялылық саясатына сәйкес сайтты пайдалану кезінде пайдаланушының жеке ақпаратының құпиялылығын сақтауға міндеттенеді.</p>
        <p> <b>6. Келісімдегі өзгерістер</b></p>
        <p>
            6.1. Университет пайдаланушының келісімін алу қажеттілігінсіз осы Келісімге біржақты тәртіппен өзгерістер енгізу құқығын өзіне қалдырады. Келісімнің жаңа нұсқасы, Егер Келісімнің жаңа нұсқасында өзгеше көзделмесе, сайтта орналастырылған кезден бастап күшіне енеді. </p>
    </>
);
export const RussianContent = () => (
    <>
        <h3>Пользовательское Соглашение</h3>
        <p>Дата вступления в силу: 01.03.2024</p>
        <p>
            Данное Пользовательское Соглашение (далее – «Соглашение») регулирует отношения между Astana IT University (далее – «Университет») и лицами (далее – «Пользователи»), использующими ресурсы и услуги веб-сайта du.astanait.edu.kz (далее – «Сайт»).
        </p>
        <p><b> 1. Общие положения</b></p>

        <p> 1.1. Используя Сайт, Пользователь выражает свое согласие с условиями данного Соглашения.<br />

            1.2. В случае несогласия с условиями Соглашения использование Сайта должно быть немедленно прекращено.
        </p>
        <p><b> 2. Условия использования</b></p>

        <p>  2.1. Сайт предоставляет Пользователям доступ к:<br />
            • Просмотр размещённых на Сайте материалов;<br />
            • Регистрацию и/или авторизацию на Сайте;<br />
            • Размещение или отображение на Сайте любых материалов, включая, но, не ограничиваясь такими, как: тексты, гипертекстовые ссылки, изображения, аудио и видео файлы, сведения и/или иная информация; создаёт договор на условиях настоящего Соглашения в соответствии с положениями ст.437 и 438 Гражданского кодекса Республики Казахстан (ГК РК).<br />
            • Smart услуги<br />
            • Транскрипт<br />
            • Подача заявлений<br />
            • регистрации записи к психологу<br />
            • Выбор элективных дисциплин <br />
            • Подтверждение Ведомостей<br />
            2.2. Университет оставляет за собой право вносить изменения в предоставляемые услуги без предварительного уведомления Пользователей.
        </p>
        <p><b>3. Права и обязанности сторон</b></p>

        <p>
            3.1. Пользователи обязуются не использовать Сайт для распространения нелегального контента и не совершать действий, нарушающих законодательство РК.<br />
            3.2 Пользователь, осуществляя вход в свою учетную запись на сайте, принимает на себя полную ответственность за все действия, совершенные под его учетной записью, включая подачу заявлений, публикацию контента и любые другие действия на сайте. Все действия, выполненные пользователем через его учетную запись, считаются выполненными от его имени и требуют от него должной аккуратности и осознанности в использовании ресурсов сайта<br />
            3.3. Университет обязуется обеспечивать доступность Сайта, за исключением периодов технического обслуживания и форс-мажорных обстоятельств.<br />
        </p>

        <p><b>4. Ответственность</b></p>

        <p>4.1. Университет не несет ответственности за временные сбои и перерывы в работе Сайта и вызванные ими потери информации.<br />
            4.2. Университет не несет ответственности за любой ущерб, причиненный Пользователю в результате использования или невозможности использования Сайта.<br />
        </p>
        <p><b>5. Конфиденциальность</b></p>

        <p>5.1. Университет обязуется соблюдать конфиденциальность личной информации Пользователей, предоставленной ими при использовании Сайта, в соответствии с политикой конфиденциальности.</p>
        <p> <b>6. Изменения в Соглашении</b></p>
        <p>
            6.1. Университет оставляет за собой право вносить изменения в данное Соглашение в одностороннем порядке без необходимости получения согласия Пользователей. Новая версия Соглашения вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой версией Соглашения.
        </p>
    </>
);
export const EnglishContent = () => (
    <>
        <h3>User agreement</h3>
        <p>Effective date: 03/01/2024</p>
        <p>
            This User Agreement (hereinafter referred to as the "Agreement") regulates relations between Astana IT University (hereinafter referred to as the "University") and individuals (hereinafter referred to as "Users") using the resources and services of the website du.astanait.edu.kz (hereinafter referred to as the "Site").</p>
        <p><b> 1. General provisions</b></p>

        <p> 1.1. By using the Site, the User agrees to the terms of this Agreement.<br />
            1.2. In case of disagreement with the terms of the Agreement, the use of the Site must be immediately terminated.
        </p>
        <p><b> 2. Terms of Use</b></p>

        <p>  2.1. The Site provides Users with access to:<br />
            • Viewing the materials posted on the Site;<br />
            • Registration and/or authorization on the Website;<br />
            • Posting or displaying any materials on the Site, including, but not limited to, such as: texts, hypertext links, images, audio and video files, information and/or other information; creates a contract under the terms of this Agreement in accordance with the provisions of Articles 437 and 438 of the Civil Code of the Republic of Kazakhstan (CC RK).<br />
            • Smart services<br />
            • Transcript<br />
            • Submission of applications<br />
            • registration of an appointment with a psychologist<br />
            • Choice of elective subjects<br />
            • Confirmation of Statements <br />
            2.2. The University reserves the right to make changes to the services provided without prior notice to Users.
        </p>
        <p><b>3. Rights and obligations of the parties</b></p>

        <p>
            3.1. Users undertake not to use the Site to distribute illegal content and not to commit actions that violate the legislation of the Republic of Kazakhstan.<br />
            3.2 By logging into his account on the site, the User assumes full responsibility for all actions performed under his account, including submitting applications, publishing content and any other actions on the site. All actions performed by the user through his account are considered to be performed on his behalf and require him to be careful and conscious in using the site's resources<br />
            3.3. The University undertakes to ensure the availability of the Site, with the exception of maintenance periods and force majeure.<br />
        </p>

        <p><b>4. Responsibility</b></p>

        <p>4.1. The University is not responsible for temporary failures and interruptions in the operation of the Site and the loss of information caused by them.<br />
            4.2. The University is not responsible for any damage caused to the User as a result of the use or inability to use the Site.<br />
        </p>
        <p><b>5. Confidentiality</b></p>

        <p>5.1. The University undertakes to respect the confidentiality of Users' personal information provided by them when using the Site, in accordance with the privacy policy.</p>
        <p> <b>6. Changes to the Agreement</b></p>
        <p>
            6.1. The University reserves the right to amend this Agreement unilaterally without the need to obtain the consent of the Users. The new version of the Agreement comes into force from the moment it is posted on the Website, unless otherwise provided by the new version of the Agreement.
        </p>
    </>
);