import { Alert, Button, DatePicker, Form, Input, Modal, Table, Typography } from 'antd';
import moment from 'moment';
import UploadDisease from './UploadDisease';
import AuthenticatedContent from '../../../common/AuthenticatedContent';
import useDisease from './useDisease';
import Page from '../../../ui/Page/Page';

const Disease = () => {
  const {
    diseases,
    studentDiseases,
    visible,
    visibleDiseaseStatus,
    modalValues,
    setModalValues,
    disabled,
    student,
    studentColumns,
    officeColumns,
    setUploaded,
    uploaded,
    setLatestSubmissionRejected,
    latestSubmissionRejected,
    concatValues,
    handleCancel,
    modalOnFinish,
    modalOnFinishFailed,
    getFullDate,
    showDiseaseStatusModal,
    showDiseaseStatusText,
  } = useDisease();

  return (
    <Page title="Disease table">
      <header>
        <AuthenticatedContent role={'STUDENTS'} type={'baseRole'}>
          {latestSubmissionRejected && (
            <Alert
              message="Your latest submission was rejected"
              description="Please notify about your illness again and upload proper certificate"
              type="error"
              closable
              style={{ marginBottom: 20 }}
            />
          )}
          <Typography.Title level={5}>Current Status: {showDiseaseStatusText()}</Typography.Title>
          {(student?.isIll === null || student?.isIll === true) && (
            <Button onClick={showDiseaseStatusModal} disabled={disabled}>
              Notify about my health status
            </Button>
          )}
          <UploadDisease
            student={student}
            cancel={handleCancel}
            visibleDiseaseStatus={visibleDiseaseStatus}
            uploaded={uploaded}
            setUploaded={setUploaded}
            setLatestSubmissionRejected={setLatestSubmissionRejected}
          />
          <Table
            dataSource={studentDiseases}
            columns={studentColumns}
            bordered={true}
            style={{ marginTop: 20 }}
            scroll={{ x: '1200px' }}
          />
        </AuthenticatedContent>
      </header>

      <AuthenticatedContent role={'DEAN'} type={'addRole'}>
        <Table dataSource={diseases} columns={officeColumns} bordered={true} />
        {modalValues && (
          <Modal title="Edit" open={visible} onCancel={handleCancel} width={1000} footer={null}>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={modalOnFinish}
              onFinishFailed={modalOnFinishFailed}
              autoComplete="off"
            >
              <Form.Item label="Fullname">
                <Input
                  name="fullname"
                  value={concatValues(modalValues?.student.surnameEn, modalValues?.student.nameEn)}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item label="Group">
                <Input name="group" value={modalValues?.student.group.title} disabled={true} />
              </Form.Item>

              <Form.Item label="Illness Notification Date">
                <Input
                  name="illnessNotificationDate"
                  value={getFullDate(modalValues?.illnessNotificationDate.toString())}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item label="Recovery Notification Date">
                <Input
                  name="recoveryNotificationDate"
                  value={getFullDate(modalValues?.recoveryNotificationDate.toString())}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item label="Status">
                <Input name="status" value={modalValues?.status} disabled={true} />
              </Form.Item>

              <Form.Item label="Disease code" required={true}>
                <Input
                  required={true}
                  value={modalValues.illnessCertificate?.diseaseCode}
                  onChange={(e) => {
                    modalValues.illnessCertificate.diseaseCode = e.target.value;
                    setModalValues({ ...modalValues });
                  }}
                />
              </Form.Item>
              <Form.Item label="Doctor FullName" required={true}>
                <Input
                  required={true}
                  value={modalValues.illnessCertificate?.doctorFullName}
                  onChange={(e) => {
                    modalValues.illnessCertificate.doctorFullName = e.target.value;
                    setModalValues({ ...modalValues });
                  }}
                />
              </Form.Item>

              <Form.Item label="Issuer Fullname" required={true}>
                <Input
                  required={true}
                  value={modalValues.illnessCertificate?.issuerFullName}
                  onChange={(e) => {
                    modalValues.illnessCertificate.issuerFullName = e.target.value;
                    setModalValues({ ...modalValues });
                  }}
                />
              </Form.Item>

              <Form.Item label="Issued Fullname" required={true}>
                <Input
                  required={true}
                  value={modalValues.illnessCertificate?.issuedFullName}
                  onChange={(e) => {
                    modalValues.illnessCertificate.issuedFullName = e.target.value;
                    setModalValues({ ...modalValues });
                  }}
                />
              </Form.Item>
              <Form.Item label="Issued date" required={true}>
                <DatePicker
                  value={
                    modalValues.illnessCertificate?.issuedDate != null
                      ? moment(modalValues.illnessCertificate.issuedDate)
                      : moment()
                  }
                  onChange={(e, l) => {
                    if (l.length > 0) {
                      modalValues.illnessCertificate.issuedDate = new Date(l);
                      setModalValues({ ...modalValues });
                    } else {
                      modalValues.illnessCertificate.issuedDate = new Date();
                      setModalValues({ ...modalValues });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item label="Certificate start date" required={true}>
                <DatePicker
                  value={
                    modalValues.illnessCertificate?.certificateStartDate != null
                      ? moment(modalValues.illnessCertificate.certificateStartDate)
                      : moment()
                  }
                  onChange={(e, l) => {
                    if (l.length > 0) {
                      modalValues.illnessCertificate.certificateStartDate = new Date(l);
                      setModalValues({ ...modalValues });
                    } else {
                      modalValues.illnessCertificate.certificateEndDate = new Date();
                      setModalValues({ ...modalValues });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item label="Certificate end date" required={true}>
                <DatePicker
                  value={
                    modalValues.illnessCertificate?.certificateEndDate != null
                      ? moment(modalValues.illnessCertificate.certificateEndDate)
                      : moment()
                  }
                  onChange={(e, l) => {
                    if (l.length > 0) {
                      modalValues.illnessCertificate.certificateEndDate = new Date(l);
                      setModalValues({ ...modalValues });
                    } else {
                      modalValues.illnessCertificate.certificateEndDate = new Date();
                      setModalValues({ ...modalValues });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </AuthenticatedContent>
    </Page>
  );
};

export default Disease;
