import React from 'react'
import Page from '../../../../ui/Page'
import { Table } from 'antd'
import useDiplomaApprove from './useDiplomaApprove';
import { GraduationThesisDto } from '../../../../interfaces/Student/StudentGraduationThesisChooseDto';

const OfficeDiplomaApprove = () => {
    const { groupedByTeacher, isGraduationThesisLoading, expandedRowRender, columns, contextHolder } = useDiplomaApprove();

    return (
        <Page>
            <Table
                bordered
                dataSource={groupedByTeacher}
                columns={columns}
                loading={isGraduationThesisLoading}
                rowKey={(record) => record.teacher}
                expandable={{
                    expandedRowRender: record => expandedRowRender(record.thesisList.sort((a: GraduationThesisDto, b: GraduationThesisDto) => a.id - b.id)),
                    rowExpandable: record => record.thesisList.length > 0,
                }}
                scroll={{ x: 800 }}
                pagination={{
                    pageSize: 20,
                }}
            />
            {contextHolder}
        </Page>
    )
}

export default OfficeDiplomaApprove