import { Card } from 'antd';
import React from 'react'

interface GridProps {
    children: React.ReactNode;
}

const StyledGrid: React.FC<GridProps> = ({ children }) => {
    const width = window.innerWidth;
    const windowBreakpointWidth = { xl: 1200 };

    return (
        <Card.Grid
            style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                width: width > windowBreakpointWidth.xl ? "33%" : "100%",
                minHeight: "120px",
            }}
            hoverable={false}
        >
            {children}
        </Card.Grid>
    );
};

export default StyledGrid