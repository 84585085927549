import { useEffect, useState } from 'react';
import moment from 'moment';
import { NotificationService } from '../../../services/NotificationService';
import ReactLinkify from 'react-linkify';
import { Button, Space } from 'antd';
import { MessageDtoResponse } from '../../../interfaces/Notification/response/MessageDtoResponse';
import { NotificationDtoRsponse } from '../../../interfaces/Notification/response/NotificationDtoResponse';

const useNotificationPage = () => {
    const [notification, setNotification] = useState<MessageDtoResponse[]>([]);
    const [students, setStudents] = useState<NotificationDtoRsponse[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [studentCurrentPage, setStudentCurrentPage] = useState(1);
    const [studentTotalPages, setStudentTotalPages] = useState(1);
    const [studentsNumber, setStudentsNumber] = useState<number | undefined>();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMessageId, setSelectedMessageId] = useState<number | null>(null);

    const showModal = (messageId: any) => {
        setSelectedMessageId(messageId);
        setStudentCurrentPage(1);
        // fetchStudents(messageId, 1); 
        setIsModalOpen(true);
    };

    const fetchStudents = (messageId: any, page: number) => {
        const zeroBasedPage = page - 1;
        NotificationService.getStudentsByMessageId(messageId, zeroBasedPage)
            .then((response) => {
                setStudents(response.data.data);
                setStudentTotalPages(response.data.total_pages);
            })
            .catch((error) => console.error(error));
        NotificationService.getNumberOfStudents(messageId)
            .then((response) => {
                setStudentsNumber(response.data);
            })
            .catch((error) => console.error(error));

    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const zeroBasedPage = currentPage - 1;
        NotificationService.getMessage(zeroBasedPage)
            .then((response) => {
                setNotification(response.data.data);
                setTotalPages(response.data.total_pages);
            })
            .catch((error) => console.error(error));
    }, [currentPage]);

    useEffect(() => {
        if (selectedMessageId !== null) {
            fetchStudents(selectedMessageId, studentCurrentPage);
        }
    }, [studentCurrentPage, selectedMessageId]);

    const column = [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            render: (text: any, record: any, index: any) => (currentPage - 1) * 10 + index + 1,
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            render: (text: any, record: any) => (record && record.text && <ReactLinkify>{record.text}</ReactLinkify>),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (text: any, record: any) => (record && record.createdDate ? moment(record.createdDate).format('DD.MM.YYYY') : 'Не найдено'),
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            width: 120,
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button onClick={() => showModal(record.key)} type="primary">
                        View
                    </Button>
                </Space>
            ),
        },
    ];

    const studentColumn = [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            render: (text: any, record: any, index: any) => (studentCurrentPage - 1) * 10 + index + 1,
        },
        {
            title: 'Student',
            dataIndex: 'student',
            key: 'student',
            render: (text: any, record: any) => (record && record.student ? record.student : ''),
        },
        {
            title: 'Is Read',
            dataIndex: 'isRead',
            key: 'isRead',
            render: (text: any, record: any) => (record && record.isRead === true ? 'Yes' : 'No'),
        },
        {
            title: 'Read at',
            dataIndex: 'readAt',
            key: 'readAt',
            width: 180,
            render: (text: any, record: any) => (record && record.readAt ? moment(record.readAt).format('DD.MM.YYYY HH:mm') : ''),
        },
        {
            title: 'Is read in Moodle',
            dataIndex: 'moodleMessageIsRead',
            key: 'moodleMessageIsRead',
            render: (text: any, record: any) => (record && record.moodleMessageIsRead === true ? 'Yes' : 'No'),
        },
        {
            title: 'Read at in Moodle',
            dataIndex: 'moodleMessageReadAt',
            key: 'moodleMessageReadAt',
            render: (text: any, record: any) => (record && record.moodleMessageReadAt ? moment(record.moodleMessageReadAt).format('DD.MM.YYYY HH:mm') : ''),
        },
    ];

    return {
        notification,
        column,
        studentColumn,
        currentPage,
        totalPages,
        setCurrentPage,
        isModalOpen,
        showModal,
        handleCancel,
        students,
        studentTotalPages,
        studentCurrentPage,
        setStudentCurrentPage,
        studentsNumber
    };
};

export default useNotificationPage;
