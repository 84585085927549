import authAxios from '../common/authAxios';
import { GradesDtoResponse } from '../interfaces/GradesDtoResponse';

export class StudentGradeService {
    private static baseUrlForAttendance = '/astanait-schedule-module/api/v1';

    static GradesById(id: number, trimester: number) {
        return authAxios.get<GradesDtoResponse>(
            `${this.baseUrlForAttendance}/avg-grade/grades?id=${id}&trimester=${trimester}`
        );
    }


}
