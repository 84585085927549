import { ImportOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row, Space, Table, Typography, Upload } from 'antd';
import Page from '../../../ui/Page';
import CreateCertificateModal from './CreateCertificateModal';
import EditCertificateModal from './EditCertificateModal';
import useCertificateRegistration from './useCertificateRegistration';

const CertificateRegistration = () => {
  const { data, handlers } = useCertificateRegistration();

  return (
    <Page title={'Регистрация сертификатов'}>
      <Row style={{ marginBottom: 20 }}>
        <Col flex={0}>
          <Input
            placeholder="ФИО, Номер сертификата..."
            onChange={(e) => handlers.searchByParameters(e.target.value)}
            style={{ width: 300 }}
          />
        </Col>
        <Col flex={'auto'}></Col>
        <Col flex={0}>
          <Space direction="horizontal">
            <Button type="primary" onClick={handlers.importFromExcelFile} icon={<ImportOutlined />}>
              Импортировать из .xlsx файла
            </Button>
            <Button type="primary" onClick={handlers.createCertificate} icon={<PlusOutlined />}>
              Зарегистрировать сертификат
            </Button>
          </Space>
        </Col>
      </Row>
      <Table dataSource={data.data} columns={data.columns} bordered loading={data.loading} />
      {data.editData && (
        <EditCertificateModal open={data.editModalOpen} onCancel={handlers.closeModal} data={data.editData} />
      )}
      <CreateCertificateModal open={data.createModalOpen} onCancel={handlers.closeModal} />
      <Modal title={'Импорт'} open={data.isModalOpen} onCancel={handlers.closeModal} footer={null} width={1200}>
        <Space direction="horizontal">
          <Upload {...data.props}>
            <Button size={'large'} icon={<ImportOutlined />} loading={data.loading}>
              Импортировать Файл
            </Button>
          </Upload>
          {data.importData && (
            <Button
              type="primary"
              size="large"
              onClick={handlers.saveCertificateList}
              loading={data.loading}
              icon={<SaveOutlined />}
            >
              Сохранить
            </Button>
          )}
        </Space>
        {data.importData && (
          <>
            <Typography.Title level={3} type="secondary" style={{ marginTop: 20 }}>
              {data.importData.fileName}
            </Typography.Title>
            <Table
              dataSource={data.importData.data}
              columns={data.importColumns}
              bordered
              loading={data.loading}
              scroll={{ x: 'auto' }}
              style={{ marginTop: 20 }}
            />
          </>
        )}
      </Modal>
    </Page>
  );
};

export default CertificateRegistration;
