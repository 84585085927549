import './index.css';
import { Avatar, Badge, Button, Col, Divider, Drawer, Dropdown, Layout, Menu, MenuProps, Modal, Row, Space, Typography } from 'antd';
import { UserOutlined, LogoutOutlined, DownOutlined, MenuOutlined, UserSwitchOutlined, MailOutlined, BellOutlined } from '@ant-design/icons';
import MenuItems from './MenuItems';
import PageContent from './PageContent';
import UserService from '../../services/userService';
import { Link, useHistory } from 'react-router-dom';
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useRef, useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions/useWindowDimensions';
import windowBreakpointWidth from '../../helpers/windowBreakpointWidth';
import { useTheme } from '../../themes/useTheme';
import checkRoleListContains from '../../helpers/checkRoleListContains';
import grantPermission from '../../helpers/grantPermission';
import { NotificationService } from '../../services/NotificationService';
import moment from 'moment';
import { NotificationDtoRsponse } from '../../interfaces/Notification/response/NotificationDtoResponse';
import AuthenticatedContent from '../../common/AuthenticatedContent';
import ReactLinkify from 'react-linkify';

const { Header, Content, Sider } = Layout;
const PUBLIC_URL = process.env.PUBLIC_URL;

const PageLayout = () => {
  const [profile, setProfile] = useState<any>();
  const [count, setCount] = useState<number>(0);
  const [message, setMessage] = useState<NotificationDtoRsponse[]>([]);
  const [visible, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isDarkMode] = useTheme();
  const history = useHistory();
  const { width } = useWindowDimensions();

  const menuItemStyle = {};

  const role = useRef(UserService.getCurrentUser());

  const showModal = (data: any) => {
    setDropdownVisible(false)
    NotificationService.updateNotificationStatus(data.id)
      .then((response) => {
        setModalText(data.message.text);
        setIsModalOpen(true);
        setCount((prevCount) => prevCount && prevCount - 1);
        setMessage((prevMessages) => prevMessages.filter((message) => message.id !== data.id));
      })

  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleMenuItemClick = () => {
    setDropdownVisible(false);
  };

  const items: MenuProps['items'] = [
    ...(checkRoleListContains(role.current.roleList, ['STUDENTS', 'TEACHERS'])
      ? [
        {
          key: '1',
          label: (
            <div onClick={() => history.push('/profile')} style={menuItemStyle}>
              <UserOutlined title="Profile" /> Profile
            </div>
          ),
        },
      ]
      : []),
    ...(checkRoleListContains(role.current.roleList, ['TEACHERS']) && checkRoleListContains(role.current.roleList, ['OFFICE'])
      ? [
        {
          key: '2',
          label: (
            <div
              onClick={() => switchRoles(role.current.baseRole === 'OFFICE' ? 'TEACHERS' : 'OFFICE')}
              style={menuItemStyle}
            >
              <UserSwitchOutlined /> Switch to {role.current.baseRole === 'OFFICE' ? 'professor' : 'office'} role
              <br />
              <Typography.Text type={'secondary'}>
                Current role: {role.current.baseRole === 'OFFICE' ? 'Office' : 'Professor'}
              </Typography.Text>
            </div>
          ),
        },
      ]
      : []),
    {
      type: 'divider',
    },
    {
      key: '3',
      label: (
        <div onClick={UserService.logOut} style={menuItemStyle}>
          <LogoutOutlined title="Logout" /> Logout
        </div>
      ),
    },
  ];

  const notififcationItems = message.map((data, i) => (
    <><div onClick={() => { showModal(data); handleMenuItemClick(); }} className="box">
      <MailOutlined /> {"New notification"}
      <br />
      <Typography.Text type={'secondary'}>
        {moment(data.message.createdAt).format('YYYY-MM-DD')}
      </Typography.Text>
      <Divider style={{ marginBottom: 0 }} />
    </div>
    </>
  ));

  useEffect(() => {
    // ДЛЯ РЕШЕНИЯ ПРОБЛЕМЫ С ПРОСРОЧКОЙ ТОКЕНА ДЛЯ ВХОДА ДЛЯ ВСЕХ ПОЛЬЗОВАТЕЛЕЙ
    if (grantPermission('STUDENT')) {
      UserService.getProfileByPrincipal();
    }
    setProfile(UserService.getCurrentUser());
    NotificationService.getNotification()
      .then((response) => {
        setMessage(response.data);
        setCount(response.data.length);
      })
  }, []);

  const switchRoles = (changeTo: string) => {
    UserService.switchRoles(changeTo);
    window.location.reload();
  };

  const componentDecorator = (href: any, text: any, key: any) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
  interface TextWithLinksProps {
    modalText: string;
  }

  const TextWithLinks: React.FC<TextWithLinksProps> = ({ modalText }) => {
    // Форматируем текст, разделяя на абзацы и строки
    const formattedText = modalText.split('\n\n').map((paragraph, index) => (
      <p key={index}>
        {paragraph.split('\n').map((line, i) => (
          <ReactLinkify key={i} componentDecorator={componentDecorator}>
            {line}
            <br />
          </ReactLinkify>
        ))}
      </p>
    ));

    return <div>{formattedText}</div>;
  };
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Drawer
        placement="left"
        onClose={() => setVisible(false)}
        open={visible}
        drawerStyle={isDarkMode ? { backgroundColor: 'none' } : { backgroundColor: '#001529' }}
        width={250}
        headerStyle={{ padding: 0, marginTop: -1 }}
      >
        <Link to="/">
          <img className="logo" src={`${PUBLIC_URL}/assets/aitu-logo.svg`} alt="logo" />
        </Link>
        <MenuItems role={role.current.baseRole} />
      </Drawer>
      <Sider breakpoint="xl" collapsedWidth="0" trigger={null}>
        <Link to="/">
          <img className="logo" src={`${PUBLIC_URL}/assets/aitu-logo.svg`} alt="logo" />
        </Link>
        <MenuItems role={role.current.baseRole} />
      </Sider>
      <Layout className="site-layout">
        <Header style={{ height: '65px', padding: '0 30px' }}>
          <Row>
            <Col flex={0}>
              <Button
                type="primary"
                icon={<MenuOutlined />}
                onClick={() => setVisible(true)}
                style={width > windowBreakpointWidth.xl ? { display: 'none' } : { display: 'block', marginTop: 20 }}
              />
            </Col>
            <Col flex={'auto'}></Col>
            <AuthenticatedContent role={'STUDENTS'} type={'baseRole'}>
              <Col flex={0} style={{ marginRight: 20 }}>
                <Dropdown
                  overlay={
                    <Menu style={{ cursor: "pointer" }} >
                      <div style={{ display: 'inline-block', maxWidth: '100%', overflow: 'hidden', width: 300 }} >
                        {count && count > 0 ? notififcationItems
                          : (
                            <><span style={{ marginLeft: 80, marginBottom: 100 }}>You have no notifications</span><Divider style={{ marginBottom: 0 }} /></>
                          )}
                        <div style={{ marginLeft: 120 }}><a href="/all-notifications">
                          See all
                        </a></div>
                      </div>
                    </Menu>
                  }
                  trigger={['click']} open={dropdownVisible} onVisibleChange={setDropdownVisible} >
                  <div style={{ cursor: "pointer" }}>
                    <Badge count={count} style={{ backgroundColor: '#1890ff' }}>
                      <Avatar style={{ backgroundColor: 'black' }} icon={<BellOutlined />} />
                    </Badge>
                  </div>
                </Dropdown>

                {count > 0 &&

                  <Modal title="Notifications" open={true} maskClosable={false}
                    keyboard={false} footer={null} >
                    {notififcationItems}
                    <p style={{ color: 'red', marginLeft: 20 }} >You have to read all notifications</p>
                  </Modal>
                }

                <Modal title="Notifications" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                  <TextWithLinks modalText={modalText} />
                </Modal>

              </Col>
            </AuthenticatedContent>

            <Col flex={0}>

              <Space>
                <Dropdown menu={{ items }} trigger={['click']} overlayStyle={{ padding: 0 }}>
                  <div style={{ cursor: "pointer" }}>
                    <Avatar style={{ backgroundColor: '#87d068', marginRight: 10 }} icon={<UserOutlined />} />
                    <Typography.Text
                      style={{ marginRight: 10, color: 'white' }}
                    >{`${profile?.username}`}</Typography.Text>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </Header>
        <Content style={{ margin: '0' }}>
          <PageContent />
        </Content>
      </Layout>
    </Layout>
  );
};

export default PageLayout;
