
import { Card, Form, Tabs, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import PageWrapper from '../../../ui/PageWrapper';
import BackButton from '../../../ui/BackButton';
import StudentInfoEditForm from './StudentInfoEditForm';
import TabPane from 'antd/lib/tabs/TabPane';
import StudentFamilyEditForm from './StudentFamilyEditForm';

interface Props {
  userId?: string;
}

const EditStudentInfo = () => {
  const { userId } = useParams<Props>();
  const [form] = Form.useForm();
  const numericUserId = Number(userId);

  return (
    <div>
      <PageWrapper>
        {userId && <BackButton />}
        <Typography.Title level={1}>Edit Student Info</Typography.Title>
        <Card
          style={{
            borderRadius: 15,

          }}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Personal info" key={'personal-info'}>
              {userId && (
                <StudentInfoEditForm
                  userId={numericUserId}
                  form={form}
                />
              )}

            </Tabs.TabPane>

            <TabPane tab="Student's Family" key={'student-family'} >
              {userId && (
                <StudentFamilyEditForm
                  userId={numericUserId}
                />
              )}
            </TabPane>
          </Tabs>
        </Card>
      </PageWrapper>

    </div>
  );
};

export default EditStudentInfo;

