import { DownloadOutlined, MinusOutlined, PlusCircleFilled, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, InputNumber, Modal, Select, Space, Typography, Upload, UploadProps } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { RcFile } from 'antd/lib/upload';
import { useState, useEffect } from 'react';
import useCreateOrderForm from '../../../../../hooks/Order/useCreateOrderForm';
import { SsciCategoriesDtoResponse } from '../../../../../interfaces/Ssci/SsciCategoriesDtoResponse';
import { FileService } from '../../../../../services/file/FileService';
import { SsciService } from '../../../../../services/student/SsciService';
import BackButton from '../../../../../ui/BackButton';
import Page from '../../../../../ui/Page/Page';
import StudentsTable from '../../../StudentsDetailsPage/StudentsTable';
import { successNotification } from '../../../../../helpers/successNotification';
import { errorNotification } from '../../../../../helpers/errorNotification';
import { StudentDtoResponse } from '../../../../../interfaces/Student/StudentDtoResponse';
import StudentSearchForm from '../../../StudentsDetailsPage/StudentSearchForm';

interface Props {
  eventId: number;
}

const SsciAddStudentPage = ({ eventId }: Props) => {
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState<SsciCategoriesDtoResponse[]>([]);
  const [studentsList, setStudentsList] = useState<StudentDtoResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { selectedStudent, setSelectedStudent, selectedStudentId, setSelectedStudentId } = useCreateOrderForm({});
  const [isLastFieldFilled, setIsLastFieldFilled] = useState(false);

  const handleData = (studentsList: StudentDtoResponse[], loading: boolean) => {
    setStudentsList(studentsList);
    setLoading(loading);
  };
  //   useEffect(() => {
  //     if (eventId) {
  //       SsciService.getStudentEventByEventId(eventId)
  //         .then(({ data: dto }) => {
  //           form.setFieldsValue({ dto });
  //           setIsUpdate(true);
  //         })
  //     }
  //   }, [eventId]);

  useEffect(() => {
    formModal.setFieldsValue({
      student: selectedStudent[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStudent, selectedStudentId]);

  const uploadProps: UploadProps = {
    maxCount: 1,
    beforeUpload: (file: RcFile) => {
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        errorNotification(`${file.name} is not pdf file!`, 'Please, upload only pdf file!');
      }
      return isPDF || Upload.LIST_IGNORE;
    },
    onChange(e) {
      e.file.status = 'done';
    },
  };

  const handleCreate = (dto: any[]) => {
    // eslint-disable-next-line array-callback-return
    dto.map((v) => {
      // fileId && (v.fileId = fileId)
      let formData = new FormData();
      v.eventId = eventId;
      v.ssciFile && formData.append('ssciFile', v.ssciFile.originFileObj);
      v.ssciFile
        ? SsciService.uploadStudentEventFile(formData, v.fileId)
          .then(({ data }) => {
            v.fileId = data;
            SsciService.createAndUpdateStudentEvent(v, eventId).then(() => {
              successNotification('Успешно создано!');
            });
          })
        : SsciService.createAndUpdateStudentEvent(v, eventId)
          .then(() => {
            successNotification('Успешно создано!');
          })
    });
  };

  const handleDownloadFile = (fileId: number) => {
    FileService.getFileById(fileId, 'Event file')
      .then(() => successNotification('Успешно создано!'));
  };

  const handleInputChange = (index: number, value: any, fields: any) => {
    // Check if the input field is filled
    if (value) {
      setIsLastFieldFilled(index === fields.length - 1 && value !== '');
    }
    else {
      setIsLastFieldFilled(false);
    }
  };

  useEffect(() => {
    SsciService.getCategories()
      .then(({ data }) => setCategories(data))
  }, []);

  return (
    <Page title="SSCI Event Detail">
      <BackButton />
      <Space style={{ alignItems: 'center', width: '100%' }} direction={'vertical'} size="small">
        <Form
          form={form}
          initialValues={{ remember: true }}
          size="large"
          style={{ width: '100%' }}
          onFinish={(e) => handleCreate(e.dto)}
        >
          <Form.List name="dto" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                      <Form.Item
                        {...restField}
                        label="Количество часов"
                        name={[name, 'hours']}
                        rules={[{ required: true, message: 'Введите количество часов' }]}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item {...restField} label="Ссылка" name={[name, 'link']}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Файл"
                        name={[name, 'ssciFile']}
                        valuePropName={'ssciFile'}
                        getValueFromEvent={(e) => e.file}
                      >
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined />}>Загрузить файл</Button>
                        </Upload>
                      </Form.Item>
                    </Space>
                    <Form.Item {...restField} key={key} label="Информация" name={[name, 'info']}>
                      <TextArea />
                    </Form.Item>

                    {form.getFieldValue(['dto', name, 'fileId']) && (
                      <Space direction="horizontal" style={{ width: '100%', justifyContent: 'flex-end' }}>
                        <Button
                          icon={<DownloadOutlined />}
                          onClick={() => handleDownloadFile(form.getFieldValue(['dto', name, 'fileId']))}
                        >
                          Скачать файл
                        </Button>
                      </Space>
                    )}

                    <Space direction={'vertical'} style={{ width: '100%' }}>
                      {!form.getFieldValue(['dto', name, 'studentRoleDtoRequest']) && (
                        <Button icon={<PlusCircleFilled />} onClick={() => setShowModal(true)}>
                          Добавить студента
                        </Button>
                      )}
                      <Form.Item
                        key={key}
                        {...restField}
                        rules={[{ required: true, message: 'Добавьте студента' }]}
                        name={[name, 'studentRoleDtoRequest']}
                      >
                        <Card bordered>
                          <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                            <Space direction={'vertical'}>
                              <Typography>
                                {form.getFieldValue(['dto', name, 'studentRoleDtoRequest', 'student', 'fullName'])}
                              </Typography>
                            </Space>
                            <Typography>
                              {
                                categories.find(
                                  (c) => c.id === form.getFieldValue(['dto', name, 'studentRoleDtoRequest', 'roleId'])
                                )?.nameRu
                              }
                            </Typography>
                          </Space>
                        </Card>
                      </Form.Item>
                    </Space>
                    <Form.Item {...restField} name={[name, 'studentRoleDtoRequest']}>
                      <Modal
                        title={'Добавить студента из списка'}
                        open={showModal}
                        onCancel={() => setShowModal(false)}
                        onOk={formModal.submit}
                      >
                        <Form
                          form={formModal}
                          onFinish={(e) => {
                            form.setFieldValue(['dto', name, 'studentRoleDtoRequest'], e);
                            setShowModal(false);
                            handleInputChange(name, e, fields)
                          }}
                        >
                          <Space direction="vertical" size={'large'}>
                            <Form.Item
                              name={'student'}
                              rules={[{ required: true, message: 'Выберите студента' }]}
                            >
                              <StudentSearchForm onData={handleData} />

                              {studentsList.length > 0 && <Typography.Title level={5}>Найдено студентов: {studentsList.length}</Typography.Title>}
                              {studentsList.length > 0 && (
                                <StudentsTable
                                  loading={loading}
                                  data={studentsList}
                                  short
                                  selectableCheckbox={true}
                                  selectedStudentId={selectedStudentId}
                                  setSelectedStudentId={setSelectedStudentId}
                                  selectedStudent={selectedStudent}
                                  setSelectedStudent={setSelectedStudent}
                                />
                              )}
                            </Form.Item>
                            <Form.Item
                              name={'roleId'}
                              rules={[{ required: true, message: 'Выберите роль' }]}
                            >
                              <Select placeholder={'Выберите категорию'}>
                                {categories.map((category) => (
                                  <Select.Option key={category.id} value={category.id}>
                                    {category.nameRu}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Space>
                        </Form>
                      </Modal>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="dashed"
                        danger
                        onClick={() => {
                          var lastName = fields.at(fields.length - 1)?.name;
                          if (lastName) {
                            if (lastName === name || form.getFieldValue(['dto', lastName, 'studentRoleDtoRequest'])) {
                              setIsLastFieldFilled(true)
                            }
                          }
                          remove(name);
                        }}
                        block
                        icon={<MinusOutlined />}
                      >
                        Удалить поле
                      </Button>
                    </Form.Item>
                    {/* {(selectedStudent.length !== 0 && selectedStudentId.length !== 0) && (
                                            <Table columns={columns} dataSource={selectedStudent} loading={loading} />
                                        )} */}
                  </div>
                ))}
                {isLastFieldFilled || fields.length === 0 ? (
                  <Form.Item>
                    <Button type="dashed" onClick={() => { add(); setIsLastFieldFilled(false) }} block icon={<PlusOutlined />}>
                      Добавить поле
                    </Button>
                  </Form.Item>
                ) : null}
                <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
                  <Form.Item>
                    <Space>
                      <Button type="primary" htmlType="submit" disabled={fields.length === 0}>
                        Сохранить
                      </Button>
                    </Space>
                  </Form.Item>
                </Space>
              </>
            )}
          </Form.List>
        </Form>
      </Space>
    </Page >
  );
};

export default SsciAddStudentPage;
