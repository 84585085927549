import { MailFilled, PhoneFilled } from '@ant-design/icons';
import { Button, Col, Input, Row, Space, Spin, Typography } from 'antd';
import Center from '../../../ui/Center';
import Page from '../../../ui/Page/Page';
import CertificateInfoList from './CertificateInfoList';
import useCertificateValidation from './useCertificateValidation';

const CertificateVerificationPage = () => {
  const { data, handlers } = useCertificateValidation();
  const PUBLIC_URL = process.env.PUBLIC_URL;

  return (
    <Page style={{ height: '100%', position: 'relative', marginTop: -20 }}>
      <Row style={{ margin: 0 }}>
        <Col lg={8} xs={24}>
          <Space
            direction="vertical"
            style={{ borderBottom: '3px solid #0693E3', paddingBottom: 20, marginBottom: 20 }}
          >
            <Typography.Title level={4} style={{ fontWeight: 500, fontSize: 26, margin: 0 }}>
              Верификация сертификатов
            </Typography.Title>
            <Typography.Title level={5} style={{ fontWeight: 400, fontSize: 20, margin: 0 }}>
              Центр компетенции и совершенства <br /> Astana IT University
            </Typography.Title>
          </Space>
        </Col>
        <Col lg={8} xs={24}>
          <Space
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <img src={`${PUBLIC_URL}/assets/color-aitu-logo.svg`} alt="logo" width={250} />
          </Space>
        </Col>
        <Col lg={8} xs={24}></Col>
      </Row>
      <Center lg={6} style={{ textAlign: 'center', marginTop: 20 }}>
        <Typography.Title level={4} type="secondary">
          Введите номер сертификата
        </Typography.Title>
        <Input
          size={'large'}
          onPressEnter={(e) => handlers.verifyCertificate(e.currentTarget.value)}
          onChange={handlers.handleInputChange}
          style={{ textAlign: 'center' }}
          value={data.codeString}
        />
        <Button
          type="primary"
          size={'large'}
          style={{ marginTop: 10 }}
          block
          onClick={() => handlers.verifyCertificate(data.codeString)}
          disabled={data.loading}
        >
          Проверить
        </Button>
      </Center>
      <Center lg={12}>
        {data.loading ? (
          data.listDisabled ? (
            <div style={{ textAlign: 'center', marginTop: 50, paddingBottom: 20 }}>
              <Spin spinning={data.loading} />
            </div>
          ) : (
            <CertificateInfoList disabled={data.listDisabled} data={data.listData} loading={data.loading} />
          )
        ) : (
          <CertificateInfoList disabled={data.listDisabled} data={data.listData} loading={data.loading} />
        )}
      </Center>
      <div
        style={{
          marginTop: 30,
          display: 'block',
        }}
      >
        <Space
          direction={'vertical'}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 200,
            display: 'block',
          }}
        >
          <Space>
            <PhoneFilled />
            <a href="tel:+771722645714">
              <Typography.Link>+7 (7172) 264 57 14</Typography.Link>
            </a>
          </Space>
          <Space>
            <MailFilled />
            <a href="mailto:ccecorp@astanait.edu.kz">
              <Typography.Link>ccecorp@astanait.edu.kz</Typography.Link>
            </a>
          </Space>
        </Space>
      </div>
    </Page>
  );
};

export default CertificateVerificationPage;
