import { Button, Form, Input, Spin, Table, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { StudentDtoResponse } from '../../../../interfaces/Student/StudentDtoResponse';
import useStudentsTable from './useStudentsTable';
import Spinner from '../../../../ui/Spinner';

interface Props {
  loading: boolean;
  selectable?: boolean;
  selectableCheckbox?: boolean;
  selectedStudentId?: any;
  setSelectedStudentId?: any;
  selectedStudent?: any;
  setSelectedStudent?: any;
  short?: boolean;
  data: StudentDtoResponse[];
}


const StudentsTable = ({
  loading,
  data,
  selectedStudentId,
  setSelectedStudentId,
  selectedStudent,
  setSelectedStudent,
  short,
}: Props) => {
  const navigate = useHistory();
  const { columns, filteredData, filteredData2, selectedStudentColumns, style, tableVisible, form, onFormFinish, handleSelectAll, createLoading } = useStudentsTable(data, navigate, {
    selectedStudentId,
    setSelectedStudentId,
    selectedStudent,
    setSelectedStudent,
    loading
  });

  return (
    <>
      <div style={{ position: 'relative' }}>
        {createLoading && (
          <div style={{
            position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 9999
          }}>
            <Spinner size={'default'} />
          </div>
        )}
        <div style={{ ...style, marginTop: '40px' }}
        ><Typography.Title level={5}>Выбранные студенты</Typography.Title>
          {tableVisible && <Table columns={selectedStudentColumns} dataSource={filteredData2} loading={loading} />}</div>
        <div style={{ ...style, marginTop: '40px' }}>
          <Button type="primary" htmlType="submit" style={{ marginLeft: "20px" }} onClick={() => handleSelectAll(filteredData)}>
            Выбрать все
          </Button>
          <Table
            columns={columns}
            dataSource={filteredData}
            style={{ paddingTop: 20 }}
            loading={loading}

          />
        </div>
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          size="large"
          form={form}
          onFinish={onFormFinish}
          style={style}
        ><Form.Item
          label="Сообщение"
          name="message"
          rules={[{ required: true, message: 'Введите сообщение' }]}
          style={{ marginBottom: '10px' }}
        >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ marginTop: "10px" }} >
              Создать
            </Button>

          </Form.Item></Form></div></>
  )
};

export default StudentsTable;
