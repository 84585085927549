import { useEffect, useState } from 'react';
import { TeacherService } from '../../../services/TeacherService';
import { useHistory } from 'react-router';
import { HRService } from '../../../services/HRService';
import { DatePickerProps, Form } from 'antd';
import { TeacherDtoRequest } from '../../../interfaces/Teacher/TeacherDtoRequest';
import { DisciplinesService } from '../../../services/DisciplinesService';
import { DepartmentDtoResponse } from '../../../interfaces/DepartmentDtoResponse';
import grantPermission from '../../../helpers/grantPermission';
import { TeacherPositionDtoResponce } from '../../../interfaces/Teacher/TeacherPositionDtoResponce';
import { TeacherTypeDtoResponse } from '../../../interfaces/Teacher/TeacherTypeDtoResponse';
import { TeacherStatusDtoResponse } from '../../../interfaces/Teacher/TeacherStatusDtoResponse';
import { successNotification } from '../../../helpers/successNotification';
import { TeacherAdditionalInfoDtoRequest } from '../../../interfaces/Teacher/TeacherAdditionalInfoDtoRequest';

const useHR = () => {
  const [params, setParams] = useState({});
  const [form] = Form.useForm();

  const [teachers, setTeachers] = useState<any>();
  const [searchValue, setSearchValue] = useState('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [positionsList, setPositionsList] = useState<TeacherPositionDtoResponce[]>();
  const [teacherTypeList, setTeacherTypeList] = useState<TeacherTypeDtoResponse[]>();
  const [teacherStatusList, setTeacherStatusList] = useState<TeacherStatusDtoResponse[]>();
  const [deps, setDeps] = useState<DepartmentDtoResponse[]>([]);
  const [birthDate, setBirthDate] = useState<Date>(new Date());

  const [refresher, setRefresher] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const typeTitles: { [key: string]: string } = {
    'F_UNIVERSITY': 'Foreign university',
    'F_UNIVERSITY_COUNTRY': "Foreign country",
    'SPECIALITY': "Speciality",
    'F_START_DATE': "Working start date",
    'F_END_DATE': "Working end date"
  };

  useEffect(() => {
    setLoading(true);
    if (grantPermission('HR')) {
      TeacherService.getAllTeachers(params).then((res) => {
        setTeachers(res.data);
      })
        .finally(() => setLoading(false));
    } else {
      TeacherService.getAllTeachersPublic(params).then((res) => {
        setTeachers(res.data);
      })
        .finally(() => setLoading(false));
    }
  }, [params]);

  useEffect(() => {
    if (grantPermission('HR')) {
      TeacherService.getPositionsList()
        .then((res) => setPositionsList(res.data))

      TeacherService.getTypesList()
        .then((res) => setTeacherTypeList(res.data))

      TeacherService.getStatusList()
        .then((res) => setTeacherStatusList(res.data))
    }
    DisciplinesService.getDepartmentsPublic()
      .then((res) => {
        setDeps(res.data);
      })
  }, [refresher]);

  const handleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const navigate = (id: number) => {
    history.push(`/teacher/${id}`);
  };

  const handleDeleteTeacher = (id: number) => {
    HRService.deleteTeacher(id, 2)
      .then(() => successNotification('Teacher deleted successfully!'))
  };

  const handleUpdateTeacher = (id: number, teacher: TeacherDtoRequest, additionalInfo: TeacherAdditionalInfoDtoRequest[]) => {
    teacher.birthDate = birthDate;
    teacher.additionalInfo = additionalInfo.filter(value => value);

    HRService.updateTeacher(id, teacher)
      .then(() => successNotification('Teacher was successfully updated!'))
      .finally(() => setRefresher(!refresher));
    setIsEditMode(false);
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setBirthDate(new Date(dateString));
  };

  const handleParams = (data?: any) => {
    setLoading(true);
    if (grantPermission('HR')) {
      TeacherService.getAllTeachers(params).then((res) => {
        setTeachers(res.data);
      })
        .finally(() => setLoading(false));
    } else {
      TeacherService.getAllTeachersPublic(params).then((res) => {
        setTeachers(res.data);
      })
        .finally(() => setLoading(false));
    }
    setParams({ fullName: searchValue, ...data, ...form.getFieldsValue() });
  };

  return {
    data: {
      teachers,
      searchValue,
      history,
      loading,
      isEditMode,
      deps,
      positionsList,
      teacherStatusList,
      teacherTypeList,
      form,
      refresher,
      typeTitles
    },
    handlers: {
      navigate,
      onChange,
      handleSearchChange,
      handleDeleteTeacher,
      handleUpdateTeacher,
      handleEditMode,
      handleParams,
      setParams,
    },
  };
};

export default useHR;
