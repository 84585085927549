import React, { useEffect,useState } from 'react';
import { Button, Card, DatePicker, Form, Input, Modal, Select, Table, message } from 'antd';
import { StudentService } from '../../../services/StudentService';
import { StudentFamilyDtoResponse } from '../../../interfaces/Student/StudentFamilyDtoResponse';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons';

interface Props {
    userId: number;
  }
const StudentFamilyEditForm: React.FC<Props> = ({ userId}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [familyData, setFamilyData] =useState<StudentFamilyDtoResponse[]>([]);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [studentId, setStudentId] = useState<number>();
  


  const fetchStudentFamilyInfo = async () => {
    try {
      if (!userId) return;

      const studentIdResponse = await StudentService.getStudentIdByUserId(userId);
      const fetchedStudentId = studentIdResponse.data.id;
      setStudentId(fetchedStudentId);

      const response = await StudentService.getStudentFamilyByStudentId(fetchedStudentId);
      setFamilyData(response.data);
    } catch (error) {
      console.error('Error fetching student info:', error);
      message.error('Failed to fetch student info');
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
};

const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
};


  useEffect(() => {
    fetchStudentFamilyInfo();
  }, [userId]);


  
  const handleAddFamilyMember = async (values: any) => {
    try {
        const response = await StudentService.createStudentFamily({ ...values, student: studentId });
        setFamilyData([...familyData, response.data]);
        message.success('New family member added successfully');
        setIsModalVisible(false);
        form.resetFields();
        fetchStudentFamilyInfo();
    } catch (error) {
        console.error('Error adding new family member:', error);
        message.error('Failed to add new family member');
    }
};

const handleEdit = (id: number) => {
  setEditingId(id);
};

const handleSave = async (record: any) => {
  try {
      await StudentService.updateStudentFamily(record, record.id);
      setEditingId(null);
      message.success('Family member details updated successfully');
  } catch (error) {
      console.error('Error updating family member details:', error);
      message.error('Failed to update family member details');
  }
};

const handleCancelEdit = () => {
  setEditingId(null);
};

const handleDelete = async (id: number) => {
  try {
      await StudentService.deleteStudentFamily(id);
      const updatedFamilyData = familyData.filter(member => member.id !== id);
      setFamilyData(updatedFamilyData);

      message.success('Family member deleted successfully');
  } catch (error) {
      console.error('Error deleting family member:', error);
      message.error('Failed to delete family member');
  }
};


const columns = [
  {
      title: 'Name',
      dataIndex: 'nameKz',
      key: 'nameKz',
      render: (text: string, record: any) => (
          <EditableCell
              editable={editingId === record.id}
              value={text}
              onChange={(value) => handleFieldChange(record.id, 'nameKz', value)}
          />
      ),
  },
  {
      title: 'Surname',
      dataIndex: 'surnameKz',
      key: 'surnameKz',
      render: (text: string, record: any) => (
          <EditableCell
              editable={editingId === record.id}
              value={text}
              onChange={(value) => handleFieldChange(record.id, 'surnameKz', value)}
          />
      ),
  },
  {
      title: 'Patronymic',
      dataIndex: 'patronymicKz',
      key: 'patronymicKz',
      render: (text: string, record: any) => (
          <EditableCell
              editable={editingId === record.id}
              value={text}
              onChange={(value) => handleFieldChange(record.id, 'patronymicKz', value)}
          />
      ),
  },
  {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (text: string, record: any) => (
          <EditableCell
              editable={editingId === record.id}
              value={text}
              onChange={(value) => handleFieldChange(record.id, 'phone', value)}
          />
      ),
  },
  {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: any) => (
          <div>
              {editingId === record.id ? (
                <div>
                <Button style={{marginRight:10}} type="primary" onClick={() => handleSave(record)}><CheckOutlined /> Save</Button>
                <Button onClick={handleCancelEdit}><CloseOutlined /> Cancel</Button>
            </div>
                  //<Button type="primary" onClick={() => handleSave(record)}>Save</Button>
              ) : (
                <div>
                  <Button style={{marginRight:10}} onClick={() => handleEdit(record.id)}  value="default"><EditOutlined /> Edit</Button>
                  <Button onClick={() => handleDelete(record.id)} type="primary" danger ghost><DeleteOutlined /> Delete</Button>
                </div>
                 
              )}
          </div>
      ),
  },
];

const handleFieldChange = (id: number, field: string, value: any) => {
  const newData = [...familyData];
  const index = newData.findIndex((item) => id === item.id);
  if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, [field]: value });
      setFamilyData(newData);
  }
};
  return (
    <div>
       <Button type="primary" onClick={showModal} style={{marginBottom:15, float:"right"}}><UserAddOutlined /> Add Family Member</Button>
      <Table
        columns={columns}
        dataSource={familyData}
        rowKey="id"
        pagination={false}
        bordered
      />

<Modal
                title="Add Family Member"
                visible={isModalVisible}
                onOk={form.submit}
                onCancel={handleCancel}
            >
                <Form form={form} onFinish={handleAddFamilyMember} layout="vertical">
                    <Form.Item name="nameKz" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="surnameKz" label="Surname" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="patronymicKz" label="Patronymic">
                        <Input />
                    </Form.Item>
                    <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
    </div>
    
  
  );
};
const EditableCell: React.FC<{
  editable: boolean;
  value: any;
  onChange: (value: any) => void;
}> = ({ editable, value, onChange }) => {
  return editable ? (
      <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={{ width: '100%' }}
      />
  ) : value;
};
export default StudentFamilyEditForm;
