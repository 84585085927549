import styles from './style.module.css';
import { Typography, Table, Button } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import useEducationalProgramsCatalog from './useEducationalProgramsCatalog';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

const EducationalProgramsCatalogPage = () => {
  const { educationalPrograms, columns } = useEducationalProgramsCatalog();

  return (
    <PageWrapper>
      <section>
        <header className={styles.header}>
          <Title level={3} className={styles.title}>
            Список Образовательных Программ
          </Title>
          <Link to="/educational-programs/create">
            <Button type="primary" icon={<PlusOutlined />}>
              Создать образовательную программу
            </Button>
          </Link>
        </header>
        <Table
          pagination={false}
          dataSource={educationalPrograms.map((educationalProgram) => ({
            key: educationalProgram.id,
            educationalProgramId: educationalProgram.id,
            educationalProgramName: `${educationalProgram.titleRu} / ${educationalProgram.titleKz} / ${educationalProgram.titleEn}`,
            educationalProgramType: educationalProgram.typeOfEducationProgram,
            applicationType: educationalProgram.typeOfResponse,
            status: educationalProgram.status,
            educationStartedDate: educationalProgram.educationStartedDate,
            academicDegree: educationalProgram.academicDegree.titleRu,
          }))}
          columns={columns}
        />
      </section>
    </PageWrapper>
  );
};

export default EducationalProgramsCatalogPage;
