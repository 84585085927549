import authAxios from "../common/authAxios";
import { TeacherDtoRequest } from "../interfaces/Teacher/TeacherDtoRequest";

export class HRService {
    static createTeacher(teacher: TeacherDtoRequest) {
        return authAxios.post(`/astanait-teacher-module/api/v1/teacher/create`, teacher);
    }

    static deleteTeacher(teacherId: number, statusId: number){
        return authAxios.delete(`/astanait-teacher-module/api/v1/teacher/delete?teacherId=${teacherId}&status=${statusId}`);
    }

    static updateTeacher(teacherId: number, teacher: TeacherDtoRequest){
        return authAxios.put(`/astanait-teacher-module/api/v1/teacher/update?teacherId=${teacherId}`, teacher);
    }
}
