import useStudentPersonalSsciPage from "./useStudentPersonalSsciPage";
import styles from './style.module.css';
import { Typography, Table } from 'antd';
import PageWrapper from "../../../ui/PageWrapper";

const { Title } = Typography;

const StudentPersonalSsciPage = (
    //{ studentId }: Props
    { location }: any
) => {
    const queryParams = new URLSearchParams(location.search);
    const studentIdString = queryParams.get('studentId');
    const studentId = studentIdString ? parseInt(studentIdString) : undefined;

    const { studentSsci, column } = useStudentPersonalSsciPage(studentId);

    return (
        <PageWrapper>
            <section>
                <header className={styles.header}>
                    <Title level={3} className={styles.title}>
                        Ssci Applications Page
                    </Title>

                </header>
                <Table
                    pagination={false}
                    dataSource={studentSsci.map((studentSsci) => ({
                        applicationId: studentSsci.id,
                        role: studentSsci.roleId.nameEn,
                        indexStatus: studentSsci.indexStatus ? studentSsci.indexStatus.nameRu : '',
                        eventName: studentSsci.eventName,
                        eventDate: studentSsci.eventDate,
                        hours: studentSsci.hours,
                        info: studentSsci.info,
                        points: studentSsci.points

                    }))}
                    columns={column}
                />
            </section>
        </PageWrapper>
    )
}
export default StudentPersonalSsciPage