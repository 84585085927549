import PageWrapper from '../../../ui/PageWrapper';
import styles from './academic.module.css';
import { Typography, Button, Space, Input } from 'antd';
import StudentsFilter from './StudentsFilter';
import StudentSearch from './StudentSearch';
import StudentGroupFilter from './StudentGroupFilter';
import TranscriptTable from '../TranscriptPage/TranscriptTable';
import AddDisciplineModal from './AddDisciplineModal';
import useTranscript from './useTranscript';

const { Title } = Typography;
const { Search } = Input;

const AcademicDepartmentTranscript = () => {
  const {
    exportTranscript,
    exportTranscriptEn,
    showModal,
    handleSearchByButton,
    onGroupSelect,
    handleSearchStudents,
    student,
    studentWithGroup,
    groupList,
    studentsList,
    studentsListWithGroup,
    setStudentsListWithGroup,
    isModalVisible,
    setIsModalVisible,
    isFilterVisible,
    selectedGroup,
    setSelectedGroup,
    selectedStudent,
    setSelectedStudent,
    searchValue,
    setSearchValue,
    transcript,
    rerender,
    setRerender,
  } = useTranscript();

  return (
    <PageWrapper>
      <section>
        <header className={styles.header}>
          <Title level={3} className={styles.title}>
            Student Transcript
          </Title>
        </header>
        <Space direction="vertical">
          <Space direction="vertical" size={20}>
            <Button type={'primary'} onClick={handleSearchByButton}>
              {isFilterVisible ? 'Поиск по ФИО' : 'Поиск по Группе и ФИО'}
            </Button>
            {isFilterVisible ? (
              <Space size={20} direction={'vertical'}>
                <StudentGroupFilter
                  placeholder="Группа обучающихся"
                  selectedOption={selectedGroup}
                  onSelect={(value) => onGroupSelect(value)}
                  options={[...groupList]}
                ></StudentGroupFilter>

                <StudentsFilter
                  placeholder="ФИО Студента"
                  selectedOption={selectedStudent}
                  onSelect={(value) => setSelectedStudent(value)}
                  options={[...studentsList]}
                ></StudentsFilter>
              </Space>
            ) : (
              <Space direction="vertical" size={20}>
                <Search
                  placeholder="Введите ФИО Студента"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onSearch={(value) => handleSearchStudents(value)}
                  enterButton
                  style={{ width: 600 }}
                />

                {studentsListWithGroup.length > 0 && (
                  <StudentSearch
                    placeholder="Список Студентов"
                    selectedOption={selectedStudent}
                    onSelect={(value) => setSelectedStudent(value)}
                    options={[...studentsListWithGroup]}
                  />
                )}
              </Space>
            )}
            <Space size={20}>
              {transcript && (
                <>
                  <Button type="primary" onClick={exportTranscript} disabled={!selectedStudent}>
                    Экспорт транскрипта
                  </Button>
                  <Button type="primary" onClick={exportTranscriptEn} disabled={!selectedStudent}>
                    Экспорт транскрипта (на английском)
                  </Button>
                </>
              )}

              <Button type="primary" onClick={showModal} disabled={!selectedStudent}>
                Добавить дисциплину
              </Button>
            </Space>
          </Space>
          <br />
          <br />
          <AddDisciplineModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            isFilterVisible={isFilterVisible}
            width={472}
            rerender={rerender}
            setRerender={setRerender}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            setSelectedStudent={setSelectedStudent}
            selectedStudent={selectedStudent}
            searchStudentNames={studentsListWithGroup}
            setSearchStudentNames={setStudentsListWithGroup}
          />
        </Space>
        {selectedStudent && (
          <>
            <Typography.Title level={2}>
              {isFilterVisible
                ? `${student?.name} ${student?.surname}`
                : `${studentWithGroup?.name} ${studentWithGroup?.surname}`}
            </Typography.Title>
            <TranscriptTable
              key={student ? student.userId : studentWithGroup ? studentWithGroup.userId : selectedStudent}
              userId={student ? student.userId : studentWithGroup ? studentWithGroup.userId : selectedStudent} userRerender={rerender} />
          </>
        )}
        {/* </div> */}
      </section>
    </PageWrapper>
  );
};

export default AcademicDepartmentTranscript;
