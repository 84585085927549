import { StudentsListDtoResponse } from '../../../../../interfaces/Student/StudentsListDtoResponse';
import { Select } from 'antd';

type Options = StudentsListDtoResponse[];

const { Option } = Select;

interface Props {
  placeholder: string;
  selectedOption: number | undefined;
  options: Options;
  onSelect: (value: number) => void;
  width?: number;
}

const StudentsFilter = ({ placeholder, selectedOption, options, onSelect, width }: Props) => {
  return (
    <Select placeholder={placeholder} value={selectedOption} onSelect={onSelect} style={{ width: 400 }}>
      {options.map((option) => (
        <Option key={option.id} value={option.id}>
          {`${option.id} ${option.name} ${option.surname} ${option.patronymic}`}
        </Option>
      ))}
    </Select>
  );
};
export default StudentsFilter;
