import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, notification, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OrderTypeDtoResponse } from '../../interfaces/Order/OrderTypeDtoResponse';
import { OrdersService } from '../../services/OrdersService';
import { UploadProps } from 'antd/es/upload/interface';
import { RcFile } from 'antd/lib/upload';
import { OrderDtoRequest } from '../../interfaces/Order/OrderDtoRequest';
import { StudentDtoResponse } from '../../interfaces/Student/StudentDtoResponse';
import { CourseSelectResponse } from '../../interfaces/CourseSelectResponse';
import { TablePaginationConfig } from 'antd/es/table';
import { TranscriptService } from '../../services/TranscriptService';
import { errorNotification } from '../../helpers/errorNotification';
import { successNotification } from '../../helpers/successNotification';
import { OrderCategoryDtoResponse } from '../../interfaces/Order/OrderCategoryDtoResponse';

interface Config {
  orderId: number;
}

interface DataType {
  key: number | string;
  id: number;
  userId: number;
  fullName: string;
  group: string;
}

interface FilterRow {
  text: string;
  value: string;
}

const useEditOrderForm = ({ orderId }: Config) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tableVisible, setTableVisible] = useState<boolean>(false);
  const [areButtonsAvailable, setAreButtonsAvailable] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number | undefined>(0);
  const [form] = Form.useForm();

  const [orderFile, setOrderFile] = useState<any>();
  const [orderName, setOrderName] = useState<string>('');

  const [tableData, setTableData] = useState<StudentDtoResponse[]>([]);
  const [totalNumber, setTotalNumber] = useState(0);
  const [ordersTypes, setOrderTypes] = useState<OrderTypeDtoResponse[]>([]);
  const [ordersCategories, setOrdersCategories] = useState<OrderCategoryDtoResponse[]>([]);
  const [courseOptions, setCourseOptions] = useState<CourseSelectResponse[]>([]);
  const [filterRows, setFilterRows] = useState<FilterRow[]>([]);

  const [selectedOrderType, setSelectedOrderType] = useState<number | undefined>();
  const [selectedEducationalDegree, setSelectedEducationalDegree] = useState<number | undefined>();
  const [selectedCourse, setSelectedCourse] = useState<number | undefined>();
  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  const [selectedTrimester, setSelectedTrimester] = useState<number | undefined>();
  const [selectedStudent, setSelectedStudent] = useState<DataType[]>([]);
  const [selectedStudentId, setSelectedStudentId] = useState<number[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    OrdersService.getAllOrderTypes().then(({ data }) => {
      setOrderTypes(data);
    });
    TranscriptService.getAllStudentsGroup().then(({ data }) => {
      let res = data.map((item) => {
        return {
          text: item.title,
          value: item.title,
        };
      });
      setFilterRows(res);
    });
  }, []);

  useEffect(() => {
    if (orderId) {
      OrdersService.getById(orderId)
        .then((res) => {
          form.setFieldsValue({
            name: res.data.order.name,
            number: res.data.order.number,
            issueDate: res.data.order.issueDate,
            orderType: res.data.order.orderType.id,
            fileId: res.data.order.fileId,
            contingateDate: res.data?.order?.contingateDate,
            orderCategory: res.data?.order?.orderCategory?.id,
          });
          setOrderName(res.data.order.name);
          setSelectedOrderType(res.data.order.orderType.id);
          setSelectedStudent(
            res.data.students.map((student) => {
              return {
                key: student.id,
                id: student.id,
                userId: student.userId,
                fullName: student.fullName,
                group: student.group.title,
              };
            })
          );
          setSelectedStudentId(res.data.students.map((student) => student.userId));
        })
    }
  }, [orderId, form]);

  useEffect(() => {
    if (selectedOrderType) {
      setLoading(true);
      setTableVisible(true);
      OrdersService.getStudentsByOrder({
        page: currentPage,
        academicDegreeId: selectedEducationalDegree,
        course: selectedCourse,
        order_type: selectedOrderType,
        term: selectedTrimester,
        year: selectedYear,
      })
        .then((res) => {
          if (Object.entries(res.data).length === 0) {
            errorNotification(`Нет подходящих студентов по этому приказу`);
            setTableVisible(true);
            setTableData([]);
            setTotalNumber(0);
          } else {
            setTableData(res.data.list);
            setTotalNumber(res.data.total_number);
            if (res.data.list.length === 0) {
              errorNotification(`Не найдено ни одного студента`);
              setTableVisible(true);
            } else {
              setTableVisible(true);
            }
          }
        })
        .finally(() => setLoading(false));
      OrdersService.getOrderCategories(selectedOrderType)
        .then(({ data }) => {
          setOrdersCategories(data);
        })
    }
  }, [currentPage, selectedEducationalDegree, selectedCourse, selectedOrderType, selectedTrimester, selectedYear]);

  const onFormFinish = (order: OrderDtoRequest) => {
    // if (orderFileId > 0) {
    //   order.fileId = orderFileId;
    // }
    let formData = new FormData();

    formData.append("orderDtoRequest", new Blob([JSON.stringify(order)], { type: 'application/json' }));
    formData.append("file", orderFile);

    OrdersService.updateOrder(formData, orderId)
      .then(() => notification.success({ message: 'Данные обновлены!' }))
      .finally(() => history.push('/order-list'));
  };

  const handleDownload = (name: string) => {
    OrdersService.downloadOrder(orderId, name)
      .then(() => {
        notification.success({ message: 'Приказ успешно был скачан!' });
      })
  };

  const handleOrderTypeSelect = (value: any) => {
    setSelectedOrderType(value);
    setAreButtonsAvailable(true);
    setTableVisible(false);
    form.setFieldsValue({
      year: undefined,
      academicDegreeId: undefined,
      course: undefined,
      term: undefined,
    });
  };

  const handlePageChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current);
  };

  const handleStudentSelect = (record: any) => {
    const student = selectedStudent.find((student) => student.userId === record.userId);
    if (!student) {
      setSelectedStudent([...selectedStudent, record]);
      successNotification(`Студент был успешно выбран`);
    } else {
      errorNotification(`Вы уже выбрали этого студента!`);
    }
  };

  const handleDelete = (record: any) => {
    const newData = selectedStudent.filter((student) => student.userId !== record.userId);
    setSelectedStudent(newData);
  };

  const handleYearSelect = (value: any) => {
    setSelectedYear(value);
    setAreButtonsAvailable(true);
    setTableVisible(false);
    form.setFieldsValue({
      academicDegreeId: undefined,
      course: undefined,
      term: undefined,
    });
  };

  const handleDegreeSelect = (value: any) => {
    setSelectedEducationalDegree(value);
    setAreButtonsAvailable(true);
    setTableVisible(false);
    if (value === 2) {
      if (selectedOrderType === 2) {
        setCourseOptions([{ id: 1, year: 1 }]);
      } else {
        setCourseOptions([
          { id: 1, year: 1 },
          { id: 2, year: 2 },
        ]);
      }
    } else {
      if (selectedOrderType === 2) {
        setCourseOptions([
          { id: 1, year: 1 },
          { id: 2, year: 2 },
        ]);
      } else {
        setCourseOptions([
          { id: 1, year: 1 },
          { id: 2, year: 2 },
          { id: 3, year: 3 },
        ]);
      }
    }
    form.setFieldsValue({
      course: undefined,
      term: undefined,
    });
  };

  const handleCourseSelect = (value: any) => {
    setSelectedCourse(value);
    setAreButtonsAvailable(true);
    setTableVisible(false);
    form.setFieldsValue({
      term: undefined,
    });
  };

  const handleTrimesterSelect = (value: any) => {
    setSelectedTrimester(value);
  };

  const onFormFinishFailed = (errorInfo: any) => {
    errorNotification('Не удалось обновить данные!');
  };

  // const confirm = (e?: React.MouseEvent<HTMLElement>) => {
  //   notification.success({ message: 'Deleted' });
  // };

  // const cancel = (e?: React.MouseEvent<HTMLElement>) => {
  //   notification.error({ message: 'Canceled' });
  // };

  const data: DataType[] = [];

  for (let i = 0; i < tableData.length; ++i) {
    data.push({
      key: i + 1,
      id: tableData[i].id,
      userId: tableData[i].userId,
      fullName: `${tableData[i]?.nameKz} ${tableData[i]?.surnameKz} ${tableData[i]?.patronymicKz}`,
      group: tableData[i]?.group.title,
    });
  }

  const availableStudentColumns: ColumnsType<DataType> = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      filters: filterRows,
      onFilter: (value: any, record: any) => record.group.indexOf(value) === 0,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: (text: any, record: any) => (
        <Space>
          <Button type="default" onClick={() => handleStudentSelect(record)}>
            <PlusOutlined />
          </Button>
        </Space>
      ),
      width: '5%',
    },
  ];

  const selectedStudentColumns: ColumnsType<DataType> = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      filters: filterRows,
      onFilter: (value: any, record: any) => record.group.indexOf(value) === 0,
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'operation',
    //   key: 'operation',
    //   render: (text: any, record: any) => (
    //     <Space>
    //       <Button type="default" onClick={() => handleDelete(record)}>
    //         <DeleteOutlined />
    //       </Button>
    //     </Space>
    //   ),
    //   width: '5%',
    // },
  ];

  const uploadProps: UploadProps = {
    beforeUpload: (file: RcFile, fileList: RcFile[]) => {
      const isPDF = fileList[0].type === 'application/pdf';
      if (!isPDF) {
        errorNotification(`${fileList[0].name} is not pdf file!`);
      } else {
        setOrderFile(file)
      }
      return false
    },
  };


  const style = { width: '800px', margin: '0 auto' };
  const history = useHistory();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return {
    data,
    availableStudentColumns,
    selectedStudentColumns,
    selectedStudent,
    uploadProps,
    ordersTypes,
    courseOptions,
    totalNumber,
    orderName,
    selectedOrderType,
    form,
    style,
    onFormFinish,
    onFormFinishFailed,
    handleDownload,
    handleOrderTypeSelect,
    handleDegreeSelect,
    handleYearSelect,
    handleTrimesterSelect,
    handlePageChange,
    handleCourseSelect,
    handleStudentSelect,
    handleDelete,
    setSelectedStudent,
    selectedStudentId,
    setSelectedStudentId,
    tableVisible,
    areButtonsAvailable,
    loading,
    showModal,
    isModalOpen,
    setIsModalOpen,
    handleCancel,
    handleOk,
    ordersCategories,
  };
};

export default useEditOrderForm;
