import { Tag } from 'antd';
import React from 'react'
import { AcademicMobilityCompetitionStatusDto } from '../../../../interfaces/AcademicMobilityCompetitionDto';


interface Props {
    status: AcademicMobilityCompetitionStatusDto;
}

const tagStyle: React.CSSProperties = {
    width: "100%",
    maxWidth: "250px",
    whiteSpace: "normal",
    overflowWrap: "break-word",
};


const MobilityStatusTag = ({ status }: Props) => {
    const handleColor = (prefix: string) => {
        switch (prefix) {
            case "open":
                return "blue";
            case "created":
                return "blue";
            case "revision":
                return "orange";
            case "draft":
                return "orange";
            case "passed":
                return "green";
            case "reserve":
                return "gold";
            case "rejected":
                return "red";
            case "closed":
                return "red";
            case "checking":
                return "purple";
            case "commission":
                return "geekblue";
            case "withdrawn":
                return "magenta";
            case "wait_confirmation":
                return "cyan";
            default:
                return "default";
        }
    }


    return (
        <Tag style={tagStyle} color={handleColor(status.prefix)}>
            {status.nameEn}
        </Tag>
    );
}

export default MobilityStatusTag