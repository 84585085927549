import { DeleteTwoTone, DownloadOutlined, EditTwoTone } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useEffect, useState } from 'react';
import { AcademicMobilityService } from '../../../../services/AcademicMobilityService';
import { AcademicMobilityDtoResponse } from '../../../../interfaces/AcademicMobilityDtoResponse';

import styles from './style.module.css';
import moment from 'moment';


const useAcademicMobility = () => {
    const [shouldRerender, setShouldRerender] = useState(false);
    const [academicMobility, setAcademicMobility] = useState<AcademicMobilityDtoResponse[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const downloadExcel = () => {
        AcademicMobilityService.downloadAcademicMobilityExcel()
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'academic_mobility.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => console.error('Error downloading Excel file:', error));
    };

    const onDeleteAcademicMobility = (academicMobilityId: number) => {
        AcademicMobilityService.deleteAcademicMobility(academicMobilityId)
            .then(() => setShouldRerender(!shouldRerender))
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        const zeroBasedPage = currentPage - 1;
        AcademicMobilityService.getAllAcademicMobility(zeroBasedPage)
            .then((response) => {
                setAcademicMobility(response.data.data);
                setTotalPages(response.data.totalPages);
            })
            .catch((error) => console.error(error));
    }, [shouldRerender, currentPage]);



    const column = [
        {
            title: 'Student',
            dataIndex: 'student',
            key: 'student',
        },
        {
            title: 'University',
            dataIndex: 'university',
            key: 'university',

        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (text: any, record: any) => (record && record.startDate ? moment(record.startDate).format('DD.MM.YYYY') : 'Не найдено'),
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (text: any, record: any) => (record && record.endDate ? moment(record.endDate).format('DD.MM.YYYY') : 'Не найдено'),
        },
        {
            title: 'Financing source',
            dataIndex: 'financingSource',
            key: 'financingSource',
        },
        {
            title: 'Order Number',
            dataIndex: 'orderNumber',
            key: 'orderNumber'
        },
        {
            title: 'Mobility Type',
            dataIndex: 'mobilityType',
            key: 'mobilityType',
            render: (text: any, record: any) => {
                const mobilityType = record.mobilityType;
                const options = {
                    1: 'Выезд внутренний',
                    2: 'Выезд внешний',
                };
                return options[mobilityType as keyof typeof options];
            },
        },
        {
            title: 'Start Term',
            dataIndex: 'startTerm',
            key: 'startTerm',
        },
        {
            title: 'Finish Term',
            dataIndex: 'finishTerm',
            key: 'finishTerm',
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            width: 120,
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button className={styles.deleteButton} onClick={() => onDeleteAcademicMobility(record.key)}>
                        <DeleteTwoTone twoToneColor="#ff0000" />
                    </Button>
                    <Button href={`/academic-mobility/edit/${record.key}`}>
                        <EditTwoTone />
                    </Button>
                </Space>
            ),
        },
    ]
    return {
        academicMobility,
        column,
        downloadExcel,
        currentPage,
        totalPages,
        setCurrentPage
    };
};

export default useAcademicMobility;