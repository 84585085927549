import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Select, Table, Typography, DatePicker } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import { PhysicalEducationCourseEnrollmentService } from './PhysicalEducationCourseEnrollmentService';
import { errorNotification } from '../../../helpers/errorNotification';
import moment from 'moment';
import { successNotification } from '../../../helpers/successNotification';
import { DeleteOutlined, EditOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { PhysicalEducationChooseDtoResponse } from '../../../interfaces/PhysicalEducationChooseDtoResponse';

interface PhysicalEducationSetting {
  id: number;
  year: number;
  trim: number;
  startAt: string;
  endAt: string;
}

interface PhysicalEducationType {
  id: number;
  name: string;
  nameEn: string;
  nameRu: string;
}


const { Option } = Select;
const hours = [8, 10, 12, 14, 16];

const formatScheduleHour = (hour: number) => {
  const startHour = hour < 10 ? `0${hour}` : `${hour}`;
  const endHour = hour + 1 < 10 ? `0${hour + 1}` : `${hour + 1}`;
  return `${startHour}:00 - ${endHour}:50`;
};

const PhysicalEducationSettings = () => {
  const [settings, setSettings] = useState<PhysicalEducationSetting[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingData, setEditingData] = useState<any>(null);
  const [form] = Form.useForm();
  const [chooseForm] = Form.useForm();
  const [pagination, setPagination] = useState<{ current: number; pageSize: number }>({
    current: 1,
    pageSize: 10
  });
  const handleTableChange = (pagination: any) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const [chosenCourses, setChosenCourses] = useState<PhysicalEducationChooseDtoResponse[]>([]);
  // const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [typeId, setTypeId] = useState<number | undefined>(undefined);
  const [scheduleDay, setScheduleDay] = useState<number | undefined>(undefined);
  const [teacherId, setTeacherId] = useState<number | undefined>(undefined);
  const [types, setTypes] = useState<PhysicalEducationType[]>([]);
  const [selectedSettingId, setSelectedSettingId] = useState<number | undefined>(undefined);
  const [teachers, setTeachers] = useState<{ id: number; nameEn: string; surnameEn: string }[]>([]);
  const [year, setYear] = useState<number | null>(null);
  const [trimester, setTrimester] = useState<number | null>(null);


  useEffect(() => {
    fetchSettings();
  }, []);


  useEffect(() => {
    fetchSettings();
    PhysicalEducationCourseEnrollmentService.getAllPhysicalEducationTypes()
      .then((data) => setTypes(data))
      .catch((error) => errorNotification('Error fetching types'));
  }, []);

  const fetchSettings = () => {
    PhysicalEducationCourseEnrollmentService.getStudentPhysicalEducationChooseSettings()
      .then((data) => setSettings(data))
      .catch((error) => errorNotification('Error fetching settings'));
  };

  const showModal = (record?: any) => {
    if (record) {
      setEditingData(record);
      form.setFieldsValue({
        id: record.id,
        year: record.year,
        trim: record.trim,
        startAt: moment(record.startAt),
        endAt: moment(record.endAt),
      });
    } else {
      setEditingData(null);
      form.resetFields();
      form.setFieldsValue({ year: 2024 });
    }

    setIsModalVisible(true);
  };

  const handleSearch = () => {
    if (!selectedSettingId) {
      errorNotification('Please select a setting first');
      return;
    }

    setLoading(true);
    setPagination({ current: 1, pageSize: pagination.pageSize });

    PhysicalEducationCourseEnrollmentService.getPhysicalEducationChoicesByFilterForOfficeRegistrar(typeId || 0, selectedSettingId, scheduleDay || 0, teacherId || 0)
      .then((data) => {
        setChosenCourses(data);
        setPagination({ ...pagination, current: 1 });
      })
      .catch(() => errorNotification('Error fetching filtered data'))
      .finally(() => setLoading(false));
  };


  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const payload = {
        ...values,
        startAt: values.startAt.format('YYYY-MM-DDTHH:mm:ss'),
        endAt: values.endAt.format('YYYY-MM-DDTHH:mm:ss'),
      };

      PhysicalEducationCourseEnrollmentService.createOrUpdateStudentChooseSettings(payload)
        .then(() => {
          fetchSettings();
          setIsModalVisible(false);
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || 'Error saving settings';
          errorNotification(errorMessage);
        });
    }).catch((errorInfo) => {
      errorNotification('Please fill in all required fields');
    });
  };


  const handleDelete = (id: number) => {
    PhysicalEducationCourseEnrollmentService.deleteStudentChooseSettings(id)
      .then(() => {
        fetchSettings();
      })
      .catch(() => {
        errorNotification('Error deleting setting');
      });
  };

  const [loading, setLoading] = useState(false);

  const handleShowInfo = (settingId: number) => {
    setSelectedSettingId(settingId);
    setTypeId(0);
    setScheduleDay(0);
    setLoading(true);
    setPagination({ current: 1, pageSize: pagination.pageSize });
    const selectedSetting = settings.find(setting => setting.id === settingId);
    if (selectedSetting) {
      setYear(selectedSetting.year);
      setTrimester(selectedSetting.trim);
    }
    PhysicalEducationCourseEnrollmentService.getPhysicalEducationChoicesByFilterForOfficeRegistrar(0, settingId, 0, 0)
      .then((data) => {
        setChosenCourses(data);
        setPagination({ ...pagination, current: 1 });
      })
      .catch(() => errorNotification('Error fetching filtered data'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    PhysicalEducationCourseEnrollmentService.getPhysicalEducationTeachers()
      .then((data) => setTeachers(data))
      .catch((error) => errorNotification('Error fetching teachers'));
  }, []);



  const columns = [
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Trimester',
      dataIndex: 'trim',
      key: 'trim',
    },
    {
      title: 'Start Date',
      dataIndex: 'startAt',
      key: 'startAt',
      render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'End Date',
      dataIndex: 'endAt',
      key: 'endAt',
      render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: PhysicalEducationSetting) => (

        <>
          <Button type="primary" onClick={() => showModal(record)}><EditOutlined /> Edit</Button>
          <Button type="primary" style={{ marginLeft: "15px" }} danger onClick={() => handleDelete(record.id)}><DeleteOutlined />Delete</Button>
          <Button style={{ marginLeft: '15px' }} onClick={() => handleShowInfo(record.id)}><InfoCircleOutlined /> Show Info</Button>
        </>

      ),
    },
  ];

  const [isChooseModalVisible, setIsChooseModalVisible] = useState(false);
  const [chooseEditingData, setChooseEditingData] = useState<any>(null);

  const showChooseModal = (record?: any) => {
    console.log('Selected Type ID:', typeId);
    console.log('Selected Schedule Day:', scheduleDay);

    if (record) {
      setChooseEditingData(record);
      chooseForm.setFieldsValue({
        id: record.id,
        name: record.name,
        typeId: record.type.id,
        teacherId: record.teacherId,
        genderId: record.gender ? record.gender.id : null,
        scheduleHour: record.scheduleHour,
        scheduleDay: record.scheduleDay,
        limit: record.limit,
      });
    } else {
      setChooseEditingData(null);
      chooseForm.resetFields();
      chooseForm.setFieldsValue({
        typeId: typeId !== 0 ? typeId : undefined,
        scheduleDay: scheduleDay !== 0 ? scheduleDay : undefined,
        teacherId: teacherId !== 0 ? teacherId : undefined,
      });
    }
    setIsChooseModalVisible(true);
  };


  const handleChooseModalClose = () => {
    chooseForm.resetFields();
    setIsChooseModalVisible(false);
    setChooseEditingData(null);
  };

  const handleDeleteChoose = (id: number) => {
    PhysicalEducationCourseEnrollmentService.deletePhysicalEducationChoose(id)
      .then(() => {
        handleSearch();
      })
      .catch(() => {
        errorNotification('Error deleting course');
      });
  };

  const handleChooseSubmit = () => {
    chooseForm.validateFields().then((values) => {
      const payload = {
        ...values,
        settingId: selectedSettingId,
        typeId: typeId || values.typeId,
        scheduleDay: scheduleDay || values.scheduleDay,
      };

      PhysicalEducationCourseEnrollmentService.createOrUpdatePhysicalEducationChoose(payload)
        .then(() => {
          setIsChooseModalVisible(false);
          handleSearch();
          setChooseEditingData(null);
          chooseForm.resetFields();
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || 'Error saving course settings';
          errorNotification(errorMessage);

        });
    }).catch((errorInfo) => {
      errorNotification('Please fill in all required fields');
    });
  };


  const chooseColumns = [
    {
      title: 'Course Name',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Schedule Day',
      dataIndex: 'scheduleDay',
      key: 'scheduleDay',
      render: (day: number) => ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day - 1],
    },
    {
      title: 'Schedule Hour',
      dataIndex: 'scheduleHour',
      key: 'scheduleHour',
      render: (hour: number) => formatScheduleHour(hour),
    },

    {
      title: 'Teacher',
      dataIndex: 'teacherDtoResponse',
      key: 'teacherDtoResponse',
      render: (teacher: any) => `${teacher.nameEn} ${teacher.surnameEn}`,
    },
    {
      title: 'Limited quantity',
      dataIndex: 'limit',
      key: 'limit',
    },
    {
      title: 'Occupied places',
      dataIndex: 'chosenCount',
      key: 'chosenCount',
    },
    {
      title: 'Places left',
      key: 'placesleft',
      render: (record: any) => `${record.limit - record.chosenCount}`,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: PhysicalEducationChooseDtoResponse) => (
        <>
          <Button type="primary" onClick={() => showChooseModal(record)}><EditOutlined /> Edit</Button>
          <Button type="primary" style={{ marginLeft: "15px" }} danger onClick={() => handleDeleteChoose(record.id)}>
            <DeleteOutlined /> Delete
          </Button>
        </>
      ),
    },
  ];


  return (
    <PageWrapper>
      <Typography.Title level={1}>Physical Education Settings</Typography.Title>
      <Card style={{ marginBottom: '20px' }}>
        <Button type="primary" onClick={() => showModal()}><PlusOutlined /> Add New Setting</Button>
        <Table
          dataSource={settings}
          columns={columns}
          rowKey="id"
          style={{ marginTop: 20 }}
        />
      </Card>

      <Card style={{ marginTop: '20px' }}>
        <Typography.Title level={2}>Course Information
          {year && trimester && (
            <span style={{ marginLeft: '10px' }}>
              (Year: {year}-{year + 1}, Trimester: {trimester} )
            </span>
          )}
        </Typography.Title>
        <Form layout="inline">
          <Form.Item label="Type">
            <Select
              value={typeId}
              onChange={setTypeId}
              defaultValue={0}
              style={{ width: 200 }}
            >
              <Option value={0}>All Types</Option>
              {types.map((type) => (
                <Option key={type.id} value={type.id}>{type.nameEn}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Schedule Day">
            <Select
              value={scheduleDay}
              onChange={setScheduleDay}
              defaultValue={0}
              style={{ width: 200 }}
            >
              <Option value={0}>All days</Option>
              <Option value={1}>Monday</Option>
              <Option value={2}>Tuesday</Option>
              <Option value={3}>Wednesday</Option>
              <Option value={4}>Thursday</Option>
              <Option value={5}>Friday</Option>
              <Option value={6}>Saturday</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Teacher">
            <Select
              value={teacherId}
              onChange={setTeacherId}
              defaultValue={0}
              style={{ width: 200 }}
            >
              <Option value={0}>All Teachers</Option>
              {teachers.map((teacher) => (
                <Option key={teacher.id} value={teacher.id}>{teacher.nameEn} {teacher.surnameEn}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button onClick={handleSearch} type="primary" disabled={loading}>
              {loading ? "Loading..." : "Search"}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              disabled={!selectedSettingId}
              onClick={() => showChooseModal()}>
              <PlusOutlined /> Create
            </Button>
          </Form.Item>

        </Form>

        <Table
          dataSource={chosenCourses}
          columns={chooseColumns}
          rowKey="id"
          style={{ marginTop: 20 }}
          loading={loading}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Card>
      <Modal
        title={editingData ? "Edit Setting" : "Add Setting"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSubmit}
      >
        <Form form={form} layout="vertical">
          {editingData && <Form.Item name="id" hidden><Input /></Form.Item>}
          <Form.Item label="Year" name="year" rules={[{ required: true, message: 'Please input the year!' }]}>
            <Input disabled={true} />
          </Form.Item>
          <Form.Item label="Trimester" name="trim" rules={[{ required: true, message: 'Please select the trimester!' }]}>
            <Select>
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
              <Option value={3}>3</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Start Date" name="startAt" rules={[{ required: true, message: 'Please select the start date!' }]}>
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
          <Form.Item label="End Date" name="endAt" rules={[{ required: true, message: 'Please select the end date!' }]}>
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={chooseEditingData ? "Edit Course" : "Add Course"}
        visible={isChooseModalVisible}
        onCancel={handleChooseModalClose}

        onOk={handleChooseSubmit}
      >
        <Form form={chooseForm} layout="vertical">
          {chooseEditingData && <Form.Item name="id" hidden><Input /></Form.Item>}
          <Form.Item label="Course Name" name="name" rules={[{ required: true, message: 'Please input the course name!' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Type"
            name="typeId"
            rules={[{ required: true, message: 'Please select the type!' }]}
          >
            <Select>
              {types.map((type) => (
                <Option key={type.id} value={type.id}>{type.nameEn}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Teacher" name="teacherId" rules={[{ required: true, message: 'Please select the teacher!' }]}>
            <Select placeholder="Select a teacher">
              {teachers.map(teacher => (
                <Option key={teacher.id} value={teacher.id}>
                  {teacher.nameEn} {teacher.surnameEn}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Gender" name="genderId" rules={[{ required: false, message: 'Please select the gender!' }]}>
            <Select
              defaultValue={null}
            >
              <Option value={1}>Female</Option>
              <Option value={2}>Male</Option>
              <Option value={null}>No Gender</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Schedule Hour" name="scheduleHour" rules={[{ required: true, message: 'Please select the schedule hour!' }]}>
            <Select
              // style={{ width: 120 }}
              placeholder="Select hour"
              onChange={(value) => console.log(`Selected hour: ${value}`)}
            >
              {hours.map(hour => (
                <Select.Option key={hour} value={hour}>
                  {formatScheduleHour(hour)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Schedule Day"
            name="scheduleDay"
            rules={[{ required: true, message: 'Please select the schedule day!' }]}
          >
            <Select>
              <Option value={1}>Monday</Option>
              <Option value={2}>Tuesday</Option>
              <Option value={3}>Wednesday</Option>
              <Option value={4}>Thursday</Option>
              <Option value={5}>Friday</Option>
              <Option value={6}>Saturday</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Limit" name="limit" rules={[{ required: true, message: 'Please select the limit!' }]}>
            <Input type="number" min={0} />
          </Form.Item>
        </Form>
      </Modal>

    </PageWrapper>
  );
};

export default PhysicalEducationSettings;
