import authAxios from "../../common/authAxios";

export class ScheduleService {
    private static baseUrlSchedule = '/astanait-schedule-module/api/v1/schedule';

    static getScheduleByTutor(tutorEmail: string) {
        return authAxios.get(`${this.baseUrlSchedule}/tutorEmail/${tutorEmail}`, {
            headers: {
                showNotification: false
            }
        });
    }

    static getScheduleByGroup(groupName: string) {
        return authAxios.get(`${this.baseUrlSchedule}/groupName/` + groupName)
    }

}