import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Typography, Modal, Form, Input, DatePicker } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { GraduationThesisSettingsDtoResponse } from './GraduationThesisSettingsDtoResponse';
import { GraduationThesisSettingsService } from './GraduationThesisSettingsService';
import grantPermission from '../../../helpers/grantPermission';



const GraduationThesisSettings = () => {
    const [settings, setSettings] = useState<GraduationThesisSettingsDtoResponse[]>([]);
    const [loading, setLoading] = useState(true);
    const [editingRecord, setEditingRecord] = useState<GraduationThesisSettingsDtoResponse | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
    const [currentAction, setCurrentAction] = useState<{ id: number, approved: boolean } | null>(null);
    const [approveForm] = Form.useForm();
    const [form] = Form.useForm();
    const history = useHistory();


    const showApproveModal = (id: number, approved: boolean) => {
        setCurrentAction({ id, approved });
        setIsApproveModalVisible(true);
    };

    const handleApproveModalOk = async () => {
        try {
            const values = await approveForm.validateFields();
            if (currentAction) {
                await GraduationThesisSettingsService.approveSetting(currentAction.id, {
                    approved: currentAction.approved,
                    comment: values.comment,
                });
                setLoading(true);
                const data = await GraduationThesisSettingsService.getByPrincipalDepartment();
                setSettings(data);
                setLoading(false);
            }
            setIsApproveModalVisible(false);
            approveForm.resetFields();
        } catch (error) {
            console.error('Error approving/rejecting setting', error);
        }
    };


    const handleApproveModalCancel = () => {
        setIsApproveModalVisible(false);
        form.resetFields();
    };

    const viewTeacherList = (settingId: number, departmentId: number) => {
        history.push(`/graduation-thesis-settings/teacher/${settingId}/department/${departmentId}`);
    };

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const data = await GraduationThesisSettingsService.getByPrincipalDepartment();
                setSettings(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchSettings();
    }, []);

    const handleModalOk = async () => {
        try {
            const values = await form.validateFields();
            const finalValues = {
                ...values,
                deadlineStartAt: values.deadlineStartAt.format("YYYY-MM-DD HH:mm:ss"),
                deadlineEndAt: values.deadlineEndAt.format("YYYY-MM-DD HH:mm:ss"),
                departmentId: editingRecord ? editingRecord.department.id : settings[0]?.department.id,
            };

            if (editingRecord) {
                await GraduationThesisSettingsService.updateSetting(editingRecord.id, finalValues);
            } else {
                await GraduationThesisSettingsService.createSetting(finalValues);
            }
            setIsModalVisible(false);
            form.resetFields();
            setLoading(true);
            const data = await GraduationThesisSettingsService.getByPrincipalDepartment();
            setSettings(data);
            setLoading(false);
        } catch (error) {
            console.error('Error saving data', error);
        }
    };

    const openModal = (record: GraduationThesisSettingsDtoResponse | null = null) => {
        setEditingRecord(record);
        setIsModalVisible(true);
        if (record) {
            form.setFieldsValue({
                ...record,
                deadlineStartAt: moment(record.deadlineStartAt),
                deadlineEndAt: moment(record.deadlineEndAt),
            });
        } else {
            form.resetFields();
        }
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };
    const columns = [
        // {
        //     title: 'id',
        //     dataIndex: 'id',
        //     key: 'id',
        // },
        {
            title: 'Defense Year',
            dataIndex: 'defenseYear',
            key: 'defenseYear',
            align: 'center' as const,
        },
        {
            title: 'Status',
            dataIndex: 'approved',
            key: 'approved',
            align: 'center' as const,
            render: (approved: boolean | null) => (
                approved === null
                    ? <Typography.Text>Unknown</Typography.Text>
                    : approved
                        ? <Typography.Text type="success">Approved</Typography.Text>
                        : <Typography.Text type="danger">Rejected</Typography.Text>
            )

        },

        {
            title: 'comment',
            dataIndex: 'comment',
            key: 'comment',
            align: 'center' as const,
        },
        {
            title: 'Deadline Start',
            dataIndex: 'deadlineStartAt',
            key: 'deadlineStartAt',
            align: 'center' as const,
            render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            title: 'Deadline End',
            dataIndex: 'deadlineEndAt',
            key: 'deadlineEndAt',
            align: 'center' as const,
            render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm:ss'),
        },
        grantPermission('STUDY_DEPARTMENT_DIRECTOR', 'addRole') ? {
            title: 'Actions',
            key: 'actions',
            align: 'center' as const,
            render: (record: GraduationThesisSettingsDtoResponse) => (
                <>
                    <Button icon={<EditOutlined />} onClick={() => openModal(record)} style={{ marginRight: 10 }}> Edit</Button>
                    <Button icon={<EyeOutlined />} onClick={() => viewTeacherList(record.id, record.department.id)}>View</Button>
                    <Button
                        type="primary"

                        disabled={record.approved !== null}
                        onClick={() => showApproveModal(record.id, true)}
                        style={{ marginRight: 10, marginLeft: 20 }}
                    >
                        Approve
                    </Button>
                    <Button
                        type="primary"
                        danger
                        disabled={record.approved !== null}
                        onClick={() => showApproveModal(record.id, false)}
                    >
                        Reject
                    </Button>

                    {/* <Button
                        icon={<DeleteOutlined />}
                        danger
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                            Modal.confirm({
                                title: 'Are you sure you want to delete this suggestion?',
                                onOk: () => handleDelete(record.id),
                            });
                        }}
                    /> */}
                </>
            ),
        } : {},
    ];

    return (
        <PageWrapper>
            <Typography.Title level={1}>Graduation Thesis Settings</Typography.Title>
            <Card>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => openModal()}>Add Setting</Button>
                <Table
                    columns={columns}
                    dataSource={settings}
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 30 }}
                />
            </Card>
            <Modal
                title={editingRecord ? 'Edit Setting' : 'Add Setting'}
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Form form={form} layout="vertical">
                    {/* <Form.Item
                        name="defenseYear"
                        label="Defense Year"
                        rules={[{ required: true, message: 'Please input the defense year!' }]}
                    >
                        <InputNumber min={2019} max={2100} style={{ width: '100%' }} />
                    </Form.Item> */}
                    <Form.Item
                        name="deadlineStartAt"
                        label="Deadline Start"
                        rules={[{ required: true, message: 'Please select the start deadline!' }]}
                    >
                        <DatePicker style={{ width: '100%' }} showTime format="DD.MM.YYYY HH:mm:ss" />
                    </Form.Item>
                    <Form.Item
                        name="deadlineEndAt"
                        label="Deadline End"
                        rules={[{ required: true, message: 'Please select the end deadline!' }]}
                    >
                        <DatePicker style={{ width: '100%' }} showTime format="DD.MM.YYYY HH:mm:ss" />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={currentAction?.approved ? 'Approve Setting' : 'Reject Setting'}
                visible={isApproveModalVisible}
                onOk={handleApproveModalOk}
                onCancel={handleApproveModalCancel}
            >
                <Form form={approveForm} layout="vertical">
                    <Form.Item
                        name="comment"
                        label="Comment"
                        rules={[{ required: true, message: 'Please provide a comment!' }]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>

        </PageWrapper>
    );
};

export default GraduationThesisSettings;
