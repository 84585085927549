import { Button, Descriptions, Space, Tabs, Typography } from 'antd';
import useProfilePage from './useProfilePage';
import moment from 'moment';
import grantPermission from '../../../helpers/grantPermission';

interface Props {
  userId?: number;
  infoDetailsHidden?: boolean;
}


const NoData = () => <Typography.Text type="secondary">Нет данных</Typography.Text>;

const ProfileDetails = ({ userId, infoDetailsHidden = false }: Props) => {
  const { profileInfo, handleDownloadFile, socialStatusList, loading } = useProfilePage(userId);

  const itemsForStudent = [
    {
      key: '1',
      label: `Address information`,
      children: (
        <Space direction={'vertical'} size={'small'}>
          <Descriptions title={'Место прописки'}>
            <Descriptions.Item label="Регион">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.studentPersonalInfoDtoResponse?.residenceAddress?.centerKatoRegion?.nameru || <NoData />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Адрес">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.studentPersonalInfoDtoResponse?.residenceAddress?.nameru || <NoData />
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title={'Место проживания'}>
            <Descriptions.Item label="Регион">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.studentPersonalInfoDtoResponse?.lifeAddress?.centerKatoRegion?.nameru || <NoData />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Адрес">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.studentPersonalInfoDtoResponse?.lifeAddress?.nameru || <NoData />
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title={'Фактический адрес'}>
            <Descriptions.Item>
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.studentPersonalInfoDtoResponse?.factAddress || <NoData />
              )}
            </Descriptions.Item>
          </Descriptions>
        </Space>
      ),
    },
    {
      key: '2',
      label: `School information`,
      children: (
        <Space direction={'vertical'} size={'small'}>
          {profileInfo?.educationalInfoDtoResponse?.educationInstitutionType?.id === 1 ? (
            <Descriptions title={'Оконченное образование'}>
              <Descriptions.Item label="Страна">
                {infoDetailsHidden ? (
                  <Typography.Text type="secondary">(hidden)</Typography.Text>
                ) : (
                  profileInfo?.educationalInfoDtoResponse?.country?.name || <NoData />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Регион">
                {infoDetailsHidden ? (
                  <Typography.Text type="secondary">(hidden)</Typography.Text>
                ) : (
                  profileInfo?.educationalInfoDtoResponse?.schoolRegionId?.nameru || <NoData />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Адрес">
                {infoDetailsHidden ? (
                  <Typography.Text type="secondary">(hidden)</Typography.Text>
                ) : (
                  profileInfo?.educationalInfoDtoResponse?.schoolAddressId?.nameru || <NoData />
                )}
              </Descriptions.Item>
              {profileInfo?.educationalInfoDtoResponse.school ? (
                <Descriptions.Item label="Название">
                  {infoDetailsHidden ? (
                    <Typography.Text type="secondary">(hidden)</Typography.Text>
                  ) : (
                    profileInfo?.educationalInfoDtoResponse?.school?.name || <NoData />
                  )}
                </Descriptions.Item>
              ) : (
                <Descriptions.Item label="Название">
                  {infoDetailsHidden ? (
                    <Typography.Text type="secondary">(hidden)</Typography.Text>
                  ) : (
                    profileInfo?.educationalInfoDtoResponse?.educationalInstitution || <NoData />
                  )}
                </Descriptions.Item>
              )}

            </Descriptions>
          ) : (
            <Descriptions title={'Оконченное образование'}>
              <Descriptions.Item label="Страна">
                {infoDetailsHidden ? (
                  <Typography.Text type="secondary">(hidden)</Typography.Text>
                ) : (
                  profileInfo?.educationalInfoDtoResponse?.country?.name || <NoData />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Название">
                {infoDetailsHidden ? (
                  <Typography.Text type="secondary">(hidden)</Typography.Text>
                ) : (
                  profileInfo?.educationalInfoDtoResponse?.educationalInstitution || <NoData />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Специальность">
                {infoDetailsHidden ? (
                  <Typography.Text type="secondary">(hidden)</Typography.Text>
                ) : (
                  profileInfo?.educationalInfoDtoResponse?.specialityName || <NoData />
                )}
              </Descriptions.Item>
            </Descriptions>
          )}
          <Descriptions title={'Данные об аттестате'}>
            <Descriptions.Item label="Дата получения">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                moment(profileInfo?.educationalInfoDtoResponse?.graduationCertificateIssueDate).format(
                  'DD-MM-YYYY'
                ) || <NoData />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Номер">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.educationalInfoDtoResponse?.kzGraduationCertificateNumber || <NoData />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Средний балл">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.educationalInfoDtoResponse?.kzGraduationCertificateAveragePoint || <NoData />
              )}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions title={'Отличия'}>
            <Descriptions.Item label="Тип отличия">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.educationalInfoDtoResponse?.distinctionMarkType?.name || <NoData />
              )}
            </Descriptions.Item>
          </Descriptions>
          {(grantPermission('DEAN', 'addRole') || grantPermission('STUDENT_DEPARTMENT', 'addRole')) && (
            <Space>
              {profileInfo?.admissionDocuments?.graduationCertificate !== null && (
                <Button
                  loading={loading}
                  onClick={() =>
                    handleDownloadFile(
                      profileInfo?.admissionDocuments?.graduationCertificate,
                      `Аттестат - ${profileInfo?.student.fullName}`
                    )
                  }
                >
                  Скачать аттестат
                </Button>
              )}
              {profileInfo?.admissionDocuments?.graduationCertificateApplication != null && (
                <Button
                  loading={loading}
                  onClick={() =>
                    handleDownloadFile(
                      profileInfo?.admissionDocuments?.graduationCertificateApplication,
                      `Аттестат(приложение) - ${profileInfo?.student.fullName}`
                    )
                  }
                >
                  Скачать аттестат(приложение)
                </Button>
              )}
              {profileInfo?.copyGraduationCertificate != null && (
                <Button
                  loading={loading}
                  onClick={() =>
                    handleDownloadFile(
                      profileInfo?.copyGraduationCertificate,
                      `Копия аттестата - ${profileInfo?.student.fullName}`
                    )
                  }
                >
                  Скачать копию аттестат
                </Button>
              )}
            </Space>
          )}
        </Space>
      ),
    },
    {
      key: '3',
      label: `Admission documents`,
      children: (
        <Space direction={'vertical'} size={'small'}>
          <Descriptions title={'Данные об удостоверений личности'}>
            <Descriptions.Item label="Тип">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.admissionDocuments?.identityDocumentType?.name || <NoData />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Дата выдачи">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                moment(profileInfo?.admissionDocuments?.identityDocumentIssueDate).format('DD-MM-YYYY') || <NoData />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Срок действия">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                moment(profileInfo?.admissionDocuments?.identityDocumentValidityPeriod).format('DD-MM-YYYY') || (
                  <NoData />
                )
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Орган Выдачи">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.admissionDocuments?.documentIssuingAuthority?.name || <NoData />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Номер">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.admissionDocuments?.identityDocumentNumber || <NoData />
              )}
            </Descriptions.Item>
          </Descriptions>
          {(grantPermission('DEAN', 'addRole') || grantPermission('STUDENT_DEPARTMENT', 'addRole')) && (
            <Space>
              {profileInfo?.admissionDocuments?.identityDocumentScanFront && (
                <Button
                  loading={loading}
                  onClick={() =>
                    handleDownloadFile(
                      profileInfo?.admissionDocuments?.identityDocumentScanFront,
                      `Удостоверение(лицевая сторона) - ${profileInfo?.student.fullName}`
                    )
                  }
                >
                  Скачать удостоверение(лицевая сторона)
                </Button>
              )}
              {profileInfo?.admissionDocuments?.identityDocumentScanBack && (
                <Button
                  loading={loading}
                  onClick={() =>
                    handleDownloadFile(
                      profileInfo?.admissionDocuments?.identityDocumentScanBack,
                      `Удостоверение(задняя сторона) - ${profileInfo?.student.fullName}`
                    )
                  }
                >
                  Скачать удостоверение(задняя сторона)
                </Button>
              )}
            </Space>
          )}
          <Descriptions title={'ЕНТ'}>
            <Descriptions.Item label="ИКТ">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.studentExamInfoDtoResponse?.ikt || <NoData />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Язык сдачи">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.studentExamInfoDtoResponse?.examLanguage || <NoData />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Кол-во баллов">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.studentExamInfoDtoResponse?.entranceExamPoints || <NoData />
              )}
            </Descriptions.Item>
          </Descriptions>
          {(grantPermission('DEAN', 'addRole') || grantPermission('STUDENT_DEPARTMENT', 'addRole')) && (
            <>
              {profileInfo?.admissionDocuments?.entCertificate && (
                <>
                  <Button
                    loading={loading}
                    onClick={() =>
                      handleDownloadFile(
                        profileInfo?.admissionDocuments?.entCertificate,
                        `Сертификат ЕНТ - ${profileInfo?.student.fullName}`
                      )
                    }
                  >
                    Скачать сертификат ЕНТ
                  </Button>
                </>
              )}
              {profileInfo?.student.financing.id === 2 &&
                profileInfo?.admissionDocuments?.documentConfirmingAvailabilityOfInternalGrantScan && (
                  <Button
                    loading={loading}
                    onClick={() =>
                      handleDownloadFile(
                        profileInfo?.admissionDocuments?.documentConfirmingAvailabilityOfInternalGrantScan,
                        `Скан гранта - ${profileInfo?.student.fullName}`
                      )
                    }
                  >
                    Скачать документ подтверждающий на право гранта(скан)
                  </Button>
                )}
            </>
          )}
          <Descriptions title={'АЕТ'}>
            <Descriptions.Item label="Баллы по английскому">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.studentExamInfoDtoResponse?.aetEnglishPoints || <NoData />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Баллы по ИКТ">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.studentExamInfoDtoResponse?.aetIctPoints || <NoData />
              )}
            </Descriptions.Item>
          </Descriptions>
        </Space>
      ),
    },
    {
      key: '6',
      label: `Grant information`,
      children: (
        <Space direction={'vertical'} size={'small'}>
          {profileInfo?.student.financing.id === 2 ? (
            <>
              <Descriptions title={'Информация об гранте'}>
                <Descriptions.Item label="Дата получения">
                  {infoDetailsHidden ? (
                    <Typography.Text type="secondary">(hidden)</Typography.Text>
                  ) : (
                    profileInfo?.studentGrantInformationDtoResponse.grantIssueDate || <NoData />
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Номер">
                  {infoDetailsHidden ? (
                    <Typography.Text type="secondary">(hidden)</Typography.Text>
                  ) : (
                    profileInfo?.studentGrantInformationDtoResponse.grantNumber || <NoData />
                  )}
                </Descriptions.Item>
              </Descriptions>
              {(grantPermission('DEAN', 'addRole') || grantPermission('STUDENT_DEPARTMENT', 'addRole')) && (
                <>
                  {profileInfo?.studentGrantInformationDtoResponse?.grantCertificateFileId && (
                    <Button
                      loading={loading}
                      onClick={() =>
                        handleDownloadFile(
                          profileInfo?.studentGrantInformationDtoResponse?.grantCertificateFileId,
                          `Сертификат гранта - ${profileInfo?.student.fullName}`
                        )
                      }
                    >
                      Скачать сертификат гранта
                    </Button>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Descriptions title={'Информация платном отделений'}>
                <Descriptions.Item label="Сумма">
                  {infoDetailsHidden ? (
                    <Typography.Text type="secondary">(hidden)</Typography.Text>
                  ) : (
                    profileInfo?.studentGrantInformationDtoResponse.paymentAmount || <NoData />
                  )}
                </Descriptions.Item>
              </Descriptions>
              {(grantPermission('DEAN', 'addRole') || grantPermission('STUDENT_DEPARTMENT', 'addRole')) && (
                <>
                  {profileInfo?.studentGrantInformationDtoResponse?.paymentReceiptFileId && (
                    <Button
                      loading={loading}
                      onClick={() =>
                        handleDownloadFile(
                          profileInfo?.studentGrantInformationDtoResponse?.paymentReceiptFileId,
                          `Чек об оплате - ${profileInfo?.student.fullName}`
                        )
                      }
                    >
                      Скачать чек об оплате
                    </Button>
                  )}
                </>
              )}
            </>

          )
          }

        </Space >
      ),
    },
    {
      key: '7',
      label: `Certificates`,
      children: (
        <Space direction={'vertical'} size={'small'}>
          <Descriptions title={'Сертификат по английскому'}>
            <Descriptions.Item label="Тип сертификата">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.studentExamInfoDtoResponse?.englishCertificationType || <NoData />
              )}
            </Descriptions.Item>
            <Descriptions.Item>
              {(grantPermission('DEAN', 'addRole') || grantPermission('STUDENT_DEPARTMENT', 'addRole')) && (
                <>
                  {profileInfo?.studentExamInfoDtoResponse?.englishCertificationFileId && (
                    <Button
                      loading={loading}
                      onClick={() =>
                        handleDownloadFile(
                          profileInfo?.studentExamInfoDtoResponse?.englishCertificationFileId,
                          `Сертификат по английскому - ${profileInfo?.student.fullName}`
                        )
                      }
                    >
                      Скачать сертификат по английскому
                    </Button>
                  )}
                </>
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title={'Сертификат по олимпиаде'}>
            <Descriptions.Item label="Тип олимпиады">
              {infoDetailsHidden ? (
                <Typography.Text type="secondary">(hidden)</Typography.Text>
              ) : (
                profileInfo?.studentExamInfoDtoResponse?.olympiadCertificationType || <NoData />
              )}
            </Descriptions.Item>
            <Descriptions.Item>
              {(grantPermission('DEAN', 'addRole') || grantPermission('STUDENT_DEPARTMENT', 'addRole')) && (
                <>
                  {profileInfo?.studentExamInfoDtoResponse?.olympiadCertificationFileId && (
                    <Button
                      loading={loading}
                      onClick={() =>
                        handleDownloadFile(
                          profileInfo?.studentExamInfoDtoResponse?.olympiadCertificationFileId,
                          `Сертификат по олимпиаде - ${profileInfo?.student.fullName}`
                        )
                      }
                    >
                      Скачать сертификат по олимпиаде
                    </Button>
                  )}
                </>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Space>
      ),
    },
  ];

  const itemsForOffice = [
    profileInfo?.familyDtoResponse && {
      key: '4',
      label: `Parents`,
      children: (
        <Space direction={'vertical'} size={'small'}>
          {profileInfo?.familyDtoResponse.map((family) => (
            <Descriptions title={family?.familyType?.name}>
              <Descriptions.Item label="Фамилия">{family.surnameKz || <NoData />}</Descriptions.Item>
              <Descriptions.Item label="Имя">{family.nameKz || <NoData />}</Descriptions.Item>
              <Descriptions.Item label="Отчество">{family?.patronymicKz || <NoData />}</Descriptions.Item>
              <Descriptions.Item label="Телефон">{family?.phone || <NoData />}</Descriptions.Item>
            </Descriptions>
          ))}
        </Space>
      ),
    },
    {
      key: '5',
      label: `Social information`,
      children: (
        <Space direction={'vertical'} size={'small'}>
          <Descriptions>
            <Descriptions.Item label="Статус">
              {socialStatusList.find((v) => v.id === Number(profileInfo?.studentPersonalInfoDtoResponse?.socialStatus))?.name || <NoData />}
            </Descriptions.Item>
          </Descriptions>
          {(grantPermission('DEAN', 'addRole') || grantPermission('STUDENT_DEPARTMENT', 'addRole')) && (
            <>
              {profileInfo?.studentPersonalInfoDtoResponse?.socialStatusFileId && (
                <Button
                  loading={loading}
                  onClick={() =>
                    handleDownloadFile(
                      Number(profileInfo?.studentPersonalInfoDtoResponse?.socialStatusFileId),
                      `Социальный статус - ${profileInfo?.student.fullName}`
                    )
                  }
                >
                  Скачать документ подтверждающий соц. статус
                </Button>
              )}
            </>)}
          <Descriptions>
            <Descriptions.Item label="Сельская квота">
              {/* {socialStatusList.find((v) => v.id === Number(profileInfo?.studentPersonalInfoDtoResponse?.socialStatus))?.name || <NoData />} */}
              {profileInfo?.studentPersonalInfoDtoResponse.ruralQuote ? 'Да' : 'Нет'}
            </Descriptions.Item>
          </Descriptions>
          {(grantPermission('DEAN', 'addRole') || grantPermission('STUDENT_DEPARTMENT', 'addRole')) && (
            <>
              {profileInfo?.studentPersonalInfoDtoResponse?.form86 && (
                <Button
                  loading={loading}
                  onClick={() =>
                    handleDownloadFile(
                      Number(profileInfo?.studentPersonalInfoDtoResponse?.form86),
                      `Форма 075 - ${profileInfo?.student.fullName}`
                    )
                  }
                >
                  Скачать форму 075
                </Button>
              )}
            </>
          )}
          {(grantPermission('DEAN', 'addRole') || grantPermission('STUDENT_DEPARTMENT', 'addRole')) && (
            <>
              {profileInfo?.studentPersonalInfoDtoResponse?.pripisnoi && (
                <Button
                  loading={loading}
                  onClick={() =>
                    handleDownloadFile(
                      Number(profileInfo?.studentPersonalInfoDtoResponse?.pripisnoi),
                      `Приписной билет - ${profileInfo?.student.fullName}`
                    )
                  }
                >
                  Скачать приписной билет
                </Button>
              )}
            </>
          )}
          <Descriptions>
            <Descriptions.Item label="Место работы">
              {profileInfo?.studentPersonalInfoDtoResponse?.workPlace || <NoData />}
            </Descriptions.Item>
          </Descriptions>
        </Space >
      ),
    },
  ];

  const mergeTabs = (studentTabs: any[], officeTabs: any[]) => {
    return [...studentTabs, ...officeTabs];
  };

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        tabPosition={'left'}
        items={userId ? mergeTabs(itemsForStudent, itemsForOffice) : itemsForStudent}
      />
    </>
  );
};

export default ProfileDetails;
