import { ApplicationStepInfoDtoResponse } from '../../../interfaces/Applications/response/ApplicationStepInfoDtoResponse'
import { Descriptions, Steps, Tag, Typography } from 'antd';
import { ApplicationDtoResponse } from '../../../interfaces/Applications/response/ApplicationDtoResponse';

interface Props {
    applicationStepInfo: ApplicationStepInfoDtoResponse[];
    application: ApplicationDtoResponse;
}

const getStatus = (accepted: boolean | null | undefined) => {
    if (accepted === true) return <Tag color="green">Accepted</Tag>;
    if (accepted === false) return <Tag color="red">Rejected</Tag>;
    if (accepted === undefined) return <Tag color="purple">Not checked</Tag>;
    if (accepted === null) return <Tag color="yellow">Waiting</Tag>;
};

const ApplicationStepInfo = ({ applicationStepInfo, application }: Props) => {
    // Combine the data from applicationSteps and applicationStepInfo
    const combinedSteps = applicationStepInfo?.sort((a: ApplicationStepInfoDtoResponse, b: ApplicationStepInfoDtoResponse) => a.step - b.step).map((step) => {
        return {
            title: "Step - " + step.step,
            description: (
                <Descriptions>
                    <Descriptions.Item label="Status">{getStatus(step?.accepted)}</Descriptions.Item>
                    {step?.executor && (<Descriptions.Item label="Executor">{step?.office.fullNameEn}</Descriptions.Item>)}
                    {!step?.executor && step?.roleId === 8 && (<Descriptions.Item label="Executor"> Dean's Office</Descriptions.Item>)}
                    {step?.comment && (<Descriptions.Item label="Comment">
                        <Typography.Text style={{ maxWidth: "500px" }}>
                            {step.comment}
                        </Typography.Text>
                    </Descriptions.Item>)}
                </Descriptions>
            ),
        };
    });

    return (
        <Steps
            direction="vertical"
            progressDot
            current={application.currentStep - 1}
            items={combinedSteps}
        />
    );
}


export default ApplicationStepInfo