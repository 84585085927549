import styles from '../style.module.css';
import { Typography, Table, Button, Pagination } from 'antd';
import PageWrapper from '../../../../ui/PageWrapper';
import useNotificationByUserPage from './useNotificationByUserPage';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;


const NotificationByUserPage = () => {
    const { notification, column, currentPage, totalPages, setCurrentPage, } = useNotificationByUserPage();

    return (
        <PageWrapper>
            <section>
                <header className={styles.header}>
                    <Title level={3} className={styles.title}>
                        Notification List
                    </Title>
                </header>
                <Table
                    pagination={{
                        current: currentPage,
                        total: totalPages * 10,
                        pageSize: 10,
                        onChange: (page) => setCurrentPage(page),
                    }}
                    dataSource={notification.map((notification) => ({
                        key: notification.id,
                        message: notification.message.text,
                        createdDate: notification.message.createdAt,
                        isRead: notification.isRead,
                        readAt: notification.readAt,
                        moodleMessageIsRead: notification.moodleMessageIsRead,
                        moodleMessageReadAt: notification.moodleMessageReadAt,

                    }))}
                    columns={column}
                />

            </section>
        </PageWrapper>
    )
}

export default NotificationByUserPage;