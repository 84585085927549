import { Select } from 'antd';
import { TrimesterDtoResponse } from '../../../../interfaces/TrimesterDtoResponse';
const { Option } = Select;

type Options = TrimesterDtoResponse[];

interface Props {
  placeholder: string;
  options: Options;
  selectedOption: number | undefined;
  onSelect: (value: number) => void;
  onClear?: () => void;
}

const TrimesterFilter = ({ placeholder, options, selectedOption, onSelect, onClear }: Props) => {
  return (
    <Select
      showSearch
      style={{ width: 400 }}
      placeholder={placeholder}
      value={selectedOption}
      onSelect={onSelect}
      onClear={onClear}
      allowClear={!!onClear}
    >
      {options.map((option) => (
        <Option key={option.id} value={option.id}>
          {option.trimester}
        </Option>
      ))}
    </Select>
  );
};

export default TrimesterFilter;
