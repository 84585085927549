import { Select } from 'antd';
import { DisciplinesDtoResponse } from '../../../../interfaces/DisciplinesDtoResponse';

const { Option } = Select;

type Options = DisciplinesDtoResponse[];

interface Props {
  placeholder: string;
  selectedOption: number | undefined;
  onSelect: (value: number) => void;
  options: Options;
  width?: number;
  onClear?: () => void;
}

const DisciplinesFilter = ({ placeholder, selectedOption, onSelect, options, width, onClear }: Props) => {
  return (
    <Select
      showSearch
      placeholder={placeholder}
      value={selectedOption}
      onSelect={onSelect}
      style={{ width: width ? width : 400 }}
      optionFilterProp="children"
      onClear={onClear}
      allowClear={!!onClear}
    >
      {options.map((option) => (
        <Option key={option.id} value={option.id}>
          {option.discipline}
        </Option>
      ))}
    </Select>
  );
};

export default DisciplinesFilter;
