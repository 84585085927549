import authAxios from '../common/authAxios';
import { CountriesDtoRequest } from '../interfaces/CountriesDtoRequest';



export class CountriesService {
    private static baseUrl = '/astanait-student-module/api/v1/country/'

    static getCountryById(countryId: number) {
        return authAxios.get<CountriesDtoRequest>(
            `${this.baseUrl}get-by-id?countryId=${countryId}`
        );
    }

    static getAllCountires() {
        return authAxios.get<CountriesDtoRequest>(
            `${this.baseUrl}all`
        );
    }
}