import { StudentGroupsDtoResponse } from '../../../../../interfaces/Student/StudentGroupsDtoResponse';
import { Select } from 'antd';

const { Option } = Select;

type Options = StudentGroupsDtoResponse[];

interface Props {
  placeholder: string;
  selectedOption: number | undefined;
  onSelect: (value: number) => void;
  options: Options;
  width?: number;
}

const StudentGroupFilter = ({ placeholder, selectedOption, onSelect, options, width }: Props) => {
  return (
    <Select
      showSearch
      placeholder={placeholder}
      value={selectedOption}
      onSelect={onSelect}
      style={{ width: width ? width : 400 }}
      optionFilterProp="children"
    >
      {options.map((option) => (
        <Option key={option.id} value={option.id}>
          {option.title}
        </Option>
      ))}
    </Select>
  );
};

export default StudentGroupFilter;
