import { useEffect, useState } from 'react';

import { StudentGroupsDtoResponse } from '../../../../interfaces/Student/StudentGroupsDtoResponse';

import { StudentsListDtoResponse } from '../../../../interfaces/Student/StudentsListDtoResponse';
import { StudentsListWithGroupDtoResponse } from '../../../../interfaces/Student/StudentsListWithGroupDtoResponse';
import { TranscriptService } from '../../../../services/TranscriptService';

const useStudentSearch = () => {
  const [groupList, setGroupList] = useState<StudentGroupsDtoResponse[]>([]);
  const [studentsList, setStudentsList] = useState<StudentsListDtoResponse[]>([]);
  const [studentsListWithGroup, setStudentsListWithGroup] = useState<StudentsListWithGroupDtoResponse[]>([]);


  const [selectedGroup, setSelectedGroup] = useState<number | undefined>(undefined);
  const [selectedStudent, setSelectedStudent] = useState<number | undefined>(undefined);




  const student = studentsList.find((student) => student.id === selectedStudent);

  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    TranscriptService.getAllStudentsGroup().then(({ data }) => {
      let res = data.map((item) => {
        return {
          id: item.id,

          title: item.title,
        };
      });
      setGroupList(res);
    });

  }, []);



  const onGroupSelect = async (groupId: number) => {
    setSelectedGroup(groupId);
    setSelectedStudent(undefined);
    TranscriptService.getStudentsByGroupForTranscript(groupId).then(({ data }) => {
      let res = data.map((item) => {
        return {
          id: item.id,
          name: item.nameKz,
          surname: item.surnameKz,
          patronymic: item.patronymicKz,
          userId: item.userId
        };
      });
      setStudentsList(res);
    });
  };

  return {
    onGroupSelect,
    groupList,
    setGroupList,
    studentsList,
    setStudentsList,
    studentsListWithGroup,
    setStudentsListWithGroup,
    student,
    selectedGroup,
    setSelectedGroup,
    selectedStudent,
    setSelectedStudent,
    searchValue,
    setSearchValue,
  }
}

export default useStudentSearch;

