import { useState, ReactNode, useEffect } from 'react';
import { DeansOfficeAppointmentService } from '../../../../services/DeansOfficeAppointmentService';
import { AvailableAppointmentDatesResponse } from '../../../../interfaces/deansOffice/AvailableAppointmentDatesResponse';
import { DeanOfficeAppointmentDtoResponse } from '../../../../interfaces/deansOffice/DeanOfficeAppointmentDtoResponse';

const useDeanOfficeAppointmentList = () => {


    const [selectedFiltersOption, setSelectedFiltersOption] = useState<number | undefined>();
    const [selectedDate, setSelectedDate] = useState<Date | undefined>();
    const [availableDate, setAvailableDate] = useState<AvailableAppointmentDatesResponse[]>([]);
    const [appointmentData, setAppointmentData] = useState<DeanOfficeAppointmentDtoResponse[]>([]);



    useEffect(() => {
        DeansOfficeAppointmentService.getAvailableDates().then(({ data }) => setAvailableDate(data))
        setSelectedDate(undefined);
    }, [selectedFiltersOption]);


    const handleSelectOption = (value: number) => {
        setSelectedFiltersOption(value);
        setSelectedDate(undefined);
    };

    const handleSelectDate = (value: Date) => {
        setSelectedDate(value);
        DeansOfficeAppointmentService.getAllAppointments(selectedFiltersOption, value)
            .then((response) => {
                setAppointmentData(response.data)
            })
    };



    return {

        handleSelect: {
            handleSelectOption,
            handleSelectDate,
        },

        selectedFiltersOption,
        selectedDate,
        availableDate,
        appointmentData

    };
};

export default useDeanOfficeAppointmentList;


