import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { errorNotification } from '../../../helpers/errorNotification';
import { DisciplineDtoResponse } from '../../../interfaces/DisciplineDtoResponse';
import { GroupDtoResponse } from '../../../interfaces/GroupDtoResponse';
import { TeacherService } from '../../../services/TeacherService';
import { VedomostiService } from '../../../services/VedomostiService';
import { IrosService } from '../../../services/student/IrosService';
import { IrosStudentConfirmedByTeacherDto } from '../../../interfaces/Iros/IrosStudentConfirmedByTeacherDto';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

interface FilteredData {
  id: number;
  studentId: number;
  name: string;
  group: string;
  grade?: any;
}

interface FilteredAllGradesData {
  id: number;
  name?: string;
  group?: string;
  grade: number;
}

const useIrosTeachersPage = () => {
  const years = [
    {
      id: 1,
      year: '2020',
    },
    {
      id: 2,
      year: '2021',
    },
    {
      id: 3,
      year: '2022',
    },
    {
      id: 4,
      year: '2023',
    },
  ];

  const trimesters = [
    {
      id: 1,
      trimester: 1,
    },
    {
      id: 2,
      trimester: 2,
    },
    {
      id: 3,
      trimester: 3,
    },
  ];

  const allGradesColumns = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Группа',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'Оценка',
      dataIndex: 'grade',
      key: 'grade',
    },
    {
      title: 'Действие',
      dataIndex: 'operation',
      width: 50,
      render: (_: any, record: any) => (
        <Button icon={<DownloadOutlined />} onClick={handleDownloadDocument}>
          Скачать
        </Button>
      ),
    },
  ];

  const [form] = useForm();
  const [disciplines, setDisciplines] = useState<DisciplineDtoResponse[]>([]);
  const [groups, setGroups] = useState<GroupDtoResponse[]>([]);
  const [studentsData, setStudentsData] = useState([]);
  const [allGradesData, setAllGradesData] = useState<IrosStudentConfirmedByTeacherDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedDisciplineId, setSelectedDisciplineId] = useState<number | null>(null);

  const filteredData: FilteredData[] = [];
  studentsData.forEach((item: any) => {
    filteredData.push({
      studentId: item.studentDtoResponse.id,
      id: item.id,
      name: `${item.studentDtoResponse.surnameKz} ${item.studentDtoResponse.nameKz} ${item.studentDtoResponse.patronymicKz}`,
      group: `${item.studentDtoResponse.group.title}`,
      grade: item.points,
    });
  });

  const filteredAllGradesData: FilteredAllGradesData[] = [];
  allGradesData.forEach((item: IrosStudentConfirmedByTeacherDto) => {
    filteredAllGradesData.push({
      id: item.id,
      // name: `${item.studentDtoResponse.surnameKz} ${item.studentDtoResponse.nameKz} ${item.studentDtoResponse.patronymicKz}`,
      // group: `${item.studentDtoResponse.group.title}`,
      grade: item.points,
    });
  });

  const handleDownloadDocument = () => {
    errorNotification('На данный момент невозможно скачать документ', 500);
  };

  const handleSelectYear = (value: number) => {
    form.setFieldsValue({
      term: null,
      disciplineId: null,
      groupId: null,
    });
    setSelectedDisciplineId(null);
  };

  const handleSelectTrim = (value: number) => {
    form.setFieldsValue({
      disciplineId: null,
      groupId: null,
    });
    setSelectedDisciplineId(null);
    setLoading(true);
    VedomostiService.getDisciplineByTeacher(form.getFieldValue('year'), value)
      .then(({ data }) => setDisciplines(data))
      .finally(() => setLoading(false));
  };

  const handleSelectDiscipline = (value: number) => {
    form.setFieldsValue({
      groupId: null,
    });
    setSelectedDisciplineId(value);
    setLoading(true);
    TeacherService.getGroupsByTeacherIdAndDisciplineId(value, form.getFieldValue('year'), form.getFieldValue('term'))
      .then(({ data }) => setGroups(data))
      .finally(() => setLoading(false));
  };

  const handleSearchStatement = () => {
    setLoading(true);
    IrosService.getVedomostForTeacher(
      form.getFieldValue('year'),
      form.getFieldValue('term'),
      form.getFieldValue('groupId'),
      form.getFieldValue('disciplineId')
    )
      .then(({ data }) => {
        setStudentsData(data);
        IrosService.getAllGradesForTeacher().then(({ data }) => {
          setAllGradesData(data);
        });
      })
      .finally(() => setLoading(false));
  };

  return {
    data: {
      form,
      trimesters,
      filteredAllGradesData,
      filteredData,
      years,
      disciplines,
      selectedDisciplineId,
      setSelectedDisciplineId,
      setDisciplines,
      groups,
      setGroups,
      studentsData,
      setStudentsData,
      loading,
      setLoading,
      allGradesData,
      allGradesColumns,
    },
    handlers: {
      handleSelectYear,
      handleSelectTrim,
      handleSelectDiscipline,
      handleSearchStatement,
    },
  };
};

export default useIrosTeachersPage;
