import React from 'react';
import { Button, Dropdown, Input, Space, Typography } from 'antd';
import { EditOutlined, PlusOutlined, RollbackOutlined, DownOutlined } from '@ant-design/icons';
import { CustomData } from '../../../../interfaces/Syllabus/SyllabusDataDto';

interface SyllabusFieldProps {
    type: "input-textarea" | "input" | "list" | "table" | "reading-list";
    sectionItem?: CustomData;
    sectionItems?: CustomData[];
    handleEditModalOpen: (sectionItem: CustomData | undefined) => void;
    handleDelete: (data: CustomData) => void;
    handleCreateModalOpen: (sectionItems: CustomData[] | undefined) => void;
    handleUndo: (data: CustomData | undefined) => void;
}

const backgroundStyle = (action: string) => {
    switch (action) {
        case "d":
            return "#800f19";
        case "u":
            return "#15395b";
        case "i":
            return "#274916";
        default:
            return "transparent";
    }

}

const renderAddItemBtn = (items: CustomData[], handleCreateModalOpen: (sectionItems: CustomData[] | undefined) => void) => (
    <Button
        type={"primary"}
        icon={<PlusOutlined />}
        style={{ maxWidth: "100px" }}
        onClick={() => handleCreateModalOpen(items)}
    >
        Add
    </Button>
);

const SyllabusFieldRenderer: React.FC<SyllabusFieldProps> = ({
    type, sectionItem, sectionItems, handleEditModalOpen, handleDelete, handleCreateModalOpen, handleUndo
}) => {
    switch (type) {
        case "input":
            return (
                <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "20px" }}>
                    <Input
                        style={{ cursor: "pointer" }}
                        defaultValue={sectionItem?.value ?? ''}
                        readOnly
                    />
                    <Button type={"primary"} icon={<EditOutlined />} style={{ maxWidth: "100px" }} onClick={() => handleEditModalOpen(sectionItem)} />
                </div>
            );
        case "input-textarea":
            return (
                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
                    <Input.TextArea
                        style={{ height: 400, cursor: "pointer" }}
                        defaultValue={sectionItem?.value ?? ''}
                        readOnly
                    />
                    <Button type={"primary"} icon={<EditOutlined />} style={{ maxWidth: "100px" }} onClick={() => handleEditModalOpen(sectionItem)}>Edit</Button>
                    <Button type={"primary"} icon={<RollbackOutlined />} style={{ maxWidth: "100px" }} onClick={() => handleUndo(sectionItem)} >Undo</Button>
                </div>
            );
        case "list":
            return (
                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
                    {sectionItems?.sort((a, b) => a.order - b.order)?.map((item, index) => (
                        <div key={index} style={{ display: "flex" }}>
                            <Input
                                style={{ cursor: "pointer", background: backgroundStyle(item.action), marginRight: "20px" }}
                                defaultValue={item.value}
                                readOnly
                            />
                            <Dropdown
                                menu={{
                                    onClick: ({ key }) => {
                                        switch (key) {
                                            case "edit":
                                                handleEditModalOpen(item);
                                                break;
                                            case "delete":
                                                handleDelete(item);
                                                break;
                                            case "undo":
                                                handleUndo(item)
                                                break;
                                        }
                                    },
                                    items: [
                                        { key: "edit", label: "Edit" },
                                        { key: "delete", label: "Delete" },
                                        { key: "undo", label: "Undo" }
                                    ]
                                }}>
                                <Space>
                                    Action
                                    <DownOutlined />
                                </Space>
                            </Dropdown>
                        </div>
                    ))}
                    {sectionItems && renderAddItemBtn(sectionItems, handleCreateModalOpen)}
                </div>
            );
        case "reading-list":
            return (
                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
                        <Typography.Text>Basic literature</Typography.Text>
                        {sectionItems?.filter(item => item.prefix.prefix === 'basic_literature').sort((a, b) => a.order - b.order)?.map((item, index) => (
                            <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
                                <Input
                                    style={{ cursor: "pointer", background: backgroundStyle(item.action), marginRight: "20px" }}
                                    defaultValue={item.value}
                                    readOnly
                                />
                                <Dropdown
                                    menu={{
                                        onClick: ({ key }) => {
                                            switch (key) {
                                                case "edit":
                                                    handleEditModalOpen(item);
                                                    break;
                                                case "delete":
                                                    handleDelete(item);
                                                    break;
                                                case "undo":
                                                    handleUndo(item)
                                                    break;
                                            }
                                        },
                                        items: [
                                            { key: "edit", label: "Edit" },
                                            { key: "delete", label: "Delete" },
                                            { key: "undo", label: "Undo" }
                                        ]
                                    }}>
                                    <Space>
                                        Action
                                        <DownOutlined />
                                    </Space>
                                </Dropdown>
                            </div>
                        ))}
                    </div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
                        <Typography.Text>Supplementary literature</Typography.Text>
                        {sectionItems?.filter(item => item.prefix.prefix === 'supplementary_literature').sort((a, b) => a.order - b.order)?.map((item, index) => (
                            <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
                                <Input
                                    style={{ cursor: "pointer", background: backgroundStyle(item.action), marginRight: "20px" }}
                                    defaultValue={item.value}
                                    readOnly
                                />
                                <Dropdown
                                    menu={{
                                        onClick: ({ key }) => {
                                            switch (key) {
                                                case "edit":
                                                    handleEditModalOpen(item);
                                                    break;
                                                case "delete":
                                                    handleDelete(item);
                                                    break;
                                                case "undo":
                                                    handleUndo(item)
                                                    break;
                                            }
                                        },
                                        items: [
                                            { key: "edit", label: "Edit" },
                                            { key: "delete", label: "Delete" },
                                            { key: "undo", label: "Undo" }
                                        ]
                                    }}>
                                    <Space>
                                        Action
                                        <DownOutlined />
                                    </Space>
                                </Dropdown>
                            </div>
                        ))}
                    </div>
                    {sectionItems && renderAddItemBtn(sectionItems, handleCreateModalOpen)}
                </div>
            );
        case "table":
            return (<></>);
    }
};

export default SyllabusFieldRenderer;
