import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Modal, Input, Tag, Select, DatePicker } from 'antd';
import PageWrapper from "../../../ui/PageWrapper";
import { EditOutlined, PlusOutlined, DownloadOutlined, CheckOutlined, CloseOutlined, FileExcelOutlined } from '@ant-design/icons';
import useProfilePage from "../ProfilePage/useProfilePage";
import { FileService } from "../../../services/file/FileService";
import { successNotification } from "../../../helpers/successNotification";
import { FxExamApplicationService } from "./FxExamApplicationService";
interface FxExamApplicationItem {
    id: number;
    iin: number;
    fileId: number;
    studentName: string;
    studentSurname: string;
    disciplineName: string;
    fileName: string;
    approved: number;
    createdDate: Date;
    course: number;
    studentStatus: string;
    creditAmount: number;
    comment: string;
}



interface Props {
    userId?: number;
}



const FxExamApplicationsList = ({ userId }: Props) => {
    const [fxExamApplicationData, setFxExamApplicationData] = useState<FxExamApplicationItem[]>([]);
    const { profileInfo } = useProfilePage(userId);
    const { Option } = Select;
    const { RangePicker } = DatePicker;

    useEffect(() => {
        fetchFxExamApplicationData();
    }, [profileInfo]);

    const fetchFxExamApplicationData = () => {
        const params = {
            studentStatus: filters.studentStatus,
            course: filters.course,
            approved: filters.approved,
            startDate: filters.startDate,
            endDate: filters.endDate
        };

        // authAxios.get("http://localhost:8765/astanait-office-module/api/v1/fx-exam-application/fx-exam-students-filter1", { params })
        FxExamApplicationService.fetchFxExamApplicationData(params)
            .then((response) => {
                setFxExamApplicationData(response.data);
            })
            .catch((error) => {
                console.error("Ошибка при получении данных:", error);
            });
    };


    const fetchFxExamApplicationExcel = () => {
        const params = {
            studentStatus: filters.studentStatus,
            course: filters.course,
            approved: filters.approved,
            startDate: filters.startDate,
            endDate: filters.endDate
        };

        // authAxios.get("http://localhost:8765/astanait-office-module/api/v1/fx-exam-application/fx-exam-students-filter", {
        //     params,
        //     responseType: 'blob'
        // })
        FxExamApplicationService.fetchFxExamApplicationExcel(params)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'fx-exam-applications-' + filters.studentStatus + '-' + filters.course + '-' + filters.startDate + '-' + filters.endDate + '.xlsx');
                document.body.appendChild(link);
                link.click();
                if (link.parentNode) {
                    link.parentNode.removeChild(link);
                }
            })
            .catch((error) => {
                console.error("Ошибка при получении данных:", error);
            });
    };




    const formatDateString = (dateString: string): string => {

        if (!dateString || new Date(dateString).toString() === 'Invalid Date') {
            return 'Unknown';
        }
        const date = new Date(dateString);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        return formattedDate;
    };

    const moveToSecondStage = (id: number) => {
        // authAxios.put(`http://localhost:8765/astanait-office-module/api/v1/fx-exam-application/move-to-second?id=${id}`)
        FxExamApplicationService.moveToSecondStage(id)
            .then(() => {
                fetchFxExamApplicationData();
            })
            .catch(error => console.error("Ошибка при обновлении статуса:", error));
    };

    const moveToThirdStage = (id: number) => {
        // authAxios.put(`http://localhost:8765/astanait-office-module/api/v1/fx-exam-application/move-to-third?id=${id}`)
        FxExamApplicationService.moveToThirdStage(id)
            .then(() => {
                fetchFxExamApplicationData();
            })
            .catch(error => console.error("Ошибка при обновлении статуса:", error));
    };

    const downloadFile = (id: number, disciplineName: string, studentName: string, studentSurname: string) => {
        const fileName = `Квитанция_${disciplineName}_${studentName}_${studentSurname}`;
        FileService.getFileById(id, fileName)
            .then(() => successNotification("Файл успешно скачан!"))
    };

    const getStatusText = (status: number) => {
        switch (status) {
            case 1:
                return <Tag color="blue">In processing</Tag>;
            case 2:
                return <Tag color="red">Declined</Tag>;
            case 3:
                return <Tag color="green">Approved</Tag>;
            default:
                return <Tag color="gray">Unknown status</Tag>;
        }
    };


    interface FilterState {
        studentStatus: string;
        course: number | null;
        approved: number | null;
        startDate: string | null;
        endDate: string | null;
    }

    const [filters, setFilters] = useState<FilterState>({
        studentStatus: '',
        course: null,
        approved: null,
        startDate: null,
        endDate: null
    });


    const approveAllFilteredApplications = () => {
        fxExamApplicationData.forEach(item => {
            if (shouldProcessItem(item)) {
                moveToThirdStage(item.id);
            }
        });
    };

    const rejectAllFilteredApplications = () => {
        fxExamApplicationData.forEach(item => {
            if (shouldProcessItem(item)) {
                moveToSecondStage(item.id);
            }
        });
    };

    const shouldProcessItem = (item: FxExamApplicationItem) => {
        return (!filters.studentStatus || item.studentStatus === filters.studentStatus) &&
            (!filters.course || item.course === filters.course) &&
            (!filters.approved || item.approved === filters.approved) &&
            (!filters.startDate || new Date(item.createdDate) >= new Date(filters.startDate)) &&
            (!filters.endDate || new Date(item.createdDate) <= new Date(filters.endDate));
    };



    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentComment, setCurrentComment] = useState('');
    const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
    const [studentInfo, setStudentInfo] = useState({
        name: '',
        surname: '',
        discipline: ''
    });

    const showModal = (record: FxExamApplicationItem) => {
        setSelectedRecordId(record.id);
        setCurrentComment(record.comment || '');
        setStudentInfo({
            name: record.studentName,
            surname: record.studentSurname,
            discipline: record.disciplineName
        });
        setIsModalVisible(true);
    };

    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentComment(e.target.value);
    };

    const handleCommentSubmit = () => {
        if (selectedRecordId !== null) {
            handleComment(selectedRecordId, currentComment);
            setIsModalVisible(false);
        }
    };

    const handleComment = (id: number, comment: string) => {
        FxExamApplicationService.addComment(id, comment)
            // authAxios.put(`http://localhost:8765/astanait-office-module/api/v1/fx-exam-application/add-comment?id=${id}&comment=${comment}`)
            .then(() => {
                fetchFxExamApplicationData();
                successNotification("Комментарий обновлен");
            })
            .catch(error => console.error("Ошибка при обновлении комментария:", error));
    };

    const [searchIIN, setSearchIIN] = useState('');

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchIIN(e.target.value);
    };

    const columns = [
        // {
        //     title: 'Фамилия',
        //     dataIndex: 'lastname',
        //     key: 'lastname',
        // },
        // {
        //     title: 'Имя',
        //     dataIndex: 'firstname',
        //     key: 'firstname',
        // },
        {
            title: (
                <>
                    {/* ИИН Студента */}
                    <Input
                        placeholder="Find by IIN"
                        value={searchIIN}
                        onChange={handleSearchChange}
                        style={{ width: 150, margin: '10px 0' }}
                    />
                </>
            ),
            dataIndex: 'iin',
        },
        {
            title: 'Name',
            dataIndex: 'studentName',
        },
        {
            title: 'Surname',
            dataIndex: 'studentSurname',
        },
        {
            title: 'Course',
            dataIndex: 'course',
        },
        {
            title: 'Student status',
            dataIndex: 'studentStatus',
        },
        {
            title: 'Discipline',
            dataIndex: 'disciplineName',
        },

        {
            title: 'Amount of credits',
            dataIndex: 'creditAmount',
        },
        // {
        //     title: 'Квитанция',
        //     dataIndex: 'fileName',
        //     key: 'final',
        // },
        {
            title: 'Application status',
            dataIndex: 'approved',
            key: 'approved',
            render: (status: number) => <span>{getStatusText(status)}</span>,
        },
        {
            title: 'Submission date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (text: string) => <span>{formatDateString(text)}</span>

        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            render: (text: string, record: FxExamApplicationItem) => (
                <>
                    <span>{record.comment}  </span>
                    <Button onClick={() => showModal(record)}>{record.comment ? <EditOutlined /> : <PlusOutlined />}</Button>
                </>
            )
        },
        {
            title: 'Receipt',
            dataIndex: 'fileId',
            render: (text: string, record: any) =>
                <Button icon={<DownloadOutlined />} onClick={() => downloadFile(record.fileId, record.disciplineName, record.studentName, record.studentSurname)} type="default">Download</Button>
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: FxExamApplicationItem) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button icon={<CheckOutlined />} onClick={() => moveToThirdStage(record.id)} type='primary' >Approve</Button>
                    <Button icon={<CloseOutlined />} onClick={() => moveToSecondStage(record.id)} type='primary' danger style={{ marginLeft: '10px' }}>Decline</Button>
                </div>
            )
        }

        // {
        //     title: 'email',
        //     dataIndex: 'email',
        //     key: 'email',
        // }
    ]



    const filteredData = fxExamApplicationData.filter(item =>
        item.iin.toString().includes(searchIIN)
    );

    return (
        <PageWrapper>
            <Typography.Title level={1}>FX Exam Applications</Typography.Title>

            <div>
                <Select
                    value={filters.studentStatus}
                    onChange={value => setFilters({ ...filters, studentStatus: value })}
                    style={{ width: 300 }}
                    placeholder="Educational Degree"
                >
                    <Option value="null">All statuses</Option>
                    <Option value="Bachelor">Bachelor</Option>
                    <Option value="Master of scientific and pedagogical direction">Master of scientific and pedagogical direction</Option>
                    <Option value="Master of the core area">Master of the core area</Option>
                    <Option value="Master of Business Administration">Master of Business Administration</Option>
                    <Option value="Резидент">Резидент</Option>
                    <Option value="Doctor PhD">Doctor PhD</Option>
                    <Option value="Doctor with specialization">Doctor with specialization</Option>
                    <Option value="Specialist">Specialist</Option>
                    <Option value="Preparatory department">Preparatory department</Option>

                </Select><br /><br />
                <Select
                    value={filters.course}
                    onChange={value => setFilters({ ...filters, course: value })}
                    style={{ width: 300 }}
                    placeholder="Course"
                >
                    <Option value={0}>All courses</Option>
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                </Select><br /><br />
                <Select
                    value={filters.approved}
                    onChange={value => setFilters({ ...filters, approved: value })}
                    style={{ width: 300 }}
                    placeholder="Application status"
                >
                    <Option value={0}>All statuses</Option>
                    <Option value={1}>In processing</Option>
                    <Option value={2}>Declined</Option>
                    <Option value={3}>Approved</Option>
                </Select><br /><br />
                <RangePicker
                    onChange={dates => {
                        const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null;
                        const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null;
                        setFilters({ ...filters, startDate, endDate });
                    }}
                /><br /><br />
                <Button onClick={fetchFxExamApplicationData}>Apply filter</Button> <br /><br />
                <Button onClick={approveAllFilteredApplications} style={{ marginRight: '20px' }} type='primary'><CheckOutlined /> Approve all applications by filter</Button>
                <Button onClick={rejectAllFilteredApplications} type='primary' danger><CloseOutlined /> Decline all applications by filter</Button> <br /><br />
                <Button onClick={fetchFxExamApplicationExcel}><FileExcelOutlined /> Excel export by filter options</Button><br /><br />
            </div>
            {/* <div>
                <Input
                    placeholder="Поиск по ИИН"
                    value={searchIIN}
                    onChange={handleSearchChange}
                    style={{ width: 200, marginBottom: 20 }}
                />
            </div> */}
            <div>
                <Table dataSource={filteredData} columns={columns} scroll={{ x: '500px' }}/>

            </div>


            <Modal
                title="Comment"
                visible={isModalVisible}
                onOk={handleCommentSubmit}
                onCancel={() => setIsModalVisible(false)}
            >
                <p>Name: {studentInfo.name}</p>
                <p>Surname: {studentInfo.surname}</p>
                <p>Discipline: {studentInfo.discipline}</p>
                <Input.TextArea value={currentComment} onChange={handleCommentChange} />
            </Modal>
        </PageWrapper>
    );
};

export default FxExamApplicationsList;