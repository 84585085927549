import authAxios from '../common/authAxios';

export class AgreementService {
    private static baseUrl = '/astanait-office-module/api/v1/academic-mobility/agreement';

    static getAllAgreementType() {
        return authAxios.get(`${this.baseUrl}/agreement-type`);
    }

    static getAllAgreement() {
        return authAxios.get(this.baseUrl);
    }

    static getAgreementTypeById(id: number) {
        return authAxios.get(`${this.baseUrl}/agreement-type/${id}`);
    }

    static getAgreementById(id: number) {
        return authAxios.get(`${this.baseUrl}/${id}`);
    }

    static createAgreement(formData: FormData) {
        return authAxios.post(this.baseUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static createAgreementType(agreementType: any) {
        return authAxios.post(`${this.baseUrl}/agreement-type`, agreementType);
    }

    static deleteAgreementType(id: number) {
        return authAxios.delete(`${this.baseUrl}/agreement-type/${id}`);
    }

    static deleteAgreement(id: number) {
        return authAxios.delete(`${this.baseUrl}/${id}`);
    }

    static updateAgreement(id: number, formData: FormData) {
        return authAxios.put(`${this.baseUrl}/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static updateAgreementType(id: number, agreementType: any) {
        return authAxios.put(`${this.baseUrl}/agreement-type/${id}`, agreementType);
    }
}
