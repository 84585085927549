import { Divider, Progress, Select, Space, Tabs } from 'antd';
import Title from 'antd/es/typography/Title';
import TeacherInfoSection from '../TeacherInfoSection';
import TeacherAvatarSection from '../TeacherAvatarSection';
import PageWrapper from '../../../ui/PageWrapper';
import useTeacher from '../TeachersPage/useTeacher';
import { Bar } from 'react-chartjs-2';
import { getFullDate } from '../../../common/getFullDate';
import {
  TeacherEducationTable,
  TeacherAcademicTitleTable,
  TeacherArticlesTable,
  TeacherScholarInformationTable,
  TeacherScientificProjectsTable,
  TeacherCertificatesTable,
  TeacherWorkTable,
  TeacherDevelopments,
  TeacherLegalDocsTable,
  TeacherAdditionalInfoTable,
  TeacherOrderTable,
  TeacherTrainingTable,
  TeacherICTable,
  TeacherExternalPartTable
} from '../TeachersPage/TeacherTables';
import useFetchData from '../../../hooks/useFetchData';
import { TeacherDtoResponse } from '../../../interfaces/Teacher/TeacherDtoResponse';
import { TeacherService } from '../../../services/TeacherService';

function TeacherProfile() {
  const { data, handlers } = useTeacher();
  const { data: teacherInfo, fetchData: fetchTeacherData } = useFetchData<TeacherDtoResponse>(TeacherService.getTeacherPrincipal, true);

  return (
    <PageWrapper>
      <TeacherAvatarSection />

      <Title level={3}>Account info</Title>
      <Divider />

      <TeacherInfoSection
        teacherInfo={teacherInfo}
        fetchTeacherData={fetchTeacherData}
      />

      {Number(data.kpiAppications?.kpi) > 0 && (
        <>
          <Divider />

          <Title level={3}>KPI information</Title>
          <Select placeholder="Select Year" onSelect={(e: any) => handlers.handleUpdateKpiInfo(e)} defaultValue={data.settings.find(v => v.isCurrent === true)?.id} style={{ width: 200 }}>
            {data.settings.map((setting, key) => (
              <Select.Option key={key} value={setting.id} style={{ background: setting.isCurrent ? 'green' : 'none' }}>
                {getFullDate(String(setting.startDate))} - {getFullDate(String(setting.endDate))}
              </Select.Option>
            ))}
          </Select>
          <Space direction="vertical" style={{ width: '60%' }}>
            <Space style={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <Progress type="circle" percent={data.kpiAppications?.kpi && data.kpiAppications?.kpi * 100} />
            </Space>
            <Bar
              data={data.kpiData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </Space>
        </>
      )}

      <Divider />
      <Tabs defaultActiveKey='item-0' type="card" size='large' items={[
        {
          label: 'Additional info',
          key: 'item-0',
          children:
            <TeacherAdditionalInfoTable
              isPublic={false}
            />,
        },
        {
          label: 'Education',
          key: 'item-1',
          children:
            <TeacherEducationTable
              isPublic={false}
            />,
        },
        {
          label: 'Academic degree/Academic title',
          key: 'item-2',
          children:
            <TeacherAcademicTitleTable
              isPublic={false}
            />,
        },
        {
          label: 'Articles',
          key: 'item-3',
          children:
            <TeacherArticlesTable
              isPublic={false}
            />,
        },
        {
          label: 'Scientific information',
          key: 'item-4',
          children:
            <TeacherScholarInformationTable
              isPublic={false}
            />,
        },
        {
          label: 'Research projects',
          key: 'item-5',
          children:
            <TeacherScientificProjectsTable
              isPublic={false}
            />,
        },
        {
          label: 'Certificates',
          key: 'item-6',
          children:
            <TeacherCertificatesTable
              isPublic={false}
            />,
        },
        {
          label: 'Work experience',
          key: 'item-7',
          children:
            <TeacherWorkTable
              isPublic={false}
            />,
        },
        {
          label: 'Developments',
          key: 'item-8',
          children:
            <TeacherDevelopments
              isPublic={false}
            />,
        },
        {
          label: 'Intellectual property',
          key: 'item-9',
          children:
            <TeacherLegalDocsTable
              isPublic={false}
            />,
        },
        {
          label: 'Orders',
          key: 'item-10',
          children: <TeacherOrderTable />,
        },
        {
          label: 'Trainings',
          key: 'item-11',
          children: <TeacherTrainingTable />,
        },
        {
          label: 'IC information',
          key: 'item-12',
          children: <TeacherICTable />,
        },
        {
          label: "External part-time",
          key: 'item-13',
          children: <TeacherExternalPartTable userId={teacherInfo?.userId} />,
        }
      ]} />
    </PageWrapper>
  );
}

export default TeacherProfile;
