import { Table, Button, Modal, Divider, Space, Typography, InputNumber, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { VedomostiService } from '../../../../../services/VedomostiService';
import TextArea from 'antd/lib/input/TextArea';
import { successNotification } from '../../../../../helpers/successNotification';

const { Column, ColumnGroup } = Table;

function gradeConvert(grade: any) {
  grade = Math.floor(grade);
  let alphabeticGrade = '';
  let gpaGrade = 0;
  let traditionalGrade = '';

  switch (true) {
    case grade >= 95:
      alphabeticGrade = 'A';
      gpaGrade = 4;
      traditionalGrade = 'Excellent';
      break;
    case grade >= 90 && grade <= 94:
      alphabeticGrade = 'A-';
      gpaGrade = 3.67;
      traditionalGrade = 'Excellent';
      break;
    case grade >= 85 && grade <= 89:
      alphabeticGrade = 'B+';
      gpaGrade = 3.33;
      traditionalGrade = 'Good';
      break;
    case grade >= 80 && grade <= 84:
      alphabeticGrade = 'B';
      gpaGrade = 3;
      traditionalGrade = 'Good';
      break;
    case grade >= 75 && grade <= 79:
      alphabeticGrade = 'B-';
      gpaGrade = 2.67;
      traditionalGrade = 'Good';
      break;
    case grade >= 70 && grade <= 74:
      alphabeticGrade = 'C+';
      gpaGrade = 2.33;
      traditionalGrade = 'Good';
      break;
    case grade >= 65 && grade <= 69:
      alphabeticGrade = 'C';
      gpaGrade = 2;
      traditionalGrade = 'Satisfactory';
      break;
    case grade >= 60 && grade <= 64:
      alphabeticGrade = 'C-';
      gpaGrade = 1.67;
      traditionalGrade = 'Satisfactory';
      break;
    case grade >= 55 && grade <= 59:
      alphabeticGrade = 'D+';
      gpaGrade = 1.33;
      traditionalGrade = 'Satisfactory';
      break;
    case grade >= 50 && grade <= 54:
      alphabeticGrade = 'D';
      gpaGrade = 1;
      traditionalGrade = 'Satisfactory';
      break;
    case grade >= 30 && grade <= 49:
      alphabeticGrade = 'FX';
      gpaGrade = 0;
      traditionalGrade = 'Fail';
      break;
    case grade >= 0 && grade <= 29:
      alphabeticGrade = 'F';
      gpaGrade = 0;
      traditionalGrade = 'Fail';
      break;
  }

  return {
    alphabeticGrade,
    gpaGrade,
    traditionalGrade,
  };
}

interface Props {
  vedomostData: any;
  loading: boolean;
}

const DeclinedTable = ({ vedomostData, loading }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [showReason, setShowReason] = useState<number>();
  const [academicStreamGrades, setAcademicStreamGrade] = useState<any>({});
  const [modalData, setModalData] = useState<any>([]);
  const [marks, setMarks] = useState<any>({});

  let filteredData = vedomostData?.map((item: any, id: number) => {
    let rating = 0;
    let exam = 0;
    let final = 0;

    item.academicStreamStudentGrade.forEach((item: any) => {
      if (item.gradeType === 1 || item.gradeType === 2) {
        rating += +item.grade;
      }
      if (item.gradeType === 3) {
        exam = item.grade;
      }
      if (item.gradeType === 4) {
        final = item.grade;
      }
    });

    return {
      key: id,
      name: `${item.student.nameKz}  ${item.student.surnameKz}`,
      academicStreamGrades: item.academicStreamStudentGrade,
      group: item.student.group.title,
      rating: Number(rating / 2).toFixed(1),
      exam: Number(exam).toFixed(1),
      total: final,
      approved: item.approved,
      reason: item.reason,
      teacher: `${item.teacherDtoResponse.nameKz}  ${item.teacherDtoResponse.surnameKz}`,
      discipline: item.discipline.titleRu,
    };
  });

  // @ts-ignore
  const data = [...filteredData];

  // @ts-ignore
  data.forEach((item) => {
    let ratingConvert = gradeConvert(+item.rating);
    let examConvert = gradeConvert(+item.exam);
    let totalConvert = gradeConvert(+item.total);
    // @ts-ignore
    item.ratingGPA = ratingConvert.gpaGrade;
    // @ts-ignore
    item.ratingALPH = ratingConvert.alphabeticGrade;
    // @ts-ignore
    item.ratingTRD = ratingConvert.traditionalGrade;
    // @ts-ignore
    item.examGPA = examConvert.gpaGrade;
    // @ts-ignore
    item.examALPH = examConvert.alphabeticGrade;
    // @ts-ignore
    item.examTRD = examConvert.traditionalGrade;
    // @ts-ignore
    item.totalGPA = totalConvert.gpaGrade;
    // @ts-ignore
    item.totalALPH = totalConvert.alphabeticGrade;
    // @ts-ignore
    item.totalTRD = totalConvert.traditionalGrade;
  });

  const handleOpenModal = (value: any) => {
    setIsModalOpen(true);
    setAcademicStreamGrade(value);
  };
  const handleOnOk = () => {
    Promise.all([
      VedomostiService.editStudentMark(
        academicStreamGrades.academicStreamGrades?.find((id: any) => id.gradeType === 1).id,
        marks.mid
      ),
      VedomostiService.editStudentMark(
        academicStreamGrades.academicStreamGrades?.find((id: any) => id.gradeType === 2).id,
        marks.end
      ),
      VedomostiService.editStudentMark(
        academicStreamGrades.academicStreamGrades?.find((id: any) => id.gradeType === 3).id,
        marks.final
      ),
    ])
      .then(() => {
        successNotification('Данные успешно сохранены');
        // shouldRerender();
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };

  useEffect(() => {
    setMarks({
      mid: academicStreamGrades.academicStreamGrades?.find((grade: any) => {
        return grade.gradeType === 1;
      }).grade,

      end: academicStreamGrades.academicStreamGrades?.find((grade: any) => {
        return grade.gradeType === 2;
      }).grade,

      final: academicStreamGrades.academicStreamGrades?.find((grade: any) => {
        return grade.gradeType === 1;
      }).grade,
    });
  }, [academicStreamGrades]);

  const openModal = (record: any) => {
    setReasonModal(true);
    setModalData(record);
  };

  return (
    <>
      {isModalOpen && academicStreamGrades.hasOwnProperty('academicStreamGrades') && (
        <Modal
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          okText="Сохранить"
          cancelText="Отмена"
          onOk={handleOnOk}
        >
          <Typography.Title level={4}>Редактировать оценки</Typography.Title>
          <Divider />
          <Space size="middle">
            <Space direction="vertical">
              <strong>Mid term</strong>
              <InputNumber
                style={{ width: 100 }}
                value={
                  academicStreamGrades.academicStreamGrades?.find((grade: any) => {
                    return grade.gradeType === 1;
                  }).grade
                }
                placeholder="Mid term"
                onChange={(value) => setMarks({ ...marks, mid: value })}
              />
            </Space>
            <Space direction="vertical">
              <strong>End term</strong>
              <InputNumber
                style={{ width: 100 }}
                value={
                  academicStreamGrades.academicStreamGrades?.find((grade: any) => {
                    return grade.gradeType === 2;
                  }).grade
                }
                placeholder="End term"
                onChange={(value) => setMarks({ ...marks, end: value })}
              />
            </Space>
            <Space direction="vertical">
              <strong>Final</strong>
              <InputNumber
                style={{ width: 100 }}
                value={
                  academicStreamGrades.academicStreamGrades?.find((grade: any) => {
                    return grade.gradeType === 3;
                  }).grade
                }
                placeholder="Final"
                onChange={(value) => setMarks({ ...marks, final: value })}
              />
            </Space>
          </Space>
        </Modal>
      )}
      <Table scroll={{ x: true }} loading={loading} dataSource={data} bordered>
        <Column title={'ФИО студента'} dataIndex={'name'} />
        <Column title={'Группа'} dataIndex={'group'} />
        <ColumnGroup title={'Рейтинг'}>
          <Column title={'В %'} dataIndex={'rating'} />
          <Column title={'Цифра'} dataIndex={'ratingGPA'} />
          <Column title={'Буква'} dataIndex={'ratingALPH'} />
          <Column title={'Традиционная'} dataIndex={'ratingTRD'} />
        </ColumnGroup>
        <ColumnGroup title={'Экзамен'}>
          <Column title={'В %'} dataIndex={'exam'} />
          <Column title={'Цифра'} dataIndex={'examGPA'} />
          <Column title={'Буква'} dataIndex={'examALPH'} />
          <Column title={'Традиционная'} dataIndex={'examTRD'} />
        </ColumnGroup>
        <ColumnGroup title={'Итоговая оценка'}>
          <Column title={'В %'} dataIndex={'total'} />
          <Column title={'Цифра'} dataIndex={'totalGPA'} />
          <Column title={'Буква'} dataIndex={'totalALPH'} />
          <Column title={'Традиционная'} dataIndex={'totalTRD'} />
        </ColumnGroup>
        <Column title={'Учитель'} dataIndex={'teacher'} />
        <Column title={'Дисциплина'} dataIndex={'discipline'} />
        <Column
          title={'Статус'}
          dataIndex={'approved'}
          render={(key, record: any, index: number) => {
            return key !== null ? (
              key === true ? (
                <Tag color="green">Подтверждено</Tag>
              ) : (
                <Button
                  danger
                  onMouseEnter={() => setShowReason(index)}
                  onMouseLeave={() => setShowReason(undefined)}
                  onClick={() => openModal(record)}
                >
                  {showReason === record.key ? 'Показать причину' : 'Опровергнуто'}
                </Button>
              )
            ) : (
              <></>
            );
          }}
        />
        <ColumnGroup title={'Действия'}>
          <Column
            title={'Редактировать'}
            dataIndex={'academicStreamGrades'}
            render={(key, value) => (
              <Button
                type="primary"
                onClick={() => {
                  handleOpenModal(value);
                }}
              >
                Редактировать
              </Button>
            )}
          ></Column>
        </ColumnGroup>
      </Table>
      <Modal
        open={reasonModal}
        title="Причина"
        onCancel={() => setReasonModal(false)}
        onOk={() => setReasonModal(false)}
      >
        <TextArea value={modalData.reason}></TextArea>
      </Modal>
    </>
  );
};

export default DeclinedTable;
