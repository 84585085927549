import { useEffect, useState } from "react";
import { Button, Space, Tag, Upload } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { TeacherService } from "../../../services/TeacherService";
import { TeacherDtoResponse } from "../../../interfaces/Teacher/TeacherDtoResponse";
import { useForm } from "antd/es/form/Form";
import { successNotification } from "../../../helpers/successNotification";
import useDictionary from "../../../hooks/useDictionary";
import TeacherEditModal from "./TeacherEditModal";

interface Props {
    teacherInfo?: TeacherDtoResponse;
    fetchTeacherData?: () => void;
}

export default function TeacherInfoSection({ teacherInfo, fetchTeacherData }: Props) {
    const [form] = useForm();
    const [resumeFile, setResumeFile] = useState();
    const { dictionary } = useDictionary({});

    useEffect(() => {
        form.setFieldsValue({
            ...teacherInfo
        });
    }, [form, teacherInfo])

    const handleDownloadResume = () => {
        TeacherService.getTeacherResume().then(res => {
            successNotification({ message: "Resume updated!" })
        })
    };

    const handleUpload = () => {
        const formData = new FormData();
        // @ts-ignore
        formData.append("resume", resumeFile)
        TeacherService.uploadResume(formData).then(res => {
            successNotification({ message: "Resume updated!" })
        })
    };

    const props = {
        beforeUpload: (file: any) => {
            setResumeFile(file)
            return false;
        },
    };


    return (
        <Space direction={"vertical"} style={{ width: "100%" }}>
            <Space direction={"vertical"} style={{ width: "100%" }}>
                <TeacherEditModal
                    form={form}
                    teacherInfo={teacherInfo}
                    fetchTeacherData={fetchTeacherData}
                />
                <Space style={{ width: "100%", marginTop: 20, justifyContent: "space-between" }} direction={"horizontal"} align={"start"} wrap>
                    <div>
                        <Paragraph strong> First name </Paragraph>
                        <p>{teacherInfo?.nameKz ?? "No name"}</p>

                        <Paragraph strong> Last name </Paragraph>
                        <p>{teacherInfo?.surnameKz ?? "No surname"}</p>

                        <Paragraph strong> Patronymic </Paragraph>
                        <p>{teacherInfo?.patronymicKz ?? "No patronymic"}</p>
                    </div>
                    <div>
                        <Paragraph strong> First name (English) </Paragraph>
                        <p>{teacherInfo?.nameEn ?? "No name"}</p>

                        <Paragraph strong> Last name (English) </Paragraph>
                        <p>{teacherInfo?.surnameEn ?? "No surname"}</p>
                    </div>
                    <div>
                        <Paragraph strong> Nationality </Paragraph>
                        <p>{teacherInfo?.nationality?.titleEn ?? "No nationality"}</p>

                        <Paragraph strong> Citizenship </Paragraph>
                        <p>{teacherInfo?.citizenship?.titleEn ?? "No citizenship"}</p>

                        <Paragraph strong> Gender </Paragraph>
                        <p>{dictionary.genderList.find((g) => g.id === teacherInfo?.sexId)?.value ?? "No gender selected"}</p>
                    </div>
                    <div>
                        <Paragraph strong> Position </Paragraph>
                        <p>{teacherInfo?.position?.name ?? "No position"}</p>

                        <Paragraph strong> Type </Paragraph>
                        <p>{teacherInfo?.type?.name ?? "No Type"}</p>

                        <Paragraph strong> Status </Paragraph>
                        <p>{teacherInfo?.status?.name ?? "No Status"}</p>

                        <Paragraph strong> Department </Paragraph>
                        <p>{teacherInfo?.department?.titleEn ?? "No Department"}</p>
                    </div>
                    <div>
                        <Paragraph strong> Scientific degree </Paragraph>
                        <p>{teacherInfo?.scientificDegree?.nameEn ?? "No degree"}</p>

                        <Paragraph strong> Academic status </Paragraph>
                        <p>{teacherInfo?.academicStatus?.nameEn ?? "No status"}</p>

                        <Paragraph strong> KPI position </Paragraph>
                        <p>{teacherInfo?.kpiPosition ?? "No Type"}</p>

                        <Paragraph strong> Foreign Teacher </Paragraph>
                        <p>{teacherInfo?.ftutor ? "Yes" : "No"}</p>
                    </div>
                    <div>
                        <Paragraph strong> IIN </Paragraph>
                        <p>{teacherInfo?.iin}</p>

                        <Paragraph strong> Date of birthday </Paragraph>
                        <p>{String(teacherInfo?.birthDate)}</p>

                        <Paragraph strong> Phone </Paragraph>
                        <p>{teacherInfo?.phone}</p>
                    </div>
                    <div>
                        <Paragraph strong> Scientific interests </Paragraph>
                        {teacherInfo?.scientificInterests?.map((interest, i) => (
                            interest ? <Tag key={i}>{interest}</Tag> : "No scientific interests"
                        ))}
                        <Paragraph strong style={{ marginTop: "20px" }}> Teaching disciplines </Paragraph>
                        {teacherInfo?.taughtCourses?.map((course, i) => (
                            course ? <Tag key={i}>{course}</Tag> : "No taught courses"
                        ))}

                        <Paragraph strong style={{ marginTop: "20px" }}>Is married</Paragraph>
                        <p>{teacherInfo?.isMarried ? "Yes" : "No"}</p>

                        <Paragraph strong> Maternity leave </Paragraph>
                        <p>{teacherInfo?.maternityLeave ? "Yes" : "No"}</p>

                        <Paragraph strong> Is resident </Paragraph>
                        <p>{teacherInfo?.isResident ? "Yes" : "No"}</p>
                    </div>
                </Space>
                {/* <Space style={{ marginTop: 20, display: teacherInfo?.ftutor && teacherAdditionalInfo ? "block" : "none" }}>
                    <Divider />
                    <Space direction="horizontal">
                        {teacherAdditionalInfo?.map(info => (
                            <div key={info.id}>
                                <Paragraph strong>{typeTitles[info.type.name]}</Paragraph>
                                <p>{info.value}</p>
                            </div>
                        ))}
                    </Space>
                </Space> */}
                <Space direction="horizontal" style={{ marginTop: 20 }}>
                    {teacherInfo?.resumeId
                        ?
                        (<Space direction="horizontal">
                            <Button icon={<DownloadOutlined />} onClick={handleDownloadResume}>Download resume</Button>
                            <Upload {...props} showUploadList={false} multiple={false} onChange={handleUpload}>
                                <Button icon={<UploadOutlined />}>Update resume</Button>
                            </Upload>
                        </Space>)
                        :
                        (<Upload {...props} multiple={false} showUploadList={false} onChange={handleUpload}>
                            <Button icon={<UploadOutlined />}>Upload resume</Button>
                        </Upload>)
                    }
                </Space>
            </Space >
        </Space >
    )
}
