import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useEffect, useState } from 'react';
import { ForeignUniversitiesService } from '../../../services/ForeignUniversitiesService';
import { ForeignUniversitiesDtoResponse } from '../../../interfaces/ForeignUniversitiesDtoResponse';
import { CountriesDtoRequest } from '../../../interfaces/CountriesDtoRequest';
import styles from './style.module.css';
import { CountriesService } from '../../../services/CountriesService';

const useForeignUniversities = () => {
    const [shouldRerender, setShouldRerender] = useState(false);
    const [foreignUniversities, setForeignUniversities] = useState<ForeignUniversitiesDtoResponse[]>([]);
    const [countries, setCountries] = useState<CountriesDtoRequest>();

    const onDeleteForeignUniversities = (universityId: number) => {
        ForeignUniversitiesService.deleteForeignUniversity(universityId)
            .then(() => setShouldRerender(!shouldRerender))
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        ForeignUniversitiesService.getAllForeignUniversities()
            .then((response) => setForeignUniversities(response.data))
            .catch((error) => console.error(error));
    }, [shouldRerender]);

    useEffect(() => {
        CountriesService.getAllCountires()
            .then((response) => setCountries(response.data))
            .catch((error) => console.error(error));
    }, []);


    const getCountryNameById = (countryId: number) => {
        if (!countries || !Array.isArray(countries)) {
            return 'Не найдено';
        }
        const country = countries.find((country) => country.id === countryId);
        return country ? country.nameEn : 'Не найдено';
    };

    const column = [
        {
            title: 'Name',
            dataIndex: 'nameEn',
            key: 'nameEn',
        },
        {
            title: 'Country',
            dataIndex: 'countryId',
            key: 'countryId',
            render: (text: any, record: any) => getCountryNameById(record.countryId),
        },
        {
            title: 'City',
            dataIndex: 'cityNameEn',
            key: 'cityNameEn',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 120,
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button className={styles.deleteButton} onClick={() => onDeleteForeignUniversities(record.key)}>
                        <DeleteTwoTone twoToneColor="#ff0000" />
                    </Button>
                    <Button href={`/foreign-universities/edit/${record.key}`}>
                        <EditTwoTone />
                    </Button>
                </Space>
            ),
        },
    ]
    return {
        foreignUniversities,
        column
    };
};

export default useForeignUniversities;