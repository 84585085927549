import './index.css';
import { Button, Card, Descriptions, Spin, Table, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useState } from 'react';


const CoursePage = (props: any) => {
  // @ts-ignore
  const { disciplineId } = useParams();
  const history = useHistory();
  const [groups, setGroups] = useState();
  const { disciplineName } = props.location;

  // useEffect(() => {
  //   TeacherService.getGroupsByTeacherIdAndDisciplineId(disciplineId).then((res) => {
  //     // @ts-ignore
  //     setGroups(res.data);
  //   });
  // }, []);

  const columns = [
    {
      title: 'Group name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Action',
      key: 'id',
      dataIndex: 'id',
      render: (id: number) => (
        <>
          <Button
            type={'primary'}
            onClick={() => {
              history.push(`/marks/course/${disciplineId}/group/${id}`);
            }}
          >
            Put marks
          </Button>
        </>
      ),
    },
  ];

  if (!groups) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', margin: '200px 0px' }}>
        <Spin />
      </div>
    );
  }

  return (
    <div style={{ padding: 24 }}>
      <Typography.Title>{disciplineName}</Typography.Title>
      <Card>
        <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
          <Descriptions.Item label={<strong>Info</strong>}>{disciplineName}</Descriptions.Item>
          <Descriptions.Item label={<strong>Credits</strong>}>5</Descriptions.Item>
          {/*// @ts-ignore*/}
          <Descriptions.Item label={<strong>Enrolled groups</strong>}>{groups.length}</Descriptions.Item>
        </Descriptions>
      </Card>
      <div style={{ backgroundColor: 'white' }}>
        <Table columns={columns} dataSource={groups} />
      </div>
    </div>
  );
};

export default CoursePage;
