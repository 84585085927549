import { DeleteTwoTone, DownloadOutlined, EditTwoTone } from '@ant-design/icons';
import { Button, notification, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import AuthenticatedContent from '../../../common/AuthenticatedContent';
import { EducationProgramDtoResponse } from '../../../interfaces/EducationalPrograms/EducationProgramDtoResponse';
import { CurriculumService } from '../../../services/CurriculumService';
import { DisciplinesService } from '../../../services/DisciplinesService';
import { EducationalProgramsService } from '../../../services/EducationalProgramsService';
import styles from './style.module.css';

interface FilterRow {
  text: string;
  value: string;
}

const useEducationalProgramsCatalog = () => {
  const [shouldRerender, setShouldRerender] = useState(false);
  const [educationalPrograms, setEducationalPrograms] = useState<EducationProgramDtoResponse[]>([]);
  const [filterRows, setFilterRows] = useState<FilterRow[]>([]);
  const [filterDate, setFilterDate] = useState<FilterRow[]>([]);

  const onDeleteEducationalProgram = (educationalProgramId: number) => {
    EducationalProgramsService.deleteEducationalProgram(educationalProgramId)
      .then(() => setShouldRerender(!shouldRerender));
  };

  useEffect(() => {
    EducationalProgramsService.getEducationalPrograms()
      .then((response) => setEducationalPrograms(response.data));
  }, [shouldRerender]);

  useEffect(() => {
    DisciplinesService.getAcademicDegrees().then(({ data }) => {
      let res = data.map((item) => {
        return {
          text: item.titleRu,
          value: item.titleRu,
        };
      });
      setFilterRows(res);
    });
    for (let i: any = 2019; i <= new Date().getFullYear(); i++) {
      i = i.toString();
      setFilterDate((oldValue) => [
        ...oldValue,
        {
          text: i,
          value: i,
        },
      ]);
    }
  }, []);

  const handleDownloadExcel = (record: any) => {
    CurriculumService.downloadExcelCurriculum(record.educationalProgramId)
      .then(() => notification.success({ message: 'Download successful' }));
  };

  const handleDownloadExcelByYear = (record: any, year: any) => {
    CurriculumService.downloadExcelCurriculumByYear(record.educationalProgramId, year)
      .then(() => notification.success({ message: 'Download successful' }));
  };

  const handleDownloadExcelCurriculumReport = (record: any) => {
    CurriculumService.downloadExcelCurriculumReport(record.educationalProgramId)
      .then(() => notification.success({ message: 'Download successful' }));
  };

  const columns = [
    {
      title: 'Наименование ОП',
      dataIndex: 'educationalProgramName',
      key: 'educationalProgramName',
    },
    {
      title: 'Дата начала действия ОП',
      dataIndex: 'educationStartedDate',
      key: 'educationStartedDate',
      filters: filterDate,
      onFilter: (value: any, record: any) => record.educationStartedDate.startsWith(value),
    },
    {
      title: 'Ученая степень',
      dataIndex: 'academicDegree',
      key: 'academicDegree',
      filters: filterRows,
      onFilter: (value: any, record: any) => record.academicDegree.indexOf(value) === 0,
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      render: (text: any, record: any) => (
        <Space size="middle">
          <AuthenticatedContent role={['OFFICE_REGISTRAR', 'ACADEMIC_DEPARTMENT']} type={'addRole'}>
            <Button className={styles.deleteButton} onClick={() => onDeleteEducationalProgram(record.key)}>
              <DeleteTwoTone twoToneColor="#ff0000" />
            </Button>
          </AuthenticatedContent>
          <AuthenticatedContent role={['OFFICE_REGISTRAR', 'ACADEMIC_DEPARTMENT']} type={'addRole'}>
            <Button href={`/educational-programs/edit/${record.key}`}>
              <EditTwoTone />
            </Button>
          </AuthenticatedContent>
          <Button onClick={() => handleDownloadExcelCurriculumReport(record)} icon={<DownloadOutlined />}>
            Скачать отчет
          </Button>
          <AuthenticatedContent role={['OFFICE_REGISTRAR', 'ACADEMIC_DEPARTMENT']} type={'addRole'}>
            <Button href={`/expected-learning-outcomes/create/education-program-id/${record.key}`}>
              Создать Результат Обучения
            </Button>
          </AuthenticatedContent>
          <AuthenticatedContent role={['OFFICE_REGISTRAR', 'ACADEMIC_DEPARTMENT']} type={'addRole'}>
            <Button href={`/curriculum/education-program-id/${record.key}`}>Редактировать Учебный План</Button>
          </AuthenticatedContent>
          <Button onClick={() => handleDownloadExcel(record)}>
            <DownloadOutlined />
            УП
          </Button>

          <Select
            placeholder={
              <p>
                {' '}
                <DownloadOutlined /> РУП
              </p>
            }
            style={{ width: 100 }}
            onSelect={(e: number) => handleDownloadExcelByYear(record, e)}
          >
            <Select.Option key={1} value={1}>
              1 курс
            </Select.Option>
            <Select.Option key={2} value={2}>
              2 курс
            </Select.Option>
            <Select.Option key={3} value={3}>
              3 курс
            </Select.Option>
          </Select>
        </Space>
      ),
    },
  ];

  return {
    shouldRerender,
    setShouldRerender,
    educationalPrograms,
    setEducationalPrograms,
    onDeleteEducationalProgram,
    columns,
  };
};

export default useEducationalProgramsCatalog;
