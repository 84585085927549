import authAxios from "../../common/authAxios";
import { CertificateType } from "../../interfaces/deansOffice/CertificateType";
import { CertificateDtoRequest } from "../../interfaces/deansOffice/CertificateDtoRequest";
import {
    CertificateIssuanceRequestDtoResponse
} from "../../interfaces/deansOffice/CertificateIssuanceRequestDtoResponse";
import axios from "axios";
import { Constants } from "../../common/constants";

export class CertificateIssuanceService {
    private static endpointPrefix: string = "/astanait-deans-module/api/v1/certificate-issuance";
    static baseURL = Constants.API_BASE_URL;

    static makeRequest(requestDto: CertificateDtoRequest) {
        return authAxios.post(`${this.endpointPrefix}/request`, requestDto);
    }

    static notifyAboutReadyPickup(requestId: number) {
        let formData = new FormData()
        formData.append('issuance-request-id', requestId.toString());
        return authAxios.post(`${this.endpointPrefix}/notify-about-readiness?issuance-request-id`, formData);
    }

    static notifyAboutReadyEmail(formData: FormData) {
        return authAxios.post(`${this.endpointPrefix}/notify-about-readiness`, formData);
    }

    static notifyEmailList(formData: FormData, ids: number[]) {
        return authAxios.post(`${this.endpointPrefix}/notify-about-readiness-list?issuance-request-id=${ids}`, formData);
    }

    static getCertificateTypes() {
        return authAxios.get<CertificateType[]>(`${this.endpointPrefix}/certificate-types`);
    }

    static getAll() {
        return authAxios.get<CertificateIssuanceRequestDtoResponse[]>(`${this.endpointPrefix}/all`);
    }

    static getAllStudent() {
        return authAxios.get<CertificateIssuanceRequestDtoResponse[]>(`${this.endpointPrefix}/all/student/logged`);
    }

    static setIssued(requestId: number) {
        return authAxios.post(`${this.endpointPrefix}/issue-pickup-certificate?issuance-request-id=${requestId}`);
    }

    static getIssuanceById(studentId: number){
        return authAxios.get<CertificateIssuanceRequestDtoResponse>(
            `${this.endpointPrefix}/by-id?id=${studentId}`
        );
    }

    static getIssuanceByIdPublic(studentId: number){
        return axios.get<CertificateIssuanceRequestDtoResponse>(
            `${this.baseURL}${this.endpointPrefix}/public/by-id?id=${studentId}`
        );
    }

    static rejectDeans(formData: FormData) {
        return authAxios.put(`${this.endpointPrefix}/reject-deans`, formData)
    }

    static rejectDeansList(formData: FormData, ids: number[]) {
        return authAxios.put(`${this.endpointPrefix}/reject-deans-list?issuance-request-id=${ids}`, formData)

    }
}