import React from 'react'
import Page from '../../../../../ui/Page'
import { SurveyService } from '../../../../../services/student/SurveyService';
import { SurveyDtoResponse } from '../../../../../interfaces/Survey/SurveyDtoResponse';
import { DatePicker, Form, Input, List, Select, Space, Tabs, Typography } from 'antd';
import { SurveyQuestionsDtoResponse } from '../../../../../interfaces/Survey/SurveyQuestionsDtoResponse';
import { StudentSurveyAnswersDtoResponse } from '../../../../../interfaces/Survey/StudentSurveyAnswersDtoResponse';
import Center from '../../../../../ui/Center';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { TeacherService } from '../../../../../services/TeacherService';
import { TeacherPublicDtoResponseListPageable } from '../../../../../interfaces/Teacher/TeacherPublicDtoResponse';

interface Props {
  id: number;
}

const AdminSurveyDetailsPage = ({ id }: Props) => {
  ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

  const [surveys, setSurveys] = React.useState<SurveyDtoResponse>();
  const [questions, setQuestions] = React.useState<SurveyQuestionsDtoResponse[]>([]);
  const [answers, setAnswers] = React.useState<StudentSurveyAnswersDtoResponse[]>([]);
  const [teachers, setTeachers] = React.useState<TeacherPublicDtoResponseListPageable>();
  const [teacherId, setTeacherId] = React.useState<number>(0);

  const [form] = Form.useForm();

  const hundredLabel = ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'];
  const [params, setParams] = React.useState({});


  function countHundredLabels(answer: string[]) {
    let counts: { [key: string]: number } = {};

    for (let label of hundredLabel) {
      counts[label] = 0;
    }

    for (let response of answer) {
      for (let label of hundredLabel) {
        if (response === label) {
          counts[label]++;
        }
      }
    }

    return counts;
  }
  function countLabels(maxValue: number) {
    let lables: string[] = [];

    for (let i = 0; i <= maxValue; i++) {
      lables.push(String(i))
    }

    return lables
  }

  function countTenLabels(answer: string[], maxValue: number) {
    let counts: { [key: string]: number } = {};

    for (let i = 0; i <= maxValue; i++) {
      counts[i] = 0;
    }

    for (let response of answer) {
      for (let i = 0; i <= maxValue; i++) {
        if (Number(response) === i) {
          counts[i]++;
        }
      }
    }

    return counts;
  }

  React.useEffect(() => {
    Promise.all([
      SurveyService.getSurveyById(id),
      SurveyService.getQuestions(id)
    ])
      .then(([surveys, questions]) => {
        setSurveys(surveys.data)
        setQuestions(questions.data)
      })
  }, [id, teacherId])

  React.useEffect(() => {
    TeacherService.getAllTeachersPublic(params).then((res) => {
      setTeachers(res.data);
    });
  }, [params]);

  const handleTabChange = (key: string) => {
    SurveyService.getAnswersBySurvey(id)
      .then(({ data }) => {
        setAnswers(data)
      })
  }

  const items = [
    {
      label: 'Вопросы',
      key: 'questions',
      children: <List
        itemLayout="vertical"
        dataSource={questions}
        size={"large"}
        renderItem={(items, i) => (
          <List.Item key={i}>
            {items.question}
          </List.Item>
        )} />,
    },
    {
      label: 'Ответы',
      key: 'answers',
      children:
        id === 1 ? (
          <>
            <Select
              showSearch
              size={"large"}
              style={{ width: 300 }}
              optionFilterProp="children"
              onSelect={(e: any) => (setTeacherId(Number(e)))}>
              {teachers && teachers.list.map((teacher, i) => (
                <Select.Option key={i} value={teacher.id}>
                  {teacher?.surnameKz + " " + teacher?.nameKz + " " + teacher?.patronymicKz + " "}
                </Select.Option>
              ))}
            </Select>
            <Center>
              {questions.sort((a, b) => a.id - b.id).map((question, i) => (
                <>
                  <Space direction='vertical' style={{ width: "100%" }}>
                    <Space direction='horizontal' style={{ width: "90%" }}>
                      <Typography.Title level={5}>{question.question}</Typography.Title>
                    </Space>
                    <Space>
                      <Typography.Title type={"secondary"} level={5}>
                        {`${answers.filter(answer => answer.surveyQuestionsDtoResponse.id === question.id && answer.teacherDtoResponse?.id === teacherId).length}` + ` answers`}
                      </Typography.Title>
                    </Space>
                    <Space style={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
                      {answers.find(answer => answer.surveyQuestionsDtoResponse.id === question.id && answer.teacherDtoResponse?.id === teacherId)?.surveyQuestionsDtoResponse.type.id === 1 && (
                        <Bar
                          data={{
                            labels: hundredLabel,
                            datasets: [
                              {
                                data: countHundredLabels(answers.filter(answer => answer.surveyQuestionsDtoResponse.id === question.id && answer.teacherDtoResponse?.id === teacherId)?.map(v => v.answer)),
                                backgroundColor: 'rgba(173,255,47, 0.6)',
                              },
                            ],
                          }}
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                          height={200}
                          width={500}
                        />
                      )}
                      {answers.find(answer => answer.surveyQuestionsDtoResponse.id === question.id && answer.teacherDtoResponse?.id === teacherId)?.surveyQuestionsDtoResponse.type.id === 2 && (
                        <Bar
                          data={{
                            labels: countLabels(question.scaleMaxValue),
                            datasets: [
                              {
                                data: countTenLabels(answers.filter(answer => answer.surveyQuestionsDtoResponse.id === question.id && answer.teacherDtoResponse?.id === teacherId)?.map(v => v.answer), question.scaleMaxValue),
                                backgroundColor: 'rgba(173,255,47, 0.6)',
                              },
                            ],
                          }}
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                          height={200}
                          width={500}
                        />
                      )}
                      {answers.find(answer => answer.surveyQuestionsDtoResponse.id === question.id && answer.teacherDtoResponse?.id === teacherId)?.surveyQuestionsDtoResponse.type.id === 3 && (
                        answers.filter(answer => answer.surveyQuestionsDtoResponse.id === question.id && answer.teacherDtoResponse?.id === teacherId && answer.surveyQuestionsDtoResponse.type.id === 3)?.map((answer, k) => (
                          <Space direction='vertical' key={k} style={{ width: "100%" }}>
                            <Typography.Text>{answer.answer}</Typography.Text>
                          </Space>
                        ))
                      )}
                    </Space>
                  </Space>
                </>
              ))}
            </Center>
          </>
        ) : (<></>)
    },
    {
      label: 'Настройки',
      key: 'settings',
      children: (
        <Space direction='vertical'>
          <Form form={form}>
            <Form.Item name={"name"} label={"Название опроса"}>
              <Input />
            </Form.Item>
            <Form.Item name={"name"} label={"Дата начала - окончания опроса"}>
              <DatePicker.RangePicker />
            </Form.Item>
          </Form>
        </Space>
      )
    },
  ];

  return (
    <Page title={surveys?.name}>
      <Tabs onChange={(e) => handleTabChange(e)} items={items} />
    </Page>
  )
}

export default AdminSurveyDetailsPage