import { DeleteTwoTone, DownloadOutlined, EditTwoTone } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useEffect, useState } from 'react';
import styles from './style.module.css';
import moment from 'moment';
import { AgreementService } from '../../../services/AgreementService';
import { AgreementDtoResponse } from '../../../interfaces/AgreementDtoResponse';


const useAgreementPage = () => {
    const [shouldRerender, setShouldRerender] = useState(false);
    const [agreement, setAgreement] = useState<AgreementDtoResponse[]>([]);


    const onDeleteAgreement = (agreement: number) => {
        AgreementService.deleteAgreement(agreement)
            .then(() => setShouldRerender(!shouldRerender))
            .catch((error) => console.error(error));
    };

    useEffect(() => {

        AgreementService.getAllAgreement()
            .then((response) => {
                setAgreement(response.data);
            })
            .catch((error) => console.error(error));
    }, [shouldRerender]);



    const column = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'University',
            dataIndex: 'university',
            key: 'university',

        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (text: any, record: any) => (record && record.startDate ? moment(record.startDate).format('DD.MM.YYYY') : 'Не найдено'),
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (text: any, record: any) => (record && record.endDate ? moment(record.endDate).format('DD.MM.YYYY') : 'Не найдено'),
        },
        {
            title: 'Agreement Type',
            dataIndex: 'agreementType',
            key: 'agreementType',
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            width: 120,
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button className={styles.deleteButton} onClick={() => onDeleteAgreement(record.key)}>
                        <DeleteTwoTone twoToneColor="#ff0000" />
                    </Button>
                    <Button href={`/agreement/edit/${record.key}`}>
                        <EditTwoTone />
                    </Button>
                </Space>
            ),
        },
    ]
    return {
        agreement,
        column,
    };
};

export default useAgreementPage;