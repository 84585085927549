import { Button, Col, Form, InputNumber, Modal, Popconfirm, Row, Space, Table, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { SsciCategoriesDtoResponse } from '../../../../../../interfaces/Ssci/SsciCategoriesDtoResponse';
import { SsciIndicatorsDtoResponse } from '../../../../../../interfaces/Ssci/SsciIndicatorsDtoResponse';
import { SsciStudentEventPointsDtoResponse } from '../../../../../../interfaces/Ssci/SsciStudentEventPointsDtoResponse';
import { SsciService } from '../../../../../../services/student/SsciService';
import Page from '../../../../../../ui/Page/Page';
import { UserAddOutlined } from '@ant-design/icons';
import BackButton from '../../../../../../ui/BackButton';
import styles from './style.module.css';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { successNotification } from '../../../../../../helpers/successNotification';

interface Props {
  eventId: number;
}

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }: any) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{ required: true, message: `Please Input ${title}!` }]}
        >
          <InputNumber max={20} min={0} />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const SsciStudentEventPointPage = ({ eventId }: Props) => {
  const [event, setEvent] = useState<any[]>([]);
  const [indicators, setIndicators] = useState<SsciIndicatorsDtoResponse[]>([]);
  const [eventPoint, setEventPoint] = useState<SsciStudentEventPointsDtoResponse[]>([]);
  const [categories, setCategories] = useState<SsciCategoriesDtoResponse[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalValue, setModalValue] = useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const history = useHistory();

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = React.useState('');
  const isEditing = (record: any) => record.key === editingKey;

  useEffect(() => {
    SsciService.getStudentEventByEventId(eventId)
      .then(({ data }) => setEvent(data))
    SsciService.getCategories()
      .then(({ data }) => setCategories(data))
  }, [eventId]);

  useEffect(() => {
    if (modalValue) {
      SsciService.getIndicators(modalValue.category)
        .then(({ data }) => {
          setIndicators(data);
        })

      SsciService.getStudentEventPoints(modalValue.id)
        .then(({ data }) => {
          setEventPoint(data);
        })
        .finally(() => setLoading(false));
    }
  }, [modalValue, loading]);

  const getStudentEvent = () => {
    SsciService.getStudentEventByEventId(eventId)
      .then(({ data }) => setEvent(data))
    SsciService.getCategories()
      .then(({ data }) => setCategories(data))
  };

  const handleModal = (record: any, state: boolean) => {
    setIsOpen(state);
    setModalValue(record);
  };

  const edit = (record: any) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };
  const onDeleteStudentEvent = (eventId: number, studentId: number) => {
    SsciService.deleteStudentEvent(eventId, studentId)
      .then(() => {
        successNotification('Студент был успешно удален!')
        getStudentEvent()
      })
  };

  const save = (record: any) => {
    const oldData = [...eventPoint];
    const index = oldData.find((item) => record.id === item.id);
    if (Number(form.getFieldValue('point'))) {
      if (index) {
        index.points = Number(form.getFieldValue('point'));
        SsciService.createOrUpdateStudentEventPoint(
          { ssciStudentEvent: index.ssciStudentEvent.id, ssciIndicator: index.ssciIndicators.id, points: index.points },
          index.id
        )
          .then(() => successNotification('Данные успешно сохранены'))
          .finally(() => {
            setLoading(true);
            cancel();
          });
      } else {
        SsciService.createOrUpdateStudentEventPoint({
          ssciStudentEvent: record.ssciStudentEventId,
          ssciIndicator: record.ssciIndicator,
          points: Number(form.getFieldValue('point')),
        })
          .then(() => successNotification('Данные успешно сохранены'))
          .finally(() => {
            setLoading(true);
            cancel();
          });
      }
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const columns = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Группа',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'Категория',
      dataIndex: 'roleId',
      key: 'roleId',
    },
    {
      title: 'Часы',
      dataIndex: 'hours',
      key: 'hours',
    },
    {
      title: 'Баллы',
      dataIndex: 'points',
      key: 'points',
    },
    {
      dataIndex: 'action',
      key: 'action',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button className={styles.deleteButton} onClick={() => onDeleteStudentEvent(record.eventId, record.studentId)}>
            <DeleteTwoTone twoToneColor="#ff0000" />
          </Button>
          <Button onClick={() => history.push(`/edit-ssci-students/${record.id}`)}>
            <EditTwoTone />
          </Button>
          <Button onClick={() => handleModal(record, true)}>Редактировать баллы</Button>
        </Space>
      ),
    },
  ];

  const modalColumns = [
    {
      title: 'Индикатор',
      dataIndex: 'ssciIndicatorName',
      key: 'ssciIndicatorName',
      width: '70%',
    },
    {
      title: 'Балл',
      dataIndex: 'point',
      key: 'point',
      editable: true,
      width: '20%',
    },
    {
      title: '',
      dataIndex: 'operation',
      width: '10%',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                save(record);
              }}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Typography>Cancel</Typography>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = modalColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Page title="SSCI Student Event points">
      <Row>
        <Col flex={0}>
          <BackButton />
        </Col>
        <Col flex="auto"></Col>
        <Col flex={0}>
          <Button type="primary" href={`/add-ssci-students/${eventId}`} icon={<UserAddOutlined />}>
            Добавить студентов
          </Button>
        </Col>
      </Row>

      {event.length > 0 ? (
        <Table
          bordered
          loading={loading}
          style={{ marginTop: 20 }}
          columns={columns}
          dataSource={event.map((event, i) => ({
            key: i + 1,
            id: event.id,
            fullName: event.studentRoleDtoRequest.student.fullName,
            group: event.studentRoleDtoRequest.student.group,
            roleId: categories.find((c) => c.id === event.studentRoleDtoRequest.roleId)?.nameRu,
            hours: event.hours,
            points: event.points,
            category: event.studentRoleDtoRequest.roleId,
            eventId: event.eventId,
            studentId: event.studentRoleDtoRequest.student.id,
          }))}
        />
      ) : (
        <Typography.Title level={3} type="secondary" style={{ marginTop: 20 }}>
          Не было выбрано студентов
        </Typography.Title>
      )}

      {modalValue && (
        <Modal
          width={700}
          open={isOpen}
          title={`Редактирование баллов - ${modalValue.fullName}`}
          onCancel={() => setIsOpen(false)}
          onOk={() => setIsOpen(false)}
        >
          <Form form={form} component={false}>
            <Table
              columns={mergedColumns}
              loading={loading}
              pagination={false}
              dataSource={indicators.map((indicator, i) => ({
                key: i + 1,
                ssciStudentEventId: modalValue.id,
                ssciIndicatorName: indicator.nameRu,
                ssciIndicator: indicator.id,
                point: eventPoint.find((point) => point.ssciIndicators.id === indicator.id)?.points,
                id: eventPoint.find((point) => point.ssciIndicators.id === indicator.id)?.id,
              }))}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
            />
          </Form>
        </Modal>
      )}
    </Page>
  );
};

export default SsciStudentEventPointPage;
