import { useEffect, useState } from 'react'
import Page from '../../../../../../ui/Page';
import BackButton from '../../../../../../ui/BackButton';
import { Button, Form, Input, InputNumber, Select, Space, Upload, UploadProps } from 'antd';
import { SsciService } from '../../../../../../services/student/SsciService';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { RcFile } from 'antd/lib/upload';
import { FileService } from '../../../../../../services/file/FileService';
import { SsciCategoriesDtoResponse } from '../../../../../../interfaces/Ssci/SsciCategoriesDtoResponse';
import { errorNotification } from '../../../../../../helpers/errorNotification';
import { successNotification } from '../../../../../../helpers/successNotification';

interface Props {
    id: number;
}

const SsciEditStudentPage = ({ id }: Props) => {
    const [form] = Form.useForm();
    const [categories, setCategories] = useState<SsciCategoriesDtoResponse[]>([]);
    const [fileId, setFileId] = useState(0);

    useEffect(() => {
        if (id) {
            SsciService.getStudentEventById(id)
                .then(({ data }) => {
                    form.setFieldsValue(data);
                    setFileId(data.fileId)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        SsciService.getCategories()
            .then(({ data }) => {
                setCategories(data)
            })
    }, [])

    const uploadProps: UploadProps = {
        maxCount: 1,
        beforeUpload: (file: RcFile) => {
            const isPDF = file.type === 'application/pdf';
            if (!isPDF) {
                errorNotification(`${file.name} is not pdf file!`, 'Please, upload only pdf file!');
            }
            return isPDF || Upload.LIST_IGNORE;
        },
        onChange(e) {
            e.file.status = 'done';
        },
    };

    const handleDownloadFile = (fileId: number) => {
        FileService.getFileById(fileId, 'Event file')
            .then(() => successNotification('Successfully downloaded!'))
    };

    const handleUpdate = (dto: any) => {
        let formData = new FormData();
        dto.ssciFile && formData.append('ssciFile', dto.ssciFile.originFileObj);
        dto.ssciFile
            ? SsciService.uploadStudentEventFile(formData, dto.fileId)
                .then(({ data }) => {
                    dto.fileId = data;
                    SsciService.createAndUpdateStudentEvent(dto, dto.eventId, id).then(() => {
                        successNotification('Успешно создано!');
                    });
                })
            : SsciService.createAndUpdateStudentEvent(dto, dto.eventId, id)
                .then(() => {
                    successNotification('Успешно создано!');
                })
    };

    return (
        <Page title="SSCI Event Detail">
            <BackButton />
            <Space style={{ alignItems: 'center', width: '100%' }} direction={'vertical'} size="small">
                <Form
                    form={form}
                    initialValues={{ remember: true }}
                    size="large"
                    style={{ width: '100%' }}
                    onFinish={handleUpdate}
                >
                    <Form.Item
                        name={['studentRoleDtoRequest', 'roleId']}
                        noStyle
                    >
                    </Form.Item>
                    <Form.Item
                        name={'eventId'}
                    >
                    </Form.Item>
                    <Form.Item
                        name={['studentRoleDtoRequest', 'student', 'fullName']}
                    >
                        <Input placeholder="First Name" disabled />
                    </Form.Item>
                    <Form.Item name={['studentRoleDtoRequest', 'roleId']}>
                        <Select>
                            {categories.map((category, i) => (
                                <Select.Option key={i} value={category.id}>
                                    {category.nameRu}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                        <Form.Item
                            label="Количество часов"
                            name={'hours'}
                            rules={[{ required: true, message: 'Введите количество часов' }]}
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item label="Ссылка" name={'link'}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Файл"
                            name={'ssciFile'}
                            valuePropName={'ssciFile'}
                            getValueFromEvent={(e) => e.file}
                        >
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined />}>Загрузить файл</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item label="Информация" name={'info'}>
                            <TextArea />
                        </Form.Item>
                    </Space>
                    {fileId > 0 && (
                        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'flex-end' }}>
                            <Button
                                icon={<DownloadOutlined />}
                                onClick={() => handleDownloadFile(form.getFieldValue(['fileId']))}
                            >
                                Скачать файл
                            </Button>
                        </Space>
                    )}
                    <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Сохранить
                                </Button>
                            </Space>
                        </Form.Item>
                    </Space>
                </Form>
            </Space >
        </Page >
    )
}

export default SsciEditStudentPage