import { StudentAttendanceDtoResponse } from '../interfaces/Student/StudentAttendanceDtoResponse';
import { StudentAttendanceDateDtoResponse } from '../interfaces/Student/StudentAttendanceDateDtoResponse'
import authAxios from '../common/authAxios';

export class AttendanceService {
    private static baseUrlForAttendance = '/astanait-schedule-module/api/v1';

    static AttendanceByEmail(email: string, trimester: number) {
        return authAxios.get<StudentAttendanceDtoResponse>(
            `${this.baseUrlForAttendance}/attendance/percent/?email=${email}&trimester=${trimester}`
        );
    }

    static AttendanceDateByEmail(email: string, trimester: number) {
        return authAxios.get<StudentAttendanceDateDtoResponse>(
            `${this.baseUrlForAttendance}/attendance/date/?email=${email}&trimester=${trimester}`
        )
    }
}
