import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { StudentDtoResponse } from '../../../interfaces/Student/StudentDtoResponse';
import useStudentsTable from './useStudentsTable';

interface Props {
  loading: boolean;
  selectable?: boolean;
  selectableCheckbox?: boolean;
  selectedStudentId?: any;
  setSelectedStudentId?: any;
  selectedStudent?: any;
  setSelectedStudent?: any;
  short?: boolean;
  data: StudentDtoResponse[];
}


const StudentsTable = ({
  loading,
  data,
  selectable = false,
  selectableCheckbox = false,
  selectedStudentId,
  setSelectedStudentId,
  selectedStudent,
  setSelectedStudent,
  short,
}: Props) => {
  const navigate = useHistory();
  const { shortColumns, columns, filteredData, onRowSelect, selectRow, rowSelection } = useStudentsTable(data, navigate, {
    selectedStudentId,
    setSelectedStudentId,
    selectedStudent,
    setSelectedStudent,
  });

  return selectable ? (
    <Table
      columns={short ? shortColumns : columns}
      dataSource={filteredData}
      style={{ paddingTop: 20 }}
      loading={loading}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => selectRow(record),
          onMouseEnter: (e) => (e.currentTarget.style.cursor = 'pointer'),
        };
      }}
      rowKey={(record) => record.id}
      rowSelection={rowSelection}
    />
  ) : selectableCheckbox ? (
    <Table
      columns={short ? shortColumns : columns}
      dataSource={filteredData}
      style={{ paddingTop: 20 }}
      loading={loading}
      rowKey={(record) => record.id}
      rowSelection={{
        type: "radio",
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
          setSelectedStudentId(selectedRowKeys)
          setSelectedStudent(selectedRows)
        }
      }}
    />
  ) : (
    <Table
      columns={short ? shortColumns : columns}
      dataSource={filteredData}
      style={{ paddingTop: 20 }}
      loading={loading}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => onRowSelect(record),
          onMouseEnter: (e) => (e.currentTarget.style.cursor = 'pointer'),
        };
      }}
    />
  );
};

export default StudentsTable;
