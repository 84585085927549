import { Select } from 'antd';
import { TeachersListDtoResponse } from '../../../../interfaces/TeachersListDtoResponse';

type Options = TeachersListDtoResponse[];

const { Option } = Select;

interface Props {
  placeholder: string;
  selectedOption: any;
  options?: Options;
  onSelect: (value: any) => void;
  onClear?: () => void;
}

const SearchFilter = ({ selectedOption, onSelect, placeholder, options, onClear }: Props) => {
  return (
    <Select
      showSearch
      value={selectedOption}
      placeholder={placeholder}
      onSelect={onSelect}
      style={{ width: 400 }}
      onClear={onClear}
      allowClear={!!onClear}
    >
      {options?.map((option) => (
        <Option key={option.id} value={option.id}>
          {option.name}
        </Option>
      ))}
    </Select>
  );
};

export default SearchFilter;
