import React from 'react';
import { Table, Button, Modal, Alert, Descriptions, Input, Tag, Space, Switch } from 'antd';
import { EditOutlined, DeleteOutlined, InfoCircleOutlined, SendOutlined } from '@ant-design/icons';
import { TeacherGraduationThesisDtoRequest } from './TeacherGraduationThesisDtoRequest';
import CustomModal from '../../../ui/Modal';
import moment from 'moment';
import { TeacherGraduationThesisService } from './TeacherGraduationThesisService';
import { successNotification } from '../../../helpers/successNotification';

interface TeacherGraduationThesisTableProps {
    suggestions: TeacherGraduationThesisDtoRequest[];
    loading: boolean;
    onEdit: (record: TeacherGraduationThesisDtoRequest) => void;
    onDelete: (id: number) => void;
    fetchSuggestions: () => void;
    isButtonDisabled: boolean;
}

const TeacherGraduationThesisTable: React.FC<TeacherGraduationThesisTableProps> = ({ suggestions, loading, onEdit, onDelete, fetchSuggestions, isButtonDisabled }) => {
    const [checked, setChecked] = React.useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<number[]>([]);

    const expandedRowRender = (record: TeacherGraduationThesisDtoRequest) => {
        if (record.similarityList.length === 0) return null;

        const similarityColumns = [
            {
                title: 'Reference Title',
                dataIndex: 'refThesisTitle',
                key: 'refThesisTitle',
            },
            {
                title: 'Similarity (%)',
                dataIndex: 'titleSimilarityPercent',
                key: 'titleSimilarityPercent',
            },
            {
                title: 'Note',
                dataIndex: 'isDiplomaWork',
                key: 'isDiplomaWork',
                render: (isDiplomaWork: boolean) => (
                    isDiplomaWork
                        ? 'This topic has similarities with another diploma work'
                        : 'This topic has similarities with another suggestion'
                ),
            },
        ];

        return (
            <Table
                columns={similarityColumns}
                dataSource={record.similarityList}
                pagination={false}
                rowKey="id"
            />
        );
    };

    const handleStatus = (record: TeacherGraduationThesisDtoRequest) => {
        switch (record.status.nameEn) {
            case "created ":
                return <Tag color="blue">Created</Tag>;
            case "for approval":
                return <Tag color="orange">Waiting Approval</Tag>;
            case "approved":
                return <Tag color="green">Approved</Tag>;
            case "rejected":
                return <CustomModal
                    title="Rejected"
                    onOk={() => { }}
                    btnIcon={<InfoCircleOutlined />}
                    btnTitle='Rejected comment'
                >
                    <Descriptions column={1}>
                        <Descriptions.Item label="Comment"><Input.TextArea value={record.comment} /></Descriptions.Item>
                        <Descriptions.Item label="Rejected At">{moment(record.approvedAt).format('LL hh:mm')}</Descriptions.Item>
                    </Descriptions>
                </CustomModal>;
            default:
                return <Tag color="blue">Created</Tag>;
        }
    };

    const columns = [
        { title: 'Defense Year', dataIndex: 'defenseYear', key: 'defenseYear' },
        { title: 'Title (EN)', dataIndex: 'titleEn', key: 'titleEn' },
        { title: 'Title (KZ)', dataIndex: 'titleKz', key: 'titleKz' },
        { title: 'Title (RU)', dataIndex: 'titleRu', key: 'titleRu' },
        { title: 'Degree', dataIndex: ['type', 'academicDegree', 'titleEn'], key: 'degree' },
        {
            title: 'Free',
            dataIndex: 'isFree',
            render: (text: boolean) => (text ? 'Yes' : 'No'),
        },
        { title: 'Type (EN)', dataIndex: ['type', 'nameEn'], key: 'type' },
        {
            title: 'Individual/Group',
            dataIndex: 'individual',
            render: (text: boolean) => (text ? 'Individual' : 'Group'),
        },
        { title: 'Required Students Number', dataIndex: 'requiredStudentsNumber', key: 'requiredStudentsNumber' },
        { title: 'Status', dataIndex: 'status', key: 'status', render: (text: string, record: TeacherGraduationThesisDtoRequest) => handleStatus(record) },
        {
            title: 'Note',
            dataIndex: 'similarityList',
            key: 'note',
            render: (similarityList: any) => similarityList.length > 0 ? (
                <Alert message="There are suspicions that this topic is similar to another topic" type="error" />
            ) : null,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record: TeacherGraduationThesisDtoRequest) => {
                const isEditable = record.status.id === 1;
                const isDisabled = isButtonDisabled || !isEditable;
                return (
                    <Space >
                        <Button icon={<EditOutlined />} onClick={() => onEdit(record)} disabled={isDisabled} />
                        <Button
                            icon={<DeleteOutlined />}
                            danger
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Are you sure you want to delete this suggestion?',
                                    onOk: () => onDelete(record.id),
                                });
                            }}
                            disabled={isDisabled}
                        />
                        {(record.status.id === 1 || record.status.id === 4) && (
                            <Button
                                icon={<SendOutlined />}
                                type='primary'
                                onClick={() => {
                                    TeacherGraduationThesisService.sendForApproval(record.id)
                                        .then(() => {
                                            successNotification('Suggestion sent for approval successfully');
                                            fetchSuggestions();
                                        });
                                }}
                            >
                                Send for approval
                            </Button>
                        )}
                    </Space>
                );
            },
        },
    ];

    const handleMultipleApprove = async () => {
        await selectedRowKeys.forEach((id) => {
            TeacherGraduationThesisService.sendForApproval(id)
        });
        await setSelectedRowKeys([]);
        await successNotification('Suggestions sent for approval successfully');
        await fetchSuggestions();
    }

    return (
        <Space direction="vertical" style={{ width: '100%', marginTop: "20px" }}>
            <Space style={{ marginBottom: 20 }}>
                <Switch onChange={() => setChecked(!checked)} checked={checked} checkedChildren="Multiple approve" unCheckedChildren="Single approve" />
                {checked && <Button
                    type="primary"
                    icon={<SendOutlined />}
                    onClick={() => {
                        handleMultipleApprove();
                    }}
                    disabled={selectedRowKeys.length === 0}>
                    Approve all
                </Button>}
            </Space>

            {checked
                ?
                <Table
                    columns={columns}
                    dataSource={suggestions}
                    loading={loading}
                    style={{ marginTop: 20 }}
                    rowKey="id"
                    scroll={{ x: '500px' }}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: selectedRowKeys,
                        onChange: (selectedRowKeys, selectedRows) => {
                            setSelectedRowKeys(selectedRows.map((row) => row.id));
                        },
                        getCheckboxProps: (record) => ({
                            disabled: (record.status.id !== 1 && record.status.id !== 4),
                        }),
                    }}
                    expandable={{
                        expandedRowRender,
                        rowExpandable: (record) => record.similarityList.length > 0,
                    }}
                />
                :
                <Table
                    columns={columns}
                    dataSource={suggestions}
                    loading={loading}
                    style={{ marginTop: 20 }}
                    rowKey="id"
                    scroll={{ x: '500px' }}
                    expandable={{
                        expandedRowRender,
                        rowExpandable: (record) => record.similarityList.length > 0,
                    }}
                />
            }
        </Space>
    );
};

export default TeacherGraduationThesisTable;
