import { useState } from 'react'
import { errorNotification } from '../../../helpers/errorNotification';
import { TeacherService } from '../../../services/TeacherService';

export const useTeacherDetails = () => {
    const [searchValue, setSearchValue] = useState('');
    const [teachersList, setTeachersList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSearchTeachers = (fullname: string) => {
        if (!fullname) {
            errorNotification('Введите имя в поле поиска!');
            return;
        }
        setLoading(true);
        TeacherService.getAllTeachersPublic({
            fullName: fullname,
        })
            .then(({ data }) => {
                setTeachersList(data);
                if (data.list.length === 0) errorNotification(`Не найдено ни одного преподавателя`);
            })
            .finally(() => setLoading(false));
    };

    return {
        searchValue,
        setSearchValue,
        teachersList,
        loading,
        handleSearchTeachers
    }
}

