import { FileExcelFilled } from '@ant-design/icons';
import { Button, Form, Select, Space, Table } from 'antd';
import Page from '../../../ui/Page/Page';
import RowSpan from '../../../ui/RowSpan';
import useAcademicRating from './useAcademicRating';

const AcademicRatingPage = () => {
  const { data, handlers } = useAcademicRating();

  const LeftSpan = () => {
    return (
      <Form initialValues={{ remember: true }} form={data.form} onFinish={handlers.handleRating}>
        <Form.Item name={'degree'}>
          <Select
            placeholder={'Выберите степень образования'}
            style={{ width: '100%' }}
            allowClear
            onSelect={handlers.handleDegreeSelect}
          >
            {data.degreeList.map((degree) => (
              <Select.Option key={degree.id} value={degree.id}>
                {degree.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'gopId'}>
          <Select placeholder={'Выберите группы образовательной программы'} style={{ width: '100%' }} allowClear>
            {data.gopList.map((gop) => (
              <Select.Option key={gop.id} value={gop.id}>
                {gop.titleRu}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'course'}>
          <Select
            placeholder={'Выберите курс'}
            style={{ width: '100%' }}
            allowClear
            onSelect={handlers.handleCourseSelect}
          >
            {data.courseList.map((course) => (
              <Select.Option key={course.id} value={course.year}>
                {course.year}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name={'educationProgram'}>
          <Select
            placeholder={'Выберите образовательную программу'}
            allowClear
            style={{ width: '100%' }}
            onSelect={handlers.handleEPSelect}
          >
            {data.educationalPrograms.map((educationalProgram) => (
              <Select.Option key={educationalProgram.id} value={educationalProgram.id}>
                {educationalProgram.titleEn + ' / ' + educationalProgram.titleKz + ' / ' + educationalProgram.titleRu}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name={'year'}>
          <Select placeholder={'Выберите год'} allowClear style={{ width: '100%' }}>
            {data.years.map((year) => (
              <Select.Option key={year.id} value={year.year}>
                {year.year}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name={'term'}>
          <Select placeholder={'Выберите триместер'} allowClear style={{ width: '100%' }}>
            {data.trimesters.map((trimester) => (
              <Select.Option key={trimester.id} value={trimester.trimester}>
                {trimester.trimester}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
                        
        <Form.Item name={'groupId'}>
          <Select placeholder={'Выберите группу'} allowClear style={{ width: '100%' }}>
            {data.groups.map((group) => (
              <Select.Option key={group.id} value={group.id}>
                {group.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name={'financingId'}>
          <Select placeholder={'Выберите тип финансирования'} allowClear style={{ width: '100%' }}>
            {data.financingList.map((financing) => (
              <Select.Option key={financing.id} value={financing.id}>
                {financing.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Space direction='horizontal' style={{ alignItems: "flex-start" }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Поиск
            </Button>
          </Form.Item>
          <Form.Item>
            <Button icon={<FileExcelFilled />} type="primary" onClick={(e) => { handlers.handleExportExcel(data.form.getFieldsValue()) }}>Export excel</Button>
          </Form.Item>
        </Space>

      </Form>
    );
  };

  return (
    <Page title="Academic Rating">
      <RowSpan left={<LeftSpan />} />
      <Table
        dataSource={data.tableData.map((td, i) => ({
          key: i + 1,
          //@ts-ignore
          fullName: td.fullName,
          //@ts-ignore
          course: td.course,
          //@ts-ignore
          op: td.educationProgram,
          //@ts-ignore
          group: td.group,
          //@ts-ignore
          gpa: td.gpa,
        }))}
        bordered
        columns={data.columns}
        loading={data.loading}
      />
    </Page>
  );
};

export default AcademicRatingPage;
