import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useEffect, useState } from 'react';
import { AcademicDegreeDtoResponse } from '../../../interfaces/AcademicDegreeDtoResponse';
import { DepartmentDtoResponse } from '../../../interfaces/DepartmentDtoResponse';
import { DisciplineDtoResponse } from '../../../interfaces/DisciplineDtoResponse';
import { PersistentStateConstants } from '../../../PersistentStateConstants';
import { DisciplinesService } from '../../../services/DisciplinesService';
import { PersistentStateService } from '../../../services/PersistentStateService';
import styles from './style.module.css';

const useDisciplinesCatalog = () => {
  const [shouldRerender, setShouldRerender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState<DepartmentDtoResponse[]>([]);
  const [academicDegrees, setAcademicDegrees] = useState<AcademicDegreeDtoResponse[]>([]);
  const [disciplines, setDisciplines] = useState<DisciplineDtoResponse[]>([]);
  const [totalDisciplines, setTotalDisciplines] = useState(0);
  const [currentPage, setCurrentPage] = useState<number | undefined>(0);
  const [pageSize, setPageSize] = useState<number | undefined>(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState<number | undefined>(undefined);
  const [selectedAcademicDegree, setSelectedAcademicDegree] = useState<number | undefined>(undefined);

  useEffect(() => {
    Promise.all([DisciplinesService.getDepartments(), DisciplinesService.getAcademicDegrees()])
      .then(([departmentsResponse, academicDegreesResponse]) => {
        setDepartments(departmentsResponse.data);
        setAcademicDegrees(academicDegreesResponse.data);
      });
  }, []);

  useEffect(() => {
    setLoading(true);

    const delayedSearch = setTimeout(() => {
      DisciplinesService.getDisciplines({
        page: currentPage ? currentPage - 1 : 0,
        pageSize: pageSize,
        departmentId: selectedDepartment,
        academicDegreeId: selectedAcademicDegree,
        keyword: searchQuery,
      })
        .then((response) => {
          setDisciplines(response.data.list);
          setTotalDisciplines(response.data.total_number);
          setLoading(false);
        });
    }, 1000);

    return () => clearInterval(delayedSearch);
  }, [currentPage, pageSize, searchQuery, selectedDepartment, selectedAcademicDegree, shouldRerender]);

  useEffect(() => {
    if (PersistentStateService.hasInputFieldsInfo(PersistentStateConstants.DISCIPLINES_CATALOG_INPUT_FIELDS)) {
      const inputFields: any = JSON.parse(
        // @ts-ignore
        PersistentStateService.getInputFieldsInfo(PersistentStateConstants.DISCIPLINES_CATALOG_INPUT_FIELDS)
      );
      setSelectedAcademicDegree(inputFields.selectedAcademicDegree);
      setSearchQuery(inputFields.searchQuery);
    }
  }, []);

  useEffect(() => {
    PersistentStateService.setInputFieldsInfo(PersistentStateConstants.DISCIPLINES_CATALOG_INPUT_FIELDS, {
      selectedAcademicDegree,
      searchQuery,
    });
  }, [selectedAcademicDegree, searchQuery]);

  const onDeleteDiscipline = (disciplineId: number) => {
    DisciplinesService.deleteDiscipline(disciplineId)
      .then(() => setShouldRerender(!shouldRerender));
  };

  const columns = [
    {
      title: 'Название дисциплины',
      dataIndex: 'disciplineName',
      key: 'disciplineName',
    },
    {
      title: 'Код дисциплины',
      dataIndex: 'disciplineCode',
      key: 'disciplineCode',
    },
    {
      title: 'Кредиты',
      dataIndex: 'credits',
      key: 'credits'
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button className={styles.deleteButton} onClick={() => onDeleteDiscipline(record.key)}>
            <DeleteTwoTone twoToneColor="#ff0000" />
          </Button>
          <Button href={`/disciplines/edit/${record.key}`}>
            <EditTwoTone />
          </Button>
          <Button href={`/allocated-hours/discipline-id/${record.key}`}>Редактировать Расчасовку</Button>
        </Space>
      ),
    },
  ];

  return {
    shouldRerender,
    setShouldRerender,
    loading,
    setLoading,
    departments,
    setDepartments,
    academicDegrees,
    setAcademicDegrees,
    disciplines,
    setDisciplines,
    totalDisciplines,
    setTotalDisciplines,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    searchQuery,
    setSearchQuery,
    selectedDepartment,
    setSelectedDepartment,
    selectedAcademicDegree,
    setSelectedAcademicDegree,
    onDeleteDiscipline,
    columns,
  };
};

export default useDisciplinesCatalog;
