import React from 'react'
import { Alert, Button, Space, Statistic, Tag } from 'antd'
import useFetchData from '../../../../hooks/useFetchData';
import { GraduationThesisDto, StudentGraduationThesisChooseDto, StudentGraduationThesisChooseSettingDto } from '../../../../interfaces/Student/StudentGraduationThesisChooseDto';
import { DiplomaService } from '../../../../services/DiplomaService';
import { ColumnsType } from 'antd/lib/table';
import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { successNotification } from '../../../../helpers/successNotification';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { isDeadlinePassed } from '../../../../helpers/isDeadlinePassed';


const useStudentDiploma = () => {
    const {
        data: setting,
    } = useFetchData<StudentGraduationThesisChooseSettingDto>(DiplomaService.getGraduationThesisSetting, true);

    const callBackSelectedThesis = React.useCallback(() => {
        if (setting) {
            return DiplomaService.getGraduationThesisByStudent();
        }
        return Promise.resolve({} as AxiosResponse<StudentGraduationThesisChooseDto>);
    }, [setting]);

    const callBackAvailableThesis = React.useCallback(() => {
        if (setting) {
            return DiplomaService.getAvailableThesisForStudent();
        }
        return Promise.resolve({} as AxiosResponse<GraduationThesisDto[]>);
    }, [setting]);

    const {
        data: selectedThesis,
        fetchData: fetchSelectedThesis,
    } = useFetchData<StudentGraduationThesisChooseDto>(callBackSelectedThesis, true);

    const {
        data: availableThesis,
        isLoading: isAvailableThesisLoading
    } = useFetchData<GraduationThesisDto[]>(callBackAvailableThesis, true);

    const addRecord = (thesisId: number, id?: number) => {
        id ?
            DiplomaService.updateGraduationThesis(id, thesisId)
                .then(() => {
                    fetchSelectedThesis();
                    successNotification("Diploma has been successfully selected");
                })
            :
            DiplomaService.selectGraduationThesis(thesisId)
                .then(() => {
                    fetchSelectedThesis();
                    successNotification("Diploma has been successfully selected");
                });
    };

    const columns: ColumnsType<GraduationThesisDto> = [
        {
            title: "Diploma",
            dataIndex: "diploma",
            key: "diploma",
            render: (text, record) => record.titleRu + " / " + record.titleKz + " / " + record.titleEn
        },
        {
            title: "Supervisor",
            dataIndex: "supervisor",
            key: "supervisor",
            render: (text, record) => record.supervisor.surnameEn + " " + record.supervisor.nameEn
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (text, record) => record.type.nameEn
        },
        {
            title: "Required Students Number",
            dataIndex: "requiredStudentsNumber",
            key: "requiredStudentsNumber"
        },
        {
            title: "Defence Year",
            dataIndex: "defenseYear",
            key: "defenseYear"
        },
        {
            title: "",
            key: "action",
            render: (text, record) => record.id === selectedThesis?.thesis.id ? (
                <Space size="middle">
                    <Button
                        type="primary"
                        disabled={true}
                        onClick={() => addRecord(record.id, selectedThesis?.id)}
                        icon={<CheckCircleOutlined />}
                    >
                        Selected
                    </Button>
                </Space>
            ) : (
                <Space size="middle">
                    <Button
                        type="primary"
                        onClick={() => addRecord(record.id, selectedThesis?.id)}
                        icon={<PlusOutlined />}
                        disabled={
                            (setting && isDeadlinePassed(setting?.deadlineEndAt)) ||
                            (setting && !isDeadlinePassed(setting?.deadlineStartAt))
                        }
                    >
                        Choose
                    </Button>
                </Space>
            )
        }
    ];

    const handleStatus = (status?: string) => {
        switch (status) {
            case "created ":
                return <Tag color="blue">Created</Tag>;
            case "for approval":
                return <Tag color="orange">For Approval</Tag>;
            case "approved":
                return <Tag color="green">Approved</Tag>;
            case "rejected":
                return <Tag color="red">Rejected</Tag>;
            default:
                return <Tag color="blue">Created</Tag>;
        }
    }



    const renderAlert = (setting: StudentGraduationThesisChooseSettingDto) => {
        if (setting === undefined || setting === null) {
            return <Alert message="Please, wait for the settings to be loaded" type="info" showIcon />;
        }

        else if (!isDeadlinePassed(setting.deadlineStartAt)) {
            return <Alert message="The selection of the diploma is not yet available" type="warning" showIcon />;
        }

        else if (isDeadlinePassed(setting.deadlineEndAt)) {
            return <Alert message="The selection of the diploma is over" type="warning" showIcon />;
        }

        else {
            return (
                <Space direction="vertical">
                    <Alert
                        message={`The selection of the diploma is available from ${moment(setting.deadlineStartAt).format('LL hh:mm')} to ${moment(setting.deadlineEndAt).format('LL hh:mm')}`}
                        type='info'
                        showIcon
                    />
                    <Statistic.Countdown
                        title="Time left to select the diploma"
                        value={Date.parse(setting.deadlineEndAt.toString())}
                        format='D [days] H [hours] m [minutes]'
                    />
                </Space>
            );
        }
    }


    return {
        setting,
        selectedThesis,
        availableThesis,
        isAvailableThesisLoading,
        columns,
        fetchSelectedThesis,
        handleStatus,
        renderAlert,
    }
}

export default useStudentDiploma