import authAxios from "../common/authAxios";
import { CommentDto, ParticipantDto } from "../interfaces/Thesis/ThesisDtos";

export class ThesisService {
    static getDocumentComments(documentUUID: string) {
        return authAxios.get<CommentDto[]>(`/astanait-thesis-module/api/v1/document/comments/${documentUUID}`);
    }

    static getDocumentParticipants(documentUUID: string) {
        return authAxios.get<ParticipantDto[]>(`/astanait-thesis-module/api/v1/document/participants/${documentUUID}`);
    }
}