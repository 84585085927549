import { useState } from 'react';
import { Button, Modal, notification, Typography, Upload } from 'antd';
import { IllnessCertificateService } from '../../../../services/deansOffice/IllnessCertificateService';
import { UploadOutlined } from '@ant-design/icons';
import { IllPersonService } from '../../../../services/deansOffice/IllPersonService';
import AuthenticatedContent from '../../../../common/AuthenticatedContent';

interface Props {
  student?: any;
  cancel: () => void;
  visibleDiseaseStatus: any;
  uploaded: boolean;
  setUploaded: any;
  setLatestSubmissionRejected: any;
}

const UploadDisease = ({
  student,
  cancel,
  visibleDiseaseStatus,
  uploaded,
  setUploaded,
  setLatestSubmissionRejected,
}: Props) => {
  let diseaseUploadButton;
  const [imageFile, setImageFile] = useState<any>();
  const [spinner, setSpinner] = useState(false);

  const handleSubmitFile = () => {
    if (imageFile !== null) {
      setSpinner(true);
      let formData = new FormData();
      formData.append('certificate', imageFile);
      IllnessCertificateService.uploadDocument(formData)
        .then((res) => {
          notification.success({
            message: 'Success',
            description: 'Your successfully upload certificate!',
            duration: 1.5,
          });
          student.isIll = null;
        })
        .finally(() => {
          setSpinner(false);
          cancel();
        });
    }
  };

  const handleImage = (file: any) => {
    setImageFile(file);
  };

  const notifyAboutIllness = () => {
    setSpinner(true);
    IllPersonService.notifyAboutIllness()
      .then((data) => {
        notification.success({
          message: 'Success',
          description: 'Your successfully notified about illness!',
          duration: 1.5,
        });
        student.isIll = true;
      })
      .finally(() => {
        setSpinner(false);
        cancel();
      });
  };

  const notifyAboutRecovery = () => {
    setSpinner(true);
    IllPersonService.notifyAboutRecovery()
      .then((data) => {
        notification.success({
          message: 'Success',
          description: 'Your successfully notified about recovery!',
          duration: 1.5,
        });
        student.isIll = false;
      })
      .finally(() => {
        setSpinner(false);
        cancel();
      });
  };

  const onUploadChange = (e: any) => {
    e.file.status = 'done';
    setUploaded(true);
    setLatestSubmissionRejected(false);
  };

  if (student !== void 0) {
    if (student.isIll === null) {
      diseaseUploadButton = (
        <>
          <Modal
            title="Disease status"
            open={visibleDiseaseStatus}
            onCancel={cancel}
            width={500}
            footer={[
              <Button type="primary" danger onClick={notifyAboutIllness} loading={spinner}>
                Notify about ill
              </Button>,
              <Button type="default" onClick={cancel} style={{ marginLeft: '10px' }}>
                Cancel
              </Button>,
            ]}
          >
            <Typography.Text>Are you sure you want to notify about your illnes?</Typography.Text>
          </Modal>
          {/* <Typography.Title level={5}>Are you sure you want to notify about your illnes?</Typography.Title> */}
        </>
      );
    } else if (student.isIll === true) {
      diseaseUploadButton = (
        <>
          <Modal
            title="Disease status"
            open={visibleDiseaseStatus}
            onCancel={cancel}
            width={500}
            footer={[
              <Button type="primary" onClick={notifyAboutRecovery} loading={spinner}>
                Notify about recovery
              </Button>,
              <Button type="default" onClick={cancel} style={{ marginLeft: '10px' }}>
                Cancel
              </Button>,
            ]}
          >
            <Typography.Text>Are you sure you want to notify about your recovery?</Typography.Text>
          </Modal>
          {/* <Typography.Title level={5}>Are you sure you want to notify about your illnes?</Typography.Title> */}
        </>
      );
    } else if (student.isIll === false) {
      diseaseUploadButton = (
        <>
          {uploaded ? (
            <Typography.Title level={4}>
              Office is currently reviewing your certificate <br />{' '}
              <Typography.Text type="secondary">Please wait!</Typography.Text>
            </Typography.Title>
          ) : (
            <Typography.Title level={4}>
              Please scan and upload file confirming your sick leave
              <br />
              <Typography.Text type="secondary">You can upload only one file!</Typography.Text>
            </Typography.Title>
          )}

          <Upload
            onChange={(e) => onUploadChange(e)}
            beforeUpload={(file, fileList) => {
              handleImage(file);
            }}
            customRequest={handleSubmitFile}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />} loading={spinner} disabled={uploaded} style={{ marginTop: 20 }}>
              Click to Upload
            </Button>
          </Upload>
        </>
      );
    }
  }

  return (
    <>
      <AuthenticatedContent role={'STUDENTS'} type={'baseRole'}>
        {diseaseUploadButton}
      </AuthenticatedContent>
    </>
  );
};
export default UploadDisease;
