import authAxios from '../common/authAxios';
import { ForeignUniversitiesDtoRequest } from '../interfaces/ForeignUniversitiesDtoRequest';
import { ForeignUniversitiesDtoResponse } from '../interfaces/ForeignUniversitiesDtoResponse';

export class ForeignUniversitiesService {

    static getForeignUniversitiesById(universityId: number) {
        return authAxios.get<ForeignUniversitiesDtoResponse>(
            `/astanait-office-module/api/v1/academic-department/universities/get-by-id?id=${universityId}`
        );
    }

    static getAllForeignUniversities() {
        return authAxios.get<ForeignUniversitiesDtoResponse[]>(
            `/astanait-office-module/api/v1/academic-department/universities/get-all`
        );
    }

    static createForeignUniversity(foreignUniversity: ForeignUniversitiesDtoRequest) {
        return authAxios.post(
            `/astanait-office-module/api/v1/academic-department/universities/create`,
            foreignUniversity
        );
    }

    static deleteForeignUniversity(universityId: number) {
        return authAxios.delete<ForeignUniversitiesDtoResponse>(
            `/astanait-office-module/api/v1/academic-department/universities/delete?id=${universityId}`
        );
    }

    static updateForeignUniversity(universityId: number, foreignUniversity: ForeignUniversitiesDtoRequest) {
        return authAxios.put(
            `/astanait-office-module/api/v1/academic-department/universities/update?id=${universityId}`,
            foreignUniversity
        );
    }

}