import axios from 'axios';
import authAxios from '../common/authAxios';
import { Constants } from '../common/constants';
import { AcademicDegreeDtoResponse } from '../interfaces/AcademicDegreeDtoResponse';
import { DepartmentDtoResponse } from '../interfaces/DepartmentDtoResponse';
import { DisciplineDtoRequest } from '../interfaces/DisciplineDtoRequest';
import { DisciplineDtoResponse } from '../interfaces/DisciplineDtoResponse';
import { StudentDisciplineChooseDtoResponse } from '../interfaces/DisciplineEnrollmentDtoResponse';
import { BreakDownByHourDtoResponse } from '../interfaces/EducationalPrograms/BreakDownByHourDtoResponse';
import { CreateBreakDownByHourDtoRequest } from '../interfaces/EducationalPrograms/CreateBreakDownByHourDtoRequest';
import { CurriculumDtoResponse } from '../interfaces/EducationalPrograms/CurriculumDtoResponse';
import { LessonsTypeDtoResponse } from '../interfaces/EducationalPrograms/LessonsTypeDtoResponse';
import { StudentDisciplineDtoResponse } from '../interfaces/Student/StudentDisciplineDtoResponse';
import { StudentDisciplineFinalChooseDtoResponse } from '../interfaces/Student/StudentDisciplineFinalChooseDtoResponse';
import { RequisitionDtoRequest } from '../interfaces/RequisitionDtoRequest';
import { RequisitionDtoResponse } from '../interfaces/RequisitionDtoResponse';
import { GradeFromTypeDtoResponse } from '../interfaces/EducationalPrograms/GradeFromTypeDtoResponse';
import { AcademicMobilityDisciplineDtoResponse } from '../interfaces/AcademicMobilityDisciplineDtoResponse';
import { DisciplinesDtoResponse } from '../interfaces/DisciplinesDtoResponse';
import { ElectiveChooseDtoResponse } from '../interfaces/EducationalPrograms/ElectiveChooseDtoResponse';

export class DisciplinesService {
  static baseURL = Constants.API_BASE_URL;

  static getDepartments() {
    return authAxios.get<DepartmentDtoResponse[]>(
      '/astanait-office-module/api/v1/catalog/department/get-all-departments'
    );
  }

  static getDepartmentsPublic() {
    return axios.get<DepartmentDtoResponse[]>(
      `${this.baseURL}/astanait-office-module/api/v1/catalog/department/pps/get-all-departments`
    );
  }

  static getAllDisciplinesByDegree(academicDegreeId: number | undefined) {
    return authAxios.get<DisciplineDtoResponse[]>(
      `/astanait-office-module/api/v1/academic-department/discipline/get-disciplines-by-degree?academicDegreeId=${academicDegreeId}`
    );
  }

  static getAcademicDegrees() {
    return authAxios.get<AcademicDegreeDtoResponse[]>(
      '/astanait-office-module/api/v1/catalog/academic-degree/get-all-academic-degrees'
    );
  }

  // ?page=1 ?departmentId=id ?academicDegreeId=id ?keyword='asdasd'(по названию на трех языках)
  static getDisciplines(params: any) {
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }

    return authAxios.get<{ list: DisciplineDtoResponse[]; total_number: number }>(
      '/astanait-office-module/api/v1/academic-department/discipline/get-disciplines-by-filter',
      { params }
    );
    // return authAxios.get<{ list: DisciplineDtoResponse[] }>('/astanait-office-module/api/v1/academic-department/discipline/get-disciplines-by-filter', { params })
  }

  static getAllDisciplines() {
    return authAxios.get<DisciplineDtoResponse[]>(
      `/astanait-office-module/api/v1/academic-department/discipline/get-all-disciplines`
    );
  }

  static getAllCredits() {
    return authAxios.get(`/astanait-office-module/api/v1/academic-department/discipline/get-all-volume-credits`);
  }

  static getDisciplinesByVolumeCredits(volumeCredits: number) {
    return authAxios.get<DisciplineDtoResponse[]>(
      `/astanait-office-module/api/v1/academic-department/discipline/get-disciplines-by-volume-credits?volume_credits=${volumeCredits}`
    );
  }

  static getOneDiscipline(disciplineId: number) {
    return authAxios.get<DisciplineDtoResponse>(
      `/astanait-office-module/api/v1/academic-department/discipline/get-discipline-by-one?discipline_id=${disciplineId}`
    );
  }

  // status 200
  static deleteDiscipline(disciplineId: number) {
    return authAxios.delete(
      `/astanait-office-module/api/v1/academic-department/discipline/delete-discipline?discipline_id=${disciplineId}`
    );
  }

  static addDiscipline(
    studentId: number | undefined,
    course: number | undefined,
    term: number | undefined,
    streamName: string | undefined,
    disciplineId: number | undefined,
    grade: number | undefined,
    grade_type: number | undefined
  ) {
    return authAxios.put(
      `/astanait-office-module/api/v1/academic-department/academic-stream-student-grade/add-discipline-and-grade-to-student-transcript?student_id=${studentId}&course=${course}&term=${term}&stream_name=${streamName}&discipline_id=${disciplineId}&grade=${grade}&grade_type=${grade_type}`
    );
  }


  static getDisciplinesFromCurriculumByFilter(
    education_program_id: number | undefined,
    course: number | undefined,
    term: number | undefined,
  ) {
    return authAxios.get<DisciplinesDtoResponse[]>(
      `/astanait-office-module/api/v1/academic-department/discipline/get-disciplines-from-curriculum-by-filter?education_program_id=${education_program_id}&course=${course}&term=${term}`
    );
  }


  static getGradeFromTypes() {
    return authAxios.get<GradeFromTypeDtoResponse[]>(
      '/astanait-office-module/api/v1/catalog/grade-from-type/get-all'
    );
  }

  static getAcademicMobilityDisciplines() {
    return authAxios.get<AcademicMobilityDisciplineDtoResponse[]>(
      '/astanait-office-module/api/v1/academic-department/discipline/get-academic-mobility-disciplines'
    );
  }


  static deleteDisciplineForStudent(academicStreamStudentId: number) {
    return authAxios.delete(
      `/astanait-office-module/api/v1/academic-department/academic-stream-student-grade/delete-discipline-and-grade-to-student-transcript?student_id=${academicStreamStudentId}`
    );
  }

  // status 200
  static updateDiscipline(disciplineId: number, updatedDiscipline: DisciplineDtoRequest) {
    return authAxios.put(
      `/astanait-office-module/api/v1/academic-department/discipline/update-discipline?discipline_id=${disciplineId}`,
      updatedDiscipline
    );
  }

  // Возвращает ID только что созданной дисциплины
  static createDiscipline(discipline: DisciplineDtoRequest) {
    return authAxios.post<number>(
      '/astanait-office-module/api/v1/academic-department/discipline/create-discipline',
      discipline
    );
  }

  static getLessonTypes() {
    return authAxios.get<LessonsTypeDtoResponse[]>(
      '/astanait-office-module/api/v1/catalog/lesson-type/get-all-lesson-type'
    );
  }

  static getAllocatedHoursByDiscipline(disciplineId: number) {
    return authAxios.get<BreakDownByHourDtoResponse[]>(
      `/astanait-office-module/api/v1/academic-department/break-down-by-hour/get-break-down-by-hour-by-discipline?discipline_id=${disciplineId}`
    );
  }

  static createAllocatedHours(disciplineId: number, data: CreateBreakDownByHourDtoRequest[]) {
    return authAxios.post(
      `/astanait-office-module/api/v1/academic-department/break-down-by-hour/create-break-down-by-hour?discipline_id=${disciplineId}`,
      data
    );
  }

  static deleteAllocatedHours(disciplineId: number) {
    return authAxios.delete(
      `/astanait-office-module/api/v1/academic-department/break-down-by-hour/delete-break-down-by-hour?discipline_id=${disciplineId}`
    );
  }

  //Discipline services for students

  static checkStudentDisciplineChoiceAccess() {
    return authAxios.get<boolean>(`/astanait-office-module/api/v1/student-discipline-choose/check-choose-exist`);
  }

  static getDisciplinesForStudentChoice() {
    return authAxios.get<ElectiveChooseDtoResponse[]>(
      `/astanait-office-module/api/v1/student-discipline-choose/get-grouped-disciplines-by-education-program`
    );
  }

  static getDisciplinesForStudent() {
    return authAxios.get<CurriculumDtoResponse[]>(
      `/astanait-office-module/api/v1/academic-department/curriculum/get-all-available-disciplines-from-all-education-programs`
    );
  }

  static getPreviousChoiceDisciplines() {
    return authAxios.get<StudentDisciplineChooseDtoResponse[]>(
      `/astanait-office-module/api/v1/student-discipline-choose/get-all-choose`
    );
  }

  static getStudentDisciplines() {
    return authAxios.get<StudentDisciplineDtoResponse[]>(
      '/astanait-office-module/api/v1/academic-department/discipline/get-disciplines-of-student'
    );
  }

  static getAllFinalChoose() {
    return authAxios.get<StudentDisciplineFinalChooseDtoResponse[]>(
      '/astanait-office-module/api/v1/student-discipline-choose/get-all-final-choose'
    );
  }

  static getCurriculumsForRequisitions(requesitionType: 'postreq' | 'prereq', curriculumId: number) {
    if (requesitionType === 'postreq') {
      return authAxios.get<CurriculumDtoResponse[]>(
        `/astanait-office-module/api/v1/academic-department/curriculum/get-curriculums-for-postrequisitions?curriculum_id=${curriculumId}`
      );
    }
    else {
      return authAxios.get<CurriculumDtoResponse[]>(
        `/astanait-office-module/api/v1/academic-department/curriculum/get-curriculums-for-prerequisitions?curriculum_id=${curriculumId}`
      );
    }
  }


  static getRequisitions(requesitionType: 'postreq' | 'prereq', curriculumId: number) {
    if (requesitionType === 'postreq') {
      return authAxios.get<RequisitionDtoResponse[]>(
        `/astanait-office-module/api/v1/catalog/postrequisition/get-by-curriculum-id?curriculumId=${curriculumId}`
      );
    }
    else {
      return authAxios.get<RequisitionDtoResponse[]>(
        `/astanait-office-module/api/v1/catalog/prerequisition/get-by-curriculum-id?curriculumId=${curriculumId}`
      );
    }
  }

  static saveRequesition(requesitionType: 'postreq' | 'prereq', dto: RequisitionDtoRequest, id?: number) {
    if (requesitionType === 'postreq') {
      if (id) {
        return authAxios.put(
          `/astanait-office-module/api/v1/catalog/postrequisition/update/${id}`, dto
        );
      }
      else {
        return authAxios.post(
          '/astanait-office-module/api/v1/catalog/postrequisition/create', dto
        );
      }
    }
    else {
      if (id) {
        return authAxios.put(
          `/astanait-office-module/api/v1/catalog/prerequisition/update/${id}`, dto
        );
      }
      else {
        return authAxios.post(
          '/astanait-office-module/api/v1/catalog/prerequisition/create', dto
        );
      }
    }
  }

  static deleteRequisitions(requesitionType: 'postreq' | 'prereq', id: number, disciplineId: number[]) {
    if (requesitionType === 'postreq') {
      return authAxios.delete(
        `/astanait-office-module/api/v1/catalog/postrequisition/remove-disciplines/${id}?disciplineIds=${disciplineId}`
      );
    }
    else {
      return authAxios.delete(
        `/astanait-office-module/api/v1/catalog/prerequisition/remove-disciplines/${id}?disciplineIds=${disciplineId}`
      );
    }
  }
}
