import authAxios from "../../../common/authAxios";
import { errorNotification } from "../../../helpers/errorNotification";
import { successNotification } from "../../../helpers/successNotification";

interface SummerRetakeApplicationsFilterParams {
    studentStatus: string;
    course: number | null;
    approved: number | null;
    startDate: string | null;
    endDate: string | null;
}

export class SummerRetakeApplicationService {
    private static baseUrlRetakeApplication = '/astanait-office-module/api/v1/summer-retake-application';

    
    static getRetakeDisciplinesForStudent(studentId: number) {
        return authAxios.get(`${this.baseUrlRetakeApplication}/summer-retake-disciplines/${studentId}`);
    }
    //http://localhost:8765/astanait-office-module/api/v1/summer-retake-application/summer-retake-disciplines/101

    static uploadFileRetakeApplication(formData: any) {
        return authAxios.post('/astanait-office-module/api/v1/summer-retake-application/upload-retake-application', formData)
    }

    static getRetakeStatusForStudent(studentId: number) {
        return authAxios.get(`${this.baseUrlRetakeApplication}/retake-applications-status-for-student/${studentId}`);
    }

    static createRetakeApplication(fileId: number, disciplineId: number,creditAmount:number) {
        authAxios.post("/astanait-office-module/api/v1/summer-retake-application/create-summer-retake-application", { fileId, disciplineId,creditAmount })
        .then(() => {
            successNotification('Application accepted for consideration');
        })
        .catch((error) => {
            if (error.response && error.response.status === 403) {
                  errorNotification('Заявки принимаются с 13 мая 2024 по 2 июня 2024 включительно.');
            } else {
                errorNotification('Произошла ошибка при отправке заявки.', error.response?.status);
                console.error('Error:', error);
            }
        });
    }

    static fetchRetakeApplicationFilter(params:SummerRetakeApplicationsFilterParams) {
        return authAxios.get(`${this.baseUrlRetakeApplication}/summer-retake-applications-filter`, { params });
    }

    static moveToSecondStage(id: number) {
        return authAxios.put(`${this.baseUrlRetakeApplication}/decline-retake-application?id=${id}`);
    }

    static moveToThirdStage(id: number) {
        return authAxios.put(`${this.baseUrlRetakeApplication}/accept-retake-application?id=${id}`);
    }

    static addComment(id: number,  comment: string) {
        return authAxios.put(`${this.baseUrlRetakeApplication}/add-comment?id=${id}&comment=${comment}`);
    }

    static fetchRetakeApplicationExcel(params:SummerRetakeApplicationsFilterParams) {
        return authAxios.get(`${this.baseUrlRetakeApplication}/retake-applications-excel`, {
            params,
            responseType: 'blob'
        });
    }

    static getStudentRetakeApplicationWord(studentId: number) {
        return authAxios.get(`${this.baseUrlRetakeApplication}/retake-applications-word/${studentId}`, { 
                        responseType: 'blob' 
        });
    }
    





    static getFxExamStudentsByTeacherEmail(email: string) {
        return authAxios.get(`${this.baseUrlRetakeApplication}/fx-exam-students-by-teacher-mail/${email}`);
    }



    

    

    static declineApplicationByOfficeRegistrar(id: number) {
        return authAxios.put(`${this.baseUrlRetakeApplication}/decline-application-office-registrar?id=${id}`);
    }

    static acceptApplicationByOfficeRegistrar(id: number) {
        return authAxios.put(`${this.baseUrlRetakeApplication}/accept-application-office-registrar?id=${id}`);
    }

    

    
    
      
    

}