import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CommissionMembersDtoResponse } from '../../../interfaces/CommissionMembersDtoResponse';
import { DiplomaWorkDtoResponse } from '../../../interfaces/Diploma/response/DiplomaWorkDtoResponse';
import { EducationProgramDtoResponse } from '../../../interfaces/EducationalPrograms/EducationProgramDtoResponse';
import { StudentGroupsDtoResponse } from '../../../interfaces/Student/StudentGroupsDtoResponse';
import { StudentsListDtoResponse } from '../../../interfaces/Student/StudentsListDtoResponse';
import { DiplomaService } from '../../../services/DiplomaService';
import { EducationalProgramsService } from '../../../services/EducationalProgramsService';
import { TranscriptService } from '../../../services/TranscriptService';
import { DiplomaQuestions } from '../../../interfaces/Diploma/response/DiplomaQuestionsDtoResponse';
import { DiplomaWorkFilesDtoResponse } from '../../../interfaces/Diploma/response/DiplomaWorkFilesDtoResponse';
import { successNotification } from '../../../helpers/successNotification';
import { DiplomaWorkFileTypesDtoResponse } from '../../../interfaces/Diploma/response/DiplomaWorkFileTypesDtoResponse';
import { DiplomaWorkDtoRequest } from '../../../interfaces/Diploma/request/DiplomaWorkDtoRequest';
import { StudentService } from '../../../services/StudentService';

const useFinalAttestationProtocol = () => {
  const [form] = Form.useForm();
  const [groups, setGroups] = useState<StudentGroupsDtoResponse[]>([]);
  const [educationalPrograms, setEducationalPrograms] = useState<EducationProgramDtoResponse>();
  const [students, setStudents] = useState<StudentsListDtoResponse[]>([]);
  const [diplomaWorks, setDiplomaWorks] = useState<DiplomaWorkDtoResponse[]>([]);
  const [commissionMembers, setCommissionMembers] = useState<CommissionMembersDtoResponse[]>([]);

  const [diplomaWorkFileTypes, setDiplomaWorkFileTypes] = useState<DiplomaWorkFileTypesDtoResponse[]>([]);
  const [diplomaWorkFiles, setDiplomaWorkFiles] = useState<DiplomaWorkFilesDtoResponse[]>([]);
  const [initialFileTypes, setInitialFileTypes] = useState<DiplomaWorkFileTypesDtoResponse[]>([]);
  const [optionalFileTypes, setOptionalFileTypes] = useState<DiplomaWorkFileTypesDtoResponse[]>([]);
  const [diplomaWorkDtoRequest, setDiplomaWorkDtoRequest] = useState<DiplomaWorkDtoRequest[]>([]);

  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [selectedDiplomaWorkId, setSelectedDiplomaWorkId] = useState<number>();
  const [selectedCommissionNumber, setSelectedCommissionNumber] = useState<number>();
  const [selectedCommissionMemberId, setSelectedCommissionMemberId] = useState<number>();
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formFields, setformFields] = useState([
    {
      questionsKz: '',
      questionsRu: '',
      questionsEn: '',
    },
  ]);

  const styles = { width: '800px' };

  // useEffect(() => {
  //   setLoading(true);
  //   EducationalProgramsService.getEducationalPrograms()
  //     .then((response) => setEducationalPrograms(response.data))
  //     .catch((err) => errorNotification('Не удалось получить данные', err.response?.status))
  //     .finally(() => setLoading(false));
  // }, []);

  const onGroupSelect = (groupId: number) => {
    form.resetFields();
    setSelectedGroupId(groupId);
    setSelectedUserId(undefined);
    setSelectedDiplomaWorkId(undefined);
    setLoading(true);
    setEducationalPrograms(undefined)
    TranscriptService.getStudentsByGroupForTranscript(groupId)
      .then((response) => {
        const mappedStudents = response.data.map((student) => ({
          id: student.id,
          name: student.nameKz,
          surname: student.surnameKz,
          patronymic: student.patronymicKz,
          userId: student.userId
        }));

        EducationalProgramsService.getOneEducationalProgram(response.data[0].educationProgramId)
          .then(({ data }) => setEducationalPrograms(data));

        setStudents(mappedStudents);
      })
      .finally(() => setLoading(false));
  };

  const onComissionMemberNumberSelect = (commissionNumber: number) => {
    setSelectedCommissionNumber(commissionNumber);
    setLoading(true);

    DiplomaService.getCommissionMember(commissionNumber, educationalPrograms?.id)
      .then((response) => {
        setCommissionMembers(response.data);
      })
      .finally(() => setLoading(false));
  };

  const onStudentSelect = (userId: number) => {
    form.resetFields();
    setSelectedDiplomaWorkId(undefined);
    setSelectedUserId(userId);
    hanleDiplomaWorkFile(userId);
  };

  const hanleDiplomaWorkFile = (userId: number) => {
    setLoading(true);
    setDiplomaWorkDtoRequest([]);
    StudentService.getStudentIdByUserId(userId).then(({ data: student }) => {
      DiplomaService.getDiplomaWorksByStudentId(student.id)
        .then((response) => {
          setDiplomaWorks(response.data);
          response.data.length > 0 &&
            DiplomaService.getDiplomaWorkFileByWorkId(response.data[0].id)
              .then((response) => {
                setDiplomaWorkFiles(response.data);
                setInitialFileTypes(diplomaWorkFileTypes.filter(
                  type => type.isRequired || response.data.some(file => file.type.id === type.id)
                ))
              })
        })
        .finally(() => setLoading(false));
    })
  }

  const handleAddOptionalFileType = (typeId: number) => {
    const fileType = diplomaWorkFileTypes.find(type => type.id === typeId);
    if (fileType) {
      setDiplomaWorkFiles(prevFiles => [
        ...prevFiles,
        {
          id: fileType.id,
          type: fileType,
          name: "",
          fileId: 0, // Add the required fileId property here
        },
      ])
    }
  };


  const onDiplomaWorkSelect = (diplomaWorkId: number) => {
    setSelectedDiplomaWorkId(diplomaWorkId);
    setLoading(true);

    let student = students.find((student) => student.userId === selectedUserId);
    DiplomaService.getProtocolInfo(diplomaWorkId, student?.id || 0)
      .then(({ data }) => {
        var reviewerFullname = '';
        if (data.reviewerPatronymic == null && data.reviewerSurname == null) {
          reviewerFullname = data.reviewerName;
        } else if (data.reviewerPatronymic == null) {
          reviewerFullname = data.reviewerSurname + ' ' + data.reviewerName + ' ';
        } else if (data.reviewerPatronymic != null) {
          reviewerFullname = data.reviewerSurname + ' ' + data.reviewerName + ' ' + data.reviewerPatronymic;
        }
        form.setFieldsValue({
          reviewerName: reviewerFullname,
          reviewerAcademicDegree: data.reviewerAcademicDegree,
          reviewerWorksAt: data.reviewerWorksAt,
          reviewerCurrentPosition: data.reviewerCurrentPosition,
          reviewerGrade: data.reviewerGrade,
          protocolNumber: data.protocolNumber,
          protocolDateEnd:
            (data.protocolDateEnd == null && '') ||
            (data.protocolDateEnd != null && moment(new Date(data.protocolDateEnd))),
          protocolDateStart:
            (data.protocolDateStart == null && '') ||
            (data.protocolDateStart != null && moment(new Date(data.protocolDateStart))),
          diplomaTextPageNumber: data.diplomaTextPageNumber,
          grade: data.grade,
          generalCharacteristicsOfStudentAnswers: data.generalCharacteristicsOfStudentAnswers,
          specialOpinionsOfCommission: data.specialOpinionsOfCommission,
        });
        const questions = data.questions;
        form.setFields(
          questions.map((question: DiplomaQuestions, index: any) => ({
            name: ['fields', index], // Specify the field name using the index
            value: question,
          }))
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    TranscriptService.getAllStudentsGroup()
      .then((response) => setGroups(response.data))
      .finally(() => setLoading(false));
    DiplomaService.getDiplomaWorkFileTypes()
      .then((response) => setDiplomaWorkFileTypes(response.data))
  }, []);



  const onFinish = async (values: any) => {
    const offset = new Date().getTimezoneOffset();

    const date = values.protocolDateEnd ? new Date(values.protocolDateEnd) : undefined;
    const protocolDateEnd = date ? new Date(date.setMinutes(date.getMinutes() - offset)) : undefined;
    const date1 = values.protocolDateStart ? new Date(values.protocolDateStart) : undefined;
    const protocolDateStart = date1 ? new Date(date1.setMinutes(date1.getMinutes() - offset)) : undefined;
    let arr = values.reviewerName ? values.reviewerName?.split(' ') : ["", "", ""];
    let question: any[] = [];
    if (typeof values.fields !== 'undefined') {
      question = values.fields;
    }
    setLoading(true);
    diplomaWorkDtoRequest.length > 0
      &&
      await DiplomaService.uploadFileForDiploma(
        selectedDiplomaWorkId,
        diplomaWorkDtoRequest
      ).then(() => {
        setDiplomaWorkDtoRequest([]);
        setOptionalFileTypes([]);
      });

    selectedUserId &&
      await StudentService.getStudentIdByUserId(selectedUserId).then(({ data: student }) => {
        DiplomaService.addProtocolData({
          studentId: student.id,
          diplomaWorkId: selectedDiplomaWorkId,
          protocolNumber: values.protocolNumber,
          protocolDateEnd: protocolDateEnd,
          protocolDateStart: protocolDateStart,
          diplomaTextPageNumber: values.diplomaTextPageNumber,
          grade: values.grade,
          reviewerGrade: values.reviewerGrade,
          reviewerName: arr[1],
          reviewerSurname: arr[0],
          reviewerPatronymic: arr[2],
          reviewerAcademicDegree: values.reviewerAcademicDegree,
          reviewerWorksAt: values.reviewerWorksAt,
          reviewerCurrentPosition: values.reviewerCurrentPosition,
          generalCharacteristicsOfStudentAnswers: values.generalCharacteristicsOfStudentAnswers,
          specialOpinionsOfCommission: values.specialOpinionsOfCommission,
          questions: question?.map((field: any) => ({
            id: field.id,
            attestationCommissionMemberId: field.attestationCommissionMemberId,
            diplomaWorkId: selectedDiplomaWorkId,
            question: field.question,
            studentId: selectedUserId,
          })),
        })
          .then(() => {
            successNotification('Протокол успешно добавлен');
          })
          .finally(() => {
            selectedDiplomaWorkId && onDiplomaWorkSelect(selectedDiplomaWorkId);
            selectedUserId &&
              hanleDiplomaWorkFile(selectedUserId);
            setLoading(false);
          });
      });
  };
  const exportProtocolForm1 = () => {
    DiplomaService.getProtocolForm1(selectedUserId, selectedDiplomaWorkId, selectedCommissionNumber).finally(() =>
      setLoading(false)
    );
  };
  const exportProtocolForm2 = () => {
    DiplomaService.getProtocolForm2(selectedUserId, selectedDiplomaWorkId, selectedCommissionNumber).finally(() =>
      setLoading(false)
    );
  };
  const exportProtocolForm3 = () => {
    DiplomaService.getProtocolForm3(selectedUserId, selectedDiplomaWorkId, selectedCommissionNumber).finally(() =>
      setLoading(false)
    );
  };
  const onchangeCommission = (values: any) => {
  };

  return {
    groups,
    setGroups,
    diplomaWorkFileTypes,
    setDiplomaWorkFileTypes,
    diplomaWorkFiles,
    setDiplomaWorkFiles,
    initialFileTypes,
    setInitialFileTypes,
    optionalFileTypes,
    setOptionalFileTypes,
    diplomaWorkDtoRequest,
    setDiplomaWorkDtoRequest,
    educationalPrograms,
    setEducationalPrograms,
    students,
    setStudents,
    diplomaWorks,
    setDiplomaWorks,
    commissionMembers,
    setCommissionMembers,
    selectedGroupId,
    setSelectedGroupId,
    selectedUserId,
    setSelectedUserId,
    selectedDiplomaWorkId,
    setSelectedDiplomaWorkId,
    selectedCommissionNumber,
    setSelectedCommissionNumber,
    selectedCommissionMemberId,
    setSelectedCommissionMemberId,
    formChanged,
    setFormChanged,
    formFields,
    setformFields,
    styles,
    onGroupSelect,
    onComissionMemberNumberSelect,
    onStudentSelect,
    onDiplomaWorkSelect,
    handleAddOptionalFileType,
    onFinish,
    exportProtocolForm1,
    exportProtocolForm2,
    exportProtocolForm3,
    onchangeCommission,
    form,
    loading,
  };
};

export default useFinalAttestationProtocol;
