
import React from 'react';
import { Typography, Form, Button, Select, Space, InputNumber } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import { DisciplinesService } from '../../../services/DisciplinesService';
import BackButton from '../../../ui/BackButton';
import { successNotification } from '../../../helpers/successNotification';
import { CycleDtoResponse } from '../../../interfaces/EducationalPrograms/CycleDtoResponse';
import { ComponentDtoResponse } from '../../../interfaces/EducationalPrograms/ComponentDtoResponse';
import { CurriculumService } from '../../../services/CurriculumService';
import { DisciplineDtoResponse } from '../../../interfaces/DisciplineDtoResponse';
import { CurriculumDtoRequest } from '../../../interfaces/EducationalPrograms/CurriculumDtoRequest';
import { errorNotification } from '../../../helpers/errorNotification';

const { Title } = Typography;
const { Option } = Select;

interface Props {
    curriculumId: number;
}

const CurriculumEditPage = ({ curriculumId }: Props) => {
    const [form] = Form.useForm();

    const styles = { width: '800px', margin: '0 auto' };


    const [components, setComponents] = React.useState<ComponentDtoResponse[]>([]);
    const [cycles, setCycles] = React.useState<CycleDtoResponse[]>([]);
    const [disciplines, setDisciplines] = React.useState<DisciplineDtoResponse[]>([]);
    const [educationProgramId, setEducationProgramId] = React.useState<number>();
    const [educationProgramName, setEducationProgramName] = React.useState<string>();

    React.useEffect(() => {
        CurriculumService.getById(curriculumId)
            .then(({ data }) => {
                DisciplinesService.getAllDisciplinesByDegree(data.educationProgram.academicDegree.id)
                    .then(({ data }) => { setDisciplines(data) })
            });

        Promise.all([CurriculumService.getComponents(), CurriculumService.getCycles()])
            .then(([componentsResponse, cyclesResponse]) => {
                setComponents(componentsResponse.data);
                setCycles(cyclesResponse.data);


            });
    }, [curriculumId]);

    React.useEffect(() => {

        CurriculumService.getById(curriculumId)
            .then(({ data }) => {


                if (disciplines.length) {
                    setEducationProgramId(data.educationProgram.id)
                    setEducationProgramName(data.educationProgram.titleEn)
                    form.setFieldsValue({
                        educationProgramId: data.educationProgram.id,
                        disciplineId: data.discipline.id,
                        year: data.year,
                        cycleId: data.cycle.id,
                        componentId: data.component.id,
                        numberOfTrimester: data.numberOfTrimester,
                        maxStudents: data.maxStudents,
                        minStudents: data.minStudents
                    });
                }

            });
    }, [curriculumId, disciplines, form]);

    const onFinish = (curriculum: CurriculumDtoRequest) => {
        // window.history.back()
        CurriculumService.updateCurriculum(curriculumId, curriculum)
            .then(() => { successNotification('Данные успешно обновлены') })
            .catch((err) => errorNotification(err.response?.data.message, err.response?.status));
    };

    return (
        <PageWrapper>
            {/* <Link to="/disciplines"> */}
            <BackButton />
            {/* </Link> */}
            <header style={styles}>
                <Title level={3}>{'Редактирование учебной программы'}</Title>
            </header>

            <Form
                layout="vertical"
                size="large"
                style={styles}
                initialValues={{ remember: true }}
                autoComplete="off"
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Учебной программа"
                    name="educationProgramId"

                >
                    <Select >
                        <Option value={educationProgramId}>{educationProgramName}</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Дисциплина"
                    name="disciplineId"

                >
                    <Select showSearch optionFilterProp="children">
                        {disciplines?.map((discipline) => (
                            <Option value={discipline.id}>{discipline.titleEn + " кредиты - " + discipline.volumeCredits}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Учебный год"
                    name="year"

                >
                    <Select placeholder="Выберите курс" >
                        <Option value={1}>1</Option>
                        <Option value={2}>2</Option>
                        <Option value={3}>3</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Цикл"
                    name="cycleId"

                >
                    <Select>
                        {cycles?.map((cycle) => (
                            <Option value={cycle.id}>{cycle.cycleAbbreviationRu} - {cycle.cycleTitleRu}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Компонент"
                    name="componentId"

                >
                    <Select >
                        {components?.map((component) => (
                            <Option value={component.id}>{component.componentAbbreviationRu} - {component.componentTitleRu}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Триместр"
                    name="numberOfTrimester"

                >
                    <Select placeholder="Выберите триместр" >
                        <Option value={1}>1 триместр</Option>
                        <Option value={2}>2 триместр</Option>
                        <Option value={3}>3 триместр</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="максимальное количество обучающихся"
                    name="maxStudents"
                >
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="минимальное количество обучающихся"
                    name="minStudents"
                >
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </PageWrapper>
    );
};

export default CurriculumEditPage;
