import styles from "./style.module.css";

import { Typography, Table, Button } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import SearchFilter from './SearchFilter';
import SelectFilter from './SelectFilter';
import useDisciplinesCatalog from './useDisciplinesCatalog';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

const DisciplinesCatalogPage = () => {
  const {
    loading,
    academicDegrees,
    disciplines,
    totalDisciplines,
    setCurrentPage,
    setPageSize,
    searchQuery,
    setSearchQuery,
    selectedAcademicDegree,
    setSelectedAcademicDegree,
    columns,
  } = useDisciplinesCatalog();

  return (
    <PageWrapper>
      <section>
        <header className={styles.header}>
          <Title level={3} className={styles.title}>
            Каталог Дисциплин
          </Title>

          {/* <SelectFilter
            placeholder="Кафедра"
            selectedOption={selectedDepartment}
            onSelect={(value) => setSelectedDepartment(value)}
            options={departments}
          /> */}
          <SelectFilter
            placeholder="Академическая Степень"
            selectedOption={selectedAcademicDegree}
            onSelect={(value) => setSelectedAcademicDegree(value)}
            options={academicDegrees}
          />
          <SearchFilter searchQuery={searchQuery} onSearch={(value) => setSearchQuery(value)} />
          <Link to="/disciplines/create">
            <Button type="primary" icon={<PlusOutlined />}>
              Создать дисциплину
            </Button>
          </Link>
        </header>
        <Table
          pagination={{ defaultPageSize: 10, total: totalDisciplines }}
          dataSource={disciplines.map((discipline) => ({
            key: discipline.id,
            disciplineName: `${discipline.titleRu} / ${discipline.titleKz} / ${discipline.titleEn}`,
            disciplineCode: discipline.code,
            credits: discipline.volumeCredits
          }))}
          columns={columns}
          onChange={(pagination) => {
            setCurrentPage(pagination.current);
            setPageSize(pagination.pageSize);
          }}
          loading={loading}
        />
      </section>
    </PageWrapper>
  );
};

export default DisciplinesCatalogPage;
