import { ColumnsType } from 'antd/lib/table';
import { StudentDtoResponse } from '../../../../interfaces/Student/StudentDtoResponse';
import { Button, Form, Space, notification } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { successNotification } from '../../../../helpers/successNotification';
import { errorNotification } from '../../../../helpers/errorNotification';
import { useEffect, useState } from 'react';
import { NotificationService } from '../../../../services/NotificationService';
import { MessageDtoResponse } from '../../../../interfaces/Notification/response/MessageDtoResponse';

interface TableColumns {
  id: number;
  userId: number;
  fullName: string;
  group?: string;
  iin?: string;
  dateOfBirth: string;
  courseGrade?: string;
  studentStatus?: string;
  studyingStatus?: string;
}

const useStudentsTable = (data: StudentDtoResponse[], navigate: any, selectionStates: any) => {
  let filteredData: TableColumns[] = [];
  const [form] = Form.useForm();
  const [selectedStudent, setSelectedStudent] = useState<TableColumns[]>([]);
  const [selectedStudentId, setSelectedStudentId] = useState<number[]>([]);
  const [tableVisible, setTableVisible] = useState<boolean>(false);
  const [filteredData2, setFilteredData2] = useState<TableColumns[]>([]);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  // eslint-disable-next-line array-callback-return
  useEffect(() => {
    setTableVisible(true);

  }, []);
  data.map((student) => {
    filteredData.push({
      id: student.id,
      userId: student.userId,
      fullName: student.fullName,
      group: student.group.title,
      iin: student.iin,
      dateOfBirth: student.birthDate?.toString().slice(0, 10),
      courseGrade: student.courseGrade,
      studentStatus: student.statusTypeId.nameRu,
      studyingStatus: student.studyingStatusType.nameRu,
    });
  });
  const handleStudentSelect = (record: any) => {
    const student = filteredData2.find((student) => student.userId === record.userId);
    if (!student) {
      setFilteredData2([...filteredData2, record]);
      setSelectedStudentId([...selectedStudentId, record.userId]);
      successNotification(`Студент был успешно выбран`);

    } else {
      errorNotification(`Вы уже выбрали этого студента!`);
    }
  };
  const handleSelectAll = (record: TableColumns[]) => {
    setFilteredData2((prevFilteredData2) => {
      const newFilteredData2 = [...prevFilteredData2];
      const existingIds = new Set(prevFilteredData2.map((student) => student.userId));
      let newStudentAdded = false; // Flag to track if any new student is added

      record.forEach((item) => {
        if (!existingIds.has(item.userId)) {
          newFilteredData2.push(item);
          existingIds.add(item.userId);
          newStudentAdded = true; // Set the flag if a new student is added
        } else {
          errorNotification(`Вы уже выбрали этого студента!`);
        }
      });

      // Show success notification once if any new student was added
      if (newStudentAdded) {
        successNotification(`Студенты были успешно выбраны`);
      }

      return newFilteredData2;
    });

    setSelectedStudentId((prevSelectedStudentIds) => {
      const newSelectedStudentIds = [...prevSelectedStudentIds];
      const existingIds = new Set(prevSelectedStudentIds);

      record.forEach((item) => {
        if (!existingIds.has(item.userId)) {
          newSelectedStudentIds.push(item.userId);
          existingIds.add(item.userId);
        }
      });

      return newSelectedStudentIds;
    });

    console.log(filteredData2);
  };
  const style = { margin: '0 auto' };
  const handleDelete = (record: any) => {

    const newData = filteredData2.filter((student) => student.userId !== record.userId);
    setFilteredData2(newData);
    const newDataId = selectedStudentId.filter((student) => student !== record.userId);
    setSelectedStudentId(newDataId);
  };
  const onFormFinish = (message: any) => {
    setCreateLoading(true)
    const notificationDtoRequest = { userIds: selectedStudentId, message: message.message };
    NotificationService.createNotification(notificationDtoRequest)
      .then(() => notification.success({ message: 'Сообщение создано!' }))
      .finally(() => setCreateLoading(false));
    // setTimeout(() => history.push('/order-list'), 1000);
  };
  const columns: ColumnsType<TableColumns> = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Группа',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'Курс',
      dataIndex: 'courseGrade',
      key: 'courseGrade',
    },
    {
      title: 'Статус',
      key: 'studentStatus',
      dataIndex: 'studentStatus',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: (text: any, record: any) => (
        <Space>
          <Button type="default" onClick={() => handleStudentSelect(record)}>
            <PlusOutlined />
          </Button>
        </Space>
      ),
      width: '5%',
    },
  ];
  const selectedStudentColumns: ColumnsType<TableColumns> = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Группа',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'Курс',
      dataIndex: 'courseGrade',
      key: 'courseGrade',
    },
    {
      title: 'Статус',
      key: 'studentStatus',
      dataIndex: 'studentStatus',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: (text: any, record: any) => (
        <Space>
          <Button type="default" onClick={() => handleDelete(record)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
      width: '5%',
    },
  ];







  return {
    columns, filteredData, filteredData2, selectedStudentColumns, style, selectedStudent,
    setSelectedStudent,
    selectedStudentId,
    setSelectedStudentId, tableVisible, form, onFormFinish, handleSelectAll, createLoading
  };
};

export default useStudentsTable;
