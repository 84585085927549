import styles from './style.module.css';
import { Typography, Table, Button, Pagination } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import useAgreementPage from './useAgreementPage';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;


const AcademicMobilityPage = () => {
    const { agreement, column } = useAgreementPage();

    return (
        <PageWrapper>
            <section>
                <header className={styles.header}>
                    <Title level={3} className={styles.title}>
                        Agreement List
                    </Title>
                    <Link to="/agreement/create">
                        <Button type="primary" icon={<PlusOutlined />}>
                            Add new agreement
                        </Button>
                    </Link>

                </header>
                <Table

                    dataSource={agreement.map((agreement) => ({
                        key: agreement.id,
                        id: agreement.id,
                        university: agreement.university ? agreement.university.nameEn : '',
                        startDate: agreement.startDate,
                        endDate: agreement.endDate,
                        agreementType: agreement.agreementType ? agreement.agreementType.nameEn : '',

                    }))}
                    columns={column}
                />

            </section>
        </PageWrapper>
    )
}

export default AcademicMobilityPage;