import PageWrapper from '../../../../ui/PageWrapper';
import { Typography } from 'antd';
import ProfileCurriculum from '../../ProfilePage/ProfileCurriculum';
import { useEffect, useState } from 'react';
import { StudentService } from '../../../../services/StudentService';
import BackButton from '../../../../ui/BackButton';

const { Title } = Typography;

interface Props {
  studentId: number;
  studentName?: string;
}

const StudentCurriculumDetailPage = ({ studentId }: Props) => {
  const [studentName, setStudentName] = useState<string>('');

  useEffect(() => {
    StudentService.getStudentIdByUserId(studentId).then(({ data }) =>
      setStudentName(data.surnameKz + ' ' + data.nameKz + ' ' + data.patronymicKz)
    );
  }, [studentId]);

  return (
    <PageWrapper>
      <BackButton />
      <section>
        <Title level={3}>{studentName}</Title>
        <ProfileCurriculum userId={studentId} />
      </section>
    </PageWrapper>
  );
};

export default StudentCurriculumDetailPage;
