import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
} from 'antd';
import moment from 'moment';
import { CertificateDtoResponse } from '../../../interfaces/CertificateDtoResponse';
import { CertificateService } from '../../../services/deansOffice/CertificateService';

interface EditModalProps {
  open: boolean;
  onCancel: () => void;
  data: CertificateDtoResponse;
}

const EditCertificateModal = ({ open, data, onCancel }: EditModalProps) => {
  const [form] = Form.useForm();
  form.setFieldsValue({
    id: data.id,
    issuedTo: data.issuedTo,
    courseName: data.courseName,
    number: data.number,
    type: data.type,
    issuedDate: data.issuedDate ?? undefined,
    validUntil: data.validUntil ?? undefined,
    isActive: data.isActive,
  });

  const onExit = () => {
  };

  const onSubmit = (record: CertificateDtoResponse) => {
    CertificateService.putCertificate(record)
      .then(({ data }) => notification.success({ message: 'Информация обновлена!' }))
      .finally(() => onCancel());
  };

  return (
    <Modal title="Изменить данные сертификата" centered open={open} onCancel={onCancel} width={700} footer={null}>
      <Form layout="vertical" onValuesChange={onExit} onFinish={onSubmit} form={form}>
        <Form.Item name="id" hidden></Form.Item>
        <Form.Item name="issuedTo" label="ФИО" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="courseName" label="Название курса">
          <Input />
        </Form.Item>
        <Form.Item name="number" label="Номер сертификата" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Тип сертификата">
          <Input />
        </Form.Item>
        <Form.Item
          name="issuedDate"
          label="Дата выдачи"
          getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
          getValueProps={(i) => ({ value: moment(i) })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="validUntil"
          label="Действителен до"
          getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
          getValueProps={(i) => ({ value: moment(i) })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item name="isActive" valuePropName="checked" initialValue={false}>
          <Checkbox>Активен?</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Изменить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditCertificateModal;
