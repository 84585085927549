import React from 'react'
import Page from '../../../ui/Page'
import useFetchData from '../../../hooks/useFetchData'
import { SyllabusDto } from '../../../interfaces/Syllabus/SyllabusDto';
import { SyllabusService } from '../../../services/SyllabusService';
import { Space, Table, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

const SyllabusPage = () => {
    const { data: syllabusList } = useFetchData<SyllabusDto[]>(SyllabusService.getSyllabusList, true);
    const history = useHistory();

    const columns = [
        {
            "title": "№",
            "dataIndex": "key",
            "key": "key",
            render(text: string, record: SyllabusDto, index: number) {
                return index + 1;
            },
        },
        {
            "title": "Discipline",
            "dataIndex": "discipline.titleEn",
            "key": "discipline.titleEn",
            render: (text: string, record: SyllabusDto) => (
                <Space>
                    <Typography>{record.discipline.titleEn}</Typography>
                </Space>
            )
        },
        {
            "title": "Academic year",
            "dataIndex": "academicYear",
            "key": "academicYear",
        },
        {
            "title": "",
            "dataIndex": "actions",
            "key": "actions",
            render: (text: string, record: SyllabusDto) => (
                <Space>
                    <Typography.Link onClick={() => { history.push(`/syllabus/${record.id}`) }}>Details</Typography.Link>
                </Space>
            )
        }
    ]

    return (
        <Page>
            <Table bordered rowKey={(row) => row.id + 1} columns={columns} dataSource={syllabusList} />
        </Page>
    )
}

export default SyllabusPage