import authAxios from '../../common/authAxios';
import { IrosApplication } from '../../interfaces/Iros/IrosApplication';
import { IrosIndicator } from '../../interfaces/Iros/IrosIndicator';
import { IrosIndicatosPoint } from '../../interfaces/Iros/IrosIndicatorsPoint';
import { IrosStatus } from '../../interfaces/Iros/IrosStatus';
import { IrosStudentPoint } from '../../interfaces/Iros/IrosStudentPoint';
import { IrosStatusPoint } from '../../interfaces/Iros/IrosStatusPoint';
import { IrosType } from '../../interfaces/Iros/IrosType';
import { StudentIrosApplication } from '../../interfaces/Iros/StudentIrosApplication';
import { downloadFile } from '../../helpers/downloadFile';
import { IrosStudentConfirmedByTeacherDto } from '../../interfaces/Iros/IrosStudentConfirmedByTeacherDto';
import { IrosStudentConfirmedByTeacherDtoRequest } from '../../interfaces/Iros/IrosStudentConfirmedByTeacherDtoRequest';
import { IrosApplicationResult } from '../../interfaces/Iros/IrosApplicationResult';
import { IrosApplicationProjection } from '../../interfaces/Iros/IrosApplicationProjection';

export class IrosService {
  private static baseUrlIros = '/astanait-office-module/api/v1/iros';

  static getTypes() {
    return authAxios.get<IrosType[]>(`${this.baseUrlIros}/get-all-iros-types`);
  }

  static getIndicators() {
    return authAxios.get<IrosIndicator[]>(`${this.baseUrlIros}/get-all-iros-indicators`);
  }

  static getIndicatorsByType(typeId: number) {
    return authAxios.get<IrosIndicator[]>(`${this.baseUrlIros}/get-by-type-iros-indicators?type_id=${typeId}`);
  }

  static getStatus() {
    return authAxios.get<IrosStatus[]>(`${this.baseUrlIros}/get-all-index-status`);
  }
  static getIndicatorById(indicatorId: number) {
    return authAxios.get<IrosIndicator>(`${this.baseUrlIros}/get-by-id-iros-indicators?id=${indicatorId}`);
  }
  static uploadFile(formData: FormData, id?: number) {
    if (id) {
      return authAxios.post<number>(`${this.baseUrlIros}/upload-iros-student-application-file?id=${id}`, formData);
    } else {
      return authAxios.post<number>(`${this.baseUrlIros}/upload-iros-student-application-file`, formData);
    }
  }

  static createStudentApplication(irosStudentApplicationsDtoRequest: StudentIrosApplication) {
    return authAxios.post<StudentIrosApplication>(`${this.baseUrlIros}/create-iros-student-applications`, {
      ...irosStudentApplicationsDtoRequest,
    });
  }

  static gradeStudentApplication(irosStudentConfirmationDtoRequest: IrosStudentConfirmedByTeacherDtoRequest) {
    return authAxios.put<IrosStudentConfirmedByTeacherDto>(
      `${this.baseUrlIros}/create-iros-student-confirmed-by-teacher`,
      {
        ...irosStudentConfirmationDtoRequest,
      }
    );
  }

  static deleteStudentApplication(id: number) {
    return authAxios.delete(`${this.baseUrlIros}/delete-iros-student-confirmed?id=${id}`);
  }

  static getAllGradesForTeacher() {
    return authAxios.get<IrosStudentConfirmedByTeacherDto[]>(`${this.baseUrlIros}/get-all-by-teacher`);
  }

  static getStudentApplicationDocumentAsPdf(userId: number, langId: number) {
    return downloadFile(
      `${this.baseUrlIros}/get-student-curriculum-for-next-year-pdf?user_id=${userId}&langId=${langId}`,
      'GET',
      'blob',
      `${userId}`,
      'pdf'
    );
  }

  static getApplicationsForStudent() {
    return authAxios.get<IrosApplication[]>(`${this.baseUrlIros}/get-iros-student-applications`);
  }



  static getPersonalIrosApplicationsForStudent(studentId: number) {
    return authAxios.get<IrosApplication[]>(
      `${this.baseUrlIros}/get-approved-personal-iros-student-applications?studentId=${studentId}`
    );
  }

  static getApplicationsForOffice(page: any, pageSize: any) {
    return authAxios.get<{ list: IrosApplicationProjection[]; total_number: number }>(
      `${this.baseUrlIros}/get-all-iros-student-applications?page=${page}&pageSize=${pageSize}`
    );
  }
  static getApplicationsByFilter(page: any, pageSize: any, params: any) {
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }
    return authAxios.get<{ list: IrosApplicationProjection[]; total_number: number }>(
      `${this.baseUrlIros}/get-all-iros-student-applications?page=${page}&pageSize=${pageSize}`, { params }
    );
  }

  static updateStudentApplication(irosStudentApplicationsDto: any) {
    for (const key of Object.keys(irosStudentApplicationsDto)) {
      if (!irosStudentApplicationsDto[key]) {
        delete irosStudentApplicationsDto[key];
      }
    }

    return authAxios.post<StudentIrosApplication>(`${this.baseUrlIros}/update-iros-student-applications`, {
      ...irosStudentApplicationsDto,
    });
  }

  static getVedomostForTeacher(
    year: number | undefined,
    term: number | undefined,
    groupId: number | undefined,
    disciplineId: number | undefined
  ) {
    return authAxios.get(
      `/astanait-office-module/api/v1/iros/get-iros-student-confirmed-by-teacher-table-by-filter?year=${year}&term=${term}&groupId=${groupId}&disciplineId=${disciplineId}`
    );
  }

  static getIrosPointForStudent(studentId: number) {
    return authAxios.get<IrosStudentPoint>(`${this.baseUrlIros}/get-student-iros?student_id=${studentId}`);
  }
  static createIrosIndicators(IrosIndicator: IrosIndicator) {
    return authAxios.post<IrosIndicator>(`${this.baseUrlIros}/create-iros-indicators`, { ...IrosIndicator });
  }
  static updateIrosIndicators(IrosIndicator: IrosIndicator) {
    return authAxios.post<IrosIndicator>(`${this.baseUrlIros}/create-iros-indicators`, { ...IrosIndicator });
  }
  static createIrosIndicatorsPoint(IrosIndicatosPoint: IrosIndicatosPoint) {
    return authAxios.post<IrosIndicatosPoint>(`${this.baseUrlIros}/create-iros-indicators-points`, {
      ...IrosIndicatosPoint,
    });
  }
  static getIrosPointByIndicatorId(IndicatorId: number) {
    return authAxios.get<IrosStatusPoint[]>(
      `${this.baseUrlIros}/get-iros-indicators-points-by-indicatorid?id=${IndicatorId}`
    );
  }

  static getIrosIndicatorsByTypeAndIsActive(irosTypeId: number) {
    return authAxios.get<IrosIndicator[]>(
      `${this.baseUrlIros}/get-by-type-and-is-active-iros-indicators?iros_types_id=${irosTypeId}&is_active=true`
    );
  }

  static getIndexStatusByIndicatorsIdAndIsActive(indicatorId: number
    //, isActive: boolean
  ) {
    return authAxios.get<IrosStatus[]>(
      //`${this.baseUrlIros}/get-iros-indicators-points-by-indicator-id?iros_type_id=${indicatorId}&is_active=${isActive}`
      `${this.baseUrlIros}/get-all-index-status-by-iros-indicators-id-and-is-active?id=${indicatorId}&isActive=true`
    );
  }

  static getIrosResults() {
    return authAxios.get<IrosApplicationResult[]>(`${this.baseUrlIros}/get-all-iros-results`);
  }

  static getIrosResultsExcel() {
    return downloadFile(`${this.baseUrlIros}/get-all-iros-results-excel`, 'GET', 'blob', 'Результаты iROS', 'xlsx');
  }
}
