import authAxios from '../../common/authAxios';
import { IllnessCertificateDtoResponse } from '../../interfaces/deansOffice/IllnessCertificateDtoResponse';
import { IllnessCertificateOfStudentDtoResponse } from '../../interfaces/deansOffice/IllnessCertificateOfStudentDtoResponse';
import { IllPersonDtoResponse } from '../../interfaces/deansOffice/IllPersonDtoResponse';

export class IllnessCertificateService {
  private static endpointPrefix: string = '/astanait-deans-module/api/v1/illness-certificate';

  static uploadDocument(formData: FormData) {
    return authAxios.post(`${this.endpointPrefix}/upload`, formData);
  }

  static getStudentStatus() {
    return authAxios.get<IllnessCertificateOfStudentDtoResponse[]>(`${this.endpointPrefix}/all/student/logged`);
  }

  static downloadDocumentOfficePermission(dto: IllPersonDtoResponse) {
    return authAxios({
      url: `${this.endpointPrefix}/download?id=${dto.illnessCertificate.id}`,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileExtension = response.headers['content-disposition'].split('.')[1].slice(0, -1);
        link.setAttribute(
          'download',
          `${dto.student.surnameEn}_${dto.student.nameEn}_${dto.student.group.title}_medical_certificate_${dto.illnessCertificate.id}.${fileExtension}`
        );
        document.body.appendChild(link);
        link.click();
      })
  }

  static downloadDocument(id: number) {
    return authAxios({
      url: `${this.endpointPrefix}/download?id=${id}`,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileExtension = response.headers['content-disposition'].split('.')[1].slice(0, -1);
        link.setAttribute('download', `medical_certificate_${id}.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
  }

  static approveCertificate(certificateId: number) {
    return authAxios.put(`${this.endpointPrefix}/approve?certificateId=${certificateId}`);
  }

  static rejectCertificate(certificateId: number) {
    return authAxios.put(`${this.endpointPrefix}/reject?certificateId=${certificateId}`);
  }

  static updateCertificateInfo(dto: IllnessCertificateDtoResponse) {
    return authAxios.put(`${this.endpointPrefix}/update-info`, dto);
  }
}
