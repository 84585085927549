import { SaveOutlined } from "@ant-design/icons";
import { Button, notification, Space, Table, Tag } from "antd"
import { TeacherCertificateDtoResponse } from "../../../../interfaces/Teacher/TeacherCertificateDtoResponse";
import AuthService from "../../../../services/authService";
import { FileService } from "../../../../services/file/FileService";


interface Props {
    dataSource?: TeacherCertificateDtoResponse[];
}

const PublicTeacherCertificatesTable = ({ dataSource }: Props) => {

    const handleDownloadCertificate = (id: number) => {
        FileService.getFileById(id, "Teacher certificate")
            .then(() => notification.success({ message: "Certificate was successfully downloaded!" }))
    }

    const columns = AuthService.isLoggedIn() ? [
        {
            title: "#",
            dataIndex: "key",
            key: "key"
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description"
        },
        {
            title: 'Certificate type',
            dataIndex: 'certificateType',
            key: 'certificateType',
            render: (record: any) => {
                switch (record) {
                    case 'INTERNSHIP':
                        return <Tag color={'blue'}>{'Internship'}</Tag>;
                    case 'QUALIFICATION':
                        return <Tag color={'blue'}>{'Qualification'}</Tag>;
                    case 'OTHER':
                        return <Tag color={'blue'}>{'Other'}</Tag>;
                    default:
                        return <></>;
                }
            },
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            render: (_: any, record: any) => {
                return (
                    <Space>
                        <Button icon={<SaveOutlined />} onClick={() => { handleDownloadCertificate(record.fileId) }}>
                            Download certificate
                        </Button>
                    </Space>
                )
            }
        },
    ] : [
        {
            title: "#",
            dataIndex: "key",
            key: "key"
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description"
        },
        {
            title: 'Certificate type',
            dataIndex: 'certificateType',
            key: 'certificateType',
            render: (record: any) => {
                switch (record) {
                    case 'INTERNSHIP':
                        return <Tag color={'blue'}>{'Internship'}</Tag>;
                    case 'QUALIFICATION':
                        return <Tag color={'blue'}>{'Qualification'}</Tag>;
                    case 'OTHER':
                        return <Tag color={'blue'}>{'Other'}</Tag>;
                    default:
                        return <></>;
                }
            },
        },
    ]

    return (
        <>
            <Table bordered columns={columns} dataSource={
                dataSource?.map((certificate, i) => ({
                    id: dataSource[i].id,
                    key: i + 1,
                    description: certificate.description,
                    certificateType: certificate.certificateType,
                    fileId: certificate.fileId,
                }))
            } />
        </>
    )
}

export default PublicTeacherCertificatesTable