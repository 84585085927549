import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Input,
  List,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import Spinner from '../../../ui/Spinner';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import PageWrapper from '../../../ui/PageWrapper';
import AuthenticatedContent from '../../../common/AuthenticatedContent';
import useHr from './useHR';
import { useHistory } from 'react-router-dom';
import FormItem from 'antd/es/form/FormItem';
import AuthService from '../../../services/authService';

export default function TeachersPage() {
  const { data, handlers } = useHr();

  const history = useHistory();
  if (!data.teachers) {
    return <Spinner size={'default'} />;
  }

  return (
    <PageWrapper>
      <Space
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography.Title>Academic staff</Typography.Title>

        <AuthenticatedContent role={'OFFICE'} type={'baseRole'}>
          <AuthenticatedContent role={'HR'} type={'addRole'}>
            <Button type="primary" onClick={() => data.history.push('/create-teacher')}>
              Add professor
            </Button>
          </AuthenticatedContent>
        </AuthenticatedContent>
      </Space>

      <Row gutter={[20, 20]}>
        <Col xl={6} xs={24}>
          <Form form={data.form} onFinish={(e: any) => handlers.handleParams(e)}>
            <Card bodyStyle={{ margin: 0, padding: 0 }}>
              <Collapse bordered={false} defaultActiveKey={['1']} expandIconPosition={'end'} ghost>
                <Collapse.Panel
                  header={<Typography.Title level={3}>Filter</Typography.Title>}
                  key="1"
                  style={{ borderBottom: 'none' }}
                >
                  <Space direction="vertical" style={{ width: '100%' }} size={'large'}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Typography.Title level={5}>Department</Typography.Title>

                      <FormItem name={'departmentId'} style={{ margin: 0 }}>
                        <Select placeholder={'Choose the department'}>
                          <Select.Option key={0} value={0}>
                            {'All'}
                          </Select.Option>
                          {data.deps.map((dep) => (
                            <Select.Option key={dep.id} value={dep.id}>
                              {dep.titleEn}
                            </Select.Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Space>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Typography.Title level={5}>Scientific interest</Typography.Title>
                      <FormItem name="scientificInterests" style={{ margin: 0 }}>
                        <Input
                          allowClear
                          placeholder="Enter the scientific interest"
                        />
                      </FormItem>
                    </Space>

                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Typography.Title level={5}>Taught disciplines</Typography.Title>
                      <FormItem name="taughtCourse" style={{ margin: 0 }}>
                        <Input
                          allowClear
                          placeholder="Enter the taught disciplines"
                        />
                      </FormItem>
                    </Space>

                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Typography.Title level={5}>Article</Typography.Title>
                      <FormItem name="article" style={{ margin: 0 }}>
                        <Input
                          allowClear
                          placeholder="Enter the article information"
                        />
                      </FormItem>
                    </Space>

                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Typography.Title level={5}>Research Project</Typography.Title>
                      <FormItem name="scientificProject" style={{ margin: 0 }}>
                        <Input
                          allowClear
                          placeholder="Enter the research project information"
                        />
                      </FormItem>
                    </Space>

                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Typography.Title level={5}>Experience</Typography.Title>
                      <FormItem name={'experience'} style={{ margin: 0 }}>
                        <Input
                          allowClear
                          placeholder="Enter the work experience information"
                        />
                      </FormItem>
                    </Space>

                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Typography.Title level={5}>Main developments/ prototypes</Typography.Title>
                      <FormItem name={'development'} style={{ margin: 0 }}>
                        <Input
                          allowClear
                          placeholder="Enter the main developments/ prototypes name"
                        />
                      </FormItem>
                    </Space>

                    <Space direction="vertical" style={{ width: '100%' }}>
                      <FormItem>
                        <Button icon={<SearchOutlined />} type="primary" style={{ width: '100%' }} htmlType="submit">
                          Search
                        </Button>
                      </FormItem>
                    </Space>
                  </Space>
                </Collapse.Panel>
              </Collapse>
            </Card>
          </Form>
        </Col>
        <Col xl={18} xs={24}>
          <Card>
            <Input.Search
              size="large"
              style={{ width: '100%', marginBottom: 20, borderRadius: 10 }}
              placeholder="Поиск по ФИО"
              onSearch={(value) => handlers.handleParams({ fullName: value, ...data.form.getFieldsValue() })}
            />
            <List size="large"
              dataSource={data.teachers.list}
              loading={data.loading}
              pagination={{
                current: data.teachers.current_page === 0 ? 0 : data.teachers.current_page + 1,
                total: data.teachers.total_number,

                onChange: (page) => {
                  handlers.setParams({
                    page: page === 0 ? 0 : page - 1,
                  });
                }
              }}
              renderItem={(item: any, i) => (
                <Row
                  key={i}
                >
                  <Col span={22}>
                    <List.Item
                      onClick={() => {
                        AuthService.isLoggedIn()
                          ? history.push(`/teacher/${item.userId}`)
                          : history.push(`/public/teacher/${item.userId}`);
                      }}
                      style={{ cursor: 'pointer', width: "100%" }}
                    >
                      <List.Item.Meta
                        avatar={<Avatar src={''} icon={<UserOutlined />} />}
                        title={`${item.surnameKz ? item.surnameKz : ''} ${item.nameKz ? item.nameKz : ''} ${item.patronymicKz ? item.patronymicKz : ''}`}
                        description={`${item.department?.titleEn ?? 'No department'}`}
                      />
                    </List.Item>
                  </Col>
                  <Col span={2}>
                    <Space style={{ height: "100%" }}>
                      <AuthenticatedContent role={'HR'} type={'addRole'}>
                        <Button
                          danger
                          onClick={() => handlers.handleDeleteTeacher(item.id)}
                        >
                          Retire
                        </Button>
                      </AuthenticatedContent>
                    </Space>
                  </Col>
                </Row>
              )}>
            </List>
          </Card>
        </Col>
      </Row >
    </PageWrapper >
  );
}
