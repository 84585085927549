import { Table, Tag } from 'antd'
import React from 'react'
import { TeacherWorkExpDto } from '../../../../interfaces/Teacher/TeacherWorkExpDto';

interface Props {
    dataSource?: TeacherWorkExpDto[];
}

const PublicTeacherWorkExpTable = ({ dataSource }: Props) => {

    const columns = [
        {
            title: "#",
            dataIndex: "key",
            key: "key"
        },
        {
            title: 'Organization name',
            dataIndex: 'organizationName',
            key: 'organizationName',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Lenght of service',
            dataIndex: 'lengthOfService',
            key: 'lengthOfService',
        },
        {
            title: "From",
            dataIndex: "yearFrom",
            key: "yearFrom"
        },
        {
            title: "To",
            dataIndex: "yearTo",
            key: "yearTo"
        },
        {
            title: 'Experience type',
            dataIndex: 'type',
            key: 'type',
            render: (record: any) => {
                switch (record) {
                    case 'GENERAL':
                        return <Tag color={'blue'}>{'General'}</Tag>;
                    case 'PEDAGOGICAL':
                        return <Tag color={'blue'}>{'Pedagogical'}</Tag>;
                    case 'PROFILE':
                        return <Tag color={'blue'}>{'Profile'}</Tag>;
                    default:
                        return <></>;
                }
            },
        },
    ]


    return (
        <Table bordered columns={columns}
            dataSource={dataSource?.map((workExp, i) => ({
                id: workExp.id,
                key: i + 1,
                address: workExp.address,
                position: workExp.position,
                organizationName: workExp.organizationName,
                lengthOfService: workExp.lengthOfService,
                yearFrom: workExp.yearFrom,
                yearTo: workExp.yearTo,
                type: workExp.type,
            }))}
            style={{
                overflowX: "auto"
            }}
        />
    )
}

export default PublicTeacherWorkExpTable