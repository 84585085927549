import { List, Space, Typography } from 'antd';

interface CertificateInfoListProps {
  disabled?: boolean;
  data: any[];
  loading: boolean;
}

const CertificateInfoList = ({ disabled, data, loading }: CertificateInfoListProps) => {
  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        marginTop: 40,
        display: disabled ? 'none' : 'block',
      }}
    >
      <Typography.Title level={3}>Информация о слушателе</Typography.Title>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item key={item.dataIndex} style={{ borderTop: '1px solid grey' }}>
            <List.Item.Meta title={<Typography.Text type="secondary">{item.field}</Typography.Text>} />
            <Typography.Text>
              {item.dataIndex === 'isActive' && item.content ? (item.content === true ? 'Активен' : 'Просрочен') : item.content}
            </Typography.Text>
          </List.Item>
        )}
      />
    </Space>
  );
};

export default CertificateInfoList;
