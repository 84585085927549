/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Button, Popconfirm, Space, notification } from 'antd';
import { Tag } from 'antd';
import { AppointmentDatesDtoResponse } from '../../../interfaces/AppointmentDatesDtoResponse';
import { PsychologistService } from '../../../services/PsychologistService';
import './appointment.css';
import { successNotification } from '../../../helpers/successNotification';
import { errorNotification } from '../../../helpers/errorNotification';

const usePsychologistAppointmentPage = () => {
  const [dates, setDates] = useState<AppointmentDatesDtoResponse[]>([]);
  const [appointment, setAppointment] = useState<AppointmentDatesDtoResponse[]>([]);
  const [selectedPsychologist, setSelectedPsychologist] = useState<number>(0);

  const confirm = (value1: any, value: any) => {
    PsychologistService.createAppointment({
      dateTime: value1 + 'T' + value + ':00',
      targetUserId: selectedPsychologist,
    })
      .then(() => {
        successNotification('You successfully made an appointment!');
        fetchData(selectedPsychologist);
      })
      .catch((e) => {
        errorNotification(e.response.data.message);
      })
  };
  const deleteAppointment = (value: any) => {
    PsychologistService.deleteAppointment(value)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'You successfully made an appointment!',
          duration: 3.5,
        });
        fetchData(selectedPsychologist);
      })
  };

  const fetchData = (psychologistId: number) => {
    PsychologistService.getAllAppointments(psychologistId)
      .then((response) => {
        setDates(response.data);
      })

    PsychologistService.getStudentAppointments()
      .then(({ data }) => {
        setAppointment(data);
      });

    // UserService.getProfileByPrincipal().then(({ data }) => {
    //   PsychologistService.getAppointmentByUserId(data.student.userId).then(({ data }) => {
    //     setAppointment(data);
    //   });
    // });
  };

  const handleSelectPsychologist = (value: number) => {
    setSelectedPsychologist(value);
    fetchData(value);
  }

  const userAppointmentsColumns = [
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
    },

    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (_: any, record: any) => (
        <>
          <Tag color={'green'}>{record.time.toUpperCase()}</Tag>
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_: any, record: any) => (
        <>
          <Tag color={'green'}>{record.date.toUpperCase()}</Tag>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: any) => (
        <>
          {record.status === "Canceled" &&
            <p className="active">{record.status}</p>
          }
          {record.status === "Сreated" &&
            <p className="create"> Waiting for confirmation </p>
          }
          {record.status === "Accepted" &&
            <p className="accept">{record.status}</p>
          }

        </>
      ),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (_: any, record: any) => (
        <>
          <p color={'green'}>{record.comment}</p>
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'delete',
      key: 'delete',
      render: (_: any, record: any) => (
        <>
          {
            <Popconfirm
              placement="topLeft"
              title={'Отменить запись?'}
              onConfirm={() => deleteAppointment(record.id)}
              okText="Yes"
              cancelText="No" >
              <a>Cancel</a>
            </Popconfirm>
          }
        </>
      ),
    },
  ];

  const appointmentsColumns = [
    {
      dataIndex: 'weekDay',
      key: 'weekDay',
    },
    {
      dataIndex: 'date',
      key: 'date',
    },
    {
      dataIndex: 'times',
      key: 'times',
      render: (_: any, record: any) => (
        <Space style={{ width: "100%", display: "flex", flexDirection: "row" }} size={"middle"}>
          {record.times.map((time: any, index: number) => (
            <Space key={index}>
              <Popconfirm
                placement="topLeft"
                title={'Confirm an appointment?'}
                onConfirm={() => confirm(record.date, time.time)}
                disabled={time.status !== 'Available'}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  disabled={time.status !== 'Available'}
                  type='primary'
                >
                  {time.time.toUpperCase()}
                </Button>
              </Popconfirm>
            </Space>
          ))}
        </Space>
      ),
    },
  ];

  const filteredUserAppointments = appointment.map((appointment) => ({
    id: appointment.id,
    appointment: new Date(appointment.dateTime).toISOString().replace(/T.*/, '') + ' ' + new Date(appointment.dateTime).toLocaleTimeString(),
    student: appointment.studentFIO,
    group: appointment.group,
    comment: appointment.comment,
    status: appointment.status.statusTitle,
    time: new Date(appointment.dateTime).toLocaleTimeString('en', {
      timeStyle: 'short',
    }),
    date: new Date(appointment.dateTime).toLocaleDateString(),
  }));

  return {
    columns: {
      userAppointmentsColumns,
      appointmentsColumns,
    },
    data: { filteredUserAppointments, dates, selectedPsychologist },
    handleSelectPsychologist,
  };
};

export default usePsychologistAppointmentPage;
