import { Typography, Table, Space } from 'antd';
import { useEffect, useState } from 'react';
import { CertificateIssuanceRequestDtoResponse } from '../../../../interfaces/deansOffice/CertificateIssuanceRequestDtoResponse';
import { CertificateIssuanceService } from '../../../../services/deansOffice/CertificateIssuanceService';
import Page from '../../../../ui/Page/Page';
import Center from '../../../../ui/Center';
import RowSpan from '../../../../ui/RowSpan';

const { Text } = Typography;
interface Props {
  certificateId: number;
}

const CertificateWebPage = ({ certificateId }: Props) => {
  const [certificate, setCertificate] = useState<CertificateIssuanceRequestDtoResponse>();

  const columns: any = [
    {
      title: 'Full name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Position',
      dataIndex: 'status',
      key: 'status',
      width: 250,
    },
    {
      title: 'Date and time of signing',
      dataIndex: 'date',
      key: 'date',
    },
  ];
  useEffect(() => {
    CertificateIssuanceService.getIssuanceByIdPublic(certificateId)
      .then((response) => {
        setCertificate(response.data);
      });
  }, [certificateId]);

  const getFullDate = (val: string): string => {
    const dateAndTime = val.split('T');
    const date = dateAndTime[0].split('-').reverse().join('-');
    const time = dateAndTime[1].split('.')[0];
    return date + ' ' + time;
  };

  return (
    <Page>
      <Center style={{ marginBottom: '50px' }}>
        <>
          <div className="logo-container"></div>
          <Space style={{ width: '100%', justifyContent: 'center' }}>
            <Typography.Title level={2}>Astana IT University</Typography.Title>
          </Space>
        </>
      </Center>
      {certificate && (
        <RowSpan
          left={
            <Space direction="vertical">
              <div className=" col-md-12">
                <div className="col-md-12 text">
                  <u>
                    <strong>
                      <em className="ng-scope">Application details</em>
                    </strong>
                  </u>
                </div>
              </div>
              <div className=" col-md-12">
                <Text strong className="col-md-4 label-colon ng-scope">
                  Full name of the student:
                </Text>
                <Text>{certificate.student.nameEn + ' ' + certificate.student.surnameEn}</Text>
              </div>

              <div className=" col-md-12">
                <Text strong className="col-md-4 label-colon ng-scope">
                  IIN:
                </Text>
                <Text>{certificate.student.iin}</Text>
              </div>

              <div className=" col-md-12">
                <Text strong className="col-md-4 label-num-sign ng-scope">
                  Application:
                </Text>
                <Text>{certificate.number}</Text>
              </div>
              <div className=" col-md-12">
                <Text strong className="col-md-4 label-colon ng-scope">
                  Service name:
                </Text>
                <Text>{certificate.type.name}</Text>
              </div>

              <div className=" col-md-12">
                <Text strong className="col-md-4 label-colon ng-scope">
                  Application status:
                </Text>
                <Text>Given</Text>
              </div>

              <div className=" col-md-12">
                <Text strong className="col-md-4 label-colon ng-scope">
                  Date and time of assignment of the status:
                </Text>
                <Text>{getFullDate(certificate.issuedDate.toString())}</Text>
              </div>
            </Space>
          }
          right={
            <Space direction="vertical">
              <div>
                <div>
                  <u>
                    <strong>
                      <em>Information about the performer</em>
                    </strong>
                  </u>
                </div>
              </div>
              <Table
                pagination={false}
                columns={columns}
                dataSource={[
                  {
                    name: 'Kumalakov Bolatzhan Armenovich',
                    status: 'Vice-Rector for Academic and Educational Work',
                    date: getFullDate(certificate.issuedDate.toString()),
                  },
                ]}
              />
            </Space>
          }
        />
      )}
    </Page>
  );
};

export default CertificateWebPage;
