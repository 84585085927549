import { Button, Col, Form, Modal, Row, Select, Table } from 'antd';
import Page from '../../../ui/Page/Page';
import IrosTeachersTable from './IrosTeachersTable';
import useIrosTeachersPage from './useIrosTeachersPage';
import useModal from '../../../ui/Modal/useModal';

const IrosTeachersPage = () => {
  const { handlers, data } = useIrosTeachersPage();
  const { isModalOpen, handleOk, handleCancel } = useModal();

  return (
    <Page title="IROS" center width={800}>
      <Form form={data.form} onFinish={handlers.handleSearchStatement}>
        <Form.Item name={'year'} rules={[{ required: true, message: 'Please, choose education year' }]}>
          <Select
            loading={data.loading}
            disabled={data.loading}
            placeholder={'Choose education year'}
            allowClear
            onSelect={handlers.handleSelectYear}
            onClear={() => {
              data.form.setFieldsValue({
                year: null,
                term: null,
                disciplineId: null,
                groupId: null,
              });
              data.setDisciplines([]);
              data.setGroups([]);
              data.setSelectedDisciplineId(null);
            }}
          >
            {data.years.map((year) => (
              <Select.Option key={year.id} value={year.year}>
                {year.year}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'term'} rules={[{ required: true, message: 'Please, choose trimester' }]}>
          <Select
            loading={data.loading}
            disabled={data.loading}
            placeholder={'Choose trimester'}
            onSelect={handlers.handleSelectTrim}
            allowClear
            onClear={() => {
              data.form.setFieldsValue({
                term: null,
                disciplineId: null,
                groupId: null,
              });
              data.setDisciplines([]);
              data.setGroups([]);
              data.setSelectedDisciplineId(null);
            }}
          >
            {data.trimesters.map((trimester) => (
              <Select.Option key={trimester.id} value={trimester.trimester}>
                {trimester.trimester}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'disciplineId'} rules={[{ required: true, message: 'Please, choose discipline' }]}>
          <Select
            loading={data.loading}
            disabled={data.loading}
            placeholder={'Choose discipline'}
            onSelect={handlers.handleSelectDiscipline}
            allowClear
            onClear={() => {
              data.form.setFieldsValue({
                disciplineId: null,
                groupId: null,
              });
              data.setGroups([]);
              data.setSelectedDisciplineId(null);
            }}
          >
            {data.disciplines.map((discipline) => (
              <Select.Option key={discipline.id} value={discipline.id}>
                {discipline.titleEn}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'groupId'} rules={[{ required: true, message: 'Please, choose group' }]}>
          <Select
            loading={data.loading}
            disabled={data.loading}
            allowClear
            placeholder={'Choose group'}
            onClear={() =>
              data.form.setFieldsValue({
                groupId: null,
              })
            }
          >
            {data.groups.map((group) => (
              <Select.Option key={group.id} value={group.id}>
                {group.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Row gutter={16}>
            <Col>
              <Button type="primary" htmlType="submit" loading={data.loading}>
                Поиск
              </Button>
            </Col>
            <Col>
              {/* <Button
                loading={data.loading}
                icon={<TableOutlined />}
                onClick={showModal}
                disabled={!!!data.studentsData}
              >
                Выставленные оценки
              </Button> */}
            </Col>
          </Row>
        </Form.Item>
      </Form>
      <Modal title="Все оценки" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={800}>
        <Table dataSource={data.filteredAllGradesData} columns={data.allGradesColumns} />
      </Modal>
      {data.studentsData.length > 0 && (
        <IrosTeachersTable
          studentsData={data.filteredData}
          loading={data.loading}
          selectedDisciplineId={data.selectedDisciplineId}
          onSubmit={handlers.handleSearchStatement}
        />
      )}
    </Page>
  );
};

export default IrosTeachersPage;
