import { useState, useEffect } from 'react';
import { IrosIndicator } from '../../../interfaces/Iros/IrosIndicator';
import { IrosService } from '../../../services/student/IrosService';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Button, Space } from 'antd';
import styles from './style.module.css';

const useIros = () => {
  const [indicators, setIndicators] = useState<IrosIndicator[]>([]);

  useEffect(() => {
    IrosService.getIndicators()
      .then(({ data }) => setIndicators(data));
  }, []);

  const columns = [
    {
      title: '№',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Indicator',
      dataIndex: 'indicator',
      key: 'indicator',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button className={styles.deleteButton}>
            <DeleteTwoTone twoToneColor="#ff0000" />
          </Button>
          <Button href={`/indicators/edit/${record.key}`}>
            <EditTwoTone />
          </Button>
          <Button href={`/iros-indicator/indicator-id/${record.key}/${record.indicator}`}>Редактировать Баллы</Button>
        </Space>
      ),
    },
  ];

  return {
    indicators,
    columns,
  };
};

export default useIros;
