import { DeleteTwoTone, DownloadOutlined, EditTwoTone } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useEffect, useState } from 'react';
import styles from './style.module.css';
import { AgreementService } from '../../../services/AgreementService';
import { AgreementTypeDtoResponse } from '../../../interfaces/AgreementTypeDtoResponse';


const useAgreementPage = () => {
    const [shouldRerender, setShouldRerender] = useState(false);
    const [agreementType, setAgreementType] = useState<AgreementTypeDtoResponse[]>([]);

    const onDeleteAgreementType = (agreementType: number) => {
        AgreementService.deleteAgreementType(agreementType)
            .then(() => setShouldRerender(!shouldRerender))
            .catch((error) => console.error(error));
    };

    useEffect(() => {

        AgreementService.getAllAgreementType()
            .then((response) => {
                console.log(response)
                setAgreementType(response.data);
            })
            .catch((error) => console.error(error));
    }, [shouldRerender]);



    const column = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Type name in KZ',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Type name in RU',
            dataIndex: 'nameRu',
            key: 'nameRu',

        },
        {
            title: 'Type name in EN',
            dataIndex: 'nameEn',
            key: 'nameEn',

        },

        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            width: 120,
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button className={styles.deleteButton} onClick={() => onDeleteAgreementType(record.key)}>
                        <DeleteTwoTone twoToneColor="#ff0000" />
                    </Button>
                    <Button href={`/agreement-type/edit/${record.key}`}>
                        <EditTwoTone />
                    </Button>
                </Space>
            ),
        },
    ]
    return {
        agreementType,
        column,

    };
};

export default useAgreementPage;