import { Typography, Form, Select, Input, DatePicker, Button, Space, Row, Col, Upload } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { RuleObject } from 'rc-field-form/lib/interface';
import { StoreValue } from 'rc-field-form/lib/interface';

import PageWrapper from '../../../../ui/PageWrapper';
import BackButton from '../../../../ui/BackButton';
import { errorNotification } from '../../../../helpers/errorNotification';
import { successNotification } from '../../../../helpers/successNotification';
import { ForeignUniversitiesDtoResponse } from '../../../../interfaces/ForeignUniversitiesDtoResponse';
import { ForeignUniversitiesService } from '../../../../services/ForeignUniversitiesService';
import { AgreementService } from '../../../../services/AgreementService';
import { AgreementTypeDtoResponse } from '../../../../interfaces/AgreementTypeDtoResponse';
import { AgreementDtoRequest } from '../../../../interfaces/AgreementDtoRequest';
import { FileService } from '../../../../services/file/FileService';

const { Title } = Typography;
const { Option } = Select;

interface Props {

    agreementId?: number;
}



const AgreementCreationPage = ({ agreementId }: Props) => {

    const [form] = Form.useForm();
    const [universities, setUniversities] = useState<ForeignUniversitiesDtoResponse[]>([]);
    const [searchResults, setSearchResults] = useState<ForeignUniversitiesDtoResponse[]>([]);
    const [agreementType, setAgreementType] = useState<AgreementTypeDtoResponse[]>([]);
    const [showResults, setShowResults] = useState<boolean>(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const [existingFileId, setExistingFileId] = useState<number | null>(null);
    const [existingFileName, setExistingFileName] = useState<string | null>(null);

    const styles = { width: '800px', margin: '0 auto' };



    useEffect(() => {
        ForeignUniversitiesService.getAllForeignUniversities()
            .then((response) => setUniversities(Array.isArray(response.data) ? response.data : [response.data]))
            .catch((error) => console.error(error));

    }, []);
    useEffect(() => {
        AgreementService.getAllAgreementType()
            .then((response) => setAgreementType(Array.isArray(response.data) ? response.data : [response.data]))
            .catch((error) => console.error(error));
    }, []);


    const handleFormValuesChange = (changedValues: any) => {
        const [fieldName] = Object.keys(changedValues);
        const value = changedValues[fieldName];
        if (fieldName === 'universityId' && typeof value === 'string') {
            const filteredUniversities = universities.filter(university =>
                university.nameEn.toLowerCase().includes(value.toLowerCase())
            );
            setSearchResults(filteredUniversities);
            setShowResults(true);
        }
    };

    const handleUniversitySelect = (value: number) => {
        form.setFieldsValue({ universityId: value });
        setShowResults(false);
    };

    const onFinish = (agreement: AgreementDtoRequest) => {
        const formData = new FormData();
        if (fileList.length > 0) {
            formData.append('file', fileList[0].originFileObj);
        }
        formData.append('academicMobilityAgreementDtoRequest', new Blob([JSON.stringify(agreement)], { type: "application/json" }));

        agreementId
            ? AgreementService.updateAgreement(agreementId, formData)
                .then(() => successNotification('Данные успешно обновлены'))
                .catch((err) => errorNotification('Не удалось обновить данные', err.response?.status))
            :
            AgreementService.createAgreement(formData)
                .then(() => {
                    console.log(agreement);
                    successNotification('Данные успешно сохранены');
                })
                .catch((err) => errorNotification('Не удалось сохранить данные', err.response?.status));
    };

    const [oldEndDate, setOldEndDate] = useState<moment.Moment | null>(null);
    const [oldStartDate, setOldStartDate] = useState<moment.Moment | null>(null);

    useEffect(() => {
        if (agreementId) {
            AgreementService.getAgreementById(agreementId)
                .then(({ data }) => {
                    form.setFieldsValue({
                        university: data.university ? data.university.id : '',
                        startDate: data.startDate ? moment(data.startDate) : null,
                        endDate: data.endDate ? moment(data.endDate) : null,
                        agreementType: data.agreementType ? data.agreementType.id : '',
                        agreementSubjectKz: data.agreementSubjectKz,
                        agreementSubjectRu: data.agreementSubjectRu,
                        agreementSubjectEn: data.agreementSubjectEn
                    });
                    setOldStartDate(data.startDate ? moment(data.startDate) : null);
                    setOldEndDate(data.endDate ? moment(data.endDate) : null);
                    setExistingFileId(data.fileId || null);
                    setExistingFileName(data.fileName || 'agreement_file.pdf'); // Убедитесь, что в вашем ответе есть имя файла
                })
                .catch((err) => {
                    errorNotification('Не удалось получить данные', err.response?.status);
                });
        }
    }, [form, agreementId]);

    const handleStartDateChange = (date: moment.Moment | null, dateString: string) => {
        if (date) {
            setOldStartDate(date);
            form.setFieldsValue({ startDate: date });
        }
    };

    const handleEndDateChange = (date: moment.Moment | null, dateString: string) => {
        if (date) {
            setOldEndDate(date);
            form.setFieldsValue({ endDate: date });
        }
    };

    const handleFileChange = ({ file, fileList }: any) => {
        if (file.type !== 'application/pdf') {
            errorNotification('Неверный формат файла', 'Допускаются только PDF файлы');
            return;
        }
        if (fileList.length > 1) {
            fileList = [fileList[fileList.length - 1]];
        }
        setFileList(fileList);
        setExistingFileId(null);
        setExistingFileName(null);
        form.setFieldsValue({ file });
    };

    const handleDownloadFile = () => {
        if (existingFileId && existingFileName) {
            FileService.getFileById(existingFileId, existingFileName)
                .catch((err) => errorNotification('Не удалось скачать файл', err.response?.status));
        }
    };

    const validateUpload = (_: RuleObject, value: StoreValue) => {
        if ((!fileList || fileList.length === 0) && !existingFileId) {
            return Promise.reject(new Error('Please upload a file'));
        }
        return Promise.resolve();
    };

    return (
        <PageWrapper>
            <BackButton />
            <header style={styles}>
                <Title level={3}>
                    {agreementId ? `Editing Agreement Data` : 'Adding new Agreement Data'}
                </Title>
            </header>

            <Form
                layout="vertical"
                size="large"
                form={form}
                style={styles}
                onFinish={onFinish}
                onValuesChange={handleFormValuesChange}
            >
                <Form.Item
                    label="University ID"
                    name="university"
                    rules={[{ required: true}]}
                >
                    <Select
                        showSearch
                        placeholder={'Выберите университет'}
                        onSearch={(value) => handleFormValuesChange({ universityId: value })}
                        onFocus={() => handleFormValuesChange({ universityId: '' })}
                        onBlur={() => setShowResults(false)}
                        onSelect={(value) => handleUniversitySelect(Number(value))}
                        filterOption={false}
                        
                    >
                        {showResults
                            ? searchResults.map((university) => (
                                <Option key={university.id} value={university.id}>
                                    {university.nameEn}
                                </Option>
                            ))
                            : universities.map((university) => (
                                <Option key={university.id} value={university.id}>
                                    {university.nameEn}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="Select Start Date"
                            name="startDate"
                            valuePropName='startDate'
                            rules={[{ required: true, message: "Введите дату" }]}
                        >
                            <DatePicker style={{ width: '90%' }}
                                format="YYYY-MM-DD"
                                onChange={handleStartDateChange}
                                value={oldStartDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Select End Date"
                            name="endDate"
                            valuePropName='endDate'
                            rules={[{ required: true, message: "Введите дату" }]}
                        >
                            <DatePicker style={{ width: '90%' }}
                                format="YYYY-MM-DD"
                                onChange={handleEndDateChange}
                                value={oldEndDate}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="agreementType" label="Agreement Type"
                rules={[{ required: true}]}
                >
                    <Select placeholder="Выберите тип соглашения">
                        {agreementType ? (
                            agreementType.map((data) => (
                                <Option key={data.id} value={data.id}>
                                    {`${data.name}`}
                                </Option>
                            ))
                        ) : null}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="agreementSubjectKz"
                    label="Agreement Subject (Kz)"
                    rules={[{ required: true, message: 'Введите предмет соглашения на казахском языке' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="agreementSubjectRu"
                    label="Agreement Subject (Ru)"
                    rules={[{ required: true, message: 'Введите предмет соглашения на русском языке' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="agreementSubjectEn"
                    label="Agreement Subject (En)"
                    rules={[{ required: true, message: 'Введите предмет соглашения на английском языке' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="file"
                    label="Upload File"
                    valuePropName="fileList"
                    rules={[{ validator: validateUpload }]}
                    getValueFromEvent={(e: any) => {
                        if (Array.isArray(e)) {
                            return e;
                        }
                        return e && e.fileList;
                    }}
                >
                    <Upload
                        name="file"
                        beforeUpload={() => false}
                        onChange={handleFileChange}
                        fileList={fileList}
                        accept=".pdf"
                    >
                        <Button icon={<UploadOutlined />}>Загрузить файл</Button>
                    </Upload>
                    {existingFileId && existingFileName && (
                        <div>
                            <Button type="primary" onClick={handleDownloadFile}>Скачать текущий файл</Button>
                        </div>
                    )}
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </PageWrapper>
    );
};

export default AgreementCreationPage;
