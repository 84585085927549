import { Select } from 'antd';
import { GradeFromTypeDtoResponse } from '../../../../interfaces/EducationalPrograms/GradeFromTypeDtoResponse';

const { Option } = Select;

type Options = GradeFromTypeDtoResponse[];

interface Props {
    placeholder: string;
    selectedOption: number | undefined;
    onSelect: (value: number) => void;
    options: Options;
    width?: number;
    onClear?: () => void;
}

const GradeFromTypeFilter = ({ placeholder, selectedOption, onSelect, options, width, onClear }: Props) => {
    return (
        <Select
            showSearch
            placeholder={placeholder}
            value={selectedOption}
            onSelect={onSelect}
            style={{ width: width ? width : 400 }}
            optionFilterProp="children"
            onClear={onClear}
            allowClear={!!onClear}
        >
            {options.map((option) => (
                <Option key={option.id} value={option.id}>
                    {option.titleRu}
                </Option>
            ))}
        </Select>
    );
};

export default GradeFromTypeFilter;
