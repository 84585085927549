import React, { useEffect, useState } from 'react'
import { SurveyService } from '../../../services/student/SurveyService';
import { successNotification } from '../../../helpers/successNotification';
import { StudentTeachersDto } from '../../../interfaces/Survey/StudentTeachersDto';
import { Form } from 'antd';
import { StudentSurveyAnswersDtoRequest } from '../../../interfaces/Survey/StudentSurveyAnswersDtoRequest';
import { SurveyQuestionsDtoResponse } from '../../../interfaces/Survey/SurveyQuestionsDtoResponse';
import { KpiService } from '../../../services/kpi/KpiService';


export const useSurvey = () => {
    const [form] = Form.useForm();

    const [answers, setAnswers] = useState<StudentSurveyAnswersDtoRequest[]>([]);
    const [teachers, setTeachers] = useState<StudentTeachersDto[]>([]);
    const [questions, setQuestions] = React.useState<SurveyQuestionsDtoResponse[]>([])

    const [currentStep, setCurrentStep] = React.useState(0);
    const [isEnd, setIsEnd] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);


    useEffect(() => {
        setIsLoading(true)
        Promise.all([
            SurveyService.getTeachers(),
            SurveyService.getQuestions(1)
        ])
            .then(([teachers, questions]) => {
                setTeachers(teachers.data)
                setQuestions(questions.data)
                // eslint-disable-next-line array-callback-return
                teachers.data.map((teacher) => {
                    // eslint-disable-next-line array-callback-return
                    questions.data.filter((question) => question.id !== 13 && question.id !== 12).map((question) => {
                        setAnswers((prevState) => [...prevState, {
                            academicStreamStudentId: teacher.academicStreamStudentId,
                            questionId: question.id,
                            answer: "",
                            surveyId: 1
                        }])
                    })
                })
            })
            .finally(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        KpiService.getApplicationSettings()
            .then(({ data: deadlineData }) =>
                SurveyService.isStudentAnswered(Number(deadlineData.find(v => v.isCurrent === true)?.id))
                    .then(({ data }) => setIsEnd(data))
            )

    }, [])


    const handleChange = (value: any, questionId: number, academicStreamStudentId: number | undefined) => {
        setAnswers((prevState) =>
            prevState.map((answer) => (answer.questionId === questionId && answer.academicStreamStudentId === academicStreamStudentId ? { ...answer, answer: value } : answer)),
        )
    };

    const prev = () => {
        setCurrentStep(currentStep - 1);
    };

    const checkAllQuestionsAnswered = (academicStreamStudentId: number): boolean => {
        const answer = answers.filter((answer) => answer.academicStreamStudentId === academicStreamStudentId && answer.questionId !== 11);
        return answer !== undefined && answer.every((answer) => answer.answer !== '');
    };

    const next = async (key: any) => {
        // if (teachers) {
        //     if (currentStep !== teachers.length) {
        //         await setCurrentStep(currentStep + 1);
        //     }
        // }
        if (!answers.find((answer) => answer.questionId === 12)) {
            setAnswers((prevState) =>
                [
                    ...prevState,
                    {
                        academicStreamStudentId: undefined,
                        questionId: 12,
                        answer: "",
                        surveyId: 1
                    }
                ])
        }
        if (!answers.find((answer) => answer.questionId === 13)) {
            setAnswers((prevState) =>
                [
                    ...prevState,
                    {
                        academicStreamStudentId: undefined,
                        questionId: 13,
                        answer: "",
                        surveyId: 1
                    }
                ])
        }
        if (checkAllQuestionsAnswered(key)) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleSubmit = () => {
        SurveyService.submitAnswers(answers)
            .then(() => {
                successNotification('Данные успешно отправлены')
                setIsEnd(true)
            })
    }



    return { teachers, questions, isLoading, form, currentStep, answers, next, prev, isEnd, handleChange, handleSubmit }
}

