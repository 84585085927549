import { useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import UserService from '../../../services/userService';
import grantPermission from '../../../helpers/grantPermission';
import PageWrapper from '../../../ui/PageWrapper';
import { FxExamApplicationService } from './FxExamApplicationService';
import moment from 'moment';

interface Props {
  userId?: number;
}

const FxExamPageForTeachers = ({ userId }: Props) => {
  const [profile, setProfile] = useState<any>();
  const [data, setData] = useState<any[]>([]);


  useEffect(() => {
    if (grantPermission('TEACHERS')) {
      setProfile(UserService.getCurrentUser());
    }
  }, []);


  const tutorEmail = profile?.username;


  useEffect(() => {
    if (tutorEmail) {
      const fetchData = async () => {
        try {
          const response = await FxExamApplicationService.getFxExamStudentsByTeacherEmail(tutorEmail);
          //const response = await FxExamApplicationService.getFxExamStudentsByTeacherEmail('MOLdir.smagulova@astanait.edu.kz');
          setData(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [tutorEmail]);




  const columns = [
    {
      title: 'IIN',
      dataIndex: 'iin',
      key: 'iin',
    },
    {
      title: 'Student Full Name',
      dataIndex: 'studentName',
      key: 'studentName',
      render: (text: string, record: any) => `${text} ${record.studentSurname}`
    },
    // {
    //     title: 'Student Surname',
    //     dataIndex: 'studentSurname',
    //     key: 'studentSurname',
    //   },
    {
      title: 'Discipline',
      dataIndex: 'disciplineName',
      key: 'disciplineName',
    },
    {
      title: 'Submission date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Approved date',
      dataIndex: 'approvedDate',
      key: 'approvedDate',
      render: (text: string) => moment(text).format('DD.MM.YYYY HH:mm'),
    },

  ];



  return (
    <PageWrapper>
      <Typography.Title level={1}>FX Exam Students</Typography.Title>
      <Table columns={columns} dataSource={data}></Table>
    </PageWrapper>
  );
};

export default FxExamPageForTeachers;
