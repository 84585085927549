import { Button, Form, Radio, Result, Select, Space, Steps, Typography } from 'antd'
import { useSurvey } from './useSurvey'
import Center from '../../../ui/Center'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea';
import Spinner from '../../../ui/Spinner'
import React from 'react'

const SurveyPage = () => {

    const { teachers, questions, isLoading, form, currentStep, answers, next, prev, isEnd, handleChange, handleSubmit } = useSurvey();

    const [showReason, setShowReason] = React.useState(false)

    const generateOptions = () => {
        const options: any[] = [];
        for (let i = 0; i <= 100; i = i + 10) {
            options.push(
                <Select.Option key={i} value={i} >
                    {i}
                </Select.Option>
            );
        }
        return options;
    };

    const generateRating = (scaleMaxValue: number) => {
        const options: any[] = [];

        for (let i = 0; i <= scaleMaxValue; i++) {
            options.push(
                <Radio key={i} value={i}>{i}</Radio>
            );
        }
        return options;
    }


    let items = teachers && teachers.map((item, i) => (
        {
            key: item.academicStreamStudentId,
            title: item.discipline,
            content: (
                <Center lg={16} key={item.academicStreamStudentId}>
                    <Form.Item name="academicStreamStudentId" noStyle></Form.Item>
                    <Typography.Title level={3}>{item.discipline}</Typography.Title>
                    <Typography.Title level={4} type="secondary">{item.teacherDtoResponse.surnameKz + " " + item.teacherDtoResponse.nameKz + " " + item.teacherDtoResponse.patronymicKz}</Typography.Title>
                    <Form.Item rules={[{ required: true }]}
                        name="answer">
                        {questions.filter((questions) => questions.id !== 13 && questions.id !== 12).sort((a, b) => a.id - b.id).map((question, j) => (
                            <Space style={{ width: "100%", justifyContent: "space-between", paddingTop: "5px" }} key={j}>
                                <Typography.Text>{question.question}</Typography.Text>
                                <Form.Item rules={[{ required: true, message: "Заполните поле!" }]}>
                                    {question.type.id === 2 && (
                                        <Space>
                                            <Typography.Text>{question.scaleMinText}</Typography.Text>
                                            <Radio.Group value={Number(answers.find((answer) => answer.academicStreamStudentId === item.academicStreamStudentId && answer.questionId === question.id)?.answer)} onChange={(value) => handleChange(value.target.value, question.id, item.academicStreamStudentId)}>
                                                {generateRating(question.scaleMaxValue)}
                                            </Radio.Group>
                                            <Typography.Text>{question.scaleMaxText}</Typography.Text>
                                        </Space>
                                    )}
                                    {question.type.id === 3 && (
                                        <Space>
                                            <TextArea value={answers.find((answer) => answer.academicStreamStudentId === item.academicStreamStudentId && answer.questionId === question.id)?.answer} onChange={(value) => handleChange(value.target.value, question.id, item.academicStreamStudentId)}></TextArea>
                                        </Space>
                                    )}
                                    {question.type.id === 1 && (
                                        <Select style={{ width: "70px" }} value={Number(answers.find((answer) => answer.academicStreamStudentId === item.academicStreamStudentId && answer.questionId === question.id)?.answer)} onChange={(value) => handleChange(value, question.id, item.academicStreamStudentId)}>
                                            {generateOptions()}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Space>
                        ))}
                    </Form.Item>
                    <Form.Item noStyle name={"questionId"}></Form.Item>

                </Center>
            )
        }
    ));

    items.push(
        {
            key: teachers.length + 2,
            title: "",
            content: (
                <Center lg={16}>
                    {questions.filter((questions) => questions.id === 13 || questions.id === 12).sort((a, b) => a.id - b.id).map((question, i) => (
                        <Space style={{ width: "100%", justifyContent: "space-between", paddingTop: "5px" }} key={question.id}>
                            {question.id === 12 && (
                                <>
                                    <Typography.Text>{question.question}</Typography.Text>
                                    <Radio.Group onChange={(value) => {
                                        handleChange(String(value.target.value), question.id, undefined)
                                        value.target.value === 0 ? setShowReason(true) : setShowReason(false)
                                    }}>
                                        <Radio key={"yes"} value={1}>{"Yes"}</Radio>
                                        <Radio key={"no"} value={0}>{"No"}</Radio>
                                    </Radio.Group>
                                </>
                            )}
                            {question.id === 13 && showReason && (
                                <>
                                    <Typography.Text>{question.question}</Typography.Text>
                                    <TextArea onChange={(value) => handleChange(value.target.value, question.id, undefined)}></TextArea>
                                </>
                            )}
                        </Space>
                    ))}
                    <Form.Item noStyle name={"questionId"}></Form.Item>

                </Center>
            )
        }
    )


    return (
        <>
            {isEnd ? (<Result
                status="success"
                title="Успешно отправлено!"
            />) : isLoading ? (<Spinner size={"large"} />) : (
                <Form initialValues={{ remember: true }} form={form} autoComplete="off" >
                    {items && teachers && (
                        <>
                            <Steps items={items} current={currentStep} size="small" responsive labelPlacement='vertical' />
                            {items[currentStep]?.content}
                            {currentStep > 0 && (
                                <Button
                                    style={{ marginRight: 8 }}
                                    onClick={prev}
                                    icon={<ArrowLeftOutlined />}
                                >
                                    Previous
                                </Button>
                            )}
                            {currentStep === teachers?.length && (
                                <Button type="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            )}
                            {currentStep < teachers.length && (
                                <Button
                                    type="primary"
                                    onClick={() => next(items[currentStep]?.key)}
                                    icon={<ArrowRightOutlined />}
                                >
                                    Next
                                </Button>
                            )}
                        </>
                    )}
                </Form>
            )}

        </ >
    )
}

export default SurveyPage