import React, { useEffect, useState } from 'react';
import { Button, Card, Typography, Modal, Form, Alert, Space, Statistic } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import { PlusOutlined } from '@ant-design/icons';
// import { TeacherDiplomaWorkService } from './TeacherDiplomaWorkService';
// import { TeacherDiplomaWorkSuggestionDtoRequest } from './TeacherDiplomaWorkSuggestionDtoRequest';
import { AcademicDegreeDtoResponse } from '../../../interfaces/AcademicDegreeDtoResponse';
import { DisciplinesService } from '../../../services/DisciplinesService';
// import { TeacherDiplomaWorkSuggestionLimitDtoResponse } from './TeacherDiplomaWorkSuggestionLimitDtoResponse';
import { EducationProgramDtoResponse } from '../../../interfaces/EducationalPrograms/EducationProgramDtoResponse';
import { TeacherGraduationThesisService } from './TeacherGraduationThesisService';
import { TeacherGraduationThesisDtoRequest } from './TeacherGraduationThesisDtoRequest';
import { GraduationThesisLimitDtoResponse } from './GraduationThesisLimitDtoResponse';
import { GraduationThesisTypeDtoResponse } from './GraduationThesisTypeDtoResponse';
import TeacherGraduationThesisTable from './TeacherGraduationThesisTable';
import TeacherGraduationThesisForm from './TeacherGraduationThesisForm';
import { GraduationThesisSettingsDtoResponse } from '../GraduationThesisSettings/GraduationThesisSettingsDtoResponse';
import moment from 'moment';


const TeacherGraduationThesis = () => {
    const [suggestions, setSuggestions] = useState<TeacherGraduationThesisDtoRequest[]>([]);
    const [academicDegrees, setAcademicDegrees] = useState<AcademicDegreeDtoResponse[]>([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingRecord, setEditingRecord] = useState<TeacherGraduationThesisDtoRequest | null>(null);
    const [individual, setIndividual] = useState<boolean>(true);
    const [form] = Form.useForm();
    const [graduationThesisTypes, setGraduationThesisTypes] = useState<GraduationThesisTypeDtoResponse[]>([]);
    const [degreeId, setDegreeId] = useState<number | undefined>(undefined);
    // const [defenseYear, setDefenseYear] = useState<number | undefined>(undefined);
    const [limits, setLimits] = useState<GraduationThesisLimitDtoResponse[]>([]);
    const [educationPrograms, setEducationPrograms] = useState<EducationProgramDtoResponse[]>([]);
    const defenseYearTest = 2025;
    const [settings, setSettings] = useState<GraduationThesisSettingsDtoResponse | null>(null);


    const handleDegreeChange = (value: number) => {
        setDegreeId(value);
        form.setFieldsValue({ graduationThesisType: { id: undefined }, limits: [] });
    };
    const handleAddEducationProgram = () => {
        const maxLimits = form.getFieldValue('requiredStudentsNumber') || 1;
        const activeLimitsCount = limits.filter((limit) => !limit.deleted).length;
        if (activeLimitsCount < maxLimits) {
            setLimits([
                ...limits,
                { limit: 0, educationProgramId: 0, deleted: false },
            ]);
        }
    };

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const data = await TeacherGraduationThesisService.getSettingByTeacherPrincipalAndCurrentDefenseYear();
                setSettings(data);
            } catch (error) {
                console.error("Error fetching settings", error);
            }
        };
        fetchSettings();
    }, []);

    const handleRemoveEducationProgram = (index: number) => {
        const newLimits = limits.map((item, i) =>
            i === index ? { ...item, deleted: true } : item
        );
        setLimits(newLimits);
    };

    useEffect(() => {
        const fetchEducationPrograms = async () => {
            if (degreeId
                //  && defenseYear
            ) {
                try {
                    const data = await TeacherGraduationThesisService.getEducationProgramsByDefenseYearAndDegree(
                        // defenseYear,
                        defenseYearTest,
                        degreeId);
                    setEducationPrograms(data);
                } catch (error) {
                    console.error("Error fetching education programs", error);
                }
            }
        };
        fetchEducationPrograms();
    }, [degreeId
        // , defenseYear
    ]);

    useEffect(() => {
        if (editingRecord) {
            // setLimits(editingRecord.limits.map((limit) => ({ ...limit, deleted: false })));
        } else {
            setLimits([]);
        }
    }, [editingRecord]);


    useEffect(() => {
        const fetchGraduationThesisType = async () => {
            if (degreeId) {
                try {
                    const data = await TeacherGraduationThesisService.getThesisTypesByDegreeId(degreeId);
                    setGraduationThesisTypes(data);
                } catch (error) {
                    console.error("Error fetching diploma work types", error);
                }
            }
        };
        fetchGraduationThesisType();
    }, [degreeId]);


    const fetchSuggestions = async () => {
        try {
            const data = await TeacherGraduationThesisService.getByTeacherPrincipal();
            setSuggestions(data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {

        const fetchAcademicDegrees = async () => {
            try {
                const { data } = await DisciplinesService.getAcademicDegrees();
                setAcademicDegrees(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchAcademicDegrees();
        fetchSuggestions();
    }, []);

    const openModal = (record: TeacherGraduationThesisDtoRequest | null = null) => {
        setEditingRecord(record);
        setIsModalVisible(true);
        setLimits(record ? (Array.isArray(record.limits) ? record.limits : []).map((limit) => ({ ...limit, deleted: false })) : []);
        if (record) {
            setDegreeId(record.type.academicDegree.id);
            form.setFieldsValue({
                ...record,
                graduationThesisType: record.graduationThesisTypeId
                    ? { id: record.graduationThesisTypeId }
                    : undefined
            });
            // setDegreeId(record.degree.id);
            setIndividual(record.individual);
        } else {
            form.resetFields();
            setDegreeId(undefined);
            setIndividual(true);
        }
    };

    const handleModalOk = async () => {
        try {
            const values = await form.validateFields();
            const graduationThesisTypeId = values.type.id;
            const isEditing = !!editingRecord;
            const requiredStudentsNumber = values.requiredStudentsNumber || 1;

            const totalLimit = limits
                .map((limit, index) => ({
                    ...limit,
                    limitValue: form.getFieldValue(['limits', index, 'limit']) || 0,
                }))
                .filter((limit) => !limit.deleted)
                .reduce((sum, limit) => sum + limit.limitValue, 0);

            if (totalLimit !== requiredStudentsNumber) {
                form.setFields([
                    {
                        name: 'requiredStudentsNumber',
                        errors: [`Total of limits (${totalLimit}) must exactly match the required students number (${requiredStudentsNumber}).`],
                    },
                ]);
                return;
            }

            const finalLimits = isEditing
                ? limits.map((limit, index) => ({
                    ...limit,
                    limit: form.getFieldValue(['limits', index, 'limit']),
                    educationProgramId: form.getFieldValue(['limits', index, 'educationProgram', 'id']) || limit.educationProgramId,
                }))
                : limits
                    .filter((limit) => !limit.deleted)
                    .map((limit, index) => ({
                        ...limit,
                        limit: form.getFieldValue(['limits', index, 'limit']),
                        educationProgramId: form.getFieldValue(['limits', index, 'educationProgram', 'id']),
                    }));

            const finalValues = {
                ...values,
                graduationThesisTypeId,
                limits: finalLimits,
            };

            if (isEditing) {
                await TeacherGraduationThesisService.updateSuggestion(editingRecord.id, finalValues);
            } else {
                await TeacherGraduationThesisService.createSuggestion(finalValues);
            }

            setIsModalVisible(false);
            form.resetFields();
            setLoading(true);
            const data = await TeacherGraduationThesisService.getByTeacherPrincipal();
            setSuggestions(data);
            setLoading(false);
        } catch (error) {
            console.error('Error saving data', error);
        }
    };


    const handleDelete = async (id: number) => {
        try {
            await TeacherGraduationThesisService.deleteSuggestion(id);
            setLoading(true);
            const data = await TeacherGraduationThesisService.getByTeacherPrincipal();
            setSuggestions(data);
            setLoading(false);
        } catch (error) {
            console.error('Error during deletion', error);
        }
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
        form.setFieldsValue({ graduationThesisTypeId: undefined });
        setLimits([]);
    };

    const handleIndividualChange = (value: boolean) => {
        setIndividual(value);
        form.setFieldsValue({ requiredStudentsNumber: value ? 1 : undefined });
    };

    const isButtonDisabled = (): boolean => {
        if (!settings) return true;
        const { approved, deadlineStartAt, deadlineEndAt } = settings;
        const now = new Date();
        return !approved || now < new Date(deadlineStartAt) || now > new Date(deadlineEndAt);
    };

    return (
        <PageWrapper>
            <Typography.Title level={1}>Graduation Thesis</Typography.Title>
            {settings ? (
                <>
                    <Space direction="vertical">
                        <Alert
                            message={`The deadline is from ${moment(settings.deadlineStartAt).format('LL hh:mm')} to ${moment(settings.deadlineEndAt).format('LL hh:mm')}`}
                            type='info'
                            showIcon
                        />
                        <Statistic.Countdown
                            title="Time left to select the diploma"
                            value={Date.parse(settings.deadlineEndAt.toString())}
                            format='D [days] H [hours] m [minutes]'
                        />
                    </Space>
                    <Card>
                        <Button type="primary" disabled={isButtonDisabled()} icon={<PlusOutlined />} onClick={() => openModal()}>Add Thesis</Button>
                        <TeacherGraduationThesisTable
                            suggestions={suggestions}
                            loading={loading}
                            onEdit={openModal}
                            onDelete={handleDelete}
                            fetchSuggestions={fetchSuggestions}
                            isButtonDisabled={isButtonDisabled()}
                        />
                    </Card>
                </>
            ) : (
                <Typography.Text type="danger">
                    You do not have permission to create suggestions or there are no settings available.
                </Typography.Text>
            )}
            {/* ) : (
                <Typography.Text type="danger">You do not have permission to create suggestions or the deadline has passed.</Typography.Text>
            )} */}
            <Modal
                title={editingRecord ? 'Edit Suggestion' : 'Add Suggestion'}
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <TeacherGraduationThesisForm
                    form={form}
                    academicDegrees={academicDegrees}
                    graduationThesisTypes={graduationThesisTypes}
                    educationPrograms={educationPrograms}
                    limits={limits}
                    individual={individual}
                    setLimits={setLimits}
                    handleDegreeChange={handleDegreeChange}
                    handleIndividualChange={handleIndividualChange}
                    handleAddEducationProgram={handleAddEducationProgram}
                    handleRemoveEducationProgram={handleRemoveEducationProgram}
                />
            </Modal>
        </PageWrapper>
    );
};

export default TeacherGraduationThesis;
