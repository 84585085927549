import { Table } from 'antd'
import React from 'react'
import { TeacherScholarInformationDtoResponse } from '../../../../interfaces/Teacher/TeacherScholarInformationDtoResponse';

interface Props {
  dataSource?: TeacherScholarInformationDtoResponse[];
}


const PublicTeacherScholarInformationTable = ({ dataSource }: Props) => {

  const columns = [
    {
      title: "№",
      dataIndex: "key",
      key: "key"
    },
    {
      title: "Scientific work ID",
      dataIndex: "platformId",
      key: "platformId",
    },
    {
      title: "Scientific work H-index",
      dataIndex: "hIndex",
      key: "hIndex",
    },
    {
      title: "Scientific work url",
      dataIndex: "url",
      key: "url",
      render: (record: any) => {
        return record && (
          <a href={record} target={"_blank"} rel="noreferer noreferrer">Link</a>
        )
      },
    },
    {
      title: "Platform Type",
      dataIndex: "platformType",
      key: "platformType",
    }
  ];



  return (
    <Table bordered columns={columns}
      dataSource={dataSource?.map((scholarInformation, i) => ({
        key: i + 1,
        platformId: scholarInformation.platformId,
        hIndex: scholarInformation.hindex,
        url: scholarInformation.url,
        platformType: scholarInformation.platformType.name
      }))}
      rowKey={(record) => record.platformId}
      style={{
        overflowX: "auto"
      }}
    />
  )
}

export default PublicTeacherScholarInformationTable