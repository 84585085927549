import React from 'react'
import UniversalTable from '../UniversalTeachersTable';
import { TeacherLegalDocsDtoResponce } from '../../../../interfaces/Teacher/TeacherLegalDocsDtoResponce';
import { TeacherService } from '../../../../services/TeacherService';
import { successNotification } from '../../../../helpers/successNotification';
import { Field } from '../../../../interfaces/Field';
import useFetchData from '../../../../hooks/useFetchData';
import { TeacherLegalDocsDtoRequest } from '../../../../interfaces/Teacher/TeacherLegalDocsDtoRequest';
import { AbiturCatalogDtoResponse } from '../../../../interfaces/AbiturCatalogDtoResponse';

interface TeacherLegalDocsTableProps {
    isPublic: boolean;
}

const TeacherLegalDocsTable = ({ isPublic }: TeacherLegalDocsTableProps) => {
    const { data: legalDoc, fetchData: fetchLegalDocs } = useFetchData<TeacherLegalDocsDtoResponce[]>(
        TeacherService.getLegalDocuments, true
    );

    const { data: types } = useFetchData<AbiturCatalogDtoResponse[]>(
        TeacherService.getLegalDocTypes, true
    );


    const addRecord = (record: TeacherLegalDocsDtoRequest) => {
        TeacherService.createOrUpdateLegalDoc(record)
            .then(() => fetchLegalDocs())
            .finally(() => successNotification('Development was created!'))
    };

    const editRecord = (record: TeacherLegalDocsDtoRequest) => {
        TeacherService.createOrUpdateLegalDoc(record)
            .then(() => fetchLegalDocs())
            .finally(() => successNotification('Development was updated!'))
    };

    const deleteRecord = (record: TeacherLegalDocsDtoRequest) => {
        TeacherService.deleteLegalDoc(record.id)
            .then(() => fetchLegalDocs())
            .finally(() => successNotification('Development was deleted!'))
    };

    const legalFields: Field[] = [
        {
            name: 'typeId', label: 'Legal document type', type: 'select', placeholder: "Select type", options: types?.map((type) => {
                return {
                    value: type.id,
                    label: type.name
                }
            })
        },
        { name: 'fileId', label: 'File', type: 'file' }
    ];


    return (
        <UniversalTable
            columns={[
                {
                    title: '#',
                    dataIndex: 'key',
                    key: 'key',
                },
                {
                    title: 'Legal document',
                    dataIndex: 'type',
                    key: 'type',
                }
            ]}
            dataSource={legalDoc ? legalDoc.map((legalDoc, i) => ({
                id: legalDoc.id,
                key: i + 1,
                type: legalDoc.type.name,
                typeId: legalDoc.type.id,
                fileId: legalDoc.fileId,
            })) : []}
            addRecord={(e: TeacherLegalDocsDtoRequest) => addRecord(e)}
            editRecord={(e: TeacherLegalDocsDtoRequest) => editRecord(e)}
            deleteRecord={(e: TeacherLegalDocsDtoRequest) => deleteRecord(e)}
            fields={legalFields}
        />)
}

export default TeacherLegalDocsTable