import { useState } from 'react'
import Page from '../../../../ui/Page'
import { useKpi } from '../useKpi'
import { Button, Form, Input, Modal, Select, Space, Table, Tag } from 'antd';
import { TeacherKpiApplicationDtoResponse } from '../../../../interfaces/Kpi/TeacherKpiApplicationDtoResponse';
import { getFullDate } from '../../../../common/getFullDate';
import { SearchOutlined, SettingOutlined } from '@ant-design/icons';
import RowSpan from '../../../../ui/RowSpan';


const KpiApprovePage = () => {

    const { data, handlers } = useKpi();
    const [open, setOpen] = useState(false);

    //Proof Modal
    const [proofForm] = Form.useForm();

    const handleOpenModal = (record: TeacherKpiApplicationDtoResponse) => {
        setOpen(true)
        proofForm.setFieldsValue({
            fileId: record.fileId,
            url: record.url
        })
    }


    //Disagree Modal
    const [disagreeForm] = Form.useForm();
    const [isDisagree, setIsDisagree] = useState(false);



    const hrColumns = [
        {
            title: '№',
            dataIndex: 'key',
            key: 'key',
            render: (text: any, record: TeacherKpiApplicationDtoResponse, index: number) => index + 1
        },
        {
            title: 'Teacher',
            dataIndex: 'teacher',
            key: 'teacher',
            render: (text: any, record: TeacherKpiApplicationDtoResponse) => record.teacher.surnameKz + " " + record.teacher.nameKz + " " + record.teacher.patronymicKz
        },
        {
            title: 'Category',
            dataIndex: 'kpiCategory',
            key: 'kpiCategory',
            render: (text: any, record: TeacherKpiApplicationDtoResponse) => record.kpiCategory.name,
            width: "50%"
        },
        {
            title: 'Created date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (text: any, record: TeacherKpiApplicationDtoResponse) => getFullDate(record.createdDate.toString())
        },
        {
            title: 'Proof',
            render: (text: any, record: TeacherKpiApplicationDtoResponse) => (
                <Button type={"primary"} onClick={() => handleOpenModal(record)}>
                    Show proof
                </Button>
            )
        },
        {
            title: 'Approved',
            dataIndex: 'approved',
            key: 'approved',
            render: (text: any, record: TeacherKpiApplicationDtoResponse, index: number) => record.approved !== null ? (
                record.approved === true ? (
                    <Tag color="green" key={index}>Approved</Tag>
                ) : (
                    <Button type='primary' key={index} danger onClick={() => handlers.handleOpenReasonModal(record)}>Show reason</Button>
                )
            ) : (
                <Space size={"small"}>
                    <Button type="primary" onClick={() => { handlers.handleApprove({ approved: true, comment: "" }, record.id) }}>
                        Agree
                    </Button>
                    <Button type="primary" danger onClick={() => setIsDisagree(true)}>
                        Disagree
                    </Button>
                    <Modal onOk={disagreeForm.submit} title={"Please, write detailed reason of disagreement"} open={isDisagree} onCancel={() => setIsDisagree(false)}>
                        <Form form={disagreeForm} onFinish={(e) => {
                            handlers.handleApprove({ approved: false, comment: e.comment }, record.id);
                            setIsDisagree(false);
                        }}>
                            <Form.Item name={"comment"} rules={[{ required: true }]}>
                                <Input.TextArea size="small" bordered autoSize={true} />
                            </Form.Item>
                        </Form>
                    </Modal>
                </Space >
            ),
        },
    ];

    return (
        <Page title='KPI Applications of Teachers' subtitle='Page for approving/declining KPI Applications of Teachers'>
            <RowSpan left={
                <Form onFinish={handlers.handleSearchApplications}>
                    <Form.Item name="kpiCategory">
                        <Select placeholder={"Choose category"} onSelect={handlers.handleChooseCategory}>
                            {data.categories.map((category, i) => (
                                <Select.Option key={i} value={category.id} >
                                    {category.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="kpiSubcategory">
                        <Select placeholder={"Choose sub-category"}>
                            {data.subCategories.map((subCategory, i) => (
                                <Select.Option key={i} value={subCategory.id}>
                                    {subCategory.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="approved">
                        <Select placeholder={"Choose status"}>
                            <Select.Option value={'0'} key={0}>
                                Declined
                            </Select.Option>
                            <Select.Option value={1} key={1}>
                                Approved
                            </Select.Option>
                            <Select.Option value={2} key={2}>
                                No Status
                            </Select.Option>
                            <Select.Option value={3} key={3}>
                                All
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="teacherFullname">
                        <Input placeholder='Type teacher fullname' />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType='submit' type='primary' icon={<SearchOutlined />}>Search</Button>
                    </Form.Item>
                </Form>
            } right={
                <Button icon={<SettingOutlined />} onClick={() => data.history.push("/kpi/settings")}>Settings</Button>
            } />

            <Table bordered dataSource={data.kpiApplications} columns={hrColumns} />
            <Modal open={open} title='Teachers proof for KPI category' onOk={() => setOpen(false)} onCancel={() => setOpen(false)}>
                <Form form={proofForm}>
                    <Form.Item name="url" label={"URL"}>
                        <Input disabled />
                    </Form.Item>
                    {proofForm.getFieldValue('fileId') && (
                        <Button onClick={() => handlers.handleDownloadFile(proofForm.getFieldValue('fileId'))}>Download file</Button>
                    )}
                </Form>
            </Modal>
            <Modal open={data.isReasonOpen} title="HR's reason of disagreement" onOk={() => data.setIsReasonOpen(false)} onCancel={() => data.setIsReasonOpen(false)}>
                <Form form={data.reasonForm}>
                    <Form.Item name={"comment"}>
                        <Input.TextArea size="small" bordered={false} disabled autoSize={true} />
                    </Form.Item>
                </Form>
            </Modal>
        </Page >
    )
}

export default KpiApprovePage