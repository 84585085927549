import React, { useState } from 'react';
import { Button, Space, Typography, Input, Form, DatePicker, Divider, Dropdown, MenuProps, Upload, UploadProps, Select, notification } from 'antd';
import { DownOutlined, DownloadOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import StudentGroupFilter from './StudentGroupFilter';
import StudentsFilter from './StudentsFilter';
import DiplomaWorkFilter from './DiplomaWorkFilter';
import moment from 'moment';
import CommissionMemberNumberFilter from './CommissionMemberNumberFilter';
import CommissionMemberFilter from './CommissionMemberFilter';
import { Prompt } from 'react-router-dom';
import useFinalAttestationProtocol from './useFinalAttestationProtocol';
import Page from '../../../ui/Page/Page';
import { FileService } from '../../../services/file/FileService';
import { DiplomaWorkFilesDtoResponse } from '../../../interfaces/Diploma/response/DiplomaWorkFilesDtoResponse';
import { DiplomaWorkFileTypesDtoResponse } from '../../../interfaces/Diploma/response/DiplomaWorkFileTypesDtoResponse';

const { TextArea } = Input;

interface UploadedFile {
  uid: string;
  name: string;
  fileTypeId: string;
}

interface FileUploadComponentProps {
  workFile?: DiplomaWorkFilesDtoResponse;
  fileTypeInstance: DiplomaWorkFileTypesDtoResponse;
  isRequired: boolean;
  handleFileChange: (e: any, fileTypeInstance: any) => void;
  props: UploadProps;
  uploadedFiles: UploadedFile[];
}

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({ workFile, fileTypeInstance, isRequired, handleFileChange, props, uploadedFiles }) => {
  const uploadedFile = uploadedFiles.find(file => file.fileTypeId === fileTypeInstance.id?.toString());

  return (
    <div key={workFile ? workFile.id : fileTypeInstance.id}>
      <Divider>
        {isRequired ? `${fileTypeInstance.nameRu} (Обязательно)` : fileTypeInstance.nameRu}
      </Divider>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Upload
          {...props}
          onChange={(e) =>
            handleFileChange(e, {
              id: workFile ? workFile.id : undefined,
              diplomaWorkFileType: fileTypeInstance.id,
            })
          }
        >
          <Button icon={<UploadOutlined />}>Выберите файл</Button>
        </Upload>
        {uploadedFile && (
          <Typography.Text>{uploadedFile.name}</Typography.Text>
        )}
        {workFile && workFile?.fileId > 0 && (
          <Button
            type='primary'
            icon={<DownloadOutlined />}
            onClick={() => FileService.getFileById(workFile.fileId, workFile.name)}
          >
            Скачать файл
          </Button>
        )}
      </Space>
    </div>
  );
};

const FinalAttestationProtocolPage: React.FC = () => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const {
    groups,
    diplomaWorkFileTypes,
    diplomaWorkFiles,
    setDiplomaWorkDtoRequest,
    educationalPrograms,
    students,
    diplomaWorks,
    commissionMembers,
    selectedGroupId,
    selectedUserId,
    selectedDiplomaWorkId,
    selectedCommissionNumber,
    formChanged,
    setFormChanged,
    handleAddOptionalFileType,
    styles,
    onGroupSelect,
    onComissionMemberNumberSelect,
    onStudentSelect,
    onDiplomaWorkSelect,
    onFinish,
    exportProtocolForm2,
    exportProtocolForm3,
    form,
    loading,
  } = useFinalAttestationProtocol();

  const props: UploadProps = {
    multiple: false,
    beforeUpload: (file) => false,
  };

  const handleFileChange = (e: any, fileTypeInstance: any) => {
    const fileList: any[] = e.fileList; // Keep the latest file only

    if (fileList) {
      fileList.forEach(file => {
        const updatedDtoRequest = {
          id: fileTypeInstance.id, // Existing file ID or undefined
          file: file.originFileObj,
          diplomaWorkFileType: fileTypeInstance.diplomaWorkFileType,
          name: file.name,
        };

        setDiplomaWorkDtoRequest(prev => {
          const filtered = prev.filter(item => item.diplomaWorkFileType !== fileTypeInstance.diplomaWorkFileType);
          return [...filtered, updatedDtoRequest];
        });

        setUploadedFiles(prev => [...prev, { uid: file.uid, name: file.name, fileTypeId: fileTypeInstance.id?.toString() }]);

        notification.success({
          message: 'Файл успешно загружен',
          description: `Файл ${file.name} был успешно загружен.`,
        });
      });
    } else {
      notification.error({
        message: 'Ошибка загрузки файла',
        description: 'Произошла ошибка при загрузке файла. Попробуйте еще раз.',
      });
    }
  };

  // Required file types block
  const requiredFileTypes = diplomaWorkFileTypes.filter(type => type.isRequired);
  const filledRequiredFiles = diplomaWorkFiles.filter(file => file.type.isRequired);
  const unfilledRequiredTypes = requiredFileTypes.filter(type => !filledRequiredFiles.some(file => file.type.id === type.id));

  // Optional file types block
  const optionalFileTypes = diplomaWorkFileTypes.filter(type => !type.isRequired);
  const filledOptionalFiles = diplomaWorkFiles.filter(file => optionalFileTypes.some(type => type.id === file.type.id));

  const items: MenuProps['items'] = [
    // {
    //   label: <Typography.Text onClick={exportProtocolForm1}>Скачать форму 1</Typography.Text>,
    //   key: '0',
    // },
    {
      label: <Typography.Text onClick={exportProtocolForm2}>Скачать форму 2</Typography.Text>,
      key: '1',
    },
    {
      label: <Typography.Text onClick={exportProtocolForm3}>Скачать форму 3</Typography.Text>,
      key: '2',
    },
  ];

  return (
    <Page title={'Final Attestation Protocol'} width={800} center>
      <Form
        layout="vertical"
        size="large"
        disabled={loading}
        form={form}
        onFinish={onFinish}
        style={styles}
        onChange={() => setFormChanged(true)}
      >
        <Form.Item name="Group" label="Группа обучающихся">
          <StudentGroupFilter
            placeholder="Группа обучающихся"
            selectedOption={selectedGroupId}
            onSelect={(value) => onGroupSelect(value)}
            options={groups}
          />
        </Form.Item>
        {educationalPrograms && (
          <Form.Item name="EducationalProgram" label="Образовательная программа">
            {/* <EducationalProgramFilter
              placeholder="Образовательная программа"
              selectedOption={selectedEducationalProgramId}
              onSelect={(value) => setSelectedEducationalProgramId(value)}
              options={educationalPrograms}
            /> */}
            <Input defaultValue={educationalPrograms.titleRu} disabled />
          </Form.Item>
        )}
        <Form.Item name="Student" label="ФИО Студента">
          <StudentsFilter
            placeholder="ФИО Студента"
            selectedOption={selectedUserId}
            onSelect={(value) => onStudentSelect(value)}
            options={students}
          />
        </Form.Item>
        <Form.Item name="Diploma" label="Дипломная работа">
          <DiplomaWorkFilter
            placeholder="Дипломная работа"
            selectedOption={selectedDiplomaWorkId}
            onSelect={(value) => onDiplomaWorkSelect(value)}
            options={diplomaWorks}
          />
        </Form.Item>
        {selectedDiplomaWorkId && (
          <Form.Item name="CommissionNumber" label="Номер комиссии">
            <CommissionMemberNumberFilter
              placeholder="Номер комиссии"
              selectedOption={selectedCommissionNumber}
              onSelect={(value) => onComissionMemberNumberSelect(value)}
              options={[
                { id: 1, option: '1' },
                { id: 2, option: '2' },
                { id: 3, option: '3' },
              ]}
            />
          </Form.Item>
        )}
        <Form.Item label="ФИО (полное) рецензента" name="reviewerName">
          <Input />
        </Form.Item>
        <Form.Item label="Академическая степень рецензента" name="reviewerAcademicDegree">
          <Input />
        </Form.Item>
        <Form.Item label="Место работы рецензента" name="reviewerWorksAt">
          <Input />
        </Form.Item>
        <Form.Item
          label="Занимаемая должность рецензента"
          name="reviewerCurrentPosition">
          <Input />
        </Form.Item>
        <Form.Item label="Номер протокола" name="protocolNumber">
          <Input placeholder="Номер протокола" />
        </Form.Item>
        <Form.Item label="Число страниц дипломной работы" name="diplomaTextPageNumber">
          <Input placeholder="Число страниц дипломной работы" />
        </Form.Item>
        <Form.Item label="Оценка за дипломную работу" name="grade">
          <Input placeholder="Оценка за дипломную работу" />
        </Form.Item>
        <Form.Item label="Оценка рецензента" name="reviewerGrade">
          <Input placeholder="Оценка рецензента" />
        </Form.Item>
        <Form.Item label="Начало защиты дипломной работы" name="protocolDateStart">
          {/* <Input type = "datetime-local"  /> */}
          <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} />
        </Form.Item>
        <Form.Item label="Конец защиты дипломной работы" name="protocolDateEnd">
          <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} />
        </Form.Item>
        <Form.List name="fields">
          {(fields, { add, remove }) => (
            <div>
              {commissionMembers.length !== 0 &&
                !commissionMembers.includes(form.getFieldValue(['fields', 0, 'attestationCommissionMemberId'])) &&
                fields.map((field, index) => (
                  <div key={field.key}>
                    <Divider>Вопрос {index + 1}</Divider>
                    <Form.Item
                      {...field}
                      label="Члены комиссии"
                      name={[index, 'attestationCommissionMemberId']}
                    >
                      <CommissionMemberFilter
                        placeholder="Члены комиссии"
                        onSelect={(value) =>
                          form.setFieldsValue({ [`fields[${index}].attestationCommissionMemberId`]: value })
                        }
                        onChange={(value) =>
                          form.setFieldsValue({ [`fields[${index}].attestationCommissionMemberId`]: value })
                        }
                        options={commissionMembers}
                        selectedOption={form.getFieldValue(['fields', index, 'attestationCommissionMemberId'])}
                      />
                    </Form.Item>
                    <Form.Item name={[index, 'question']} label="Вопрос">
                      <Input placeholder="Введите вопрос" />
                    </Form.Item>
                    <Button
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                      icon={<MinusCircleOutlined />}
                    >
                      Удалить вопрос
                    </Button>
                  </div>
                ))}
              <Divider />
              <Form.Item>
                <Button type="dashed" onClick={() => add()} style={{ width: '60%' }}>
                  <PlusOutlined /> Добавить вопрос
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>
        <Form.Item
          label="Общая характеристика ответов обучающегося на заданные ему вопросы"
          name="generalCharacteristicsOfStudentAnswers"
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Особые мнения членов комиссии" name="specialOpinionsOfCommission">
          <TextArea rows={4} />
        </Form.Item>
        <div>
          {[...filledRequiredFiles, ...unfilledRequiredTypes].map((item, index) => {
            const isFile = 'fileId' in item;
            const fileType = isFile ? item.type : item;

            return (
              <FileUploadComponent
                key={index}
                workFile={isFile ? item : undefined}
                fileTypeInstance={fileType}
                isRequired={fileType.isRequired}
                handleFileChange={handleFileChange}
                props={props}
                uploadedFiles={uploadedFiles}
              />
            );
          })}

          {filledOptionalFiles.map((workFile, index) => (
            <FileUploadComponent
              key={index}
              workFile={workFile}
              fileTypeInstance={workFile.type}
              isRequired={false}
              handleFileChange={handleFileChange}
              props={props}
              uploadedFiles={uploadedFiles}
            />
          ))}
          <Form.Item>
            <Select
              style={{ width: "100%" }}
              placeholder="Select file type"
              onChange={handleAddOptionalFileType}
              value={null}
            >
              {optionalFileTypes.map(type => (
                <Select.Option key={type.id} value={type.id}>{type.nameRu}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Space size={'large'} direction="horizontal">
          <Form.Item style={{ margin: 0, padding: 0 }}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
          <Dropdown menu={{ items }} trigger={['click']}>
            <Button loading={loading} size={'large'} onClick={(e) => e.preventDefault()}>
              <Space>
                Скачать
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      </Form>

      <Prompt when={formChanged} message="Вы уверены, что хотите покинуть страницу?" />
    </Page >
  );
};

export default FinalAttestationProtocolPage;
