import { Select } from 'antd';
import { AcademicDegreeDtoResponse } from '../../../../interfaces/AcademicDegreeDtoResponse';
import { DepartmentDtoResponse } from '../../../../interfaces/DepartmentDtoResponse';

const { Option } = Select;

type Options = DepartmentDtoResponse[] | AcademicDegreeDtoResponse[];

interface Props {
  placeholder?: string;
  options: Options;
  selectedOption: number | undefined;
  onSelect: (value: number) => void;
  width?: number;
  defaultValue?: number;
}

const SelectFilter = ({ placeholder, options, selectedOption, onSelect, width, defaultValue }: Props) => {
  return (
    <Select
      showSearch
      style={{ width: width ? width : 200 }}
      placeholder={placeholder}
      value={selectedOption}
      onSelect={onSelect}
      optionFilterProp="children"
    >
      <Option key={0} value={0}>
        -
      </Option>
      {options.map((option) => (
        <Option key={option.id} value={option.id}>
          {option.titleEn} / {option.titleRu} / {option.titleKz}
        </Option>
      ))}
    </Select>
  );
};

export default SelectFilter;
