import { LikeOutlined, SolutionOutlined, SyncOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, notification, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { errorNotification } from '../../../helpers/errorNotification';
import grantPermission from '../../../helpers/grantPermission';
import { IllnessCertificateDtoResponse } from '../../../interfaces/deansOffice/IllnessCertificateDtoResponse';
import { IllnessCertificateOfStudentDtoResponse } from '../../../interfaces/deansOffice/IllnessCertificateOfStudentDtoResponse';
import { IllPersonDtoResponse } from '../../../interfaces/deansOffice/IllPersonDtoResponse';
import { IllPersonStatus } from '../../../interfaces/deansOffice/IllPersonStatus';
import { IllnessCertificateService } from '../../../services/deansOffice/IllnessCertificateService';
import { IllPersonService } from '../../../services/deansOffice/IllPersonService';
import { TranscriptService } from '../../../services/TranscriptService';
import UserService from '../../../services/userService';

interface FilterRow {
  text: string;
  value: string;
}

const useDisease = () => {
  const [diseases, setDiseases] = useState<IllPersonDtoResponse[]>([]);
  const [studentDiseases, setStudentDiseases] = useState<IllnessCertificateOfStudentDtoResponse[]>([]);
  const [visible, setVisible] = useState(false);
  const [visibleDiseaseStatus, setVisibleDiseaseStatus] = useState<boolean>(false);
  const [modalValues, setModalValues] = useState<IllPersonDtoResponse>();
  const [filterRows, setFilterRows] = useState<FilterRow[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [student, setStudent] = useState<any>();
  const [uploaded, setUploaded] = useState(false);
  const [latestSubmissionRejected, setLatestSubmissionRejected] = useState(false);

  const studentColumns = [
    {
      title: 'Illness Notification Date',
      render: (record: IllnessCertificateOfStudentDtoResponse) =>
        getFullDate(record.illnessNotificationDate.toString()),
    },
    {
      title: 'Recovery Notification Date',
      render: (record: IllnessCertificateOfStudentDtoResponse) =>
        getFullDate(record.recoveryNotificationDate.toString()),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (record: string) => {
        switch (record) {
          case 'REJECTED':
            return <Tag color="red">Rejected</Tag>;
          case 'CHECKED':
            return <Tag color="green">Checked</Tag>;
          case 'CERTIFICATE_UPLOADED':
            return <Tag color="yellow">Certificate Uploaded</Tag>;
        }
        // return <Tag color="green">{record}</Tag>;
      },
      width: 10,
    },
    {
      title: 'Download',
      render: (record: IllnessCertificateOfStudentDtoResponse) => (
        <Button onClick={() => downloadCertificate(record.illnessCertificateId)}>Download</Button>
      ),
    },
  ];

  const officeColumns = [
    {
      title: 'Full Name',
      render: (record: any) => concatValues(record.student.surnameEn, record.student.nameEn),
    },
    {
      title: 'Group',
      dataIndex: ['student', 'group', 'title'],
      filters: filterRows,
      onFilter: (title: any, record: IllPersonDtoResponse) => record.student.group.title.indexOf(title) === 0,
    },
    {
      title: 'Illness Notification Date',
      render: (record: IllPersonDtoResponse) => getFullDate(record.illnessNotificationDate.toString()),
    },
    {
      title: 'Recovery Notification Date',
      render: (record: IllPersonDtoResponse) =>
        record.recoveryNotificationDate !== null ? getFullDate(record.recoveryNotificationDate.toString()) : '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: 'Ill',
          value: 'ILL',
        },
        {
          text: 'Recovered',
          value: 'RECOVERED',
        },
        {
          text: 'Certificate uploaded',
          value: 'CERTIFICATE UPLOADED',
        },
        {
          text: 'Certificate late submit',
          value: 'CERTIFICATE LATE SUBMIT',
        },
        {
          text: 'Checked',
          value: 'CHECKED',
        },
        {
          text: 'Rejected',
          value: 'REJECTED',
        },
      ],
      render: (record: string) => {
        switch (record) {
          case 'REJECTED':
            return <Tag color="red">Rejected</Tag>;
          case 'CHECKED':
            return <Tag color="green">Checked</Tag>;
          case 'CERTIFICATE_UPLOADED':
            return <Tag color="yellow">Certificate Uploaded</Tag>;
          case 'CERTIFICATE_LATE_SUBMIT':
            return <Tag color="orange">Certificate Late uploaded</Tag>;
          case 'ILL':
            return <Tag color="red">Ill</Tag>;
          case 'RECOVERED':
            return <Tag color="green">Recovered</Tag>;
        }
      },
      width: 10,
      onFilter: (value: any, record: IllPersonDtoResponse) => record.status.indexOf(value) === 0,
    },
    {
      title: 'Action',
      render: (record: IllPersonDtoResponse) => (
        <Button onClick={() => showModal(record)} disabled={checkStatus(record)}>
          View
        </Button>
      ),
    },
    {
      title: 'Download',
      render: (record: IllPersonDtoResponse) => (
        <Button onClick={() => downloadCertificateForOffice(record)} disabled={checkStatus(record)}>
          Download
        </Button>
      ),
    },
    {
      title: 'Approve',
      render: (record: IllPersonDtoResponse) => (
        <Button
          type="primary"
          onClick={() => approveCertificate(record)}
          disabled={checkStatus(record)}
          loading={spinner}
        >
          Approve
        </Button>
      ),
    },
    {
      title: 'Reject',
      render: (record: IllPersonDtoResponse) => (
        <Button
          onClick={() => rejectCertificate(record)}
          danger={true}
          disabled={checkStatus(record)}
          loading={spinner}
        >
          Reject
        </Button>
      ),
    },
  ];

  const checkStatus = (record: IllPersonDtoResponse) => {
    return !(
      record.status === IllPersonStatus.CERTIFICATE_UPLOADED ||
      record.status === IllPersonStatus.CERTIFICATE_LATE_SUBMIT
    );
  };

  const checkForEmptyOrNull = (val: string) => {
    return val === null || val.trim() === '';
  };

  const rejectCertificate = (record: IllPersonDtoResponse) => {
    setSpinner(true);
    IllnessCertificateService.rejectCertificate(record.illnessCertificate.id)
      .then((data) => {
        notification.success({
          message: 'Success',
          description: 'You successfully rejected disease certificate!',
          duration: 1.5,
        });
        fetchDataForOffice();
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const approveCertificate = (record: IllPersonDtoResponse) => {
    setSpinner(true);
    if (
      checkForEmptyOrNull(record.illnessCertificate.diseaseCode) ||
      checkForEmptyOrNull(record.illnessCertificate.doctorFullName) ||
      checkForEmptyOrNull(record.illnessCertificate.issuedFullName) ||
      checkForEmptyOrNull(record.illnessCertificate.issuerFullName) ||
      record.illnessCertificate.id === null
    ) {
      errorNotification('Some required values are empty! Check it by press button view!');
      setSpinner(false);
      return;
    }
    record.illnessCertificate.issuedDate =
      record.illnessCertificate.issuedDate === null ? new Date() : record.illnessCertificate.issuedDate;
    record.illnessCertificate.certificateStartDate =
      record.illnessCertificate.certificateStartDate === null
        ? new Date()
        : record.illnessCertificate.certificateStartDate;
    record.illnessCertificate.certificateEndDate =
      record.illnessCertificate.certificateEndDate === null ? new Date() : record.illnessCertificate.certificateEndDate;
    IllnessCertificateService.approveCertificate(record.illnessCertificate.id)
      .then((data) => {
        notification.success({
          message: 'Success',
          description: 'You successfully approved disease certificate!',
          duration: 1.5,
        });
        fetchDataForOffice();
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const downloadCertificateForOffice = (dto: IllPersonDtoResponse) => {
    IllnessCertificateService.downloadDocumentOfficePermission(dto).finally(() => { });
  };

  const downloadCertificate = (id: number) => {
    IllnessCertificateService.downloadDocument(id).finally(() => { });
  };

  const concatValues = (val1: string | undefined, val2: string | undefined) => {
    if (val1 === void 0 || val2 === void 0) {
      return '';
    }
    return val1 + ' ' + val2;
  };

  const showModal = (record: IllPersonDtoResponse) => {
    setModalValues(record);
    setVisible(true);
  };

  const handleCancel = () => {
    if (grantPermission('STUDENTS')) {
      fetchDataForStudents();
      checkLastStatus();
    }
    setVisible(false);
    setVisibleDiseaseStatus(false);
  };

  const modalOnFinish = () => {
    const dto: IllnessCertificateDtoResponse = {
      id: modalValues!.illnessCertificate.id,
      diseaseCode: modalValues!.illnessCertificate.diseaseCode,
      doctorFullName: modalValues!.illnessCertificate.doctorFullName,
      issuerFullName: modalValues!.illnessCertificate.issuerFullName,
      issuedFullName: modalValues!.illnessCertificate.issuedFullName,
      issuedDate:
        modalValues!.illnessCertificate.issuedDate === null ? new Date() : modalValues!.illnessCertificate.issuedDate,
      certificateStartDate:
        modalValues!.illnessCertificate.certificateStartDate === null
          ? new Date()
          : modalValues!.illnessCertificate.certificateStartDate,
      certificateEndDate:
        modalValues!.illnessCertificate.certificateEndDate === null
          ? new Date()
          : modalValues!.illnessCertificate.certificateEndDate,
    };

    IllnessCertificateService.updateCertificateInfo(dto)
      .then((data) => {
        notification.success({
          message: 'Success',
          description: 'Your data successfully updated',
          duration: 1.5,
        });
      });
    fetchDataForOffice();
    handleCancel();
  };

  const modalOnFinishFailed = () => {
    errorNotification('Something wrong with service. Try again');
  };

  const getFullDate = (val: string): string => {
    const date = new Date(val + 'Z').toLocaleString('ru-RU', { dateStyle: 'long' });
    const time = new Date(val + 'Z').toLocaleTimeString();
    return `${date} ${time}`;
  };

  const fetchDataForStudents = async () => {
    await UserService.getProfileByPrincipal().then((data) => {
      IllPersonService.getCurrentStatus(data.data.student.id).then(({ data }) => {
        setStudent(data);
      });
    });
    await IllnessCertificateService.getStudentStatus().then(({ data }) => {
      setStudentDiseases(data);
    });
  };

  const fetchDataForOffice = () => {
    TranscriptService.getAllStudentsGroup().then(({ data }) => {
      let res = data.map((item) => {
        return {
          text: item.title,
          value: item.title,
        };
      });
      setFilterRows(res);
    });
    IllPersonService.getAll().then(({ data }) => {
      setDiseases(data);
    });
  };

  useEffect(() => {
    if (grantPermission('OFFICE')) {
      fetchDataForOffice();
    } else if (grantPermission('STUDENTS')) {
      fetchDataForStudents();
      checkLastStatus();
    }
  }, []);

  useEffect(() => {
    IllnessCertificateService.getStudentStatus().then(({ data }) => {
      const latestSubmittion = data[0]?.status;
      if (latestSubmittion === 'CERTIFICATE_UPLOADED' || latestSubmittion === 'CERTIFICATE_LATE_SUBMIT')
        setUploaded(true);
      else if (latestSubmittion === 'REJECTED') setLatestSubmissionRejected(true);
    });
  }, []);

  const showDiseaseStatusModal = () => {
    if (!checkLastStatus()) {
      setVisibleDiseaseStatus(true);
    }
  };

  const checkLastStatus = () => {
    for (let i = 0; i < studentDiseases.length; i++) {
      if (
        studentDiseases[i].status === IllPersonStatus.CERTIFICATE_UPLOADED ||
        studentDiseases[i].status === IllPersonStatus.CERTIFICATE_LATE_SUBMIT
      ) {
        setDisabled(true);
        return true;
      }
    }
    return false;
  };

  const showDiseaseStatusText = () => {
    if (student === void 0)
      return (
        <Tag icon={<SyncOutlined spin />} color="default">
          processing
        </Tag>
      );
    if (uploaded)
      return (
        <Tag color="blue" icon={<SolutionOutlined />}>
          Awaiting for office approval
        </Tag>
      );
    if (student.isIll === null)
      return (
        <Tag color="green" icon={<LikeOutlined />}>
          Healthy
        </Tag>
      );
    else if (student.isIll === true)
      return (
        <Tag color="red" icon={<WarningOutlined />}>
          Got Sick
        </Tag>
      );
    else if (student.isIll === false)
      return (
        <Tag color="warning" icon={<SolutionOutlined />}>
          Awaiting certificate upload
        </Tag>
      );

    // if(student)
  };

  return {
    diseases,
    setDiseases,
    studentDiseases,
    setStudentDiseases,
    visible,
    setVisible,
    visibleDiseaseStatus,
    setVisibleDiseaseStatus,
    modalValues,
    setModalValues,
    filterRows,
    setFilterRows,
    disabled,
    setDisabled,
    spinner,
    setSpinner,
    student,
    setStudent,
    uploaded,
    setUploaded,
    studentColumns,
    setLatestSubmissionRejected,
    latestSubmissionRejected,
    officeColumns,
    checkStatus,
    checkForEmptyOrNull,
    rejectCertificate,
    approveCertificate,
    downloadCertificateForOffice,
    downloadCertificate,
    concatValues,
    showModal,
    handleCancel,
    modalOnFinish,
    modalOnFinishFailed,
    getFullDate,
    fetchDataForStudents,
    fetchDataForOffice,
    showDiseaseStatusModal,
    checkLastStatus,
    showDiseaseStatusText,
  };
};

export default useDisease;
