import { TableColumnsType } from 'antd';
import { Table } from 'antd';
import { useState } from 'react';
import { SummarySheetForTranscriptDtoResponse } from '../../../../interfaces/SummarySheetForTranscriptDtoResponse';
import { AcademicDebtService } from '../../../../services/AcademicDebtService';
import { MinNumberOfAcademicDebtsResponse } from '../../../../services/MinNumberOfAcademicDebtsResponse';

interface DataType {
  key: number | string;
  id: number;
  userId: number;
  fullName: string;
  group: string;
  count: number;
  // debtDisciplines: ExpandedDataType[];
}

interface ExpandedDataType {
  key: number | string;
  disciplineName: string;
  credits: number | string;
  midTerm?: number;
  endTerm?: number;
  final?: number;
  term: number | string;
}

interface Props {
  tableData: MinNumberOfAcademicDebtsResponse[];
  loading: boolean;
}

const DebtTableForOffice = ({ tableData, loading }: Props) => {

  const [expandableData, setExpandableData] = useState<SummarySheetForTranscriptDtoResponse[]>([]);
  const [expandedRow, setExpandedRow] = useState<any>([]);
  const [expandableLoading, setExpandableLoading] = useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const keys: any = [];

  const columns: TableColumnsType<DataType> = [
    { title: '№', dataIndex: 'id', key: 'id' },
    { title: 'Full Name', dataIndex: 'fullName', key: 'fullName' },
    { title: 'Group', dataIndex: 'group', key: 'group' },
    { title: 'Number of debts', dataIndex: 'count', key: 'count', sorter: (a, b) => a.count - b.count },
  ];

  const data: DataType[] = [];

  for (let i = 0; i < tableData.length; ++i) {
    data.push({
      key: i + 1,
      id: i + 1,
      userId: tableData[i].studentDtoResponse.userId,
      fullName: `${tableData[i].studentDtoResponse?.nameKz} ${tableData[i].studentDtoResponse?.surnameKz} ${tableData[i].studentDtoResponse?.patronymicKz}`,
      group: tableData[i].studentDtoResponse?.group.title,
      count: tableData[i]?.count,
    });
  }

  const expandedRowRender = (record: DataType, index: number, indent: any, expanded: boolean) => {
    const columns: TableColumnsType<ExpandedDataType> = [
      { title: '№', dataIndex: 'key', key: 'key' },
      { title: 'Subject Name', dataIndex: 'disciplineName', key: 'disciplineName' },
      { title: 'Credits', dataIndex: 'credits', key: 'credits' },
      {
        title: 'Grades',
        children: [
          {
            title: 'Midterm',
            dataIndex: 'midTerm',
            key: 'midTerm',
          },
          {
            title: 'Endterm',
            dataIndex: 'endTerm',
            key: 'endTerm',
          },
          {
            title: 'Final',
            dataIndex: 'final',
            key: 'final',
          },
        ],
      },
      {
        title: 'Term',
        dataIndex: 'term',
        key: 'term',
      },
    ];
    const data: ExpandedDataType[] = [];
    console.log(expandableData)
    for (let i = 0; i < expandableData.length; ++i) {
      console.log(expandableData)
      data.push({
        key: i + 1,
        disciplineName: `${expandableData[i].academicStream.discipline.titleEn}/${expandableData[i].academicStream.discipline.titleRu}/${expandableData[i].academicStream.discipline.titleKz}`,
        credits: expandableData[i].academicStream.discipline.volumeCredits,
        midTerm: expandableData[i]?.academicStreamStudentGrade[0]?.grade,
        endTerm: expandableData[i]?.academicStreamStudentGrade[1]?.grade,
        final: expandableData[i]?.academicStreamStudentGrade[2]?.grade,
        term: expandableData[i].academicStream.term,
      });
    }
    return <Table columns={columns} dataSource={data} pagination={false} bordered={true} loading={expandableLoading} />;
  };

  const handleExpand = (data: DataType, expanded: boolean) => {
    if (expanded) {
      keys.push(data.id);
    }
    setExpandedRow(keys)
    setExpandableLoading(true);
    setExpandedRowKeys(keys);
    AcademicDebtService.AcademicDebtsForStudentById(data.userId)
      .then((res) => {
        setExpandableData(res.data);
        console.log(res.data)
      })
      .finally(() => setExpandableLoading(false));
  };

  const onTableRowExpand = (expanded: boolean, record: any) => { };

  return (
    <>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
          onExpand: (expanded, record) => handleExpand(record, expanded),
          expandedRowKeys: expandedRow,
        }}
        expandedRowKeys={expandedRowKeys}
        onExpand={onTableRowExpand}
        dataSource={data}
        bordered={true}
        expandRowByClick={true}

      />
    </>
  );
};

export default DebtTableForOffice;
