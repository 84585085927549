export class PersistentStateConstants {
  static STUDENT_DETAILS_SEARCH_QUERY = 'STUDENT_DETAILS_SEARCH_QUERY';
  static ORDERS_PAGE_INPUT_FIELDS = 'ORDERS_PAGE_INPUT_FIELDS';
  static STUDENT_DEBT_INPUT_FIELDS = 'STUDENT_DEBT_INPUT_FIELDS';
  static DIPLOMA_SUPPLEMENT_INPUT_FIELDS = 'DIPLOMA_SUPPLEMENT_INPUT_FIELDS';
  static LEARNING_OUTCOMES_SELECT_OPTION = 'LEARNING_OUTCOMES_SELECT_OPTION';
  static DISCIPLINES_CATALOG_INPUT_FIELDS = 'DISCIPLINES_CATALOG_INPUT_FIELDS';
  static STUDENT_EDU_PROGRAM_INPUT_FIELDS = 'STUDENT_EDU_PROGRAM_INPUT_FIELDS';
  static STATEMENT_INPUT_FIELDS = 'STATEMENT_INPUT_FIELDS';
  static TRANSCRIPT_INPUT_FIELDS = 'TRANSCRIPT_INPUT_FIELDS';
}
