import styles from './style.module.css';
import { Typography, Table, Button, Pagination } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import useAgreementTypePage from './useAgreementTypePage';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;


const AcademicMobilityPage = () => {
    const { agreementType, column } = useAgreementTypePage();

    return (
        <PageWrapper>
            <section>
                <header className={styles.header}>
                    <Title level={3} className={styles.title}>
                        Agreement Type List
                    </Title>
                    <Link to="/agreement-type/create">
                        <Button type="primary" icon={<PlusOutlined />}>
                            Add new agreement type
                        </Button>
                    </Link>

                </header>
                <Table

                    dataSource={agreementType.map((agreementType) => ({
                        key: agreementType.id,
                        id: agreementType.id,
                        name: agreementType.name ? agreementType.name : '',
                        nameRu: agreementType.nameRu ? agreementType.nameRu : '',
                        nameEn: agreementType.nameEn ? agreementType.nameEn : '',


                    }))}
                    columns={column}
                />

            </section>
        </PageWrapper>
    )
}

export default AcademicMobilityPage;