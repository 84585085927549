import React from 'react'
import UniversalTable from '../UniversalTeachersTable';
import { TeacherService } from '../../../../services/TeacherService';
import { PrimaryEmploymentTypeDto, TeacherExternalPartTimeInfoDtoRequest, TeacherExternalPartTimeInfoDtoResponse } from '../../../../interfaces/Teacher/TeacherExternalPartTimeInfoDto';
import { successNotification } from '../../../../helpers/successNotification';
import useFetchData from '../../../../hooks/useFetchData';
import grantPermission from '../../../../helpers/grantPermission';
import { Field } from '../../../../interfaces/Field';

interface Props {
    userId?: number;
}

const TeacherExternalPartTable = ({ userId }: Props) => {
    const callbackExternalPartTimeInfo = React.useCallback(() => {
        if (grantPermission('HR') && userId) {
            return TeacherService.getTeacherExternalPartTimeInfoByUserId(userId);
        } else {
            return TeacherService.getTeacherExternalPartTimeInfo();
        }
    }, [userId])

    const { data: extPartTypes } = useFetchData<PrimaryEmploymentTypeDto[]>(TeacherService.getTeacherExternalPartTypes, (grantPermission('HR') && userId) ? true : false);
    const { data: extPart, fetchData: fetchExtPart } = useFetchData<TeacherExternalPartTimeInfoDtoResponse>(callbackExternalPartTimeInfo, true);

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Contract start date',
            dataIndex: 'contractStartDate',
            key: 'contractStartDate',
        },
        {
            title: 'Contract finish date',
            dataIndex: 'contractFinishDate',
            key: 'contractFinishDate',
        },
        {
            title: 'By Agreement',
            dataIndex: 'byAgreement',
            key: 'byAgreement',
            render: (text: boolean) => text ? 'Yes' : 'No',
        },
        {
            title: 'Employment Type',
            dataIndex: 'primaryEmploymentTypeName',
            key: 'primaryEmploymentTypeName',
        },
    ];

    const addRecord = (record: TeacherExternalPartTimeInfoDtoRequest) => {
        record.teacherId = userId ? userId : 0;
        TeacherService.createOrUpdateTeacherExternalPartTimeInfo(record)
            .then(() => {
                fetchExtPart();
                successNotification('External info added!')
            })
    };

    const editRecord = (record: TeacherExternalPartTimeInfoDtoRequest) => {
        TeacherService.createOrUpdateTeacherExternalPartTimeInfo(record)
            .then(() => {
                fetchExtPart();
                successNotification('External info updated!')
            })
    };

    const deleteRecord = (record: TeacherExternalPartTimeInfoDtoRequest) => {

    };

    const fields: Field[] = [
        {
            name: 'primaryEmploymentTypeId', label: 'Employment Type', type: 'select', options: extPartTypes?.map((type) => {
                return { value: type.id, label: type.name }
            })
        },
        { name: 'contractStartDate', label: 'Contract start date', type: 'date' },
        { name: 'contractFinishDate', label: 'Contract finish date', type: 'date' },
        { name: 'byAgreement', label: 'By Agreement', type: 'checkbox' },
    ]

    return (
        <UniversalTable
            columns={columns}
            dataSource={extPart ? [{
                key: 1,
                teacherId: userId,
                contractStartDate: extPart.contractStartDate,
                contractFinishDate: extPart.contractFinishDate,
                byAgreement: extPart.byAgreement,
                primaryEmploymentTypeName: extPart.primaryEmploymentType.name,
                primaryEmploymentTypeId: extPart.primaryEmploymentType.id
            }] : []}
            addRecord={(e: TeacherExternalPartTimeInfoDtoRequest) => addRecord(e)}
            editRecord={(e: TeacherExternalPartTimeInfoDtoRequest) => editRecord(e)}
            deleteRecord={(e: TeacherExternalPartTimeInfoDtoRequest) => deleteRecord(e)}
            fields={fields}
            hideDelete={grantPermission('HR') ? false : true}
            hideEdit={grantPermission('HR') ? false : true}
            hideCreate={grantPermission('HR') ? false : true}
            hideActions={grantPermission('HR') ? false : true}
            isPublic={false}
        />
    )
}

export default TeacherExternalPartTable