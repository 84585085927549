import authAxios from "../common/authAxios";
import { BenefitsDtoResponse } from "../interfaces/Student/BenefitsDtoResponse";
import { StudentBenefitDtoRequest } from "../interfaces/Student/StudentBenefitDtoRequest";
import { StudentBenefitDtoResponse } from "../interfaces/Student/StudentBenefitDtoResponse";
import { StudentRetiresDtoRequest } from "../interfaces/Student/StudentRetiresDtoRequest";
import { StudentRetiresDtoResponse } from "../interfaces/Student/StudentRetiresDtoResponse";

export class EpvoService {
    private static baseUrl = '/astanait-epvo-module/api/v1';


    static syncWithEPVO(educationProgramId: number) {
        return authAxios.put(`${this.baseUrl}/epvo/sync/${educationProgramId}`)
    }

    // STUDENTS
    static syncStudentsWithEPVO(params: any) {
        for (const key of Object.keys(params)) {
            if (!params[key]) {
                delete params[key];
            }
        }

        return authAxios.get(
            `${this.baseUrl}/epvo/students/convert`,
            { params }
        );
    }

    static syncStudentWithEPVO(studentId: number) {
        return authAxios.get(
            `${this.baseUrl}/epvo/students/convert/${studentId}`,
        );
    }

    // RETIRES
    static getStudentRetires() {
        return authAxios.get<StudentRetiresDtoResponse[]>(`/astanait-epvo-module/api/v1/epvo/retires/get-all`)
    }

    static createOrUpdateStudentRetire(dto: StudentRetiresDtoRequest, id?: number) {
        if (id) {
            return authAxios.post(`${this.baseUrl}/retires/create-or-update?id=${id}`, dto)
        }
        else {
            return authAxios.post(`${this.baseUrl}/epvo/retires/create-or-update`, dto)
        }
    }

    static syncRetires() {
        return authAxios.post(`${this.baseUrl}/epvo/retires/sync`)
    }

    // BENEFITS
    static getBenefits() {
        return authAxios.get<StudentBenefitDtoResponse[]>(`/astanait-epvo-module/api/v1/epvo/benefits/get-all`)
    }

    static getBenefitsTypes() {
        return authAxios.get<BenefitsDtoResponse[]>(`/astanait-epvo-module/api/v1/epvo/benefits/types`)
    }

    static createOrUpdateBenefit(dto: StudentBenefitDtoRequest, id?: number) {
        if (id) {
            return authAxios.post(`${this.baseUrl}/epvo/benefits/create-or-update?id=${id}`, dto)
        }
        else {
            return authAxios.post(`${this.baseUrl}/epvo/benefits/create-or-update`, dto)
        }
    }

    static syncBenefits() {
        return authAxios.post(`${this.baseUrl}/epvo/benefits/sync`)
    }

}