import { Typography } from 'antd';
import { useState } from 'react';
import PageWrapper from '../../../../ui/PageWrapper';
import StudentSearchForm from './StudentSearchForm';
import { StudentDtoResponse } from '../../../../interfaces/Student/StudentDtoResponse';
import StudentsTable from './StudentsTable';

const CreateNotificationPage = () => {
    const [studentsList, setStudentsList] = useState<StudentDtoResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleData = (studentsList: StudentDtoResponse[], loading: boolean) => {
        setStudentsList(studentsList);
        setLoading(loading);
    };

    return (
        <PageWrapper>
            <Typography.Title level={3}>Create Notification</Typography.Title>
            <StudentSearchForm onData={handleData} isDetails={true} />

            {studentsList.length > 0 && <Typography.Title level={5}>Найдено студентов: {studentsList.length}</Typography.Title>}

            {studentsList.length > 0 && <StudentsTable loading={loading} data={studentsList} />}
        </PageWrapper >
    );
};

export default CreateNotificationPage;