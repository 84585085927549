import React, { useEffect, useState } from 'react';
import { Form, Input, Select, InputNumber, Button, Card } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { AcademicDegreeDtoResponse } from '../../../interfaces/AcademicDegreeDtoResponse';
import { EducationProgramDtoResponse } from '../../../interfaces/EducationalPrograms/EducationProgramDtoResponse';
import { GraduationThesisTypeDtoResponse } from './GraduationThesisTypeDtoResponse';
import { GraduationThesisLimitDtoResponse } from './GraduationThesisLimitDtoResponse';

const { Option } = Select;

interface TeacherGraduationThesisFormProps {
    academicDegrees: AcademicDegreeDtoResponse[];
    graduationThesisTypes: GraduationThesisTypeDtoResponse[];
    educationPrograms: EducationProgramDtoResponse[];
    limits: GraduationThesisLimitDtoResponse[];
    individual: boolean;
    form: any;
    setLimits: React.Dispatch<React.SetStateAction<GraduationThesisLimitDtoResponse[]>>;
    handleDegreeChange: (value: number) => void;
    handleIndividualChange: (value: boolean) => void;
    handleAddEducationProgram: () => void;
    handleRemoveEducationProgram: (index: number) => void;
}

const TeacherGraduationThesisForm: React.FC<TeacherGraduationThesisFormProps> = ({
    academicDegrees,
    graduationThesisTypes,
    educationPrograms,
    limits,
    individual,
    form,
    setLimits,
    handleDegreeChange,
    handleIndividualChange,
    handleAddEducationProgram,
    handleRemoveEducationProgram
}) => {
    return (
        <Form form={form} layout="vertical">
            {/* <Form.Item
                        name="defenseYear"
                        label="Defense Year"
                        rules={[{ required: true, message: 'Please input the defense year!' }]}
                    >
                        <InputNumber min={2019} max={2100} style={{ width: '100%' }} onChange={handleDefenseYearChange} />
                    </Form.Item> */}
            <Form.Item
                name="titleEn"
                label="Diploma Work Title (EN)"
                rules={[{ required: true, message: 'Please input the title in English!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="titleKz"
                label="Diploma Work Title (KZ)"
                rules={[{ required: true, message: 'Please input the title in Kazakh!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="titleRu"
                label="Diploma Work Title (RU)"
                rules={[{ required: true, message: 'Please input the title in Russian!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="isFree"
                label="Free"
                rules={[{ required: true, message: 'Please select!' }]}
            >
                <Select>
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                </Select>
            </Form.Item>
            <Form.Item
                name={['type', 'academicDegree', 'id']}
                label="Degree"
                rules={[{ required: true, message: "Please select the degree!" }]}
            >
                <Select onChange={handleDegreeChange}>
                    {academicDegrees.map((degree) => (
                        <Option key={degree.id} value={degree.id}>
                            {degree.titleEn}
                        </Option>
                    ))}
                </Select>
            </Form.Item>


            <Form.Item
                name={['type', 'id']}
                label="Graduation Thesis Type"
                rules={[{ required: true, message: "Please select the Graduation Thesis Type!" }]}
            >
                <Select
                    onChange={(value) => {
                        form.setFieldsValue({
                            graduationThesisTypeId: value
                        });
                    }}
                >
                    {graduationThesisTypes.map((type) => (
                        <Option key={type.id} value={type.id}>
                            {type.nameEn}
                        </Option>
                    ))}
                </Select>
            </Form.Item>


            <Form.Item
                name="individual"
                label="Individual/Group"
                rules={[{ required: true, message: 'Please select work type!' }]}
            >
                <Select onChange={handleIndividualChange}>
                    <Option value={true}>Individual</Option>
                    <Option value={false}>Group</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="requiredStudentsNumber"
                label="Required Students Number"
                rules={[{ required: true, message: 'Please select the number of required students!' }]}
            >
                <Select
                    disabled={individual === true}
                    onChange={(value) => {
                        setLimits(limits.map(limit => ({ ...limit, deleted: true })));
                        form.setFieldsValue({ limits: [] });
                    }}
                >
                    {individual === true ? (
                        <Option value={1}>1</Option>
                    ) : (
                        <>
                            <Option value={2}>2</Option>
                            <Option value={3}>3</Option>
                        </>
                    )}
                </Select>
            </Form.Item>

            <Card style={{
                padding: '5px',
                marginBottom: '8px',
            }}>
                {limits.map((limit, index) => (
                    !limit.deleted && (
                        <div key={limit.id}
                            style={{
                                display: 'flex', gap: '8px',

                                justifyContent: 'space-around', alignItems: 'center'
                            }}>
                            <Form.Item
                                label="Limit"
                                name={['limits', index, 'limit']}
                                rules={[{ required: true, message: 'Please input the limit!' }]}
                            >
                                <InputNumber min={1} max={3} />
                            </Form.Item>
                            <Form.Item
                                label="Education Program"
                                name={['limits', index, 'educationProgram', 'id']}
                                rules={[{ required: true, message: 'Please input the education program!' }]}
                            >
                                <Select style={{ width: '100%' }} placeholder="Select an education program">
                                    {educationPrograms?.map((program) => (
                                        <Option key={program.id} value={program.id}>
                                            {program.titleEn}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Button
                                type="link"
                                icon={<DeleteOutlined />}
                                onClick={() => handleRemoveEducationProgram(index)}
                            />
                        </div>
                    )
                ))}
            </Card>
            <Button type="dashed"
                onClick={handleAddEducationProgram}
                style={{ width: '100%' }}
                disabled={limits.filter(limit => !limit.deleted).length >= (form.getFieldValue('requiredStudentsNumber') || 1)}>
                <PlusOutlined /> Add Education Program
            </Button>
        </Form>
    );
};

export default TeacherGraduationThesisForm;
