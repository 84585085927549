import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import AuthService from "../services/authService";

interface Props {
  Component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
}

const handlePublicRoutes = () => {
  const publicRoutes = [
    "/public-teachers-list",
    "/public-certificate-verification",
    "/public/register-number",
  ];
  const certificate: RegExp = new RegExp("/certificate-web-page/[0-9]+");
  const certificateEn: RegExp = new RegExp("/certificate-web-page-en/[0-9]+");
  const teacherPage: RegExp = new RegExp("/public/teacher/[0-9]+");

  if (publicRoutes.some(route => window.location.href.endsWith(route))) {
    return true;
  }
  else if (certificate.test(window.location.href) === true) {
    return true;
  }
  else if (certificateEn.test(window.location.href) === true) {
    return true;
  }
  else if (teacherPage.test(window.location.href) === true) {
    return true;
  }
  else {
    return false;
  }
}

const AuthorizedRoute = ({
  Component,
  path,
  exact = false,
}: Props): JSX.Element => {
  const message = "You must be logged in to view the requested page";
  const isPublicRoute = handlePublicRoutes();

  return (
    <Route
      path={path}
      exact={exact}
      render={(props: RouteComponentProps) => {
        if (isPublicRoute) {
          return (
            <Redirect
              to={{ pathname: props.location.pathname, state: { message, requestedPath: path } }}
            />
          );
        } else {
          return AuthService.isLoggedIn() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { message, requestedPath: path } }}
            />
          );
        }
      }}
    />
  );
};

export default AuthorizedRoute;