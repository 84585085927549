export class PersistentStateService {
  static setInputFieldsInfo(name: string, fields: Object) {
    sessionStorage.setItem(name, JSON.stringify(fields));
  }

  static getInputFieldsInfo(name: string) {
    return sessionStorage.getItem(name);
  }

  static hasInputFieldsInfo(name: string) {
    // return !!JSON.parse(this.getInputFieldsInfo(name));
    return (
      // @ts-ignore
      this.getInputFieldsInfo(name) !== null && Object.keys(JSON.parse(this.getInputFieldsInfo(name))).length !== 0
    );
  }
}
