import React from 'react'
import { StudyStream, StudyStreamDtoResponse } from '../../../../interfaces/Syllabus/StudyStreamDtoResponse';
import { Modal, Steps, Table, Transfer } from 'antd';
import { ColumnsType } from 'antd/lib/table';

interface SyllabusStudyStreamsTableProps {
    syllabus: StudyStreamDtoResponse;
    setStudyStreams: React.Dispatch<React.SetStateAction<StudyStreamDtoResponse | undefined>>;
}


const SyllabusStudyStreamsTable = ({ syllabus, setStudyStreams }: SyllabusStudyStreamsTableProps) => {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [currentStep, setCurrentStep] = React.useState<number>(0);
    const [sourceRow, setSourceRow] = React.useState<StudyStream>();
    const [targetRow, setTargetRow] = React.useState<StudyStream>();

    const columns: ColumnsType<StudyStream> = [
        {
            dataIndex: ['groupsIds', 'studentCountByGroup'],
            title: 'Группы',
            render: (value: any, record: StudyStream, index: number) =>
                record.groupsIds.map((groupId, index) => `${syllabus.groups[groupId]}- (${record.studentCountByGroup[index]} students)`).join(", ")
        },
        {
            dataIndex: 'studentCount',
            title: 'Кол-во студентов',
            render: (value: any, record: StudyStream, index: number) =>
                record.studentCountByGroup.reduce((acc, item) => acc + item, 0)
        },
        {
            dataIndex: 'groupCount',
            title: 'Кол-во групп',
            render: (value: any, record: StudyStream, index: number) =>
                record.groupsIds.length
        },
        {
            dataIndex: 'streamTypeId',
            title: 'Тип',
            render: (streamTypeId: number) => streamTypeId === 1 ? "Лекция" : "Практика"
        },
        {
            dataIndex: 'disciplineName',
            title: 'Дисциплина',
        },
        {
            dataIndex: 'disciplineId',
            title: 'ID урока',
        },
        {
            dataIndex: 'departmentId',
            title: 'Департамент',
            render: (departmentId: number) => syllabus.departments[departmentId]
        },
    ];



    const handleSourceRowSelect = (record: any) => {
        setSourceRow(record);
        setCurrentStep(1);
    };

    const handleTargetRowSelect = (record: any) => {
        setTargetRow(record);
        setCurrentStep(2);
    };

    const handleTransferChange = async (targetKeys: string[], direction: 'left' | 'right', moveKeys: string[]) => {
        if (sourceRow && targetRow) {
            switch (direction) {
                case 'left':
                    const leftCounts = moveKeys.map(key => {
                        const index = targetRow.groupsIds.findIndex(groupId => groupId.toString() === key);
                        return syllabus.studyStreamList.find(item => item.key === targetRow.key)?.studentCountByGroup[index] || 0;
                    });

                    setSourceRow((prevSourceRow: StudyStream | undefined) => {
                        if (prevSourceRow) {
                            return {
                                ...prevSourceRow,
                                groupsIds: [...prevSourceRow.groupsIds, ...moveKeys.map(key => parseInt(key))],
                                studentCountByGroup: [...prevSourceRow.studentCountByGroup, ...leftCounts],
                            };
                        }
                        return prevSourceRow;
                    });

                    setTargetRow((prevTargetRow: StudyStream | undefined) => {
                        if (prevTargetRow) {
                            return {
                                ...prevTargetRow,
                                groupsIds: prevTargetRow.groupsIds.filter(groupId => !moveKeys.includes(groupId.toString())),
                                studentCountByGroup: prevTargetRow.studentCountByGroup.filter((_, index) => !moveKeys.includes(targetRow.groupsIds[index].toString())),
                            };
                        }
                        return prevTargetRow;
                    });

                    break;
                case 'right':
                    const rightCounts = moveKeys.map(key => {
                        const index = sourceRow.groupsIds.findIndex(groupId => groupId.toString() === key);
                        return syllabus.studyStreamList.find(item => item.key === sourceRow.key)?.studentCountByGroup[index] || 0;
                    });

                    setSourceRow(prevSourceRow => {
                        if (prevSourceRow) {
                            return {
                                ...prevSourceRow,
                                groupsIds: prevSourceRow.groupsIds.filter(groupId => !moveKeys.includes(groupId.toString())),
                                studentCountByGroup: prevSourceRow.studentCountByGroup.filter((_, index) => !moveKeys.includes(sourceRow.groupsIds[index].toString())),
                            };
                        }
                        return prevSourceRow;
                    });

                    setTargetRow(prevTargetRow => {
                        if (prevTargetRow) {
                            return {
                                ...prevTargetRow,
                                groupsIds: [...prevTargetRow.groupsIds, ...moveKeys.map(key => parseInt(key))],
                                studentCountByGroup: [...prevTargetRow.studentCountByGroup, ...rightCounts],
                            };
                        }
                        return prevTargetRow;
                    });

                    break;
            }
        }

    };

    const handleModalOpen = () => {
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setCurrentStep(0);
        setTargetRow(undefined);
        setSourceRow(undefined);
    };

    const handleFinishModal = () => {
        setStudyStreams({
            ...syllabus,
            studyStreamList: syllabus.studyStreamList.map((item) => {
                if (item.key === sourceRow?.key) {
                    return sourceRow;
                }

                if (item.key === targetRow?.key) {
                    return targetRow;
                }

                return item;
            })
        });
        handleModalClose();
    }

    return (
        <>
            <Table
                onRow={(record) => ({
                    onClick: () => {
                        handleSourceRowSelect(record);
                        handleModalOpen();
                    },
                })}
                columns={columns}
                rowKey={(record) => record.key}
                dataSource={syllabus.studyStreamList}
            />
            <Modal width={700} open={isModalVisible} onOk={handleFinishModal} onCancel={handleModalClose}>
                <Steps items={[
                    {
                        title: "Select source row",
                        description: "Select source row",
                    },
                    {
                        title: "Select target row",
                        description: "Select target row",
                    },
                    {
                        title: "Transfer groups",
                        description: "Transfer groups",
                    }
                ]}
                    current={currentStep}
                    size={"small"}
                    style={{ marginBottom: 50, marginTop: 10 }}
                />

                {currentStep === 1 && (
                    <Table
                        onRow={(record) => ({
                            onClick: () => handleTargetRowSelect(record),
                        })}
                        rowKey={(record) => record.key}
                        columns={columns}
                        dataSource={syllabus.studyStreamList.filter((item) => item !== sourceRow && item.disciplineId === sourceRow?.disciplineId)}
                    />
                )}

                {currentStep === 2 && targetRow && sourceRow && (
                    <Transfer
                        dataSource={[
                            ...sourceRow.groupsIds.map(groupId => ({
                                key: groupId.toString(),
                                title: syllabus.groups[groupId] + "-" + sourceRow.studentCountByGroup[sourceRow.groupsIds.findIndex(item => item === groupId)] + " students",
                            })),
                            ...targetRow.groupsIds.map(groupId => ({
                                key: groupId.toString(),
                                title: syllabus.groups[groupId] + "-" + targetRow.studentCountByGroup[targetRow.groupsIds.findIndex(item => item === groupId)] + " students",
                            })),
                        ]}
                        targetKeys={targetRow.groupsIds.map(groupId => groupId.toString())}
                        onChange={handleTransferChange}
                        render={item => item.title}
                        titles={[sourceRow.disciplineName, targetRow.disciplineName]}
                        listStyle={{
                            width: "50%",
                            height: 300,
                        }}

                    />
                )}
            </Modal>
        </>
    )
}

export default SyllabusStudyStreamsTable