import { Table } from 'antd';
import React from 'react';

interface TableViewProps {
  columns: any[];
  renderItem: any[]; // Add the return type here
}

const TableView: React.FC<TableViewProps> = ({ columns, renderItem }) => (
  <Table columns={columns} dataSource={renderItem} />
);

export default TableView;
