import { DownloadOutlined } from '@ant-design/icons';
import { Button, Modal, Form, Space, Table, Tag, InputRef, Col } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { Moment } from 'moment';
import { errorNotification } from '../../../../helpers/errorNotification';
import { IrosApplicationProjection } from '../../../../interfaces/Iros/IrosApplicationProjection';
import { IrosService } from '../../../../services/student/IrosService';
import useIros from '../useIros';
import { getColumnSearchProps } from '../../../../common/getColumnSearchProps';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import FormItem from 'antd/es/form/FormItem';
import { useForm } from 'antd/lib/form/Form';
import { successNotification } from '../../../../helpers/successNotification';

interface Props {
    approved: boolean | undefined;
    applicationName: string | undefined;
    startDate: Moment | undefined;
    endDate: Moment | undefined;
}

interface DataType {
    key: number | string;
    id: number | string;
    approved: boolean;
    indexStatus: number | string;
    indicator: number | string,
    createdDate: Date | string;
    fileId: number | string;
    student: number | string;
    group: number | string;
    course: number | string;
}
interface FilterRow {
    text: string;
    value: string;
}

const ApplicationsTable = ({ approved, applicationName, startDate, endDate }: Props) => {
    const [form] = useForm();
    const { data, handlers } = useIros();
    const inputRef = React.useRef() as React.Ref<InputRef>;
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number | undefined>(10);
    const [tableDataForOffice, setTableDataForOffice] = useState<IrosApplicationProjection[]>([]);
    const [totalTableDataForOffice, setTotalTableDataForOffice] = useState(0);
    const [filterRows, setFilterRows] = useState<FilterRow[]>([]);
    const [modalValue, setModalValue] = useState<any>();
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);


    useEffect(() => {
        setLoading(true);
        setTableDataForOffice([]);

        const delayedSearch = setTimeout(() => {
            IrosService.getApplicationsByFilter(currentPage == 0 ? 0 : currentPage - 1, pageSize, {
                startDate: startDate,
                endDate: endDate,
                name: applicationName,
                approved: approved,
            })
                .then(({ data }) => {
                    setTableDataForOffice(data.list);
                    setTotalTableDataForOffice(data.total_number);
                    if (data.list.length === 0) {
                        errorNotification(`Не найдено ни одного студента`);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }, 1000);
        return () => clearInterval(delayedSearch);
    }, [currentPage, pageSize, startDate, endDate, applicationName, approved, loader]);



    const columns: ColumnsType<DataType> = [
        {
            title: '№',
            dataIndex: 'key',
            key: 'key',
            render: (text: string, record: any, index: number) => {
                if (currentPage && pageSize) return (currentPage - 1) * pageSize + index + 1;
            },
        },
        {
            title: 'Student',
            dataIndex: 'student',
            key: 'student',
            ...getColumnSearchProps('student', inputRef),
        },
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
            filters: filterRows,
            onFilter: (value: any, record: any) => record.group.indexOf(value) === 0,
        },
        {
            title: 'Course',
            dataIndex: 'course',
            key: 'course',
            filters: [
                {
                    text: '3',
                    value: 3,
                },
                {
                    text: '2',
                    value: 2,
                },
                {
                    text: '1',
                    value: 1,
                },
            ],
            onFilter: (value: any, record: any) => record.course === value,
        },
        {
            title: 'Index status',
            dataIndex: 'indexStatus',
            key: 'indexStatus',
        },
        {
            title: 'Indicator',
            dataIndex: 'indicator',
            key: 'indicator',
            filters: data.lists.indicators.map((indicator) => {
                return {
                    text: indicator.nameRu,
                    value: indicator.nameRu,
                };
            }),
            onFilter: (value: any, record: any) => record.indicator.indexOf(value) === 0,
        },
        {
            title: 'Created date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (_: any, record: any, idx: any) => moment(record.createdDate).format('LL'),
            sorter: (a: any, b: any) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
        },
        {
            title: 'File',
            dataIndex: 'file',
            key: 'file',
            render: (text: any, record: any) => (
                <Space size={'small'}>
                    <Button type="default" icon={<DownloadOutlined />} onClick={() => handlers.handleDownloadFile(record.fileId)}>
                        Download file
                    </Button>
                    {/* <Button type="default" icon={<EditOutlined />} onClick={() => { handlers.handleEditModalOpen(true, record) }}>
                        Edit
                    </Button> */}
                </Space>
            ),
        },
        {
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) =>
                record.approved !== null ? (
                    record.approved === true ? (
                        <Tag color="green">Approved</Tag>
                    ) : (
                        <Button type="primary" danger onClick={() => handleRejectModal(record)}>
                            Show reason
                        </Button>
                    )
                ) : (
                    <Space size={'small'}>
                        <Button
                            type="primary"
                            onClick={() => {
                                handleUpdate(true, record.id);
                            }}
                        >
                            Agree
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={() => {
                                handleRejectModal(record);
                            }}
                        >
                            Disagree
                        </Button>
                    </Space>
                ),
        },
    ];


    const data1: DataType[] = [];

    for (let i = 0; i < tableDataForOffice.length; ++i) {
        data1.push({
            key: i + 1,
            id: tableDataForOffice[i].id,
            approved: tableDataForOffice[i].approved,
            indexStatus: tableDataForOffice[i].index,
            indicator: tableDataForOffice[i].indicator,
            createdDate: tableDataForOffice[i].createdDate,
            fileId: tableDataForOffice[i].fileId,
            student: tableDataForOffice[i].fullname,
            group: tableDataForOffice[i].group,
            course: tableDataForOffice[i].course,

        });
    }
    const handleRejectModal = (value: any) => {
        setIsRejectModalOpen(true);
        setModalValue(value);
    };
    const handleUpdate = (status: boolean | string, id: number, comment?: string) => {
        IrosService.updateStudentApplication({
            approved: status,
            comment: comment,
            id: id,
        })
            .then(() => {
                setLoader(!loader);
                successNotification('Successfully downloaded!');
            })
            .finally(() => {
                setIsRejectModalOpen(false);
                setLoader(!loader);
            });
    };


    return (
        <Col>
            <Table
                columns={columns}
                dataSource={data1}
                bordered={true}
                loading={loading}
                onChange={(pagination) => {

                    setCurrentPage(pagination.current ? pagination.current : 0);
                    setPageSize(pagination.pageSize);
                }}
                pagination={{ total: totalTableDataForOffice, pageSize: pageSize }}

            />
            <Modal
                open={isRejectModalOpen}
                title={'Reason'}
                onCancel={() => setIsRejectModalOpen(false)}
                onOk={form.submit}
            >
                {modalValue?.approved === null ? (
                    <Form
                        form={form}
                        onFinish={() => {
                            handleUpdate('false', modalValue.id, form.getFieldValue('comment'));
                        }}
                    >
                        <FormItem name="comment">
                            <TextArea />
                        </FormItem>
                    </Form>
                ) : (
                    <TextArea value={modalValue?.comment} readOnly />
                )}
            </Modal>
        </Col>

    );
};

export default ApplicationsTable;
