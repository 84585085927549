import { useState, useEffect } from 'react';
import ChooseDiscipline from './ChooseDisciplines';
import PageWrapper from '../../../ui/PageWrapper';
import { EducationalProgramsService } from '../../../services/EducationalProgramsService';

const StudentDisciplineEnrollment = () => {
  const [isAbleToChoose, setIsAbleToChoose] = useState<boolean | undefined>();

  useEffect(() => {
    Promise.all([EducationalProgramsService.checkStudentEPChoiceAccess()])
      .then(([isAbleToChoose]) => {
        setIsAbleToChoose(isAbleToChoose.data);
      })
  }, []);

  return (
    <PageWrapper>
      {/* <section>
        <header className={styles.header}>
          <Title level={3} className={styles.title}>
            Educational program selection for next academic year
          </Title>
        </header>
        <Space size="large" direction="vertical" style={{ display: 'flex' }}>
          {isAbleToChoose === undefined ? (
            <Spin />
          ) : isAbleToChoose ? (
            <ChooseEducationalProgram />
          ) : (
            <Title level={4} className={styles.announcement}>
              The selection of educational program is closed
            </Title>
          )}
        </Space>
      </section> */}
      <ChooseDiscipline />
    </PageWrapper>
  );
};

export default StudentDisciplineEnrollment;
