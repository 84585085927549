import { StudentsListDtoResponse } from '../../../../interfaces/Student/StudentsListDtoResponse';
import { Select } from 'antd';

type Options = StudentsListDtoResponse[];

const { Option } = Select;

interface Props {
  placeholder: string;
  selectedOption: number | undefined;
  options: Options;
  onSelect: (value: number) => void;
  width?: number;
  loading: boolean;
  onClear: () => void;
  componentSize?: 'large' | 'middle' | 'small';
}

const StudentsFilter = ({
  placeholder,
  selectedOption,
  options,
  onSelect,
  componentSize,
  loading,
  width,
  onClear,
}: Props) => {
  return (
    <Select
      placeholder={placeholder}
      size={componentSize}
      disabled={loading}
      loading={loading}
      value={selectedOption}
      onSelect={onSelect}
      style={{ width: width ? width : '100%' }}
      allowClear
      onClear={onClear}
    >
      {options.map((option) => (
        <Option key={option.userId} value={option.userId}>
          {`${option.name} ${option.surname}`}
        </Option>
      ))}
    </Select>
  );
};
export default StudentsFilter;
