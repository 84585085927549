import React from 'react'
import { TeacherAdditionalInfoTypesDto } from '../../../interfaces/Teacher/TeacherAdditionalInfoTypesDto'
import { Button, DatePicker, Form, FormInstance, Input, Upload, UploadProps } from 'antd'
import moment from 'moment'
import { UploadOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/lib/upload'

interface TeacherAdditionalDataTypeProps {
    teacherAdditionalInfo?: TeacherAdditionalInfoTypesDto
    form: FormInstance<any>;
}

const TeacherAdditionalDataType = ({ teacherAdditionalInfo, form }: TeacherAdditionalDataTypeProps) => {
    const [fileList, setFileList] = React.useState<any>([]);
    const label = teacherAdditionalInfo?.titleEn + "/" + teacherAdditionalInfo?.titleRu + "/" + teacherAdditionalInfo?.title;

    const props: UploadProps = {
        fileList: fileList,
        beforeUpload: async (file: RcFile) => {
            return await new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const base64String = (e.target?.result as string).split(',')[1];
                    teacherAdditionalInfo && form.setFieldsValue({ [teacherAdditionalInfo.name]: base64String })
                    setFileList([file]);
                    resolve(false);
                };

                reader.onerror = (error) => reject(error);

                if (file) {
                    reader.readAsDataURL(file);
                }
            });
        },
        onRemove: () => {
            setFileList([]);
            teacherAdditionalInfo && form.setFieldsValue({ [teacherAdditionalInfo.name]: "" })
        },
    };


    switch (teacherAdditionalInfo?.objectType) {
        case "string":
            return (
                <Form.Item
                    key={teacherAdditionalInfo.id}
                    name={"value"}
                    label={label}
                    rules={[{ required: true, message: `Please input your ${label}!` }]}
                >
                    <Input placeholder={`Enter ${label}`} />
                </Form.Item>
            )
        case "int":
            return (
                <Form.Item
                    key={teacherAdditionalInfo.id}
                    name={"value"}
                    label={label}
                    rules={[{ required: true, message: `Please input your ${label}!` }]}
                >
                    <Input type="number" placeholder={`Enter ${label}`} />
                </Form.Item>
            )
        case "date":
            return (
                <Form.Item
                    key={teacherAdditionalInfo.id}
                    name={"value"}
                    label={label}
                    rules={[{ required: true, message: `Please input your ${label}!` }]}
                    getValueProps={(i) => i ? { value: moment(i) } : { value: '' }}
                    getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                >
                    <DatePicker />
                </Form.Item>
            )
        case "file":
            return (
                <Form.Item
                    key={teacherAdditionalInfo.id}
                    name={"value"}
                    label={label}
                    rules={[{ required: true, message: `Please input your ${label}!` }]}
                >
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload >
                </Form.Item>
            )
        default:
            return <></>;
    }
}

export default TeacherAdditionalDataType