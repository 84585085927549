import { Avatar, Badge, Button, Col, Modal, Row, Space, Upload } from 'antd';
import { EditOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import { TeacherService } from '../../../services/TeacherService';
import { successNotification } from '../../../helpers/successNotification';

export default function TeacherAvatarSection() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');

  useEffect(() => {
    TeacherService.getTeacherImage().then((res) => {
      let imageUrl = URL.createObjectURL(res.data);
      setImage(imageUrl);
    });
  }, []);

  const handleUpload = () => {
    const formData = new FormData();
    // @ts-ignore
    formData.append('image', file);
    // You can use any AJAX library you like
    setLoading(true);
    TeacherService.uploadTeacherProfileImage(formData)
      .then((res) => {
        successNotification('Profile image updated!');
        setLoading(false);
        setIsModalVisible(false);
      })
      .catch((err) => {
        setLoading(false);
        setIsModalVisible(false);
      });
  };

  const props = {
    beforeUpload: (file: any) => {
      setFile(file);
      return false;
    },
  };

  let editButton = (
    <Button
      shape={'circle'}
      onClick={() => {
        setIsModalVisible(true);
      }}
      icon={<EditOutlined size={64} />}
    />
  );

  return (
    <>
      <Space style={{ width: '100%', justifyContent: 'center' }}>
        <Badge count={editButton}>
          <Avatar src={image} icon={<UserOutlined />} size={300} shape={'circle'} />
        </Badge>
      </Space>
      <Modal
        style={{ width: '50%' }}
        title="Update image"
        onOk={handleUpload}
        okButtonProps={{ loading }}
        okText={'Save'}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Row>
          <Col span={8}>
            <Avatar
              src={file ? file && URL.createObjectURL(file) : image}
              icon={<UserOutlined />}
              size={128}
              alt="avatar"
            />
          </Col>
          <Col span={16}>
            <Title level={5}>Your profile image</Title>

            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
