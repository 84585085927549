import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import { IrosStatus } from '../../../../interfaces/Iros/IrosStatus';
import { SsciCategoriesDtoResponse } from '../../../../interfaces/Ssci/SsciCategoriesDtoResponse';
import { SsciIndicatorsDtoResponse } from '../../../../interfaces/Ssci/SsciIndicatorsDtoResponse';
import { SsciStudentApplicationsWithPointsDto } from '../../../../interfaces/Ssci/Applications/SsciStudentApplicationsWithPointsDto';
import moment from 'moment';
import { useState } from 'react';

interface AddEventModalProps {
  isModalOpen: boolean;
  application: SsciStudentApplicationsWithPointsDto | undefined;
  form: any;
  handlers: {
    selectCategories: (id: number) => void;
    handleFinishForm: (application: any, isHaveFile?: boolean) => void;
    handleCancel: () => void;
    handleOk: () => void;
    handleDownloadFile: (id: number) => void;
  };
  data: {
    statusList: IrosStatus[];
    categories: SsciCategoriesDtoResponse[];
    indicators: SsciIndicatorsDtoResponse[];
    uploadProps: UploadProps;
    loading: boolean;
  };
}

const AddEventModal = ({ isModalOpen, form, data, handlers, application }: AddEventModalProps) => {
  const { selectCategories, handleFinishForm, handleCancel, handleOk, handleDownloadFile } = handlers;
  const { statusList, categories, indicators, uploadProps, loading } = data;
  const isNewEvent = !application;
  const [isHaveFile, setIsHaveFile] = useState<boolean>(false);

  return (
    <Modal title={'Add event'} onCancel={handleCancel} onOk={handleOk} open={isModalOpen} footer={null} width={800}>
      <Form form={form} onFinish={(e) => handleFinishForm(e, isHaveFile)} layout="vertical" disabled={loading}>
        <Form.Item
          label={'Event name'}
          name={'eventName'}
          rules={[{ required: true, message: 'Please input the event name!' }]}
          initialValue={isNewEvent ? '' : application.eventName}
        >
          <Input placeholder="Enter event name..." />
        </Form.Item>
        <Form.Item
          label={'Event date'}
          name={'eventDate'}
          rules={[{ required: true, message: 'Please select the event date!' }]}
          initialValue={isNewEvent ? null : moment(application.eventDate, 'YYYY-MM-DD')}
        >
          <DatePicker placeholder="Select event date..." format="YYYY-MM-DD" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={'Event type'}
          name={'indexStatusId'}
          rules={[{ required: true, message: 'Please select the event type!' }]}
          initialValue={isNewEvent ? null : application.indexStatus.id}
        >
          <Select placeholder={'Select event type...'}>
            {statusList.map((type) => (
              <Select.Option key={type.id} value={type.id}>
                {type.nameEn}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={'Category'}
          name={'roleId'}
          rules={[{ required: true, message: 'Please select the category!' }]}
          initialValue={isNewEvent ? null : application.roleId.id}
        >
          <Select placeholder={'Select category...'} onChange={selectCategories}>
            {categories.map((category) => (
              <Select.Option key={category.id} value={category.id}>
                {category.nameRu}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.List name={'points'}>
          {(fields, { add, remove }) =>
            indicators.map((indicator, index) => (
              <Row key={index}>
                <Col flex={0}>
                  <Typography.Text>{indicator.nameRu}</Typography.Text>
                </Col>
                <Col flex="auto"></Col>
                <Col flex={0}>
                  <Form.Item
                    name={indicator.id}
                    rules={[{ required: true, message: 'Please, enter the points!' }]}
                    style={{ margin: 0 }}
                  // initialValue={
                  //   application?.points.find((point) => point.ssciIndicators.id === indicator.id)?.points || 0
                  // }
                  >

                    <div>
                      <InputNumber
                        max={20}
                        min={0}
                        formatter={(value) => (value !== undefined ? String(value) : '')}

                      />
                      <div style={{ marginTop: '8px', color: '#888' }}>Range from {0} to {20}!</div>
                    </div>
                  </Form.Item>
                </Col>
                <Divider style={{ margin: '10px auto' }} />
              </Row>
            ))
          }
        </Form.List>
        <Form.Item
          label={'Work hours'}
          name={'hours'}
          rules={[{ required: true, message: 'Please enter hours!' }]}
          initialValue={isNewEvent ? null : application.hours}
        >
          <InputNumber placeholder="Enter hours..." style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={'Your responsibilities (max 240 characters)'}
          name={'info'}
          rules={[{ required: true, message: 'Please input your responsibilities!' }]}
          initialValue={isNewEvent ? null : application.info}
        >
          <Input.TextArea maxLength={240} placeholder="Enter description of your responsibilities... (max 240 characters)" rows={4} />
        </Form.Item>
        <Form.Item
          name={'fileId'}
          rules={[{ required: true, message: 'Please upload the confirming file!' }]}
          initialValue={isNewEvent ? null : application.fileId}
        >
          {isNewEvent ? (
            <Upload {...uploadProps} maxCount={1}>
              <Button icon={<UploadOutlined />}>Upload a confirming file</Button>
            </Upload>
          ) : (
            <>
              <Upload {...uploadProps} onChange={() => setIsHaveFile(true)} maxCount={1}>
                <Button icon={<UploadOutlined />}>Update a confirming file</Button>
              </Upload>
              <br />
              <Button onClick={() => handleDownloadFile(application.fileId)} icon={<DownloadOutlined />}>
                Download a previously downloaded document
              </Button>
            </>
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEventModal;
