import authAxios from "../../../common/authAxios";
import { errorNotification } from "../../../helpers/errorNotification";
import { successNotification } from "../../../helpers/successNotification";
import { GraduationThesisChooseSettingsDtoResponse } from "./GraduationThesisChooseSettingsDtoResponse";

export class StudentGraduationThesisChooseSettingsService {
    private static baseUrl = '/astanait-diploma-work-module/api/v1/student-graduation-thesis-choose-setting';

    static async getAllCourseSettings() {
        return authAxios.get(`${this.baseUrl}/current-defense-year`)
            .then((response) => response.data)
            .catch((error) => {
                errorNotification('Error fetching choose', error.response?.status);
            });
    }

    static async createChooseSetting(degreeId: number, data: Omit<GraduationThesisChooseSettingsDtoResponse, 'id' | 'academicDegree'>) {
        return authAxios.post(`${this.baseUrl}/degree/${degreeId}`, data)
            .then(() => successNotification('Choose setting created successfully'))
            .catch((error) => {
                errorNotification('Error creating choose setting', error.response?.status);
            });
    }
    
    static async updateChooseSetting(id: number, data: Omit<GraduationThesisChooseSettingsDtoResponse, 'academicDegree'>) {
        return authAxios.put(`${this.baseUrl}/${id}`, data)
            .then(() => successNotification('Choose setting updated successfully'))
            .catch((error) => {
                errorNotification('Error updating choose setting', error.response?.status);
            });
    }
    

    static async deleteChooseSetting(id: number) {
        return authAxios.delete(`${this.baseUrl}/${id}`)
            .then(() => successNotification('Choose setting deleted successfully'))
            .catch((error) => {
                errorNotification('Error deleting choose setting', error.response?.status);
            });
    }

}
