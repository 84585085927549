import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { errorNotification } from '../../../helpers/errorNotification';
import { DisciplineDtoResponse } from '../../../interfaces/DisciplineDtoResponse';
import { GroupDtoResponse } from '../../../interfaces/GroupDtoResponse';
import { TeacherService } from '../../../services/TeacherService';
import { VedomostiService } from '../../../services/VedomostiService';

const useStatementPage = () => {
  const years = [
    {
      id: 1,
      year: '2020',
    },
    {
      id: 2,
      year: '2021',
    },
    {
      id: 3,
      year: '2022',
    },
    {
      id: 4,
      year: '2023',
    },
    {
      id: 5,
      year: '2024',
    },
  ];

  const trimesters = [
    {
      id: 1,
      trimester: 1,
    },
    {
      id: 2,
      trimester: 2,
    },
    {
      id: 3,
      trimester: 3,
    },
  ];

  const [form] = useForm();
  const [disciplines, setDisciplines] = useState<DisciplineDtoResponse[]>([]);
  const [groups, setGroups] = useState<GroupDtoResponse[]>([]);
  const [statements, setStatements] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSelectTrim = (value: number) => {
    VedomostiService.getDisciplineByTeacher(form.getFieldValue('year'), value)
      .then(({ data }) => setDisciplines(data))
  };

  const handleSelectDiscipline = (value: number) => {
    TeacherService.getGroupsByTeacherIdAndDisciplineId(value, form.getFieldValue('year'), form.getFieldValue('term'))
      .then(({ data }) => setGroups(data))
  };

  const handleSearchStatement = () => {
    setLoading(true);
    VedomostiService.getVedomostForTeacher(
      form.getFieldValue('year'),
      form.getFieldValue('term'),
      form.getFieldValue('groupId'),
      form.getFieldValue('disciplineId')
    )
      .then(({ data }) => { setStatements(data); data.length === 0 && errorNotification('Не найдено студентов ') })
      .finally(() => setLoading(false));
  };

  return {
    data: {
      form,
      years,
      trimesters,
      disciplines,
      setDisciplines,
      groups,
      setGroups,
      statements,
      setStatements,
      loading,
      setLoading,
    },
    handlers: {
      handleSelectTrim,
      handleSelectDiscipline,
      handleSearchStatement,
    },
  };
};

export default useStatementPage;
