import './style.module.css';

import React from 'react';
import { Input, Typography, Form, Button, Select, Space, InputNumber, Checkbox } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import { DepartmentDtoResponse } from '../../../interfaces/DepartmentDtoResponse';
import { DisciplineDtoRequest } from '../../../interfaces/DisciplineDtoRequest';
import { DisciplinesService } from '../../../services/DisciplinesService';
import { AcademicDegreeDtoResponse } from '../../../interfaces/AcademicDegreeDtoResponse';
import BackButton from '../../../ui/BackButton';
import { successNotification } from '../../../helpers/successNotification';

const { Title } = Typography;
const { Option } = Select;

interface Props {
  disciplineId?: number;
}

const DisciplinesCreationPage = ({ disciplineId }: Props) => {
  const [form] = Form.useForm();

  const styles = { width: '800px', margin: '0 auto' };

  const [departments, setDepartments] = React.useState<DepartmentDtoResponse[]>([]);
  const [academicDegrees, setAcademicDegrees] = React.useState<AcademicDegreeDtoResponse[]>([]);

  React.useEffect(() => {
    Promise.all([DisciplinesService.getDepartments(), DisciplinesService.getAcademicDegrees()])
      .then(([departmentsResponse, academicDegreesResponse]) => {
        setDepartments(departmentsResponse.data);
        setAcademicDegrees(academicDegreesResponse.data);
      });
  }, []);

  // useEffect для страниц с редактированием дисциплины
  React.useEffect(() => {
    if (disciplineId && departments.length && academicDegrees.length) {
      DisciplinesService.getOneDiscipline(disciplineId)
        .then(({ data }) => {
          form.setFieldsValue({
            departmentId: data.department?.id,
            academicDegreeId: data.academicDegree.id,
            volumeCredits: data.volumeCredits,
            titleKz: data.titleKz,
            descriptionKz: data.descriptionKz,
            titleRu: data.titleRu,
            descriptionRu: data.descriptionRu,
            titleEn: data.titleEn,
            descriptionEn: data.descriptionEn,
            code: data.code,
            isAcademicMobility: data.isAcademicMobility,
          });
        });
    }
  }, [disciplineId, departments, academicDegrees, form]);

  const onFinish = (discipline: DisciplineDtoRequest) => {
    disciplineId
      ? DisciplinesService.updateDiscipline(disciplineId, discipline)
        .then(() => successNotification('Данные успешно обновлены'))
      : DisciplinesService.createDiscipline(discipline)
        .then(() => successNotification('Данные успешно сохранены'));
  };

  return (
    <PageWrapper>
      {/* <Link to="/disciplines"> */}
      <BackButton />
      {/* </Link> */}
      <header style={styles}>
        <Title level={3}>{disciplineId ? 'Редактирование Дисциплин' : 'Создание Дисциплин'}</Title>
      </header>

      <Form
        layout="vertical"
        size="large"
        style={styles}
        initialValues={{ remember: true }}
        autoComplete="off"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="Департамент"
          name="departmentId"
          rules={[{ required: true, message: 'Выберите департамент из списка' }]}
        >
          <Select placeholder="Выберите кафедру из списка" allowClear>
            {departments?.map((department) => (
              <Option value={department.id}>{department.titleRu}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Академическая степень"
          name="academicDegreeId"
          rules={[{ required: true, message: 'Выберите академичесую степень из списка' }]}
        >
          <Select placeholder="Выберите академичесую степень из списка" allowClear>
            {academicDegrees?.map((academicDegree) => (
              <Option value={academicDegree.id}>{academicDegree.titleRu}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Количество кредитов"
          name="volumeCredits"
          rules={[{ required: true, message: 'Введите количество кредитов' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Код дисциплины"
          name="code"
          rules={[{ required: true, message: 'Введите количество кредитов' }]}
        >
          <Input />
        </Form.Item>

        <Title level={4}>Казахский язык</Title>
        <Form.Item
          label="Название дисциплины на казахском языке"
          name="titleKz"
          rules={[
            {
              required: true,
              message: 'Введите название дисциплины на казахском языке',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Краткая информация о дисциплине на казахском языке"
          name="descriptionKz"
          rules={[
            {
              required: true,
              message: 'Введите краткую информацию о дисциплине на казахском языке',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Title level={4}>Русский язык</Title>
        <Form.Item
          label="Название дисциплины на русском языке"
          name="titleRu"
          rules={[
            {
              required: true,
              message: 'Введите название дисциплины на русском языке',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Краткая информация о дисциплине на русском языке"
          name="descriptionRu"
          rules={[
            {
              required: true,
              message: 'Введите краткую информацию о дисциплине на русском языке',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Title level={4}>Английский язык</Title>
        <Form.Item
          label="Название дисциплины на английском языке"
          name="titleEn"
          rules={[
            {
              required: true,
              message: 'Введите название дисциплины на английском языке',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Краткая информация о дисциплине на английском языке"
          name="descriptionEn"
          rules={[
            {
              required: true,
              message: 'Введите краткую информацию о дисциплине на английском языке',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="isAcademicMobility" valuePropName="checked">
          <Checkbox>Является ли это дисциплина академической мобильностью</Checkbox>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
            <Button htmlType="button" onClick={() => form.resetFields()}>
              Сбросить форму
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </PageWrapper>
  );
};

export default DisciplinesCreationPage;
