import React, { useEffect, useState } from 'react';
import Page from '../../../../ui/Page';
import { Button, Col, Input, Modal, Row, Space, Table, Tag, UploadProps } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import useModal from '../../../../ui/Modal/useModal';
import AddEventModal from './AddEventModal';
import { ColumnsType } from 'antd/lib/table';
import { SsciService } from '../../../../services/student/SsciService';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { IrosStatus } from '../../../../interfaces/Iros/IrosStatus';
import { SsciCategoriesDtoResponse } from '../../../../interfaces/Ssci/SsciCategoriesDtoResponse';
import { SsciIndicatorsDtoResponse } from '../../../../interfaces/Ssci/SsciIndicatorsDtoResponse';
import { IrosService } from '../../../../services/student/IrosService';
import { SsciStudentApplicationsDto } from '../../../../interfaces/Ssci/Applications/SsciStudentApplicationsDto';
import { SsciStudentApplicationsWithPointsDto } from '../../../../interfaces/Ssci/Applications/SsciStudentApplicationsWithPointsDto';
import { FileService } from '../../../../services/file/FileService';
import { successNotification } from '../../../../helpers/successNotification';

const StudentSSCIPage = () => {

  const columns: ColumnsType<SsciStudentApplicationsDto> = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
      render: (_, record, idx) => idx + 1,
    },
    {
      title: 'Event name',
      dataIndex: 'eventName',
      key: 'eventName',
    },
    {
      title: 'Event date',
      dataIndex: 'eventDate',
      key: 'eventDate',
      render: (_, record, idx) => moment(record.eventDate).format('LL'),
    },
    {
      title: 'Role',
      dataIndex: 'roleId',
      key: 'roleId',
      render: (_, record, idx) => (record.roleId !== null ? record.roleId.nameRu : 'Not Found'),
    },
    {
      title: 'Status',
      dataIndex: 'approved',
      key: 'approved',
      render: (_, record, idx) => {
        let color = record.approved === null ? '#ffc53d' : record.approved ? '#73d13d' : '#ff4d4f';
        let text = record.approved === null ? 'Under review' : record.approved ? 'Approved' : 'Rejected';

        return (
          <>
            {record.approved !== false ? (
              <Tag color={color} key={idx}>
                {text}
              </Tag>
            ) : (
              <Button type="primary" danger onClick={() => openModal(record, true)}>
                Show reason
              </Button>
            )}
            {record.approved === null ? (
              <>
                {/* <Button type="primary" onClick={() => editModal(record.id)}>
                  Edit
                </Button> */}
              </>
            ) : null}
          </>
        );
      },
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',

    },
  ];
  const { isModalOpen, showModal, handleOk, handleCancel } = useModal();
  const [tableData, setTableData] = useState<SsciStudentApplicationsDto[]>([]);
  const [form] = useForm();
  const [statusList, setStatusList] = useState<IrosStatus[]>([]);
  const [applicationId, setApplicationId] = useState<number | null>(null);
  const [application, setApplication] = useState<SsciStudentApplicationsWithPointsDto>();
  const [categories, setCategories] = useState<SsciCategoriesDtoResponse[]>([]);
  const [indicators, setIndicator] = useState<SsciIndicatorsDtoResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [irosFile, setIrosFile] = useState<any>();
  const [isDeclineOpen, setIsDeclineOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState<SsciStudentApplicationsDto>();

  const openModal = (record: any, state?: boolean) => {
    if (state) {
      setIsDeclineOpen(state);
    }
    setModalData(record);
    form.resetFields();
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    SsciService.getCategories()
      .then(({ data }) => setCategories(data))

    IrosService.getStatus()
      .then(({ data }) => setStatusList(data))
  }, []);

  // const editModal = (applicationId: number) => {
  //   form.resetFields();
  //   setApplication(undefined);
  //   SsciService.getStudentApplicationById(applicationId)
  //     .then(({ data }) => {
  //       setApplication(data);
  //       setApplicationId(data.id);
  //       selectCategories(data.roleId.id);
  //     })
  //     .catch((err) => errorNotification('Unable to get aplication', err.response?.status))
  //     .finally(() => {
  //       showModal();
  //     });
  // };

  // useEffect(() => {

  // }, [applicationId]);

  const selectCategories = (id: number) => {
    SsciService.getIndicators(id)
      .then(({ data }) => setIndicator(data))
  };

  const uploadProps: UploadProps = {
    maxCount: 1,
    onChange(e) {
      e.file.status = 'done';
    },
    customRequest(info) {
      setIrosFile(info.file);
    },
  };

  const handleDownloadFile = (fileId: number) => {
    FileService.getFileById(fileId, 'file')
      .then(() => successNotification('Successfully downloaded!'))
  };

  const fetchTableData = () => {
    setLoading(true);
    SsciService.getStudentEventsByPrincipal()
      .then(({ data }) => setTableData(data))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFinishForm = (application: any, isHaveFile?: boolean) => {
    if (applicationId) {
      let filteredPointsArr: any[] = [];
      application.points.forEach((point: string, index: number) => {
        if (point) filteredPointsArr.push({ indicatorId: index, points: handleNumberInput(point, 20, 0) });
      });
      application.points = filteredPointsArr;

      let formData = new FormData();
      formData.append('ssciFile', irosFile);
      setLoading(true);


      isHaveFile ? (
        SsciService.uploadStudentEventFile(formData)
          .then(({ data }) => {
            application.fileId = data;
            SsciService.updateStudentApplication(application, applicationId)
              .then(({ data }) => successNotification('Successfully updated!'))
              .finally(() => {
                setLoading(false);
                handleCancel();
                fetchTableData();
                form.resetFields();
              });
          })
      ) : (
        SsciService.updateStudentApplication(application, applicationId)
          .then(({ data }) => successNotification('Successfully updated!'))
          .finally(() => {
            setLoading(false);
            handleCancel();
            fetchTableData();
            form.resetFields();
          })
      )


    } else {
      application.eventDate = moment(application.eventDate).format('YYYY-MM-DD');

      let filteredPointsArr: any[] = [];
      application.points.forEach((point: string, index: number) => {
        if (point) filteredPointsArr.push({ indicatorId: index, points: handleNumberInput(point, 20, 0) });
      });
      application.points = filteredPointsArr;

      let formData = new FormData();
      formData.append('ssciFile', irosFile);
      setLoading(true);
      SsciService.uploadStudentEventFile(formData)
        .then(({ data }) => {
          application.fileId = data;
          SsciService.createStudentApplication(application)
            .then(({ data }) => successNotification('Successfully created!'))
            .finally(() => {
              setLoading(false);
              handleCancel();
              fetchTableData();
              form.resetFields();
            });
        })
    }
  };

  const handleNumberInput = (input: string, max: number, min: number) => {
    let parsedInput = Number.parseInt(input);
    parsedInput = parsedInput > max ? max : parsedInput;
    parsedInput = parsedInput < min ? min : parsedInput;
    return parsedInput;
  };

  return (
    <Page title={'SSCI'}>
      <Row style={{ marginBottom: 20 }}>
        <Col flex={0}></Col>
        <Col flex="auto"></Col>
        <Col flex={0}>
          <Button
            type="primary"
            icon={<UserAddOutlined />}
            onClick={() => {
              setApplicationId(null);
              setApplication(undefined);
              form.resetFields();
              handleCancel();
              setIndicator([]);
              showModal();
            }}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Table dataSource={tableData} scroll={{ x: 1000 }}
        columns={columns} loading={loading} />
      <AddEventModal
        isModalOpen={isModalOpen}
        form={form}
        handlers={{ selectCategories, handleFinishForm, handleCancel, handleOk, handleDownloadFile }}
        data={{ statusList, categories, indicators, uploadProps, loading }}
        application={application ? application : undefined}
      />
      <Modal
        open={isDeclineOpen}
        title={'SEW Departments declinement comment'}
        onOk={() => setIsDeclineOpen(false)}
        onCancel={() => setIsDeclineOpen(false)}
      >
        <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
          <Input.TextArea value={modalData?.comment} readOnly />
        </Space>
      </Modal>
    </Page>
  );
};

export default StudentSSCIPage;
