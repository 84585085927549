import { Button, Form, Input, Select, Space } from 'antd';
import useStudentDetails from './useStudentDetails';
import { useEffect, useState } from 'react';
import useDictionary from '../../../../hooks/useDictionary';
import { StudentDtoResponse } from '../../../../interfaces/Student/StudentDtoResponse';
// import useEpvo from '../../../hooks/EPVO/useEpvo';

interface StudentSearchFormProps {
    onData: (studentsList: StudentDtoResponse[], loading: boolean, selectedTrimester: number) => void;
    isAttendance?: boolean;
    isDetails?: boolean;
}

const StudentSearchForm: React.FC<StudentSearchFormProps> = ({ onData, isAttendance = false, isDetails = false }) => {
    const {
        searchValue,
        setSearchValue,
        studentStatusTypeList,
        studentStudyStatusTypeList,
        educPrograms,
        groupOptions,
        courseOptions,
        loading,
        showFilter,
        handleShowFilter,
        handleSearchStudents,
        handleDegreeSelect,
        handleCourseSelect,
        handleEducationalProgramSelect,
        handleSearchByParam,
        form,
        studentsList,
        handleDownloadContingent,
    } = useStudentDetails({});

    const { dictionary } = useDictionary({});
    // const { handleSendStudentToEpvo, loading: epvoLoading } = useEpvo();

    const trimesterStatic = [
        { id: 202, name: 'Trimester 1' },
        { id: 205, name: 'Trimester 2' },
        { id: 208, name: 'Trimester 3' }
    ];

    const [selectedTrimester, setSelectedTrimester] = useState<number>(0);

    const handleTrimesterChange = (value: number) => {
        setSelectedTrimester(value);
    };


    useEffect(() => {
        onData(studentsList, loading, selectedTrimester);
    }, [studentsList, loading, onData, selectedTrimester]);

    return (
        <Space direction="vertical" size={20} style={{ marginBottom: 20 }}>
            <Button type="primary" onClick={handleShowFilter}>
                {showFilter ? 'Поиск по ФИО' : 'Поиск по фильтру'}
            </Button>

            {showFilter ? (
                <Form form={form} onFinish={handleSearchByParam}>
                    <Form.Item name="academicDegreeId" style={{ marginBottom: '20px' }}>
                        <Select placeholder="Выберите степень образования" onSelect={handleDegreeSelect} allowClear>
                            {dictionary.degreeList.map((degree) => (
                                <Select.Option key={degree.key} value={degree.id}>
                                    {`${degree.title}`}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="course" style={{ marginBottom: '20px' }}>
                        <Select placeholder="Выберите курс" allowClear onSelect={handleCourseSelect}>
                            {courseOptions?.map((course) => (
                                <Select.Option key={course.id} value={course.id}>
                                    {`${course.year}`}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="educationProgramId" style={{ marginBottom: '20px' }}>
                        <Select
                            placeholder="Выберите образовательную программу"
                            onSelect={handleEducationalProgramSelect}
                            allowClear
                        >
                            {educPrograms.map((educProgram) => (
                                <Select.Option key={educProgram.id} value={educProgram.id}>
                                    {`${educProgram.titleEn}`}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="groupId" style={{ marginBottom: '20px' }}>
                        <Select placeholder="Выберите группу" allowClear>
                            {groupOptions.map((group) => (
                                <Select.Option key={group.id} value={group.id}>
                                    {`${group.title}`}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="status" style={{ marginBottom: '20px' }}>
                        <Select placeholder="Выберите статус" allowClear>
                            {studentStatusTypeList.map((status) => (
                                <Select.Option key={status.id} value={status.id}>
                                    {`${status.nameRu}`}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="studyStatus" style={{ marginBottom: '20px' }}>
                        <Select placeholder="Выберите академический статус" allowClear>
                            {studentStudyStatusTypeList.map((status) => (
                                <Select.Option key={status.id} value={status.id}>
                                    {`${status.nameRu}`}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="financingId" style={{ marginBottom: '20px' }}>
                        <Select placeholder="Выберите тип финансирования" allowClear>
                            {dictionary.financingShortList.map((financing) => (
                                <Select.Option key={financing.id} value={financing.id}>
                                    {`${financing.value}`}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {isAttendance
                        ?
                        (
                            <Form.Item name="trimester" style={{ marginBottom: '20px' }}>
                                <Select placeholder="Выберите триместр текущего учебного года" onChange={handleTrimesterChange} onSelect={(value) => value} allowClear>
                                    {trimesterStatic.map((trimester) => (
                                        <Select.Option key={trimester.id} value={trimester.id}>
                                            {`${trimester.name}`}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )
                        :
                        (
                            <>
                                <Form.Item name="isInRetire" style={{ marginBottom: '20px' }}>
                                    <Select placeholder="Выберите находится ли он в отпуске" allowClear>
                                        <Select.Option key={0} value={"0"}>
                                            {`Нет`}
                                        </Select.Option>
                                        <Select.Option key={1} value={"1"}>
                                            {`Да`}
                                        </Select.Option>
                                        <Select.Option key={2} value={"2"}>
                                            {`Все`}
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="isAcademicMobility" style={{ marginBottom: '20px' }}>
                                    <Select placeholder="Выберите находится ли он в мобильности" allowClear>
                                        <Select.Option key={0} value={"0"}>
                                            {`Нет`}
                                        </Select.Option>
                                        <Select.Option key={1} value={"1"}>
                                            {`Да`}
                                        </Select.Option>
                                        <Select.Option key={2} value={"2"}>
                                            {`Все`}
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </>
                        )}

                    <Space direction="horizontal">
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Поиск
                            </Button>
                        </Form.Item>
                        {isDetails && (
                            <>
                                <Form.Item>
                                    <Button type="primary" onClick={() => handleDownloadContingent(form)} loading={loading}>
                                        Скачать контингент студентов
                                    </Button>
                                </Form.Item>
                                {/* <Form.Item>
                                    <Button type="dashed" icon={<SendOutlined />} onClick={() => handleSendStudentToEpvo(form.getFieldsValue())} loading={epvoLoading}>
                                        Отправить в ЕПВО
                                    </Button>
                                </Form.Item> */}
                            </>
                        )}
                    </Space>
                </Form >
            ) : (
                <Input.Search
                    placeholder="Введите ФИО Студента"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onSearch={(value) => handleSearchStudents(value)}
                    enterButton
                    style={{ width: 400 }}
                />
            )}
        </Space >
    )
}

export default StudentSearchForm;