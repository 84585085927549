import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Table, Typography } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import { PhysicalEducationCourseEnrollmentService } from './PhysicalEducationCourseEnrollmentService';
import { errorNotification } from '../../../helpers/errorNotification';
import { successNotification } from '../../../helpers/successNotification';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

interface PhysicalEducationType {
  id: number;
  name: string;
  nameEn: string;
  nameRu: string;
}

const PhysicalEducationTypes = () => {
  const [types, setTypes] = useState<PhysicalEducationType[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingData, setEditingData] = useState<PhysicalEducationType | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTypes();
  }, []);

  const fetchTypes = () => {
    PhysicalEducationCourseEnrollmentService.getAllPhysicalEducationTypes()
      .then((data) => setTypes(data))
      .catch((error) => {
        const errorMessage = error.response?.data?.message || 'Error fetching PE types';
        errorNotification(errorMessage);
      });
  };

  const showModal = (record?: PhysicalEducationType) => {
    setEditingData(record || null);
    setIsModalVisible(true);

    if (record) {
      form.setFieldsValue({
        id: record.id,
        name: record.name,
        nameEn: record.nameEn,
        nameRu: record.nameRu,
      });
    } else {
      form.resetFields();
    }
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const payload = { ...values };

      PhysicalEducationCourseEnrollmentService.createOrUpdatePhysicalEducationType(payload)
        .then(() => {
          fetchTypes();
          setIsModalVisible(false);
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || 'Error saving PE type';
          errorNotification(errorMessage);
        });
    });
  };

  const handleDelete = (id: number) => {
    PhysicalEducationCourseEnrollmentService.deletePhysicalEducationType(id)
      .then(() => {
        fetchTypes();
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || 'Error deleting PE type';
        errorNotification(errorMessage);
      });
  };

  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Name (English)',
      dataIndex: 'nameEn',
      key: 'nameEn',
    },
    {
      title: 'Name (Russian)',
      dataIndex: 'nameRu',
      key: 'nameRu',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: PhysicalEducationType) => (
        <>
          <Button type="primary" onClick={() => showModal(record)}>
            <EditOutlined /> Edit
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: '15px' }}
            danger
            onClick={() => handleDelete(record.id)}
          >
            <DeleteOutlined /> Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <PageWrapper>
      <Typography.Title level={1}>Physical Education Types</Typography.Title>
      <Card style={{ marginBottom: '20px' }}>
        <Button type="primary" onClick={() => showModal()}>
          <PlusOutlined /> Add New Type
        </Button>
        <Table
          dataSource={types}
          columns={columns}
          rowKey="id"
          style={{ marginTop: 20 }}
        />
      </Card>

      <Modal
        title={editingData ? 'Edit Type' : 'Add Type'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSubmit}
      >
        <Form form={form} layout="vertical">
          {editingData && (
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
          )}
          <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input the Name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Name (English)" name="nameEn" rules={[{ required: true, message: 'Please input the name in English!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Name (Russian)" name="nameRu" rules={[{ required: true, message: 'Please input the name in Russian!' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </PageWrapper>
  );
};

export default PhysicalEducationTypes;
