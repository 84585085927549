import React from 'react';
import { DisciplineDtoResponse } from '../../../interfaces/DisciplineDtoResponse';
import { ComponentDtoResponse } from '../../../interfaces/EducationalPrograms/ComponentDtoResponse';
import { CycleDtoResponse } from '../../../interfaces/EducationalPrograms/CycleDtoResponse';
import { CurriculumService } from '../../../services/CurriculumService';
import { DisciplinesService } from '../../../services/DisciplinesService';
import PageWrapper from '../../../ui/PageWrapper';
import CurriculumCatalogWindow from './CurriculumCatalogWindow';
import MainDisciplineForm from './MainDisciplineForm';
import ElectiveDisciplinesForm from './ElectiveDisciplinesForm';
import { CurriculumDtoResponse } from '../../../interfaces/EducationalPrograms/CurriculumDtoResponse';
import { EducationalProgramsService } from '../../../services/EducationalProgramsService';
import { successNotification } from '../../../helpers/successNotification';

interface Props {
  educationalProgramId: number;
}

const CurriculumCreationPage = ({ educationalProgramId }: Props) => {
  const [shouldRerender, setShouldRerender] = React.useState(0);
  const [components, setComponents] = React.useState<ComponentDtoResponse[]>([]);
  const [cycles, setCycles] = React.useState<CycleDtoResponse[]>([]);
  const [disciplines, setDisciplines] = React.useState<DisciplineDtoResponse[]>([]);
  const [selectedCurriculums, setSelectedCurriculums] = React.useState<CurriculumDtoResponse[] | undefined>(undefined);

  React.useEffect(() => {
    Promise.all([CurriculumService.getComponents(), CurriculumService.getCycles()])
      .then(([componentsResponse, cyclesResponse]) => {
        setComponents(componentsResponse.data);
        setCycles(cyclesResponse.data);
      });
  }, []);

  React.useEffect(() => {
    CurriculumService.getCurriculumsByEducationalProgram(educationalProgramId)
      .then(({ data }) => {
        if (data.length > 0) {
          setSelectedCurriculums(data);
        }
      });

    EducationalProgramsService.getOneEducationalProgram(educationalProgramId).then(({ data }) => {
      DisciplinesService.getAllDisciplinesByDegree(data.academicDegree.id)
        .then(({ data }) =>
          setDisciplines(
            // data.filter(
            //   (discipline) => !selectedCurriculums.find((curriculum) => curriculum.discipline.id === discipline.id)
            // )
            data
          )
        );
    });
  }, [educationalProgramId, shouldRerender]);

  const onDeleteCurriculum = (curriculumId: number) => {
    CurriculumService.deleteCurriculum(curriculumId)
      .then(() => {
        successNotification('Запись успешно удалена');
        setShouldRerender(shouldRerender + 1);
      });
  };

  return (
    <PageWrapper>
      <CurriculumCatalogWindow selectedCurriculums={selectedCurriculums} onDeleteCurriculum={onDeleteCurriculum} />

      <MainDisciplineForm
        rerender={(count) => setShouldRerender(shouldRerender + count)}
        educationalProgramId={educationalProgramId}
        disciplines={disciplines}
        cycles={cycles}
        components={components}
      />

      <ElectiveDisciplinesForm
        rerender={(count) => setShouldRerender(shouldRerender + count)}
        educationalProgramId={educationalProgramId}
        disciplines={disciplines}
        cycles={cycles}
        components={components}
      />
    </PageWrapper>
  );
};

export default CurriculumCreationPage;
