import authAxios from '../../common/authAxios';
import { Constants } from '../../common/constants';
import { CertificateDtoResponse } from '../../interfaces/CertificateDtoResponse';
import { CertificateIssuanceRequestDtoResponse } from '../../interfaces/deansOffice/CertificateIssuanceRequestDtoResponse';
import { RcFile } from 'antd/lib/upload';
import { downloadFile } from '../../helpers/downloadFile';

export class CertificateService {
  private static endpointPrefix: string = '/astanait-deans-module/api/v1/certificate-issuance/pdf';
  static baseURL = Constants.API_BASE_URL;

  static makeCertificate(dto: CertificateIssuanceRequestDtoResponse) {
    return downloadFile(
      `${this.endpointPrefix}?certificate-issuance-id=${dto.id}`,
      'GET',
      'blob',
      `${dto.student.surnameEn}_${dto.student.nameEn}_${dto.type.name}`,
      'pdf'
    );
  }

  static saveCertificateList(list: CertificateDtoResponse[]) {
    return authAxios.post(`${this.baseURL}/astanait-du-cce/api/v1/certificate/create-list-certificate`, list);
  }

  static importDataFromExcelFile(file: RcFile) {
    const formData = new FormData();
    formData.append('file', file);
    return authAxios.post(
      `${this.baseURL}/astanait-du-cce/api/v1/certificate/import-certificate
    `,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
  }

  static getCertificateByCode(code: string) {
    return authAxios.get<CertificateDtoResponse>(
      `${this.baseURL}/astanait-du-cce/api/v1/certificate/public/get-by-number?number=${code}`
    );
  }

  static getCertificateById(id: number) {
    return authAxios.get(`${this.baseURL}/astanait-du-cce/api/v1/certificate/get-by-id?id=${id}`);
  }

  static getAll() {
    return authAxios.get(`${this.baseURL}/astanait-du-cce/api/v1/certificate/get-all`);
  }

  static putCertificate(data: any) {
    return authAxios.post(`${this.baseURL}/astanait-du-cce/api/v1/certificate/create-certificate`, data);
  }

  static deleteCertificate(id: number) {
    return authAxios.delete(`${this.baseURL}/astanait-du-cce/api/v1/certificate/delete?id=${id}`);
  }
}
