import React from 'react';
import { Input, Typography, Form, Button, Select, Space } from 'antd';
import PageWrapper from '../../../../ui/PageWrapper';
import BackButton from '../../../../ui/BackButton';
import { successNotification } from '../../../../helpers/successNotification';
import { IrosService } from '../../../../services/student/IrosService';
import { IrosType } from '../../../../interfaces/Iros/IrosType';
import { IrosIndicator } from '../../../../interfaces/Iros/IrosIndicator';

const { Title } = Typography;
const { Option } = Select;

interface Props {
  indicatorId?: number;
}

const CreateIrosIndicators = ({ indicatorId }: Props) => {
  const [form] = Form.useForm();

  const styles = { width: '800px', margin: '0 auto' };

  const [types, setTypes] = React.useState<IrosType[]>([]);


  React.useEffect(() => {
    Promise.all([IrosService.getTypes()])
      .then(([IrosType]) => {
        setTypes(IrosType.data);

      });
  }, []);

  React.useEffect(() => {
    if (indicatorId && types.length) {
      IrosService.getIndicatorById(indicatorId)
        .then(({ data }) => {
          form.setFieldsValue({
            irosId: data.id,
            name: data.name,
            nameEn: data.nameEn,
            nameRu: data.nameRu,
            type: data.type.id,

          });
        });
    }
  }, [indicatorId, types, form]);

  const onFinish = (indicators: IrosIndicator) => {
    if (indicatorId) {
      indicators.id = indicatorId;
      IrosService.updateIrosIndicators(indicators)
        .then(() => successNotification('Данные успешно обновлены'))
    }
    else {
      IrosService.createIrosIndicators(indicators)
        .then(() => successNotification('Данные успешно добавлены'))
    }
  };


  return (
    <PageWrapper>
      {/* <Link to="/disciplines"> */}
      <BackButton />
      {/* </Link> */}
      <header style={styles}>
        <Title level={3}> {indicatorId ? 'Редактирование iROS индикатора' : 'Создание iROS индикатора'}</Title>
      </header>

      <Form
        layout="vertical"
        size="large"
        style={styles}
        initialValues={{ remember: true }}
        autoComplete="off"
        form={form}
        onFinish={onFinish}
      >


        <Form.Item
          label="Название на казахском"
          name="name"

        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Название на английском"
          name="nameEn"

        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Название на русском"
          name="nameRu"
          rules={[{ required: true, message: 'Введите название' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Тип"
          name="type"
          rules={[{ required: true, message: 'Выберите тип из списка' }]}
        >
          <Select placeholder="Выберите тип из списка" allowClear>
            {types?.map((type) => (
              <Option value={type.id}>{type.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
            <Button htmlType="button" onClick={() => form.resetFields()}>
              Сбросить форму
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </PageWrapper>
  );
};

export default CreateIrosIndicators;
