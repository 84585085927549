import { Button, Modal, Space, Typography } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import AuthenticatedContent from '../../../common/AuthenticatedContent';
import StudentCurriculumTable from './StudentCurriculumTable/StudentCurriculumTable';
import OfficeCurriculumTable from './OfficeCurriculumTable/OfficeCurriculumTable';
import useCurriculum from '../../../hooks/Curriculum/useCurriculum';
import DataSelect from '../StudentDebtPage/DataSelect';
import { useState } from 'react';

const { Title } = Typography;

const ApproveCurriculumPage = () => {
  const {
    choiceStatus,
    onAgreeOrDisagree,
    isButtonDisabled,
    loading,
    areButtonsAvailable,
    tableVisible,
    selectedCourse,
    selectedEducationalDegree,
    selectedEducationalProgram,
    selectedGroup,
    setSelectedGroup,
    handleClearDegree,
    handleClearCourse,
    handleClearEducationalProgram,
    handleEducationalDegreeSelect,
    handleCourseSelect,
    handleEducationalProgramSelect,
    handleGroupSelect,
    handleSearch,
    handleSetStatus,
    educPrograms,
    courseOptions,
    groupOptions,
    tableData,
  } = useCurriculum();

  const [showModal, setShowModal] = useState(false);


  return (
    <PageWrapper>
      <section>
        <AuthenticatedContent role={'STUDENTS'} type={'baseRole'}>
          <Title level={3}>Individual Сurriculum (ИУП)</Title>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <StudentCurriculumTable />
            <Title level={4}>
              Do you agree with the Individual Curriculum above? {choiceStatus !== undefined && `Your current status: ${choiceStatus}`}
            </Title>
            <Space size="middle">
              <Button type="primary" size="large" onClick={() => onAgreeOrDisagree(true)} disabled={isButtonDisabled}>
                Approve / Agree
              </Button>
              <Button
                type="primary"
                danger
                size="large"
                onClick={() => onAgreeOrDisagree(false)}
                disabled={isButtonDisabled}
              >
                Disapprove / Disagree
              </Button>
            </Space>
          </Space>
        </AuthenticatedContent>
        <AuthenticatedContent role={'OFFICE_REGISTRAR'} type={'addRole'}>
          <Title level={3}>Approved Students List</Title>
          <Space size="large" direction="vertical">
            <DataSelect
              options={[
                {
                  id: 1,
                  value: 1,
                  name: "Bachelor's degree",
                },
                {
                  id: 2,
                  value: 2,
                  name: "Master's degree",
                },
                {
                  id: 3,
                  value: 6,
                  name: "Doctorate's degree",
                },
              ]}
              value={selectedEducationalDegree}
              onClear={handleClearDegree}
              placeholder={'Выберите степень образования'}
              onSelect={handleEducationalDegreeSelect}
              renderItems={(item) => item.name}
            />
            <DataSelect
              options={courseOptions}
              value={selectedCourse}
              placeholder={'Выберите курс'}
              onSelect={handleCourseSelect}
              onClear={handleClearCourse}
              renderItems={(item) => item.year}
            />
            <DataSelect
              options={educPrograms}
              value={selectedEducationalProgram}
              onSelect={handleEducationalProgramSelect}
              onClear={handleClearEducationalProgram}
              placeholder={'Выберите образовательную программу'}
              renderItems={(item) => `${item.titleEn} / ${item.titleRu} / ${item.titleKz}`}
            />
            <DataSelect
              options={groupOptions}
              value={selectedGroup}
              onSelect={handleGroupSelect}
              onClear={() => setSelectedGroup(undefined)}
              placeholder={'Выберите группу'}
              renderItems={(item) => item.title}
            />
            <Space size={"large"}>
              <Button
                style={{ display: 'block', marginBottom: '20px' }}
                type="primary"
                disabled={!areButtonsAvailable}
                onClick={handleSearch}
              >
                Search
              </Button>
              <Button
                style={{ display: 'block', marginBottom: '20px' }}
                type="primary"
                disabled={!areButtonsAvailable}
                onClick={() => setShowModal(true)}
              >
                Set approved status to students
              </Button>
            </Space>
          </Space>

          {tableVisible &&
            <OfficeCurriculumTable tableData={tableData} loading={loading} />
          }

          {showModal && (
            <Modal
              open={showModal}
              onCancel={() => setShowModal(false)}
              okText="Сменить"
              cancelText="Отмена"
              onOk={() => { handleSetStatus(); setShowModal(false) }}
            >
              <Title level={5}>Вы уверены что хотите сменить статус студентам?</Title>
            </Modal>
          )}

        </AuthenticatedContent>
      </section>
    </PageWrapper >
  );
};

export default ApproveCurriculumPage;
