import React, { useState } from 'react';
import { Button, Modal, Typography, Table } from 'antd';
import { StudentDisciplineChooseDtoResponse } from '../../../interfaces/DisciplineEnrollmentDtoResponse';
import { DisciplinesService } from '../../../services/DisciplinesService';

const { Title } = Typography;

const PreviousChoiceModal = () => {
  const [prevChoice, setPrevChoice] = useState<StudentDisciplineChooseDtoResponse[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => {
    DisciplinesService.getPreviousChoiceDisciplines()
      .then((prevChoice) => {
        setPrevChoice(prevChoice.data);
        setIsOpenModal(!isOpenModal);
      })
  };

  const columns = [
    {
      title: 'Subject name',
      key: 'subject',
      dataIndex: 'subject',
    },

    {
      title: 'Trimester',
      key: 'trimester',
      dataIndex: 'trimester',
    },

    {
      title: 'Year',
      key: 'year',
      dataIndex: 'year',
    },

    {
      title: 'Priority',
      key: 'priority',
      dataIndex: 'priority',
    },
  ];
  return (
    <>
      <Modal
        centered
        width={1000}
        open={isOpenModal}
        okText="Close"
        onOk={() => setIsOpenModal(!isOpenModal)}
        onCancel={() => setIsOpenModal(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Title level={5}>Here is your last choice</Title>

        {prevChoice && prevChoice.length > 0 ? (
          <Table
            columns={columns}
            dataSource={prevChoice.map((subject) => ({
              key: subject.id,
              subject: subject.curriculum.discipline.titleEn,
              trimester: subject.curriculum.numberOfTrimester,
              year: subject.curriculum.year,
              priority: subject.priority,
            }))}
          />
        ) : (
          <Title level={5}>You didn't choose disciplines yet</Title>
        )}
      </Modal>
      <Button size="large" onClick={handleOpenModal}>
        Show my choice
      </Button>
    </>
  );
};

export default PreviousChoiceModal;
