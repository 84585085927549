import React from 'react';
import { Menu } from 'antd';
import { useLocation } from 'react-router-dom';
import { AuthoritedRoutes } from './AuthoritedRoutes/AuthoritedRoutes';

interface Props {
  role: string;
}

const MenuItems = ({ role }: Props) => {
  const location = useLocation();

  // useEffect(() => {
  //    AcademicDebtService.AcademicDebtsNumberForStudent().then((res) => setDebtNumber(res.data));
  // }, []);


  return (
    <React.Fragment>
      <Menu theme="dark" items={AuthoritedRoutes(role)} defaultSelectedKeys={['/']} mode="inline" selectedKeys={[location.pathname]} />
      {/* Не выходит сделать switch menu универсальным на данный момент. Позже вынесу его в отдельный компонент с параметрами */}
      {/* <AuthenticatedContent role={'OFFICE'} type={'baseRole'}> */}
      {/* <AuthenticatedContent role={'DEAN'} type={'addRole'}>
          <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline" selectedKeys={[location.pathname]}>
            <Menu.Item key="/disease-list" icon={<MedicineBoxFilled />}>
              <Link to="/disease-list"></Link>
              Disease List
            </Menu.Item>
            <Menu.Item key="/certificate-list" icon={<FilePdfFilled />}>
              <Link to="/certificate-list"></Link>
              Certificate List
            </Menu.Item>
          </Menu>
        </AuthenticatedContent> */}

      {/* <AuthenticatedContent role={'OFFICE_REGISTRAR'} type={'addRole'}>
          <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline" selectedKeys={[location.pathname]}>
            <Menu.SubMenu title="Statements" icon={<BookFilled />}>
              <Menu.Item key="/vedomost" icon={<UnorderedListOutlined />}>
                <Link to="/vedomost" />
                By Filter
              </Menu.Item>
              <Menu.Item key="/vedomost/status" icon={<InfoCircleOutlined />}>
                <Link to="/vedomost/status" />
                By status
              </Menu.Item>
            </Menu.SubMenu> 
            <Menu.Item key="/surveys" icon={<FilePdfFilled />}>
              <Link to="/surveys"></Link>
              Surveys
            </Menu.Item> 
          </Menu>
        </AuthenticatedContent> */}
      {/* <AuthenticatedContent role={'TECHNICAL_SECRETARY'} type={'addRole'}>
          <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline" selectedKeys={[location.pathname]}>
            <Menu.Item key="/final-attestation-protocol" icon={<PaperClipOutlined />}>
              <Link to="/final-attestation-protocol" />
              Final Attestation Protocol
            </Menu.Item>
          </Menu>
        </AuthenticatedContent> */}

      {/* <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline" selectedKeys={[location.pathname]}>
          <Menu.Item key="/expected-learning-outcomes" icon={<OrderedListOutlined />}>
            <Link to="/expected-learning-outcomes" />
            Learning Outcomes
          </Menu.Item> 
           <Menu.Item key="/academic-stream" icon={<ClusterOutlined />}>
            <Link to="/academic-stream" />
            Academic stream
          </Menu.Item>
          <Menu.Item key="/bachelor-contingent" icon={<ClusterOutlined />}>
            <Link to="/bachelor-contingent" />
            Bachelor's contingent
          </Menu.Item>  
          <Menu.SubMenu title="ESUVO" icon={<BarChartOutlined />}>
            <Menu.Item key="/esuvo-disciplines" icon={<SnippetsFilled />}>
              <Link to="/esuvo-disciplines" />
              Disciplines
            </Menu.Item>
            <Menu.Item key="/esuvo-programs" icon={<CarryOutFilled />}>
              <Link to="/esuvo-programs" />
              Edu Programs
            </Menu.Item>
            <Menu.Item key="/esuvo-curriculums" icon={<BookFilled />}>
              <Link to="/esuvo-curriculums" />
              Curriculums
            </Menu.Item>
          </Menu.SubMenu>*/}

      {/* <Menu.SubMenu title="Students" icon={<TeamOutlined />}>
            <MenuItem key="/create-student" icon={<PlusSquareOutlined />}>
              <Link to="/create-student">
                Create new student
              </Link>
            </MenuItem> Disabled 
            <Menu.Item key="/aсademic_transcript" icon={<SnippetsFilled />}>
              <Link to="/aсademic_transcript" />
              Transcripts
            </Menu.Item>
            <Menu.Item key="/students-ep-choice" icon={<CarryOutFilled />}>
              <Link to="/students-ep-choice" />
              EP choice
            </Menu.Item> Disabled
            <Menu.Item key="/student-debt" icon={<BookFilled />}>
              <Link to="/student-debt" />
              Debts
            </Menu.Item>
            <Menu.Item key="/students-details" icon={<IdcardOutlined />}>
              <Link to="/students-details" />
              Details
            </Menu.Item>
            {grantPermission('OFFICE_REGISTRAR', 'addRole') && (
              <Menu.Item key="/students-curriculum" icon={<CheckSquareFilled />}>
                <Link to="/students-curriculum" />
                Approve Curriculum
              </Menu.Item>
            )}
            <Menu.Item key="/statistics" icon={<LineChartOutlined />}>
              <Link to="/statistics" />
              Statistics
            </Menu.Item>
            <Menu.Item key="/academic_rating" icon={<StarOutlined />}>
              <Link to="/academic_rating" />
              Rating
            </Menu.Item>
            <Menu.Item key="/students-attendance" icon={<BarChartOutlined />}>
              <Link to="/students-attendance" />
              Attendance
            </Menu.Item>
            <Menu.SubMenu title="IROS" icon={<TeamOutlined />}>
              <Menu.Item key="/students-iros" icon={<AreaChartOutlined />}>
                <Link to="/students-iros" />
                Applications
              </Menu.Item>
              <Menu.Item key="/iros-indicators" icon={<AreaChartOutlined />}>
                <Link to="/iros-indicators" />
                Indicators
              </Menu.Item>
            </Menu.SubMenu>
            {grantPermission('SEW_DEPARTMENT', 'addRole') && (
              <Menu.SubMenu title="SSCI" icon={<TeamOutlined />}>
                <Menu.Item key="/ssci-applications" icon={<AreaChartOutlined />}>
                  <Link to="/ssci-applications" />
                  Applications
                </Menu.Item>
                <Menu.Item key="/ssci-events" icon={<AreaChartOutlined />}>
                  <Link to="/ssci-events" />
                  Events
                </Menu.Item>
              </Menu.SubMenu>
            )}
          </Menu.SubMenu> 
        </Menu> */}


      {/* <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline" selectedKeys={[location.pathname]}>
          <Menu.Item key="/educational-programs" icon={<OrderedListOutlined />}>
            <Link to="/educational-programs" />
            Educational Programs
          </Menu.Item>
        </Menu> */}

      {/* <AuthenticatedContent role={['OFFICE_REGISTRAR', 'ACADEMIC_DEPARTMENT']} type={'addRole'}>
          <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline" selectedKeys={[location.pathname]}>
            <Menu.Item key="/disciplines" icon={<OrderedListOutlined />}>
              <Link to="/disciplines" />
              Disciplines
            </Menu.Item>
            <Menu.Item key="/diploma-supplement" icon={<PaperClipOutlined />}>
              <Link to="/diploma-supplement" />
              Diploma Supplement
            </Menu.Item>
          </Menu>
        </AuthenticatedContent> */}

      {/* <AuthenticatedContent role={'CCE_DEPARTMENT'} type={'addRole'}>
          <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline" selectedKeys={[location.pathname]}>
            <Menu.Item key="/certificate-registration" icon={<FileDoneOutlined />}>
              <Link to="/certificate-registration" />
              Certificate registration
            </Menu.Item>
          </Menu>
        </AuthenticatedContent> */}

      {/* <AuthenticatedContent role={'STUDENT_DEPARTMENT'} type={'addRole'}>
          <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline" selectedKeys={[location.pathname]}>
            <Menu.Item key="/order-list" icon={<FileDoneOutlined />}>
              <Link to="/order-list" />
              Orders
            </Menu.Item>
          </Menu>
        </AuthenticatedContent> */}

      {/* <AuthenticatedContent role={'PSYCHOLOGIST'} type={'addRole'}>
          <Menu theme="dark" defaultSelectedKeys={['/']} selectedKeys={[location.pathname]} mode="inline">
            <Menu.Item key="/appointment-list" icon={<FileDoneOutlined />}>
              <Link to="/appointment-list" />
              Appointment List
            </Menu.Item>
          </Menu>
        </AuthenticatedContent> */}
      {/* </AuthenticatedContent> */}

      {/* <AuthenticatedContent role={'STUDENTS'} type={'baseRole'}>
        <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline" selectedKeys={[location.pathname]}>
          <Menu.Item key="/transcript" icon={<SnippetsFilled />}>
            <Link to="/transcript" />
            Transcript
          </Menu.Item>
          <Menu.Item style={{ paddingLeft: '24px' }} key="/disease" icon={<MedicineBoxFilled />}>
            <Link to={{ pathname: '/disease' }}></Link> Disabled
            Disease
          </Menu.Item> 
          <Menu.Item key="/course-enrollment" icon={<FileTextFilled />}>
            <Link to="/course-enrollment" />
            Course Enrollment
          </Menu.Item> 
          <Menu.Item style={{ paddingLeft: '24px' }} key="/certificate" icon={<FilePdfFilled />}>
            <Link to={{ pathname: '/certificate' }}></Link>
            Certificate
          </Menu.Item>
          <Menu.Item key="/services" icon={<SolutionOutlined />}> 
            <Link to={{ pathname: '/services' }}></Link> Disabled
            Services
          </Menu.Item> 
          <Menu.Item key="/profile/curriculum" icon={<SolutionOutlined />}>
            <Link to={{ pathname: '/profile/curriculum' }}></Link>
            Curriculum
          </Menu.Item>
          <Menu.Item key="/psychologist-appointment-page" icon={<OrderedListOutlined />}>
            <Link to={{ pathname: '/psychologist-appointment-page' }}></Link>
            Psychologist Appointment
          </Menu.Item>
          <Menu.Item key="/disciplines" icon={<OrderedListOutlined />}>
            <Link to="/disciplines" /> Disabled
            Disciplines
          </Menu.Item> 
          <Menu.Item key="/approve-curriculum" icon={<CheckSquareFilled />}>
            <Link to="/approve-curriculum" /> Disabled
            Approve Curriculum
          </Menu.Item> 
          <Menu.Item key="/personal-information" icon={<InfoCircleOutlined />}>
            <Link to="/personal-information" />
            Confirm Personal Info
          </Menu.Item>
          <Menu.Item key="/student-debt" icon={<SnippetsFilled />}>
            <Link to="/student-debt" /> Disabled
            Student Debts
            <Badge count={debtNumber} showZero={false} offset={[0, -20]} size="small"></Badge>
          </Menu.Item> 
          <Menu.Item key="/student-profile" icon={<UserOutlined />}>
            <Link to="/student-profile" /> Disabled
            Student Profile
          </Menu.Item> 
          <Menu.Divider /> 
          <Menu.Item key="/iros-page" icon={<AreaChartOutlined />}>
            <Link to="/iros-page" />
            iROS
          </Menu.Item>

          <Menu.Item key="/ssci-page" icon={<TeamOutlined />}>
            <Link to="/ssci-page" />
            SSCI
          </Menu.Item>
          <Menu.Item key="/surveys" icon={<QuestionCircleOutlined />}>
            <Link to="/surveys" /> Disabled
            Questionnaires
          </Menu.Item>
        </Menu>
      </AuthenticatedContent> */}

      {/* <AuthenticatedContent role={'TEACHERS'} type={'baseRole'}>
        <Menu theme="dark" defaultSelectedKeys={['/']} selectedKeys={[location.pathname]} mode="inline">
          <Menu.Item style={{ paddingLeft: '24px' }} key="/vedomost" icon={<BookFilled />}>
            <Link to="/vedomost"></Link>
            Statements
          </Menu.Item>
          <Menu.Item key={'/kpi'} icon={<BarChartOutlined />}>
            <Link to="/kpi" />
            Kpi Application Disabled
          </Menu.Item> 
          <Menu.Item style={{ paddingLeft: '24px' }} key="/iros" icon={<ProfileOutlined />}>
            <Link to="/iros"></Link>
            IROS
          </Menu.Item>
        </Menu>
      </AuthenticatedContent> */}

      {/* <AuthenticatedContent role={'HR'} type={'addRole'}>
        <Menu theme="dark" defaultSelectedKeys={['/']} selectedKeys={[location.pathname]} mode="inline">
          <Menu.Item key={'/kpi'} icon={<BarChartOutlined />}>
            <Link to="/kpi" />
            Kpi Applications
          </Menu.Item>
        </Menu>
      </AuthenticatedContent> */}

      {/* <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline" selectedKeys={[location.pathname]}>
        <Menu.Item key={uuidv4()} icon={<LogoutOutlined />} onClick={UserService.logOut}>
          Log out
        </Menu.Item>
        <Menu.Item key="dark-mode">
          <Switch checked={darkMode} onChange={setDarkMode} checkedChildren="Dark" unCheckedChildren="Light" />
        </Menu.Item>
      </Menu> */}
    </React.Fragment>
  );
};

export default MenuItems;
