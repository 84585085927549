import React from 'react';
import { Button, Space, Table, Typography } from 'antd';
import { CurriculumService } from '../../../../services/CurriculumService';
import { successNotification } from '../../../../helpers/successNotification';
import styles from './style.module.css';
import PageWrapper from '../../../../ui/PageWrapper';
import { useHistory } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';

const { Title } = Typography;

const handleDownloadExcelCurriculumElectiveCatalog = (record: any) => {
  CurriculumService.downloadExcelCurriculumElectiveCatalog(record)
    .then(() => successNotification('Download successful'));
};

interface Props {
  educationalProgramId: number;
}
const ElectiveDisciplinesForm = ({ educationalProgramId }: Props) => {
  const history = useHistory();
  const [selectedElectives, setSelectedElectives] = React.useState<any[]>([]);

  // const [selectedElectiveDisciplines, setSelectedElectiveDisciplines] = React.useState<number[]>([]);

  React.useEffect(() => {
    CurriculumService.getElectiveDisciplines(educationalProgramId)
      .then(({ data }) => {
        if (data.length > 0) {
          setSelectedElectives(data);
        }
      });
  }, [educationalProgramId]);

  const columns = [

    {
      title: 'Наименование дисциплины',
      dataIndex: 'name',
      key: 'name',

    },
    {
      title: 'Код дисциплины',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Цикл дисциплины',
      dataIndex: 'cycle',
      key: 'cycle',
    },
    {
      title: 'Академические кредиты',
      dataIndex: 'credits',
      key: 'credits',
    },
    {
      title: 'Курс',
      dataIndex: 'course',
      key: 'course',
    },
    {
      title: 'Триместр',
      dataIndex: 'trimester',
      key: 'trimester',
    },
    {
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (_: any, record: any) => (
        <Space>
          <Button onClick={() => history.push(`/elective/education-program-id/${educationalProgramId}/curriculum/${record.curriculumId}/prereq`)}>
            Пререквизиты
          </Button>
          <Button onClick={() => history.push(`/elective/education-program-id/${educationalProgramId}/curriculum/${record.curriculumId}/postreq`)}>
            Постреквизиты
          </Button>
        </Space>
      )
    }
  ];

  // const onFinish = (values: any) => {
  //   const { numberOfTrimester, year, cycleId, componentId } = values;

  //   if (selectedElectiveDisciplines.length) {
  //     CurriculumService.createCurriculumByGroup({
  //       disciplineId: selectedElectiveDisciplines,
  //       educationProgramId: educationalProgramId,
  //       year: parseInt(year),
  //       numberOfTrimester,
  //       cycleId,
  //       componentId,
  //     })
  //       .then(() => {
  //         successNotification('Данные успешно сохранены');
  //       });
  //   }
  // };

  return (
    <PageWrapper>
      <section>
        <Title level={3} className={styles.title}>Каталог элективных дисциплин</Title>
        <Button onClick={() => handleDownloadExcelCurriculumElectiveCatalog(educationalProgramId)} icon={<DownloadOutlined />}>
          Скачать отчет
        </Button>
        <Table
          pagination={{ defaultPageSize: 30 }}
          dataSource={selectedElectives.map((electives) => ({
            disciplineId: electives.discipline.id,
            curriculumId: electives.id,
            name: electives.discipline.titleEn,
            code: electives.discipline.code,
            cycle: electives.cycle.cycleAbbreviationRu,
            credits: electives.discipline.volumeCredits,
            course: electives.year,
            trimester: electives.numberOfTrimester,
          }))}
          columns={columns}
        />
      </section>
    </PageWrapper>


  );
};

export default ElectiveDisciplinesForm;