import React from 'react';
import { Table, Button, Modal, Typography, Divider, Space, InputNumber } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import { AcademicStreamStudentGradeDtoResponse } from '../../../../interfaces/AcademicStreamStudentGradeDtoResponse';
import { SummarySheetForTranscriptDtoResponse } from '../../../../interfaces/SummarySheetForTranscriptDtoResponse';
import { TranscriptService } from '../../../../services/TranscriptService';
import { VedomostiService } from '../../../../services/VedomostiService';
import { DisciplinesService } from '../../../../services/DisciplinesService';
import { successNotification } from '../../../../helpers/successNotification';

interface TranscriptRow {
  number?: number | string;
  disciplineName: string;
  creditsAmount?: number | string;
  percentsGrade?: number | string;
  alphabeticGrade?: string;
  gpaGrade?: number | string;
  traditionalGrade?: number | string;
  actions: boolean;
}

interface Props {
  userId?: number;
  userRerender?: boolean;
}

const TranscriptTable = ({ userId, userRerender }: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [rerender, setRerender] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isDeleteModalIsVisible, setIsDeleteModalIsVisible] = React.useState(false);
  const [currentDisciplineName, setCurrentDisciplineName] = React.useState('');
  const [studentsGrade, setStudentsGrade] = React.useState<AcademicStreamStudentGradeDtoResponse[]>([]);
  const [marks, setMarks] = React.useState<any>({});
  const [studentId, setStudentId] = React.useState(0);
  const [transcript, setTranscript] = React.useState<TranscriptRow[] | undefined>(undefined);
  const [averagePerformanceGpa, setAveragePerformanceGpa] = React.useState(0.0);

  React.useEffect(() => {
    setLoading(true);
    Promise.all(
      userId
        ? [
          TranscriptService.getSummarySheetForTranscriptAcademDep(userId),
          TranscriptService.getTranscriptGpaForTrimestersWithAverage(userId),
        ]
        : [
          TranscriptService.getSummarySheetForTranscriptStudent(),
          TranscriptService.getTranscriptGpaForTrimestersWithAverageForStudents(),
        ]
    )
      .then(([summarySheetResponse, transcriptGpaResponse]) => {
        let transcriptRows: TranscriptRow[] = [];
        let rowCounter = 0;

        for (const [trimester, summarySheet] of Object.entries(summarySheetResponse.data)) {
          transcriptRows.push(
            // eslint-disable-next-line no-loop-func
            ...summarySheet.map((row: SummarySheetForTranscriptDtoResponse, index: number) => {
              const { alphabeticGrade, gpaGrade, traditionalGrade } = row;
              const [, , , total] = row.academicStreamStudentGrade; //  Достаем 4 элемент из массива
              let totalGrade = 0;
              if (total) {
                totalGrade = total.grade;
              }

              // Проверяем наличие предмета в летниках
              else if (totalGrade < 50) {
                const retake = summarySheetResponse.data[0].find(
                  (summarySheetForTranscript) =>
                    summarySheetForTranscript.academicStream.discipline.id === row.academicStream.discipline.id
                );
                if (retake) {
                  const [retakeTotal] = retake.academicStreamStudentGrade;
                  if (retakeTotal && retakeTotal.grade >= 50) {
                    totalGrade = retakeTotal.grade;
                  }
                }
              }

              ++rowCounter;

              return {
                key: rowCounter,
                Id: rowCounter,
                disciplineName: `${row.academicStream.discipline.titleEn} / ${row.academicStream.discipline.titleRu} / ${row.academicStream.discipline.titleKz}`,
                creditsAmount: row.academicStream.discipline.volumeCredits,
                percentsGrade: totalGrade,
                alphabeticGrade,
                gpaGrade,
                traditionalGrade,
                actions: true, // Placeholder
                studentsGrade: row.academicStreamStudentGrade,
                academicStreamStudentId: row.academicStreamStudentId,
              };
            })
          );

          const totalGpa = transcriptGpaResponse.data.gpaOfTrimesters[trimester];

          // totalGpa может быть undefined
          if (!isNaN(totalGpa)) {
            transcriptRows.push({
              disciplineName:
                trimester === '0' ? `Summer Trimester GPA - ${totalGpa}` : `Trimester ${trimester} GPA - ${totalGpa}`,
              actions: false,
            });
          }
        }

        setTranscript(transcriptRows);
        setAveragePerformanceGpa(transcriptGpaResponse.data.averageGpa);
      })
      .finally(() => setLoading(false));
  }, [userId, userRerender, rerender]);

  React.useEffect(() => {
    setMarks({
      mid: studentsGrade.find((grade: any) => grade.gradeType === 1)?.grade ?? 0,
      end: studentsGrade.find((grade: any) => grade.gradeType === 2)?.grade ?? 0,
      final: studentsGrade.find((grade: any) => grade.gradeType === 3)?.grade ?? 0,
    });
  }, [studentsGrade]);

  const columns: any[] = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
      width: '5%',
    },
    {
      title: 'Subject name',
      dataIndex: 'disciplineName',
      key: 'disciplineName',
    },
    {
      title: 'Number of credits',
      dataIndex: 'creditsAmount',
      key: 'creditsAmount',
    },
    {
      title: 'Mark',
      children: [
        {
          title: 'In percents',
          dataIndex: 'percentsGrade',
          key: 'percentsGrade',
        },
        {
          title: 'Alphabetic',
          dataIndex: 'alphabeticGrade',
          key: 'alphabeticGrade',
        },
        {
          title: 'In points',
          dataIndex: 'gpaGrade',
          key: 'gpaGrade',
        },
        {
          title: 'Traditional',
          dataIndex: 'traditionalGrade',
          key: 'traditionalGrade',
        },
      ],
    },
    ...(userId
      ? [
        {
          title: 'Actions',
          dataIndex: 'actions',
          key: 'actions',
          render: (shouldBeRendered: boolean, record: any) => {
            return (
              shouldBeRendered && (
                <Space size="middle">
                  <Button onClick={() => showModalDelete(record.academicStreamStudentId, record.disciplineName)}>
                    <DeleteTwoTone twoToneColor="#ff0000" />
                  </Button>
                  <Button
                    type="primary"
                    onClick={() =>
                      showModal(record.studentsGrade, record.disciplineName, record.academicStreamStudentId)
                    }
                  >
                    Редактировать оценку
                  </Button>
                </Space>
              )
            );
          },
        },
      ]
      : []),
  ];

  const showModalDelete = (academicStreamStudentId: number, disciplineName: string) => {
    setStudentId(academicStreamStudentId);
    setCurrentDisciplineName(disciplineName);
    setIsDeleteModalIsVisible(true);
  };

  const showModal = (
    studentsGrade: AcademicStreamStudentGradeDtoResponse[],
    disciplineName: string,
    academicStreamStudentId: number
  ) => {
    setStudentId(academicStreamStudentId);
    setCurrentDisciplineName(disciplineName);
    setStudentsGrade(studentsGrade);
    setIsModalVisible(true);
  };

  const onOk = () => {
    const midGradeId: any = studentsGrade.find((grade: any) => grade.gradeType === 1)?.id
      ? studentsGrade.find((grade: any) => grade.gradeType === 1)?.id
      : 0;
    const endGradeId: any = studentsGrade.find((grade: any) => grade.gradeType === 2)?.id
      ? studentsGrade.find((grade: any) => grade.gradeType === 2)?.id
      : 0;
    const finalGradeId: any = studentsGrade.find((grade: any) => grade.gradeType === 3)?.id
      ? studentsGrade.find((grade: any) => grade.gradeType === 3)?.id
      : 0;
    Promise.all([
      VedomostiService.editStudentMarkEmpty(midGradeId, marks.mid, studentId, 1),
      VedomostiService.editStudentMarkEmpty(endGradeId, marks.end, studentId, 2),
      VedomostiService.editStudentMarkEmpty(finalGradeId, marks.final, studentId, 3),
    ])
      .then(() => {
        successNotification('Данные успешно сохранены');
        setRerender(!rerender);
      })
      .finally(() => setIsModalVisible(false));
  };

  const deleteDiscipline = () => {
    DisciplinesService.deleteDisciplineForStudent(studentId)
      .then(() => {
        successNotification('Дисциплина успешно удалена');
      })
      .finally(() => {
        setIsDeleteModalIsVisible(false);
        setRerender(!rerender);
      });
  };

  return (
    <>
      {isModalVisible && (
        <Modal
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          okText="Cохранить"
          cancelText="Отмена"
          onOk={onOk}
        >
          <Typography.Title level={4}>Редактирование оценки для {currentDisciplineName}</Typography.Title>
          <Divider />
          <Space size="middle">
            <Space direction="vertical">
              <strong>Midterm</strong>
              <InputNumber
                style={{ width: 100 }}
                value={marks.mid}
                placeholder="Midterm"
                onChange={(value) => setMarks({ ...marks, mid: value ? value : 0 })}
              />
            </Space>
            <Space direction="vertical">
              <strong>Endterm</strong>
              <InputNumber
                style={{ width: 100 }}
                value={marks.end}
                placeholder="Endterm"
                onChange={(value) => setMarks({ ...marks, end: value ? value : 0 })}
              />
            </Space>
            <Space direction="vertical">
              <strong>Final</strong>
              <InputNumber
                style={{ width: 100 }}
                value={marks.final}
                placeholder="Final"
                onChange={(value) => setMarks({ ...marks, final: value ? value : 0 })}
              />
            </Space>
          </Space>
        </Modal>
      )}

      {isDeleteModalIsVisible && (
        <Modal
          open={isDeleteModalIsVisible}
          onCancel={() => setIsDeleteModalIsVisible(false)}
          okText="Удалить"
          okType="danger"
          cancelText="Отмена"
          onOk={deleteDiscipline}
        >
          <Typography.Title level={5}>
            Вы уверены что хотите удалить дисциплину - {currentDisciplineName} ?
          </Typography.Title>
        </Modal>
      )}

      <Table
        columns={columns}
        loading={loading}
        dataSource={transcript}
        rowKey="Id"
        scroll={{ x: '1200px' }}
        summary={() => (
          <Table.Summary>
            <Table.Summary.Row>
              {Array(7).fill(null).map((_, index) => (
                <Table.Summary.Cell key={index} index={index}>
                  {index === 1 && `Average performance GPA - ${averagePerformanceGpa}`}
                </Table.Summary.Cell>
              ))}
            </Table.Summary.Row>
          </Table.Summary>
        )}
        pagination={false}
        bordered
      />
    </>
  );
};

export default TranscriptTable;
