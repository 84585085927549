import styles from './style.module.css';
import { Typography, Table, Button } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import useForeignUniversities from './useForeignUniversities';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';


const { Title } = Typography;


const ForeignUniversitiesPage = () => {
    const { foreignUniversities, column } = useForeignUniversities();
    return (
        <PageWrapper>
            <section>
                <header className={styles.header}>
                    <Title level={3} className={styles.title}>
                        List of foreign universities
                    </Title>
                    <Link to="/foreign-universities/create">
                        <Button type="primary" icon={<PlusOutlined />}>
                            Add a university
                        </Button>
                    </Link>
                </header>
                <Table
                    pagination={false}
                    dataSource={foreignUniversities.map((foreignUniversities) => ({
                        key: foreignUniversities.id,
                        nameKz: foreignUniversities.nameKz,
                        nameRu: foreignUniversities.nameRu,
                        nameEn: foreignUniversities.nameEn,
                        countryId: foreignUniversities.countryId,
                        cityNameKz: foreignUniversities.cityNameKz,
                        cityNameRu: foreignUniversities.cityNameRu,
                        cityNameEn: foreignUniversities.cityNameEn,
                    }))}
                    columns={column}
                />
            </section>
        </PageWrapper>
    )
}

export default ForeignUniversitiesPage;