import React from 'react'
import Page from '../../../../../ui/Page'
import RowSpan from '../../../../../ui/RowSpan'
import { Button, Form, Select, Table } from 'antd'
import { useSyllabus } from '../../useSyllabus'
import useDictionary from '../../../../../hooks/useDictionary'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import { StudyStreamDto } from '../../../../../interfaces/StudyStream/StudyStreamDto'
import { useHistory } from 'react-router-dom'

const SyllabusGroupsAndStreamsPage = () => {
    const { data, methods } = useSyllabus();
    const { dictionary } = useDictionary({});
    const router = useHistory();

    const columns: ColumnsType<StudyStreamDto> = [
        {
            title: 'Enrolled Year',
            dataIndex: 'enrolledYear',
            key: 'enrolledYear',
        },
        {
            title: 'Course',
            dataIndex: 'course',
            key: 'course',
        },
        {
            title: 'Academic degree',
            dataIndex: 'degreeId',
            key: 'degreeId',
            render: (degreeId: number) => {
                const degree = dictionary.degreeList?.find(item => item.id === degreeId);
                return degree?.title;
            }
        },
    ];

    const onRowSelect = (id: number) => {
        router.push(`/syllabus/groups-and-streams/edit/${id}`)
    }

    return (
        <Page title='Syllabus of students' subtitle='By groups and streams'>
            <RowSpan
                left={
                    <Form form={data.form} layout='vertical' style={{ width: "25em" }} onFinish={methods.handleSearchListByParamsGroupsAndStreams}>
                        <Form.Item required name="degreeId" rules={[{ required: true }]} label='Academic degree'>
                            <Select>
                                {dictionary.degreeList?.map((item, index) => (
                                    <Select.Option key={index} value={item.id}>{`${item.title} / ${item.title} / ${item.title}`}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item required name={"enrolledYear"} rules={[{ required: true }]} label='Education Year'>
                            <Select>
                                {dictionary.years?.map((item, index) => (
                                    <Select.Option key={index} value={item.year}>{item.year}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item required name={"course"} rules={[{ required: true }]} label='Course'>
                            <Select>
                                <Select.Option key={1} value={1}>{1}</Select.Option>
                                <Select.Option key={2} value={2}>{2}</Select.Option>
                                <Select.Option key={3} value={3}>{3}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={data.isLoading} icon={<SearchOutlined />}>Search</Button>
                        </Form.Item>
                    </Form>
                }
                right={
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => router.push('/syllabus/groups-and-streams/create')}>Add New</Button>
                }
            />

            {data.groupsAndStreamsList.length > 0 && (
                <Table
                    columns={columns}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => onRowSelect(record.id ?? 0),
                            onMouseEnter: (e) => (e.currentTarget.style.cursor = 'pointer'),
                        };
                    }}
                    rowKey={(i) => i.id ?? 0}
                    dataSource={data.groupsAndStreamsList} />
            )}
        </Page>
    )
}

export default SyllabusGroupsAndStreamsPage