import { DeleteTwoTone, EditTwoTone, InfoCircleTwoTone, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Popconfirm, Space, Table } from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SsciEventsDtoResponse } from '../../../../interfaces/Ssci/SsciEventsDtoResponse';
import { SsciService } from '../../../../services/student/SsciService';
import Page from '../../../../ui/Page/Page';
import { successNotification } from '../../../../helpers/successNotification';

const SsciEventPage = () => {
  // const navigate = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<SsciEventsDtoResponse[]>([]);

  useEffect(() => {
    setLoading(true);
    SsciService.getEvents()
      .then(({ data }) => setEvents(data))
      .finally(() => setLoading(false));
  }, []);

  const deleteRow = (id: number) => {
    SsciService.deleteEvent(id)
      .then(() => successNotification('Успешно удалено!'))
      .finally(() => setLoading(!loading));
  };

  // const selectRow = (id: number) => {
  //   navigate.push(`/ssci-events/point/${id}`);
  // };

  const onFinish = (data: any) => {
    if (data) {
      setLoading(true);
      SsciService.getEventsByFilter({
        year: data.year && Number(moment(data.year).year()),
        month: data.month && Number(moment(data.month).month() + 1),
        name: data.name
      })
        .then(({ data }) => setEvents(data))
        .finally(() => setLoading(false));
      return;
    }
    setLoading(true);
    SsciService.getEvents()
      .then(({ data }) => setEvents(data))
      .finally(() => setLoading(false));
  }

  // const onDatePick: DatePickerProps['onChange'] = (date, dateString) => {
  //   const [year, month] = dateString.split('-');
  //   if (+year && +month) {
  //     setLoading(true);
  //     SsciService.getEventsByFilter(+year, +month)
  //       .then(({ data }) => setEvents(data))
  //       .finally(() => setLoading(false));
  //     return;
  //   }
  //   setLoading(true);
  //   SsciService.getEvents()
  //     .then(({ data }) => setEvents(data))
  //     .finally(() => setLoading(false));
  // };

  const columns = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Name',
      dataIndex: 'nameRu',
      key: 'nameRu',
    },
    {
      title: 'Date',
      dataIndex: 'eventDate',
      key: 'eventDate',
    },
    {
      title: 'Index status',
      dataIndex: 'indexStatus',
      key: 'indexStatus',
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      render: (text: any, record: any) => (
        <Space size="small">
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={() => deleteRow(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button>
              <DeleteTwoTone twoToneColor="#ff0000" />
            </Button>
          </Popconfirm>
          <Button href={`/edit-ssci-event/${record.id}`}>
            <EditTwoTone />
          </Button>
          <Button href={`/ssci-events/point/${record.id}`}>
            <InfoCircleTwoTone />
            Баллы студентов
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Page title="SSCI Events">
      <Space direction="vertical" style={{ width: '100%' }} size={'large'}>
        <Link to="/create-ssci-event">
          <Button type="primary" icon={<PlusOutlined />}>
            Создать эвент
          </Button>
        </Link>
        <Form onFinish={onFinish}>
          <Form.Item name="year" >
            <DatePicker style={{ width: "30%" }} format="YYYY" picker="year" placeholder="Год" />
          </Form.Item>
          <Form.Item name="month">
            <DatePicker style={{ width: "30%" }} format="MM" picker="month" placeholder="Месяц" />
          </Form.Item>
          <Form.Item name={"name"}>
            <Input style={{ width: "30%" }} placeholder='Название' />
          </Form.Item>
          <Form.Item>
            <Button icon={<SearchOutlined />} type='primary' htmlType='submit'>Поиск</Button>
          </Form.Item>
        </Form>
        <Table
          bordered
          // onRow={(record, rowIndex) => {
          //     return {
          //         onClick: () => selectRow(record.id),
          //         onMouseEnter: (e) => (e.currentTarget.style.cursor = 'pointer'),
          //     };
          // }}
          loading={loading}
          columns={columns}
          dataSource={events.map((event, i) => ({
            key: i + 1,
            id: event.id,
            nameRu: event.nameRu,
            eventDate: moment(event.eventDate).format('DD/MM/YYYY').toString(),
            indexStatus: event.indexStatus.nameRu,
          }))}
        />
      </Space>
    </Page>
  );
};

export default SsciEventPage;
