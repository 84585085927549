import { Table } from 'antd'
import React from 'react'
import { TeacherEducationInformationResponceDto } from '../../../../interfaces/Teacher/TeacherEducationInformationResponceDto';

interface Props {
    dataSource?: TeacherEducationInformationResponceDto[];
}

const PublicTeacherEducationTable = ({ dataSource }: Props) => {
    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Educational institution",
            dataIndex: "educationalInstitution",
            key: "educationalInstitution"
        },
        {
            title: "Year of admission",
            dataIndex: "admissionYear",
            key: "admissionYear"
        },
        {
            title: "Year of graduation",
            dataIndex: "graduationYear",
            key: "graduationYear"
        },
        {
            title: "Specialization",
            dataIndex: "specialization",
            key: "specialization"
        },
        {
            title: "Academic Degree",
            dataIndex: "degree",
            key: "degree"
        },
    ];


    return (
        <>
            <Table bordered columns={columns}
                rowKey={(record) => record.id}
                dataSource={
                    dataSource?.map((education, i) => ({
                        id: i + 1,
                        educationalInstitution: education.educationalInstitution,
                        admissionYear: education.admissionYear,
                        graduationYear: education.graduationYear,
                        specialization: education.specialization,
                        degree: education.academicDegree.titleEn
                    }))
                }
                style={{
                    overflowX: "auto"
                }}
            />
        </>
    )
}

export default PublicTeacherEducationTable