import authAxios from '../common/authAxios';
import { AcademicMobilityDtoRequest } from '../interfaces/AcademicMobilityDtoRequest';
import { AcademicMobilityDtoResponse } from '../interfaces/AcademicMobilityDtoResponse';

export class AcademicMobilityService {

    private static baseUrl = '/astanait-office-module/api/v1/academic-department/academic-mobility'

    static getAcademicMobilityById(id: number) {
        return authAxios.get<AcademicMobilityDtoResponse>(
            `${this.baseUrl}/get-by-id?id=${id}`
        );
    }

    static getAllAcademicMobility(page: number) {
        return authAxios.get<{ data: AcademicMobilityDtoResponse[]; totalPages: number }>(
            `${this.baseUrl}/get-all?page=${page}`
        );
    }


    static downloadAcademicMobilityExcel() {
        return authAxios.get(
            `${this.baseUrl}/get-academic-mobility-excel-file`,
            { responseType: 'blob' }
        );
    }

    static createAcademicMobility(academicMobility: AcademicMobilityDtoRequest) {
        return authAxios.post(
            `${this.baseUrl}/create-academic-mobility`,
            academicMobility
        );
    }

    static deleteAcademicMobility(academicMobilityId: number) {
        return authAxios.delete<AcademicMobilityDtoResponse>(
            `${this.baseUrl}/delete-academic-mobility?id=${academicMobilityId}`
        );
    }

    static updateAcademicMobility(id: number, academicMobility: AcademicMobilityDtoRequest) {
        return authAxios.put(
            `${this.baseUrl}/update-academic-mobility?id=${id}`,
            academicMobility
        );
    }
}