import { Modal, Typography, Divider, Button, Space, Input, InputNumber } from 'antd';
import StudentGroupFilter from '../../Academic Department/StudentGroupFilter';
import StudentsFilter from '../StudentsFilter';
import StudentSearch from '../StudentSearch';
import EnrollYearFilter from '../../Academic Department/EnrollYearFilter';
import DisciplinesFilter from '../../Academic Department/DisciplinesFilter';
import { StudentsListDtoResponse } from '../../../../interfaces/Student/StudentsListDtoResponse';
import { StudentsListWithGroupDtoResponse } from '../../../../interfaces/Student/StudentsListWithGroupDtoResponse';
import { DisciplinesService } from '../../../../services/DisciplinesService';
import { TranscriptService } from '../../../../services/TranscriptService';
import { DisciplinesDtoResponse } from '../../../../interfaces/DisciplinesDtoResponse';
import { StudentGroupsDtoResponse } from '../../../../interfaces/Student/StudentGroupsDtoResponse';
import { useEffect, useState } from 'react';
import { GradeFromTypeDtoResponse } from '../../../../interfaces/EducationalPrograms/GradeFromTypeDtoResponse';
import GradeFromTypeFilter from '../../Academic Department/GradeFromTypeFilter';
import { AcademicMobilityDisciplineDtoResponse } from '../../../../interfaces/AcademicMobilityDisciplineDtoResponse';
import { successNotification } from '../../../../helpers/successNotification';
import { errorNotification } from '../../../../helpers/errorNotification';

const { Search } = Input;
type Options = StudentsListWithGroupDtoResponse[];

interface Props {
  isModalVisible: boolean;
  rerender: boolean;
  isFilterVisible: boolean;
  selectedGroup: number | undefined;
  selectedStudent: number | undefined;
  searchStudentNames: Options;
  width?: number;
  setIsModalVisible: (value: boolean) => void;
  setSelectedGroup: (value: number | undefined) => void;
  setSelectedStudent: (value: number | undefined) => void;
  setRerender: (value: boolean) => void;
  setSearchStudentNames: (value: Options) => void;
}

const AddDisciplineModal = ({
  isModalVisible,
  rerender,
  isFilterVisible,
  width,
  setIsModalVisible,
  selectedGroup,
  setSelectedGroup,
  selectedStudent,
  setSelectedStudent,
  setRerender,
  searchStudentNames,
  setSearchStudentNames,
}: Props) => {
  const [studentsList, setStudentsList] = useState<StudentsListDtoResponse[]>([]);
  const [gradeTypes, setGradeTypes] = useState<GradeFromTypeDtoResponse[]>([]);
  const [disciplines, setDisciplines] = useState<DisciplinesDtoResponse[]>([]);
  // const [tutorList, setTutorList] = useState<TeachersListDtoResponse[]>([]);
  const [groupList, setGroupList] = useState<StudentGroupsDtoResponse[]>([]);
  const [academicMobilityDisciplines, setAcademicMobilityDisciplines] = useState<AcademicMobilityDisciplineDtoResponse[]>([]);
  // const [educationProgramIdState, setEducationProgramIdState] = useState<number | undefined>(educationProgramId);
  // const [educationProgramId, setEducationProgramId] = useState<number | undefined>(undefined);
  const [selectedGradeType, setSelectedGradeType] = useState<number | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  const [selectedCourse, setSelectedCourse] = useState<number | undefined>();
  //const [selectedCredit, setSelectedCredit] = useState<number | undefined>();
  const [selectedDiscipline, setSelectedDiscipline] = useState<number | undefined>(undefined);
  const [streamName, setStreamName] = useState<string | undefined>('');
  const [grade, setGrade] = useState<any>();


  useEffect(() => {
    TranscriptService.getAllStudentsGroup().then(({ data }) => {
      let res = data.map((item) => {
        return {
          id: item.id,
          title: item.title,
        };
      });
      setGroupList(res);
    });

    // DisciplinesService.getAllCredits().then(({ data }) => {
    //   setCreditList(data);
    // });
  }, []);


  useEffect(() => {
    DisciplinesService.getGradeFromTypes()
      .then((response) => {
        const gradeTypesData: GradeFromTypeDtoResponse[] = response.data;
        setGradeTypes(gradeTypesData);
      });

    if (selectedGradeType === 4) {
      DisciplinesService.getAcademicMobilityDisciplines()
        .then(({ data }) => {
          setAcademicMobilityDisciplines(renameDisciplineObjects(data));
        });
    }
  }, [selectedGradeType]);

  useEffect(() => {
    if (selectedCourse !== undefined && selectedYear !== undefined && studentsList !== undefined) {
      const educationProgramId = studentsList.find((student) => student.id === selectedStudent)?.educationProgramId;
      const searchEducationProgramId = searchStudentNames.find((student) => student.id === selectedStudent)?.educationProgramId;

      DisciplinesService.getDisciplinesFromCurriculumByFilter(
        educationProgramId ? educationProgramId : searchEducationProgramId,
        selectedYear,
        selectedCourse === 4 ? 0 : selectedCourse
      )
        .then(({ data }) => {
          setDisciplines(renameDisciplineObjects(data));
        });
    }
  }, [selectedCourse, selectedYear, selectedStudent, studentsList, searchStudentNames]);


  useEffect(() => {
    setSelectedGroup(selectedGroup);
    if (selectedGroup)
      TranscriptService.getStudentsByGroupForTranscript(selectedGroup).then(({ data }) => {
        let res = data.map((item) => {
          return {
            id: item.id,
            name: item.nameKz,
            surname: item.surnameKz,
            patronymic: item.patronymicKz,
            educationProgramId: item.educationProgramId,
            userId: item.userId
          };
        });
        setStudentsList(res);
      });
  }, [selectedGroup, setSelectedGroup]);

  const showMessage = (msg: string) => {
    if (
      selectedGroup !== null &&
      selectedYear !== null &&
      selectedCourse !== null &&
      selectedDiscipline !== null &&
      grade !== null
    ) {
      successNotification(msg)
    }
  };

  const handleAddDiscipline = () => {
    if (
      selectedCourse === undefined ||
      selectedYear === undefined ||
      selectedGradeType === undefined ||
      selectedDiscipline === undefined ||
      grade === undefined
    ) {
      successNotification('Заполните все обязательные поля');
    } else {

      const student = studentsList.find((student) => student.id === selectedStudent);
      const studentSearch = searchStudentNames.find((student) => student.id === selectedStudent);
      if (student || studentSearch) {
        DisciplinesService.addDiscipline(
          selectedStudent,
          selectedYear,
          selectedCourse,
          streamName,
          selectedDiscipline,
          grade,
          selectedGradeType,
        )
          .then(() => {
            showMessage('Дисциплина успешно добавлена!');
            setRerender(!rerender);
          })
          .finally(() => setIsModalVisible(false));
      }
    }
  };

  const onSelectStudent = async (studentId: number) => {
    const group = searchStudentNames.find((group) => group.id === studentId);

    setSelectedStudent(studentId);
    setSelectedGroup(group?.groupid);
  };

  const onGroupSelect = async (groupId: number) => {
    setSelectedGroup(groupId);

    TranscriptService.getStudentsByGroupForTranscript(groupId).then(({ data }) => {
      let res = data.map((item) => {
        return {
          id: item.id,
          name: item.nameKz,
          surname: item.surnameKz,
          patronymic: item.patronymicKz,
          educationProgramId: item.educationProgramId,
          userId: item.userId
        };
      });
      setStudentsList(res);
    });
  };

  // const handleSelectStudentsCourse = (value: number) => {
  //   setSelectedCourse(value);
  //   // EducationalProgramsService.getEducationalProgramsByCourse(value)
  //   //   .then((educProgramsData) => setEducPrograms(educProgramsData.data))
  //   //   .catch((err) => message.error(err.message));
  // };

  const handleSearchStudents = async (fullname: string) => {
    TranscriptService.getStudentByName(fullname)
      .then(({ data }) => {
        let res = data.map((item) => {
          return {
            id: item.id,
            name: item.nameKz,
            surname: item.surnameKz,
            patronymic: item.patronymicKz,
            groupid: item.group.id,
            groupname: item.group.title,
            educationProgramId: item.educationProgramId,
            userId: item.userId,
          };
        });
        setSearchStudentNames(res);
        if (res.length === 0) errorNotification(`Не найдено ни одного студента`);
        else successNotification(`Найдено ${res.length} студентов`);
      });
  };

  const renameDisciplineObjects = (data: any) => {
    return data.map((item: any) => {
      return {
        id: item.id,
        discipline: item.code + ' - ' + item.titleEn,
      };
    });
  };

  // const renameTeacherObjects = (data: any) => {
  //   return data.map((item: any) => {
  //     return {
  //       id: item.id,
  //       name: item.nameKz + ' ' + item.surnameKz,
  //     };
  //   });
  // };


  // const handleSelectStudentsCourse = (value: number) => {
  //   setSelectedCourse(value);
  //   DisciplinesService.getDisciplinesFromCurriculumByFilter(
  //     educationProgramId,
  //     selectedYear,
  //     selectedCourse
  //   ).then(({ data }) => {
  //     setDisciplines(renameDisciplineObjects(data));
  //   })
  // };



  // const onSearchQueryDisciplineChange = (disciplineId: number) => {
  //   setSelectedDiscipline(disciplineId);

  //   if (disciplineId !== selectedDiscipline) {
  //     VedomostiService.getTeachersByDiscipline(disciplineId).then(({ data }) => {
  //       setTutorList(renameTeacherObjects(data));
  //     });
  //   }
  // };

  return (
    <Modal
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      okText="Сохранить"
      cancelText="Отмена"
      onOk={() => handleAddDiscipline()}
    >
      <Typography.Title level={4}>Добавить дисциплину и оценку</Typography.Title>

      <Divider />
      <Button type="primary" href="/disciplines/create">
        Создать новую дисциплину
      </Button>
      <Divider />

      <Space size={'middle'} direction={'vertical'}>
        {isFilterVisible ? (
          <>
            <StudentGroupFilter
              placeholder="Группа обучающихся"
              selectedOption={selectedGroup}
              onSelect={(value) => onGroupSelect(value)}
              options={[...groupList]}
              width={width}
            ></StudentGroupFilter>

            <StudentsFilter
              placeholder="ФИО Студента"
              selectedOption={selectedStudent}
              onSelect={(value) => setSelectedStudent(value)}
              options={[...studentsList]}
              width={width}
            ></StudentsFilter>
          </>
        ) : (
          <>
            <Search placeholder="Введите ФИО Студента" onSearch={(value) => handleSearchStudents(value)} enterButton />

            <StudentSearch
              placeholder="Список Студентов"
              selectedOption={selectedStudent}
              onSelect={(value) => onSelectStudent(value)}
              options={[...searchStudentNames]}
              width={width}
            />
          </>
        )}

        <EnrollYearFilter
          placeholder="Курс"
          selectedOption={selectedYear}
          // Backend accepting 2019,2020,2021 instead of id's like 1 2 3
          onSelect={(value) => setSelectedYear(value)}
          options={[
            {
              id: 1,
              year: '1',
            },
            {
              id: 2,
              year: '2',
            },
            {
              id: 3,
              year: '3',
            },
          ]}
          width={width}
        />


        <EnrollYearFilter
          placeholder="Триместр"
          options={[
            {
              id: 0,
              year: 'Летник',
            },
            {
              id: 1,
              year: '1',
            },
            {
              id: 2,
              year: '2',
            },
            {
              id: 3,
              year: '3',
            },
            {
              id: 4,
              year: 'Дополнительный',
            },
          ]}
          selectedOption={selectedCourse}
          onSelect={(value) => {
            // handleSelectStudentsCourse(value);
            setSelectedCourse(value)
          }}
          width={width}
        />



        <Input placeholder="Stream name" value={streamName} onChange={(value) => setStreamName(value.target.value)} />


        <GradeFromTypeFilter
          placeholder="Тип оценки"
          selectedOption={selectedGradeType}
          options={[...gradeTypes]}
          onSelect={(value) => setSelectedGradeType(value)}
          width={width}

        />


        {/* <CreditsFilter
          placeholder="Кол-во кредитов"
          options={[...creditList]}
          selectedOption={selectedCredit}
          onSelect={(value) => {
            handleSelectCourseByCredits(value);
          }}
          width={width}
        /> */}

        {/* <DisciplinesFilter
          placeholder="Название дисциплины"
          selectedOption={selectedDiscipline}
          onSelect={(value) => onSearchQueryDisciplineChange(value)}
          options={[...disciplines]}
          width={width}
        ></DisciplinesFilter> */}

        {selectedGradeType === 4 ? (
          <DisciplinesFilter
            placeholder="Название дисциплины"
            selectedOption={selectedDiscipline}
            onSelect={(value) => setSelectedDiscipline(value)}
            options={[...academicMobilityDisciplines]}
            width={width}
          />
        ) : (
          <DisciplinesFilter
            placeholder="Название дисциплины"
            selectedOption={selectedDiscipline}
            onSelect={(value) => setSelectedDiscipline(value)}
            options={[...disciplines]}
            width={width}
          />
        )}



        <InputNumber
          style={{ width: 472 }}
          placeholder="Оценка"
          value={grade}
          onChange={(value) => setGrade(value ? value : 0)}
        ></InputNumber>
      </Space>
    </Modal>
  );
};

export default AddDisciplineModal;
