import React from 'react';
import { Button, Card, Col, Form, Input, Modal, Row, Space, Typography } from 'antd';
import {
  StudentAffirmationService,
  StudentAffirmationStatus,
  StudentAffirmationType,
} from '../../../services/StudentAffirmationService';
import UserService from '../../../services/userService';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import Page from '../../../ui/Page/Page';
import { successNotification } from '../../../helpers/successNotification';

const { Title } = Typography;

const ConfirmStudentInfoPage = () => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const inputStyle: React.CSSProperties = { fontWeight: 'bold', fontSize: 22, padding: 0 };

  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [choiceStatus, setChoiceStatus] = React.useState<string | undefined>(undefined);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const onAgreeOrDisagree = (status: boolean) => {
    StudentAffirmationService.createStudentAffirmation(
      StudentAffirmationType.CONFIRM_DATIVE_CASE,
      status,
      form1.getFieldValue('reason')
    )
      .then(() => {
        status ? setChoiceStatus(StudentAffirmationStatus.AGREE) : setChoiceStatus(StudentAffirmationStatus.NOT_AGREE);
        successNotification(`Data was successfully saved (${status ? 'Agree' : 'Disagree'})`);
      })
      .finally(() => setIsOpen(false));
  };

  React.useEffect(() => {
    Promise.all([
      StudentAffirmationService.getStudentStatusByStudentAndType(StudentAffirmationType.CONFIRM_DATIVE_CASE),
      UserService.getProfileByPrincipal(),
    ])
      .then(([studentStatus, additionalInfo]) => {
        setChoiceStatus(studentStatus.data);

        let fullNameKz = `${additionalInfo.data.student.surnameKz} ${additionalInfo.data.student.nameKz}`;

        if (additionalInfo.data.student.patronymicKz) {
          fullNameKz += ' ' + additionalInfo.data.student.patronymicKz;
        }
        const fullNameEn = `${additionalInfo.data.student.surnameEn} ${additionalInfo.data.student.nameEn}`;

        if (additionalInfo.data.student.patronymicEn) {
          fullNameKz += ' ' + additionalInfo.data.student.patronymicEn;
        }
        const dateBirth = moment(new Date(additionalInfo.data.student.birthDate).toDateString()).format('LL');

        if (fullNameKz === undefined || fullNameEn === undefined) {
          setIsButtonDisabled(true);
        }

        form.setFieldsValue({
          fullNameKz,
          fullNameEn,
          dateBirth,
        });
      });
  }, [form]);

  React.useEffect(() => {
    if (choiceStatus !== undefined) {
      switch (choiceStatus) {
        case StudentAffirmationStatus.AGREE:
        case StudentAffirmationStatus.NOT_AGREE:
          setIsButtonDisabled(true);
          break;
        case StudentAffirmationStatus.NOT_CHECKED:
          setIsButtonDisabled(false);
          break;
        default:
          setIsButtonDisabled(false);
      }
    }
  }, [choiceStatus]);

  return (
    <Row>
      <Col flex={8}></Col>
      <Col flex={8}>
        <Page title="Confirm Personal Information" center>
          <Card>
            <Form layout="vertical" size="large" form={form}>
              <Form.Item label="ФИО по удостоверению личности:" name="fullNameKz">
                <Input readOnly bordered={false} style={inputStyle} />
              </Form.Item>

              <Form.Item
                label="Фамилия и Имя на латинице по удостоверению личности (на обратной стороне):"
                name="fullNameEn"
              >
                <Input readOnly bordered={false} style={inputStyle} />
              </Form.Item>

              <Form.Item label="Дата рождения:" name="dateBirth">
                <Input readOnly bordered={false} style={inputStyle} />
              </Form.Item>
            </Form>
          </Card>
          <Title level={3} style={{ marginTop: 20 }}>
            Can you confirm that the data above is correct?
          </Title>
          <Title level={4} type="secondary" style={{ margin: 0 }}>
            {choiceStatus !== undefined && `Your current status: ${choiceStatus}`}
          </Title>
          <Space size="middle" style={{ marginTop: 20 }}>
            <Button type="primary" size="large" onClick={() => onAgreeOrDisagree(true)} disabled={isButtonDisabled}>
              Approve / Agree
            </Button>
            <Button type="primary" danger size="large" onClick={() => setIsOpen(true)} disabled={isButtonDisabled}>
              Disapprove / Disagree
            </Button>
          </Space>

          <Modal
            open={isOpen}
            title={'Please, describe in detail why you disagree'}
            onCancel={() => setIsOpen(false)}
            onOk={() => onAgreeOrDisagree(false)}
            okText={'Submit'}
          >
            <Form form={form1} onFinish={() => onAgreeOrDisagree(false)}>
              <Form.Item name="reason" required>
                <TextArea size={'middle'} style={{ height: 300 }}></TextArea>
              </Form.Item>
            </Form>
          </Modal>
        </Page>
      </Col>
      <Col flex={8}></Col>
    </Row>
  );
};

export default ConfirmStudentInfoPage;
