import React from 'react';
import { Button, Form, Input, Modal, Space, Typography } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import TranscriptTable from './TranscriptTable';
import {
  StudentAffirmationService,
  StudentAffirmationStatus,
  StudentAffirmationType,
} from '../../../services/StudentAffirmationService';
import { successNotification } from '../../../helpers/successNotification';

const { Title } = Typography;

const TranscriptPage = () => {
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [choiceStatus, setChoiceStatus] = React.useState<string | undefined>(undefined);
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const onAgreeOrDisagree = (status: boolean) => {
    StudentAffirmationService.createStudentAffirmation(StudentAffirmationType.TRANSCRIPT, status, form.getFieldValue('reason')
    )
      .then(() => {
        status ? setChoiceStatus(StudentAffirmationStatus.AGREE) : setChoiceStatus(StudentAffirmationStatus.NOT_AGREE);
        successNotification(`Data was successfully saved (${status ? 'Agree' : 'Disagree'})`);
      })
      .finally(() => setIsOpen(false));
  };

  React.useEffect(() => {
    StudentAffirmationService.getStudentStatusByStudentAndType(StudentAffirmationType.TRANSCRIPT)
      .then((response) => setChoiceStatus(response.data))
  }, []);

  React.useEffect(() => {
    if (choiceStatus !== undefined) {
      switch (choiceStatus) {
        case StudentAffirmationStatus.AGREE:
        case StudentAffirmationStatus.NOT_AGREE:
          setIsButtonDisabled(true);
          break;
        case StudentAffirmationStatus.NOT_CHECKED:
          setIsButtonDisabled(false);
          break;
        default:
          setIsButtonDisabled(false);
      }
    }
  }, [choiceStatus]);

  return (
    <PageWrapper>
      <Title level={1}>Transcript</Title>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <TranscriptTable />
        <Title level={4}>
          Do you agree with the Transcript above? {choiceStatus !== undefined && `Your current status: ${choiceStatus}`}
        </Title>
        <Space size="middle">
          <Button type="primary" size="large" onClick={() => onAgreeOrDisagree(true)} disabled={isButtonDisabled}>
            Approve / Agree
          </Button>
          <Button type="primary" danger size="large" onClick={() => setIsOpen(true)} disabled={isButtonDisabled}>
            Disapprove / Disagree
          </Button>
        </Space>
      </Space>
      <Modal open={isOpen}
        title={'Please, describe in detail why you disagree'}
        onCancel={() => setIsOpen(false)}
        onOk={() => onAgreeOrDisagree(false)}
        okText={'Submit'}>
        <Form form={form}>
          <Form.Item label="Reason:" name="reason">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </PageWrapper>
  );
};

export default TranscriptPage;
