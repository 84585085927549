import { Button, Form, Input, Modal, Space, Switch, Table, Upload } from 'antd';
import PageWrapper from '../../../../ui/PageWrapper';
import { useState } from 'react';
import Title from 'antd/es/typography/Title';
import { CertificateIssuanceRequestDtoResponse } from '../../../../interfaces/deansOffice/CertificateIssuanceRequestDtoResponse';
import { UploadOutlined } from '@ant-design/icons';
import useCertificateList from './useCertificateList';
import Typography from 'antd/lib/typography/Typography';
import TextArea from 'antd/es/input/TextArea';

const CertificateList = () => {
  const {
    spinner,
    all,
    modalView,
    rejectModal,
    setRejectModal,
    rejectModalList,
    setRejectModalList,
    setEdsPass,
    columns,
    makeCertificateMultiple,
    handleSubmitFileList,
    handleRejectList,
    multipleColumns,
    openModal,
    showModal,
    setShowModal,
    handleCancel,
    handleImage,
    handleSubmitFile,
    onUploadChange,
    onFinish,
    onFinishReject,
    form,
    rejectModalValue
  } = useCertificateList();

  const [checked, setChecked] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const handleSelect = (record: CertificateIssuanceRequestDtoResponse, selected: any) => {
    if (selected) {
      setSelectedRowKeys((ids: any) => [...ids, record.id]);
    } else {
      setSelectedRowKeys((ids: any) => {
        const index = ids.indexOf(record.id);
        return [...ids.slice(0, index), ...ids.slice(index + 1)];
      });
    }
  };

  const handleSelectAll = (selected: any, record: any, changeRows: any) => {
    if (selected) {
      changeRows.map((row: any) => setSelectedRowKeys(((ids: any) => [...ids, row.id])))
    }
    else {
      setSelectedRowKeys([])
    }
  }

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    getCheckboxProps: (record: any) => ({
      disabled: record.issued === "RECEIVED" || record.issued === "DENIED",
    }),
  };

  return (
    <PageWrapper>
      <header>
        <Title level={3}>Certificate list</Title>
      </header>
      <Modal
        title="Send certificate to student email"
        open={modalView}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <Form onFinish={onFinish}>
          <Form.Item name="edsPass" label="EDS password" required={true}>
            <Input.Password
              style={{ width: 304 }}
              required={true}
              onChange={(e) => {
                setEdsPass(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item name="fileEDS" label="Upload your RSA file" required={true}>
            {checked ? (
              <Upload
                onChange={(file) => onUploadChange(file)}
                beforeUpload={(file, fileList) => {
                  handleImage(file);
                }}
                customRequest={() => handleSubmitFileList(selectedRowKeys)}
              >
                <Button icon={<UploadOutlined />} loading={spinner}>
                  Upload EDS and Send to Email
                </Button>
              </Upload>
            ) : (
              <Upload
                onChange={(file) => onUploadChange(file)}
                beforeUpload={(file, fileList) => {
                  handleImage(file);
                }}
                customRequest={() => handleSubmitFile()}
              >
                <Button icon={<UploadOutlined />} loading={spinner}>
                  Upload EDS and Send to Email
                </Button>
              </Upload>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={spinner}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Rejection reason"
        open={checked ? rejectModalList : rejectModal}
        onCancel={checked ? () => setRejectModalList(false) : () => setRejectModal(false)}
        width={1000}
        footer={null}
      >
        <Typography>
          Please write the reason for refusal
        </Typography>
        <Form onFinish={checked ? () => handleRejectList(selectedRowKeys, form.getFieldValue(("reasonDeans"))) : onFinishReject} form={form}>
          <Form.Item name="reasonDeans" required={true}>
            <TextArea style={{ height: "200px" }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={spinner}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal open={showModal} onCancel={() => setShowModal(false)} onOk={() => setShowModal(false)}>
        <Space direction='vertical' style={{ width: "100%" }}>
          <Title level={5}>Students reason</Title>
          <TextArea readOnly value={rejectModalValue?.reasonStudent} />
          {rejectModalValue?.issued === "DENIED" && (
            <>
              <Title level={5}>Deans reason</Title>
              <TextArea readOnly value={rejectModalValue?.reasonDeans} />
            </>
          )}
        </Space>
      </Modal>

      <Space direction={"vertical"} style={{ width: "100%", height: "100%" }}>
        <Space direction={"horizontal"} style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <Switch onChange={() => setChecked(!checked)} checked={checked} checkedChildren="Multiple students mode" unCheckedChildren="Single student mode" />
          {checked && (
            <div>
              <Button type="primary" onClick={() => makeCertificateMultiple(selectedRowKeys)} disabled={selectedRowKeys.length === 0}>
                Download all students certificates
              </Button>
              <Button onClick={() => { openModal() }} style={{ marginLeft: "10px" }} loading={spinner} disabled={selectedRowKeys.length === 0}>
                Certificate to email
              </Button>
              <Button onClick={() => setRejectModalList(true)} danger style={{ marginLeft: "10px" }} loading={spinner} disabled={selectedRowKeys.length === 0}>
                Deny
              </Button>
            </div>
          )}
        </Space>

        {checked ? (
          <Table
            dataSource={all}
            loading={spinner}
            columns={multipleColumns}
            bordered={true}
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
          />
        ) : (
          <Table
            dataSource={all} loading={spinner} columns={columns} bordered={true} />
        )}
      </Space>

    </PageWrapper>
  );
};
export default CertificateList;
