import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Input, Tag, Select, DatePicker, Modal } from 'antd';
import PageWrapper from "../../../ui/PageWrapper";
import { CheckOutlined, CloseOutlined, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import useProfilePage from "../ProfilePage/useProfilePage";
import { FileService } from "../../../services/file/FileService";
import { successNotification } from "../../../helpers/successNotification";
import { FxExamApplicationService } from "./FxExamApplicationService";
import { errorNotification } from "../../../helpers/errorNotification";
interface FxExamApplicationItem {
    id: number;
    iin: number;
    fileId: number;
    studentName: string;
    studentSurname: string;
    disciplineName: string;
    fileName: string;
    approved: number;
    createdDate: Date;
    course: number;
    studentStatus: string;
    creditAmount: number;
    comment: string;
    teacher: string;
    groupName: string;
    email: string;
    studentNameEn: string;
    studentSurnameEn: string;
    attendance: number;
    midterm: number;
    endterm: number;
    term: number;
    final: number;
    approvedOfficeRegistrar: number;
}



interface Props {
    userId?: number;
}



const ApprovedFxExamApplications = ({ userId }: Props) => {
    const [fxExamApplicationData, setFxExamApplicationData] = useState<FxExamApplicationItem[]>([]);
    const { profileInfo } = useProfilePage(userId);
    const { Option } = Select;
    const { RangePicker } = DatePicker;

    useEffect(() => {
        fetchFxExamApplicationData();
    }, [profileInfo]);

    const fetchFxExamApplicationData = () => {
        const params = {
            studentStatus: filters.studentStatus,
            course: filters.course,
            approved: filters.approved,
            startDate: filters.startDate,
            endDate: filters.endDate
        };

        FxExamApplicationService.fetchFxExamApplicationData(params)
            .then((response) => {
                setFxExamApplicationData(response.data);
            });
    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [actionType, setActionType] = useState<'approve' | 'decline' | null>(null);

    const showConfirmModal = (type: 'approve' | 'decline') => {
        setActionType(type);
        setIsModalVisible(true);
    };

    const handleConfirm = () => {
        const queryParams = new URLSearchParams({
            studentStatus: filters.studentStatus || 'null',
            course: filters.course?.toString() || '0',
            approved: filters.approved?.toString() || '0',
            startDate: filters.startDate || '',
            endDate: filters.endDate || ''
        }).toString();

        if (actionType === 'approve') {
            FxExamApplicationService.approveAllApplicationsByFilterByOfficeRegistrar(queryParams)
                .then(() => {
                    successNotification('Applications approved successfully!');
                    fetchFxExamApplicationData();
                })
                .catch(error => console.error('Error approving applications:', error));
        } else if (actionType === 'decline') {
            FxExamApplicationService.declineAllApplicationsByFilterByOfficeRegistrar(queryParams)
                .then(() => {
                    successNotification('Applications declined successfully!');
                    fetchFxExamApplicationData();
                })
                .catch(error => console.error('Error declining applications:', error));
        }

        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };



    const fetchFxExamApplicationExcel = () => {
        const params = {
            studentStatus: filters.studentStatus,
            course: filters.course,
            approved: filters.approved,
            startDate: filters.startDate,
            endDate: filters.endDate
        };

        FxExamApplicationService.fetchFxExamApplicationExcel(params)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'fx-exam-applications-' + filters.studentStatus + '-' + filters.course + '-' + filters.startDate + '-' + filters.endDate + '.xlsx');
                document.body.appendChild(link);
                link.click();
                if (link.parentNode) {
                    link.parentNode.removeChild(link);
                }
            })
            .catch((error) => {
                console.error("Ошибка при получении данных:", error);
            });
    };




    const formatDateString = (dateString: string): string => {

        if (!dateString || new Date(dateString).toString() === 'Invalid Date') {
            return 'Unknown';
        }
        const date = new Date(dateString);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        return formattedDate;
    };



    const downloadFile = (id: number, disciplineName: string, studentName: string, studentSurname: string) => {
        const fileName = `Квитанция_${disciplineName}_${studentName}_${studentSurname}`;
        FileService.getFileById(id, fileName)
            .then(() => successNotification("Файл успешно скачан!"))
    };

    const getStatusText = (status: number) => {
        switch (status) {
            case 0:
                return <Tag color="gray">All statuses</Tag>;
            case 1:
                return <Tag color="blue">In processing</Tag>;
            case 2:
                return <Tag color="red">Declined</Tag>;
            case 3:
                return <Tag color="green">Approved</Tag>;
            default:
                return <Tag color="gray">Unknown status</Tag>;
        }
    };


    interface FilterState {
        studentStatus: string;
        course: number | null;
        approved: number | null;
        startDate: string | null;
        endDate: string | null;
    }

    const [filters, setFilters] = useState<FilterState>({
        studentStatus: '',
        course: null,
        approved: null,
        startDate: null,
        endDate: null
    });



    const [searchIIN, setSearchIIN] = useState('');

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchIIN(e.target.value);
    };



    const [selectedItem, setSelectedItem] = useState<FxExamApplicationItem | null>(null);

    const declineApplicationByOfficeRegistrar = (id: number) => {
        FxExamApplicationService.declineApplicationByOfficeRegistrar(id)
            .then(() => {
                fetchFxExamApplicationData();
            })
            .catch(error => console.error("Ошибка при обновлении статуса:", error));
    };

    const acceptApplicationByOfficeRegistrar = (id: number) => {
        FxExamApplicationService.acceptApplicationByOfficeRegistrar(id)
            .then(() => {
                fetchFxExamApplicationData();
            })
            .catch(error => console.error("Ошибка при обновлении статуса:", error));
    };
    const showModal = (record: FxExamApplicationItem) => {
        setSelectedItem(record);
    };


    const columns = [
        {
            title: (
                <>
                    <Input
                        placeholder="Find by IIN"
                        value={searchIIN}
                        onChange={handleSearchChange}
                        style={{ width: 150, margin: '10px 0' }}
                    />
                </>
            ),
            dataIndex: 'iin',
        },
        {
            title: 'Name',
            dataIndex: 'studentName',
        },
        {
            title: 'Surname',
            dataIndex: 'studentSurname',
        },
        {
            title: 'Name EN',
            dataIndex: 'studentNameEn',
        },
        {
            title: 'Surname EN',
            dataIndex: 'studentSurnameEn',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Course',
            dataIndex: 'course',
        },
        {
            title: 'Educational Degree',
            dataIndex: 'studentStatus',
        },
        {
            title: 'Group',
            dataIndex: 'groupName',
        },
        {
            title: 'Discipline',
            dataIndex: 'disciplineName',
        },
        {
            title: 'Teacher',
            dataIndex: 'teacher',
        },
        {
            title: 'Grades',
            dataIndex: 'id',
            render: (id: number, record: FxExamApplicationItem) => (
                <Button onClick={() => showModal(record)}>View</Button>
            )
        },
        {
            title: 'Amount of credits',
            dataIndex: 'creditAmount',
        },
        {
            title: 'Accountant application status',
            dataIndex: 'approved',
            key: 'approved',
            render: (status: number) => <span>{getStatusText(status)}</span>,
        },
        {
            title: 'Office Registrar application status',
            dataIndex: 'approvedOfficeRegistrar',
            key: 'approved',
            render: (status: number) => <span>{getStatusText(status)}</span>,
        },
        {
            title: 'Submission date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (text: string) => <span>{formatDateString(text)}</span>

        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            // render: (text: string, record: FxExamApplicationItem) => (
            //     <>
            //         <span>{record.comment}  </span>
            //         <Button onClick={() => showModal(record)}>{record.comment ? <EditOutlined /> : <PlusOutlined />}</Button>
            //     </>
            // )
        },
        {
            title: 'Receipt',
            dataIndex: 'fileId',
            render: (text: string, record: any) =>
                <Button icon={<DownloadOutlined />} onClick={() => downloadFile(record.fileId, record.disciplineName, record.studentName, record.studentSurname)} type="default">Скачать</Button>
        },
        // {
        //     title: 'Actions',
        //     key: 'actions',
        //     render: (text: string, record: FxExamApplicationItem) => (
        //         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        //             <Button icon={<CheckOutlined />} onClick={() => acceptApplicationByOfficeRegistrar(record.id)} type='primary' >Approve</Button>
        //             <Button icon={<CloseOutlined />} onClick={() => declineApplicationByOfficeRegistrar(record.id)} type='primary' danger style={{ marginLeft: '10px' }}>Decline</Button>
        //         </div>
        //     )
        // }
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: FxExamApplicationItem) => {
                if (record.approved === 3) {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button icon={<CheckOutlined />} onClick={() => acceptApplicationByOfficeRegistrar(record.id)} type='primary'>Approve</Button>
                            <Button icon={<CloseOutlined />} onClick={() => declineApplicationByOfficeRegistrar(record.id)} type='primary' danger style={{ marginLeft: '10px' }}>Decline</Button>
                        </div>
                    );
                } else {
                    return null;
                }
            }
        }
    ]



    const filteredData = fxExamApplicationData.filter(item =>
        item.iin.toString().includes(searchIIN)
    );

    return (
        <PageWrapper>
            <Typography.Title level={1}>FX Exam Applications</Typography.Title>

            <div>
                <Select
                    value={filters.studentStatus}
                    onChange={value => setFilters({ ...filters, studentStatus: value })}
                    style={{ width: 300 }}
                    placeholder="Educational Degree"
                >
                    <Option value="null">All statuses</Option>
                    <Option value="Bachelor">Bachelor</Option>
                    <Option value="Master of scientific and pedagogical direction">Master of scientific and pedagogical direction</Option>
                    <Option value="Master of the core area">Master of the core area</Option>
                    <Option value="Master of Business Administration">Master of Business Administration</Option>
                    <Option value="Резидент">Резидент</Option>
                    <Option value="Doctor PhD">Doctor PhD</Option>
                    <Option value="Doctor with specialization">Doctor with specialization</Option>
                    <Option value="Specialist">Specialist</Option>
                    <Option value="Preparatory department">Preparatory department</Option>

                </Select><br /><br />
                <Select
                    value={filters.course}
                    onChange={value => setFilters({ ...filters, course: value })}
                    style={{ width: 300 }}
                    placeholder="Course"
                >
                    <Option value={0}>All courses</Option>
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                </Select><br /><br />
                <Select
                    value={filters.approved}
                    onChange={value => setFilters({ ...filters, approved: value })}
                    style={{ width: 300 }}
                    placeholder="Accountant application status"
                >
                    <Option value={0}>All statuses</Option>
                    <Option value={1}>In processing</Option>
                    <Option value={2}>Declined</Option>
                    <Option value={3}>Approved</Option>
                </Select><br /><br />
                <RangePicker
                    onChange={dates => {
                        const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null;
                        const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null;
                        setFilters({ ...filters, startDate, endDate });
                    }}
                /><br /><br />
                <Button onClick={fetchFxExamApplicationData}>Apply filter</Button> <br /><br />
                <Button onClick={fetchFxExamApplicationExcel}><FileExcelOutlined /> Excel export by filter options</Button><br /><br />
                <Button
                    onClick={() => showConfirmModal('approve')}
                    type="primary"
                    style={{ marginBottom: 16, marginRight: 8 }}
                >
                    Approve All Applications
                </Button>
                <Button
                    onClick={() => showConfirmModal('decline')}
                    type="primary"
                    danger
                    style={{ marginBottom: 16 }}
                >
                    Decline All Applications
                </Button>

            </div>
            <div>
                <Table dataSource={filteredData} columns={columns} scroll={{ x: '500px' }} />

            </div>
            <Modal
                title="Confirm Action"
                visible={isModalVisible}
                onOk={handleConfirm}
                onCancel={handleCancel}
            >
                <Typography.Text>
                    Are you sure you want to {actionType === 'approve' ? 'approve' : 'decline'} applications with the following filters?
                </Typography.Text>
                <ul>
                    <li><b>Educational Degree:</b>  {filters.studentStatus === null || filters.studentStatus === 'null' ? 'All statuses' : filters.studentStatus}</li>
                    <li><b>Course:</b> {filters.course ? filters.course : 'All courses'}</li>
                    <li><b>Accountant application status:</b> {filters.approved !== null ? getStatusText(filters.approved) : 'All statuses'}</li>
                    <li><b>Start Date:</b> {filters.startDate || 'Not set'}</li>
                    <li><b>End Date:</b> {filters.endDate || 'Not set'}</li>
                </ul>
            </Modal>

            <Modal
                title="Grades"
                visible={!!selectedItem}
                onCancel={() => setSelectedItem(null)}
                footer={null}
            >
                {selectedItem && (
                    <Table
                        dataSource={[selectedItem]}
                        pagination={false}
                        columns={[
                            { title: 'Attendance', dataIndex: 'attendance' },
                            { title: 'Midterm', dataIndex: 'midterm' },
                            { title: 'Endterm', dataIndex: 'endterm' },
                            { title: 'Term', dataIndex: 'term' },
                            { title: 'Final', dataIndex: 'final' },
                        ]}
                    />
                )}
            </Modal>


        </PageWrapper>
    );
};


export default ApprovedFxExamApplications;