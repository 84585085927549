import Page from '../../../../ui/Page'
import { Button, Form, Select, Space } from 'antd'
import RowSpan from '../../../../ui/RowSpan'
import { useSyllabus } from '../useSyllabus'
import useDictionary from '../../../../hooks/useDictionary'
import { DownloadOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import SyllabusTable from './SyllabusGroupsAndStreamsTable'
import { successNotification } from '../../../../helpers/successNotification'
import BackButton from '../../../../ui/BackButton'


const SyllabusGroupsAndStreamsCreatePage = () => {
    const { data, methods } = useSyllabus();
    const { dictionary } = useDictionary({});


    return (
        <Page title={'Create syllabus'} subtitle='By groups and streams'>
            <BackButton />
            <RowSpan
                left={
                    <Form form={data.form} layout='vertical' style={{ width: "25em" }} onFinish={methods.handleSearchByParamsGroupsAndStreams}>
                        <Form.Item required name="degree_id" rules={[{ required: true }]} label='Academic degree'>
                            <Select>
                                {dictionary.degreeList?.map((item, index) => (
                                    <Select.Option key={index} value={item.id}>{`${item.title} / ${item.title} / ${item.title}`}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item required name={"enrolled_year"} rules={[{ required: true }]} label='Enrolled Year'>
                            <Select>
                                {dictionary.years?.map((item, index) => (
                                    <Select.Option key={index} value={item.year}>{item.year}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item required name={"education_year"} rules={[{ required: true }]} label='Education Year'>
                            <Select>
                                {dictionary.years?.map((item, index) => (
                                    <Select.Option key={index} value={item.year}>{item.year}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item required name={"course"} rules={[{ required: true }]} label='Course'>
                            <Select>
                                <Select.Option key={1} value={1}>{1}</Select.Option>
                                <Select.Option key={2} value={2}>{2}</Select.Option>
                                <Select.Option key={3} value={3}>{3}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <Button type='primary' htmlType='submit' loading={data.isLoading} icon={<SearchOutlined />}>Search</Button>
                                <Button type='primary' onClick={methods.handleDownloadExcelGroupsAndStreams} loading={data.isLoading} icon={<DownloadOutlined />}>Download Excel</Button>
                            </Space>
                        </Form.Item>
                    </Form>
                }
                right={
                    data.groupsAndStreams && <Button type='primary' onClick={() => {
                        methods.handleCreateOrUpdateGroupAndStreamSyllabus(
                            {
                                degreeId: data.form.getFieldValue('degree_id'),
                                enrolledYear: data.form.getFieldValue('enrolled_year'),
                                course: data.form.getFieldValue('course'),
                                approved: false,
                                json: JSON.stringify(data.groupsAndStreams)
                            }
                        ).then(() => {
                            successNotification('Syllabus has been saved successfully!')
                        })
                    }} loading={data.isLoading} icon={<PlusOutlined />}>Save syllabus</Button>
                }
            />

            {data.groupsAndStreams && (
                <SyllabusTable
                    key={JSON.stringify(data.groupsAndStreams)}
                    syllabus={data.groupsAndStreams}
                    setGroupsAndStreams={methods.setGroupsAndStreams}
                />
            )}
        </Page >
    )
}

export default SyllabusGroupsAndStreamsCreatePage