import { Button, Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { useState } from 'react';
import { VedomostiService } from '../../../../services/VedomostiService';
import Page from '../../../../ui/Page/Page';
import DeclinedTable from './DeclinedTable';

const DeclinedPage = () => {
  const [notApprovedStudents, setNotApprovedStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const handleSearch = (value: any) => {
    setLoading(true)
    VedomostiService.getVedomostForOffice({
      year: value.year,
      term: value.term,
      approved: value.approved,
      type: 1
    })
      .then(({ data }) => setNotApprovedStudents(data))
      .finally(() => setLoading(false));
  }

  return (
    <Page title="Все ведомости" subtitle="По статусам">
      <Form form={form} style={{ width: '30%' }} onFinish={handleSearch}>
        <FormItem name={"year"} rules={[{ required: true, message: 'Выберите год обучения' }]}>
          <Select
            placeholder={'Выберите год обучения'}
          >
            <Select.Option value={2019} key={0}>
              2019
            </Select.Option>
            <Select.Option value={2020} key={1}>
              2020
            </Select.Option>
            <Select.Option value={2021} key={2}>
              2021
            </Select.Option>
            <Select.Option value={2022} key={3}>
              2022
            </Select.Option>
            <Select.Option value={2023} key={4}>
              2023
            </Select.Option>
          </Select>
        </FormItem>
        <FormItem name={"term"} rules={[{ required: true, message: 'Выберите триместер' }]}>
          <Select
            placeholder={'Выберите триместер'}
          >
            <Select.Option value={1} key={0}>
              1
            </Select.Option>
            <Select.Option value={2} key={1}>
              2
            </Select.Option>
            <Select.Option value={3} key={2}>
              3
            </Select.Option>
          </Select>
        </FormItem>
        <FormItem name="approved" rules={[{ required: true, message: 'Выберите статус' }]}>
          <Select
            placeholder={'Выберите статус'}
          >
            <Select.Option value={'0'} key={0}>
              Опровергнуто
            </Select.Option>
            <Select.Option value={1} key={1}>
              Подтверждено
            </Select.Option>
            <Select.Option value={2} key={2}>
              Без статуса
            </Select.Option>
            <Select.Option value={3} key={3}>
              Все
            </Select.Option>
          </Select>
        </FormItem>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <DeclinedTable vedomostData={notApprovedStudents} loading={loading} />
    </Page>
  );
};

export default DeclinedPage;
