import authAxios from '../common/authAxios';
import { downloadFile } from '../helpers/downloadFile';

export class StudentContingentService {
  private static endpointPrefix: string = '/astanait-contingent-module/api/v1';

  static async getServiceTypeList() {
    return await authAxios.get(`${this.endpointPrefix}/application/type/get-all`);
  }

  static async createServiceApplication(application: any) {
    return await authAxios.post(`${this.endpointPrefix}/application/create`, application);
  }

  static async uploadCertificate(typeId: number, formData: FormData) {
    return authAxios.post(`/astanait-file-uploader-module/api/v1/file/upload`, { typeId: typeId, file: formData });
  }

  static async downloadStudentContingent(params: any) {
    return downloadFile(
      `/astanait-contingent-module/api/v1/contingent/list-of-students-contingent`,
      'GET',
      'blob',
      'Контингент студентов',
      'xlsx',
      params
    );
  }
}
