import { Form, notification } from 'antd';
import { useEffect, useState } from 'react';
import { PersistentStateConstants } from '../../../../PersistentStateConstants';
import { StudentDtoResponse } from '../../../../interfaces/Student/StudentDtoResponse';
import { PersistentStateService } from '../../../../services/PersistentStateService';
import { TranscriptService } from '../../../../services/TranscriptService';
import { CourseSelectResponse } from '../../../../interfaces/CourseSelectResponse';
import { StudentGroupsDtoResponse } from '../../../../interfaces/Student/StudentGroupsDtoResponse';
import { StudentStatusTypeDtoResponse } from '../../../../interfaces/Student/StudentStatusTypeDtoResponse';
import { StudentService } from '../../../../services/StudentService';
import { StudentStudyingStatusTypeDtoResponse } from '../../../../interfaces/Student/StudentStudyingStatusTypeDtoResponse';
import { VedomostiService } from '../../../../services/VedomostiService';
import { EducationalProgramsDtoResponse } from '../../../../interfaces/EducationalPrograms/EducationalProgramsDtoResponse';
import { errorNotification } from '../../../../helpers/errorNotification';
import { StudentContingentService } from '../../../../services/StudentContingentService';
import { FormInstance } from 'antd/es/form/Form';

interface Props {
  retirePage?: boolean;
}

const useStudentDetails = ({ retirePage = false }: Props) => {

  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState('');
  const [studentsList, setStudentsList] = useState<StudentDtoResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [courseOptions, setCourseOptions] = useState<CourseSelectResponse[]>([]);
  const [selectedEducationalDegree, setSelectedEducationalDegree] = useState<number>();
  const [selectedCourse, setSelectedCourse] = useState<number>();
  const [educPrograms, setEducPrograms] = useState<EducationalProgramsDtoResponse[]>([]);
  const [groupOptions, setGroupOptions] = useState<StudentGroupsDtoResponse[]>([]);
  const [studentStatusTypeList, setStudentStatusTypeList] = useState<StudentStatusTypeDtoResponse[]>([]);
  const [studentStudyStatusTypeList, setStudyStudentStatusTypeList] = useState<StudentStudyingStatusTypeDtoResponse[]>(
    []
  );
  const [studentsCount, setStudentsCount] = useState<number>(0);

  useEffect(() => {
    if (PersistentStateService.hasInputFieldsInfo(PersistentStateConstants.STUDENT_DETAILS_SEARCH_QUERY)) {
      const searchQuery: any = JSON.parse(
        // @ts-ignore
        PersistentStateService.getInputFieldsInfo(PersistentStateConstants.STUDENT_DETAILS_SEARCH_QUERY)
      );
      setSearchValue(searchQuery);
    }
    if (!retirePage) {
      StudentService.getStudentStatusList()
        .then(({ data }) => setStudentStatusTypeList(data))

      StudentService.getStudentStudyStatusList()
        .then(({ data }) => setStudyStudentStatusTypeList(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    PersistentStateService.setInputFieldsInfo(PersistentStateConstants.STUDENT_DETAILS_SEARCH_QUERY, searchValue);
  }, [searchValue]);

  const handleSearchStudents = (fullname: string) => {
    if (!fullname) {
      errorNotification('Введите имя в поле поиска!');
      return;
    }
    setLoading(true);
    TranscriptService.getStudentByName(fullname)
      .then(({ data }) => {
        setStudentsList(data);
        if (data.length === 0) errorNotification(`Не найдено ни одного студента`);
        else notification.success({ message: `Найдено ${data.length} студентов` });
        setStudentsCount(data.length);
      })
      .finally(() => setLoading(false));
  };

  const handleDownloadContingent = (value: FormInstance) => {
    const fieldsValues = value.getFieldsValue();
    setLoading(true);
    StudentContingentService.downloadStudentContingent(fieldsValues)
      .finally(() => setLoading(false));
  };

  const handleClear = () => {
    setStudentsCount(0);
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
    setStudentsList([]);
    handleClear();
  };

  const handleDegreeSelect = (value: number) => {
    setSelectedEducationalDegree(value);
    if (value === 2)
      setCourseOptions([
        { id: 1, year: 1 },
        { id: 2, year: 2 },
      ]);
    else
      setCourseOptions([
        { id: 1, year: 1 },
        { id: 2, year: 2 },
        { id: 3, year: 3 },
      ]);
  };
  const sortEduProgramsAlphabetically = (data: EducationalProgramsDtoResponse[]) => {
    return data.sort((a, b) => a.titleEn.localeCompare(b.titleEn));
  };

  const handleCourseSelect = (value: number) => {
    setSelectedCourse(value);
    VedomostiService.getEducationalProgramsByCourseAndDegree(value, selectedEducationalDegree)
      .then(({ data }) => {
        setEducPrograms(sortEduProgramsAlphabetically(data));
      })
      .finally(() => setLoading(false));
  };

  const handleEducationalProgramSelect = (value: any) => {
    VedomostiService.getGroupsByCourseAndEducationalProgram(selectedCourse, value)
      .then((res) => setGroupOptions(res.data))
      .finally(() => setLoading(false));
  };

  const handleSearchByParam = (value: any) => {
    StudentService.getStudentsByFilter(value)
      .then(({ data }) => {
        setStudentsList(data);
        if (data.length === 0) errorNotification(`Не найдено ни одного студента`);
        else notification.success({ message: `Найдено ${data.length} студентов` });
        setStudentsCount(data.length);
      })
      .finally(() => setLoading(false));
  };

  return {
    searchValue,
    courseOptions,
    educPrograms,
    groupOptions,
    setSearchValue,
    studentsList,
    setStudentsList,
    loading,
    setLoading,
    showFilter,
    handleSearchStudents,
    handleShowFilter,
    handleDegreeSelect,
    handleCourseSelect,
    handleEducationalProgramSelect,
    handleSearchByParam,
    studentStatusTypeList,
    studentStudyStatusTypeList,
    handleClear,
    studentsCount,
    handleDownloadContingent,
    form
  };
};

export default useStudentDetails;
