import styles from './style.module.css';
import { Typography, Table, Button, Pagination } from 'antd';
import PageWrapper from '../../../../ui/PageWrapper';
import useAcademicMobility from './useAcademicMobility';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;


const AcademicMobilityPage = () => {
    const { academicMobility, column, downloadExcel, currentPage, totalPages, setCurrentPage } = useAcademicMobility();

    return (
        <PageWrapper>
            <section>
                <header className={styles.header}>
                    <Title level={3} className={styles.title}>
                        Academic Mobility List
                    </Title>
                    <Link to="/academic-mobility/create">
                        <Button type="primary" icon={<PlusOutlined />}>
                            Add new academic mobility data
                        </Button>
                    </Link>
                    <Button type="primary" onClick={downloadExcel} >
                        Download Excel
                    </Button>
                </header>
                <Table
                   pagination={{
                    current: currentPage,
                    total: totalPages * 10, 
                    pageSize: 10, 
                    onChange: (page) => setCurrentPage(page),
                }}
                    dataSource={academicMobility.map((academicMobility) => ({
                        key: academicMobility.id,
                        student: academicMobility.student.fullNameKz,
                        university: academicMobility.university ? academicMobility.university.nameEn : '',
                        startDate: academicMobility.startDate,
                        endDate: academicMobility.endDate,
                        financingSource: academicMobility.financingSource ? academicMobility.financingSource.nameRu : '',
                        orderNumber: academicMobility.orderNumber,
                        mobilityType: academicMobility.mobilityType,
                        startTerm: academicMobility.startTerm,
                        finishTerm: academicMobility.finishTerm,
                    }))}
                    columns={column}
                />
                 {/* <div className={styles.paginationContainer}>
                    <Pagination
                        current={currentPage}
                        total={totalPages} 
                        pageSize={1} 
                        onChange={handlePageChange} 
                    />
                </div> */}
            </section>
        </PageWrapper>
    )
}

export default AcademicMobilityPage;