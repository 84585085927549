import UniversalTable from '../UniversalTeachersTable';
import Linkify from 'react-linkify';
import { TeacherArticleDtoRequest } from '../../../../interfaces/Teacher/TeacherArticleDtoRequest';
import { TeacherArticleDtoResponse } from '../../../../interfaces/Teacher/TeacherArticleDtoResponse';
import useFetchData from '../../../../hooks/useFetchData';
import { TeacherService } from '../../../../services/TeacherService';
import { Field } from '../../../../interfaces/Field';
import { successNotification } from '../../../../helpers/successNotification';


interface TeacherArticlesTableProps {
    isPublic: boolean;
}


const TeacherArticlesTable = ({ isPublic }: TeacherArticlesTableProps) => {

    const { data: articles, fetchData: fetchArticles } = useFetchData<TeacherArticleDtoResponse[]>(TeacherService.getTeacherArticles, true);

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Full citation: Authors, Title, Journal, Year, Pages. DOI',
            dataIndex: 'information',
            width: '40%',
            key: 'information',
            render: (_: any, record: any, idx: number) => {
                return <Linkify>{record.information}</Linkify>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'articleTypeStr',
            key: 'articleTypeStr',
        },
        {
            title: 'Link or DOI',
            dataIndex: 'linkOrDoi',
            key: 'linkOrDoi',
            render: (_: any, record: any, idx: number) => {
                return <Linkify>{record.linkOrDoi}</Linkify>;
            },
        },
        {
            title: 'Publication Date',
            dataIndex: 'publicationDate',
            key: 'publicationDate',
        },
    ];

    const articleFields: Field[] = [
        { name: 'information', label: 'Information', type: 'textArea' },
        {
            name: 'articleType', label: 'Article Type', type: 'select', options: [
                { value: 1, label: 'Scopus/Web of Science' },
                { value: 2, label: 'КОКСОН' },
                { value: 3, label: 'Other' },
            ]
        },
        { name: 'publicationDate', label: 'Publication Date', type: 'date' },
        { name: 'linkOrDoi', label: 'Link or DOI', type: 'text' },
    ];

    const addRecord = (record: TeacherArticleDtoRequest) => {
        TeacherService.createOrUpdateTeacherArticle(record)
            .then(() => {
                fetchArticles();
            })
            .finally(() => {
                successNotification('Article added!')
            })
    };

    const editRecord = (record: TeacherArticleDtoRequest) => {
        TeacherService.createOrUpdateTeacherArticle(record)
            .then(() => {
                fetchArticles();
            })
            .finally(() => {
                successNotification('Article updated!')
            })
    };

    const deleteRecord = (record: TeacherArticleDtoRequest) => {
        TeacherService.deleteTeacherArticle(record.id)
            .then(() => {
                fetchArticles();
            })
            .finally(() => {
                successNotification('Article deleted!')
            })
    };

    return (
        <UniversalTable
            columns={columns}
            dataSource={articles ? articles.map((article, i) => ({
                id: article.id,
                key: i + 1,
                information: article.information,
                articleTypeStr: article.articleType.name,
                articleType: article.articleType.id,
                linkOrDoi: article.linkOrDoi,
                publicationDate: article.publicationDate
            })) : []}
            addRecord={(e: TeacherArticleDtoRequest) => addRecord(e)}
            editRecord={(e: TeacherArticleDtoRequest) => editRecord(e)}
            deleteRecord={(e: TeacherArticleDtoRequest) => deleteRecord(e)}
            fields={articleFields}
            isPublic={isPublic}
        />
    )
}

export default TeacherArticlesTable