import React from 'react'
import Page from '../../../ui/Page';
import useFetchData from '../../../hooks/useFetchData';
import { Alert, Button, Card, DatePicker, Form, Input, Modal, Select, Space, Typography, Upload, UploadProps } from 'antd';
import ApplicationStatusTag from './ApplicationStatusTag';
import useModal from '../../../ui/Modal/useModal';
import useApplications from './useApplications';
import ApplicationStepInfo from './ApplicationStepInfo';
import grantPermission from '../../../helpers/grantPermission';
import RowSpan from '../../../ui/RowSpan';
import AuthenticatedContent from '../../../common/AuthenticatedContent';
import BackButton from '../../../ui/BackButton';
import { ApplicationService } from '../../../services/ApplicationService';
import UserService from '../../../services/userService';
import { DownloadOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { DictionaryCatalogDtoResponse } from '../../../interfaces/DictionaryCatalogDtoResponse';
import Spinner from '../../../ui/Spinner';
import StyledGrid from './StyledGrid';
import ApplicationInfo from './ApplicationInfo';
import { RcFile } from 'antd/es/upload/interface';
import { errorNotification } from '../../../helpers/errorNotification';
import { OrderStudentDtoRequest } from '../../../interfaces/Order/OrderStudentDtoRequest';
import { OrdersService } from '../../../services/OrdersService';
import { OrderCategoryDtoResponse } from '../../../interfaces/Order/OrderCategoryDtoResponse';
import { successNotification } from '../../../helpers/successNotification';
import { ApplicationStepInfoDtoResponse } from '../../../interfaces/Applications/response/ApplicationStepInfoDtoResponse';

interface Props {
    id: number;
}


const btnStyle: React.CSSProperties = {
    width: "100%",
    whiteSpace: "normal",
    overflowWrap: "break-word",
};


const ApplicationDetailPage = ({ id }: Props) => {
    const { data, methods } = useApplications({ fetchAllApplications: false, fetchApplicationId: true, fetchApplicationInfo: true, fetchApplicationStepInfo: true, id });
    const { data: statuses } = useFetchData<DictionaryCatalogDtoResponse[]>(ApplicationService.getApplicationStatuses, true);

    const { isModalOpen: isOpenModalSignModal, showModal: showModalSignModal, handleOk: handleOkSignModal, handleCancel: handleCancelSignModal } = useModal();
    const { isModalOpen: isOpenOrderModal, showModal: showModalOrderModal, handleOk: handleOkOrderModal, handleCancel: handleCancelOrderModal } = useModal();

    const user = React.useRef(UserService.getCurrentUser());

    const [ordersCategories, setOrdersCategories] = React.useState<OrderCategoryDtoResponse[]>([]);
    const [userRoles, setUserRoles] = React.useState<number[]>([]);

    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
    const [file, setFile] = React.useState<RcFile>({} as RcFile);
    const [form] = Form.useForm();
    const [orderForm] = Form.useForm();


    const uploadProps: UploadProps = {
        beforeUpload: (file: RcFile, fileList: RcFile[]) => {
            const isPDF = fileList[0].type === 'application/pdf';
            if (!isPDF) {
                errorNotification(`${fileList[0].name} is not pdf file!`);
            } else {
                setFile(file)
            }
            return false
        },
    };

    React.useEffect(() => {
        if (data.application) {
            if (data.application.typeId.orderType && grantPermission('STUDENT_DEPARTMENT', 'addRole')) {
                OrdersService.getOrderCategories(data.application.typeId.orderType.id)
                    .then((response) => {
                        setOrdersCategories(response.data);
                    })
            }
        }
    }, [data.application]);

    React.useEffect(() => {
        UserService.getRolesById(user.current.id)
            .then(({ data }) => setUserRoles(data.map(role => role.id)));
    }, [user.current.id]);

    const submitForm = () => {
        form
            .validateFields()
            .then((values) => {
                return methods.handleApprove(values, id)
                    .then(() => {
                        handleOkSignModal();
                    })
                    .finally(() => {
                        if (data.application?.currentStep === data.applicationStepInfo?.length) {
                            window.location.reload();
                        }
                        else {
                            methods.getApplicationById();
                            methods.getApplicationInfo();
                            methods.getApplicationStepInfo();
                        }
                    });
            });

    };

    const isCurrentUserSignStep = (applicationStepsInfo: ApplicationStepInfoDtoResponse[], currentStep?: number) => {
        const currentApplicationStep = applicationStepsInfo.find(step => step.step === currentStep);

        if (currentApplicationStep?.executor && currentApplicationStep?.executor !== user.current.id && data.application?.statusId.id === 1) {
            return (
                <Alert
                    message="Error"
                    description={
                        "You can't sign this application because you are not the executor of this step!"
                    }
                    type="error"
                    showIcon
                />
            )
        }

        if (currentApplicationStep?.roleId && userRoles && !userRoles.includes(currentApplicationStep.roleId)) {
            return (
                <Alert
                    message="Error"
                    description={
                        `You can't sign this application because you dont have suitable role!`
                    }
                    type="error"
                    showIcon
                />
            );
        }


        if (data.application?.statusId.id === 2) {
            return <RowSpan right={
                <Space>
                    <Button
                        onClick={() => {
                            data.application !== undefined && methods.handleDownload(data.application.fileId, data.application.typeId)

                        }}
                        type='primary'
                        icon={<DownloadOutlined />}>
                        Download the application file
                    </Button>
                    {data.application?.typeId.orderType && !data.application.order && data.application?.typeId.needOrder && (
                        <>
                            <Button
                                onClick={showModalOrderModal}
                                type='primary'
                                icon={<EditOutlined />}>
                                Create order for application
                            </Button>
                            <Modal open={isOpenOrderModal} title="Create order for that application" onCancel={handleCancelOrderModal} onOk={orderForm.submit}>
                                <Form form={orderForm} onFinish={e => {
                                    const orderStudentDtoRequest: OrderStudentDtoRequest = {
                                        orderDtoRequest: {
                                            ...e,
                                            orderType: data.application?.typeId.orderType.id,
                                            issued: true,
                                        },
                                        students: [data.application?.student.id ?? 0]
                                    };

                                    !file
                                        ?
                                        errorNotification('Please upload file!')
                                        :
                                        methods.handleOrder(id, file, orderStudentDtoRequest)
                                            .then(() => {
                                                handleOkOrderModal();
                                                methods.getApplicationById();
                                                methods.getApplicationInfo();
                                                methods.getApplicationStepInfo();
                                            })
                                            .finally(() => {
                                                successNotification('Order created successfully!')
                                            });
                                }} layout='vertical'>
                                    <Form.Item
                                        label="Тип приказа"
                                        name="orderType"
                                        initialValue={data.application?.typeId.orderType.id}
                                        rules={[{ required: true, message: 'Выберите тип приказа' }]}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <Select placeholder="Выберите приказ" disabled>
                                            <Select.Option value={data.application.typeId.orderType.id}>
                                                {`${data.application.typeId.orderType.nameRu} / ${data.application.typeId.orderType.name} / ${data.application.typeId.orderType.nameEn}`}
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Категория" name="orderCategory" rules={[{ required: ordersCategories.length !== 0, message: 'выберите категорию' }]}
                                        style={{ marginBottom: '10px' }}>
                                        <Select placeholder="Выберите категорию приказа" allowClear>
                                            {ordersCategories?.map((option) => (
                                                <Select.Option key={option.id} value={option.id}>
                                                    {`${option.nameRu}`}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Номер приказа"
                                        name="number"
                                        rules={[{ required: true, message: 'номер приказа' }]}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <Input placeholder="Введите номер приказа" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Название приказа"
                                        name="name"
                                        rules={[{ required: true, message: 'Введите название приказа' }]}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <Input placeholder="Введите название приказа" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Дата приказа"
                                        name="issueDate"
                                        rules={[{ required: true, message: 'Выберите дату создания приказа' }]}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <DatePicker placeholder="Выберите дату" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Дата движения"
                                        name="contingateDate"
                                        rules={[{ required: true, message: 'Выберите дату движения' }]}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <DatePicker placeholder="Выберите дату" />
                                    </Form.Item>
                                    <Upload {...uploadProps} accept={'.pdf'}>
                                        <Button icon={<UploadOutlined />}>Загрузите файл приказа</Button>
                                    </Upload>
                                </Form>
                            </Modal>
                        </>
                    )}
                </Space>
            } />;
        }

        return <RowSpan right={
            <>
                <Button style={{ marginRight: 20 }}
                    onClick={() => {
                        data.application !== undefined &&
                            !data.application.fileId && methods.handleDownloadTestApplication(data.application.id, data.application.typeId)
                    }}
                    type='primary'
                    icon={<DownloadOutlined />}>
                    Download the application file
                </Button>
                <Button onClick={showModalSignModal} type='primary' icon={<EditOutlined />}>Sign the application</Button>
                <Modal open={isOpenModalSignModal} title={"Sign the application"} onCancel={handleCancelSignModal} onOk={submitForm}>
                    <Form form={form}>
                        <Form.Item
                            label="Status"
                            name="statusId"
                            rules={[{ required: true, message: 'Please choose status!' }]}>
                            <Select placeholder="Choose status">
                                {statuses?.filter(v => v.id !== 1)?.map((status) => (
                                    <Select.Option key={status.id} value={status.id}>{`${status.nameRu} / ${status.name} / ${status.nameEn}`}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Comment"
                            name="comment"
                            rules={[{ required: true, message: 'Please write a comment!' }]}>
                            <Input.TextArea />
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        } />;

    }

    return data.application && data.applicationInfo && data.applicationStepInfo ? (
        <Page
            title={data.application.typeId.nameEn}
            subtitle={grantPermission('OFFICE', 'baseRole') ? data.application?.student.fullName + " - " + data.application?.student.group.title : ""}
        >
            <Space style={{ width: "100%" }} size={"large"} direction='vertical'>
                <BackButton />
                <AuthenticatedContent role={"OFFICE"} type='baseRole'>
                    {isCurrentUserSignStep(data.applicationStepInfo, data.application?.currentStep)}
                </AuthenticatedContent>
                <AuthenticatedContent role={"STUDENTS"} type='baseRole'>
                    {data.application?.statusId.id === 4 && (
                        <Button icon={<EditOutlined />} onClick={() => setIsEditMode(!isEditMode)}>Edit application information</Button>
                    )}
                </AuthenticatedContent>

                <Card title="General information">
                    <StyledGrid>
                        {`Created date :  ${new Date(data.application?.createdAt).toLocaleDateString()}`}
                    </StyledGrid>
                    <StyledGrid>
                        {`Current status : `} <ApplicationStatusTag statusNameRu={data.application.statusId.nameRu} />
                    </StyledGrid>
                    <StyledGrid>
                        {`Application language : ${methods.handleLanguage(data.application.lang)}`}
                    </StyledGrid>
                    <StyledGrid>
                        {`Reason : `}
                        <Typography.Text style={{ maxWidth: "250px" }}>
                            {data.application.reason}
                        </Typography.Text>
                    </StyledGrid>
                    <StyledGrid>
                        {`Student profile : `}
                        <Button style={btnStyle} type="link" >
                            <a
                                href={`/profile/${data.application?.student.userId}`}
                                target="_blank"
                                rel="noreferrer"
                            >View profile</a>
                        </Button>
                    </StyledGrid>
                </Card>

                <ApplicationInfo
                    application={data.application}
                    applicationInfo={data.applicationInfo}
                    isEdit={isEditMode}
                    setIsEdit={setIsEditMode}
                    isAccepted={data.application.statusId.id === 2}
                    applicationId={data.application.id}
                    handleUpdate={methods.handleUpdate}
                />

                <ApplicationStepInfo
                    applicationStepInfo={data.applicationStepInfo}
                    application={data.application}
                />
            </Space>
        </Page >
    ) : (<Spinner size="large" />)
}

export default ApplicationDetailPage