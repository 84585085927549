import axios from "axios";
import { Constants } from "../common/constants";

export class PublicService {
    static baseURL = Constants.API_BASE_URL;

    static getRegisterNumberByIIN(iin: string) {
        return axios.get(
            `${this.baseURL}/astanait-diploma-work-module/api/v1/public/get-by-iin?iin=${iin}`
        );
    }
}