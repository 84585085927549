import { FiltersOptionsDtoResponse } from '../../../../interfaces/FIltersOptionsDtoResponse';

import { Select } from 'antd';

type Options = FiltersOptionsDtoResponse[];

const { Option } = Select;

interface Props {
  placeholder: string;
  options: Options;
  selectedOption: number | undefined;
  onSelect: (value: number) => void;
}

const CommissionMemberNumberFilter = ({ placeholder, options, selectedOption, onSelect }: Props) => {
  return (
    <Select style={{ width: '100%' }} placeholder={placeholder} value={selectedOption} onSelect={onSelect}>
      {options.map((option) => (
        <Option key={option.id} value={option.id}>
          {option.option}
        </Option>
      ))}
    </Select>
  );
};

export default CommissionMemberNumberFilter;
