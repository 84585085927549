import React, { useState } from 'react';
import { CertificateService } from '../../../services/deansOffice/CertificateService';
import { CertificateDtoResponse } from '../../../interfaces/CertificateDtoResponse';

interface ListDataProps {
  field: string;
  dataIndex: string;
  content: string;
}

const data = [
  {
    field: 'ФИО слушателя',
    dataIndex: 'issuedTo',
    content: '',
  },
  {
    field: 'Статус документа',
    dataIndex: 'isActive',
    content: '',
  },
  {
    field: 'Тип документа',
    dataIndex: 'type',
    content: '',
  },
  {
    field: 'Регистрационный номер документа',
    dataIndex: 'number',
    content: '',
  },
  {
    field: 'Дата выдачи документа',
    dataIndex: 'issuedDate',
    content: '',
  },
  {
    field: 'Документ действителен до',
    dataIndex: 'validUntil',
    content: '',
  },
  {
    field: 'Название курса',
    dataIndex: 'courseName',
    content: '',
  },
];

const useCertificateValidation = () => {
  const [listDisabled, setListDisabled] = useState<boolean>(true);
  const [codeString, setCodeString] = useState<string>('');
  const [listData, setListData] = useState<ListDataProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const mergeCertificateDataWithListData = (certificateData: CertificateDtoResponse) => {
    Object.entries(certificateData).forEach(([prop, key]) => {
      data.forEach((item) => {
        if (item.dataIndex === prop) item.content = key;
      });
    });
    return data;
  };

  const verifyCertificate = (code: string) => {
    setLoading(true);
    CertificateService.getCertificateByCode(code)
      .then(({ data }) => {
        const resultData = mergeCertificateDataWithListData(data);
        setListData(resultData);
        if (listDisabled) setListDisabled(false);
      })
      .finally(() => setLoading(false));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodeString(e.target.value);
  };
  return {
    data: {
      codeString,
      loading,
      listDisabled,
      listData,
    },
    handlers: {
      verifyCertificate,
      handleInputChange,
    },
  };
};

export default useCertificateValidation;
