import React from 'react'
import { GraduationThesisDto } from '../../../../interfaces/Student/StudentGraduationThesisChooseDto';
import useFetchData from '../../../../hooks/useFetchData';
import { DiplomaService } from '../../../../services/DiplomaService';
import { Button, Descriptions, Form, Input, Modal, Progress, Space, Switch, Table, Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import useModal from '../../../../ui/Modal/useModal';
import { successNotification } from '../../../../helpers/successNotification';
import moment from 'moment';
import CustomModal from '../../../../ui/Modal';
import grantPermission from '../../../../helpers/grantPermission';

interface GroupedByTeacher {
    teacher: string;
    thesisList: GraduationThesisDto[];
}

const useDiplomaApprove = () => {
    const {
        data: graduationThesis,
        isLoading: isGraduationThesisLoading,
        fetchData: fetchGraduationThesis,
    } = useFetchData<GraduationThesisDto[]>(DiplomaService.getGraduationThesisForOffice, true);

    const [modal, contextHolder] = Modal.useModal();
    const { isModalOpen, handleCancel } = useModal();
    const [form] = Form.useForm();
    const [checked, setChecked] = React.useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<number[]>([]);

    const groupedByTeacher: GroupedByTeacher[] = React.useMemo(() => {
        const groupedByTeacher: GroupedByTeacher[] = [];
        graduationThesis?.forEach((thesis) => {
            const teacher = thesis.supervisor.surnameEn + ' ' + thesis.supervisor.nameEn;
            const teacherIndex = groupedByTeacher.findIndex((group) => group.teacher === teacher);
            if (teacherIndex === -1) {
                groupedByTeacher.push({
                    teacher,
                    thesisList: [thesis],
                });
            } else {
                groupedByTeacher[teacherIndex].thesisList.push(thesis);
            }
        });
        return groupedByTeacher;
    }, [graduationThesis]);

    const columns = [
        {
            title: 'Teacher',
            dataIndex: 'teacher',
            key: 'teacher',
        },
        {
            title: 'Number of thesises',
            dataIndex: 'thesisListNumber',
            key: 'thesisListNumber',
            render: (text: any, record: GroupedByTeacher) => record.thesisList.length
        }
    ];

    const handleStatus = (record: GraduationThesisDto) => {
        switch (record.status.nameEn) {
            case "created ":
                return <Tag color="blue">Created</Tag>;
            case "for approval":
                return <Tag color="orange">For Approval</Tag>;
            case "approved":
                return <Tag color="green">Approved</Tag>;
            case "rejected":
                return <CustomModal
                    title="Rejected"
                    onOk={() => { }}
                    btnIcon={<InfoCircleFilled />}
                    btnTitle='Show rejected comment'
                >
                    <Descriptions column={1}>
                        <Descriptions.Item label="Comment"><Input.TextArea value={record.comment} /></Descriptions.Item>
                        <Descriptions.Item label="Rejected At">{moment(record.approvedAt).format('LL hh:mm')}</Descriptions.Item>
                    </Descriptions>
                </CustomModal>;
            default:
                return <Tag color="blue">Created</Tag>;
        }
    };

    const handleNoteClick = (record: GraduationThesisDto) => {
        modal.confirm({
            title: 'Similarity List of the Thesis',
            content: (
                <Space direction="vertical" size={"large"}>
                    {record.similarityList.map((similarity) => (
                        <Space key={similarity.id}>
                            <p>{similarity.refThesisTitle}</p>
                            <Progress
                                type="circle"
                                percent={similarity.titleSimilarityPercent}
                                strokeWidth={3}
                                width={80}
                            />
                        </Space>
                    ))}
                </Space>
            ),
            open: isModalOpen,
            onOk() {
                handleCancel();
            },
            onCancel() {
                handleCancel();
            }
        });
    }

    const handleApprove = (thesisId: number, status: boolean) => {
        return modal.confirm({
            title: status === true ? 'Approve the Thesis' : 'Reject the Thesis',
            content: (
                <Form
                    onFinish={(e) =>
                        DiplomaService.approveGraduationThesis(thesisId, {
                            comment: e.comment,
                            approved: status,
                        })
                            .then(() => {
                                successNotification('Thesis has been approved');
                                Modal.destroyAll();
                                form.resetFields();
                                fetchGraduationThesis();
                            })
                    }
                    layout='vertical'
                    form={form}
                >
                    <Form.Item
                        name="comment"
                        label="Comment"
                        rules={[{ required: status === true ? false : true, message: 'Please input your comment!' }]}
                    >
                        <Input.TextArea size='small' />
                    </Form.Item>
                </Form >
            ),
            onOk() {
                form.submit();
            }
        });
    }

    const handleApproveAll = (status: boolean) => {
        return modal.confirm({
            title: status === true ? 'Approve the Student' : 'Reject the Student',
            content: (
                <Form
                    onFinish={(e) =>
                        Promise.all(selectedRowKeys.map((id) => {
                            return DiplomaService.approveGraduationThesis(id, {
                                comment: e.comment,
                                approved: status,
                            });
                        }))
                            .then(() => {
                                successNotification('Student has been approved');
                                Modal.destroyAll();
                                form.resetFields();
                                fetchGraduationThesis();
                                setSelectedRowKeys([]);
                            })
                    }
                    layout='vertical'
                    form={form}
                >
                    <Form.Item
                        name="comment"
                        label="Comment"
                        rules={[{ required: status === true ? false : true, message: 'Please input your comment!' }]}
                    >
                        <Input.TextArea size='small' />
                    </Form.Item>
                </Form >
            ),
            onOk() {
                form.submit();
            }
        });
    }

    const expandedRowRender = (record: GraduationThesisDto[]) => {
        if (record.length === 0) return null;

        const expandedColumns = [
            {
                title: 'Defense Year',
                dataIndex: 'defenseYear',
                key: 'defenseYear',
                width: "5%",
            },
            {
                title: 'Title (EN)',
                dataIndex: 'titleEn',
                key: 'diplomaWorkTitleEn',
                width: "15%",
            },
            {
                title: 'Title (KZ)',
                dataIndex: 'titleKz',
                key: 'diplomaWorkTitleKz',
                width: "15%",
            },
            {
                title: 'Title (RU)',
                dataIndex: 'titleRu',
                key: 'diplomaWorkTitleRu',
                width: "15%",
            },
            {
                title: 'Degree',
                dataIndex: ['type', 'academicDegree', 'titleEn'],
                key: 'degree',
                width: "5%",
            },
            {
                title: 'Free',
                dataIndex: 'isFree',
                render: (text: boolean) => (text ? 'Yes' : 'No'),
                width: "5%",
            },
            {
                title: 'Type (EN)',
                dataIndex: ['type', 'nameEn'],
                key: 'Type',
                width: "5%",
            },
            {
                title: 'Individual/Group',
                dataIndex: 'individual',
                key: 'individual',
                render: (text: boolean) => (text ? 'Individual' : 'Group'),
                width: "5%",
            },
            {
                title: 'Required Students Number',
                dataIndex: 'requiredStudentsNumber',
                key: 'requiredStudentsNumber',
                width: "5%",
            },
            {
                title: 'Status',
                dataIndex: 'statusInfo',
                key: 'statusInfo',
                render: (text: any, record: GraduationThesisDto) => handleStatus(record),
                width: "1%",
            },
            {
                title: 'Similiarity Info',
                dataIndex: 'similiarityList',
                key: 'similiarityInfo',
                render: (text: any, record: GraduationThesisDto) => record.similarityList && record.similarityList.length > 0 &&
                    (
                        <Button
                            icon={<InfoCircleFilled />}
                            onClick={() => handleNoteClick(record)}
                        >
                            Similiarity Info
                        </Button>
                    ),
                width: "5%",
            },
            grantPermission('STUDY_DEPARTMENT_DIRECTOR', 'addRole') ? {
                title: 'Action',
                key: 'action',
                render: (text: any, record: GraduationThesisDto) => record.status.id === 2 && (
                    <Space size="middle">
                        <Button
                            type="primary"
                            onClick={() => handleApprove(record.id, true)}
                            icon={<CheckCircleOutlined />}
                            disabled={checked}
                        >
                            Approve
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => handleApprove(record.id, false)}
                            icon={<CloseCircleOutlined />}
                            disabled={checked}
                            danger
                        >
                            Reject
                        </Button>
                    </Space>
                ),
                width: "5%",
            } : {}
        ];

        return (
            <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
                <Switch onChange={() => setChecked(!checked)} checked={checked} checkedChildren="Multiple approve" unCheckedChildren="Single approve" />
                {checked &&
                    <Space>
                        <Button
                            type="primary"
                            icon={<CheckCircleOutlined />}
                            onClick={() => {
                                handleApproveAll(true);
                            }}
                            disabled={selectedRowKeys.length === 0}
                        >
                            Approve all
                        </Button>
                        <Button
                            type="primary"
                            icon={<CloseCircleOutlined />}
                            onClick={() => {
                                handleApproveAll(false);
                            }}
                            danger
                            disabled={selectedRowKeys.length === 0}
                        >
                            Reject all
                        </Button>
                    </Space>
                }
                {checked
                    ?
                    <Table
                        columns={expandedColumns}
                        dataSource={record}
                        pagination={false}
                        rowKey="id"
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedRowKeys(selectedRows.map((row) => row.id));
                            },
                            getCheckboxProps: (record) => ({
                                disabled: record.status.id !== 2,
                            }),
                        }}
                    />
                    :
                    <Table
                        columns={expandedColumns}
                        dataSource={record}
                        pagination={false}
                        rowKey="id"
                    />
                }
            </Space>
        );
    };

    return { graduationThesis, groupedByTeacher, expandedRowRender, isGraduationThesisLoading, columns, contextHolder }
}

export default useDiplomaApprove