import { SaveOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd'
import React from 'react'
import grantPermission from '../../../../helpers/grantPermission';
import { TeacherDevelopmentsDtoResponse } from '../../../../interfaces/Teacher/TeacherDevelopmentsDtoResponse'
import { FileService } from '../../../../services/file/FileService';

interface Props {
    dataSource?: TeacherDevelopmentsDtoResponse[];
}

const PublicTeacherDevelopmentTable = ({ dataSource }: Props) => {
    const columns = grantPermission('HR') ? [
        {
            title: "#",
            dataIndex: "key",
            key: "key"
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            render: (_: any, record: any) => {
                return (
                    <Space>
                        {record.fileId && (<Button onClick={() => FileService.getFileById(record.fileId, "Teacher development")} icon={<SaveOutlined />}>Download</Button>)}
                    </Space>
                )
            }
        }
    ] : [
        {
            title: "#",
            dataIndex: "key",
            key: "key"
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
    ]

    return (
        <Table bordered columns={columns}
            dataSource={dataSource?.map((data, i) => ({
                key: i + 1,
                id: data.id,
                name: data.name,
                fileId: data.fileId
            }))}
            style={{
                overflowX: "auto"
            }}
        />
    )
}

export default PublicTeacherDevelopmentTable