import { DeleteTwoTone, DownloadOutlined, EditTwoTone } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import { useEffect, useState } from 'react';

import moment from 'moment';
import { NotificationService } from '../../../../services/NotificationService';
import { NotificationDtoRsponse } from '../../../../interfaces/Notification/response/NotificationDtoResponse';


const useNotificationByUserPage = () => {
    const [shouldRerender, setShouldRerender] = useState(false);
    const [notification, setNotification] = useState<NotificationDtoRsponse[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);


    useEffect(() => {
        const zeroBasedPage = currentPage - 1;
        NotificationService.getNotificationByUser(zeroBasedPage)
            .then((response) => {
                setNotification(response.data.data);
                setTotalPages(response.data.total_pages);
            })
            .catch((error) => console.error(error));

    }, [shouldRerender, currentPage]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalText, setModalText] = useState('');
    const showModal = (data: any) => {
        setIsModalVisible(true);
        NotificationService.updateNotificationStatus(data.record.key)
            .then((response) => {
                setModalText(data.record.message)
            })
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setShouldRerender(prev => !prev);
        window.location.reload();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setShouldRerender(prev => !prev);
        window.location.reload();
    };

    const column = [
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            render: (text: any, record: any) => (record && record.message && record.isRead ? record.message : 'New notification'),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (text: any, record: any) => (record && record.createdDate ? moment(record.createdDate).format('DD.MM.YYYY') : 'Не найдено'),
        },
        {
            title: 'Is Read',
            dataIndex: 'isRead',
            key: 'isRead',
            render: (text: any, record: any) => {


                return record && record.isRead == true ? (
                    'Yes'
                ) : (
                    <>
                        <Button type="primary" onClick={() => showModal({ record })}>
                            Read
                        </Button>
                        <Modal
                            title="Notification"
                            visible={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            maskClosable={false}
                            keyboard={false}
                        >
                            <p>{modalText}</p>
                        </Modal>
                    </>
                );
            },
        },

        {
            title: 'Read at',
            dataIndex: 'readAt',
            key: 'readAt',
            width: 180,
            render: (text: any, record: any) => (record && record.readAt ? moment(record.createdDate).format('DD.MM.YYYY HH:mm') : '')
        },
        {
            title: 'Is read in Moodle',
            dataIndex: 'moodleMessageIsRead',
            key: 'moodleMessageIsRead',
            render: (text: any, record: any) => (record && record.moodleMessageIsRead == true ? 'Yes' : 'No')
        },
        {
            title: 'Read at in Moodle',
            dataIndex: 'moodleMessageReadAt',
            key: 'moodleMessageReadAt',
            render: (text: any, record: any) => (record && record.moodleMessageReadAt ? moment(record.createdDate).format('DD.MM.YYYY HH:mm') : '')
        },

    ]
    return {
        notification,
        column,
        currentPage,
        totalPages,
        setCurrentPage

    };
};

export default useNotificationByUserPage;