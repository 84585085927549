import React from 'react'
import Page from '../../../../../ui/Page';
import { CurriculumService } from '../../../../../services/CurriculumService';
import { DisciplinesService } from '../../../../../services/DisciplinesService';
import Spinner from '../../../../../ui/Spinner';
import RowSpan from '../../../../../ui/RowSpan';
import { Button, Space, Table } from 'antd';
import CustomModal from '../../../../../ui/Modal';
import { CurriculumDtoResponse } from '../../../../../interfaces/EducationalPrograms/CurriculumDtoResponse';
import { successNotification } from '../../../../../helpers/successNotification';
import { RequisitionDtoResponse } from '../../../../../interfaces/RequisitionDtoResponse';
import { DeleteOutlined } from '@ant-design/icons';

interface Props {
    educationalProgramId: number;
    curriculumId: number;
    requisiteType: 'postreq' | 'prereq';
    handleSuccess: () => void;
}

interface DisciplineModalProps {
    curriculums: CurriculumDtoResponse[];
    requisitions?: RequisitionDtoResponse[];
    requisiteProps: Props;
}

const disciplineColumns = [
    {
        title: "№",
        dataIndex: "id",
        key: "discipline.id",
    },
    {
        title: "Название",
        dataIndex: "titleRu",
        key: "discipline.titleRu",
    },
    {
        title: "Код дисциплины",
        dataIndex: "code",
        key: "discipline.code",
    },
    {
        title: "Кол-во кредитов",
        dataIndex: "volumeCredits",
        key: "discipline.volumeCredits",
    },
];

const DisciplineModal = ({ curriculums, requisiteProps, requisitions }: DisciplineModalProps) => {
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<any[]>([]);

    const handleSelect = (record: any, selected: any) => {
        if (selected) {
            setSelectedRowKeys((ids: any) => [...ids, record.id]);
        } else {
            setSelectedRowKeys((ids: any) => {
                const index = ids.indexOf(record.id);
                return [...ids.slice(0, index), ...ids.slice(index + 1)];
            });
        }
    };

    const handleSelectAll = (selected: any, record: any, changeRows: any) => {
        if (selected) {
            changeRows.map((row: any) => setSelectedRowKeys(((ids: any) => [...ids, row.id])))
        }
        else {
            setSelectedRowKeys([])
        }
    }


    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onSelect: handleSelect,
        onSelectAll: handleSelectAll,
    };


    return (
        <CustomModal title='Добавить дисциплину' btnTitle='Добавить дисциплину' onOk={() => handleAddDiscipline(selectedRowKeys, requisiteProps.requisiteType, requisiteProps.curriculumId, requisiteProps.handleSuccess, requisitions)}>
            <Table size='large' rowKey={(record) => record.id}
                rowSelection={rowSelection} columns={disciplineColumns} dataSource={curriculums.map((data) => ({
                    id: data.discipline.id,
                    titleRu: data.discipline.titleRu,
                    code: data.discipline.code,
                    volumeCredits: data.discipline.code
                }))} />
        </CustomModal>
    )
}


const handleAddDiscipline = (disciplineIds: number[], requisiteType: 'postreq' | 'prereq', curriculumId: number, onSuccess: () => void, requisitions?: RequisitionDtoResponse[]) => {
    DisciplinesService.saveRequesition(requisiteType,
        requisiteType === 'postreq' ? {
            curriculumId: curriculumId,
            disciplines: disciplineIds,
            postrequisiteType: 1
        } : {
            curriculumId: curriculumId,
            disciplines: disciplineIds,
            prerequisiteType: 1
        }, requisitions && requisitions[0]?.id)
        .then(() => {
            successNotification('Успешно сохранено!');
            onSuccess();
        })
}

const RequisitePage = ({ educationalProgramId, requisiteType, curriculumId }: Props) => {

    const [curriculum, setCurriculum] = React.useState<CurriculumDtoResponse>();
    const [curriculums, setCurriculums] = React.useState<CurriculumDtoResponse[]>([]);
    const [requisitions, setRequisitions] = React.useState<RequisitionDtoResponse[]>([]);
    const [fetchLoading, setFetchLoading] = React.useState<boolean>(false);
    const [refetchRequired, setRefetchRequired] = React.useState(false);

    const toggleRefetch = () => {
        setRefetchRequired(prev => !prev);
    }

    const columns = [
        {
            title: "№",
            dataIndex: "id",
            key: "discipline.id",
        },
        {
            title: "Название",
            dataIndex: "titleRu",
            key: "discipline.titleRu",
        },
        {
            title: "Код дисциплины",
            dataIndex: "code",
            key: "discipline.code",
        },
        {
            title: "Кол-во кредитов",
            dataIndex: "volumeCredits",
            key: "discipline.volumeCredits",
        },
        {
            dataIndex: "action",
            key: "action",
            width: "10%",
            render: (_: any, record: any) => (
                <Space>
                    <Button danger icon={<DeleteOutlined />} onClick={() => handleDeleteDisciplines(record.id, requisiteType, record.requisitionId)}>
                        Удалить
                    </Button>
                </Space>
            )
        }
    ];

    React.useEffect(() => {
        setFetchLoading(true)
        Promise.all([
            CurriculumService.getById(curriculumId),
            DisciplinesService.getCurriculumsForRequisitions(requisiteType, curriculumId)
        ])
            .then(([curriculum, curriculums]) => {
                setCurriculum(curriculum.data)
                setCurriculums(curriculums.data)
            })
            .finally(() => { setFetchLoading(false) })
    }, [curriculumId, requisiteType]);

    React.useEffect(() => {
        DisciplinesService.getRequisitions(requisiteType, curriculumId)
            .then(({ data }) => {
                setRequisitions(data)
            })
    }, [curriculumId, refetchRequired, requisiteType]);

    const handleDeleteDisciplines = (disciplineIds: number[], requisiteType: 'postreq' | 'prereq', id: number) => {
        DisciplinesService.deleteRequisitions(requisiteType, id, disciplineIds)
            .then(() => {
                successNotification('Успешно удалено!');
                toggleRefetch();
            })
    }



    return fetchLoading ? (<Spinner size='large' />) : (
        <Page
            title={requisiteType === 'postreq' ? `Постреквизиты дисциплины ${curriculum?.discipline?.titleRu} - ${curriculum?.discipline?.code}` : `Пререквизиты дисциплины ${curriculum?.discipline?.titleRu} - ${curriculum?.discipline?.code}`}
            subtitle={requisiteType === 'postreq' ?
                'Постреквизиты: дисциплины, содержащие перечень знаний, умений и навыков, необходимых для освоение последующих дисциплины.' :
                'Пререквизиты: дисциплины, содержащие перечень знаний, умений и навыков, необходимых для освоения изучаемой дисциплины.'}>

            <Space direction='vertical' style={{ width: "100%" }}>
                <RowSpan right={<DisciplineModal curriculums={curriculums} requisitions={requisitions} requisiteProps={{ educationalProgramId, curriculumId, requisiteType, handleSuccess: toggleRefetch }} />} />

                <Table size='large' pagination={{ pageSize: 40 }} bordered columns={columns} dataSource={
                    requisitions[0]?.disciplines.map((data) => ({
                        id: data.id,
                        requisitionId: requisitions[0].id,
                        titleRu: data.titleRu,
                        code: data.code,
                        volumeCredits: data.volumeCredits,
                    }))
                } />
            </Space>

        </Page>
    )
}

export default RequisitePage