import { Button, Space, Form, Divider, Col, Row } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import StudentGroupFilter from './StudentGroupFilter';
import StudentsFilter from './StudentsFilter';
import DiplomaWorkFilter from './DiplomaWorkFilter';
import useFinalAttestationProtocol from './useDiplomaWorksCatalogPage';
import Page from '../../../ui/Page/Page';
import { FileService } from '../../../services/file/FileService';
import { DiplomaWorkFilesDtoResponse } from '../../../interfaces/Diploma/response/DiplomaWorkFilesDtoResponse';
import { DiplomaWorkFileTypesDtoResponse } from '../../../interfaces/Diploma/response/DiplomaWorkFileTypesDtoResponse';

interface FileUploadComponentProps {
    workFile?: DiplomaWorkFilesDtoResponse;
    fileTypeInstance: DiplomaWorkFileTypesDtoResponse;
    isRequired: boolean;
}

const DiplomaWorksCatalogPage = () => {
    const {
        groups,
        diplomaWorkFileTypes,
        diplomaWorkFiles,
        students,
        diplomaWorks,
        selectedGroupId,
        selectedUserId,
        selectedDiplomaWorkId,
        formChanged,
        setFormChanged,
        styles,
        onGroupSelect,
        onStudentSelect,
        onDiplomaWorkSelect,
        onFinish,
        form,
        loading,
    } = useFinalAttestationProtocol();

    const FileUploadComponent: React.FC<FileUploadComponentProps> = ({ workFile, fileTypeInstance, isRequired }) => {
        return (
            <div key={workFile ? workFile.id : fileTypeInstance.id}>
                <Row gutter={[16, 16]}> {/* gutter adds spacing between columns */}
                    <Col span={12}> {/* Controls the width of the column */}
                        <h3>
                            {isRequired ? `${fileTypeInstance.nameRu}` : fileTypeInstance.nameRu}
                        </h3>
                    </Col>

                    <Col span={12}>
                        <Space style={{ alignItems: "start" }}>
                            {workFile && workFile?.fileId > 0 ? (
                                <Button
                                    type='primary'
                                    icon={<DownloadOutlined />}
                                    onClick={() => FileService.getFileById(workFile.fileId, workFile.name)}
                                >
                                    Скачать файл
                                </Button>
                            ) : <h4>Файл не загружен</h4>
                            }
                        </Space>
                        <Divider />
                    </Col>
                </Row>
            </div>

        );
    };

    // Required file types block
    const requiredFileTypes = diplomaWorkFileTypes.filter(type => type.isRequired);
    const filledRequiredFiles = diplomaWorkFiles.filter(file => file.type.isRequired);
    const unfilledRequiredTypes = requiredFileTypes.filter(type => !filledRequiredFiles.some(file => file.type.id === type.id));

    // Optional file types block
    const optionalFileTypes = diplomaWorkFileTypes.filter(type => !type.isRequired);
    const filledOptionalFiles = diplomaWorkFiles.filter(file => optionalFileTypes.some(type => type.id === file.type.id));

    return (
        <Page title={'Catalog of Diploma works'} width={800} center>
            <Form
                layout="vertical"
                size="large"
                disabled={loading}
                form={form}
                onFinish={onFinish}
                style={styles}
                onChange={() => setFormChanged(true)}
            >
                <Form.Item name="Group" label="Группа обучающихся">
                    <StudentGroupFilter
                        placeholder="Группа обучающихся"
                        selectedOption={selectedGroupId}
                        onSelect={(value) => onGroupSelect(value)}
                        options={groups}
                    />
                </Form.Item>
                <Form.Item name="Student" label="ФИО Студента">
                    <StudentsFilter
                        placeholder="ФИО Студента"
                        selectedOption={selectedUserId}
                        onSelect={(value) => onStudentSelect(value)}
                        options={students}
                    />
                </Form.Item>
                <Form.Item name="Diploma" label="Дипломная работа">
                    <DiplomaWorkFilter
                        placeholder="Дипломная работа"
                        selectedOption={selectedDiplomaWorkId}
                        onSelect={(value) => onDiplomaWorkSelect(value)}
                        options={diplomaWorks}
                    />
                </Form.Item>
                {selectedDiplomaWorkId && (
                    <div>
                        {[...filledRequiredFiles, ...unfilledRequiredTypes].map((item, index) => {
                            const isFile = 'fileId' in item;
                            const fileType = isFile ? item.type : item;
                            return (
                                <FileUploadComponent
                                    key={index}
                                    workFile={isFile ? item : undefined}
                                    fileTypeInstance={fileType}
                                    isRequired={fileType.isRequired}
                                />
                            );
                        })}

                        {filledOptionalFiles.map((workFile, index) => (
                            <FileUploadComponent
                                key={index}
                                workFile={workFile}
                                fileTypeInstance={workFile.type}
                                isRequired={false}
                            />
                        ))}

                    </div>
                )}
            </Form>

        </Page >
    );
};

export default DiplomaWorksCatalogPage;
