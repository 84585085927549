import { UploadProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Upload, { RcFile } from 'antd/lib/upload';
import { useState, useEffect } from 'react';
import grantPermission from '../../../helpers/grantPermission';
import { IrosApplication } from '../../../interfaces/Iros/IrosApplication';
import { IrosIndicator } from '../../../interfaces/Iros/IrosIndicator';
import { IrosStatus } from '../../../interfaces/Iros/IrosStatus';
import { StudentIrosApplication } from '../../../interfaces/Iros/StudentIrosApplication';
import { FileService } from '../../../services/file/FileService';
import { IrosService } from '../../../services/student/IrosService';
import { successNotification } from '../../../helpers/successNotification';
import { IrosApplicationResult } from '../../../interfaces/Iros/IrosApplicationResult';
import { IrosApplicationProjection } from '../../../interfaces/Iros/IrosApplicationProjection';
import { errorNotification } from '../../../helpers/errorNotification';

interface FilterRow {
  text: string;
  value: string;
}

const useIros = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [modalValue, setModalValue] = useState<any>();
  const [indicators, setIndicators] = useState<IrosIndicator[]>([]);
  const [status, setStatus] = useState<IrosStatus[]>([]);
  const [tableDataForStudent, setTableDataForStudent] = useState<IrosApplication[]>([]);
  const [tableDataForOffice, setTableDataForOffice] = useState<IrosApplicationProjection[]>([]);
  const [irosResults, setIrosResults] = useState<IrosApplicationResult[]>([]);
  const [totalTableDataForOffice, setTotalTableDataForOffice] = useState(0);
  const [currentPage, setCurrentPage] = useState<number | undefined>(0);
  const [pageSize, setPageSize] = useState<number | undefined>(10);

  const [irosFile, setIrosFile] = useState<any>();
  const [filterRows, setFilterRows] = useState<FilterRow[]>([]);

  const [loader, setLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm();
  const [statuses, setStatuses] = useState<IrosStatus[]>([]);

  useEffect(() => {
    setLoading(true);
    IrosService.getIrosIndicatorsByTypeAndIsActive(2)
      .then(response => {
        setIndicators(response.data);
      })
      .catch(error => {
        console.error('Error fetching indicators:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const fetchStatusesByIndicatorId = (indicatorId: number) => {
    setLoading(true);
    IrosService.getIndexStatusByIndicatorsIdAndIsActive(indicatorId)
      .then(response => {
        setStatuses(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching statuses:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setTableDataForOffice([]);
    if (currentPage)
      IrosService.getApplicationsForOffice(currentPage - 1, pageSize)
        .then(({ data }) => {
          setTableDataForOffice(data.list);
          setTotalTableDataForOffice(data.total_number);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [currentPage, pageSize]);

  useEffect(() => {
    setLoading(true);
    if (grantPermission('STUDENTS')) {
      IrosService.getApplicationsForStudent()
        .then(({ data }) => setTableDataForStudent(data))
        .finally(() => {
          setLoading(false);
        });
    } else {
      IrosService.getApplicationsForOffice(currentPage, pageSize)
        .then(({ data }) => {
          setTableDataForOffice(data.list);
          setTotalTableDataForOffice(data.total_number);
        })
        .finally(() => {
          setLoading(false);
        });
      IrosService.getIrosResults()
        .then((response) => {
          setIrosResults(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader]);

  const handleDownloadIrosResults = () => {
    IrosService.getIrosResultsExcel()
      .then(() => {
        successNotification('Данные успешно скачаны!');
      })
  };

  const openModal = (record?: any, state?: boolean) => {
    if (state) {
      setIsOpen(state);
    }
    if (record) {
      setModalValue(record);
      form.resetFields();
    }
  };

  const handleEditModalOpen = (state: boolean, record?: any) => {
    if (state === false) {
      setIsEditOpen(state);
      form.resetFields();
    } else {
      setIsEditOpen(state);
      form.setFieldsValue(record);
    }
  };

  const uploadProps: UploadProps = {
    maxCount: 1,
    beforeUpload: (file: RcFile) => {
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        errorNotification(`${file.name} is not pdf file!`, 'Please, upload only pdf file!');
      }
      return isPDF || Upload.LIST_IGNORE;
    },
    onChange(e) {
      e.file.status = 'done';
    },
    customRequest(info) {
      setIrosFile(info.file);
    },
  };

  const handleCreateStudentApplication = (application: StudentIrosApplication) => {
    let formData = new FormData();
    formData.append('irosFile', irosFile);
    IrosService.uploadFile(formData)
      .then((res) => {
        application.fileId = res.data;
        IrosService.createStudentApplication(application)
          .then(({ data }) => successNotification('Successfully created!'))
          .finally(() => {
            setIsOpen(false);
            form.resetFields();
            setLoader(!loader);
          });
      })
  };

  const handleDownloadFile = (fileId: number) => {
    FileService.getFileById(fileId, 'iROS')
      .then(() => successNotification('Successfully downloaded!'))
  };

  const handleUpdate = (status: boolean | string, id: number, comment?: string) => {
    IrosService.updateStudentApplication({
      approved: status,
      comment: comment,
      id: id,
    })
      .then(() => {
        setLoader(!loader);
        successNotification('Successfully downloaded!');
      })
      .finally(() => {
        setIsRejectModalOpen(false);
        setLoader(!loader);
      });
  };

  const handleRejectModal = (value: any) => {
    setIsRejectModalOpen(true);
    setModalValue(value);
  };

  return {
    data: {
      indicators,
      statuses,
      form,
      isOpen,
      isEditOpen,
      tableDataForStudent,
      tableDataForOffice,
      filterRows,
      loading,
      irosResults,
      totalTableDataForOffice,
      setCurrentPage,
      setPageSize,
      pageSize,
      currentPage,
      lists: {
        indicators,
        status,
      },
      modal: {
        modalValue,
        isRejectModalOpen,
        setIsRejectModalOpen,
      },
    },
    handlers: {
      openModal,
      handleEditModalOpen,
      handleCreateStudentApplication,
      handleDownloadFile,
      handleDownloadIrosResults,
      handleUpdate,
      handleRejectModal, fetchStatusesByIndicatorId,
      setIsOpen
    },
    uploadProps,
  };
};

export default useIros;
