import { TableColumnsType } from 'antd';
import { Table } from 'antd';
import { useState } from 'react';
import { StudentDtoResponse } from '../../../../interfaces/Student/StudentDtoResponse';
import UserService from '../../../../services/userService';
import { StudentGradeService } from '../../../../services/StudentGradeService';


interface DataType {
  key: number | string;
  id: number;
  userId: number;
  fullName: string;
  group: string;


}

interface ExpandedDataType {
  key: number | string;
  id: number;
  fullname: string;
  endterm: number;
  midterm: number;
  total: number;
  finalGrade: number;
}

interface Props {
  tableData: StudentDtoResponse[];
  loading: boolean;
}
export interface GradesTableForOfficeProps {
  trimester: number;
}


const GradeTableForOffice = ({ tableData, loading, trimester }: Props & GradesTableForOfficeProps) => {
  const [expandableData, setExpandableData] = useState<any>([]);
  const [expandedRow, setExpandedRow] = useState<any>([]);
  const [expandableLoading, setExpandableLoading] = useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const keys: any = [];

  const columns: TableColumnsType<DataType> = [
    { title: '№', dataIndex: 'id', key: 'id' },
    { title: 'Full Name', dataIndex: 'fullName', key: 'fullName' },
    { title: 'Group', dataIndex: 'group', key: 'group' },

  ];

  const data: DataType[] = [];

  for (let i = 0; i < tableData.length; ++i) {
    data.push({
      key: i + 1,
      id: i + 1,
      userId: tableData[i].userId,
      fullName: `${tableData[i].nameKz} ${tableData[i].surnameKz} ${tableData[i].patronymicKz}`,
      group: tableData[i].group.title,
    });
  }

  const expandedRowRender = (record: DataType, index: number, indent: any, expanded: boolean) => {
    const columns: TableColumnsType<ExpandedDataType> = [
      { title: '№', dataIndex: 'key', key: 'key' },
      { title: 'Discipline', dataIndex: 'fullname', key: 'fullname' },
      { title: 'Midterm', dataIndex: 'midterm', key: 'midterm' },
      { title: 'Endterm', dataIndex: 'endterm', key: 'endterm' },
      { title: 'Final Gtade', dataIndex: 'finalGrade', key: 'finalGrade' },
      { title: 'Total', dataIndex: 'total', key: 'total' },

    ];

    const data: ExpandedDataType[] = [];
    for (let i = 0; i < expandableData.length; ++i) {
      data.push({
        key: i + 1,
        id: expandableData[i].id,
        fullname: expandableData[i].fullname,
        endterm: expandableData[i].endterm,
        midterm: expandableData[i].midterm,
        total: expandableData[i].total,
        finalGrade: expandableData[i].finalGrade,
      });
    }
    return <Table columns={columns} dataSource={data} pagination={false} bordered={true} loading={expandableLoading} />;
  };
  const handleExpand = (data: DataType, expanded: boolean) => {
    if (expanded) {
      keys.push(data.id);
    }
    setExpandedRow(keys)
    setExpandableLoading(true);
    setExpandedRowKeys(keys);
    UserService.getProfileById(data.userId)
      .then((res) => {
        StudentGradeService.GradesById(res.data.student.id, trimester).then((response) => {
          setExpandableData(response.data);
        })
          .finally(() => setExpandableLoading(false));
      })
      .finally(() => setExpandableLoading(false));
  };

  const onTableRowExpand = (expanded: boolean, record: any) => { };

  return (
    <>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
          onExpand: (expanded, record) => handleExpand(record, expanded),
          expandedRowKeys: expandedRow,
        }}
        expandedRowKeys={expandedRowKeys}
        onExpand={onTableRowExpand}
        dataSource={data}
        bordered={true}
        expandRowByClick={true}
      />
    </>
  );
};

export default GradeTableForOffice;
