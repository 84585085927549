import UniversalTable from '../UniversalTeachersTable';
import { TeacherService } from '../../../../services/TeacherService';
import { successNotification } from '../../../../helpers/successNotification';
import { TeacherICInfoDtoRequest, TeacherICInfoDtoResponse } from '../../../../interfaces/Teacher/EPVO/TeacherEpvoDto';
import useFetchData from '../../../../hooks/useFetchData';
import { useCallback } from 'react';
import grantPermission from '../../../../helpers/grantPermission';
import { Field } from '../../../../interfaces/Field';
import useDictionary from '../../../../hooks/useDictionary';

interface TeacherICTableProps {
    userId?: number;
}

const TeacherICTable = ({ userId }: TeacherICTableProps) => {
    const callbackICInfo = useCallback(() => {
        if (userId) {
            return TeacherService.getTeacherICInfoByUser(userId);
        } else {
            return TeacherService.getTeacherICInfo();
        }
    }, [userId])

    const { data: ic, fetchData: fetchIC } = useFetchData<TeacherICInfoDtoResponse[]>(callbackICInfo, true);
    const { dictionary } = useDictionary({})

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Number',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Issue date',
            dataIndex: 'issueDate',
            key: 'issueDate',
        },
        {
            title: 'Expiration date',
            dataIndex: 'validityPeriod',
            key: 'validityPeriod',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Issuing authority',
            dataIndex: 'issuingAuthority',
            key: 'issuingAuthority',
        }
    ];

    const addRecord = (record: TeacherICInfoDtoRequest) => {
        if (userId) {
            record.userId = userId;
        }
        TeacherService.createOrUpdateTeacherICInfo(record)
            .then(() => {
                fetchIC();
                successNotification('IC added!');
            });
    };

    const editRecord = (record: TeacherICInfoDtoRequest) => {
        if (userId) {
            record.userId = userId;
        }
        TeacherService.createOrUpdateTeacherICInfo(record)
            .then(() => {
                fetchIC();
                successNotification('IC updated!');
            });
    };

    const deleteRecord = (record: TeacherICInfoDtoRequest) => {
        // TeacherService.deleteTeacherICInfo(record.id)
        //     .then(() => {
        //         fetchIC();
        //         successNotification('IC deleted!');
        //     });
    };

    const fields: Field[] = [
        {
            name: 'icDepartmentId',
            label: 'Department',
            placeholder: 'Select department',
            type: 'select',
            options: dictionary?.icDepartments?.map((type) => {
                return { value: type.id, label: type.name }
            })
        },
        {
            name: 'icTypeId',
            label: 'Type',
            type: 'select',
            placeholder: 'Select type',
            options: dictionary?.icTypes?.map((type) => {
                return { value: type.id, label: type.name }
            })
        },
        { name: 'issueDate', label: 'Contract start date', type: 'date' },
        { name: 'validityPeriod', label: 'Contract finish date', type: 'date' },
        { name: 'number', label: 'Number', type: 'input', placeholder: 'Input document number' },
    ]

    return (
        <UniversalTable
            columns={columns}
            dataSource={ic ? ic.map((ic, i) => ({
                id: ic.teacher.id,
                key: i + 1,
                number: ic.number,
                issueDate: ic.issueDate,
                validityPeriod: ic.validityPeriod,
                type: ic.icType?.name,
                issuingAuthority: ic.icDepartment?.name,
                icTypeId: ic.icType?.id,
                icDepartmentId: ic.icDepartment?.id,
            })) : []}
            addRecord={(e: TeacherICInfoDtoRequest) => addRecord(e)}
            editRecord={(e: TeacherICInfoDtoRequest) => editRecord(e)}
            deleteRecord={(e: TeacherICInfoDtoRequest) => deleteRecord(e)}
            fields={fields}
            hideDelete={grantPermission('HR') ? false : true}
            hideEdit={grantPermission('HR') ? false : true}
            hideCreate={grantPermission('HR') ? false : true}
            hideActions={grantPermission('HR') ? false : true}
            isPublic={false}
        />
    )
}

export default TeacherICTable