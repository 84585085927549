import { Select } from 'antd';

const { Option } = Select;

interface Props {
  placeholder: string;
  selectedOption: number | undefined;
  onSelect: (value: number) => void;
  // TODO: Поменять тип
  options: any;
  onClear: () => void;
  width?: number | string;
  loading: boolean;
  componentSize?: 'large' | 'middle' | 'small';
}

const DiplomaWorkFilter = ({
  placeholder,
  selectedOption,
  onSelect,
  componentSize,
  width,
  options,
  loading,
  onClear,
}: Props) => {
  return (
    <Select
      showSearch
      size={componentSize}
      loading={loading}
      disabled={loading}
      placeholder={placeholder}
      value={selectedOption}
      onSelect={onSelect}
      style={{ width: width ? width : '100%' }}
      allowClear
      onClear={onClear}
    >
      {options?.map((diplomaWork: any) => (
        <Option key={diplomaWork.id} value={diplomaWork.id}>
          {diplomaWork.titleRu}
        </Option>
      )) ?? []}
    </Select>
  );
};

export default DiplomaWorkFilter;
