import authAxios from '../../common/authAxios';
import { downloadFile } from '../../helpers/downloadFile';
import { AituFileDtoResponse } from '../../interfaces/AituFileDtoResponse';

export class FileService {
  private static baseUrlFileUploader = '/astanait-file-uploader-module/api/v1/file';

  static getFileById(id: number, name: string) {
    return downloadFile(`${this.baseUrlFileUploader}/data/${id}`, 'GET', 'blob', name.toString());
  }
  static getApplicationFileTestById(id: number, name: string) {
    return downloadFile(`astanait-contingent-module/api/v1/applications/application-file-test?application_id=${id}`, 'GET', 'blob', name.toString());
  }

  static getFileBlobById(id: number, name: string) {
    return authAxios.get(`${this.baseUrlFileUploader}/data/${id}`, { responseType: 'blob' });
  }


  static getFileObjectById(id: number) {
    return authAxios.get<AituFileDtoResponse>(`${this.baseUrlFileUploader}/by-id?id=${id}`);
  }

  static getStudentImage(id: number) {
    return authAxios.get(`${this.baseUrlFileUploader}/data/${id}`, { responseType: 'blob' });
  }

  static deleteFile(id: number) {
    return authAxios.delete(`${this.baseUrlFileUploader}/delete/${id}`);
  }

  static uploadFile(FormData: FormData, fileType: number, id?: number) {
    if (id) {
      return authAxios.post<AituFileDtoResponse>(
        `${this.baseUrlFileUploader}/upload?id=${id}&typeId=${fileType}`,
        FormData
      );
    } else {
      return authAxios.post<AituFileDtoResponse>(`${this.baseUrlFileUploader}/upload?typeId=${fileType}`, FormData);
    }
  }
}
