import authAxios from '../../common/authAxios';
import { SsciStudentApplicationsDto } from '../../interfaces/Ssci/Applications/SsciStudentApplicationsDto';
import { SsciStudentApplicationsWithPointsDto } from '../../interfaces/Ssci/Applications/SsciStudentApplicationsWithPointsDto';
import { SsciApplicationProjection } from '../../interfaces/Ssci/SsciApplicationProjection';
import { SsciCategoriesDtoResponse } from '../../interfaces/Ssci/SsciCategoriesDtoResponse';
import { SsciEventsDtoRequest } from '../../interfaces/Ssci/SsciEventsDtoRequest';
import { SsciEventsDtoResponse } from '../../interfaces/Ssci/SsciEventsDtoResponse';
import { SsciIndicatorsDtoResponse } from '../../interfaces/Ssci/SsciIndicatorsDtoResponse';
import { SsciStudentEventDtoRequest } from '../../interfaces/Ssci/SsciStudentEventDtoRequest';
import { SsciStudentEventDtoResponse } from '../../interfaces/Ssci/SsciStudentEventDtoResponse';
import { SsciStudentEventPointDtoRequest } from '../../interfaces/Ssci/SsciStudentEventPointDtoRequest';
import { SsciStudentEventPointsDtoResponse } from '../../interfaces/Ssci/SsciStudentEventPointsDtoResponse';

export class SsciService {
  private static baseUrlSSCI = '/astanait-office-module/api/v1/ssci';

  static getGpi(studentId: number) {
    return authAxios.get<number>(`${this.baseUrlSSCI}/get-ssci-gpa?studentId=${studentId}`);
  }

  static getCategories() {
    return authAxios.get<SsciCategoriesDtoResponse[]>(`${this.baseUrlSSCI}/categories`);
  }

  static getEvents() {
    return authAxios.get<SsciEventsDtoResponse[]>(`${this.baseUrlSSCI}/get-ssci-events`);
  }

  static getEventById(id: number) {
    return authAxios.get<SsciEventsDtoResponse>(`${this.baseUrlSSCI}/get-ssci-event-by-id?id=${id}`);
  }

  static createOrUpdateEvent(ssciEventsDtoRequest: SsciEventsDtoRequest, id?: number) {
    if (id) {
      return authAxios.post(`${this.baseUrlSSCI}/create-or-update-ssci-event?id=${id}`, { ...ssciEventsDtoRequest });
    } else {
      return authAxios.post(`${this.baseUrlSSCI}/create-or-update-ssci-event`, { ...ssciEventsDtoRequest });
    }
  }

  static deleteEvent(id: number) {
    return authAxios.delete(`${this.baseUrlSSCI}/delete-ssci-event?id=${id}`);
  }

  // student events
  static getStudentEventsByPrincipal() {
    return authAxios.get<SsciStudentApplicationsDto[]>(
      `${this.baseUrlSSCI}/get-ssci-student-applications-by-principal`
    );
  }

  static getStudentEventById(id: number) {
    return authAxios.get<SsciStudentEventDtoResponse>(`${this.baseUrlSSCI}/get-ssci-student-by-id?id=${id}`);
  }

  static getStudentEventByEventId(id: number) {
    return authAxios.get<SsciStudentEventDtoResponse[]>(
      `${this.baseUrlSSCI}/get-ssci-students-by-event-id?eventId=${id}`
    );
  }

  static createStudentApplication(formData: FormData) {
    return authAxios.post(`${this.baseUrlSSCI}/create-ssci-student-applications`, formData);
  }

  static updateStudentApplication(formData: FormData, applicationId: number) {
    return authAxios.post(
      `${this.baseUrlSSCI}/update-ssci-student-applications?applicationId=${applicationId}`,
      formData
    );
  }

  static getStudentApplicationsByStudentId(studentId: number) {
    return authAxios.get<SsciStudentApplicationsDto[]>(
      `${this.baseUrlSSCI}/get-approved-ssci-student-applications-by-student-id?studentId=${studentId}`
    );
  }

  static getStudentApplicationById(id: number) {
    return authAxios.get<SsciStudentApplicationsWithPointsDto>(
      `${this.baseUrlSSCI}/get-ssci-student-applications-by-id?applicationId=${id}`
    );
  }

  static uploadStudentEventFile(FormData: FormData, id?: number) {
    if (id) {
      return authAxios.post<number>(`${this.baseUrlSSCI}/upload-file-ssci-student-applications?id=${id}`, FormData);
    } else {
      return authAxios.post<number>(`${this.baseUrlSSCI}/upload-file-ssci-student-applications`, FormData);
    }
  }

  static createAndUpdateStudentEvent(dto: SsciStudentEventDtoRequest, eventId?: number, id?: number) {
    if (eventId) {
      if (id) {
        return authAxios.post(`${this.baseUrlSSCI}/create-or-update-student-event?eventId=${eventId}&id=${id}`, dto);
      }
      return authAxios.post(`${this.baseUrlSSCI}/create-or-update-student-event?eventId=${eventId}`, dto);
    } else {
      return authAxios.post(`${this.baseUrlSSCI}/create-or-update-student-event`, dto);
    }
  }

  static deleteStudentEvent(eventId: number, studentId: number) {
    return authAxios.delete(`${this.baseUrlSSCI}/delete-student-event?eventId=${eventId}&studentId=${studentId}`);
  }

  static getEventsByFilter(params: any) {
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }

    return authAxios.get(`${this.baseUrlSSCI}/get-ssci-events-by-filter`, { params });
  }

  //ssci indicators
  static getIndicators(categoryId: number) {
    return authAxios.get<SsciIndicatorsDtoResponse[]>(
      `${this.baseUrlSSCI}/get-by-category-ssci-indicators?categoryId=${categoryId}`
    );
  }

  //ssci student event points
  static getStudentEventPoints(eventId: number) {
    return authAxios.get<SsciStudentEventPointsDtoResponse[]>(
      `${this.baseUrlSSCI}/get-ssci-student-event-points-by-event?id=${eventId}`
    );
  }

  static createOrUpdateStudentEventPoint(dto: SsciStudentEventPointDtoRequest, id?: number) {
    if (id) {
      return authAxios.post(`${this.baseUrlSSCI}/create-or-update-student-event-point?id=${id}`, dto);
    } else {
      return authAxios.post(`${this.baseUrlSSCI}/create-or-update-student-event-point`, dto);
    }
  }

  //ssci applications
  static getAllApplications(page: any, pagesize: any) {
    return authAxios.get<{ list: SsciApplicationProjection[]; total_number: number }>(
      `${this.baseUrlSSCI}/get-ssci-student-applications?page=${page}&pageSize=${pagesize}`
    );
  }

  static getApplicationsByFilter(page: any, pageSize: any, params: any) {
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }
    return authAxios.get<{ list: SsciApplicationProjection[]; total_number: number }>(
      `${this.baseUrlSSCI}/get-all-ssci-student-applications?page=${page}&pageSize=${pageSize}`, { params }
    );
  }

  static approveApplication(dto: any) {
    for (const key of Object.keys(dto)) {
      if (!dto[key]) {
        delete dto[key];
      }
    }

    return authAxios.put<SsciStudentApplicationsDto>(`${this.baseUrlSSCI}/approve-ssci-student-applications`, {
      ...dto,
    });
  }

  static setNullapproveApplication(applicationId: any) {
    return authAxios.post<SsciStudentApplicationsDto>(
      `${this.baseUrlSSCI}/update-approve-ssci-student-applications?applicationId=${applicationId}`
    );
  }
}
