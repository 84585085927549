import { Button, Checkbox, Form, FormInstance, Select, Upload, UploadProps, DatePicker, TreeSelect, Input, Typography, Space } from 'antd'
import React, { useState } from 'react';
import { ApplicationInfoTypesDtoResponse } from '../../../interfaces/Applications/response/ApplicationInfoTypesDtoResponse';
import { RcFile } from 'antd/lib/upload';
import { errorNotification } from '../../../helpers/errorNotification';
import { EducationalProgramsService } from '../../../services/EducationalProgramsService';
import { EducationProgramDtoResponse } from '../../../interfaces/EducationalPrograms/EducationProgramDtoResponse';
import { AbiturCatalogDtoResponse } from '../../../interfaces/AbiturCatalogDtoResponse';
import { ApplicationService } from '../../../services/ApplicationService';
import { StudentGroupsDtoResponse } from '../../../interfaces/Student/StudentGroupsDtoResponse';
import { DownloadOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ApplicationDiscountTypeDtoResponse } from '../../../interfaces/Applications/response/ApplicationDiscountTypeDtoResponse';
import { StudyDiscountService } from '../../../services/StudyDiscountService';
import { ApplicationDiscountTypeItemDtoResponse } from '../../../interfaces/Applications/response/ApplicationDiscountTypeItemDtoResponse';
import { CurriculumService } from '../../../services/CurriculumService';
import { StudentDisciplineFinalChooseDtoResponse } from '../../../interfaces/Student/StudentDisciplineFinalChooseDtoResponse';
import { OrdersService } from '../../../services/OrdersService';
import { OrderCategoryDtoResponse } from '../../../interfaces/Order/OrderCategoryDtoResponse';
import { PrivilegeDtoResponse } from '../../../interfaces/Student/PrivilegeDtoResponse';


interface DataTypeComponentProps {
    type: ApplicationInfoTypesDtoResponse;
    form: FormInstance<any>;
    langId: number;
    setFileLabel?: React.Dispatch<React.SetStateAction<string>>;
    fileLabel?: string;
}

const DataTypeComponent = ({ type, form, langId, fileLabel, setFileLabel }: DataTypeComponentProps) => {
    const PUBLIC_URL = process.env.PUBLIC_URL;
    const [fileList, setFileList] = React.useState<any>([]);
    // const [errorMessage, setErrorMessage] = useState('');
    // const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const props: UploadProps = {
        accept: ".pdf",
        fileList: fileList,
        beforeUpload: (file: RcFile) => {
            const isPDF = file.type === 'application/pdf';
            const reader = new FileReader();

            if (!isPDF) {
                errorNotification(`${file.name} is not a pdf file!`, 'Please, upload only pdf files!');
                return Upload.LIST_IGNORE; // Ignore this file
            }

            if (file) {
                reader.readAsDataURL(file);
            }
            reader.onload = (e) => {
                const base64String = (reader.result as string).split(',')[1];
                form.setFieldsValue({ [type.id]: base64String })
            };

            setFileList([file]);

            return false;
        },
        onRemove: () => {
            setFileList([]);
            form.setFieldsValue({ [type.id]: "" })
        },
    };

    const [selectData, setSelectData] = React.useState<Record<string, AbiturCatalogDtoResponse[]>>({});
    const [discountItems, setFiscountItems] = useState<ApplicationDiscountTypeItemDtoResponse[]>([]);

    const onTypeSelect = (typeId: number) => {
        StudyDiscountService.getApplicatinDiscountTypeItems(typeId)
            .then((response) => {
                const mappedItems = response.data.map((item) => ({
                    id: item.id,
                    name: item.name,
                    typeId: item.typeId,
                    nameEn: item.nameEn,
                    nameRu: item.nameRu,
                    paragraph: item.paragraph,
                    subparagraph: item.subparagraph,
                    percent: item.percent,
                    minGrade: item.minGrade,
                    confirmingFileInfo: item.confirmingFileInfo
                }));
                setFiscountItems(mappedItems);
            })
    };

    const processData = (data: any, parentValue = '') => {
        return data.map((item: { value: any; title: any; children: any; }) => {
            const newValue = parentValue ? `${parentValue}-${item.value}` : item.value;
            if (item.children) {
                return {
                    title: item.title,
                    value: newValue, // Not directly selectable, used for nesting only
                    selectable: false, // Disable selection for parent nodes
                    children: processData(item.children, newValue)
                };
            }
            return { title: item.title, value: item.value, selectable: true };
        });
    };
    const disableWeekends = (current: any) => {
        // Disable Saturdays (6) and Sundays (0)
        return current && (current.day() === 0 || current.day() === 6);
    };

    React.useEffect(() => {
        if (type.dataType === 'object') {
            switch (type.applicationType.id) {
                case 1: {
                    return setSelectData({});
                }
                case 2: {
                    if (type.prefix === "order_category") {
                        OrdersService.getOrderCategories(type.applicationType.orderType.id)
                            .then(({ data }) => {
                                const newData = data.map((item: OrderCategoryDtoResponse) => (
                                    {
                                        id: item.id,
                                        name: langId === 3 ? item.nameEn : langId === 1 ? item.nameRu : item.name
                                    }
                                ));
                                setSelectData(prevData => ({ ...prevData, [type.id]: newData }))
                            });
                        // handleOrderCategories();

                    }
                    if (type.prefix === "retire_reason") {
                        // handleReasons();
                        const newData = [
                            { id: '1', title: 'жүктілік және босану / туберкулез', titleEn: 'pregnancy and childbirth / tuberculosis', titleRu: 'беременность и роды / туберкулез' },
                            { id: '2', title: 'бала асырап алу / басқа медициналық көрсеткіштер', titleEn: 'child adoption / other medical indications', titleRu: 'усыновление/удочерение ребенка / другие медицинские показания' },
                            { id: '3', title: 'әскери қызметке шақыруға байланысты', titleEn: 'in connection with conscription for military service', titleRu: 'в связи с призывом на воинскую службу' }
                        ].map((item) => ({
                            id: item.id,
                            name: langId === 3 ? item.titleEn : langId === 1 ? item.titleRu : item.title
                        }));

                        setSelectData(prevData => ({ ...prevData, [type.id]: newData }))
                    }

                    break;

                    // return setSelectData({});
                }
                case 3: {
                    if (type.prefix === "education_program") {
                        EducationalProgramsService.getEducationalProgramsForApplication(true)
                            .then(({ data }) => {
                                const newData = data.map((item: EducationProgramDtoResponse) => ({
                                    id: item.id,
                                    name: item.code + ' ' + (langId === 3 ? item.titleEn : langId === 1 ? item.titleRu : item.titleKz)
                                }));

                                setSelectData(prevData => ({ ...prevData, [type.id]: newData }))
                            });
                    }
                    break;
                }
                case 4: {
                    return setSelectData({});
                }
                case 5: {
                    return setSelectData({});
                }
                case 6: {
                    return setSelectData({});
                }
                case 7: {
                    return setSelectData({});
                }
                case 9: {
                    if (type.prefix === "academic_year") {
                        const newData = [{ title: 2023 }, { title: 2024 }].map((item: any) => ({
                            id: item.title,
                            name: item.title
                        }));
                        setSelectData(prevData => ({ ...prevData, [type.id]: newData }))
                    }
                    if (type.prefix === "trim") {
                        const newData = [{ title: 1 }, { title: 2 }, { title: 3 }].map((item: any) => ({
                            id: item.title,
                            name: item.title
                        }));
                        setSelectData(prevData => ({ ...prevData, [type.id]: newData }))
                    }
                    if (type.prefix === "study_discount_type_item") {
                        StudyDiscountService.getApplicatinDiscountTypes()
                            .then(({ data }) => {
                                const newData = data.map((item: ApplicationDiscountTypeDtoResponse) => ({
                                    id: item.id,
                                    name: langId === 3 ? item.nameEn : langId === 1 ? item.nameRu : item.name
                                }));

                                setSelectData(prevData => ({ ...prevData, [type.id]: newData }))
                            });
                    }
                    break;

                }
                case 10: {
                    if (type.prefix === "student_group") {
                        ApplicationService.getStudentGroupsForApplication()
                            .then(({ data }) => {
                                const newData = data.map((item: StudentGroupsDtoResponse) => ({
                                    id: item.id,
                                    name: item.title
                                }));

                                setSelectData(prevData => ({ ...prevData, [type.id]: newData }))
                            });
                    }
                    break;
                }
                case 11: {
                    if (type.prefix === "education_program") {
                        EducationalProgramsService.getEducationalProgramsForApplication(false)
                            .then(({ data }) => {
                                const newData = data.map((item: EducationProgramDtoResponse) => ({
                                    id: item.id,
                                    name: item.code + ' ' + (langId === 3 ? item.titleEn : langId === 1 ? item.titleRu : item.titleKz),
                                }));

                                setSelectData(prevData => ({ ...prevData, [type.id]: newData }))
                            });

                    }
                    break;
                }
                case 12: {
                    if (type.prefix === "basis") {
                        const newData = [
                            {
                                "title": "Формальное обучение | Ресми оқыту | Formal education",
                                "value": "Формальное обучение",
                                "children": [
                                    {
                                        "title": "Транскрипт | Transcript",
                                        "value": "Транскрипт"
                                    },
                                    {
                                        "title": "Диплом | Diploma",
                                        "value": "Диплом"
                                    },
                                    {
                                        "title": "Аттестат | School Certificate",
                                        "value": "Аттестат",
                                        "children": [
                                            {
                                                "title": "БИЛ | BIL",
                                                "value": "БИЛ"
                                            },
                                            {
                                                "title": "НИШ | NIS",
                                                "value": "НИШ"
                                            },
                                            {
                                                "title": "РФМШ",
                                                "value": "РФМШ"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "title": "Неформальное обучение | Бейресми оқыту | Informal education",
                                "value": "Неформальное обучение",
                                "children": [
                                    {
                                        "title": "IELTS, TOEFL",
                                        "value": "IELTS, TOEFL"
                                    },
                                    {
                                        "title": "Удостоверение КМС | Спорт шеберіне үміткер куәлігі | Certificate of the candidate for the Master of sports",
                                        "value": "Удостоверение КМС"
                                    },
                                    {
                                        "title": "Сертификаты | Сертификаттар | Certificates",
                                        "value": "Сертификаты",
                                        "children": [
                                            {
                                                "title": "Coursera",
                                                "value": "Coursera"
                                            },
                                            {
                                                "title": "Академия ШАГ | IT Step Academy",
                                                "value": "Академия ШАГ"
                                            },
                                            {
                                                "title": "Tech Orda",
                                                "value": "Tech Orda"
                                            },
                                            {
                                                "title": "Другое | Басқа | Other",
                                                "value": "Другое"
                                            }
                                        ]
                                    },
                                    {
                                        "title": "AITU Project Challenge",
                                        "value": "AITU Project Challenge"
                                    },
                                    {
                                        "title": "AITU Hackathon",
                                        "value": "AITU Hackathon"
                                    },
                                    {
                                        "title": "ACM - ICPC",
                                        "value": "ACM - ICPC"
                                    },
                                    {
                                        "title": "Волонтеры (приемная комиссия) | Еріктілер (қабылдау комиссиясы) | Volunteers (admission committee)",
                                        "value": "Волонтеры (приемная комиссия)"
                                    }
                                ]
                            }
                        ];
                        setSelectData(prevData => ({ ...prevData, [type.id]: processData(newData) }))
                    }
                    if (type.prefix === "discipline_id") {
                        CurriculumService.getStudentCurrentCurriculumForStudent()
                            .then(({ data }) => {
                                const newData = data.map((item: StudentDisciplineFinalChooseDtoResponse) => ({
                                    id: item.curriculum.discipline.id,
                                    name: item.curriculum.discipline.titleEn,
                                    term: item.curriculum.numberOfTrimester
                                }));
                                setSelectData(prevData => ({ ...prevData, [type.id]: newData }))
                            });
                    }
                    break;
                }
                case 14: {
                    if (type.prefix === "dorm_privilege") {
                        ApplicationService.getPrivilege()
                            .then(({ data }) => {
                                const newData = data.map((item: PrivilegeDtoResponse) => ({
                                    id: item.id,
                                    name: (langId === 3 ? item.nameEn : langId === 1 ? item.nameRu : item.name)
                                }));

                                setSelectData(prevData => ({ ...prevData, [type.id]: newData }))
                            });

                    }
                    break;
                }
                default: {
                    return setSelectData({});
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);
    const labelName = langId === 3 ? type.nameEn : langId === 1 ? type.nameRu : type.name;

    switch (type.dataType) {
        case "bool":
            return (
                <Form.Item
                    label={labelName}
                    name={type.id}
                    valuePropName="checked"
                    initialValue={false}>
                    <Checkbox />
                </Form.Item>
            )
        case "object":
            switch (type.prefix) {
                case "basis":
                    const transformedData = processData(selectData[type.id] || []);
                    return (
                        <>
                            <Form.Item
                                label={labelName}
                                name={21}
                                rules={[{ required: true, message: 'Please select!' }]}
                            >
                                <TreeSelect
                                    treeData={transformedData}
                                    treeDefaultExpandAll
                                    treeCheckable={false}
                                />
                            </Form.Item>
                        </>
                    )
                default:
                    return (
                        <>
                            <Form.Item
                                label={labelName}
                                name={type.id}
                                rules={[{ required: true, message: 'Please select!' }]}
                                getValueFromEvent={(selectedOption) => {
                                    const selectedItem = selectData[type.id].find((item: any) => item.id === selectedOption);
                                    return selectedItem ? JSON.stringify({ id: selectedItem.id, name: selectedItem.name, term: selectedItem.term }) : '';
                                }}
                                getValueProps={(i) => i ? { value: JSON.parse(i).term ? `${JSON.parse(i).name} - (${JSON.parse(i).term} trimester)` : JSON.parse(i).name } : { value: '' }}
                            >
                                <Select onSelect={(value) => onTypeSelect(value)}>
                                    {selectData[type.id]?.map((data: any, i: number) => (
                                        <Select.Option key={i} value={data.id}>
                                            {data.name || data} {data.term ? `(${data.term} trimester)` : ''}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {type.id === 12 && <Form.Item
                                label={labelName}
                                name={13}
                                rules={[{ required: true, message: 'Please select!' }]}
                                getValueFromEvent={(selectedOption) => {
                                    const selectedItem = discountItems.find((item: any) => item.id === selectedOption);
                                    return selectedItem ? JSON.stringify({
                                        id: selectedItem.id,
                                        name: selectedItem.name,
                                        paragraph: selectedItem.paragraph,
                                        subparagraph: selectedItem.subparagraph
                                    }) : '';
                                }}
                                getValueProps={(i) => i ? { value: JSON.parse(i).name } : { value: '' }}
                            >

                                <Select onSelect={e => setFileLabel && setFileLabel(discountItems.find(item => item.id === e)?.confirmingFileInfo || '')}>
                                    {discountItems?.map((data: any, i: number) => (
                                        <Select.Option key={i} value={data.id}>
                                            {data.name || data}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>}
                            { }
                        </>
                    );
            }
        case "string":
            return (
                <Form.Item
                    label={labelName}
                    name={type.id}
                    rules={[{ required: true, message: 'Please input!' }]}
                >
                    <Input placeholder="" />
                </Form.Item>
            )
        case "file":
            return (
                <>
                    <Form.Item
                        label={
                            fileLabel ?
                                <Space style={{ width: "100%", gap: "50px" }} direction='horizontal'>
                                    <div>
                                        {fileLabel.split('\r\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        {"(все сканы документов должны быть в одном файле в формате pdf)"}
                                    </div>
                                </Space>
                                : labelName
                        }
                        name={type.id}
                        style={{ width: "100%" }}
                        rules={[{ required: true, message: 'Please upload a file!' }]}>
                        <Upload {...props}>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload >
                    </Form.Item>
                    {type.id === 1 && <Form.Item>
                        <Button type="primary" icon={<DownloadOutlined />} href={`${PUBLIC_URL}/assets/Receipt from the parent kazakh.docx`} style={{ marginRight: 20 }}>
                            Скачать шаблон расписки на казахском
                        </Button>

                        <Button type="primary" icon={<DownloadOutlined />} href={`${PUBLIC_URL}/assets/Receipt from the parent russian.docx`}>
                            Скачать шаблон расписки на русском
                        </Button>
                    </Form.Item>}
                </>
            )
        case "date":
            return (
                <>
                    <Form.Item
                        label={labelName}
                        name={type.id}
                        getValueProps={(i) => i ? { value: moment(i) } : { value: '' }}
                        getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                        rules={[{ required: true, message: 'Please input!' }]}
                    >
                        <DatePicker placeholder="Выберите дату" allowClear={false} disabledDate={disableWeekends} />
                    </Form.Item>
                </>
            )
        case "string_list":
            return (
                <Form.List
                    name={type.id}
                    rules={[
                        {
                            validator: async (_, fields) => {
                                if (!fields || fields.length === 0) {
                                    // setErrorMessage('Необходимо добавить хотя бы одно значение');
                                    // setIsErrorModalOpen(true);
                                    return Promise.reject(new Error('Please add at least one value'));
                                }
                            },
                        },

                    ]}

                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    label={index === 0 ? type.name : ''}
                                    required={true}
                                    key={field.key}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input a value.",
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input placeholder="Enter text" style={{ width: '60%' }} />
                                    </Form.Item>
                                    {index > 0 ? (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                    ) : null}
                                </Form.Item>
                            ))}
                            <Form.Item
                                name={type.id}
                                hidden

                            >
                                <Input />
                            </Form.Item>
                            <Form.Item >
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '60%' }}
                                    icon={<PlusOutlined />}
                                >
                                    Добавить описание подтверждающих документов
                                </Button>
                                {errors && <div style={{ color: 'red' }}>{errors}</div>}
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                // <Form.Item
                //     label={labelName}
                //     name={type.id}
                //     rules={[{ required: true, message: 'Please input!' }]}
                // >
                //     <Input placeholder="Enter text" />
                // </Form.Item>
            )
        default:
            return null
    }

}

export default DataTypeComponent;