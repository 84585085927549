import authAxios from "../../common/authAxios";
import {IllPersonDtoResponse} from "../../interfaces/deansOffice/IllPersonDtoResponse";

export class IllPersonService {
    private static endpointPrefix: string = "/astanait-deans-module/api/v1/ill-person";

    static getCurrentStatus(studentId: number) {
        return authAxios.get(`${this.endpointPrefix}/get-current-status?student-id=${studentId}`);
    }

    static notifyAboutIllness(){
        return authAxios.post(`${this.endpointPrefix}/notify-about-illness`);
    }

    static notifyAboutRecovery(){
        return authAxios.post(`${this.endpointPrefix}/notify-about-recovery`);
    }

    static getAll() {
        return authAxios.get<IllPersonDtoResponse[]>(`${this.endpointPrefix}/all`);
    }
}