import authAxios from '../common/authAxios';
import { AppointmentDatesDtoResponse } from '../interfaces/AppointmentDatesDtoResponse';
import { AppointmentDto } from '../interfaces/AppointmentDto';
import { UserDtoResponse } from '../interfaces/UserDtoResponse';

export class PsychologistService {
  static getAllAppointments(psychologistId: number) {
    return authAxios.get<AppointmentDatesDtoResponse[]>(`/astanait-office-module/api/v1/psychologist/appointment/week`, {
      params: {
        psychologist_id: psychologistId
      }
    });
  }

  static getPsychologists() {
    return authAxios.get<UserDtoResponse[]>(`/astanait-office-module/api/v1/psychologist/appointment/psychologist`);
  }

  static createAppointment(appointmentDto: AppointmentDto) {
    return authAxios.post(`/astanait-office-module/api/v1/psychologist/appointment/make-appointment`, appointmentDto, {
      headers: {
        showNotification: false
      }
    });
  }

  static deleteAppointment(id: any) {
    return authAxios.delete(`/astanait-office-module/api/v1/psychologist/appointment/delete-appointment/${id}`);
  }

  static addAppointment(appointmentDto: AppointmentDto) {
    return authAxios.post(`/astanait-office-module/api/v1/psychologist/appointment/make-appointment-by-psychologist`, appointmentDto);
  }

  static getAppointment() {
    return authAxios.get(`/astanait-office-module/api/v1/psychologist/appointment/psychologist/appointments`);
  }

  static getStudentAppointments() {
    return authAxios.get(`/astanait-office-module/api/v1/psychologist/appointment`);
  }

  static MakeDateUnavailable(date: any) {
    return authAxios.post(`/astanait-office-module/api/v1/psychologist/appointment/make-appointment-date-unavailable?date_time=${date}`);
  }

  static MakeDateAvailable(date: any) {
    return authAxios.delete(`/astanait-office-module/api/v1/psychologist/appointment/make-appointment-date-available?date_time=${date}`);
  }

  static updateAppointment(id: any, status_id: any, comment: any) {
    return authAxios.put(`/astanait-office-module/api/v1/psychologist/appointment/update-appointment?appointment_id=${id}&appointment_status_id=${status_id}&comment=${comment}`);
  }
}