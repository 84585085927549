import styles from './style.module.css';

import React from 'react';
import { Button, InputRef, Select, Space, Table, Typography } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { CurriculumDtoResponse } from '../../../../interfaces/EducationalPrograms/CurriculumDtoResponse';
import { getColumnSearchProps } from '../../../../common/getColumnSearchProps';
import BackButton from '../../../../ui/BackButton';
import { EpvoService } from '../../../../services/EpvoService';
import { successNotification } from '../../../../helpers/successNotification';

const { Title } = Typography;
const { Option } = Select;

interface Props {
  onDeleteCurriculum: (curriculumId: number) => void;
  selectedCurriculums: CurriculumDtoResponse[] | undefined;
}

const CurriculumCatalogWindow = ({ onDeleteCurriculum, selectedCurriculums }: Props) => {
  const [selectedYear, setSelectedYear] = React.useState<number | undefined>(undefined);
  const [selectedTrimester, setSelectedTrimester] = React.useState<number | undefined>(undefined);
  const [filteredCurriculums, setFilteredCurriculums] = React.useState<CurriculumDtoResponse[] | undefined>(undefined);
  const [educProgramName, setEducProgramName] = React.useState('');
  const inputRef = React.useRef() as React.Ref<InputRef>;
  const [loadin, setLoadin] = React.useState(false);

  const handleSync = (id: number) => {
    setLoadin(true)
    EpvoService.syncWithEPVO(id)
      .then((res) => successNotification('Дисциплина была успешно синхронизирована с EPVO'))
      .finally(() => setLoadin(false))
  }

  const getFilteredCurriculums = React.useCallback(() => {
    const curriculums: { [key: number]: CurriculumDtoResponse[] } = {};
    const newCurriculums: CurriculumDtoResponse[] = [];

    if (filteredCurriculums) {
      filteredCurriculums.forEach((selectedCurriculum) => {
        if (!curriculums[selectedCurriculum.groupedDisciplines]) {
          curriculums[selectedCurriculum.groupedDisciplines] = [];
        }

        curriculums[selectedCurriculum.groupedDisciplines].push(selectedCurriculum);
      });

      Object.values(curriculums).forEach((curriculum) => newCurriculums.push(...curriculum));
      return newCurriculums.map((curriculum) => ({
        key: curriculum.id,
        title: `Группа дисциплин: ${curriculum.groupedDisciplines} - ${curriculum.discipline.titleRu} / ${curriculum.discipline.titleKz} / ${curriculum.discipline.titleEn}`,
        code: curriculum.discipline.code,
        number: curriculum.discipline.id,
        credits: curriculum.discipline.volumeCredits,
        component: curriculum.component.componentTitleRu,
        numberOfTrimester: curriculum.numberOfTrimester,
        year: curriculum.year,
      }));
    }

    return [];
  }, [filteredCurriculums]);

  const columns = [
    {
      title: 'Наименование дисциплины',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title', inputRef),
    },
    {
      title: 'Количество кредитов',
      dataIndex: 'credits',
      key: 'credits',
    },
    {
      title: '№',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Код',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Компонент',
      dataIndex: 'component',
      key: 'component',
    },
    {
      title: 'Триместр',
      dataIndex: 'numberOfTrimester',
      key: 'numberOfTrimester',
    },
    {
      title: 'Год обучения',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button className={styles.deleteButton} onClick={() => onDeleteCurriculum(record.key)}>
            <DeleteTwoTone twoToneColor="#ff0000" />
          </Button>
          <Button href={`/curriculum/edit/${record.key}`}>
            <EditTwoTone />
          </Button>
        </Space>
      ),
    },
  ];

  React.useEffect(() => {
    if (selectedCurriculums && selectedYear && selectedTrimester) {
      setFilteredCurriculums(
        selectedCurriculums.filter(
          (curriculum) => curriculum.year === selectedYear && curriculum.numberOfTrimester === selectedTrimester
        )
      );
    }
  }, [selectedCurriculums, selectedYear, selectedTrimester]);

  React.useEffect(() => {
    if (selectedCurriculums && selectedCurriculums.length > 0) {
      setEducProgramName(selectedCurriculums[0].educationProgram.titleRu);
    }
  }, [selectedCurriculums]);

  return (
    <section>
      <BackButton />

      <header className={styles.header}>
        <Title level={3} className={styles.title}>
          Ранее Созданный Учебный План в ОП {educProgramName}
        </Title>


        {selectedCurriculums && (<Button type='primary' loading={loadin} href={`/elective/education-program-id/${selectedCurriculums[0].educationProgram.id}`}>
          Каталог элективных дисциплин
        </Button>)}
        {selectedCurriculums && (<Button type='primary' loading={loadin} onClick={() => handleSync(selectedCurriculums[0].educationProgram.id)}>
          Синхронизировать с ЕПВО
        </Button>)}

        {/* @ts-ignore */}
        <Select placeholder="Выберите год обучения" value={selectedYear} onSelect={(value) => setSelectedYear(value)}>
          <Option value={1}>1 год обучения</Option>
          <Option value={2}>2 год обучения</Option>
          <Option value={3}>3 год обучения</Option>
          <Option value={4}>4 год обучения</Option>
          <Option value={5}>5 год обучения</Option>
          <Option value={6}>6 год обучения</Option>
        </Select>
        <Select
          placeholder="Выберите номер триместра из списка"
          value={selectedTrimester}
          // @ts-ignore
          onSelect={(value) => setSelectedTrimester(value)}
        >
          <Option value={1}>1 триместр</Option>
          <Option value={2}>2 триместр</Option>
          <Option value={3}>3 триместр</Option>
        </Select>
      </header>
      <Table columns={columns} pagination={{ defaultPageSize: 30 }} dataSource={getFilteredCurriculums()} />
      <br />
    </section>
  );
};

export default CurriculumCatalogWindow;
