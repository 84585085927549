import Page from '../../../../ui/Page'
import { Button, Form, Select, Space } from 'antd'
import RowSpan from '../../../../ui/RowSpan'
import { useSyllabus } from '../useSyllabus'
import useDictionary from '../../../../hooks/useDictionary'
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons'
import Spinner from '../../../../ui/Spinner'
import SyllabusStudyStreamsTable from './SyllabusStudyStreamsTable'

const SyllabusStudyStreamsPage = () => {
    const { data, methods } = useSyllabus();
    const { dictionary } = useDictionary({});

    return (
        <Page title='Syllabus of students' subtitle='By study streams'>
            <RowSpan
                left={
                    <Form form={data.form} layout='vertical' style={{ width: "25em" }} onFinish={methods.handleSearchByParamsStudyStreams}>
                        <Form.Item required name="degree_id" rules={[{ required: true }]} label='Academic degree'>
                            <Select>
                                {dictionary.degreeList?.map((item, index) => (
                                    <Select.Option key={index} value={item.id}>{`${item.title} / ${item.title} / ${item.title}`}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item required name={"enrolled_year"} rules={[{ required: true }]} label='Enrolled Year'>
                            <Select>
                                {dictionary.years?.map((item, index) => (
                                    <Select.Option key={index} value={item.year}>{item.year}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item required name={"education_year"} rules={[{ required: true }]} label='Education Year'>
                            <Select>
                                {dictionary.years?.map((item, index) => (
                                    <Select.Option key={index} value={item.year}>{item.year}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item required name={"course"} rules={[{ required: true }]} label='Course'>
                            <Select>
                                <Select.Option key={1} value={1}>{1}</Select.Option>
                                <Select.Option key={2} value={2}>{2}</Select.Option>
                                <Select.Option key={3} value={3}>{3}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item required name={"trim"} rules={[{ required: true }]} label='Trimester'>
                            <Select>
                                <Select.Option key={1} value={1}>{1}</Select.Option>
                                <Select.Option key={2} value={2}>{2}</Select.Option>
                                <Select.Option key={3} value={3}>{3}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <Button type='primary' htmlType='submit' loading={data.isLoading} icon={<SearchOutlined />}>Search</Button>
                                <Button type='primary' loading={data.isLoading} onClick={methods.handleDownloadExcelStudyStreams} icon={<DownloadOutlined />}>Download Excel</Button>
                            </Space>
                        </Form.Item>
                    </Form>
                }
            />

            {data.studyStreams ? (
                <SyllabusStudyStreamsTable syllabus={data.studyStreams} setStudyStreams={methods.setStudyStreams} />
            ) : data.isLoading && <Spinner size='large' />}
        </Page >
    )
}

export default SyllabusStudyStreamsPage