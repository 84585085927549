import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { CSSProperties } from 'react'


interface ModalProps {
    children: React.ReactNode | React.ReactNode[];
    title: string;
    onOk: () => void;
    btnTitle: string;
    footer?: React.ReactNode;
    btnType?: "primary" | "default" | "dashed" | "ghost" | "link" | "text";
    btnIcon?: React.ReactNode;
    style?: CSSProperties;
}

const CustomModal = ({ children, onOk, btnTitle, btnType, title, footer, btnIcon, style }: ModalProps) => {
    const [open, isOpen] = React.useState<boolean>(false);

    return (
        <>
            <Button type={btnType ? btnType : "primary"} onClick={() => isOpen(true)} icon={btnIcon ? btnIcon : <PlusOutlined />}>{btnTitle}</Button>
            <Modal open={open} style={style} centered={true} onCancel={() => isOpen(false)} onOk={() => { onOk(); isOpen(false) }} destroyOnClose={true} title={title} footer={footer}>
                {children}
            </Modal>
        </>

    )
}

export default CustomModal