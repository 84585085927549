import authAxios from '../../common/authAxios';
import { StudentSurveyAnswerForStudentDtoResponse } from '../../interfaces/Survey/StudentSurveyAnswerForStudentDtoResponse';
import { StudentSurveyAnswersDtoRequest } from '../../interfaces/Survey/StudentSurveyAnswersDtoRequest';
import { StudentSurveyAnswersDtoResponse } from '../../interfaces/Survey/StudentSurveyAnswersDtoResponse';
import { StudentTeachersDto } from '../../interfaces/Survey/StudentTeachersDto';
import { SurveyDtoResponse } from '../../interfaces/Survey/SurveyDtoResponse';
import { SurveyQuestionsDtoResponse } from '../../interfaces/Survey/SurveyQuestionsDtoResponse';

export class SurveyService {
    private static baseUrlSSCI = '/astanait-office-module/api/v1/survey';


    static getSurveys() {
        return authAxios.get<SurveyDtoResponse[]>(`${this.baseUrlSSCI}/get-all`)
    }

    static getSurveyById(id: number) {
        return authAxios.get<SurveyDtoResponse>(`${this.baseUrlSSCI}/get-by-id?id=${id}`)
    }

    static getAnswersBySurvey(surveyId: number) {
        return authAxios.get<StudentSurveyAnswersDtoResponse[]>(`${this.baseUrlSSCI}/by-survey?surveyId=${surveyId}`)
    }

    static isStudentAnswered(settingsId: number) {
        return authAxios.get<boolean>(`${this.baseUrlSSCI}/is-answered?settingsId=${settingsId}`)
    }

    static getTeachers() {
        return authAxios.get<StudentTeachersDto[]>(`${this.baseUrlSSCI}/get-teachers-for-student`);
    }

    static getAnswersByStudent() {
        return authAxios.get<StudentSurveyAnswerForStudentDtoResponse[]>(`${this.baseUrlSSCI}/for-student`)
    }

    static getQuestions(surveyId: number) {
        return authAxios.get<SurveyQuestionsDtoResponse[]>(`${this.baseUrlSSCI}/get-questions?surveyId=${surveyId}`)
    }

    static submitAnswers(dto: StudentSurveyAnswersDtoRequest[]) {
        return authAxios.post(`${this.baseUrlSSCI}/submit`, dto)
    }
}