import authAxios from '../common/authAxios';
import { FinancingSourceDto } from '../interfaces/FinancingSourceDto';

export class FinancingSourceService {
    static getFinancingSourceById(id: number) {
        return authAxios.get<FinancingSourceDto>(
            `/astanait-office-module/api/v1/academic-department/financing-source/get-by-id?id=${id}`
        );
    }

    static getAllFinancingSource() {
        return authAxios.get<FinancingSourceDto[]>(
            `/astanait-office-module/api/v1/academic-department/financing-source/get-all`
        );
    }
}