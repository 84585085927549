import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { CourseSelectResponse } from '../../../interfaces/CourseSelectResponse';
import StudentByGenderDtoResponse from '../../../interfaces/Student/StudentByGenderDtoResponse';
import { StudentCountByAgeDtoResponse } from '../../../interfaces/Student/StudentCountByAgeDtoResponse';
import { StatisticsService } from '../../../services/StatisticsService';
import { VedomostiService } from '../../../services/VedomostiService';
import { EducationalProgramsDtoResponse } from '../../../interfaces/EducationalPrograms/EducationalProgramsDtoResponse';

const useStatisticsPage = () => {
  const academicDegrees = [
    {
      id: 1,
      value: 1,
      name: "Bachelor's degree",
    },
    {
      id: 2,
      value: 2,
      name: "Master's degree",
    },
    {
      id: 3,
      value: 6,
      name: "Doctorate's degree",
    },
  ];

  const [courseOptions, setCourseOptions] = useState<CourseSelectResponse[]>([]);
  const [educationalPrograms, setEducationalPrograms] = useState<EducationalProgramsDtoResponse[]>([]);
  const [selectedEducationalDegree, setSelectedEducationalDegree] = useState<number>(0);

  const [genderStatistics, setGenderStatistics] = useState<StudentByGenderDtoResponse[]>([]);
  const [ageStatistics, setAgeStatistics] = useState<StudentCountByAgeDtoResponse[]>([]);
  const [totalNumber, setTotalNumber] = useState<number>(0);
  const [filter, setFilters] = useState([]);
  const [form] = useForm();

  useEffect(() => {
    StatisticsService.getByGender({ ...filter })
      .then(({ data }) => { setGenderStatistics(data); setTotalNumber(data.map((genderData) => genderData.count).reduce((a, b) => a + b, 0)) })
    StatisticsService.getByAge({ ...filter })
      .then(({ data }) => setAgeStatistics(data))
  }, [filter]);

  const handleSubmitFilter = (value: any) => {
    setFilters(value);
  };

  const sortEduProgramsAlphabetically = (data: EducationalProgramsDtoResponse[]) => {
    return data.sort((a, b) => a.titleEn.localeCompare(b.titleEn));
  };

  const handleEducationalDegreeSelect = (value: number) => {
    setSelectedEducationalDegree(value);

    if (value === 2) {
      setCourseOptions([
        { id: 1, year: 1 },
        { id: 2, year: 2 },
      ]);
    } else {
      setCourseOptions([
        { id: 1, year: 1 },
        { id: 2, year: 2 },
        { id: 3, year: 3 },
      ]);
    }
  };

  const handleSelectCourse = (value: number) => {
    VedomostiService.getEducationalProgramsByCourseAndDegree(value, selectedEducationalDegree)
      .then(({ data }) => {
        setEducationalPrograms(sortEduProgramsAlphabetically(data));
      })
  };

  const genderData = {
    labels: genderStatistics.map((gender) => gender.titleEn),
    datasets: [
      {
        label: '# of Votes',
        data: genderStatistics.map((gender) => gender.count),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],

        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const ageData = {
    labels: ageStatistics.map((age) => age.age),
    datasets: [
      {
        label: 'Number of students',
        data: ageStatistics.map((age) => age.count),
        backgroundColor: 'yellow',
      },
    ],
  };

  const ageOptions = {
    plugins: {
      tooltip: {
        intersect: false,
      },
      interaction: {
        mode: 'index',
      },
    },
  };

  return {
    genderData,
    totalNumber,
    ageData,
    ageOptions,
    academicDegrees,
    courseOptions,
    genderStatistics,
    educationalPrograms,
    form,
    handleSubmitFilter,
    handleEducationalDegreeSelect,
    handleSelectCourse,
  };
};

export default useStatisticsPage;
