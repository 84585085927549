import { Typography, Form, Input, Button, Space } from 'antd';
import { useEffect } from 'react';

import PageWrapper from '../../../../ui/PageWrapper';
import BackButton from '../../../../ui/BackButton';
import { errorNotification } from '../../../../helpers/errorNotification';
import { successNotification } from '../../../../helpers/successNotification';
import { AgreementService } from '../../../../services/AgreementService';
import { AgreementTypeDtoRequest } from '../../../../interfaces/AgreementTypeDtoRequest';


const { Title } = Typography;

interface Props {
    typeId?: number;
}

const AgreementTypeCreationPage = ({ typeId }: Props) => {
    const [form] = Form.useForm();

    const styles = { width: '800px', margin: '0 auto' };

    useEffect(() => {
        if (
            typeId
        ) {
            AgreementService.getAgreementTypeById(typeId)
                .then(({ data }) => {

                    form.setFieldsValue({
                        nameRu: data.nameRu,
                        name: data.name,
                        nameEn: data.nameEn,


                    });
                })
                .catch((err) => errorNotification('Не удалось получить данные', err.response?.status));
        }
    }, [
        typeId,
        form,
    ]);


    const onFinish = (agreementType: AgreementTypeDtoRequest) => {
        typeId
            ? AgreementService.updateAgreementType(typeId, agreementType)
                .then(() => successNotification('Данные успешно обновлены'))
                .catch((err) => errorNotification('Не удалось обновить данные', err.response?.status))
            :
            AgreementService.createAgreementType(agreementType)
                .then(() => successNotification('Данные успешно сохранены'))
                .catch((err) => errorNotification('Не удалось сохранить данные', err.response?.status));
    };


    return (
        <PageWrapper>
            <BackButton />
            <header style={styles}>
                <Title level={3}>
                    {typeId ? `Editing a Agreement Type` : 'Adding a new Agreement Type'}
                </Title>
            </header>

            <Form
                layout="vertical"
                size="large"
                form={form}
                style={styles}
                onFinish={onFinish}
            // onReset={handleFormReset}
            >
                <Form.Item
                    shouldUpdate
                    label="Type name in Kz"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Введите тип соглашения на казахском языке',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Type name in Ru"
                    name="nameRu"
                    rules={[
                        {
                            required: true,
                            message: 'Введите тип соглашения на русском языке',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Type name in En"
                    name="nameEn"
                    rules={[
                        {
                            required: true,
                            message: 'Введите тип соглашения на английском языке',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>


                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>


                    </Space>
                </Form.Item>
            </Form>
        </PageWrapper>
    );
};

export default AgreementTypeCreationPage;
