import { useEffect, useState } from 'react';
import grantPermission from '../../../helpers/grantPermission';
import { PersistentStateConstants } from '../../../PersistentStateConstants';
import { CourseSelectResponse } from '../../../interfaces/CourseSelectResponse';
import { StudentGroupsDtoResponse } from '../../../interfaces/Student/StudentGroupsDtoResponse';
import { AcademicDebtService } from '../../../services/AcademicDebtService';
import { MinNumberOfAcademicDebtsResponse } from '../../../services/MinNumberOfAcademicDebtsResponse';
import { PersistentStateService } from '../../../services/PersistentStateService';
import { VedomostiService } from '../../../services/VedomostiService';
import { EducationalProgramsDtoResponse } from '../../../interfaces/EducationalPrograms/EducationalProgramsDtoResponse';

const useStudentDebtPage = () => {

  const [educPrograms, setEducPrograms] = useState<EducationalProgramsDtoResponse[]>([]);
  const [courseOptions, setCourseOptions] = useState<CourseSelectResponse[]>([]);
  const [groupOptions, setGroupOptions] = useState<StudentGroupsDtoResponse[]>([]);
  const [numberOfDebts, setNumberOfDebts] = useState<number | undefined>();
  const [tableData, setTableData] = useState<MinNumberOfAcademicDebtsResponse[]>([]);

  const [areButtonsAvailable, setAreButtonsAvailable] = useState<boolean>(false);
  const [tableVisible, setTableVisible] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<number | undefined>();
  const [selectedEducationalDegree, setSelectedEducationalDegree] = useState<number | undefined>();
  const [selectedEducationalProgram, setSelectedEducationalProgram] = useState<number | undefined>();
  const [selectedGroup, setSelectedGroup] = useState<number | undefined>();
  const [count, setCount] = useState<number | undefined>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (grantPermission('STUDENTS'))
      AcademicDebtService.AcademicDebtsNumberForStudent().then((res) => setNumberOfDebts(res.data));
  }, []);

  // useEffect(() => {
  //   setLoading(true);
  //   if (PersistentStateService.hasInputFieldsInfo(PersistentStateConstants.STUDENT_DEBT_INPUT_FIELDS) && selectedCourse) {
  //     const inputFields: any = JSON.parse(
  //       // @ts-ignore
  //       PersistentStateService.getInputFieldsInfo(PersistentStateConstants.STUDENT_DEBT_INPUT_FIELDS)
  //     );
  //     handleEducationalDegreeSelect(inputFields.selectedEducationalDegree);
  //     setSelectedCourse(inputFields.selectedCourse);
  //     VedomostiService.getEducationalProgramsByCourseAndDegree(
  //       inputFields.selectedCourse,
  //       inputFields.selectedEducationalDegree
  //     )
  //       .then(({ data }) => {
  //         setEducPrograms(sortEduProgramsAlphabetically(data));
  //       });
  //     handleEducationalProgramSelect(inputFields.selectedEducationalProgram);
  //     handleGroupSelect(inputFields.selectedGroup);
  //   }
  //   setLoading(false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedCourse]);

  useEffect(() => {
    PersistentStateService.setInputFieldsInfo(PersistentStateConstants.STUDENT_DEBT_INPUT_FIELDS, {
      selectedEducationalDegree,
      selectedCourse,
      selectedEducationalProgram,
      selectedGroup,
    });
  }, [selectedEducationalDegree, selectedCourse, selectedEducationalProgram, selectedGroup]);

  const sortEduProgramsAlphabetically = (data: EducationalProgramsDtoResponse[]) => {
    return data.sort((a, b) => a.titleEn.localeCompare(b.titleEn));
  };

  const downloadExcelTable = () => {
  };

  const handleSearch = () => {
    setLoading(true);
    setAreButtonsAvailable(true);
    setTableVisible(true);
    AcademicDebtService.MinNumberOfAcademicDebtsForOffice(
      selectedEducationalDegree,
      selectedCourse,
      selectedEducationalProgram,
      selectedGroup,
      count
    )
      .then((res) => {
        setTableData(res.data);
      })
      .finally(() => setLoading(false));
  };

  const handleEducationalDegreeSelect = (value: number) => {
    if (value === 2)
      setCourseOptions([
        { id: 1, year: 1 },
        { id: 2, year: 2 },
      ]);
    else
      setCourseOptions([
        { id: 1, year: 1 },
        { id: 2, year: 2 },
        { id: 3, year: 3 },
      ]);
    setSelectedEducationalDegree(value);
    setSelectedCourse(undefined);
    setSelectedEducationalProgram(undefined);
    setSelectedGroup(undefined);
    setAreButtonsAvailable(true);
    setTableVisible(false);
  };

  const handleCourseSelect = (value: number) => {
    setSelectedCourse(value);
    setLoading(true);
    setSelectedEducationalProgram(undefined);
    setSelectedGroup(undefined);
    setTableVisible(false);
    setAreButtonsAvailable(true);
    VedomostiService.getEducationalProgramsByCourseAndDegree(value, selectedEducationalDegree)
      .then(({ data }) => {
        setEducPrograms(sortEduProgramsAlphabetically(data));
      })
      .finally(() => setLoading(false));
  };

  const handleEducationalProgramSelect = (value: any) => {
    setLoading(true);
    setSelectedEducationalProgram(value);
    setSelectedGroup(undefined);
    setTableVisible(false);
    setAreButtonsAvailable(true);
    VedomostiService.getGroupsByCourseAndEducationalProgram(selectedCourse, value)
      .then((res) => setGroupOptions(res.data))
      .finally(() => setLoading(false));
  };

  const handleGroupSelect = (value: any) => {
    setSelectedGroup(value);
  };

  const handleCountSelect = (value: any) => {
    setCount(value);
  };

  return {
    downloadExcelTable,
    handleSearch,
    handleEducationalDegreeSelect,
    handleCourseSelect,
    handleEducationalProgramSelect,
    handleGroupSelect,
    handleCountSelect,
    educPrograms,
    setEducPrograms,
    courseOptions,
    setCourseOptions,
    groupOptions,
    setGroupOptions,
    numberOfDebts,
    setNumberOfDebts,
    tableData,
    setTableData,
    areButtonsAvailable,
    setAreButtonsAvailable,
    tableVisible,
    setTableVisible,
    selectedCourse,
    setSelectedCourse,
    selectedEducationalDegree,
    setSelectedEducationalDegree,
    selectedEducationalProgram,
    setSelectedEducationalProgram,
    selectedGroup,
    setSelectedGroup,
    count,
    setCount,
    loading,
    setLoading,
  };
};

export default useStudentDebtPage;
