import Login from './components/pages/Login';
import AuthorizedRoute from './common/AuthorizedRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthService from './services/authService';
import PageLayout from './components/layout';
import { ConfigProvider } from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import moment from 'moment';
import 'moment/locale/en-gb'; // important!
import TeachersPage from './components/pages/TeachersPage/TeachersPage';
import TeacherPage from './components/pages/TeacherPage/TeacherPage';
import CertificateVerificationPage from './components/pages/CertificateVerificationPage/CertificateVerificationPage';
import CertificateWebPage from './components/pages/CertificateWebPage';
// import MaintenancePage from './components/pages/MaintenencePage';
import CertificateWebPageEn from './components/pages/CertificateWebPage/CertificateWebPageEn';
import DiplomaRegisterNumberPage from './components/pages/PublicPages/DiplomaRegisterNumberPage';
import UserService from './services/userService';
moment.locale('en-gb'); // important!

const rExp: RegExp = new RegExp('/certificate-web-page/[0-9]+');
const rExpEn: RegExp = new RegExp('/certificate-web-page-en/[0-9]+');

const App = () => {
  let button;
  if (rExp.test(window.location.href)) {
    button = <Route
      path="/certificate-web-page/:id"
      exact
      render={({ match }) => <CertificateWebPage certificateId={parseInt(match.params.id)} />}
    ></Route>
  } else if (rExpEn.test(window.location.href)) {
    button = <Route
      path="/certificate-web-page-en/:id"
      exact
      render={({ match }) => <CertificateWebPageEn certificateId={parseInt(match.params.id)} />}
    ></Route>
  }
  else {
    button = <AuthorizedRoute path="/" Component={PageLayout}></AuthorizedRoute>
  }
  return (
    <Switch>
      <ConfigProvider locale={en_GB}>
        {button}
        {!AuthService.isLoggedIn() && <Route path="/public-teachers-list" exact component={TeachersPage} />}
        {!AuthService.isLoggedIn() && (
          <Route path="/public/teacher/:id" render={({ match }) => <TeacherPage isPublic={true} />} />
        )}

        <Route path="/public-certificate-verification" exact component={CertificateVerificationPage}></Route>
        <Route path="/public/register-number" exact component={DiplomaRegisterNumberPage}></Route>

        <Route path="/login" exact component={Login}>
          {AuthService.isLoggedIn() && <Redirect to="/" />}
        </Route>
        {/* <MaintenancePage /> */}
      </ConfigProvider>
    </Switch>
  );
};

export default App;
