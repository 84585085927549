import React from 'react'
import { TeacherService } from '../../../../services/TeacherService';
import { TeacherAdditionalInfoDtoRequest } from '../../../../interfaces/Teacher/TeacherAdditionalInfoDtoRequest';
import useFetchData from '../../../../hooks/useFetchData';
import { TeacherAdditionalInfoDtoResponse } from '../../../../interfaces/Teacher/TeacherAdditionalInfoDtoResponse';
import UniversalTable from '../UniversalTeachersTable';
import { Button, Form, Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { FileService } from '../../../../services/file/FileService';
import TeacherAdditionalDataType from '../../TeacherInfoSection/TeacherAdditionalDataType';
import { CatalogDtoResponse } from '../../../../interfaces/CatalogDtoResponse';
import { successNotification } from '../../../../helpers/successNotification';

interface TeacherAdditionalInfoTableProps {
    isPublic: boolean;
}


const TeacherAdditionalInfoTable = ({ isPublic }: TeacherAdditionalInfoTableProps) => {
    const { data: additionalInfoTypes } = useFetchData(TeacherService.getAdditionalInfoTypes, !isPublic);
    const { data: additionalInfo, fetchData: fetchAdditionalInfo } = useFetchData<TeacherAdditionalInfoDtoResponse[]>(TeacherService.getTeacherAdditionalInfo, !isPublic);
    const { data: categories } = useFetchData<CatalogDtoResponse[]>(TeacherService.getAdditionalInfoCategories, !isPublic);

    const [form] = Form.useForm();

    const [selectedCategory, setSelectedCategory] = React.useState<number>();
    const [selectedType, setSelectedType] = React.useState<string>("");

    const addRecord = async (record: TeacherAdditionalInfoDtoRequest) => {
        const additionalInfoType = additionalInfoTypes?.find(v => v.name === record.type);

        if (additionalInfoType?.objectType === "file") {
            const reader = new FileReader();

            const fileToBase64 = new Promise<string>((resolve, reject) => {
                reader.onload = (e) => {
                    const base64String = (e.target?.result as string).split(',')[1];
                    resolve(base64String);
                };
                reader.onerror = (error) => reject(error);
            });

            if (record.value.file) {
                reader.readAsDataURL(record.value.file);
            }

            record.value = await fileToBase64;
        }
        record.objectType = additionalInfoType?.objectType;

        TeacherService.createOrUpdateAdditionalInfo(record)
            .then(() => fetchAdditionalInfo())
            .finally(() => {
                form.resetFields();
                setSelectedCategory(undefined);
                setSelectedType("");
                successNotification("Additional info created!");
            });
    };

    const editRecord = (record: TeacherAdditionalInfoDtoRequest) => {
    };

    const deleteRecord = (record: TeacherAdditionalInfoDtoRequest) => {
        record.id && TeacherService.deleteAdditionalInfo(record.id)
            .then(() => fetchAdditionalInfo())
            .finally(() => successNotification("Additional info deleted!"));
    };

    const handleDownload = (value: string) => {
        FileService.getFileById(Number(value), "Teacher additional info");
    };

    return (
        <UniversalTable
            columns={
                [
                    {
                        title: "#",
                        dataIndex: "key",
                        key: "key",
                    },
                    {
                        title: "Name",
                        dataIndex: "name",
                        key: "name",
                    },
                    {
                        title: "Value",
                        dataIndex: "value",
                        key: "value",
                        render: (text: string, record: any) => {
                            switch (record.type.objectType) {
                                case "file":
                                    return (
                                        <Button icon={<DownloadOutlined />} onClick={() => handleDownload(record.value)}>
                                            Download
                                        </Button>
                                    );
                                default:
                                    return text;
                            }
                        }
                    },
                ]
            }
            dataSource={additionalInfo?.map((info, index) => ({
                ...info,
                key: index + 1,
                id: info.id,
                name: info.type.titleEn || info.type.titleRu,
            })) || []}
            addRecord={(e: TeacherAdditionalInfoDtoRequest) => addRecord(e)}
            editRecord={(e: TeacherAdditionalInfoDtoRequest) => editRecord(e)}
            deleteRecord={(e: TeacherAdditionalInfoDtoRequest) => deleteRecord(e)}
            hideEdit={true}
            form={form}
            customForm={
                <Form form={form} layout='vertical'>
                    <Form.Item label={"Category"} name={"category"}>
                        <Select placeholder="Select category" onSelect={e => setSelectedCategory(e)} style={{ width: "90%", marginBottom: 15 }}>
                            {categories?.map((category) => (
                                <Select.Option value={category.id}>{category.nameEn}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {selectedCategory && <Form.Item label={"Additional info type"} name={"type"}>
                        <Select placeholder="Select type" style={{ width: "90%" }} onSelect={e => setSelectedType(e)}>
                            {additionalInfoTypes?.filter(v => v.category.id === selectedCategory)?.map((type) => (
                                <Select.Option value={type.name}>{type.titleEn + "/" + type.titleRu + "/" + type.title}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>}
                    {selectedType && <TeacherAdditionalDataType teacherAdditionalInfo={additionalInfoTypes?.find(v => v.name === selectedType)} form={form} />}
                </Form>
            }
            isPublic={isPublic}
        />
    )
}

export default TeacherAdditionalInfoTable