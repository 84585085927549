
export const getFullDateWithTime = (val: string): string => {
    const dateAndTime = val.split('T');
    const date = dateAndTime[0].split('-').reverse().join('-');
    const time = dateAndTime[1].split('.')[0];
    return date + ' ' + time;
};

export const getFullDate = (val: string): string => {
    const date = val.split('T')[0].split('-').reverse().join('-');
    return date;
};
