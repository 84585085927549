import { useEffect, useState } from 'react';
import { SsciStudentApplicationsDto } from '../../../interfaces/Ssci/Applications/SsciStudentApplicationsDto';
import { SsciService } from '../../../services/student/SsciService';


const useStudentPersonalSsciPage = (studentId?: number) => {
    const [studentSsci, setStudentSsci] = useState<SsciStudentApplicationsDto[]>([]);



    useEffect(() => {
        if (studentId !== undefined) {
            SsciService.getStudentApplicationsByStudentId(studentId)
                .then((response) => setStudentSsci(response.data))
                .catch((error) => console.error(error));
        }
    }, [studentId]);

    const column = [
        {
            title: 'Application ID',
            dataIndex: 'applicationId',
            key: 'applicationId',

        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',

        },
        {
            title: 'Index Status',
            dataIndex: 'indexStatus',
            key: 'indexStatus',

        },
        {
            title: 'Event Name',
            dataIndex: 'eventName',
            key: 'eventName',

        },
        {
            title: 'Event Date',
            dataIndex: 'eventDate',
            key: 'eventDate',

        },
        {
            title: 'Hours',
            dataIndex: 'hours',
            key: 'hours',

        },
        {
            title: 'Info',
            dataIndex: 'info',
            key: 'info',

        },
        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points',

        }
    ]

    return {
        studentSsci,
        column
    };
}



export default useStudentPersonalSsciPage;