import authAxios from '../common/authAxios';
import { downloadFile } from '../helpers/downloadFile';
import { ComponentDtoResponse } from '../interfaces/EducationalPrograms/ComponentDtoResponse';
import { CurriculumByGroupDtoRequest } from '../interfaces/EducationalPrograms/CurriculumByGroupDtoRequest';
import { CurriculumDtoRequest } from '../interfaces/EducationalPrograms/CurriculumDtoRequest';
import { CurriculumDtoResponse } from '../interfaces/EducationalPrograms/CurriculumDtoResponse';
import { CycleDtoResponse } from '../interfaces/EducationalPrograms/CycleDtoResponse';
import { StudentDisciplineChoiceDtoRequest } from '../interfaces/Student/StudentDisciplineChoiceDtoRequest';
import { StudentDisciplineChoiceByOfficeDtoRequest } from '../interfaces/EducationalPrograms/StudentDisciplineChoiceByOfficeDtoRequest';
import { StudentDisciplineFinalChooseDtoResponse } from '../interfaces/Student/StudentDisciplineFinalChooseDtoResponse';
import { StudentWithCurriculumStatus } from '../interfaces/Student/StudentWithCurriculumStatus';

export class CurriculumService {
  private static baseUrl = '/astanait-office-module/api/v1/academic-department/curriculum';

  static getComponents() {
    return authAxios.get<ComponentDtoResponse[]>('/astanait-office-module/api/v1/catalog/component/get-all-component');
  }

  static getStudentsWithStatuses(
    academicDegreeId: number | undefined = 0,
    courseId: number | undefined = 0,
    educationProgramId: number | undefined = 0,
    groupId: number | undefined = 0
  ) {
    return authAxios.get<StudentWithCurriculumStatus[]>(
      `/astanait-office-module/api/v1/academic-department/curriculum/get-students-with-status?academic_degree_id=${academicDegreeId}&course=${courseId}&education_program_id=${educationProgramId}&group_id=${groupId}`
    );
  }

  static setStatusToStudents(
    academicDegreeId: number | undefined = 0,
    courseId: number | undefined = 0,
    educationProgramId: number | undefined = 0,
    groupId: number | undefined = 0
  ) {
    return authAxios.post(
      `/astanait-office-module/api/v1/academic-department/curriculum/set-status-to-students?academic_degree_id=${academicDegreeId}&course=${courseId}&education_program_id=${educationProgramId}&group_id=${groupId}`
    );
  }

  static getCycles() {
    return authAxios.get<CycleDtoResponse[]>('/astanait-office-module/api/v1/catalog/cycle/get-all-cycle');
  }

  static getCurriculumsByEducationalProgram(educationalProgramId: number) {
    return authAxios.get<CurriculumDtoResponse[]>(
      `${this.baseUrl}/get-curriculums-by-education-program?education_program_id=${educationalProgramId}`
    );
  }

  static getById(curriculumId: number) {
    return authAxios.get<CurriculumDtoResponse>(`${this.baseUrl}/get-by-id/${curriculumId}`);
  }

  static createCurriculum(curriculum: CurriculumDtoRequest) {
    return authAxios.post(`${this.baseUrl}/create-curriculum`, curriculum);
  }

  static createCurriculumByGroup(curriculumByGroup: CurriculumByGroupDtoRequest) {
    return authAxios.post(`${this.baseUrl}/create-curriculum-by-group`, curriculumByGroup);
  }

  static deleteCurriculum(curriculumId: number) {
    return authAxios.delete(`${this.baseUrl}/delete-curriculum?curriculum_id=${curriculumId}`);
  }

  // Student side

  // Save discipline choice by priority

  static saveDisciplineChoice(data: StudentDisciplineChoiceDtoRequest) {
    return authAxios.post(
      `/astanait-office-module/api/v1/student-discipline-choose/create-student-discipline-choose`,
      data
    );
  }

  static saveDisciplineChoiceForOffice(data: StudentDisciplineChoiceByOfficeDtoRequest) {
    return authAxios.post(
      `/astanait-office-module/api/v1/student-discipline-choose/create-student-discipline-final-choose-for-office`,
      data
    );
  }

  static getStudentCurriculumForNextYear() {
    return authAxios.get<CurriculumDtoResponse[]>(
      'astanait-office-module/api/v1/academic-department/curriculum/get-student-curriculum-for-next-year'
    );
  }

  static getStudentCurriculumForNextYearPDF(userId: number, course: number, langId: number) {
    return downloadFile(
      `${this.baseUrl}/get-student-curriculum-for-next-year-pdf?user_id=${userId}&course=${course}&langId=${langId}`,
      'GET',
      'blob',
      `${userId}`,
      'pdf'
    );
  }

  static getStudentCurriculumForNextYearForOffice(userId: number, course: number) {
    return authAxios.get<StudentDisciplineFinalChooseDtoResponse[]>(
      `astanait-office-module/api/v1/student-discipline-choose/get-student-IC-by-course-for-office?user_id=${userId}&course=${course}`
    );
  }

  static getStudentCurriculumForNextYearForStudent(course: number) {
    return authAxios.get<StudentDisciplineFinalChooseDtoResponse[]>(
      `astanait-office-module/api/v1/student-discipline-choose/get-student-IC-by-course-for-student?course=${course}`
    );
  }

  static getStudentCurrentCurriculumForStudent() {
    return authAxios.get<StudentDisciplineFinalChooseDtoResponse[]>(
      `astanait-office-module/api/v1/student-discipline-choose/get-student-current-IC-for-student`
    );
  }

  static getElectiveDisciplines(educationalProgramId: number) {
    return authAxios.get<any>(
      `astanait-office-module/api/v1/academic-department/curriculum/get-ced-by-education-program?education_program_id=${educationalProgramId}`
    );
  }
  static deleteStudentDiscipline(id: number) {
    return authAxios.delete(
      `astanait-office-module/api/v1/student-discipline-choose/delete-student-discipline-final-choose-by-id-for-office?id=${id}`
    );
  }

  static downloadExcelCurriculum(education_program_id: number) {
    return downloadFile(
      `astanait-office-module/api/v1/academic-department/curriculum/get-working-curriculum-excel?education_program_id=${education_program_id}`,
      'GET',
      'blob',
      education_program_id.toString(),
      'xlsx'
    );
  }

  static downloadExcelCurriculumReport(education_program_id: number) {
    return downloadFile(
      `astanait-office-module/api/v1/academic-department/curriculum/get-curriculum-report-excel?education_program_id=${education_program_id}`,
      'GET',
      'blob',
      education_program_id.toString(),
      'xlsx'
    );
  }

  static downloadExcelCurriculumByYear(education_program_id: number, year: number) {
    return downloadFile(
      `astanait-office-module/api/v1/academic-department/curriculum/get-working-curriculum-by-year-excel?education_program_id=${education_program_id}&year=${year}`,
      'GET',
      'blob',
      year.toString() + 'курс' + education_program_id.toString(),
      'xlsx'
    );
  }

  static downloadExcelCurriculumElectiveCatalog(education_program_id: number) {
    return downloadFile(
      `astanait-office-module/api/v1/academic-department/curriculum/get-elective-catalog-excel?education_program_id=${education_program_id}`,
      'GET',
      'blob',
      education_program_id.toString(),
      'xlsx'
    );
  }
  static updateCurriculum(curriculumId: number, updatedCurriculum: CurriculumDtoRequest) {
    return authAxios.put(
      `/astanait-office-module/api/v1/academic-department/curriculum/${curriculumId}`,
      updatedCurriculum
    );
  }
}
