import { useEffect, useState } from 'react'
import Page from '../../../../../ui/Page'
import { Button, DatePicker, Form, List, Modal, Tag } from 'antd'
import moment from 'moment';
import { KpiService } from '../../../../../services/kpi/KpiService'
import { KpiApplicationSettingsDtoResponse } from '../../../../../interfaces/Kpi/KpiApplicationSettingsDtoResponse'
import { errorNotification } from '../../../../../helpers/errorNotification'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import RowSpan from '../../../../../ui/RowSpan';
import CustomModal from '../../../../../ui/Modal';
import { successNotification } from '../../../../../helpers/successNotification';
import BackButton from '../../../../../ui/BackButton';

const KpiSettingsPage = () => {

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();
    const [modalForm] = Form.useForm();

    const [settings, setSettings] = useState<KpiApplicationSettingsDtoResponse[]>([]);

    useEffect(() => {
        KpiService.getApplicationSettings()
            .then(({ data }) => setSettings(data));
    }, [isLoading])

    const handleModalOpen = (value: KpiApplicationSettingsDtoResponse) => {
        setOpen(true)
        modalForm.setFieldsValue({
            "id": value.id,
            "dates": [moment(value.startDate), moment(value.endDate)]
        })
    }

    const handleApplicationSetting = (dates: any, id?: number) => {
        const startDateString = new Date(moment(dates[0]).format('YYYY-MM-DD'));
        const endDateString = new Date(moment(dates[1]).format('YYYY-MM-DD'));

        KpiService.createOrUpdateApplicationSettings({ startDate: startDateString, endDate: endDateString }, id)
            .then(() => {
                successNotification(id ? 'Successfully updated!' : 'Successfully created!')
                setIsLoading(!isLoading)
            });
    }

    const handleDeleteSetting = (id: number) => {
        KpiService.deleteApplicationSetting(id)
            .then(() => {
                successNotification('Successfully deleted!')
                setIsLoading(!isLoading)
            })
            .catch((err) => {
                switch (err.response!.status) {
                    case 404:
                        return errorNotification('Заявки уже существуют по данному отрезку времени!');
                    default:
                        return errorNotification('Не удалось сохранить данные', err.response?.status);
                }
            });
    }

    return (
        <Page title='KPI Stream Settings'>
            <RowSpan
                left={
                    <BackButton />
                }
                right={
                    <CustomModal title='Create new deadline' btnTitle='Create new KPI stream' onOk={form.submit}>
                        <Form form={form} onFinish={(e) => handleApplicationSetting(e.dates)}>
                            <Form.Item name="dates">
                                <DatePicker.RangePicker />
                            </Form.Item>
                        </Form>
                    </CustomModal>
                } />

            <List itemLayout='horizontal' dataSource={settings} renderItem={(item, i) => (
                <List.Item key={i} actions={item.isCurrent === true ? [
                    <Button icon={<EditOutlined />} onClick={() => handleModalOpen(item)}>Edit</Button>,
                    <Button icon={<DeleteOutlined />} onClick={() => handleDeleteSetting(item.id)} danger>Delete</Button>
                ] : [
                    <Button icon={<DeleteOutlined />} onClick={() => handleDeleteSetting(item.id)} danger>Delete</Button>
                ]}>
                    <DatePicker.RangePicker value={[moment(item.startDate), moment(item.endDate)]} />
                    <Tag color={item.isCurrent === true ? "green" : "red"}>{item.isCurrent === true ? "Active" : "Not Active"}</Tag>
                </List.Item>
            )} />

            <Modal open={open} title={"Edit deadline"} onOk={modalForm.submit} onCancel={() => setOpen(false)}>
                <Form form={modalForm} onFinish={(e) => {
                    handleApplicationSetting(e.dates, e.id)
                    setOpen(false)
                }}>
                    <Form.Item name="id" noStyle>
                        <></>
                    </Form.Item>
                    <Form.Item name={"dates"}>
                        <DatePicker.RangePicker />
                    </Form.Item>
                </Form>
            </Modal>
        </Page>
    )
}

export default KpiSettingsPage;