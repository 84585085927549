import { Input, Table, Typography, Form, Popconfirm } from 'antd';
import React from 'react';
import { errorNotification } from '../../../../helpers/errorNotification';
import { successNotification } from '../../../../helpers/successNotification';
import { IrosStatus } from '../../../../interfaces/Iros/IrosStatus';
import { IrosStatusPoint } from '../../../../interfaces/Iros/IrosStatusPoint';
import { IrosService } from '../../../../services/student/IrosService';
import PageWrapper from '../../../../ui/PageWrapper';

const { Title } = Typography;

interface Props {
  indicatorId: number;
  indicatorTitle: string;
}

const IrosPointCreationPage = ({ indicatorId, indicatorTitle }: Props) => {
  const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }: any) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const [irosStatus, setIrosStatus] = React.useState<IrosStatus[]>([]);
  const [oldIndexStatusPoint, setOldIndexStatusPoint] = React.useState<IrosStatusPoint[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = React.useState('');

  const isEditing = (record: any) => record.key === editingKey;

  React.useEffect(() => {
    IrosService.getStatus()
      .then(({ data }) => setIrosStatus(data));
  }, [loading]);

  React.useEffect(() => {
    IrosService.getIrosPointByIndicatorId(indicatorId)
      .then(({ data }) => {
        setOldIndexStatusPoint(data);
      })
      .finally(() => setLoading(false));
  }, [indicatorId, loading]);

  const edit = (record: any) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = (record: any) => {
    const oldData = [...oldIndexStatusPoint];
    const index = oldData.find((item) => record.id === item.id);
    if (Number(form.getFieldValue('point'))) {
      if (Number(form.getFieldValue('point')) > 100 || Number(form.getFieldValue('point')) < 0) {
        errorNotification('Значение не может быть больше 100 или меньше 0!');
      } else if (index) {
        index!.points = Number(form.getFieldValue('point'));
        IrosService.createIrosIndicatorsPoint({
          id: index.id,
          indexStatus: index.indexStatus.id,
          indicator: indicatorId,
          points: index.points,
        })
          .then(() => successNotification('Данные успешно сохранены'))
          .finally(() => {
            setLoading(true);
            cancel();
          });
      } else {
        IrosService.createIrosIndicatorsPoint({
          indexStatus: record.statusId,
          indicator: indicatorId,
          points: Number(form.getFieldValue('point')),
        })
          .then(() => successNotification('Данные успешно сохранены'))
          .finally(() => {
            setLoading(true);
            cancel();
          });
      }
    } else {
      errorNotification('Нужно обязательно заполнить баллы!');
    }
  };

  const columns = [
    {
      title: 'Статус индекса',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Балл',
      dataIndex: 'point',
      key: 'point',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <p>Cancel</p>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <PageWrapper>
      <Title level={3}>Редактирование Баллов Индикатора iROS / {indicatorTitle}</Title>

      <Form form={form} component={false}>
        <Table
          loading={loading}
          columns={mergedColumns}
          dataSource={irosStatus.map((irosstatus, i) => ({
            key: i,
            id: oldIndexStatusPoint.find((indexStatusPoint) => indexStatusPoint.indexStatus.id === irosstatus.id)?.id,
            status: irosstatus.name,
            statusId: irosstatus.id,
            point: oldIndexStatusPoint.find((indexStatusPoint) => indexStatusPoint.indexStatus.id === irosstatus.id)
              ?.points,
          }))}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
    </PageWrapper>
  );
};

export default IrosPointCreationPage;
