export const isDeadlinePassed = (deadlineDate: Date | string, noHours?: boolean): boolean => {
    const deadline = new Date(deadlineDate);
    const now = new Date();

    if (noHours) {
        deadline.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);
    }

    return deadline < now;
}